var i18n = {
    en: {
        overtime_1: `Error code: `,
        overtime_2: `, plesase retry it`,
        isnetwork: `Internet error. Please check and retry`,
        email_placeholder: `Email`,
        email_empty: `Please input email`,
        email_not_valid: `Email is not valid`,
        email_not_valid_1: `Please input your email`,
        email_not_valid_2: `Email is not valid, please use a different address.`,
        email_not_valid_3: `No email input`,
        password_placeholder: `Password`,
        password_empty: `Please input password`,
        password_not_valid: `Incorrect account or password`,
        password_not_valid_1: `Password above 8 characters is required`,
        password_not_valid_2: `Please create a password`,
        password_placeholder_1: `Create your password`,
        password_placeholder_2: `Confirm your Password`,
        password_placeholder_3: `Create new password`,
        password_placeholder_4: `Confirm new password`,
        password_placeholder_5: `Input old password`,
        copy_password_empty: `Please confirm password`,
        copy_password_not_valid: `Please confirm your password`,
        copy_passwords_inconsistent: `Your password confirmation does not match`,
        code_empty: `Please input verification code`,
        code_not_valid: `Invalid verification code`,
        code_placeholder: `Verification Code`,
        not_received_code: `If your mailbox has not received the verification code for a long time, please get the verification code again.`,
        get_first_code: `Please get the verification code first.`,
        last_name_placeholder: `Please enter your last name`,
        first_name_placeholder: `Please enter your first name`,
        address_placeholder: `Please enter your address`,
        no_code_text: `We've sent a verification code. Please enter your code. <span class='tips'>Didn't receive a code?`,
        no_code_text_1: `1. Please make sure the email address is valid and it can receive emails.`,
        no_code_text_2: `2. For the email is sent by system automatically, it may be flagged as the spam or junk email. Please check whether the email is in the Trash folder.`,
        no_code_text_3: `3. Cannot solve your problem? `,
        no_code_text_3_span: `Then click here to contact us.`,
        order_no: `You have not purchased any product,If you have any question, please <a href=\"https://da.tipard.com/contact.html\">contact us</a>.`,
        error_24901: `Current account has no email linked, and cannot find the orders. Please connect an email.`,
        user_guide: `User Guide>>`,
        download: `Download`,
        order_number: `Order Number:`,
        Refund: `Refund`,
        Disabled: `Disabled`,
        Normal: `Normal`,
        Modify: `Modify`,
        Modify_1: `Modify>>`,
        Connect: `Connect`,
        unlink_success: `Unlink successfully`,
        connect_success: `Connect successfully`,
        feedback_title: `Thanks for your feedback. Please leave your problem and we will respond to you within 24 hours.`,
        feedback_thank_you: `Thank You!<br />Your Feedback has been submitted successfully.`,
        feedback_email: `Enter your email here!`,
        feedback_content: `Leave any problem or suggestion you encountered here.`,
        feedback_submit: `Submit`,
        form_contents: `You have not entered any description. Please enter it and submit again.`,
        old_password: `Please input old password`,
        new_password: `Please create a new password`,
        old_new_password: `The new password cannot be the same as the old one`,
        incorrect_password: `Incorrect password`,
        delete_no: `Delete Now`,
        Caps: `Caps lock is on`,
        Get: `Get`,
        Done: `Done`,
        error_20001: `Error: 20001. Please log in again.`,
        error_20002: `Error: 20002. Please log in again.`,
        error_20003: `Error: 20003. Please log in again.`,
        error_20004: `Request failed (error code: 20004). Please try it again.`,
        error_20005: `Login session has expired (Error: 20005). Please log in again.`,
        error_20006: `Request failed (error code: 20006). Please try it again.`,
        error_20007: `Login session has expired (Error: 20007). Please log in again.`,
        error_20008: `Login session has expired (Error: 20008). Please log in again.`,
        error_20009: `Login session has expired (Error: 20009). Please log in again.`,
        error_20101: `Please input your email (error code: 20101)`,
        error_20102: `Email is not valid (error code: 20102)`,
        error_20103: `Request failed (error code: 20103). Please try it again`,
        error_20104: `Email is already in use, please <a href="https://account.tipard.com/login">log in</a> or register with a new one`,
        error_20105: `Request failed (error code: 20105). Please try it again`,
        error_20106: `Failed to send email, please retry it`,
        error_20201: `Please input your email (error code: 20201)`,
        error_20202: `Please input your password (error code: 20202)`,
        error_20203: `Please input verification code (error code: 20203)`,
        error_20204: `Email is not valid (error code: 20204)`,
        error_20205: `Password above 8 characters is required (error code: 20205)`,
        error_20206: `Request failed (error code: 20206). Please try it again`,
        error_20207: `Invalid verification code`,
        error_20208: `Request failed (error code: 20208). Please try it again`,
        error_20209: `Request failed (error code: 20209). Please try it again`,
        error_20301: `Please input your email (error code: 20301)`,
        error_20302: `Error: 20302. Please contact us`,
        error_20303: `Email is not valid (error code: 20303)`,
        error_20304: `Request failed (error code: 20304). Please try it again`,
        error_20305: `Account does not exist. Please re-enter or <a href="https://account.tipard.com/register">Create it</a> first.`,
        error_20306: `No password yet. Use <a href="https://account.tipard.com/passwordless-login">Passwordless login</a> or <a href="create-password">set a password</a> and log in.`,
        error_20308: `Request failed (error code: 20308). Please try it again`,
        error_20401: `Failed to log out (error code: 20401). Please try it again`,
        error_20501: `Please input your email (error code: 20501)`,
        error_20502: `Email is not valid (error code: 20502)`,
        error_20503: `Request failed (error code: 20503). Please try it again`,
        error_20504: `Failed to send email. Please try it again.`,
        error_20601: `Please input your email (error code: 20601)`,
        error_20602: `Please input verification code (error code: 20602)`,
        error_20603: `Email is not valid (error code: 20603)`,
        error_20604: `Request failed (error code: 20604). Please try it again`,
        error_20606: `Request failed (error code: 20606). Please try it again`,
        error_20607: `Request failed (error code: 20607). Please try it again`,
        error_20608: `Request failed (error code: 20608). Please try it again`,
        error_20701: `Please input your email (error code: 20701)`,
        error_20702: `Email is not valid (error code: 20702)`,
        error_20703: `Request failed (error code: 20703). Please try it again`,
        error_20704: `Account does not exist. Please re-enter or <a href="https://account.tipard.com/register">Create it</a> first.`,
        error_20705: `Request failed (error code: 20705). Please try it again`,
        error_20706: `Failed to send email. Please try it again`,
        error_20801: `Please input your email (error code: 20801)`,
        error_20802: `Error: 20802. Please contact us`,
        error_20803: `Please input verification code (error code: 20803)`,
        error_20804: `Email is not valid (error code: 20804)`,
        error_20805: `Password above 8 characters is required (error code: 20805)`,
        error_20806: `Request failed (error code: 20806). Please try it again`,
        error_20808: `Request failed (error code: 20808). Please try it again`,
        error_20901: `Request failed (error code: 20901). Please try it again`,
        error_20902: `Request failed (error code: 20902). Please try it again`,
        error_21000: `Changes are saved`,
        error_21001: `No information is submitted (error code: 21001)`,
        error_21002: `Request failed (error code: 21002). Please try it again`,
        error_21101: `Please input your email (error code: 21101)`,
        error_21102: `Email is not valid (error code: 21102)`,
        error_21103: `Request failed (error code: 21103), plesase retry it again`,
        error_21104: `Email is already connected, please use a new one`,
        error_21105: `Request failed (error code: 21105), plesase retry it again`,
        error_21106: `Failed to send email. Please try it again`,
        error_21201: `Please input your email (error code: 21201)`,
        error_21202: `Please input verification code (error code: 21202)`,
        error_21203: `Email is not valid (error code: 21203)`,
        error_21204: `Error: 21204. Please contact us`,
        error_21205: `Error: 21205. Please contact us`,
        error_21206: `Password above 8 characters is required (error code: 21206)`,
        error_21207: `Request failed (error code: 21207). Please try it again`,
        error_21209: `Request failed (error code: 21209). Please try it again`,
        error_21301: `Please input old password (error code: 21301)`,
        error_21302: `Please create a new password (error code: 21302)`,
        error_21303: `The new password cannot be the same as the old one. (Error: 21303)`,
        error_21304: `Password above 8 characters is required (error code: 21304)`,
        error_21306: `Request failed (error code: 21306). Please try it again`,
        error_21402: `Request failed (error code: 21402). Please try it again`,
        error_21403: `Failed to send verification code. Please resend it`,
        error_21500: `The account has been deleted`,
        error_21501: `Please input verification code (error code: 21501)`,
        error_21502: `Login session has expired (Error: 21502). Please log in again.`,
        error_21503: `Request failed (error code: 21503). Please try it again`,
        error_21505: `Request failed (error code: 21505), plesase retry it`,
        error_21601: `Error: 20601. Please contact us`,
        error_21602: `Invalid Verification (Error: 20602). Please try it again.`,
        error_21603: `Error: 20603. Please try it again`,
        error_21604: `Request failed (error code: 21604). Please try it again`,
        error_21606: `Request failed (error code: 21606). Please try it again`,
        error_21611: `Request failed (error code: 21611). Please try it again`,
        error_21801: `Error: 21801. Please contact us`,
        error_21802: `Request failed (Error: 21802). Please try it again`,
        error_21803: `Error: 21803. Please try it again`,
        error_21804: `Request failed (error code: 21804). Please try it again`,
        error_21806: `Error: 21806. Please try it again`,
        error_21807: `Error: 21807. Please contact us`,
        error_21808: `Error: 21808. Please contact us`,
        error_21809: `Error: 21809. Please contact us`,
        error_21810: `Error: 21810. Please contact us`,
        error_21811: `Error: 21811. Please contact us`,
        error_21812: `Error: 21812. Please contact us`,
        error_21813: `Request failed (error code: 21813). Please try it again`,
        error_21814: `Error: 21814. Please contact us`,
        error_21815: `Request failed (error code: 21815). Please try it again`,
        error_21816: `Error: 21816. Please contact us`,
        error_21817: `Error: 21817. Please contact us`,
        error_21818: `Error: 21818. Please contact us`,
        error_21819: `Request failed (error code: 21819). Please try it again`,
        error_21820: `Error: 21820. Please contact us`,
        error_21821: `Error: 21821. Please contact us`,
        error_21822: `Error: 21822. Please contact us`,
        error_21823: `Request failed (error code: 21823). Please try it again`,
        error_21824: `Request failed (error code: 21824). Please try it again`,
        error_21825: `Request failed (error code: 21825). Please try it again`,
        error_21826: `Request failed (error code: 21826). Please try it again`,
        error_21901: `Error: 21901. Please contact us`,
        error_21902: `Request failed (error code: 21902). Please try it again`,
        error_21903: `The account status has changed (error code: 21903), please refresh the page and try again`,
        error_21904: `Error: 21904. Please try it again`,
        error_21905: `Error: 21905. Please try it again`,
        error_21906: `Request failed (error code: 21906). Please try it again`,
        error_21907: `Google account has been linked to other account`,
        error_21908: `Request failed (error code: 21908). Please try it again`,
        error_22001: `Request failed (error code: 22001). Please try it again`,
        error_22002: `Unlink failed with no additional login`,
        error_22003: `Request failed (error code: 22003). Please try it again`,
        error_22101: `Error: 22101. Please contact us`,
        error_22102: `The account status has changed (error code: 22102), please refresh the page and try again`,
        error_22103: `Request failed (error code: 22103). Please try it again`,
        error_22104: `The account status has changed (error code: 22104), please refresh the page and try again`,
        error_22105: `Error: 22105. Please try it again`,
        error_22106: `Error: 22106. Please try it again`,
        error_22107: `Error: 22107. Please contact us`,
        error_22108: `Request failed (error code: 22108). Please try it again`,
        error_22201: `Error: 22201. Please contact us`,
        error_22202: `Invalid Verification (Error: 22202). Please try it again.`,
        error_22203: `Error: 22203. Please try it again"`,
        error_22204: `Request failed (error code: 22204). Please try it again`,
        error_22206: `Request failed (error code: 22206). Please try it again`,
        error_22401: `Error: 22401. Please contact us`,
        error_22402: `Invalid Verification (Error: 22402). Please try it again.`,
        error_22403: `Error: 22403. Please try it again`,
        error_22404: `Request failed (error code: 22404). Please try it again`,
        error_22405: `Facebook account has been linked to other email`,
        error_22406: `Error: 22406. Please try it again`,
        error_22407: `Error: 22407. Please contact us`,
        error_22408: `Error: 22408. Please contact us`,
        error_22409: `Error: 22409. Please contact us`,
        error_22410: `Error: 224010. Please contact us`,
        error_22411: `Error: 224011. Please contact us`,
        error_22412: `Error: 224012. Please contact us`,
        error_22413: `Error: 22413. Please contact us`,
        error_22414: `Request failed (error code: 22414). Please try it again`,
        error_22415: `Error: 22415. Please contact us`,
        error_22416: `Error: 22416. Please contact us`,
        error_22417: `Error: 22417. Please contact us`,
        error_22418: `Request failed (error code: 22418). Please try it again`,
        error_22419: `Error: 22419. Please contact us`,
        error_22420: `Error: 22420. Please contact us`,
        error_22421: `Error: 22421. Please contact us`,
        error_22422: `Request failed (error code: 22422). Please try it again`,
        error_22423: `Request failed (error code: 22423). Please try it again`,
        error_22424: `Request failed (error code: 22424). Please try it again`,
        error_22425: `Request failed (error code: 22425). Please try it again`,
        error_20098: `Error code: 20098. If you are browsing in Private mode, please switch to Normal mode and try again.`,
        error_22298: `Request for Google failed (Error: 22298). Please try it again.`,
        error_22498: `Request for Facebook failed (Error: 22498). Please try it again.`,
        error_24902: `Request failed (error code: 24902). Please try it again`,
        error_24903: `Request failed (error code: 24903). Please try it again`,
        error_24904: `Request failed (error code: 24904). Please try it again`,
        error_24905: `Request failed (error code: 24905). Please try it again`,
        login_title: `Log in to Tipard`,
        password_placeholder: `Password`,
        log_in: `Log In`,
        no_account: `No account?`,
        create_it: `Create it`,
        or_log_in_with: `Or log in with`,
        passwordless_login: `Passwordless login`,
        log_in_done: `Log In Done`,
        three_rd_account_connect_info: `Congratulations! You have logged in successfully. Now you can connect one email account that is used to log in in the future.`,
        see_my_account: `See my account`,
        three_rd_login_merge_account: `The third-party account email address has been signed up, do you want to connect and log in with this email address directly?`,
        connect_log_in: `Connect & Log In`,
        create_an_account: `Create an account`,
        back_to_log_in: `Back to log in`,
        create_password: `Create Password`,
        create_now: `Create Now`,
        password_login_subtitle: `Passwordless login with email`,
        account_login: `Account Login`,
        rights: `By creating this account, you agree with to <a href="https://co.tipard.com/terms-of-use.html">Terms of Service</a> and  <a href="https://co.tipard.com/policy.html">Privacy Policy</a>`,
        passwordless_login_done: `Passwordless login done`,
        passwordless_login_info: `Congratulations, you have completed passwordless login successfully. You can create a password for this account and log in with the account and password in the future. <a href="/create-password" style="display: initial;">Create Now</a>`,
        sign_up: `Sign Up`,
        register_info: `Create your account`,
        reset_now: `Reset Now`,
        forgot_password: `Forgot Password`,
        reset_password_subtitle: `Use your account email to reset password`,
        plan_products: `Plans & Products`,
        nick_name: `Username:`,
        email: `Email:`,
        my_products: `My Products`,
        my_orders: `My Orders`,
        unlink: `Unlink`,
        link: `Link`,
        connected_accounts: `Connected accounts`,
        last_name: `Last name:`,
        first_name: `First name:`,
        Gender: `Gender:`,
        Birth: `Birth:`,
        Month: `Month`,
        Year: `Year`,
        Country_Region: `Country/Region:`,
        Address: `Address:`,
        Save: `Save`,
        Date: `Date`,
        Male: `Male`,
        Female: `Female`,
        Unspecified: `Unspecified`,
        Security: `Security`,
        change_password: `Change Password`,
        change_now: `Change Now`,
        connect_email: `Connect email`,
        delete_account: `Delete Account`,
        delete_account_info: `When your account is deleted, all the data in My Account associated with your account will be permanently deleted, and you might not be able to recover it. We recommend you tread warily.`,
        Delete: `Delete`,
        Logout: `Logout`,
        my_profile: `My Profile`,
        guides_faqs: `Guides & FAQs`,
        More: `More`,
        guides: `Guides`,
        register: `register`,
        hot_faq: `Hot FAQ`,
        Contents: `Contents:`,
        contact_us: `Contact us>>`,
        plan: `Plan`,
        unregistered: `Unregistered`,
        buy_more: `Purchase More`,
        buy_again: `Buy Again`,
        buy_now: `Buy Now`,
        free_no_limit: `Free & No limit`,
        expired: `Expired`,
        lifetime: `Lifetime`,
        remain: `Remain`,
        day_s: `Day(s)`,
        day: `Day`,
        error_24801: `Request failed (error code: 24801). Please try it again`,
        no_app_found: `No app found！<a href="/">Refresh</a> or <a href="https://co.tipard.com/">Go to the official website</a>`,
        get_more: `Get More >>`,
        edit_photo: `Edit Photo`,
        select_photo: `Select photo`,
        change_photo: `Change photo`,
        cancel: `Cancel`,
        hide_password: `Hide password`,
        show_password: `Show password`,
        zoom_in: `Zoom in`,
        zoom_out: `Zoom out`,
        rotate: `Rotate`,
        horizontal_flip: `Horizontal flip`,
        vertical_flip: `Vertical flip`,
        country: `Country`,
        country_1: `Select your country/region`,
        country_2: `Åaland Island`,
        country_3: `Afghanistan`,
        country_4: `Albania`,
        country_5: `Algeria`,
        country_6: `American Samoa`,
        country_7: `Andorra`,
        country_8: `Angola`,
        country_9: `Anguilla`,
        country_10: `Antarctica`,
        country_11: `Antigua & Barbuda`,
        country_12: `Argentina`,
        country_13: `Armenia`,
        country_14: `Aruba`,
        country_15: `Australia`,
        country_16: `Austria`,
        country_17: `Azerbaijan`,
        country_18: `Bahrain`,
        country_19: `Bangladesh`,
        country_20: `Barbados`,
        country_21: `Belarus`,
        country_22: `Belgium`,
        country_23: `Belize`,
        country_24: `Benin`,
        country_25: `Bermuda`,
        country_26: `Bhutan`,
        country_27: `Bolivia`,
        country_28: `Bosnia & Herzegovina`,
        country_29: `Botswana`,
        country_30: `Bouvet Island`,
        country_31: `Brazil`,
        country_32: `British Indian Ocean Territory`,
        country_33: `British Virgin Islands`,
        country_34: `Brunei`,
        country_35: `Bulgaria`,
        country_36: `Burkina`,
        country_37: `Burundi`,
        country_38: `Cambodia`,
        country_39: `Cameroon`,
        country_40: `Canada`,
        country_41: `Cape Verde`,
        country_42: `Caribbean Netherlands`,
        country_43: `Cayman Islands`,
        country_44: `Central African Republic`,
        country_45: `Chad`,
        country_46: `Chile`,
        country_47: `China`,
        country_48: `Christmas Island`,
        country_49: `Cocos (Keeling) Islands`,
        country_50: `Colombia`,
        country_51: `Cook Islands`,
        country_52: `Costa Rica`,
        country_53: `Côte d'Ivoire`,
        country_54: `Croatia`,
        country_55: `Cuba`,
        country_56: `Cyprus`,
        country_57: `Czech Republic`,
        country_58: `Democratic Republic of the Congo`,
        country_59: `Denmark`,
        country_60: `Djibouti`,
        country_61: `Dominica`,
        country_62: `Dominican Republic`,
        country_63: `Ecuador`,
        country_64: `Egypt`,
        country_65: `El Salvador`,
        country_66: `Equatorial Guinea`,
        country_67: `Eritrea`,
        country_68: `Estonia`,
        country_69: `Ethiopia`,
        country_70: `Falkland Islands`,
        country_71: `Faroe Islands`,
        country_72: `Federated States of Micronesia`,
        country_73: `Fiji`,
        country_74: `Finland`,
        country_75: `France`,
        country_76: `French Guiana`,
        country_77: `French polynesia`,
        country_78: `French Southern Territories`,
        country_79: `Gabon`,
        country_80: `Gambia`,
        country_81: `Georgia`,
        country_82: `Germany`,
        country_83: `Ghana`,
        country_84: `Gibraltar`,
        country_85: `Great Britain (United Kingdom; England)`,
        country_86: `Greece`,
        country_87: `Greenland`,
        country_88: `Grenada`,
        country_89: `Guadeloupe`,
        country_90: `Guam`,
        country_91: `Guatemala`,
        country_92: `Guernsey`,
        country_93: `Guinea`,
        country_94: `Guinea-Bissau`,
        country_95: `Guyana`,
        country_96: `Haiti`,
        country_97: `Heard Island and McDonald Islands`,
        country_98: `Honduras`,
        country_99: `Hong Kong`,
        country_100: `Hungary`,
        country_101: `Iceland`,
        country_102: `India`,
        country_103: `Indonesia`,
        country_104: `Iran`,
        country_105: `Iraq`,
        country_106: `Ireland`,
        country_107: `Isle of Man`,
        country_108: `Israel`,
        country_109: `Italy`,
        country_110: `Jamaica`,
        country_111: `Japan`,
        country_112: `Jersey`,
        country_113: `Jordan`,
        country_114: `Kazakhstan`,
        country_115: `Kenya`,
        country_116: `Kiribati`,
        country_117: `Kuwait`,
        country_118: `Kyrgyzstan`,
        country_119: `Laos`,
        country_120: `Latvia`,
        country_121: `Lebanon`,
        country_122: `Lesotho`,
        country_123: `Liberia`,
        country_124: `Libya`,
        country_125: `Liechtenstein`,
        country_126: `Lithuania`,
        country_127: `Luxembourg`,
        country_128: `Macao`,
        country_129: `Madagascar`,
        country_130: `Malawi`,
        country_131: `Malaysia`,
        country_132: `Maldives`,
        country_133: `Mali`,
        country_134: `Malta`,
        country_135: `Marshall islands`,
        country_136: `Martinique`,
        country_137: `Mauritania`,
        country_138: `Mauritius`,
        country_139: `Mayotte`,
        country_140: `Mexico`,
        country_141: `Moldova`,
        country_142: `Monaco`,
        country_143: `Mongolia`,
        country_144: `Montenegro`,
        country_145: `Montserrat`,
        country_146: `Morocco`,
        country_147: `Mozambique`,
        country_148: `Myanmar (Burma)`,
        country_149: `Namibia`,
        country_150: `Nauru`,
        country_151: `Nepal`,
        country_152: `Netherlands`,
        country_153: `New Caledonia`,
        country_154: `New Zealand`,
        country_155: `Nicaragua`,
        country_156: `Niger`,
        country_157: `Nigeria`,
        country_158: `Niue`,
        country_159: `Norfolk Island`,
        country_160: `North Korea`,
        country_161: `Northern Mariana Islands`,
        country_162: `Norway`,
        country_163: `Oman`,
        country_164: `Pakistan`,
        country_165: `Palau`,
        country_166: `Palestinian territories`,
        country_167: `Panama`,
        country_168: `Papua New Guinea`,
        country_169: `Paraguay`,
        country_170: `Peru`,
        country_171: `Pitcairn Islands`,
        country_172: `Poland`,
        country_173: `Portugal`,
        country_174: `Puerto Rico`,
        country_175: `Qatar`,
        country_176: `Republic of Macedonia (FYROM)`,
        country_177: `Republic of the Congo`,
        country_178: `Réunion`,
        country_179: `Romania`,
        country_180: `Russian Federation`,
        country_181: `Rwanda`,
        country_182: `Saint Barthélemy`,
        country_183: `Saint Martin (France)`,
        country_184: `Saint-Pierre and Miquelon`,
        country_185: `Samoa`,
        country_186: `San Marino`,
        country_187: `Sao Tome & Principe`,
        country_188: `Saudi Arabia`,
        country_189: `Senegal`,
        country_190: `Serbia`,
        country_191: `Seychelles`,
        country_192: `Sierra Leone`,
        country_193: `Singapore`,
        country_194: `Slovakia`,
        country_195: `Slovenia`,
        country_196: `Solomon Islands`,
        country_197: `Somalia`,
        country_198: `South Africa`,
        country_199: `South Georgia and the South Sandwich Isla`,
        country_200: `South Korea`,
        country_201: `South Sudan`,
        country_202: `Spain`,
        country_203: `Sri Lanka`,
        country_204: `St. Helena & Dependencies`,
        country_205: `St. Kitts & Nevis`,
        country_206: `St. Lucia`,
        country_207: `St. Vincent & the Grenadines`,
        country_208: `Sudan`,
        country_209: `Suriname`,
        country_210: `Swaziland`,
        country_211: `Sweden`,
        country_212: `Switzerland`,
        country_213: `Syria`,
        country_214: `Taiwan`,
        country_215: `Tajikistan`,
        country_216: `Tanzania`,
        country_217: `Template:Country data SJM Svalbard`,
        country_218: `Thailand`,
        country_219: `The Bahamas`,
        country_220: `The Comoros`,
        country_221: `The Philippines`,
        country_222: `Timor-Leste (East Timor)`,
        country_223: `Togo`,
        country_224: `Tokelau`,
        country_225: `Tonga`,
        country_226: `Trinidad & Tobago`,
        country_227: `Tunisia`,
        country_228: `Turkey`,
        country_229: `Turkmenistan`,
        country_230: `Turks & Caicos Islands`,
        country_231: `Tuvalu`,
        country_232: `Uganda`,
        country_233: `Ukraine`,
        country_234: `United Arab Emirates`,
        country_235: `United States Minor Outlying Islands`,
        country_236: `United States of America (USA)`,
        country_237: `United States Virgin Islands`,
        country_238: `Uruguay`,
        country_239: `Uzbekistan`,
        country_240: `Vanuatu`,
        country_241: `Vatican City (The Holy See)`,
        country_242: `Venezuela`,
        country_243: `Vietnam`,
        country_244: `Wallis and Futuna`,
        country_245: `Western Sahara`,
        country_246: `Yemen`,
        country_247: `Zambia`,
        country_248: `Zimbabwe`,
        google_login: `Sign in with Google`,
        State: `State`,
        Activation_code: `Activation code`,
        Question: `List all the apps you have logged in`,
        Copy_complete: `Copy complete`,
        change_password_success: `Password changed successfully`,
        successful_login_title: `Login Success`,
        product_page: `Product Page>>`,
        successful_login_info: `Login completed. Please close the current page and go back to the original tab to continue with the following process. The current page will be closed automatically in 5 seconds. If auto-closing or closing by clicking the "Done" button fails, please close this tab directly.`,
        successful_login_info_firefox: `Login completed. Please close the current page and go back to the original tab to continue with the following process.`,
        my_account: `My Account`,
        my_history: `My History`,
        remove_watermark: `Remove Watermark`,
        no_history: `No History`,
        history_all: `Select all`,
        history_open: `Open`,
        history_down: `Download`,
        history_delete: `Delete`,
        history_clear: `Clear invalid`,
        images: `image(s)`,
        use_this_function: `Use This Product>>`,
        hd_downloading: `HD original images downloading benefits:`,
        lifetimeRemaining: `Lifetime-Remaining`,
        Remaining: `Remaining`,
        email_verification: `Email Verification`,
        email_verification_info: `We have sent the verification message to your email <span class="email"></span>, and please finish the verification. After verifying, the benefits will be in sync automatically.`,
        wrong_email: `Wrong email address?`,
        click_here_to_modify: `Click here to modify`,
        get_more_help: `Get more help?`,
        click_here: `Click here`,
        email_verification_info_success: `Congratulations for verifying your email account.`,
        email_verification_info_error: `Verification failed because of the expired link.`,
        registration_succeeded: `Registration Succeeded`,
        registration_succeeded_info_1: `Congratulations! You have registered successfully. We have sent the verification message to your email <span class="email"></span>, and please finish verifying to use the benefits of this email.`,
        registration_succeeded_info_2: `Click "Done" to return to the Home page and use this product.`,
        registration_succeeded_info_3: `Click "Done" to close the current page and return to the Home page for the following operations. If closing fails, please manually close the tab.`,
        verify_email: `Verify Email`,
        registered_email_not_verified: `The registered email has not been verified, please verify it at once.`,
        email_verification_time_1: `Didn't receive a verification email?`,
        email_verification_time_2: `After`,
        email_verification_time_3: `Click here to resend it`,
        error_26301: `Error code: 26301, please contact us`,
        error_26302: `Error code: 26302, please contact us`,
        error_26303: `Email format error (error code: 26303). Please enter it again`,
        error_26304: `Password above 8 characters is recommended (error code: 26304)`,
        error_26305: `Reuqest failed (error code: 26305). Please retry it`,
        error_26306: `Email has been registered, please <a href="https://account.tipard.com/login">go to log in</a>`,
        error_26307: `Reuqest failed (error code: 26307). Please retry it`,
        error_26308: `Reuqest failed (error code: 26308). Please retry it`,
        error_26401: `Error code: 26401, please retry it`,
        error_26402: `Email has been verified (error code: 26402), please retry it`,
        error_26403: `Reuqest failed (error code: 26403). Please retry it`,
        error_26404: `Request failed (error code: 26404). Please retry it`,
        error_26501: `Error code: 26501, please contact us`,
        error_26502: `Error code: 26502, please contact us`,
        error_26503: `Email format error (error code: 26503). Please enter it again`,
        error_26504: `Reuqest failed (error code: 26504). Please retry it`,
        error_26505: `Email has not been registered, please <a href="https://account.tipard.com/register">register it first</a>`,
        error_26506: `Email has been verified.`,
        error_26507: `Reuqest failed (error code: 26507). Please retry it`,
        error_26508: `Verification fialed (error code: 26508), please retry it`,
        error_26509: `Request failed (error code: 26509), please retry it`,
        error_26510: `Error code: 26510, please contact us`,
        error_26601: `Error code: 26601, please contact us`,
        error_26602: `Error code: 26602, please contact us`,
        error_26603: `Reuqest failed (error code: 26603). Please retry it`,
        error_26604: `Error code: 26604, please contact us`,
        error_26605: `Error code: 26605, please contact us`,
        error_26701: `Error code: 26701, please contact us`,
        error_26702: `Reuqest failed (error code: 26702). Please retry it`,
        error_26703: `Error code: 26703, please contact us`,
        error_26704: `Error code: 26704, please contact us`,
        error_26705: `Wait for logging in (error code: 26705). Please retry it`,
        no_cookie: `You have turned on the Block all cookies function in your browser, so you cannot log in. Please go to Settings to check the box of Allow all cookies.`,
        error_26801: `Error code: 26801, please contact us`,
        error_26802: `Error code: 26802, please contact us`,
        error_26803: `Request failed (error code: 26803). Please retry it`,
        error_26804: `Request failed (error code: 26804). Please retry it`,
        error_order: `Request failed (error code: 27098), please try it again!`,
        error_order1: `The order query is incomplete（error code: `,
        error_order2: `）, please refresh and try it again.`,
        modify_email_title: `Modify Email`,
        modify_email_info: `You can use the modified email to log into your account.`,
        images_per: `Images per`,
        error_26101: `Error: 26101. Please contact us`,
        error_26102: `Error: 26102. Please contact us`,
        error_26103: `Request failed (error code:26103). Please try it again`,
        error_26104: `Error code: 26104, plesase retry it`,
        error_26105: `Error code: 26105, plesase retry it`,
        error_26106: `Delete failed (error code: 26106). Please try it again`,
        error_26201: `Error: 26201. Please contact us`,
        error_26202: `Request failed (error code:26202). Please try it again`,
        error_26001: `Error: 26001. Please contact us`,
        error_26002: `Error: 26002. Please contact us`,
        error_26003: `Error: 26003. Please contact us`,
        error_26004: `Error: 26004. Please contact us`,
        error_26005: `Request failed (error code:26005). Please try it again`,
        error_26006: `Error code: 26006, plesase retry it`,
        error_26008: `Error: 26008. Please contact us`,
        go_to_the_home_page: `Go to the Home page`,
        error_27101: `Request failed (error code: 27101). Please retry ist`,
        error_27201: `Error code: 27201, please contact us`,
        error_27202: `Error code: 27202, please retry it`,
        error_27203: `Request failed (error code: 27203). Please retry it`,
        error_27204: `Invalid code (error code: 27204).`,
        error_27205: `Request failed (error code: 27205). Please retry it`,
        error_27206: `Request failed (error code: 27206). Please retry it`,
        error_27207: `Request failed (error code: 27207). Please retry it`,
        no_history_found: `You haven't used any tool! <a href="/">Refresh</a> or <a href="https://co.tipard.com/">Go to the official website</a>`,
        error_25301: `Error: 25301. Please contact us`,
        error_25302: `Error: 25302. Please contact us`,
        error_25303: `Request failed (error code: 25303). Please try it again`,
        error_25304: `Request failed (error code: 25304). Please try it again`,
        error_25305: `Request failed (error code: 25305). Please try it again`,
        error_25306: `Request failed (error code: 25306). Please try it again`,
        image_upscaler_p: `Image without watermark downloading benefits:`,
        Available_for: `Available for:`,
        credit_per: `%s credit(s) per HD image`,
        still_valid: `Purchased plan(s) is still valid`,
        credit: `credit(s)`,
        pc_3rd_info: `Login successfully. Please go to the application for further operation.`,
        use_online: `Use the online service`,
        use_download: `Use the desktop program`,
        use_immediately: `Use Immediately`,
        Use_in_browser: `Use in Browser`,
        win_desktop: `Windows`,
        Mac_desktop: `Mac`,
        credits_per: `{%} Credits per Month`,
        expire: `Expiration Time:`,
        viewDetails: `View details`,
        viewHistory: `View history benefits>>`,
        viewDetailsInfo: `Notice: If the subscription benefits are renewed within 7 days after expiration, the unused benefits can continue to be used. Also, the expiration time will be automatically updated to the expiration time of the new subscription. If there is no new subscription after 7 days of expiration time, all expired benefits will be cleared.`,
        "connect_account": "Bind Email to Your Account",
        "connect_account_info": "After binding, you can log in with this email address.",
        "connect_now": "Bind Now",
        "no_email_bind": "No email bind",
        "bind_email": "Bind Email",
        "connect_your_email_placeholder": "Please enter your email address",
        "bind_an_email": "Bind an Email",
        "bind_info": "You have logged in successfully. Please bind an email to your account to activate your benefits.",
        "bind_later": "Bind later",
        "footer": "Copyright © 2024 Tipard Studio. All Rights Reserved.",
        "overtime": "Error code: {%}, plesase retry it",
        "hi": "Hi, %s!",
        "Personal_Information": "Personal Information",
        "Access": "Access",
        "Subscription_Plan": "(Subscription Plan)",
        "No_orders": "No orders found.",
        "No_data": "No data",
        "Featured_Products": "Featured Products",
        "More_Products": "More Products",
        "Free_Download": "Free Download",
        "Get_Started": "Get Started",
        "Subscribe": "Subscribe",
        "Verified": "Verified",
        "back_to_account_center": "Back to Account Center",
        "success": "Success!",
        "successfully": "You have successfully registered an account.",
        "Continue": "Continue",
        "Already": "Already have an account?",
        "loading_verification": "Checking verification status...",
        "email_no_verification": "Sorry, the registered email address has not been verified. Please complete the verification according to the above instructions and click the \"Done\" button again to complete the registration.",
        "error_20505": "The email has been linked",
        "error_20506": "Error: 20506. Please contact us",
        "error_28401": "Please input your email",
        "error_28402": "Incorrect email format",
        "error_28403": "Error: 28403. Please contact us",
        "error_28404": "The email has been signed up",
        "error_28405": "Error: 28405. Please contact us",
        "error_28406": "The email has been linked",
        "error_28407": "Error: 28407. Please contact us",
        "error_28408": "Failed to send the email",
        "error_28601": "Please input your email",
        "error_28602": "Please input the verification code",
        "error_28603": "Incorrect email format",
        "error_28604": "Error: 28604. Please contact us",
        "error_28605": "The verification code is incorrect",
        "error_28606": "Error: 28606. Please contact us",
        "error_28607": "The email has been signed up",
        "error_28608": "There is no email bound to your account",
        "error_28609": "The email is not verified",
        "error_28610": "Error: 28610. Please contact us",
        "error_28611": "The email has been linked",
        "error_28612": "Error: 28612. Please contact us",
        "unbind_email_btn": "Unlink the email",
        "binding_exchange": "Bind Email",
        "Subscription_Status": "Subscription Status",
        "mask_unbind_info_1": "Are you sure to unlink {%} from this account? After unlinking, you cannot check the the benefits of this email any more",
        "mask_unbind_info_2": "Are you sure you want to unbind all PCs associated with this registration code?<a href=\"https://www.tipard.com/faq.html#unbind\">Learn more about changing devices.</a>",
        "error_dc_link": "Error,Please contact us",
        "vcu_state": "Full Version Benefits:",
        "monthly_subscription": "1 Month License",
        "year_subscription": "1 Year License",
        "to": "to",
        "lifetime_subscription": "Lifetime License",
        "unbind": "Confirm",
        "unbind_success": "Unbinded successfully",
        "error_28702": "Error: 28702. Please contact us",
        "error_28703": "The verification code is incorrect",
        "error_28704": "Error: 28704. Please contact us",
        "error_28705": "The email hasn't been linked",
        "error_28706": "Please input the verification code",
        "error_28707": "Failed to unlink",
        "error_28901": "Error: 28901. Please contact us",
        "error_28902": "Error: 28902. Please contact us",
        "error_28903": "Error: 28903. Please contact us",
        "error_28904": "Error: 28904. Please contact us",
        "error_28905": "Error: 28905. Please contact us",
        "error_28906": "Error: 28906. Please contact us",
        "error_28907": "Invalid registration code",
        "error_28908": "Error: 28908. Please contact us",
        "error_28909": "Currently in the unbind cooldown period. You can only unbind once a month.",
        "error_289010": "Error: 289010. Please contact us",
        "error_289011": "Error: 289011. Please contact us",
        "error_289012": "Error: 289012. Please contact us",
        "activated_computer": "Activated on %s/%s computer(s)",
        "bind_email_info": "The benefits of the following emails will be bound and used under this VIP account.",
        "error_26309": "Error: 26309. Please contact us",
        "error_26310": "Invalid referral code",
        "error_26311": "Error: 26311. Please contact us",
        "error_26312": "Invalid referral code",
        "error_26313": "Error: 26313. Please contact us",
        "error_26314": "The email has been linked",
        "activate_unbinding": "Change Device",
        "copy_registration_code": "Copy registration code",
        "show_registration_code": "Show registration code",
        "hide_registration_code": "Hide registration code",
        "other_binding_email": "Other binding email(s)",
        "home": "Home",
        "points_balance": "Points balance:",
        "earn_now": "Earn Now >>",
        "earn_more": "Earn More FREE Points, Redeem More Gifts",
        "earn_or_redeem_points": "Earn/Redeem points",
        "unbind_email_info": "The current account has been associated with an email address",
        "bind_email_title": "If you want to associate more orders for purchasing email addresses, please click the \"Email Association\" button below to associate.",
        "bind_email_tip": "<span>Tip:</span> Currently only purchases of email registration code orders are supported.",
        "email_association": "Email Association",
        "email_disassociation": "Dissociation",
        "back": "Back",
        "activate_benefits": "Please enter the purchase email that needs to be associated to obtain the verification code. Enter the verification code received in the email in the verification code input box, and click the \"Verify Activation\" button to activate the benefits.",
        "e_mail": "E-Mail:",
        "verification_code": "Verification Code:",
        "obtain_verification_code": "Obtain verification code",
        "verification_association": "Verify association",
        "current_password": "Current Password:",
        "account_center": "Account Center",
        "new_password": "New Password:",
        "confirm_password": "Confirm New Password:",
        "Send": "Send",
        "history_files": "History Files",
        "delete_account_info_1": "1. Deleting your Tipard account is a permanent action and cannot be undone. All your data, including photos and preferences, will be lost. Are you sure to delete your Tipard account?",
        "delete_account_info_2": "2. If you decide to delete the Tipard account, please click on the Send button and enter the verification code from the email we sent you.",
        "best_deals": "Best Deals",
        "close": "Close",
        "product":"Product:"
    },
    cs: {
        overtime_1: `Chybový kód:`,
        overtime_2: `, plesase, zkus to znovu`,
        isnetwork: `Chyba internetu. Zkontrolujte a zkuste to znovu`,
        email_placeholder: `E-mailem`,
        email_empty: `Zadejte prosím e -mail`,
        email_not_valid: `E -mail není platný`,
        email_not_valid_1: `Zadejte prosím svůj e -mail`,
        email_not_valid_2: `E -mail není platný, použijte prosím jinou adresu.`,
        email_not_valid_3: `Žádné zadávání e -mailů`,
        password_placeholder: `Heslo`,
        password_empty: `Zadejte heslo`,
        password_not_valid: `Nesprávný účet nebo heslo`,
        password_not_valid_1: `Je vyžadováno heslo nad 8 znaků`,
        password_not_valid_2: `Vytvořte si heslo`,
        password_placeholder_1: `Vytvořte vaše heslo`,
        password_placeholder_2: `Potvrzení hesla`,
        password_placeholder_3: `Vytvořte nové heslo`,
        password_placeholder_4: `Potvrďte nové heslo`,
        password_placeholder_5: `Zadejte staré heslo`,
        copy_password_empty: `Potvrďte prosím heslo`,
        copy_password_not_valid: `Potvrďte prosím své heslo`,
        copy_passwords_inconsistent: `Potvrzení vašeho hesla se neshoduje`,
        code_empty: `Zadejte prosím ověřovací kód`,
        code_not_valid: `neplatný ověřovací kód`,
        code_placeholder: `Ověřovací kód`,
        not_received_code: `Pokud vaše poštovní schránka delší dobu neobdržela ověřovací kód, získejte prosím ověřovací kód znovu.`,
        get_first_code: `Nejprve prosím získejte ověřovací kód.`,
        last_name_placeholder: `Prosím zadejte své příjmení`,
        first_name_placeholder: `Prosím zadej své křestní jméno`,
        address_placeholder: `Zadejte prosím svoji adresu`,
        no_code_text: `Odeslali jsme ověřovací kód. Zadejte prosím svůj kód. <span class='tips'>Nedostali jste kód?`,
        no_code_text_1: `1. Ujistěte se, že je e -mailová adresa platná a může přijímat e -maily.`,
        no_code_text_2: `2. E -mail je automaticky odesílán systémem a může být označen jako nevyžádaná nebo nevyžádaná pošta. Zkontrolujte, zda je e -mail ve složce Koš.`,
        no_code_text_3: `3. Nemůžete vyřešit svůj problém?`,
        no_code_text_3_span: `Pak nás kontaktujte kliknutím sem.`,
        order_no: `Nezakoupili jste žádné produkty. Jakékoli dotazy prosím <a href="https://cs.tipard.com/store.html">Nakupujte nyní</a> Nebo <a href="https://cs.tipard.com/contact.html"> Kontaktujte nás</a>`,
        error_24901: `K běžnému účtu není připojen žádný e -mail a nelze najít objednávky. Připojte prosím e -mail.`,
        user_guide: `Uživatelská příručka >>`,
        download: `Stažení`,
        order_number: `Číslo objednávky`,
        Refund: `Vrácení peněz`,
        Disabled: `Zakázáno`,
        Normal: `Normální`,
        Modify: `Upravit`,
        Modify_1: `Upravit >>`,
        Connect: `Připojit`,
        unlink_success: `Úspěšně odpojit`,
        connect_success: `Připojte se úspěšně`,
        feedback_title: `Děkujeme za vaši odezvu. Zanechte prosím svůj problém a my vám odpovíme do 24 hodin.`,
        feedback_thank_you: `Děkujeme! <br /> Vaše zpětná vazba byla úspěšně odeslána.`,
        feedback_email: `Sem zadejte svůj e -mail!`,
        feedback_content: `Zde zanechte jakýkoli problém nebo návrh, se kterým jste se setkali.`,
        feedback_submit: `Předložit`,
        form_contents: `Nezadali jste žádný popis. Zadejte jej a odešlete znovu.`,
        old_password: `Zadejte prosím staré heslo`,
        new_password: `Vytvořte si prosím nové heslo`,
        old_new_password: `Nové heslo nemůže být stejné jako staré`,
        incorrect_password: `Nesprávné heslo`,
        delete_no: `Odstranit nyní`,
        Caps: `Caps Lock je zapnutý`,
        Get: `Dostat`,
        Done: `Hotovo`,
        error_20001: `Chyba: 20001. Přihlaste se prosím znovu.`,
        error_20002: `Chyba: 20002. Přihlaste se prosím znovu.`,
        error_20003: `Chyba: 20003. Přihlaste se prosím znovu.`,
        error_20004: `Požadavek se nezdařil (kód chyby: 20004). Zkuste to prosím znovu.`,
        error_20005: `Platnost relace přihlášení vypršela (chyba: 20005). Přihlaste se prosím znovu.`,
        error_20006: `Požadavek se nezdařil (kód chyby: 20006). Zkuste to prosím znovu.`,
        error_20007: `Platnost relace přihlášení vypršela (chyba: 20007). Přihlaste se prosím znovu.`,
        error_20008: `Platnost relace přihlášení vypršela (chyba: 20008). Přihlaste se prosím znovu.`,
        error_20009: `Platnost relace přihlášení vypršela (chyba: 20009). Přihlaste se prosím znovu.`,
        error_20101: `Zadejte prosím svůj e -mail (kód chyby: 20101)`,
        error_20102: `E -mail není platný (kód chyby: 20102)`,
        error_20103: `Požadavek se nezdařil (kód chyby: 20103). Zkuste to prosím znovu`,
        error_20104: `E -mail se již používá. <a href="https://account.tipard.com/login"> Přihlaste se </a> nebo se zaregistrujte pomocí nového`,
        error_20105: `Požadavek se nezdařil (kód chyby: 20105). Zkuste to prosím znovu`,
        error_20106: `Odeslání e -mailu se nezdařilo, zkuste to prosím znovu`,
        error_20201: `Zadejte prosím svůj e -mail (kód chyby: 20201)`,
        error_20202: `Zadejte prosím heslo (kód chyby: 20202)`,
        error_20203: `Zadejte prosím ověřovací kód (kód chyby: 20203)`,
        error_20204: `E -mail není platný (kód chyby: 20204)`,
        error_20205: `Je vyžadováno heslo nad 8 znaků (kód chyby: 20205)`,
        error_20206: `Požadavek se nezdařil (kód chyby: 20206). Zkuste to prosím znovu`,
        error_20207: `neplatný ověřovací kód`,
        error_20208: `Požadavek se nezdařil (kód chyby: 20208). Zkuste to prosím znovu`,
        error_20209: `Požadavek se nezdařil (kód chyby: 20209). Zkuste to prosím znovu`,
        error_20301: `Zadejte prosím svůj e -mail (kód chyby: 20301)`,
        error_20302: `Chyba: 20302. Kontaktujte nás`,
        error_20303: `E -mail není platný (kód chyby: 20303)`,
        error_20304: `Požadavek se nezdařil (kód chyby: 20304). Zkuste to prosím znovu`,
        error_20305: `Účet neexistuje. Nejprve prosím zadejte znovu nebo se <a href="https://account.tipard.com/register">Vytvořit to</a>.`,
        error_20306: `Zatím žádné heslo. Použijte <a href="https://account.tipard.com/passwordless-login"> přihlášení bez hesla </a> nebo <a href="create-password"> nastavení hesla </a> a přihlaste se.`,
        error_20308: `Požadavek se nezdařil (kód chyby: 20308). Zkuste to prosím znovu`,
        error_20401: `Odhlášení se nezdařilo (kód chyby: 20401). Zkuste to prosím znovu`,
        error_20501: `Zadejte prosím svůj e -mail (kód chyby: 20501)`,
        error_20502: `E -mail není platný (kód chyby: 20502)`,
        error_20503: `Požadavek se nezdařil (kód chyby: 20503). Zkuste to prosím znovu`,
        error_20504: `Odeslání e -mailu se nezdařilo. Zkuste to prosím znovu.`,
        error_20601: `Zadejte prosím svůj e -mail (kód chyby: 20601)`,
        error_20602: `Zadejte prosím ověřovací kód (kód chyby: 20602)`,
        error_20603: `E -mail není platný (kód chyby: 20603)`,
        error_20604: `Požadavek se nezdařil (kód chyby: 20604). Zkuste to prosím znovu`,
        error_20606: `Požadavek se nezdařil (kód chyby: 20606). Zkuste to prosím znovu`,
        error_20607: `Požadavek se nezdařil (kód chyby: 20607). Zkuste to prosím znovu`,
        error_20608: `Požadavek se nezdařil (kód chyby: 20608). Zkuste to prosím znovu`,
        error_20701: `Zadejte prosím svůj e -mail (kód chyby: 20701)`,
        error_20702: `E -mail není platný (kód chyby: 20702)`,
        error_20703: `Požadavek se nezdařil (kód chyby: 20703). Zkuste to prosím znovu`,
        error_20704: `Účet neexistuje. Nejprve prosím zadejte znovu nebo se <a href="https://account.tipard.com/register">Vytvořit to</a>.`,
        error_20705: `Požadavek se nezdařil (kód chyby: 20705). Zkuste to prosím znovu`,
        error_20706: `Odeslání e -mailu se nezdařilo. Zkuste to prosím znovu`,
        error_20801: `Zadejte prosím svůj e -mail (kód chyby: 20801)`,
        error_20802: `Chyba: 20802. Kontaktujte nás`,
        error_20803: `Zadejte prosím ověřovací kód (kód chyby: 20803)`,
        error_20804: `E -mail není platný (kód chyby: 20804)`,
        error_20805: `Je vyžadováno heslo nad 8 znaků (kód chyby: 20805)`,
        error_20806: `Požadavek se nezdařil (kód chyby: 20806). Zkuste to prosím znovu`,
        error_20808: `Požadavek se nezdařil (kód chyby: 20808). Zkuste to prosím znovu`,
        error_20901: `Požadavek se nezdařil (kód chyby: 20901). Zkuste to prosím znovu`,
        error_20902: `Požadavek se nezdařil (kód chyby: 20902). Zkuste to prosím znovu`,
        error_21000: `Změny se uloží`,
        error_21001: `Nebyly odeslány žádné informace (kód chyby: 21001)`,
        error_21002: `Požadavek se nezdařil (kód chyby: 21002). Zkuste to prosím znovu`,
        error_21101: `Zadejte prosím svůj e -mail (kód chyby: 21101)`,
        error_21102: `E -mail není platný (kód chyby: 21102)`,
        error_21103: `Požadavek se nezdařil (kód chyby: 21103), plesase to zkuste znovu`,
        error_21104: `E -mail je již připojen, použijte prosím nový`,
        error_21105: `Požadavek se nezdařil (kód chyby: 21105), plesase to zkuste znovu`,
        error_21106: `Odeslání e -mailu se nezdařilo. Zkuste to prosím znovu`,
        error_21201: `Zadejte prosím svůj e -mail (kód chyby: 21201)`,
        error_21202: `Zadejte prosím ověřovací kód (kód chyby: 21202)`,
        error_21203: `E -mail není platný (kód chyby: 21203)`,
        error_21204: `Chyba: 21204. Kontaktujte nás`,
        error_21205: `Chyba: 21205. Kontaktujte nás`,
        error_21206: `Je vyžadováno heslo nad 8 znaků (kód chyby: 21206)`,
        error_21207: `Požadavek se nezdařil (kód chyby: 21207). Zkuste to prosím znovu`,
        error_21209: `Požadavek se nezdařil (kód chyby: 21209). Zkuste to prosím znovu`,
        error_21301: `Zadejte prosím staré heslo (kód chyby: 21301)`,
        error_21302: `Vytvořte prosím nové heslo (kód chyby: 21302)`,
        error_21303: `Nové heslo nemůže být stejné jako staré. (Chyba: 21303)`,
        error_21304: `Je vyžadováno heslo nad 8 znaků (kód chyby: 21304)`,
        error_21306: `Požadavek se nezdařil (kód chyby: 21306). Zkuste to prosím znovu`,
        error_21402: `Požadavek se nezdařil (kód chyby: 21402). Zkuste to prosím znovu`,
        error_21403: `Odeslání ověřovacího kódu se nezdařilo. Pošlete to prosím znovu`,
        error_21500: `Účet byl smazán`,
        error_21501: `Zadejte prosím ověřovací kód (kód chyby: 21501)`,
        error_21502: `Platnost relace přihlášení vypršela (chyba: 21502). Přihlaste se prosím znovu.`,
        error_21503: `Požadavek se nezdařil (kód chyby: 21503). Zkuste to prosím znovu`,
        error_21505: `Požadavek se nezdařil (kód chyby: 21505), plesase to zkuste znovu`,
        error_21601: `Chyba: 20601. Kontaktujte nás`,
        error_21602: `Neplatné ověření (chyba: 20602). Zkuste to prosím znovu.`,
        error_21603: `Chyba: 20603. Zkuste to prosím znovu`,
        error_21604: `Požadavek se nezdařil (kód chyby: 21604). Zkuste to prosím znovu`,
        error_21606: `Požadavek se nezdařil (kód chyby: 21606). Zkuste to prosím znovu`,
        error_21611: `Požadavek se nezdařil (kód chyby: 21611). Zkuste to prosím znovu`,
        error_21801: `Chyba: 21801. Kontaktujte nás`,
        error_21802: `Požadavek se nezdařil (chyba: 21802). Zkuste to prosím znovu`,
        error_21803: `Chyba: 21803. Zkuste to prosím znovu`,
        error_21804: `Požadavek se nezdařil (kód chyby: 21804). Zkuste to prosím znovu`,
        error_21806: `Chyba: 21806. Zkuste to prosím znovu`,
        error_21807: `Chyba: 21807. Kontaktujte nás`,
        error_21808: `Chyba: 21808. Kontaktujte nás`,
        error_21809: `Chyba: 21809. Kontaktujte nás`,
        error_21810: `Chyba: 21810. Kontaktujte nás`,
        error_21811: `Chyba: 21811. Kontaktujte nás`,
        error_21812: `Chyba: 21812. Kontaktujte nás`,
        error_21813: `Požadavek se nezdařil (kód chyby: 21813). Zkuste to prosím znovu`,
        error_21814: `Chyba: 21814. Kontaktujte nás`,
        error_21815: `Požadavek se nezdařil (kód chyby: 21815). Zkuste to prosím znovu`,
        error_21816: `Chyba: 21816. Kontaktujte nás`,
        error_21817: `Chyba: 21817. Kontaktujte nás`,
        error_21818: `Chyba: 21818. Kontaktujte nás`,
        error_21819: `Požadavek se nezdařil (kód chyby: 21819). Zkuste to prosím znovu`,
        error_21820: `Chyba: 21820. Kontaktujte nás`,
        error_21821: `Chyba: 21821. Kontaktujte nás`,
        error_21822: `Chyba: 21822. Kontaktujte nás`,
        error_21823: `Požadavek se nezdařil (kód chyby: 21823). Zkuste to prosím znovu`,
        error_21824: `Požadavek se nezdařil (kód chyby: 21824). Zkuste to prosím znovu`,
        error_21825: `Požadavek se nezdařil (kód chyby: 21825). Zkuste to prosím znovu`,
        error_21826: `Požadavek se nezdařil (kód chyby: 21826). Zkuste to prosím znovu`,
        error_21901: `Chyba: 21901. Kontaktujte nás`,
        error_21902: `Požadavek se nezdařil (kód chyby: 21902). Zkuste to prosím znovu`,
        error_21903: `Stav účtu se změnil (kód chyby: 21903), obnovte stránku a zkuste to znovu`,
        error_21904: `Chyba: 21904. Zkuste to prosím znovu`,
        error_21905: `Chyba: 21905. Zkuste to prosím znovu`,
        error_21906: `Požadavek se nezdařil (kód chyby: 21906). Zkuste to prosím znovu`,
        error_21907: `Účet Google byl propojen s jiným účtem`,
        error_21908: `Požadavek se nezdařil (kód chyby: 21908). Zkuste to prosím znovu`,
        error_22001: `Požadavek se nezdařil (kód chyby: 22001). Zkuste to prosím znovu`,
        error_22002: `Odpojení se nezdařilo bez dalšího přihlášení`,
        error_22003: `Požadavek se nezdařil (kód chyby: 22003). Zkuste to prosím znovu`,
        error_22101: `Chyba: 22101. Kontaktujte nás`,
        error_22102: `Stav účtu se změnil (kód chyby: 22102), obnovte stránku a zkuste to znovu`,
        error_22103: `Požadavek se nezdařil (kód chyby: 22103). Zkuste to prosím znovu`,
        error_22104: `Stav účtu se změnil (kód chyby: 22104), obnovte stránku a zkuste to znovu`,
        error_22105: `Chyba: 22105. Zkuste to prosím znovu`,
        error_22106: `Chyba: 22106. Zkuste to prosím znovu`,
        error_22107: `Chyba: 22107. Kontaktujte nás`,
        error_22108: `Požadavek se nezdařil (kód chyby: 22108). Zkuste to prosím znovu`,
        error_22201: `Chyba: 22201. Kontaktujte nás`,
        error_22202: `Neplatné ověření (chyba: 22202). Zkuste to prosím znovu.`,
        error_22203: `Chyba: 22203. Zkuste to prosím znovu "`,
        error_22204: `Požadavek se nezdařil (kód chyby: 22204). Zkuste to prosím znovu`,
        error_22206: `Požadavek se nezdařil (kód chyby: 22206). Zkuste to prosím znovu`,
        error_22401: `Chyba: 22401. Kontaktujte nás`,
        error_22402: `Neplatné ověření (chyba: 22402). Zkuste to prosím znovu.`,
        error_22403: `Chyba: 22403. Zkuste to prosím znovu`,
        error_22404: `Požadavek se nezdařil (kód chyby: 22404). Zkuste to prosím znovu`,
        error_22405: `Účet na Facebooku byl propojen s jiným e -mailem`,
        error_22406: `Chyba: 22406. Zkuste to prosím znovu`,
        error_22407: `Chyba: 22407. Kontaktujte nás`,
        error_22408: `Chyba: 22408. Kontaktujte nás`,
        error_22409: `Chyba: 22409. Kontaktujte nás`,
        error_22410: `Chyba: 224010. Kontaktujte nás`,
        error_22411: `Chyba: 224011. Kontaktujte nás`,
        error_22412: `Chyba: 224012. Kontaktujte nás`,
        error_22413: `Chyba: 22413. Kontaktujte nás`,
        error_22414: `Požadavek se nezdařil (kód chyby: 22414). Zkuste to prosím znovu`,
        error_22415: `Chyba: 22415. Kontaktujte nás`,
        error_22416: `Chyba: 22416. Kontaktujte nás`,
        error_22417: `Chyba: 22417. Kontaktujte nás`,
        error_22418: `Požadavek se nezdařil (kód chyby: 22418). Zkuste to prosím znovu`,
        error_22419: `Chyba: 22419. Kontaktujte nás`,
        error_22420: `Chyba: 22420. Kontaktujte nás`,
        error_22421: `Chyba: 22421. Kontaktujte nás`,
        error_22422: `Požadavek se nezdařil (kód chyby: 22422). Zkuste to prosím znovu`,
        error_22423: `Požadavek se nezdařil (kód chyby: 22423). Zkuste to prosím znovu`,
        error_22424: `Požadavek se nezdařil (kód chyby: 22424). Zkuste to prosím znovu`,
        error_22425: `Požadavek se nezdařil (kód chyby: 22425). Zkuste to prosím znovu`,
        error_20098: `Kód chyby: 20098. Pokud procházíte v soukromém režimu, přepněte prosím do normálního režimu a zkuste to znovu.`,
        error_22298: `Požadavek na Google se nezdařil (chyba: 22298). Zkuste to prosím znovu.`,
        error_22498: `Žádost o Facebook se nezdařila (chyba: 22498). Zkuste to prosím znovu.`,
        error_24902: `Požadavek se nezdařil (kód chyby: 24902). Zkuste to prosím znovu`,
        error_24903: `Požadavek se nezdařil (kód chyby: 24903). Zkuste to prosím znovu`,
        error_24904: `Požadavek se nezdařil (kód chyby: 24904). Zkuste to prosím znovu`,
        error_24905: `Požadavek se nezdařil (kód chyby: 24905). Zkuste to prosím znovu`,
        login_title: `Přihlaste se do Tipard`,
        password_placeholder: `Heslo`,
        log_in: `Přihlásit se`,
        no_account: `Žádný účet?`,
        create_it: `Vytvořit to`,
        or_log_in_with: `Nebo se přihlaste pomocí`,
        passwordless_login: `Přihlášení bez hesla`,
        log_in_done: `Přihlásit se Hotovo`,
        three_rd_account_connect_info: `Gratulujeme! Úspěšně jste se přihlásili. Nyní můžete připojit jeden e -mailový účet, který slouží k budoucímu přihlášení.`,
        see_my_account: `Viz můj účet`,
        three_rd_login_merge_account: `E-mailová adresa účtu třetí strany byla zaregistrována. Chcete se připojit a přihlásit se pomocí této e-mailové adresy přímo?`,
        connect_log_in: `Připojte se a přihlaste se`,
        create_an_account: `Vytvořit účet`,
        back_to_log_in: `Zpět k přihlášení`,
        create_password: `Vytvořit heslo`,
        create_now: `Vytvořit hned`,
        password_login_subtitle: `Přihlášení bez hesla pomocí e -mailu`,
        account_login: `Přihlášení k účtu`,
        rights: `Vytvořením tohoto účtu souhlasíte s <a href="https://cs.tipard.com/terms.html"> smluvními podmínkami </a> a <a href="https://cs.tipard.com/policy.html"> Zásady ochrany osobních údajů </a>`,
        passwordless_login_done: `Přihlášení bez hesla dokončeno`,
        passwordless_login_info: `Gratulujeme, úspěšně jste dokončili přihlášení bez hesla. Pro tento účet můžete vytvořit heslo a v budoucnu se pomocí účtu a hesla přihlásit. <a href="/create-password" style="display: initial;"> Vytvořit nyní </a>`,
        sign_up: `Přihlásit se`,
        register_info: `Vytvořte si účet`,
        reset_now: `Resetovat nyní`,
        forgot_password: `Zapomenuté heslo`,
        reset_password_subtitle: `K obnovení hesla použijte svůj e -mailový účet`,
        plan_products: `Plány a produkty`,
        nick_name: `uživatelské jméno`,
        email: `E-mailem`,
        my_products: `Moje produkty`,
        my_orders: `Mé objednávky`,
        unlink: `Odpojit`,
        link: `Odkaz`,
        connected_accounts: `Propojené účty`,
        last_name: `Příjmení`,
        first_name: `Jméno`,
        Gender: `Rod`,
        Birth: `Narození`,
        Month: `Měsíc`,
        Year: `Rok`,
        Country_Region: `Země/oblast`,
        Address: `Adresa`,
        Save: `Uložit`,
        Date: `datum`,
        Male: `mužský`,
        Female: `ženský`,
        Unspecified: `Nespecifikováno`,
        Security: `Bezpečnostní`,
        change_password: `Změnit heslo`,
        change_now: `Změnit hned`,
        connect_email: `Připojit e -mail`,
        delete_account: `Smazat účet`,
        delete_account_info: `Když bude váš účet smazán, všechna data v plánu a produktech přidružená k vašemu účtu budou trvale smazána a možná je nebudete moci obnovit. Doporučujeme opatrně šlapat.`,
        Delete: `Vymazat`,
        Logout: `Odhlásit se`,
        my_profile: `Můj profil`,
        guides_faqs: `Průvodci a časté dotazy`,
        More: `Více`,
        guides: `Průvodci`,
        register: `Registrovat`,
        hot_faq: `Nejčastější dotazy`,
        Contents: `Obsah:`,
        contact_us: `Kontaktujte nás >>`,
        plan: `Plán`,
        unregistered: `Neregistrovaný`,
        buy_more: `Koupit více`,
        buy_again: `Koupit znovu`,
        buy_now: `Kup nyní`,
        free_no_limit: `Zdarma a bez omezení`,
        expired: `Platnost vypršela`,
        lifetime: `Život`,
        remain: `Zůstat`,
        day_s: `dny`,
        day: `dny`,
        error_24801: `Požadavek se nezdařil (kód chyby: 24801). Zkuste to znovu`,
        no_app_found: `Nebyla nalezena žádná aplikace! <a href="/">Obnovte</a> nebo <a href="https://cs.tipard.com/">Přejděte na oficiální web</a>`,
        get_more: `Získejte více >>`,
        edit_photo: `Upravit fotografii`,
        select_photo: `Vyberte fotografii`,
        change_photo: `Změnit fotku`,
        cancel: `zrušení`,
        hide_password: `Skrýt heslo`,
        show_password: `Zobrazit heslo`,
        zoom_in: `Přiblížit`,
        zoom_out: `Oddálit`,
        rotate: `Točit se`,
        horizontal_flip: `Horizontální překlopení`,
        vertical_flip: `Vertikální překlopení`,
        country: `Země`,
        country_1: `Vyberte svou zemi/oblast`,
        country_2: `Ostrov Åaland`,
        country_3: `Afghánistán`,
        country_4: `Albánie`,
        country_5: `Alžírsko`,
        country_6: `Americká Samoa`,
        country_7: `Andorra`,
        country_8: `Angola`,
        country_9: `Anguilla`,
        country_10: `Antarktida`,
        country_11: `Antigua a Barbuda`,
        country_12: `Argentina`,
        country_13: `Arménie`,
        country_14: `Aruba`,
        country_15: `Austrálie`,
        country_16: `Rakousko`,
        country_17: `Ázerbajdžán`,
        country_18: `Bahrajn`,
        country_19: `Bangladéš`,
        country_20: `Barbados`,
        country_21: `Bělorusko`,
        country_22: `Belgie`,
        country_23: `Belize`,
        country_24: `Benin`,
        country_25: `Bermudy`,
        country_26: `Bhútán`,
        country_27: `Bolívie`,
        country_28: `Bosna a Hercegovina`,
        country_29: `Botswana`,
        country_30: `Bouvetův ostrov`,
        country_31: `Brazílie`,
        country_32: `Britské indickooceánské území`,
        country_33: `Britské Panenské ostrovy`,
        country_34: `Brunej`,
        country_35: `Bulharsko`,
        country_36: `Burkina`,
        country_37: `Burundi`,
        country_38: `Kambodža`,
        country_39: `Kamerun`,
        country_40: `Kanada`,
        country_41: `Kapverdy`,
        country_42: `Karibské Nizozemsko`,
        country_43: `Kajmanské ostrovy`,
        country_44: `Středoafrická republika`,
        country_45: `Čad`,
        country_46: `Chile`,
        country_47: `Čína`,
        country_48: `Vánoční ostrov`,
        country_49: `Kokosové (Keelingovy) ostrovy`,
        country_50: `Kolumbie`,
        country_51: `Cookovy ostrovy`,
        country_52: `Kostarika`,
        country_53: `"Pobřeží slonoviny"`,
        country_54: `Chorvatsko`,
        country_55: `Kuba`,
        country_56: `Kypr`,
        country_57: `Česká republika`,
        country_58: `Demokratická republika Kongo`,
        country_59: `Dánsko`,
        country_60: `Džibutsko`,
        country_61: `Dominika`,
        country_62: `Dominikánská republika`,
        country_63: `Ekvádor`,
        country_64: `Egypt`,
        country_65: `El Salvador`,
        country_66: `Rovníková Guinea`,
        country_67: `Eritrea`,
        country_68: `Estonsko`,
        country_69: `Etiopie`,
        country_70: `Falklandy`,
        country_71: `Faerské ostrovy`,
        country_72: `Federativní státy Mikronésie`,
        country_73: `Fidži`,
        country_74: `Finsko`,
        country_75: `Francie`,
        country_76: `Francouzská Guyana`,
        country_77: `Francouzská Polynésie`,
        country_78: `Francouzská jižní území`,
        country_79: `Gabon`,
        country_80: `Gambie`,
        country_81: `Gruzie`,
        country_82: `Německo`,
        country_83: `Ghana`,
        country_84: `Gibraltar`,
        country_85: `Velká Británie (Spojené království; Anglie)`,
        country_86: `Řecko`,
        country_87: `Grónsko`,
        country_88: `Grenada`,
        country_89: `Guadeloupe`,
        country_90: `Guam`,
        country_91: `Guatemala`,
        country_92: `Guernsey`,
        country_93: `Guinea`,
        country_94: `Guinea-Bissau`,
        country_95: `Guyana`,
        country_96: `Haiti`,
        country_97: `Heardův ostrov a McDonaldovy ostrovy`,
        country_98: `Honduras`,
        country_99: `Hongkong`,
        country_100: `Maďarsko`,
        country_101: `Island`,
        country_102: `Indie`,
        country_103: `Indonésie`,
        country_104: `Írán`,
        country_105: `Irák`,
        country_106: `Irsko`,
        country_107: `Ostrov Man`,
        country_108: `Izrael`,
        country_109: `Itálie`,
        country_110: `Jamaica`,
        country_111: `Japonsko`,
        country_112: `Trikot`,
        country_113: `Jordán`,
        country_114: `Kazachstán`,
        country_115: `Keňa`,
        country_116: `Kiribati`,
        country_117: `Kuvajt`,
        country_118: `Kyrgyzstán`,
        country_119: `Laos`,
        country_120: `Lotyšsko`,
        country_121: `Libanon`,
        country_122: `Lesotho`,
        country_123: `Libérie`,
        country_124: `Libye`,
        country_125: `Lichtenštejnsko`,
        country_126: `Litva`,
        country_127: `Lucembursko`,
        country_128: `Macao`,
        country_129: `Madagaskar`,
        country_130: `Malawi`,
        country_131: `Malajsie`,
        country_132: `Maledivy`,
        country_133: `Mali`,
        country_134: `Malta`,
        country_135: `Marshallovy ostrovy`,
        country_136: `Martinik`,
        country_137: `Mauritánie`,
        country_138: `Mauricius`,
        country_139: `Mayotte`,
        country_140: `Mexiko`,
        country_141: `Moldavsko`,
        country_142: `Monako`,
        country_143: `Mongolsko`,
        country_144: `Černá Hora`,
        country_145: `Montserrat`,
        country_146: `Maroko`,
        country_147: `Mosambik`,
        country_148: `Myanmar (Barma)`,
        country_149: `Namibie`,
        country_150: `Nauru`,
        country_151: `Nepál`,
        country_152: `Holandsko`,
        country_153: `Nová Kaledonie`,
        country_154: `Nový Zéland`,
        country_155: `Nikaragua`,
        country_156: `Niger`,
        country_157: `Nigérie`,
        country_158: `Niue`,
        country_159: `Ostrov Norfolk`,
        country_160: `Severní Korea`,
        country_161: `Severní Mariany`,
        country_162: `Norsko`,
        country_163: `Omán`,
        country_164: `Pákistán`,
        country_165: `Palau`,
        country_166: `Palestinská území`,
        country_167: `Panama`,
        country_168: `Papua-Nová Guinea`,
        country_169: `Paraguay`,
        country_170: `Peru`,
        country_171: `Pitcairnovy ostrovy`,
        country_172: `Polsko`,
        country_173: `Portugalsko`,
        country_174: `Portoriko`,
        country_175: `Katar`,
        country_176: `Makedonie (FYROM)`,
        country_177: `republika Kongo`,
        country_178: `Shledání`,
        country_179: `Rumunsko`,
        country_180: `Ruská Federace`,
        country_181: `Rwanda`,
        country_182: `Svatý Bartoloměj`,
        country_183: `Svatý Martin (Francie)`,
        country_184: `Saint-Pierre a Miquelon`,
        country_185: `Samoa`,
        country_186: `San Marino`,
        country_187: `Svatý Tomáš a Princův ostrov`,
        country_188: `Saudská arábie`,
        country_189: `Senegal`,
        country_190: `Srbsko`,
        country_191: `Seychely`,
        country_192: `Sierra Leone`,
        country_193: `Singapur`,
        country_194: `Slovensko`,
        country_195: `Slovinsko`,
        country_196: `Solomonovy ostrovy`,
        country_197: `Somálsko`,
        country_198: `Jižní Afrika`,
        country_199: `Jižní Georgie a Jižní Sandwich Isla`,
        country_200: `Jižní Korea`,
        country_201: `jižní Súdán`,
        country_202: `Španělsko`,
        country_203: `Srí Lanka`,
        country_204: `Svatá Helena & Závislosti`,
        country_205: `Svatý Kryštof a Nevis`,
        country_206: `Svatá Lucie`,
        country_207: `Svatý Vincenc a Grenadiny`,
        country_208: `Súdán`,
        country_209: `Surinam`,
        country_210: `Svazijsko`,
        country_211: `Švédsko`,
        country_212: `Švýcarsko`,
        country_213: `Sýrie`,
        country_214: `Tchaj-wan`,
        country_215: `Tádžikistán`,
        country_216: `Tanzanie`,
        country_217: `Šablona:Údaje o zemi SJM Svalbard`,
        country_218: `Thajsko`,
        country_219: `Bahamy`,
        country_220: `Komory`,
        country_221: `Filipíny`,
        country_222: `Timor-Leste (Východní Timor)`,
        country_223: `Jít`,
        country_224: `Tokelau`,
        country_225: `Tonga`,
        country_226: `Trinidad a Tobago`,
        country_227: `Tunisko`,
        country_228: `krocan`,
        country_229: `Turkmenistán`,
        country_230: `Ostrovy Turks a Caicos`,
        country_231: `Tuvalu`,
        country_232: `Uganda`,
        country_233: `Ukrajina`,
        country_234: `Spojené arabské emiráty`,
        country_235: `Menší odlehlé ostrovy Spojených států amerických`,
        country_236: `Spojené státy americké (USA)`,
        country_237: `Panenské ostrovy Spojených států amerických`,
        country_238: `Uruguay`,
        country_239: `Uzbekistán`,
        country_240: `Vanuatu`,
        country_241: `Vatikán (Svatý stolec)`,
        country_242: `Venezuela`,
        country_243: `Vietnam`,
        country_244: `Wallis a Futuna`,
        country_245: `západní Sahara`,
        country_246: `Jemen`,
        country_247: `Zambie`,
        country_248: `Zimbabwe`,
        google_login: `Přihlaste se pomocí Google`,
        State: `Stát`,
        Activation_code: `Aktivační kód`,
        Question: `Seznam všech aplikací, ke kterým jste přihlášeni`,
        Copy_complete: `Kopírování dokončeno`,
        change_password_success: `heslo bylo úspěšně změněno`,
        successful_login_title: `Přihlášení bylo úspěšné`,
        product_page: `Stránka produktu >>`,
        successful_login_info: `Přihlášení dokončeno. Zavřete prosím aktuální stránku a vraťte se na původní kartu, abyste mohli pokračovat v následujícím procesu. Aktuální stránka se automaticky zavře za 5 sekund. Pokud se automatické zavření nebo zavření kliknutím na tlačítko "Hotovo" nezdaří, zavřete prosím přímo tuto kartu.`,
        successful_login_info_firefox: `Přihlášení dokončeno. Zavřete prosím aktuální stránku a vraťte se na původní kartu, abyste mohli pokračovat v následujícím procesu.`,
        my_account: `Můj účet`,
        my_history: `Moje historie`,
        remove_watermark: `Odstraňte vodoznak`,
        no_history: `Žádná historie`,
        history_all: `Vybrat vše`,
        history_open: `Otevřeno`,
        history_down: `Stažení`,
        history_delete: `Vymazat`,
        history_clear: `Vymazat neplatné`,
        images: `snímky)`,
        use_this_function: `Použít tento produkt >>`,
        hd_downloading: `Výhody stahování originálních HD obrázků:`,
        lifetimeRemaining: `Životnost-zbývající`,
        Remaining: `Zbývající`,
        email_verification: `potvrzení e-mailu`,
        email_verification_info: `Ověřovací zprávu jsme odeslali na váš e-mail <span class="email"></span> a dokončete prosím ověření. Po ověření budou výhody automaticky synchronizovány.`,
        wrong_email: `Špatná e-mailová adresa?`,
        click_here_to_modify: `Klikněte zde pro úpravu`,
        get_more_help: `Získat další pomoc?`,
        click_here: `Klikněte zde`,
        email_verification_info_success: `Gratulujeme k ověření vašeho e-mailového účtu.`,
        email_verification_info_error: `Ověření se nezdařilo z důvodu vypršení platnosti odkazu.`,
        registration_succeeded: `Registrace byla úspěšná`,
        registration_succeeded_info_1: `Gratulujeme! Úspěšně jste se zaregistrovali. Ověřovací zprávu jsme odeslali na váš e-mail <span class="email"></span> a dokončete ověření, abyste mohli využívat výhod tohoto e-mailu.`,
        registration_succeeded_info_2: `Kliknutím na „Hotovo“ se vraťte na domovskou stránku a použijte tento produkt.`,
        registration_succeeded_info_3: `Kliknutím na „Hotovo“ zavřete aktuální stránku a vrátíte se na domovskou stránku pro následující operace. Pokud se zavření nezdaří, zavřete kartu ručně.`,
        verify_email: `ověřovací email`,
        registered_email_not_verified: `Registrovaný e-mail nebyl ověřen, prosím jej ihned ověřte.`,
        email_verification_time_1: `Neobdrželi jste ověřovací e-mail?`,
        email_verification_time_2: `Po`,
        email_verification_time_3: `Kliknutím sem jej znovu odešlete`,
        error_26301: `Kód chyby: 26301, kontaktujte nás`,
        error_26302: `Kód chyby: 26302, kontaktujte nás`,
        error_26303: `Chyba formátu e-mailu (kód chyby: 26303). Zadejte jej znovu`,
        error_26304: `Doporučuje se heslo delší než 8 znaků (kód chyby: 26304)`,
        error_26305: `Požadavek se nezdařil (kód chyby: 26305). Zkuste to znovu`,
        error_26306: `E-mail byl zaregistrován, <a href="https://account.tipard.com/login">přejděte k přihlášení</a>`,
        error_26307: `Požadavek se nezdařil (kód chyby: 26307). Zkuste to znovu`,
        error_26308: `Požadavek se nezdařil (kód chyby: 26308). Zkuste to znovu`,
        error_26401: `Kód chyby: 26401, zkuste to znovu`,
        error_26402: `E-mail byl ověřen (kód chyby: 26402), zkuste to prosím znovu`,
        error_26403: `Požadavek se nezdařil (kód chyby: 26403). Zkuste to znovu`,
        error_26404: `Požadavek se nezdařil (kód chyby: 26404). Zkuste to znovu`,
        error_26501: `Kód chyby: 26501, kontaktujte nás`,
        error_26502: `Kód chyby: 26502, kontaktujte nás`,
        error_26503: `Chyba formátu e-mailu (kód chyby: 26503). Zadejte jej znovu`,
        error_26504: `Požadavek se nezdařil (kód chyby: 26504). Zkuste to znovu`,
        error_26505: `E-mail nebyl zaregistrován, prosím <a href="https://account.tipard.com/register">nejprve jej zaregistrujte</a>`,
        error_26506: `E-mail byl ověřen.`,
        error_26507: `Požadavek se nezdařil (kód chyby: 26507). Zkuste to znovu`,
        error_26508: `Ověření proběhlo úspěšně (kód chyby: 26508), zkuste to znovu`,
        error_26509: `Požadavek se nezdařil (kód chyby: 26509), zkuste to znovu`,
        error_26510: `Kód chyby: 26510, kontaktujte nás`,
        error_26601: `Kód chyby: 26601, kontaktujte nás`,
        error_26602: `Kód chyby: 26602, kontaktujte nás`,
        error_26603: `Požadavek se nezdařil (kód chyby: 26603). Zkuste to znovu`,
        error_26604: `Kód chyby: 26604, kontaktujte nás`,
        error_26605: `Kód chyby: 26605, kontaktujte nás`,
        error_26701: `Kód chyby: 26701, kontaktujte nás prosím`,
        error_26702: `Požadavek se nezdařil (kód chyby: 26702). Zkuste to znovu`,
        error_26703: `Kód chyby: 26703, kontaktujte nás`,
        error_26704: `Kód chyby: 26704, kontaktujte nás`,
        error_26705: `Počkejte na přihlášení (kód chyby: 26705). Zkuste to znovu`,
        no_cookie: `Ve svém prohlížeči jste zapnuli funkci Blokovat všechny soubory cookie, takže se nemůžete přihlásit. Přejděte prosím do Nastavení a zaškrtněte políčko Povolit všechny soubory cookie.`,
        error_26801: `Kód chyby: 26801, kontaktujte nás prosím`,
        error_26802: `Kód chyby: 26802, kontaktujte nás`,
        error_26803: `Požadavek se nezdařil (kód chyby: 26803). Zkuste to znovu`,
        error_26804: `Požadavek se nezdařil (kód chyby: 26804). Zkuste to znovu`,
        error_order: `Požadavek se nezdařil (kód chyby: 27098), zkuste to prosím znovu!`,
        error_order1: `Dotaz na objednávku je neúplný (kód chyby:`,
        error_order2: `), obnovte stránku a zkuste to znovu.`,
        modify_email_title: `Upravit e-mail`,
        modify_email_info: `Upravený e-mail můžete použít k přihlášení ke svému účtu.`,
        images_per: `Obrázky za`,
        error_26101: `Chyba: 26101. Kontaktujte nás`,
        error_26102: `Chyba: 26102. Kontaktujte nás`,
        error_26103: `Požadavek se nezdařil (kód chyby: 26103). Zkuste to znovu`,
        error_26104: `Kód chyby: 26104, zkuste to prosím znovu`,
        error_26105: `Kód chyby: 26105, zkuste to prosím znovu`,
        error_26106: `Smazání se nezdařilo (kód chyby: 26106). Zkuste to znovu`,
        error_26201: `Chyba: 26201. Kontaktujte nás`,
        error_26202: `Požadavek se nezdařil (kód chyby: 26202). Zkuste to znovu`,
        error_26001: `Chyba: 26001. Kontaktujte nás`,
        error_26002: `Chyba: 26002. Kontaktujte nás`,
        error_26003: `Chyba: 26003. Kontaktujte nás`,
        error_26004: `Chyba: 26004. Kontaktujte nás`,
        error_26005: `Požadavek se nezdařil (kód chyby: 26005). Zkuste to znovu`,
        error_26006: `Kód chyby: 26006, zkuste to prosím znovu`,
        error_26008: `Chyba: 26008. Kontaktujte nás`,
        go_to_the_home_page: `Přejděte na domovskou stránku`,
        error_27101: `Požadavek se nezdařil (kód chyby: 27101). Zkuste to znovu ist`,
        error_27201: `Kód chyby: 27201, kontaktujte nás`,
        error_27202: `Kód chyby: 27202, zkuste to znovu`,
        error_27203: `Požadavek se nezdařil (kód chyby: 27203). Zkuste to znovu`,
        error_27204: `Neplatný kód (kód chyby: 27204).`,
        error_27205: `Požadavek se nezdařil (kód chyby: 27205). Zkuste to znovu`,
        error_27206: `Požadavek se nezdařil (kód chyby: 27206). Zkuste to znovu`,
        error_27207: `Požadavek se nezdařil (kód chyby: 27207). Zkuste to znovu`,
        no_history_found: `Nepoužili jste žádný nástroj! <a href="/">Obnovte</a> nebo <a href="https://cs.tipard.com/">Přejděte na oficiální web</a>`,
        error_25301: `Chyba: 25301. Kontaktujte nás`,
        error_25302: `Chyba: 25302. Kontaktujte nás`,
        error_25303: `Požadavek se nezdařil (kód chyby: 25303). Zkuste to znovu`,
        error_25304: `Požadavek se nezdařil (kód chyby: 25304). Zkuste to znovu`,
        error_25305: `Požadavek se nezdařil (kód chyby: 25305). Zkuste to znovu`,
        error_25306: `Požadavek se nezdařil (kód chyby: 25306). Zkuste to znovu`,
        image_upscaler_p: `Výhody stahování obrázku bez vodoznaku:`,
        Available_for: `Dostupné pro:`,
        credit_per: `%s kreditů za HD obrázek`,
        still_valid: `Zakoupený plán(y) je stále platný`,
        credit: `kredit(y)`,
        pc_3rd_info: `Přihlášení proběhlo úspěšně. Pro další operaci přejděte do aplikace.`,
        use_online: `Použijte online službu`,
        use_download: `Použijte desktopový program`,
        use_immediately: `Použijte Ihned`,
        Use_in_browser: `Použijte v prohlížeči`,
        win_desktop: `Okna`,
        Mac_desktop: `Mac`,
        credits_per: `{%} kreditů za měsíc`,
        expire: `Doba platnosti:`,
        viewDetails: `Zobrazit podrobnosti`,
        viewHistory: `Zobrazit výhody historie >>`,
        viewDetailsInfo: `Upozornění: Pokud jsou výhody předplatného obnoveny do 7 dnů po vypršení platnosti, lze nevyužité výhody nadále používat. Doba vypršení platnosti bude také automaticky aktualizována na dobu vypršení platnosti nového předplatného. Pokud do 7 dnů po uplynutí doby platnosti nedojde k žádnému novému předplatnému, všechny výhody, jejichž platnost vypršela, budou vymazány.`,
        "connect_account": "Svázat e-mail se svým účtem",
        "connect_account_info": "Po vázání se můžete přihlásit pomocí této e-mailové adresy.",
        "connect_now": "Svázat nyní",
        "no_email_bind": "Žádná vazba na e-mail",
        "bind_email": "Svázat email",
        "connect_your_email_placeholder": "prosím zadejte svou e-mailovou adresu",
        "bind_an_email": "Svažte e-mail",
        "bind_info": "Úspěšně jste se přihlásili. Pro aktivaci výhod připojte e-mail ke svému účtu.",
        "bind_later": "Svázat později",
        "footer": "Copyright © 2024 Tipard Studio. Všechna práva vyhrazena.",
        "overtime": "Kód chyby: {%}, zkuste to prosím znovu",
        "hi": "Ahoj, %s!",
        "Personal_Information": "Osobní údaje",
        "Access": "Přístup",
        "Subscription_Plan": "(Tarif předplatného)",
        "No_orders": "Nebyly nalezeny žádné objednávky.",
        "No_data": "Žádná data",
        "Featured_Products": "Vybrané produkty",
        "More_Products": "Další produkty",
        "Free_Download": "Zdarma ke stažení",
        "Get_Started": "Začněte",
        "Subscribe": "Upsat",
        "Verified": "Ověřeno",
        "back_to_account_center": "Zpět do Account Center",
        "success": "Úspěch!",
        "successfully": "Úspěšně jste zaregistrovali účet.",
        "Continue": "Pokračovat",
        "Already": "Už máte účet?",
        "loading_verification": "Kontrola stavu ověření...",
        "email_no_verification": "Litujeme, registrovaná e-mailová adresa nebyla ověřena. Dokončete prosím ověření podle výše uvedených pokynů a opětovným kliknutím na tlačítko \"Hotovo\" dokončete registraci.",
        "error_20505": "E-mail byl propojen",
        "error_20506": "Chyba: 20506. Kontaktujte nás",
        "error_28401": "Zadejte prosím svůj email",
        "error_28402": "Nesprávný formát e-mailu",
        "error_28403": "Chyba: 28403. Kontaktujte nás",
        "error_28404": "E-mail byl zaregistrován",
        "error_28405": "Chyba: 28405. Kontaktujte nás",
        "error_28406": "E-mail byl propojen",
        "error_28407": "Chyba: 28407. Kontaktujte nás",
        "error_28408": "Odeslání e-mailu se nezdařilo",
        "error_28601": "Zadejte prosím svůj email",
        "error_28602": "Zadejte ověřovací kód",
        "error_28603": "Nesprávný formát e-mailu",
        "error_28604": "Chyba: 28604. Kontaktujte nás",
        "error_28605": "Ověřovací kód je nesprávný",
        "error_28606": "Chyba: 28606. Kontaktujte nás",
        "error_28607": "E-mail byl zaregistrován",
        "error_28608": "K vašemu účtu není vázán žádný e-mail",
        "error_28609": "E-mail není ověřen",
        "error_28610": "Chyba: 28610. Kontaktujte nás",
        "error_28611": "E-mail byl propojen",
        "error_28612": "Chyba: 28612. Kontaktujte nás",
        "unbind_email_btn": "Odpojte e-mail",
        "binding_exchange": "Svázat email",
        "Subscription_Status": "Stav předplatného",
        "mask_unbind_info_1": "Opravdu chcete odpojit účet {%} od tohoto účtu? Po odpojení již nebudete moci kontrolovat výhody tohoto e-mailu",
        "mask_unbind_info_2": "Opravdu chcete zrušit vazbu všech počítačů spojených s tímto registračním kódem?<a href=\"https://cs.tipard.com/faq.html#unbind\">Další informace o změně zařízení.</a>",
        "error_dc_link": "Chyba, kontaktujte nás prosím",
        "vcu_state": "Výhody plné verze:",
        "monthly_subscription": "Licence na 1 měsíc",
        "year_subscription": "Licence na 1 rok",
        "to": "na",
        "lifetime_subscription": "Doživotní licence",
        "unbind": "Potvrdit",
        "unbind_success": "Úspěšně odpojeno",
        "error_28401": "Zadejte prosím svůj email",
        "error_28702": "Chyba: 28702. Kontaktujte nás",
        "error_28703": "Ověřovací kód je nesprávný",
        "error_28704": "Chyba: 28704. Kontaktujte nás",
        "error_28705": "E-mail nebyl propojen",
        "error_28706": "Zadejte ověřovací kód",
        "error_28707": "Odpojení se nezdařilo",
        "error_28901": "Chyba: 28901. Kontaktujte nás",
        "error_28902": "Chyba: 28902. Kontaktujte nás",
        "error_28903": "Chyba: 28903. Kontaktujte nás",
        "error_28904": "Chyba: 28904. Kontaktujte nás",
        "error_28905": "Chyba: 28905. Kontaktujte nás",
        "error_28906": "Chyba: 28906. Kontaktujte nás",
        "error_28907": "Neplatný registrační kód",
        "error_28908": "Chyba: 28908. Kontaktujte nás",
        "error_28909": "Aktuálně probíhá období ochlazení na unbind. Zrušit vazbu můžete pouze jednou za měsíc.",
        "error_289010": "Chyba: 289010. Kontaktujte nás",
        "error_289011": "Chyba: 289011. Kontaktujte nás",
        "error_289012": "Chyba: 289012. Kontaktujte nás",
        "activated_computer": "Aktivováno na %s/%s počítačích",
        "bind_email_info": "Výhody následujících e-mailů budou vázány a využívány pod tímto VIP účtem.",
        "error_26309": "Chyba: 26309. Kontaktujte nás",
        "error_26310": "Neplatný kód doporučení",
        "error_26311": "Chyba: 26311. Kontaktujte nás",
        "error_26312": "Neplatný kód doporučení",
        "error_26313": "Chyba: 26313. Kontaktujte nás",
        "error_26314": "E-mail byl propojen",
        "activate_unbinding": "Změnit zařízení",
        "copy_registration_code": "Zkopírujte registrační kód",
        "show_registration_code": "Zobrazit registrační kód",
        "hide_registration_code": "Skrýt registrační kód",
        "other_binding_email": "Další závazné e-maily",
        "home": "Domov",
        "points_balance": "Bilance bodů:",
        "earn_now": "Vydělávejte nyní >>",
        "earn_more": "Získejte více bodů ZDARMA, využijte více dárků",
        "earn_or_redeem_points": "Získejte/uplatněte body",
        "unbind_email_info": "Aktuální účet byl spojen s e-mailovou adresou",
        "bind_email_title": "Chcete-li přiřadit více objednávek k nákupu e-mailových adres, klikněte na tlačítko „Přidružení k e-mailu“ níže a přiřaďte je.",
        "bind_email_tip": "<span>Tip:</span> V současné době jsou podporovány pouze nákupy e-mailových objednávek registračního kódu.",
        "email_association": "E-mailová asociace",
        "dissociation": "Disociace",
        "back": "Zadní",
        "activate_benefits": "Zadejte prosím nákupní e-mail, který je třeba přiřadit k získání ověřovacího kódu. Do pole pro zadání ověřovacího kódu zadejte ověřovací kód přijatý v e-mailu a kliknutím na tlačítko „Ověřit aktivaci“ aktivujte výhody.",
        "e_mail": "E-mail:",
        "verification_code": "Ověřovací kód:",
        "obtain_verification_code": "Získejte ověřovací kód",
        "verification_association": "Ověřte přidružení",
        "current_password": "Aktuální heslo:",
        "account_center": "Account Center",
        "new_password": "Nové heslo:",
        "confirm_password": "Potvrďte nové heslo:",
        "Send": "Poslat",
        "history_files": "Soubory historie",
        "delete_account_info_1": "1. Smazání účtu Tipard je trvalá akce a nelze ji vrátit zpět. Všechna vaše data, včetně fotografií a preferencí, budou ztracena. Opravdu chcete smazat svůj účet Tipard?",
        "delete_account_info_2": "2. Pokud se rozhodnete smazat účet Tipard, klikněte prosím na tlačítko Odeslat a zadejte ověřovací kód z e-mailu, který jsme vám zaslali.",
        "best_deals": "Nejlepší nabídky",
        "close": "Blízko",
        "product": "Produkt:"
    },
    da: {
        overtime_1: `Fejlkode:`,
        overtime_2: `, prøv igen`,
        isnetwork: `Internetfejl. Tjek venligst, og prøv igen`,
        email_placeholder: `E -mail`,
        email_empty: `Indtast venligst e -mail`,
        email_not_valid: `E -mail er ikke gyldig`,
        email_not_valid_1: `Indtast venligst din e -mail`,
        email_not_valid_2: `E -mail er ikke gyldig. Brug en anden adresse.`,
        email_not_valid_3: `Ingen e -mail -input`,
        password_placeholder: `Adgangskode`,
        password_empty: `Indtast venligst adgangskode`,
        password_not_valid: `Forkert konto eller adgangskode`,
        password_not_valid_1: `Adgangskode over 8 tegn er påkrævet`,
        password_not_valid_2: `Opret en adgangskode`,
        password_placeholder_1: `Opret din adgangskode`,
        password_placeholder_2: `Bekræft dit kodeord`,
        password_placeholder_3: `Opret ny adgangskode`,
        password_placeholder_4: `Bekræft ny adgangskode`,
        password_placeholder_5: `Indtast gammel adgangskode`,
        copy_password_empty: `Bekræft venligst adgangskoden`,
        copy_password_not_valid: `Bekræft venligst din adgangskode`,
        copy_passwords_inconsistent: `Din adgangskodebekræftelse stemmer ikke overens`,
        code_empty: `Indtast venligst verifikationskode`,
        code_not_valid: `Ugyldig verifikationskode`,
        code_placeholder: `Verifikationskode`,
        not_received_code: `Hvis din postkasse ikke har modtaget verifikationskoden i lang tid, skal du få bekræftelseskoden igen.`,
        get_first_code: `Få venligst bekræftelseskoden først.`,
        last_name_placeholder: `Indtast venligst dit efternavn`,
        first_name_placeholder: `Indtast venligst dit fornavn`,
        address_placeholder: `Indtast venligst din adresse`,
        no_code_text: `Vi har sendt en verifikationskode. Angiv venligst din kode. <span class='tips'>Har du ikke modtaget en kode?`,
        no_code_text_1: `1.Kontroller venligst, at e -mailadressen er gyldig, og at den kan modtage e -mails.`,
        no_code_text_2: `2.For e -mailen sendes automatisk af systemet, kan den markeres som spam- eller uønsket e -mail. Kontroller, om e -mailen er i papirkurven.`,
        no_code_text_3: `3. kan ikke løse dit problem?`,
        no_code_text_3_span: `Klik derefter her for at kontakte os.`,
        order_no: `Du har ikke købt nogen produkter. Eventuelle spørgsmål, venligst <a href="https://da.tipard.com/store.html">Køb nu</a> Eller <a href="https://da.tipard.com/contact.html"> Kontakt os</a>`,
        error_24901: `Nuværende konto har ingen link til e -mail, og kan ikke finde ordrerne. Tilslut venligst en e -mail.`,
        user_guide: `Brugervejledning >>`,
        download: `Hent`,
        order_number: `Ordrenummer`,
        Refund: `Tilbagebetale`,
        Disabled: `handicappet`,
        Normal: `Normal`,
        Modify: `Modificere`,
        Modify_1: `Rediger >>`,
        Connect: `Forbinde`,
        unlink_success: `Fjern forbindelsen med succes`,
        connect_success: `Opret forbindelse`,
        feedback_title: `tak for din tilbagemelding. Forlad venligst dit problem, og vi vil svare dig inden for 24 timer.`,
        feedback_thank_you: `Tak! <br /> Din feedback er blevet indsendt.`,
        feedback_email: `Indtast din email her!`,
        feedback_content: `Efterlad ethvert problem eller forslag, du stødte på her.`,
        feedback_submit: `Indsend`,
        form_contents: `Du har ikke angivet nogen beskrivelse. Indtast det, og send det igen.`,
        old_password: `Indtast venligst den gamle adgangskode`,
        new_password: `Opret en ny adgangskode`,
        old_new_password: `Den nye adgangskode kan ikke være den samme som den gamle`,
        incorrect_password: `forkert kodeord`,
        delete_no: `Slet nu`,
        Caps: `Caps Lock er på`,
        Get: `Få`,
        Done: `Færdig`,
        error_20001: `Fejl: 20001. Log venligst ind igen.`,
        error_20002: `Fejl: 20002. Log venligst ind igen.`,
        error_20003: `Fejl: 20003. Log venligst ind igen.`,
        error_20004: `Anmodning mislykkedes (fejlkode: 20004). Prøv det igen.`,
        error_20005: `Login session er udløbet (fejl: 20005). Log venligst ind igen.`,
        error_20006: `Anmodning mislykkedes (fejlkode: 20006). Prøv det igen.`,
        error_20007: `Login session er udløbet (fejl: 20007). Log venligst ind igen.`,
        error_20008: `Login session er udløbet (fejl: 20008). Log venligst ind igen.`,
        error_20009: `Login session er udløbet (fejl: 20009). Log venligst ind igen.`,
        error_20101: `Indtast venligst din e -mail (fejlkode: 20101)`,
        error_20102: `E -mail er ikke gyldig (fejlkode: 20102)`,
        error_20103: `Anmodning mislykkedes (fejlkode: 20103). Prøv det igen`,
        error_20104: `E -mail er allerede i brug. <a href="https://account.tipard.com/login"> log ind </a> eller registrer dig med en ny`,
        error_20105: `Anmodning mislykkedes (fejlkode: 20105). Prøv det igen`,
        error_20106: `Mailen kunne ikke sendes. Prøv igen`,
        error_20201: `Indtast venligst din e -mail (fejlkode: 20201)`,
        error_20202: `Indtast venligst din adgangskode (fejlkode: 20202)`,
        error_20203: `Indtast venligst verifikationskode (fejlkode: 20203)`,
        error_20204: `E -mail er ikke gyldig (fejlkode: 20204)`,
        error_20205: `Adgangskode over 8 tegn er påkrævet (fejlkode: 20205)`,
        error_20206: `Anmodning mislykkedes (fejlkode: 20206). Prøv det igen`,
        error_20207: `Ugyldig verifikationskode`,
        error_20208: `Anmodning mislykkedes (fejlkode: 20208). Prøv det igen`,
        error_20209: `Anmodning mislykkedes (fejlkode: 20209). Prøv det igen`,
        error_20301: `Indtast venligst din e -mail (fejlkode: 20301)`,
        error_20302: `Fejl: 20302. Kontakt os venligst`,
        error_20303: `E -mail er ikke gyldig (fejlkode: 20303)`,
        error_20304: `Anmodning mislykkedes (fejlkode: 20304). Prøv det igen`,
        error_20305: `Kontoen findes ikke. Angiv igen, eller <a href="https://account.tipard.com/register">Opret det</a> først.`,
        error_20306: `Ingen adgangskode endnu. Brug <a href="https://account.tipard.com/passwordless-login"> login uden adgangskode </a> eller <a href="create-password"> angiv en adgangskode </a>, og log ind.`,
        error_20308: `Anmodning mislykkedes (fejlkode: 20308). Prøv det igen`,
        error_20401: `Kunne ikke logge ud (fejlkode: 20401). Prøv det igen`,
        error_20501: `Indtast venligst din e -mail (fejlkode: 20501)`,
        error_20502: `E -mail er ikke gyldig (fejlkode: 20502)`,
        error_20503: `Anmodning mislykkedes (fejlkode: 20503). Prøv det igen`,
        error_20504: `Kunne ikke sende e -mail. Prøv det igen.`,
        error_20601: `Indtast venligst din e -mail (fejlkode: 20601)`,
        error_20602: `Angiv venligst verifikationskode (fejlkode: 20602)`,
        error_20603: `E -mail er ikke gyldig (fejlkode: 20603)`,
        error_20604: `Anmodning mislykkedes (fejlkode: 20604). Prøv det igen`,
        error_20606: `Anmodning mislykkedes (fejlkode: 20606). Prøv det igen`,
        error_20607: `Anmodning mislykkedes (fejlkode: 20607). Prøv det igen`,
        error_20608: `Anmodning mislykkedes (fejlkode: 20608). Prøv det igen`,
        error_20701: `Indtast venligst din e -mail (fejlkode: 20701)`,
        error_20702: `E -mail er ikke gyldig (fejlkode: 20702)`,
        error_20703: `Anmodning mislykkedes (fejlkode: 20703). Prøv det igen`,
        error_20704: `Kontoen findes ikke. Angiv igen, eller <a href="https://account.tipard.com/register">Opret det</a> først.`,
        error_20705: `Anmodning mislykkedes (fejlkode: 20705). Prøv det igen`,
        error_20706: `Kunne ikke sende e -mail. Prøv det igen`,
        error_20801: `Indtast venligst din e -mail (fejlkode: 20801)`,
        error_20802: `Fejl: 20802. Kontakt os venligst`,
        error_20803: `Indtast venligst verifikationskode (fejlkode: 20803)`,
        error_20804: `E -mail er ikke gyldig (fejlkode: 20804)`,
        error_20805: `Adgangskode over 8 tegn er påkrævet (fejlkode: 20805)`,
        error_20806: `Anmodning mislykkedes (fejlkode: 20806). Prøv det igen`,
        error_20808: `Anmodning mislykkedes (fejlkode: 20808). Prøv det igen`,
        error_20901: `Anmodning mislykkedes (fejlkode: 20901). Prøv det igen`,
        error_20902: `Anmodning mislykkedes (fejlkode: 20902). Prøv det igen`,
        error_21000: `Ændringer gemmes`,
        error_21001: `Der indsendes ingen oplysninger (fejlkode: 21001)`,
        error_21002: `Anmodning mislykkedes (fejlkode: 21002). Prøv det igen`,
        error_21101: `Indtast venligst din e -mail (fejlkode: 21101)`,
        error_21102: `E -mail er ikke gyldig (fejlkode: 21102)`,
        error_21103: `Anmodningen mislykkedes (fejlkode: 21103), prøv igen`,
        error_21104: `E -mail er allerede forbundet, brug en ny`,
        error_21105: `Anmodningen mislykkedes (fejlkode: 21105), prøv igen`,
        error_21106: `Kunne ikke sende e -mail. Prøv det igen`,
        error_21201: `Indtast venligst din e -mail (fejlkode: 21201)`,
        error_21202: `Indtast venligst verifikationskode (fejlkode: 21202)`,
        error_21203: `E -mail er ikke gyldig (fejlkode: 21203)`,
        error_21204: `Fejl: 21204. Kontakt os venligst`,
        error_21205: `Fejl: 21205. Kontakt os venligst`,
        error_21206: `Adgangskode over 8 tegn er påkrævet (fejlkode: 21206)`,
        error_21207: `Anmodning mislykkedes (fejlkode: 21207). Prøv det igen`,
        error_21209: `Anmodning mislykkedes (fejlkode: 21209). Prøv det igen`,
        error_21301: `Indtast venligst den gamle adgangskode (fejlkode: 21301)`,
        error_21302: `Opret en ny adgangskode (fejlkode: 21302)`,
        error_21303: `Den nye adgangskode kan ikke være den samme som den gamle. (Fejl: 21303)`,
        error_21304: `Adgangskode over 8 tegn er påkrævet (fejlkode: 21304)`,
        error_21306: `Anmodning mislykkedes (fejlkode: 21306). Prøv det igen`,
        error_21402: `Anmodning mislykkedes (fejlkode: 21402). Prøv det igen`,
        error_21403: `Verificeringskoden kunne ikke sendes. Send det venligst igen`,
        error_21500: `Kontoen er blevet slettet`,
        error_21501: `Angiv venligst verifikationskode (fejlkode: 21501)`,
        error_21502: `Login session er udløbet (fejl: 21502). Log venligst ind igen.`,
        error_21503: `Anmodning mislykkedes (fejlkode: 21503). Prøv det igen`,
        error_21505: `Anmodningen mislykkedes (fejlkode: 21505). Prøv igen`,
        error_21601: `Fejl: 20601. Kontakt os venligst`,
        error_21602: `Ugyldig verifikation (fejl: 20602). Prøv det igen.`,
        error_21603: `Fejl: 20603. Prøv det igen`,
        error_21604: `Anmodning mislykkedes (fejlkode: 21604). Prøv det igen`,
        error_21606: `Anmodning mislykkedes (fejlkode: 21606). Prøv det igen`,
        error_21611: `Anmodning mislykkedes (fejlkode: 21611). Prøv det igen`,
        error_21801: `Fejl: 21801. Kontakt os venligst`,
        error_21802: `Anmodning mislykkedes (fejl: 21802). Prøv det igen`,
        error_21803: `Fejl: 21803. Prøv det igen`,
        error_21804: `Anmodning mislykkedes (fejlkode: 21804). Prøv det igen`,
        error_21806: `Fejl: 21806. Prøv det igen`,
        error_21807: `Fejl: 21807. Kontakt os venligst`,
        error_21808: `Fejl: 21808. Kontakt os venligst`,
        error_21809: `Fejl: 21809. Kontakt os venligst`,
        error_21810: `Fejl: 21810. Kontakt os venligst`,
        error_21811: `Fejl: 21811. Kontakt os venligst`,
        error_21812: `Fejl: 21812. Kontakt os venligst`,
        error_21813: `Anmodning mislykkedes (fejlkode: 21813). Prøv det igen`,
        error_21814: `Fejl: 21814. Kontakt os venligst`,
        error_21815: `Anmodning mislykkedes (fejlkode: 21815). Prøv det igen`,
        error_21816: `Fejl: 21816. Kontakt os venligst`,
        error_21817: `Fejl: 21817. Kontakt os venligst`,
        error_21818: `Fejl: 21818. Kontakt os venligst`,
        error_21819: `Anmodning mislykkedes (fejlkode: 21819). Prøv det igen`,
        error_21820: `Fejl: 21820. Kontakt os venligst`,
        error_21821: `Fejl: 21821. Kontakt os venligst`,
        error_21822: `Fejl: 21822. Kontakt os venligst`,
        error_21823: `Anmodning mislykkedes (fejlkode: 21823). Prøv det igen`,
        error_21824: `Anmodning mislykkedes (fejlkode: 21824). Prøv det igen`,
        error_21825: `Anmodning mislykkedes (fejlkode: 21825). Prøv det igen`,
        error_21826: `Anmodning mislykkedes (fejlkode: 21826). Prøv det igen`,
        error_21901: `Fejl: 21901. Kontakt os venligst`,
        error_21902: `Anmodning mislykkedes (fejlkode: 21902). Prøv det igen`,
        error_21903: `Kontostatus er ændret (fejlkode: 21903). Opdater venligst siden, og prøv igen`,
        error_21904: `Fejl: 21904. Prøv det igen`,
        error_21905: `Fejl: 21905. Prøv det igen`,
        error_21906: `Anmodning mislykkedes (fejlkode: 21906). Prøv det igen`,
        error_21907: `Google -konto er blevet linket til en anden konto`,
        error_21908: `Anmodning mislykkedes (fejlkode: 21908). Prøv det igen`,
        error_22001: `Anmodning mislykkedes (fejlkode: 22001). Prøv det igen`,
        error_22002: `Fjernelse af link mislykkedes uden yderligere login`,
        error_22003: `Anmodning mislykkedes (fejlkode: 22003). Prøv det igen`,
        error_22101: `Fejl: 22101. Kontakt os venligst`,
        error_22102: `Kontostatus er ændret (fejlkode: 22102). Opdater venligst siden, og prøv igen`,
        error_22103: `Anmodning mislykkedes (fejlkode: 22103). Prøv det igen`,
        error_22104: `Kontostatus er ændret (fejlkode: 22104). Opdater venligst siden, og prøv igen`,
        error_22105: `Fejl: 22105. Prøv det igen`,
        error_22106: `Fejl: 22106. Prøv det igen`,
        error_22107: `Fejl: 22107. Kontakt os venligst`,
        error_22108: `Anmodning mislykkedes (fejlkode: 22108). Prøv det igen`,
        error_22201: `Fejl: 22201. Kontakt os venligst`,
        error_22202: `Ugyldig verifikation (fejl: 22202). Prøv det igen.`,
        error_22203: `Fejl: 22203. Prøv det igen "`,
        error_22204: `Anmodning mislykkedes (fejlkode: 22204). Prøv det igen`,
        error_22206: `Anmodning mislykkedes (fejlkode: 22206). Prøv det igen`,
        error_22401: `Fejl: 22401. Kontakt os venligst`,
        error_22402: `Ugyldig verifikation (fejl: 22402). Prøv det igen.`,
        error_22403: `Fejl: 22403. Prøv det igen`,
        error_22404: `Anmodning mislykkedes (fejlkode: 22404). Prøv det igen`,
        error_22405: `Facebook -kontoen er blevet linket til anden e -mail`,
        error_22406: `Fejl: 22406. Prøv det igen`,
        error_22407: `Fejl: 22407. Kontakt os venligst`,
        error_22408: `Fejl: 22408. Kontakt os venligst`,
        error_22409: `Fejl: 22409. Kontakt os venligst`,
        error_22410: `Fejl: 224010. Kontakt os venligst`,
        error_22411: `Fejl: 224011. Kontakt os venligst`,
        error_22412: `Fejl: 224012. Kontakt os venligst`,
        error_22413: `Fejl: 22413. Kontakt os venligst`,
        error_22414: `Anmodning mislykkedes (fejlkode: 22414). Prøv det igen`,
        error_22415: `Fejl: 22415. Kontakt os venligst`,
        error_22416: `Fejl: 22416. Kontakt os venligst`,
        error_22417: `Fejl: 22417. Kontakt os venligst`,
        error_22418: `Anmodning mislykkedes (fejlkode: 22418). Prøv det igen`,
        error_22419: `Fejl: 22419. Kontakt os venligst`,
        error_22420: `Fejl: 22420. Kontakt os venligst`,
        error_22421: `Fejl: 22421. Kontakt os venligst`,
        error_22422: `Anmodning mislykkedes (fejlkode: 22422). Prøv det igen`,
        error_22423: `Anmodning mislykkedes (fejlkode: 22423). Prøv det igen`,
        error_22424: `Anmodning mislykkedes (fejlkode: 22424). Prøv det igen`,
        error_22425: `Anmodning mislykkedes (fejlkode: 22425). Prøv det igen`,
        error_20098: `Fejlkode: 20098. Hvis du søger i privat tilstand, skal du skifte til normal tilstand og prøve igen.`,
        error_22298: `Anmodning om Google mislykkedes (fejl: 22298). Prøv det igen.`,
        error_22498: `Anmodning om Facebook mislykkedes (fejl: 22498). Prøv det igen.`,
        error_24902: `Anmodning mislykkedes (fejlkode: 24902). Prøv det igen`,
        error_24903: `Anmodning mislykkedes (fejlkode: 24903). Prøv det igen`,
        error_24904: `Anmodning mislykkedes (fejlkode: 24904). Prøv det igen`,
        error_24905: `Anmodning mislykkedes (fejlkode: 24905). Prøv det igen`,
        login_title: `Log ind på Tipard`,
        password_placeholder: `Adgangskode`,
        log_in: `Log på`,
        no_account: `Ingen konto?`,
        create_it: `Opret det`,
        or_log_in_with: `Eller log ind med`,
        passwordless_login: `Login uden adgangskode`,
        log_in_done: `Log på Udført`,
        three_rd_account_connect_info: `Tillykke! Du har logget ind. Nu kan du forbinde en e -mail -konto, der bruges til at logge ind i fremtiden.`,
        see_my_account: `Se min konto`,
        three_rd_login_merge_account: `Tredjeparts kontos e-mail-adresse er blevet tilmeldt. Vil du oprette forbindelse til og logge på med denne e-mail-adresse direkte?`,
        connect_log_in: `Opret forbindelse og log ind`,
        create_an_account: `Opret en konto`,
        back_to_log_in: `Tilbage for at logge ind`,
        create_password: `Opret adgangskode`,
        create_now: `Opret nu`,
        password_login_subtitle: `Login uden adgangskode med e -mail`,
        account_login: `Konto -login`,
        rights: `Ved at oprette denne konto accepterer du <a href="https://da.tipard.com/terms.html"> servicevilkår </a> og <a href="https://da.tipard.com/policy.html"> Fortrolighedspolitik </a>`,
        passwordless_login_done: `Login uden adgangskode udført`,
        passwordless_login_info: `Tillykke, du har fuldført adgangskodefri login. Du kan oprette en adgangskode til denne konto og logge ind med kontoen og adgangskoden i fremtiden. <a href="/create-password" style="display: initial;"> Opret nu </a>`,
        sign_up: `Tilmelde`,
        register_info: `Opret din konto`,
        reset_now: `Nulstil nu`,
        forgot_password: `Glemt kodeord`,
        reset_password_subtitle: `Brug din kontos e -mail til at nulstille adgangskoden`,
        plan_products: `Planer og produkter`,
        nick_name: `Brugernavn`,
        email: `E -mail`,
        my_products: `Mine produkter`,
        my_orders: `Mine Ordrer`,
        unlink: `Fjern tilknytningen`,
        link: `Link`,
        connected_accounts: `Tilsluttede konti`,
        last_name: `Efternavn`,
        first_name: `Fornavn`,
        Gender: `Køn`,
        Birth: `Fødsel`,
        Month: `Måned`,
        Year: `År`,
        Country_Region: `Land/region`,
        Address: `Adresse`,
        Save: `Gemme`,
        Date: `Dato`,
        Male: `Han`,
        Female: `Kvinde`,
        Unspecified: `Uspecificeret`,
        Security: `Sikkerhed`,
        change_password: `Skift kodeord`,
        change_now: `Skift nu`,
        connect_email: `Tilslut e -mail`,
        delete_account: `Slet konto`,
        delete_account_info: `Når din konto slettes, slettes alle data i Plan & Produkter, der er knyttet til din konto, permanent, og du kan muligvis ikke gendanne dem. Vi anbefaler, at du træder forsigtigt.`,
        Delete: `Slet`,
        Logout: `Log ud`,
        my_profile: `Min profil`,
        guides_faqs: `Guider og ofte stillede spørgsmål`,
        More: `Mere`,
        guides: `Guider`,
        register: `Tilmeld`,
        hot_faq: `Hot FAQ`,
        Contents: `Indhold:`,
        contact_us: `Kontakt os >>`,
        plan: `Plan`,
        unregistered: `Uregistreret`,
        buy_more: `Køb mere`,
        buy_again: `Køb igen`,
        buy_now: `Køb nu`,
        free_no_limit: `Gratis & ingen grænse`,
        expired: `Udløbet`,
        lifetime: `Livstid`,
        remain: `Forblive`,
        day_s: `Dage)`,
        day: `Dage`,
        error_24801: `Forespørgsel mislykkedes (fejlkode: 24801). Prøv det igen`,
        no_app_found: `Ingen app fundet!<a href="/">Opdater</a> eller <a href="https://da.tipard.com/">Gå til det officielle websted</a>`,
        get_more: `Få mere >>`,
        edit_photo: `Rediger billede`,
        select_photo: `Vælg foto`,
        change_photo: `Ændr Foto`,
        cancel: `Afbestille`,
        hide_password: `Skjul adgangskode`,
        show_password: `Vis adgangskode`,
        zoom_in: `Zoom ind`,
        zoom_out: `Zoome ud`,
        rotate: `Rotere`,
        horizontal_flip: `Vandret flip`,
        vertical_flip: `Lodret flip`,
        country: `Land`,
        country_1: `Vælg dit land/område`,
        country_2: `Åaland`,
        country_3: `Afghanistan`,
        country_4: `Albanien`,
        country_5: `Algeriet`,
        country_6: `Amerikansk Samoa`,
        country_7: `Andorra`,
        country_8: `Angola`,
        country_9: `Anguilla`,
        country_10: `Antarktis`,
        country_11: `Antigua og Barbuda`,
        country_12: `Argentina`,
        country_13: `Armenien`,
        country_14: `Aruba`,
        country_15: `Australien`,
        country_16: `Østrig`,
        country_17: `Aserbajdsjan`,
        country_18: `Bahrain`,
        country_19: `Bangladesh`,
        country_20: `Barbados`,
        country_21: `Hviderusland`,
        country_22: `Belgien`,
        country_23: `Belize`,
        country_24: `Benin`,
        country_25: `Bermuda`,
        country_26: `Bhutan`,
        country_27: `Bolivia`,
        country_28: `Bosnien og Hercegovina`,
        country_29: `Botswana`,
        country_30: `Bouvetøen`,
        country_31: `Brasilien`,
        country_32: `Britisk territorium i Det Indiske Ocean`,
        country_33: `Britiske Jomfruøer`,
        country_34: `Brunei`,
        country_35: `Bulgarien`,
        country_36: `Burkina`,
        country_37: `Burundi`,
        country_38: `Cambodja`,
        country_39: `Cameroun`,
        country_40: `Canada`,
        country_41: `Kap Verde`,
        country_42: `Caribiske Holland`,
        country_43: `Caymanøerne`,
        country_44: `Den Centralafrikanske Republik`,
        country_45: `Tchad`,
        country_46: `Chile`,
        country_47: `Kina`,
        country_48: `Juleøen`,
        country_49: `Cocos (Keeling) Islands`,
        country_50: `Colombia`,
        country_51: `Cookøerne`,
        country_52: `Costa Rica`,
        country_53: `"Côte d'Ivoire"`,
        country_54: `Kroatien`,
        country_55: `Cuba`,
        country_56: `Cypern`,
        country_57: `Tjekkiet`,
        country_58: `Den Demokratiske Republik Congo`,
        country_59: `Danmark`,
        country_60: `Djibouti`,
        country_61: `Dominica`,
        country_62: `Dominikanske republik`,
        country_63: `Ecuador`,
        country_64: `Egypten`,
        country_65: `El Salvador`,
        country_66: `Ækvatorial Guinea`,
        country_67: `Eritrea`,
        country_68: `Estland`,
        country_69: `Etiopien`,
        country_70: `Falklandsøerne`,
        country_71: `Færøerne`,
        country_72: `Mikronesiens fødererede stater`,
        country_73: `Fiji`,
        country_74: `Finland`,
        country_75: `Frankrig`,
        country_76: `Fransk Guyana`,
        country_77: `Fransk polynesien`,
        country_78: `franske sydlige territorier`,
        country_79: `Gabon`,
        country_80: `Gambia`,
        country_81: `Georgien`,
        country_82: `Tyskland`,
        country_83: `Ghana`,
        country_84: `Gibraltar`,
        country_85: `Storbritannien (Storbritannien; England)`,
        country_86: `Grækenland`,
        country_87: `Grønland`,
        country_88: `Grenada`,
        country_89: `Guadeloupe`,
        country_90: `Guam`,
        country_91: `Guatemala`,
        country_92: `Guernsey`,
        country_93: `Guinea`,
        country_94: `Guinea-Bissau`,
        country_95: `Guyana`,
        country_96: `Haiti`,
        country_97: `Heard Island og McDonald Islands`,
        country_98: `Honduras`,
        country_99: `Hong Kong`,
        country_100: `Ungarn`,
        country_101: `Island`,
        country_102: `Indien`,
        country_103: `Indonesien`,
        country_104: `Iran`,
        country_105: `Irak`,
        country_106: `Irland`,
        country_107: `Isle of Man`,
        country_108: `Israel`,
        country_109: `Italien`,
        country_110: `Jamaica`,
        country_111: `Japan`,
        country_112: `Jersey`,
        country_113: `Jordan`,
        country_114: `Kasakhstan`,
        country_115: `Kenya`,
        country_116: `Kiribati`,
        country_117: `Kuwait`,
        country_118: `Kirgisistan`,
        country_119: `Laos`,
        country_120: `Letland`,
        country_121: `Libanon`,
        country_122: `Lesotho`,
        country_123: `Liberia`,
        country_124: `Libyen`,
        country_125: `Liechtenstein`,
        country_126: `Litauen`,
        country_127: `Luxembourg`,
        country_128: `Macao`,
        country_129: `Madagaskar`,
        country_130: `Malawi`,
        country_131: `Malaysia`,
        country_132: `Maldiverne`,
        country_133: `Mali`,
        country_134: `Malta`,
        country_135: `Marshalløerne`,
        country_136: `Martinique`,
        country_137: `Mauretanien`,
        country_138: `Mauritius`,
        country_139: `Mayotte`,
        country_140: `Mexico`,
        country_141: `Moldova`,
        country_142: `Monaco`,
        country_143: `Mongoliet`,
        country_144: `Montenegro`,
        country_145: `Montserrat`,
        country_146: `Marokko`,
        country_147: `Mozambique`,
        country_148: `Myanmar (Burma)`,
        country_149: `Namibia`,
        country_150: `Nauru`,
        country_151: `Nepal`,
        country_152: `Holland`,
        country_153: `Ny Kaledonien`,
        country_154: `New Zealand`,
        country_155: `Nicaragua`,
        country_156: `Niger`,
        country_157: `Nigeria`,
        country_158: `Niue`,
        country_159: `Norfolk Island`,
        country_160: `Nordkorea`,
        country_161: `Nordmarianerne`,
        country_162: `Norge`,
        country_163: `Oman`,
        country_164: `Pakistan`,
        country_165: `Palau`,
        country_166: `palæstinensiske områder`,
        country_167: `Panama`,
        country_168: `Papua Ny Guinea`,
        country_169: `Paraguay`,
        country_170: `Peru`,
        country_171: `Pitcairnøerne`,
        country_172: `Polen`,
        country_173: `Portugal`,
        country_174: `Puerto Rico`,
        country_175: `Qatar`,
        country_176: `Republikken Makedonien (FYROM)`,
        country_177: `Republikken Congo`,
        country_178: `Genforening`,
        country_179: `Rumænien`,
        country_180: `Den Russiske Føderation`,
        country_181: `Rwanda`,
        country_182: `Sankt Barthélemy`,
        country_183: `Saint Martin (Frankrig)`,
        country_184: `Saint-Pierre og Miquelon`,
        country_185: `Samoa`,
        country_186: `San Marino`,
        country_187: `Sao Tome og Principe`,
        country_188: `Saudi Arabien`,
        country_189: `Senegal`,
        country_190: `Serbien`,
        country_191: `Seychellerne`,
        country_192: `Sierra Leone`,
        country_193: `Singapore`,
        country_194: `Slovakiet`,
        country_195: `Slovenien`,
        country_196: `Salomonøerne`,
        country_197: `Somalia`,
        country_198: `Sydafrika`,
        country_199: `South Georgia og South Sandwich Isla`,
        country_200: `Sydkorea`,
        country_201: `Sydsudan`,
        country_202: `Spanien`,
        country_203: `Sri Lanka`,
        country_204: `St. Helena & afhængigheder`,
        country_205: `St. Kitts og Nevis`,
        country_206: `St. Lucia`,
        country_207: `St. Vincent og Grenadinerne`,
        country_208: `Sudan`,
        country_209: `Surinam`,
        country_210: `Swaziland`,
        country_211: `Sverige`,
        country_212: `Schweiz`,
        country_213: `Syrien`,
        country_214: `Taiwan`,
        country_215: `Tadsjikistan`,
        country_216: `Tanzania`,
        country_217: `Skabelon:Landedata SJM Svalbard`,
        country_218: `Thailand`,
        country_219: `Bahamas`,
        country_220: `Comorerne`,
        country_221: `Filippinerne`,
        country_222: `Timor-Leste (Østtimor)`,
        country_223: `At gå`,
        country_224: `Tokelau`,
        country_225: `Tonga`,
        country_226: `Trinidad og Tobago`,
        country_227: `Tunesien`,
        country_228: `Kalkun`,
        country_229: `Turkmenistan`,
        country_230: `Turks- og Caicosøerne`,
        country_231: `Tuvalu`,
        country_232: `Uganda`,
        country_233: `Ukraine`,
        country_234: `Forenede Arabiske Emirater`,
        country_235: `Forenede Staters mindre afsidesliggende øer`,
        country_236: `USA (USA)`,
        country_237: `De Forenede Staters Jomfruøer`,
        country_238: `Uruguay`,
        country_239: `Usbekistan`,
        country_240: `Vanuatu`,
        country_241: `Vatikanstaten (Den Hellige Stol)`,
        country_242: `Venezuela`,
        country_243: `Vietnam`,
        country_244: `Wallis og Futuna`,
        country_245: `Vestsahara`,
        country_246: `Yemen`,
        country_247: `Zambia`,
        country_248: `Zimbabwe`,
        google_login: `Log ind med Google`,
        State: `Stat`,
        Activation_code: `Aktiveringskode`,
        Question: `Liste over alle de apps, du har logget på`,
        Copy_complete: `Kopi færdig`,
        change_password_success: `Adgangskoden blev ændret`,
        successful_login_title: `Login succes`,
        product_page: `Produktside >>`,
        successful_login_info: `Login gennemført. Luk venligst den aktuelle side og gå tilbage til den oprindelige fane for at fortsætte med følgende proces. Den aktuelle side lukkes automatisk om 5 sekunder. Hvis automatisk lukning eller lukning ved at klikke på knappen "Udført" mislykkes, skal du lukke denne fane direkte.`,
        successful_login_info_firefox: `Login gennemført. Luk venligst den aktuelle side og gå tilbage til den oprindelige fane for at fortsætte med følgende proces.`,
        my_account: `Min konto`,
        my_history: `Min historie`,
        remove_watermark: `Fjern vandmærke`,
        no_history: `Ingen historie`,
        history_all: `Vælg alle`,
        history_open: `Åben`,
        history_down: `Hent`,
        history_delete: `Slet`,
        history_clear: `Ryd ugyldig`,
        images: `billede(r)`,
        use_this_function: `Brug denne funktion >>`,
        hd_downloading: `Fordele ved download af originale HD-billeder:`,
        lifetimeRemaining: `Levetid tilbage`,
        Remaining: `Tilbage`,
        email_verification: `E-mailbekræftelse`,
        email_verification_info: `Vi har sendt bekræftelsesmeddelelsen til din e-mail <span class="email"></span>, og bedes venligst afslutte bekræftelsen. Efter bekræftelse vil fordelene automatisk blive synkroniseret.`,
        wrong_email: `Forkert e-mailadresse?`,
        click_here_to_modify: `Klik her for at ændre`,
        get_more_help: `Få mere hjælp?`,
        click_here: `Klik her`,
        email_verification_info_success: `Tillykke med din bekræftelse af din e-mail-konto.`,
        email_verification_info_error: `Bekræftelse mislykkedes på grund af det udløbne link.`,
        registration_succeeded: `Registreringen lykkedes`,
        registration_succeeded_info_1: `Tillykke! Du har registreret dig. Vi har sendt bekræftelsesmeddelelsen til din e-mail <span class="email"></span>, og bedes afslutte bekræftelsen for at bruge fordelene ved denne e-mail.`,
        registration_succeeded_info_2: `Klik på "Udført" for at vende tilbage til startsiden og bruge dette produkt.`,
        registration_succeeded_info_3: `Klik på "Udført" for at lukke den aktuelle side og vende tilbage til startsiden for følgende handlinger. Hvis lukningen mislykkes, skal du lukke fanen manuelt.`,
        verify_email: `Bekræft e-mail`,
        registered_email_not_verified: `Den registrerede e-mail er ikke blevet bekræftet. Bekræft den med det samme.`,
        email_verification_time_1: `Modtog du ikke en bekræftelses-e-mail?`,
        email_verification_time_2: `Efter`,
        email_verification_time_3: `Klik her for at sende den igen`,
        error_26301: `Fejlkode: 26301, kontakt os venligst`,
        error_26302: `Fejlkode: 26302, kontakt os venligst`,
        error_26303: `E-mail-formatfejl (fejlkode: 26303). Indtast det igen`,
        error_26304: `Adgangskode over 8 tegn anbefales (fejlkode: 26304)`,
        error_26305: `Reuqest mislykkedes (fejlkode: 26305). Prøv det igen`,
        error_26306: `E-mail er blevet registreret. <a href="https://account.tipard.com/login">gå til log ind</a>`,
        error_26307: `Reuqest mislykkedes (fejlkode: 26307). Prøv det igen`,
        error_26308: `Reuqest mislykkedes (fejlkode: 26308). Prøv det igen`,
        error_26401: `Fejlkode: 26401, prøv venligst igen`,
        error_26402: `E-mail er blevet bekræftet (fejlkode: 26402), prøv venligst igen`,
        error_26403: `Reuqest mislykkedes (fejlkode: 26403). Prøv det igen`,
        error_26404: `Forespørgsel mislykkedes (fejlkode: 26404). Prøv det igen`,
        error_26501: `Fejlkode: 26501, kontakt os venligst`,
        error_26502: `Fejlkode: 26502, kontakt os venligst`,
        error_26503: `E-mail-formatfejl (fejlkode: 26503). Indtast det igen`,
        error_26504: `Reuqest mislykkedes (fejlkode: 26504). Prøv det igen`,
        error_26505: `E-mail er ikke blevet registreret, venligst <a href="https://account.tipard.com/register">registrer den først</a>`,
        error_26506: `E-mail er blevet bekræftet.`,
        error_26507: `Reuqest mislykkedes (fejlkode: 26507). Prøv det igen`,
        error_26508: `Bekræftelse mislykkedes (fejlkode: 26508), prøv venligst igen`,
        error_26509: `Forespørgsel mislykkedes (fejlkode: 26509), prøv venligst igen`,
        error_26510: `Fejlkode: 26510, kontakt os venligst`,
        error_26601: `Fejlkode: 26601, kontakt os venligst`,
        error_26602: `Fejlkode: 26602, kontakt os venligst`,
        error_26603: `Reuqest mislykkedes (fejlkode: 26603). Prøv det igen`,
        error_26604: `Fejlkode: 26604, kontakt os venligst`,
        error_26605: `Fejlkode: 26605, kontakt os venligst`,
        error_26701: `Fejlkode: 26701, kontakt os venligst`,
        error_26702: `Reuqest mislykkedes (fejlkode: 26702). Prøv det igen`,
        error_26703: `Fejlkode: 26703, kontakt os venligst`,
        error_26704: `Fejlkode: 26704, kontakt os venligst`,
        error_26705: `Vent på at logge ind (fejlkode: 26705). Prøv det igen`,
        no_cookie: `Du har aktiveret funktionen Bloker alle cookies i din browser, så du kan ikke logge ind. Gå venligst til Indstillinger for at markere feltet Tillad alle cookies.`,
        error_26801: `Fejlkode: 26801, kontakt os venligst`,
        error_26802: `Fejlkode: 26802, kontakt os venligst`,
        error_26803: `Forespørgsel mislykkedes (fejlkode: 26803). Prøv det igen`,
        error_26804: `Forespørgsel mislykkedes (fejlkode: 26804). Prøv det igen`,
        error_order: `Forespørgsel mislykkedes (fejlkode: 27098), prøv venligst igen!`,
        error_order1: `Ordreforespørgslen er ufuldstændig (fejlkode:`,
        error_order2: `）, opdater venligst og prøv det igen.`,
        modify_email_title: `Rediger e-mail`,
        modify_email_info: `Du kan bruge den ændrede e-mail til at logge ind på din konto.`,
        images_per: `Obrázky za`,
        error_26101: `Fejl: 26101. Kontakt os venligst`,
        error_26102: `Fejl: 26102. Kontakt os venligst`,
        error_26103: `Anmodningen mislykkedes (fejlkode:26103). Prøv det igen`,
        error_26104: `Fejlkode: 26104, prøv det igen`,
        error_26105: `Fejlkode: 26105, prøv venligst igen`,
        error_26106: `Sletning mislykkedes (fejlkode: 26106). Prøv det igen`,
        error_26201: `Fejl: 26201. Kontakt os venligst`,
        error_26202: `Anmodningen mislykkedes (fejlkode:26202). Prøv det igen`,
        error_26001: `Fejl: 26001. Kontakt os venligst`,
        error_26002: `Fejl: 26002. Kontakt os venligst`,
        error_26003: `Fejl: 26003. Kontakt os venligst`,
        error_26004: `Fejl: 26004. Kontakt os venligst`,
        error_26005: `Anmodningen mislykkedes (fejlkode:26005). Prøv det igen`,
        error_26006: `Fejlkode: 26006, prøv det igen`,
        error_26008: `Fejl: 26008. Kontakt os venligst`,
        go_to_the_home_page: `Gå til startsiden`,
        error_27101: `Forespørgsel mislykkedes (fejlkode: 27101). Prøv venligst igen`,
        error_27201: `Fejlkode: 27201, kontakt os venligst`,
        error_27202: `Fejlkode: 27202, prøv venligst igen`,
        error_27203: `Forespørgsel mislykkedes (fejlkode: 27203). Prøv det igen`,
        error_27204: `Ugyldig kode (fejlkode: 27204).`,
        error_27205: `Forespørgsel mislykkedes (fejlkode: 27205). Prøv det igen`,
        error_27206: `Forespørgsel mislykkedes (fejlkode: 27206). Prøv det igen`,
        error_27207: `Forespørgsel mislykkedes (fejlkode: 27207). Prøv det igen`,
        no_history_found: `Du har ikke brugt noget værktøj!<a href="/">Opdater</a> eller <a href="https://da.tipard.com/">Gå til det officielle websted</a>`,
        error_25301: `Fejl: 25301. Kontakt os venligst`,
        error_25302: `Fejl: 25302. Kontakt os venligst`,
        error_25303: `Anmodningen mislykkedes (fejlkode: 25303). Prøv det igen`,
        error_25304: `Anmodningen mislykkedes (fejlkode: 25304). Prøv det igen`,
        error_25305: `Anmodningen mislykkedes (fejlkode: 25305). Prøv det igen`,
        error_25306: `Anmodningen mislykkedes (fejlkode: 25306). Prøv det igen`,
        image_upscaler_p: `Fordele ved download af billede uden vandmærke:`,
        Available_for: `Tilgængelig for:`,
        credit_per: `%s kredit(er) pr. HD-billede`,
        still_valid: `Købte abonnement(er) er stadig gyldige`,
        credit: `kredit(er)`,
        pc_3rd_info: `Login successfully. Please go to the application for further operation.`,
        use_online: `Brug onlinetjenesten`,
        use_download: `Brug skrivebordsprogrammet`,
        use_immediately: `Brug med det samme`,
        Use_in_browser: `Brug i browser`,
        win_desktop: `Windows`,
        Mac_desktop: `Mac`,
        credits_per: `{%} kreditter pr. måned`,
        expire: `Udløbstid:`,
        viewDetails: `Se detaljer`,
        viewHistory: `Se historiefordele >>`,
        viewDetailsInfo: `Bemærk: Hvis abonnementsfordelene fornyes inden for 7 dage efter udløb, kan de uudnyttede fordele fortsat bruges. Udløbstiden vil også automatisk blive opdateret til udløbstiden for det nye abonnement. Hvis der ikke er nyt abonnement efter 7 dages udløbstid, vil alle udløbne fordele blive slettet.`,
        "connect_account": "Bind e-mail til din konto",
        "connect_account_info": "Efter binding kan du logge ind med denne e-mailadresse.",
        "connect_now": "Bind nu",
        "no_email_bind": "Ingen e-mail-binding",
        "bind_email": "Bind e-mail",
        "connect_your_email_placeholder": "Indtast venligst din e-mailadresse",
        "bind_an_email": "Bind en e-mail",
        "bind_info": "Du har logget ind. Bind venligst en e-mail til din konto for at aktivere dine fordele.",
        "bind_later": "Bind senere",
        "footer": "Copyright © 2024 Tipard Studio. Alle rettigheder forbeholdes.",
        "overtime": "Fejlkode: {%}, prøv venligst igen",
        "hi": "Hej %s!",
        "Personal_Information": "Personlige oplysninger",
        "Access": "Adgang",
        "Subscription_Plan": "(Abonnement)",
        "No_orders": "Ingen ordrer fundet.",
        "No_data": "Ingen data",
        "Featured_Products": "Udvalgte produkter",
        "More_Products": "Flere produkter",
        "Free_Download": "Gratis download",
        "Get_Started": "Kom i gang",
        "Subscribe": "Abonner",
        "Verified": "Verificeret",
        "back_to_account_center": "Tilbage til Account Center",
        "success": "Succes!",
        "successfully": "Du har registreret en konto.",
        "Continue": "Fortsætte",
        "Already": "Har du allerede en konto?",
        "loading_verification": "Kontrollerer bekræftelsesstatus...",
        "email_no_verification": "Beklager, den registrerede e-mailadresse er ikke blevet bekræftet. Udfyld venligst bekræftelsen i henhold til ovenstående instruktioner og klik på knappen \"Udført\" igen for at fuldføre registreringen.",
        "error_20505": "E-mailen er blevet linket",
        "error_20506": "Fejl: 20506. Kontakt os venligst",
        "error_28401": "Indtast venligst din e-mail",
        "error_28402": "Forkert e-mail-format",
        "error_28403": "Fejl: 28403. Kontakt os venligst",
        "error_28404": "Mailen er tilmeldt",
        "error_28405": "Fejl: 28405. Kontakt os venligst",
        "error_28406": "E-mailen er blevet linket",
        "error_28407": "Fejl: 28407. Kontakt os venligst",
        "error_28408": "Kunne ikke sende e-mailen",
        "error_28601": "Indtast venligst din e-mail",
        "error_28602": "Indtast venligst bekræftelseskoden",
        "error_28603": "Forkert e-mail-format",
        "error_28604": "Fejl: 28604. Kontakt os venligst",
        "error_28605": "Bekræftelseskoden er forkert",
        "error_28606": "Fejl: 28606. Kontakt os venligst",
        "error_28607": "Mailen er tilmeldt",
        "error_28608": "Der er ingen e-mail bundet til din konto",
        "error_28609": "E-mailen er ikke bekræftet",
        "error_28610": "Fejl: 28610. Kontakt os venligst",
        "error_28611": "E-mailen er blevet linket",
        "error_28612": "Fejl: 28612. Kontakt os venligst",
        "unbind_email_btn": "Fjern linket til e-mailen",
        "binding_exchange": "Bind e-mail",
        "Subscription_Status": "Abonnementsstatus",
        "mask_unbind_info_1": "Er du sikker på at fjerne linket til {%} fra denne konto? Efter at have fjernet linket, kan du ikke længere tjekke fordelene ved denne e-mail",
        "mask_unbind_info_2": "Er du sikker på, at du vil fjerne bindingen til alle pc'er, der er knyttet til denne registreringskode?<a href=\"https://cs.tipard.com/faq.html#unbind\">Få mere at vide om, hvordan du skifter enheder.</a>",
        "error_dc_link": "Fejl, kontakt os venligst",
        "vcu_state": "Fordele for fuld version:",
        "monthly_subscription": "1 måneds licens",
        "year_subscription": "1 års licens",
        "to": "til",
        "lifetime_subscription": "Livstidslicens",
        "unbind": "Bekræfte",
        "unbind_success": "Ophævet med succes",
        "error_28401": "Indtast venligst din e-mail",
        "error_28702": "Fejl: 28702. Kontakt os venligst",
        "error_28703": "Bekræftelseskoden er forkert",
        "error_28704": "Fejl: 28704. Kontakt os venligst",
        "error_28705": "E-mailen er ikke blevet linket",
        "error_28706": "Indtast venligst bekræftelseskoden",
        "error_28707": "Tilknytningen kunne ikke fjernes",
        "error_28901": "Fejl: 28901. Kontakt os venligst",
        "error_28902": "Fejl: 28902. Kontakt os venligst",
        "error_28903": "Fejl: 28903. Kontakt os venligst",
        "error_28904": "Fejl: 28904. Kontakt os venligst",
        "error_28905": "Fejl: 28905. Kontakt os venligst",
        "error_28906": "Fejl: 28906. Kontakt os venligst",
        "error_28907": "Ugyldig registreringskode",
        "error_28908": "Fejl: 28908. Kontakt os venligst",
        "error_28909": "I øjeblikket i afkølingsperioden. Du kan kun opløse en gang om måneden.",
        "error_289010": "Fejl: 289010. Kontakt os venligst",
        "error_289011": "Fejl: 289011. Kontakt os venligst",
        "error_289012": "Fejl: 289012. Kontakt os venligst",
        "activated_computer": "Aktiveret på %s/%s computer(e)",
        "bind_email_info": "Fordelene ved følgende e-mails vil blive bundet og brugt under denne VIP-konto.",
        "error_26309": "Fejl: 26309. Kontakt os venligst",
        "error_26310": "Ugyldig henvisningskode",
        "error_26311": "Fejl: 26311. Kontakt os venligst",
        "error_26312": "Ugyldig henvisningskode",
        "error_26313": "Fejl: 26313. Kontakt os venligst",
        "error_26314": "E-mailen er blevet linket",
        "activate_unbinding": "Skift enhed",
        "copy_registration_code": "Kopiér registreringskoden",
        "show_registration_code": "Vis registreringskode",
        "hide_registration_code": "Skjul registreringskoden",
        "other_binding_email": "Andre bindende e-mail(s)",
        "home": "Hjem",
        "points_balance": "Pointsaldo:",
        "earn_now": "Tjen nu >>",
        "earn_more": "Tjen flere GRATIS point, indløs flere gaver",
        "earn_or_redeem_points": "Optjen/indløs point",
        "unbind_email_info": "Den aktuelle konto er blevet knyttet til en e-mailadresse",
        "bind_email_title": "Hvis du ønsker at tilknytte flere ordrer til køb af e-mailadresser, skal du klikke på knappen \"E-mail tilknytning\" nedenfor for at tilknytte.",
        "bind_email_tip": "<span>Tip:</span> I øjeblikket understøttes kun køb af e-mail-registreringskodeordrer.",
        "email_association": "E-mail foreningen",
        "dissociation": "Dissociation",
        "back": "Tilbage",
        "activate_benefits": "Indtast venligst den købs-e-mail, der skal tilknyttes for at få bekræftelseskoden. Indtast den bekræftelseskode, der er modtaget i e-mailen, i indtastningsfeltet til bekræftelseskoden, og klik på knappen \"Bekræft aktivering\" for at aktivere fordelene.",
        "e_mail": "E-mail:",
        "verification_code": "Bekræftelseskode:",
        "obtain_verification_code": "Få bekræftelseskode",
        "verification_association": "Bekræft tilknytning",
        "current_password": "Nuværende adgangskode:",
        "account_center": "Kontocenter",
        "new_password": "Ny adgangskode:",
        "confirm_password": "Bekræft ny adgangskode:",
        "Send": "Sende",
        "history_files": "Historie filer",
        "delete_account_info_1": "1. Sletning af din Tipard-konto er en permanent handling og kan ikke fortrydes. Alle dine data, inklusive fotos og præferencer, vil gå tabt. Er du sikker på at du vil slette din Tipard-konto?",
        "delete_account_info_2": "2. Hvis du beslutter dig for at slette Tipard-kontoen, skal du klikke på knappen Send og indtaste bekræftelseskoden fra den e-mail, vi sendte dig.",
        "best_deals": "Bedste tilbud",
        "close": "Tæt",
        "product": "Produkt:"
    },    
    el: {
        overtime_1: `Κωδικός λάθους:`,
        overtime_2: `, παρακαλώ δοκιμάστε το ξανά`,
        isnetwork: `Σφάλμα διαδικτύου. Ελέγξτε και δοκιμάστε ξανά`,
        email_placeholder: `ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ`,
        email_empty: `Παρακαλώ εισάγετε email`,
        email_not_valid: `Το email δεν είναι έγκυρο`,
        email_not_valid_1: `Παρακαλώ εισάγετε το email σας`,
        email_not_valid_2: `Το email δεν είναι έγκυρο, χρησιμοποιήστε διαφορετική διεύθυνση.`,
        email_not_valid_3: `Χωρίς εισαγωγή email`,
        password_placeholder: `Κωδικός πρόσβασης`,
        password_empty: `Παρακαλώ εισάγετε τον κωδικό πρόσβασης`,
        password_not_valid: `Λάθος λογαριασμός ή κωδικός πρόσβασης`,
        password_not_valid_1: `Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων`,
        password_not_valid_2: `Παρακαλώ δημιουργήστε έναν κωδικό πρόσβασης`,
        password_placeholder_1: `Δημιούργησε τον κωδικό σου`,
        password_placeholder_2: `Επιβεβαιώστε τον κωδικό πρόσβασής σας`,
        password_placeholder_3: `Δημιουργία νέου κωδικού πρόσβασης`,
        password_placeholder_4: `Επιβεβαιώστε τον καινούριο σας κωδικό`,
        password_placeholder_5: `Εισαγάγετε τον παλιό κωδικό πρόσβασης`,
        copy_password_empty: `Παρακαλώ επιβεβαιώστε τον κωδικό πρόσβασης`,
        copy_password_not_valid: `Παρακαλώ επιβεβαιώστε τον κωδικό πρόσβασής σας`,
        copy_passwords_inconsistent: `Η επιβεβαίωση του κωδικού πρόσβασής σας δεν ταιριάζει`,
        code_empty: `Εισαγάγετε τον κωδικό επαλήθευσης`,
        code_not_valid: `Μη έγκυρος κωδικός επαλήθευσης`,
        code_placeholder: `Κωδικός επαλήθευσης`,
        not_received_code: `Εάν το γραμματοκιβώτιό σας δεν έχει λάβει τον κωδικό επαλήθευσης για μεγάλο χρονικό διάστημα, λάβετε ξανά τον κωδικό επαλήθευσης.`,
        get_first_code: `Πάρτε πρώτα τον κωδικό επαλήθευσης.`,
        last_name_placeholder: `Παρακαλώ εισάγετε το επώνυμό σας`,
        first_name_placeholder: `Παρακαλώ εισάγετε το ονομα σας`,
        address_placeholder: `Παρακαλώ εισάγετε τη διεύθυνσή σας`,
        no_code_text: `Έχουμε στείλει έναν κωδικό επαλήθευσης. Εισαγάγετε τον κωδικό σας. <span class='tips'>Δεν λάβατε κωδικό;`,
        no_code_text_1: `1. Βεβαιωθείτε ότι η διεύθυνση ηλεκτρονικού ταχυδρομείου είναι έγκυρη και ότι μπορεί να λαμβάνει μηνύματα ηλεκτρονικού ταχυδρομείου.`,
        no_code_text_2: `2. Για το μήνυμα ηλεκτρονικού ταχυδρομείου που αποστέλλεται αυτόματα από το σύστημα, μπορεί να επισημανθεί ως ανεπιθύμητο ή ανεπιθύμητο μήνυμα ηλεκτρονικού ταχυδρομείου. Ελέγξτε αν το μήνυμα ηλεκτρονικού ταχυδρομείου βρίσκεται στον φάκελο Κάδος απορριμμάτων.`,
        no_code_text_3: `3. Δεν μπορείτε να λύσετε το πρόβλημά σας;`,
        no_code_text_3_span: `Στη συνέχεια, κάντε κλικ εδώ για να επικοινωνήσετε μαζί μας.`,
        order_no: `Δεν έχετε αγοράσει κανένα προϊόν. Οποιεσδήποτε ερωτήσεις, <a href="https://el.tipard.com/store.html">Αγοράστε τώρα</a> Ή <a href="https://el.tipard.com/contact.html"> Επικοινωνήστε μαζί μας</a>`,
        error_24901: `Ο τρέχων λογαριασμός δεν έχει συνδεδεμένο email και δεν μπορεί να βρει τις παραγγελίες. Συνδέστε ένα email.`,
        user_guide: `Οδηγός χρήσης >>`,
        download: `Κατεβάστε`,
        order_number: `Αριθμός παραγγελίας`,
        Refund: `Επιστροφή χρημάτων`,
        Disabled: `άτομα με ειδικές ανάγκες`,
        Normal: `Κανονικός`,
        Modify: `Τροποποιώ`,
        Modify_1: `Τροποποίηση >>`,
        Connect: `Συνδέω-συωδεομαι`,
        unlink_success: `Αποσύνδεση με επιτυχία`,
        connect_success: `Συνδεθείτε με επιτυχία`,
        feedback_title: `Ευχαριστούμε για την ανταπόκριση σας. Αφήστε το πρόβλημά σας και θα σας απαντήσουμε εντός 24 ωρών.`,
        feedback_thank_you: `Σας ευχαριστούμε! <br /> Τα σχόλιά σας υποβλήθηκαν με επιτυχία.`,
        feedback_email: `Εισάγετε το email σας εδώ!`,
        feedback_content: `Αφήστε οποιοδήποτε πρόβλημα ή πρόταση αντιμετωπίσατε εδώ.`,
        feedback_submit: `υποβάλλουν`,
        form_contents: `Δεν έχετε εισαγάγει καμία περιγραφή. Παρακαλώ εισάγετε το και υποβάλετε ξανά.`,
        old_password: `Παρακαλώ εισάγετε τον παλιό κωδικό πρόσβασης`,
        new_password: `Δημιουργήστε έναν νέο κωδικό πρόσβασης`,
        old_new_password: `Ο νέος κωδικός πρόσβασης δεν μπορεί να είναι ίδιος με τον παλιό`,
        incorrect_password: `Λάθος κωδικός`,
        delete_no: `Διαγραφή τώρα`,
        Caps: `Το Caps lock είναι ενεργοποιημένο`,
        Get: `Παίρνω`,
        Done: `Εγινε`,
        error_20001: `Σφάλμα: 20001. Συνδεθείτε ξανά.`,
        error_20002: `Σφάλμα: 20002. Συνδεθείτε ξανά.`,
        error_20003: `Σφάλμα: 20003. Συνδεθείτε ξανά.`,
        error_20004: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20004). Δοκιμάστε το ξανά.`,
        error_20005: `Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 20005). Παρακαλώ συνδεθείτε ξανά.`,
        error_20006: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20006). Δοκιμάστε το ξανά.`,
        error_20007: `Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 20007). Παρακαλώ συνδεθείτε ξανά.`,
        error_20008: `Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 20008). Παρακαλώ συνδεθείτε ξανά.`,
        error_20009: `Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 20009). Παρακαλώ συνδεθείτε ξανά.`,
        error_20101: `Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20101)`,
        error_20102: `Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20102)`,
        error_20103: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20103). Δοκιμάστε το ξανά`,
        error_20104: `Το email χρησιμοποιείται ήδη, <a href="https://account.tipard.com/login"> συνδεθείτε </a> ή εγγραφείτε με νέο`,
        error_20105: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20105). Δοκιμάστε το ξανά`,
        error_20106: `Η αποστολή μηνύματος ηλεκτρονικού ταχυδρομείου απέτυχε, δοκιμάστε ξανά`,
        error_20201: `Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20201)`,
        error_20202: `Παρακαλώ εισάγετε τον κωδικό πρόσβασής σας (κωδικός σφάλματος: 20202)`,
        error_20203: `Εισαγάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 20203)`,
        error_20204: `Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20204)`,
        error_20205: `Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 20205)`,
        error_20206: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20206). Δοκιμάστε το ξανά`,
        error_20207: `Μη έγκυρος κωδικός επαλήθευσης`,
        error_20208: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20208). Δοκιμάστε το ξανά`,
        error_20209: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20209). Δοκιμάστε το ξανά`,
        error_20301: `Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20301)`,
        error_20302: `Σφάλμα: 20302. Επικοινωνήστε μαζί μας`,
        error_20303: `Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20303)`,
        error_20304: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20304). Δοκιμάστε το ξανά`,
        error_20305: `Λογαριασμός δεν υπάρχει. Εισαγάγετε ξανά ή <a href="https://account.tipard.com/register">Δημιουργησε το</a> πρώτα.`,
        error_20306: `Δεν υπάρχει ακόμη κωδικός πρόσβασης. Χρησιμοποιήστε <a href="https://account.tipard.com/passwordless-login"> Σύνδεση χωρίς κωδικό πρόσβασης </a> ή <a href="create-password"> ορίστε έναν κωδικό πρόσβασης </a> και συνδεθείτε.`,
        error_20308: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20308). Δοκιμάστε το ξανά`,
        error_20401: `Αποτυχία αποσύνδεσης (κωδικός σφάλματος: 20401). Δοκιμάστε το ξανά`,
        error_20501: `Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20501)`,
        error_20502: `Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20502)`,
        error_20503: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20503). Δοκιμάστε το ξανά`,
        error_20504: `Αποτυχία αποστολής μηνύματος ηλεκτρονικού ταχυδρομείου. Δοκιμάστε το ξανά.`,
        error_20601: `Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20601)`,
        error_20602: `Εισαγάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 20602)`,
        error_20603: `Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20603)`,
        error_20604: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20604). Δοκιμάστε το ξανά`,
        error_20606: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20606). Δοκιμάστε το ξανά`,
        error_20607: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20607). Δοκιμάστε το ξανά`,
        error_20608: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20608). Δοκιμάστε το ξανά`,
        error_20701: `Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20701)`,
        error_20702: `Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20702)`,
        error_20703: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20703). Δοκιμάστε το ξανά`,
        error_20704: `Λογαριασμός δεν υπάρχει. Εισαγάγετε ξανά ή <a href="https://account.tipard.com/register">Δημιουργησε το</a> πρώτα.`,
        error_20705: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20705). Δοκιμάστε το ξανά`,
        error_20706: `Αποτυχία αποστολής μηνύματος ηλεκτρονικού ταχυδρομείου. Δοκιμάστε το ξανά`,
        error_20801: `Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 20801)`,
        error_20802: `Σφάλμα: 20802. Επικοινωνήστε μαζί μας`,
        error_20803: `Εισαγάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 20803)`,
        error_20804: `Το email δεν είναι έγκυρο (κωδικός σφάλματος: 20804)`,
        error_20805: `Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 20805)`,
        error_20806: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20806). Δοκιμάστε το ξανά`,
        error_20808: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20808). Δοκιμάστε το ξανά`,
        error_20901: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20901). Δοκιμάστε το ξανά`,
        error_20902: `Το αίτημα απέτυχε (κωδικός σφάλματος: 20902). Δοκιμάστε το ξανά`,
        error_21000: `Οι αλλαγές αποθηκεύονται`,
        error_21001: `Δεν υποβάλλονται πληροφορίες (κωδικός σφάλματος: 21001)`,
        error_21002: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21002). Δοκιμάστε το ξανά`,
        error_21101: `Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 21101)`,
        error_21102: `Το email δεν είναι έγκυρο (κωδικός σφάλματος: 21102)`,
        error_21103: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21103), δοκιμάστε ξανά`,
        error_21104: `Το email είναι ήδη συνδεδεμένο, χρησιμοποιήστε ένα νέο`,
        error_21105: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21105), δοκιμάστε το ξανά`,
        error_21106: `Αποτυχία αποστολής μηνύματος ηλεκτρονικού ταχυδρομείου. Δοκιμάστε το ξανά`,
        error_21201: `Παρακαλώ εισάγετε το email σας (κωδικός σφάλματος: 21201)`,
        error_21202: `Παρακαλώ εισάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 21202)`,
        error_21203: `Το email δεν είναι έγκυρο (κωδικός σφάλματος: 21203)`,
        error_21204: `Σφάλμα: 21204. Επικοινωνήστε μαζί μας`,
        error_21205: `Σφάλμα: 21205. Επικοινωνήστε μαζί μας`,
        error_21206: `Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 21206)`,
        error_21207: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21207). Δοκιμάστε το ξανά`,
        error_21209: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21209). Δοκιμάστε το ξανά`,
        error_21301: `Παρακαλώ εισάγετε τον παλιό κωδικό πρόσβασης (κωδικός σφάλματος: 21301)`,
        error_21302: `Δημιουργήστε έναν νέο κωδικό πρόσβασης (κωδικός σφάλματος: 21302)`,
        error_21303: `Ο νέος κωδικός πρόσβασης δεν μπορεί να είναι ίδιος με τον παλιό. (Σφάλμα: 21303)`,
        error_21304: `Απαιτείται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 21304)`,
        error_21306: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21306). Δοκιμάστε το ξανά`,
        error_21402: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21402). Δοκιμάστε το ξανά`,
        error_21403: `Αποτυχία αποστολής κωδικού επαλήθευσης. Παρακαλώ στείλτε το ξανά`,
        error_21500: `Ο λογαριασμός έχει διαγραφεί`,
        error_21501: `Παρακαλώ εισάγετε τον κωδικό επαλήθευσης (κωδικός σφάλματος: 21501)`,
        error_21502: `Η περίοδος σύνδεσης έχει λήξει (Σφάλμα: 21502). Παρακαλώ συνδεθείτε ξανά.`,
        error_21503: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21503). Δοκιμάστε το ξανά`,
        error_21505: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21505), δοκιμάστε το ξανά`,
        error_21601: `Σφάλμα: 20601. Επικοινωνήστε μαζί μας`,
        error_21602: `Μη έγκυρη επαλήθευση (Σφάλμα: 20602). Δοκιμάστε το ξανά.`,
        error_21603: `Σφάλμα: 20603. Δοκιμάστε ξανά`,
        error_21604: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21604). Δοκιμάστε το ξανά`,
        error_21606: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21606). Δοκιμάστε το ξανά`,
        error_21611: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21611). Δοκιμάστε το ξανά`,
        error_21801: `Σφάλμα: 21801. Επικοινωνήστε μαζί μας`,
        error_21802: `Το αίτημα απέτυχε (Σφάλμα: 21802). Δοκιμάστε το ξανά`,
        error_21803: `Σφάλμα: 21803. Δοκιμάστε το ξανά`,
        error_21804: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21804). Δοκιμάστε το ξανά`,
        error_21806: `Σφάλμα: 21806. Δοκιμάστε το ξανά`,
        error_21807: `Σφάλμα: 21807. Επικοινωνήστε μαζί μας`,
        error_21808: `Σφάλμα: 21808. Επικοινωνήστε μαζί μας`,
        error_21809: `Σφάλμα: 21809. Επικοινωνήστε μαζί μας`,
        error_21810: `Σφάλμα: 21810. Επικοινωνήστε μαζί μας`,
        error_21811: `Σφάλμα: 21811. Επικοινωνήστε μαζί μας`,
        error_21812: `Σφάλμα: 21812. Επικοινωνήστε μαζί μας`,
        error_21813: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21813). Δοκιμάστε το ξανά`,
        error_21814: `Σφάλμα: 21814. Επικοινωνήστε μαζί μας`,
        error_21815: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21815). Δοκιμάστε το ξανά`,
        error_21816: `Σφάλμα: 21816. Επικοινωνήστε μαζί μας`,
        error_21817: `Σφάλμα: 21817. Επικοινωνήστε μαζί μας`,
        error_21818: `Σφάλμα: 21818. Επικοινωνήστε μαζί μας`,
        error_21819: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21819). Δοκιμάστε το ξανά`,
        error_21820: `Σφάλμα: 21820. Επικοινωνήστε μαζί μας`,
        error_21821: `Σφάλμα: 21821. Επικοινωνήστε μαζί μας`,
        error_21822: `Σφάλμα: 21822. Επικοινωνήστε μαζί μας`,
        error_21823: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21823). Δοκιμάστε το ξανά`,
        error_21824: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21824). Δοκιμάστε το ξανά`,
        error_21825: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21825). Δοκιμάστε το ξανά`,
        error_21826: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21826). Δοκιμάστε το ξανά`,
        error_21901: `Σφάλμα: 21901. Επικοινωνήστε μαζί μας`,
        error_21902: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21902). Δοκιμάστε το ξανά`,
        error_21903: `Η κατάσταση του λογαριασμού άλλαξε (κωδικός σφάλματος: 21903), ανανεώστε τη σελίδα και δοκιμάστε ξανά`,
        error_21904: `Σφάλμα: 21904. Δοκιμάστε το ξανά`,
        error_21905: `Σφάλμα: 21905. Δοκιμάστε το ξανά`,
        error_21906: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21906). Δοκιμάστε το ξανά`,
        error_21907: `Ο λογαριασμός Google έχει συνδεθεί με άλλο λογαριασμό`,
        error_21908: `Το αίτημα απέτυχε (κωδικός σφάλματος: 21908). Δοκιμάστε το ξανά`,
        error_22001: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22001). Δοκιμάστε το ξανά`,
        error_22002: `Η αποσύνδεση απέτυχε χωρίς επιπλέον σύνδεση`,
        error_22003: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22003). Δοκιμάστε το ξανά`,
        error_22101: `Σφάλμα: 22101. Επικοινωνήστε μαζί μας`,
        error_22102: `Η κατάσταση του λογαριασμού άλλαξε (κωδικός σφάλματος: 22102), ανανεώστε τη σελίδα και δοκιμάστε ξανά`,
        error_22103: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22103). Δοκιμάστε το ξανά`,
        error_22104: `Η κατάσταση του λογαριασμού άλλαξε (κωδικός σφάλματος: 22104), ανανεώστε τη σελίδα και δοκιμάστε ξανά`,
        error_22105: `Σφάλμα: 22105. Δοκιμάστε το ξανά`,
        error_22106: `Σφάλμα: 22106. Δοκιμάστε το ξανά`,
        error_22107: `Σφάλμα: 22107. Επικοινωνήστε μαζί μας`,
        error_22108: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22108). Δοκιμάστε το ξανά`,
        error_22201: `Σφάλμα: 22201. Επικοινωνήστε μαζί μας`,
        error_22202: `Μη έγκυρη επαλήθευση (Σφάλμα: 22202). Δοκιμάστε το ξανά.`,
        error_22203: `Σφάλμα: 22203. Δοκιμάστε το ξανά "`,
        error_22204: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22204). Δοκιμάστε το ξανά`,
        error_22206: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22206). Δοκιμάστε το ξανά`,
        error_22401: `Σφάλμα: 22401. Επικοινωνήστε μαζί μας`,
        error_22402: `Μη έγκυρη επαλήθευση (Σφάλμα: 22402). Δοκιμάστε το ξανά.`,
        error_22403: `Σφάλμα: 22403. Δοκιμάστε το ξανά`,
        error_22404: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22404). Δοκιμάστε το ξανά`,
        error_22405: `Ο λογαριασμός Facebook έχει συνδεθεί με άλλα μηνύματα ηλεκτρονικού ταχυδρομείου`,
        error_22406: `Σφάλμα: 22406. Δοκιμάστε το ξανά`,
        error_22407: `Σφάλμα: 22407. Επικοινωνήστε μαζί μας`,
        error_22408: `Σφάλμα: 22408. Επικοινωνήστε μαζί μας`,
        error_22409: `Σφάλμα: 22409. Επικοινωνήστε μαζί μας`,
        error_22410: `Σφάλμα: 224010. Επικοινωνήστε μαζί μας`,
        error_22411: `Σφάλμα: 224011. Επικοινωνήστε μαζί μας`,
        error_22412: `Σφάλμα: 224012. Επικοινωνήστε μαζί μας`,
        error_22413: `Σφάλμα: 22413. Επικοινωνήστε μαζί μας`,
        error_22414: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22414). Δοκιμάστε το ξανά`,
        error_22415: `Σφάλμα: 22415. Επικοινωνήστε μαζί μας`,
        error_22416: `Σφάλμα: 22416. Επικοινωνήστε μαζί μας`,
        error_22417: `Σφάλμα: 22417. Επικοινωνήστε μαζί μας`,
        error_22418: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22418). Δοκιμάστε το ξανά`,
        error_22419: `Σφάλμα: 22419. Επικοινωνήστε μαζί μας`,
        error_22420: `Σφάλμα: 22420. Επικοινωνήστε μαζί μας`,
        error_22421: `Σφάλμα: 22421. Επικοινωνήστε μαζί μας`,
        error_22422: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22422). Δοκιμάστε το ξανά`,
        error_22423: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22423). Δοκιμάστε το ξανά`,
        error_22424: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22424). Δοκιμάστε το ξανά`,
        error_22425: `Το αίτημα απέτυχε (κωδικός σφάλματος: 22425). Δοκιμάστε το ξανά`,
        error_20098: `Κωδικός σφάλματος: 20098. Εάν κάνετε περιήγηση σε ιδιωτική λειτουργία, μεταβείτε στην Κανονική λειτουργία και δοκιμάστε ξανά.`,
        error_22298: `Το αίτημα για Google απέτυχε (Σφάλμα: 22298). Δοκιμάστε το ξανά.`,
        error_22498: `Το αίτημα για το Facebook απέτυχε (Σφάλμα: 22498). Δοκιμάστε το ξανά.`,
        error_24902: `Το αίτημα απέτυχε (κωδικός σφάλματος: 24902). Δοκιμάστε το ξανά`,
        error_24903: `Το αίτημα απέτυχε (κωδικός σφάλματος: 24903). Δοκιμάστε το ξανά`,
        error_24904: `Το αίτημα απέτυχε (κωδικός σφάλματος: 24904). Δοκιμάστε το ξανά`,
        error_24905: `Το αίτημα απέτυχε (κωδικός σφάλματος: 24905). Δοκιμάστε το ξανά`,
        login_title: `Συνδεθείτε στο Tipard`,
        password_placeholder: `Κωδικός πρόσβασης`,
        log_in: `Σύνδεση`,
        no_account: `Χωρίς λογαριασμό;`,
        create_it: `Δημιουργησε το`,
        or_log_in_with: `Or συνδεθείτε με`,
        passwordless_login: `Σύνδεση χωρίς κωδικό πρόσβασης`,
        log_in_done: `Σύνδεση Ολοκληρώθηκε`,
        three_rd_account_connect_info: `Συγχαρητήρια! Έχετε συνδεθεί με επιτυχία. Τώρα μπορείτε να συνδέσετε έναν λογαριασμό email που χρησιμοποιείται για να συνδεθείτε στο μέλλον.`,
        see_my_account: `Δείτε τον λογαριασμό μου`,
        three_rd_login_merge_account: `Η διεύθυνση ηλεκτρονικού ταχυδρομείου του λογαριασμού τρίτου μέρους έχει εγγραφεί, θέλετε να συνδεθείτε και να συνδεθείτε απευθείας με αυτήν τη διεύθυνση ηλεκτρονικού ταχυδρομείου;`,
        connect_log_in: `Σύνδεση & Σύνδεση`,
        create_an_account: `Δημιουργία λογαριασμού`,
        back_to_log_in: `Επιστροφή στην σελίδα εισόδου`,
        create_password: `Δημιούργησε κωδικό`,
        create_now: `Δημιουργία τώρα`,
        password_login_subtitle: `Σύνδεση χωρίς κωδικό πρόσβασης με email`,
        account_login: `Είσοδος σε λογαριασμό`,
        rights: `Με τη δημιουργία αυτού του λογαριασμού, συμφωνείτε με τους <a href="https://el.tipard.com/terms.html"> Όρους Παροχής Υπηρεσιών </a> και <a href="https://el.tipard.com/policy.html"> Πολιτική απορρήτου </a>`,
        passwordless_login_done: `Ολοκληρώθηκε η σύνδεση χωρίς κωδικό πρόσβασης`,
        passwordless_login_info: `Συγχαρητήρια, ολοκληρώσατε επιτυχώς τη σύνδεση χωρίς κωδικό πρόσβασης. Μπορείτε να δημιουργήσετε έναν κωδικό πρόσβασης για αυτόν τον λογαριασμό και να συνδεθείτε με τον λογαριασμό και τον κωδικό πρόσβασης στο μέλλον. <a href="/create-password" style="display: initial;"> Δημιουργία τώρα </a>`,
        sign_up: `Εγγραφείτε`,
        register_info: `Δημιούργησε τον λογαριασμό σου`,
        reset_now: `Επαναφορά τώρα`,
        forgot_password: `Ξεχάσατε τον κωδικό`,
        reset_password_subtitle: `Χρησιμοποιήστε το email του λογαριασμού σας για να επαναφέρετε τον κωδικό πρόσβασης`,
        plan_products: `Σχέδιο & Προϊόντα`,
        nick_name: `Όνομα χρήστη`,
        email: `ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ`,
        my_products: `Τα Προϊόντα Μου`,
        my_orders: `Οι παραγγελίες μου`,
        unlink: `Αποσύνδεση`,
        link: `Σύνδεσμος`,
        connected_accounts: `Συνδεδεμένοι λογαριασμοί`,
        last_name: `Επίθετο`,
        first_name: `Ονομα`,
        Gender: `Γένος`,
        Birth: `Γέννηση`,
        Month: `Μήνας`,
        Year: `Ετος`,
        Country_Region: `Χώρα/Περιφέρεια`,
        Address: `Διεύθυνση`,
        Save: `Αποθηκεύσετε`,
        Date: `Ημερομηνία`,
        Male: `Αρσενικός`,
        Female: `Θηλυκός`,
        Unspecified: `Απροσδιόριστος`,
        Security: `Ασφάλεια`,
        change_password: `Αλλαξε κωδικό`,
        change_now: `Αλλάξτε τώρα`,
        connect_email: `Συνδέστε το email`,
        delete_account: `Διαγραφή λογαριασμού`,
        delete_account_info: `Όταν διαγραφεί ο λογαριασμός σας, όλα τα δεδομένα στο Plan & Products που σχετίζονται με τον λογαριασμό σας θα διαγραφούν οριστικά και ενδέχεται να μην μπορείτε να τα ανακτήσετε. Σας προτείνουμε να πατήσετε πολεμικά.`,
        Delete: `Διαγράφω`,
        Logout: `Αποσύνδεση`,
        my_profile: `Το ΠΡΟΦΙΛ μου`,
        guides_faqs: `Οδηγοί & Συχνές Ερωτήσεις`,
        More: `Περισσότερο`,
        guides: `Οδηγοί`,
        register: `κανω ΕΓΓΡΑΦΗ`,
        hot_faq: `Καυτό FAQ`,
        Contents: `Περιεχόμενα:`,
        contact_us: `Επικοινωνήστε μαζί μας >>`,
        plan: `Σχέδιο`,
        unregistered: `Αδήλωτος`,
        buy_more: `Αγόρασε περισσότερα`,
        buy_again: `Αγοράστε ξανά`,
        buy_now: `Αγορασε τωρα`,
        free_no_limit: `Δωρεάν & Χωρίς όριο`,
        expired: `έχει λήξει`,
        lifetime: `Διάρκεια Ζωής`,
        remain: `Παραμένει`,
        day_s: `Μέρες)`,
        day: `Μέρες`,
        error_24801: `Το αίτημα απέτυχε (κωδικός σφάλματος: 24801). Δοκιμάστε το ξανά`,
        no_app_found: `Δεν βρέθηκε εφαρμογή！<a href="/">Ανανέωση</a> ή <a href="https://el.tipard.com/">Μεταβείτε στον επίσημο ιστότοπο</a>`,
        get_more: `Λάβετε περισσότερα >>`,
        edit_photo: `Επεξεργασία φωτογραφίας`,
        select_photo: `Επιλέξτε φωτογραφία`,
        change_photo: `Αλλαξε φωτογραφία`,
        cancel: `Ματαίωση`,
        hide_password: `Απόκρυψη κωδικού πρόσβασης`,
        show_password: `Δείξε τον κωδικό`,
        zoom_in: `Μεγέθυνση`,
        zoom_out: `Σμίκρυνση`,
        rotate: `Γυρίζω`,
        horizontal_flip: `Οριζόντια ανατροπή`,
        vertical_flip: `Κατακόρυφη ανατροπή`,
        country: `Χώρα`,
        country_1: `Επιλέξτε τη χώρα/περιοχή σας`,
        country_2: `Νησί Åaland`,
        country_3: `Αφγανιστάν`,
        country_4: `Αλβανία`,
        country_5: `Αλγερία`,
        country_6: `Αμερικάνικη Σαμόα`,
        country_7: `Ανδόρα`,
        country_8: `Αγκόλα`,
        country_9: `Ανγκουίλα`,
        country_10: `Ανταρκτική`,
        country_11: `Αντίγκουα & Μπαρμπούντα`,
        country_12: `Αργεντίνη`,
        country_13: `Αρμενία`,
        country_14: `Αρούμπα`,
        country_15: `Αυστραλία`,
        country_16: `Αυστρία`,
        country_17: `Αζερμπαϊτζάν`,
        country_18: `Μπαχρέιν`,
        country_19: `Μπαγκλαντές`,
        country_20: `Μπαρμπάντος`,
        country_21: `Λευκορωσία`,
        country_22: `Βέλγιο`,
        country_23: `Μπελίζ`,
        country_24: `Μπενίν`,
        country_25: `Βερμούδα`,
        country_26: `Μπουτάν`,
        country_27: `Βολιβία`,
        country_28: `Βοσνία-Ερζεγοβίνη`,
        country_29: `Μποτσουάνα`,
        country_30: `Νησί Μπουβέ`,
        country_31: `Βραζιλία`,
        country_32: `Βρετανική επικράτεια Ινδικού Ωκεανού`,
        country_33: `Βρετανικές Παρθένοι Νήσοι`,
        country_34: `Μπρουνέι`,
        country_35: `Βουλγαρία`,
        country_36: `Μπουρκίνα`,
        country_37: `Μπουρούντι`,
        country_38: `Καμπότζη`,
        country_39: `Καμερούν`,
        country_40: `Καναδάς`,
        country_41: `Πράσινο Ακρωτήριο`,
        country_42: `Καραϊβική Ολλανδία`,
        country_43: `Νησιά Κέιμαν`,
        country_44: `Δημοκρατία Κεντρικής Αφρικής`,
        country_45: `Τσαντ`,
        country_46: `χιλή`,
        country_47: `Κίνα`,
        country_48: `Νησί των Χριστουγέννων`,
        country_49: `Νησιά Cocos (Keeling).`,
        country_50: `Κολομβία`,
        country_51: `Νησιά Κουκ`,
        country_52: `Κόστα Ρίκα`,
        country_53: `"Ακτή Ελεφαντοστού"`,
        country_54: `την Κροατία`,
        country_55: `Κούβα`,
        country_56: `Κύπρος`,
        country_57: `Τσεχική Δημοκρατία`,
        country_58: `Λαϊκή Δημοκρατία του Κονγκό`,
        country_59: `Δανία`,
        country_60: `Τζιμπουτί`,
        country_61: `Δομίνικα`,
        country_62: `Δομινικανή Δημοκρατία`,
        country_63: `Εκουαδόρ`,
        country_64: `Αίγυπτος`,
        country_65: `Ελ Σαλβαδόρ`,
        country_66: `Ισημερινή Γουινέα`,
        country_67: `Ερυθραία`,
        country_68: `Εσθονία`,
        country_69: `Αιθιοπία`,
        country_70: `Νησιά Φώκλαντ`,
        country_71: `Νήσοι Φερόες`,
        country_72: `Ομόσπονδες Πολιτείες της Μικρονησίας`,
        country_73: `Φίτζι`,
        country_74: `Φινλανδία`,
        country_75: `Γαλλία`,
        country_76: `Γαλλική Γουιάνα`,
        country_77: `Γαλλική Πολυνησία`,
        country_78: `Γαλλικά Νότια Εδάφη`,
        country_79: `Γκαμπόν`,
        country_80: `Γκάμπια`,
        country_81: `Γεωργία`,
        country_82: `Γερμανία`,
        country_83: `Γκάνα`,
        country_84: `Γιβραλτάρ`,
        country_85: `Μεγάλη Βρετανία (Ηνωμένο Βασίλειο, Αγγλία)`,
        country_86: `Ελλάδα`,
        country_87: `Γροιλανδία`,
        country_88: `Γρενάδα`,
        country_89: `Γουαδελούπη`,
        country_90: `Γκουάμ`,
        country_91: `Γουατεμάλα`,
        country_92: `Γκέρνσεϊ`,
        country_93: `Γκινέα`,
        country_94: `Γουινέα-Μπισάου`,
        country_95: `Γουιάνα`,
        country_96: `Αΐτη`,
        country_97: `Heard Island και McDonald Islands`,
        country_98: `Ονδούρα`,
        country_99: `Χονγκ Κονγκ`,
        country_100: `Ουγγαρία`,
        country_101: `Ισλανδία`,
        country_102: `Ινδία`,
        country_103: `Ινδονησία`,
        country_104: `Ιράν`,
        country_105: `Ιράκ`,
        country_106: `Ιρλανδία`,
        country_107: `Isle of Man`,
        country_108: `Ισραήλ`,
        country_109: `Ιταλία`,
        country_110: `Ιαμαϊκή`,
        country_111: `Ιαπωνία`,
        country_112: `Φανέλα`,
        country_113: `Ιορδανία`,
        country_114: `Καζακστάν`,
        country_115: `Κενύα`,
        country_116: `Κιριμπάτι`,
        country_117: `Κουβέιτ`,
        country_118: `Κιργιζιστάν`,
        country_119: `Λάος`,
        country_120: `Λετονία`,
        country_121: `Λίβανος`,
        country_122: `Λεσότο`,
        country_123: `Λιβερία`,
        country_124: `Λιβύη`,
        country_125: `Λιχτενστάιν`,
        country_126: `Λιθουανία`,
        country_127: `Λουξεμβούργο`,
        country_128: `Μακάο`,
        country_129: `Μαδαγασκάρη`,
        country_130: `Μαλάουι`,
        country_131: `Μαλαισία`,
        country_132: `Μαλδίβες`,
        country_133: `Μάλι`,
        country_134: `Μάλτα`,
        country_135: `Νήσοι Μάρσαλ`,
        country_136: `Μαρτινίκα`,
        country_137: `Μαυριτανία`,
        country_138: `Μαυρίκιος`,
        country_139: `Μαγιότ`,
        country_140: `Μεξικό`,
        country_141: `Μολδαβία`,
        country_142: `Μονακό`,
        country_143: `Μογγολία`,
        country_144: `Μαυροβούνιο`,
        country_145: `Μονσεράτ`,
        country_146: `Μαρόκο`,
        country_147: `Μοζαμβίκη`,
        country_148: `Μιανμάρ (Βιρμανία)`,
        country_149: `Ναμίμπια`,
        country_150: `Ναούρου`,
        country_151: `Νεπάλ`,
        country_152: `Ολλανδία`,
        country_153: `Νέα Καληδονία`,
        country_154: `Νέα Ζηλανδία`,
        country_155: `Νικαράγουα`,
        country_156: `Νίγηρας`,
        country_157: `Νιγηρία`,
        country_158: `Niue`,
        country_159: `Νησί Νόρφολκ`,
        country_160: `Βόρεια Κορέα`,
        country_161: `Βορεια Νησια Μαριανα`,
        country_162: `Νορβηγία`,
        country_163: `Ομάν`,
        country_164: `Πακιστάν`,
        country_165: `Παλάου`,
        country_166: `παλαιστινιακά εδάφη`,
        country_167: `Παναμάς`,
        country_168: `Παπούα Νέα Γουινέα`,
        country_169: `Παραγουάη`,
        country_170: `Περού`,
        country_171: `Νησιά Πίτκερν`,
        country_172: `Πολωνία`,
        country_173: `Πορτογαλία`,
        country_174: `Πουέρτο Ρίκο`,
        country_175: `Κατάρ`,
        country_176: `Δημοκρατία της Μακεδονίας (FYROM)`,
        country_177: `Δημοκρατία του Κονγκό`,
        country_178: `Επανένωση`,
        country_179: `Ρουμανία`,
        country_180: `Ρωσική Ομοσπονδία`,
        country_181: `Ρουάντα`,
        country_182: `Άγιος Βαρθολομαίος`,
        country_183: `Saint Martin (Γαλλία)`,
        country_184: `Σεν Πιερ και Μικελόν`,
        country_185: `Σαμόα`,
        country_186: `Σαν Μαρίνο`,
        country_187: `Σάο Τομέ & Πρίνσιπε`,
        country_188: `Σαουδική Αραβία`,
        country_189: `Σενεγάλη`,
        country_190: `Σερβία`,
        country_191: `Σεϋχέλλες`,
        country_192: `Σιέρρα Λεόνε`,
        country_193: `Σιγκαπούρη`,
        country_194: `Σλοβακία`,
        country_195: `Σλοβενία`,
        country_196: `Νησιά του Σολομώντα`,
        country_197: `Σομαλία`,
        country_198: `Νότια Αφρική`,
        country_199: `Νότια Γεωργία και το Νότιο Σάντουιτς Νήσος`,
        country_200: `Νότια Κορέα`,
        country_201: `Νότιο Σουδάν`,
        country_202: `Ισπανία`,
        country_203: `Σρι Λάνκα`,
        country_204: `Αγία Ελένη & Εξαρτήσεις`,
        country_205: `Σεντ Κιτς & Νέβις`,
        country_206: `Αγία Λουκία`,
        country_207: `Άγιος Βικέντιος & Γρεναδίνες`,
        country_208: `Σουδάν`,
        country_209: `Σουρινάμ`,
        country_210: `Σουαζιλάνδη`,
        country_211: `Σουηδία`,
        country_212: `Ελβετία`,
        country_213: `Συρία`,
        country_214: `Ταϊβάν`,
        country_215: `Τατζικιστάν`,
        country_216: `Τανζανία`,
        country_217: `Πρότυπο:Country data SJM Svalbard`,
        country_218: `Ταϊλάνδη`,
        country_219: `Οι Μπαχάμες`,
        country_220: `Οι Κομόρες`,
        country_221: `Οι Φιλιππίνες`,
        country_222: `Ανατολικό Τιμόρ (Ανατολικό Τιμόρ)`,
        country_223: `Να πάω`,
        country_224: `Τοκελάου`,
        country_225: `Τόνγκα`,
        country_226: `Τρινιντάντ & Τομπάγκο`,
        country_227: `Τυνησία`,
        country_228: `Τουρκία`,
        country_229: `Τουρκμενιστάν`,
        country_230: `Νησιά Turks & Caicos`,
        country_231: `Τουβαλού`,
        country_232: `Ουγκάντα`,
        country_233: `Ουκρανία`,
        country_234: `Ηνωμένα Αραβικά Εμιράτα`,
        country_235: `Μικρά Απομακρυσμένα Νησιά των Ηνωμένων Πολιτειών`,
        country_236: `Ηνωμένες Πολιτείες Αμερικής (ΗΠΑ)`,
        country_237: `Παρθένοι Νήσοι των Ηνωμένων Πολιτειών`,
        country_238: `Ουρουγουάη`,
        country_239: `Ουζμπεκιστάν`,
        country_240: `Βανουάτου`,
        country_241: `Πόλη του Βατικανού (Αγία Έδρα)`,
        country_242: `Βενεζουέλα`,
        country_243: `Βιετνάμ`,
        country_244: `Wallis και Futuna`,
        country_245: `Δυτική Σαχάρα`,
        country_246: `Γέμενη`,
        country_247: `Ζάμπια`,
        country_248: `Ζιμπάμπουε`,
        google_login: `Συνδεθείτε με το Google`,
        State: `κατάσταση`,
        Activation_code: `Κωδικός ενεργοποίησης`,
        Question: `Καταχωρίστε όλες τις εφαρμογές στις οποίες έχετε συνδεθεί`,
        Copy_complete: `Η αντιγραφή ολοκληρώθηκε`,
        change_password_success: `Ο κωδικός άλλαξε επιτυχώς`,
        successful_login_title: `Επιτυχία σύνδεσης`,
        product_page: `Σελίδα προϊόντος >>`,
        successful_login_info: `Η σύνδεση ολοκληρώθηκε. Κλείστε την τρέχουσα σελίδα και επιστρέψτε στην αρχική καρτέλα για να συνεχίσετε με την ακόλουθη διαδικασία. Η τρέχουσα σελίδα θα κλείσει αυτόματα σε 5 δευτερόλεπτα. Εάν το αυτόματο κλείσιμο ή το κλείσιμο κάνοντας κλικ στο κουμπί "Τέλος" αποτύχει, κλείστε απευθείας αυτήν την καρτέλα.`,
        successful_login_info_firefox: `Η σύνδεση ολοκληρώθηκε. Κλείστε την τρέχουσα σελίδα και επιστρέψτε στην αρχική καρτέλα για να συνεχίσετε με την ακόλουθη διαδικασία.`,
        my_account: `Ο λογαριασμός μου`,
        my_history: `Η Ιστορία μου`,
        remove_watermark: `Αφαιρέστε το υδατογράφημα`,
        no_history: `Χωρίς Ιστορία`,
        history_all: `Επιλογή όλων`,
        history_open: `Ανοιξε`,
        history_down: `Κατεβάστε`,
        history_delete: `Διαγράφω`,
        history_clear: `Διαγραφή μη έγκυρου`,
        images: `εικόνα(εις)`,
        use_this_function: `Χρησιμοποιήστε αυτήν τη συνάρτηση >>`,
        hd_downloading: `Πλεονεκτήματα λήψης πρωτότυπων εικόνων HD:`,
        lifetimeRemaining: `Lifetime-Remaining`,
        Remaining: `Παραμένων`,
        email_verification: `Επαλήθευση email`,
        email_verification_info: `Έχουμε στείλει το μήνυμα επαλήθευσης στο email σας <span class="email"></span> και ολοκληρώστε την επαλήθευση. Μετά την επαλήθευση, τα οφέλη θα συγχρονιστούν αυτόματα.`,
        wrong_email: `Λάθος διεύθυνση email;`,
        click_here_to_modify: `Κάντε κλικ εδώ για τροποποίηση`,
        get_more_help: `Λάβετε περισσότερη βοήθεια;`,
        click_here: `Κάντε κλικ ΕΔΩ`,
        email_verification_info_success: `Συγχαρητήρια για την επαλήθευση του λογαριασμού email σας.`,
        email_verification_info_error: `Η επαλήθευση απέτυχε λόγω του ληγμένου συνδέσμου.`,
        registration_succeeded: `Η εγγραφή ολοκληρώθηκε`,
        registration_succeeded_info_1: `Συγχαρητήρια! Έχετε εγγραφεί επιτυχώς. Έχουμε στείλει το μήνυμα επαλήθευσης στο email σας <span class="email"></span> και ολοκληρώστε την επαλήθευση για να χρησιμοποιήσετε τα πλεονεκτήματα αυτού του μηνύματος ηλεκτρονικού ταχυδρομείου.`,
        registration_succeeded_info_2: `Κάντε κλικ στο "Τέλος" για να επιστρέψετε στην Αρχική σελίδα και να χρησιμοποιήσετε αυτό το προϊόν.`,
        registration_succeeded_info_3: `Κάντε κλικ στο "Τέλος" για να κλείσετε την τρέχουσα σελίδα και να επιστρέψετε στην Αρχική σελίδα για τις ακόλουθες λειτουργίες. Εάν το κλείσιμο αποτύχει, κλείστε χειροκίνητα την καρτέλα.`,
        verify_email: `Επαλήθευση email`,
        registered_email_not_verified: `Το καταχωρημένο email δεν έχει επαληθευτεί, επαληθεύστε το αμέσως.`,
        email_verification_time_1: `Δεν λάβατε email επαλήθευσης;`,
        email_verification_time_2: `Μετά`,
        email_verification_time_3: `Κάντε κλικ εδώ για να το ξαναστείλετε`,
        error_26301: `Κωδικός σφάλματος: 26301, επικοινωνήστε μαζί μας`,
        error_26302: `Κωδικός σφάλματος: 26302, επικοινωνήστε μαζί μας`,
        error_26303: `Σφάλμα μορφής email (κωδικός σφάλματος: 26303). Εισαγάγετε το ξανά`,
        error_26304: `Συνιστάται κωδικός πρόσβασης άνω των 8 χαρακτήρων (κωδικός σφάλματος: 26304)`,
        error_26305: `Το αίτημα απέτυχε (κωδικός σφάλματος: 26305). Παρακαλώ δοκιμάστε το ξανά`,
        error_26306: `Το email έχει καταχωρηθεί, <a href="https://account.tipard.com/login">μεταβείτε για να συνδεθείτε</a>`,
        error_26307: `Το αίτημα απέτυχε (κωδικός σφάλματος: 26307). Παρακαλώ δοκιμάστε το ξανά`,
        error_26308: `Το αίτημα απέτυχε (κωδικός σφάλματος: 26308). Παρακαλώ δοκιμάστε το ξανά`,
        error_26401: `Κωδικός σφάλματος: 26401, δοκιμάστε ξανά`,
        error_26402: `Το email έχει επαληθευτεί (κωδικός σφάλματος: 26402), δοκιμάστε το ξανά`,
        error_26403: `Το αίτημα απέτυχε (κωδικός σφάλματος: 26403). Παρακαλώ δοκιμάστε το ξανά`,
        error_26404: `Το αίτημα απέτυχε (κωδικός σφάλματος: 26404). Παρακαλώ δοκιμάστε το ξανά`,
        error_26501: `Κωδικός σφάλματος: 26501, επικοινωνήστε μαζί μας`,
        error_26502: `Κωδικός σφάλματος: 26502, επικοινωνήστε μαζί μας`,
        error_26503: `Σφάλμα μορφής email (κωδικός σφάλματος: 26503). Εισαγάγετε το ξανά`,
        error_26504: `Το αίτημα απέτυχε (κωδικός σφάλματος: 26504). Παρακαλώ δοκιμάστε το ξανά`,
        error_26505: `Το email δεν έχει καταχωρηθεί, <a href="https://account.tipard.com/register">εγγραφείτε πρώτα</a>`,
        error_26506: `Το email έχει επαληθευτεί.`,
        error_26507: `Το αίτημα απέτυχε (κωδικός σφάλματος: 26507). Παρακαλώ δοκιμάστε το ξανά`,
        error_26508: `Η επαλήθευση ολοκληρώθηκε (κωδικός σφάλματος: 26508), δοκιμάστε ξανά`,
        error_26509: `Το αίτημα απέτυχε (κωδικός σφάλματος: 26509), δοκιμάστε το ξανά`,
        error_26510: `Κωδικός σφάλματος: 26510, επικοινωνήστε μαζί μας`,
        error_26601: `Κωδικός σφάλματος: 26601, επικοινωνήστε μαζί μας`,
        error_26602: `Κωδικός σφάλματος: 26602, επικοινωνήστε μαζί μας`,
        error_26603: `Το αίτημα απέτυχε (κωδικός σφάλματος: 26603). Παρακαλώ δοκιμάστε το ξανά`,
        error_26604: `Κωδικός σφάλματος: 26604, επικοινωνήστε μαζί μας`,
        error_26605: `Κωδικός σφάλματος: 26605, επικοινωνήστε μαζί μας`,
        error_26701: `Κωδικός σφάλματος: 26701, επικοινωνήστε μαζί μας`,
        error_26702: `Το αίτημα απέτυχε (κωδικός σφάλματος: 26702). Παρακαλώ δοκιμάστε το ξανά`,
        error_26703: `Κωδικός σφάλματος: 26703, επικοινωνήστε μαζί μας`,
        error_26704: `Κωδικός σφάλματος: 26704, επικοινωνήστε μαζί μας`,
        error_26705: `Περιμένετε να συνδεθείτε (κωδικός σφάλματος: 26705). Παρακαλώ δοκιμάστε το ξανά`,
        no_cookie: `Έχετε ενεργοποιήσει τη λειτουργία Αποκλεισμός όλων των cookies στο πρόγραμμα περιήγησής σας, επομένως δεν μπορείτε να συνδεθείτε. Μεταβείτε στις Ρυθμίσεις για να επιλέξετε το πλαίσιο Επιτρέπονται όλα τα cookies.`,
        error_26801: `Κωδικός σφάλματος: 26801, επικοινωνήστε μαζί μας`,
        error_26802: `Κωδικός σφάλματος: 26802, επικοινωνήστε μαζί μας`,
        error_26803: `Το αίτημα απέτυχε (κωδικός σφάλματος: 26803). Παρακαλώ δοκιμάστε το ξανά`,
        error_26804: `Το αίτημα απέτυχε (κωδικός σφάλματος: 26804). Παρακαλώ δοκιμάστε το ξανά`,
        error_order: `Το αίτημα απέτυχε (κωδικός σφάλματος: 27098), δοκιμάστε το ξανά!`,
        error_order1: `Το ερώτημα παραγγελίας είναι ελλιπές (κωδικός σφάλματος:`,
        error_order2: `）, ανανεώστε και δοκιμάστε το ξανά.`,
        modify_email_title: `Τροποποίηση email`,
        modify_email_info: `Μπορείτε να χρησιμοποιήσετε το τροποποιημένο email για να συνδεθείτε στο λογαριασμό σας.`,
        images_per: `Εικόνες ανά`,
        error_26101: `Σφάλμα: 26101. Επικοινωνήστε μαζί μας`,
        error_26102: `Σφάλμα: 26102. Επικοινωνήστε μαζί μας`,
        error_26103: `Το αίτημα απέτυχε (κωδικός λάθους:26103). Δοκιμάστε το ξανά`,
        error_26104: `Κωδικός σφάλματος: 26104, παρακαλώ δοκιμάστε το ξανά`,
        error_26105: `Κωδικός σφάλματος: 26105, παρακαλώ δοκιμάστε το ξανά`,
        error_26106: `Η διαγραφή απέτυχε (κωδικός σφάλματος: 26106). Δοκιμάστε το ξανά`,
        error_26201: `Σφάλμα: 26201. Επικοινωνήστε μαζί μας`,
        error_26202: `Το αίτημα απέτυχε (κωδικός λάθους:26202). Δοκιμάστε το ξανά`,
        error_26001: `Σφάλμα: 26001. Επικοινωνήστε μαζί μας`,
        error_26002: `Σφάλμα: 26002. Επικοινωνήστε μαζί μας`,
        error_26003: `Σφάλμα: 26003. Επικοινωνήστε μαζί μας`,
        error_26004: `Σφάλμα: 26004. Επικοινωνήστε μαζί μας`,
        error_26005: `Το αίτημα απέτυχε (κωδικός λάθους:26005). Δοκιμάστε το ξανά`,
        error_26006: `Κωδικός σφάλματος: 26006, παρακαλώ δοκιμάστε το ξανά`,
        error_26008: `Σφάλμα: 26008. Επικοινωνήστε μαζί μας`,
        go_to_the_home_page: `Μεταβείτε στην Αρχική σελίδα`,
        error_27101: `Το αίτημα απέτυχε (κωδικός σφάλματος: 27101). Παρακαλώ δοκιμάστε ξανά`,
        error_27201: `Κωδικός σφάλματος: 27201, επικοινωνήστε μαζί μας`,
        error_27202: `Κωδικός σφάλματος: 27202, δοκιμάστε ξανά`,
        error_27203: `Το αίτημα απέτυχε (κωδικός σφάλματος: 27203). Παρακαλώ δοκιμάστε το ξανά`,
        error_27204: `Μη έγκυρος κωδικός (κωδικός σφάλματος: 27204).`,
        error_27205: `Το αίτημα απέτυχε (κωδικός σφάλματος: 27205). Παρακαλώ δοκιμάστε το ξανά`,
        error_27206: `Το αίτημα απέτυχε (κωδικός σφάλματος: 27206). Παρακαλώ δοκιμάστε το ξανά`,
        error_27207: `Το αίτημα απέτυχε (κωδικός σφάλματος: 27207). Παρακαλώ δοκιμάστε το ξανά`,
        no_history_found: `Δεν έχεις χρησιμοποιήσει κανένα εργαλείο! <a href="/">Ανανέωση</a> ή <a href="https://el.tipard.com/">Μεταβείτε στον επίσημο ιστότοπο</a>`,
        error_25301: `Σφάλμα: 25301. Επικοινωνήστε μαζί μας`,
        error_25302: `Σφάλμα: 25302. Επικοινωνήστε μαζί μας`,
        error_25303: `Το αίτημα απέτυχε (κωδικός λάθους: 25303). Δοκιμάστε το ξανά`,
        error_25304: `Το αίτημα απέτυχε (κωδικός λάθους: 25304). Δοκιμάστε το ξανά`,
        error_25305: `Το αίτημα απέτυχε (κωδικός λάθους: 25305). Δοκιμάστε το ξανά`,
        error_25306: `Το αίτημα απέτυχε (κωδικός λάθους: 25306). Δοκιμάστε το ξανά`,
        image_upscaler_p: `Πλεονεκτήματα λήψης εικόνας χωρίς υδατογράφημα:`,
        Available_for: `Διαθέσιμο για:`,
        credit_per: `%s πιστώσεις ανά εικόνα HD`,
        still_valid: `Τα αγορασμένα προγράμματα εξακολουθούν να ισχύουν`,
        credit: `πίστωση(ες)`,
        pc_3rd_info: `Συνδεθείτε με επιτυχία. Μεταβείτε στην εφαρμογή για περαιτέρω λειτουργία.`,
        use_online: `Χρησιμοποιήστε την ηλεκτρονική υπηρεσία`,
        use_download: `Χρησιμοποιήστε το πρόγραμμα επιφάνειας εργασίας`,
        use_immediately: `Χρήση Αμέσως`,
        Use_in_browser: `Χρήση στο πρόγραμμα περιήγησης`,
        win_desktop: `Windows`,
        Mac_desktop: `Μακ`,
        credits_per: `{%} Πιστώσεις ανά μήνα`,
        expire: `Χρόνος λήξης:`,
        viewDetails: `Δείτε λεπτομέρειες`,
        viewHistory: `Δείτε τα προνόμια ιστορικού>>`,
        viewDetailsInfo: `Σημείωση: Εάν τα προνόμια της συνδρομής ανανεωθούν εντός 7 ημερών μετά τη λήξη, τα αχρησιμοποίητα προνόμια μπορούν να συνεχίσουν να χρησιμοποιούνται. Επίσης, ο χρόνος λήξης θα ενημερωθεί αυτόματα στον χρόνο λήξης της νέας συνδρομής. Εάν δεν υπάρξει νέα συνδρομή μετά από 7 ημέρες λήξης, όλα τα ληγμένα οφέλη θα διαγραφούν.`,
        "connect_account": "Συνδέστε το email στον λογαριασμό σας",
        "connect_account_info": "Μετά τη δέσμευση, μπορείτε να συνδεθείτε με αυτήν τη διεύθυνση email.",
        "connect_now": "Δέστε τώρα",
        "no_email_bind": "Χωρίς δέσμευση email",
        "bind_email": "Σύνδεση email",
        "connect_your_email_placeholder": "Παρακαλώ εισάγετε τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας",
        "bind_an_email": "Συνδέστε ένα email",
        "bind_info": "Έχετε συνδεθεί με επιτυχία. Συνδέστε ένα email στον λογαριασμό σας για να ενεργοποιήσετε τα προνόμιά σας.",
        "bind_later": "Δέστε αργότερα",
        "footer": "Πνευματικά δικαιώματα © Studio 2024 Tipard. Ολα τα δικαιώματα διατηρούνται.",
        "overtime": "Κωδικός σφάλματος: {%}, δοκιμάστε ξανά",
        "hi": "Γεια, %s!",
        "Personal_Information": "Προσωπικές Πληροφορίες",
        "Access": "Πρόσβαση",
        "Subscription_Plan": "(Πρόγραμμα συνδρομής)",
        "No_orders": "Δεν βρέθηκαν παραγγελίες.",
        "No_data": "Δεν υπάρχουν δεδομένα",
        "Featured_Products": "Επιλεγμένα Προϊόντα",
        "More_Products": "Περισσότερα Προϊόντα",
        "Free_Download": "Δωρεάν λήψη",
        "Get_Started": "Ξεκινήστε",
        "Subscribe": "Συνεισφέρω",
        "Verified": "Επαληθεύτηκε",
        "back_to_account_center": "Επιστροφή στο Κέντρο Λογαριασμού",
        "success": "Επιτυχία!",
        "successfully": "Έχετε καταχωρήσει με επιτυχία έναν λογαριασμό.",
        "Continue": "Συνεχίζω",
        "Already": "Έχετε ήδη λογαριασμό;",
        "loading_verification": "Έλεγχος κατάστασης επαλήθευσης...",
        "email_no_verification": "Λυπούμαστε, η καταχωρημένη διεύθυνση ηλεκτρονικού ταχυδρομείου δεν έχει επαληθευτεί. Ολοκληρώστε την επαλήθευση σύμφωνα με τις παραπάνω οδηγίες και κάντε ξανά κλικ στο κουμπί \"Τέλος\" για να ολοκληρώσετε την εγγραφή.",
        "error_20505": "Το email έχει συνδεθεί",
        "error_20506": "Σφάλμα: 20506. Επικοινωνήστε μαζί μας",
        "error_28401": "Παρακαλώ εισάγετε το email σας",
        "error_28402": "Λανθασμένη μορφή email",
        "error_28403": "Σφάλμα: 28403. Επικοινωνήστε μαζί μας",
        "error_28404": "Το email έχει εγγραφεί",
        "error_28405": "Σφάλμα: 28405. Επικοινωνήστε μαζί μας",
        "error_28406": "Το email έχει συνδεθεί",
        "error_28407": "Σφάλμα: 28407. Επικοινωνήστε μαζί μας",
        "error_28408": "Απέτυχε η αποστολή του email",
        "error_28601": "Παρακαλώ εισάγετε το email σας",
        "error_28602": "Εισαγάγετε τον κωδικό επαλήθευσης",
        "error_28603": "Λανθασμένη μορφή email",
        "error_28604": "Σφάλμα: 28604. Επικοινωνήστε μαζί μας",
        "error_28605": "Ο κωδικός επαλήθευσης είναι λανθασμένος",
        "error_28606": "Σφάλμα: 28606. Επικοινωνήστε μαζί μας",
        "error_28607": "Το email έχει εγγραφεί",
        "error_28608": "Δεν υπάρχει δεσμευμένο email στον λογαριασμό σας",
        "error_28609": "Το email δεν επαληθεύεται",
        "error_28610": "Σφάλμα: 28610. Επικοινωνήστε μαζί μας",
        "error_28611": "Το email έχει συνδεθεί",
        "error_28612": "Σφάλμα: 28612. Επικοινωνήστε μαζί μας",
        "unbind_email_btn": "Αποσυνδέστε το email",
        "binding_exchange": "Σύνδεση email",
        "Subscription_Status": "Κατάσταση συνδρομής",
        "mask_unbind_info_1": "Είστε βέβαιοι ότι θα αποσυνδέσετε το {%} από αυτόν τον λογαριασμό; Μετά την αποσύνδεση, δεν μπορείτε πλέον να ελέγξετε τα οφέλη αυτού του μηνύματος ηλεκτρονικού ταχυδρομείου",
        "mask_unbind_info_2": "Είστε βέβαιοι ότι θέλετε να αποδεσμεύσετε όλους τους υπολογιστές που σχετίζονται με αυτόν τον κωδικό εγγραφής;<a href=\"https://el.tipard.com/faq.html#unbind\">Μάθετε περισσότερα σχετικά με την αλλαγή συσκευών.</a>",
        "error_dc_link": "Σφάλμα, επικοινωνήστε μαζί μας",
        "vcu_state": "Πλεονεκτήματα πλήρους έκδοσης:",
        "monthly_subscription": "Άδεια 1 μήνα",
        "year_subscription": "Άδεια 1 έτους",
        "to": "να",
        "lifetime_subscription": "Άδεια ζωής",
        "unbind": "Επιβεβαιώνω",
        "unbind_success": "Αποδέσμευσε με επιτυχία",
        "error_28401": "Παρακαλώ εισάγετε το email σας",
        "error_28702": "Σφάλμα: 28702. Επικοινωνήστε μαζί μας",
        "error_28703": "Ο κωδικός επαλήθευσης είναι λανθασμένος",
        "error_28704": "Σφάλμα: 28704. Επικοινωνήστε μαζί μας",
        "error_28705": "Το email δεν έχει συνδεθεί",
        "error_28706": "Εισαγάγετε τον κωδικό επαλήθευσης",
        "error_28707": "Η αποσύνδεση απέτυχε",
        "error_28901": "Σφάλμα: 28901. Επικοινωνήστε μαζί μας",
        "error_28902": "Σφάλμα: 28902. Επικοινωνήστε μαζί μας",
        "error_28903": "Σφάλμα: 28903. Επικοινωνήστε μαζί μας",
        "error_28904": "Σφάλμα: 28904. Επικοινωνήστε μαζί μας",
        "error_28905": "Σφάλμα: 28905. Επικοινωνήστε μαζί μας",
        "error_28906": "Σφάλμα: 28906. Επικοινωνήστε μαζί μας",
        "error_28907": "Μη έγκυρος κωδικός εγγραφής",
        "error_28908": "Σφάλμα: 28908. Επικοινωνήστε μαζί μας",
        "error_28909": "Αυτήν τη στιγμή βρίσκεται η περίοδος αποσύνδεσης. Μπορείτε να αποδεσμεύσετε μόνο μία φορά το μήνα.",
        "error_289010": "Σφάλμα: 289010. Επικοινωνήστε μαζί μας",
        "error_289011": "Σφάλμα: 289011. Επικοινωνήστε μαζί μας",
        "error_289012": "Σφάλμα: 289012. Επικοινωνήστε μαζί μας",
        "activated_computer": "Ενεργοποιήθηκε σε %s/%s υπολογιστές",
        "bind_email_info": "Τα οφέλη των ακόλουθων μηνυμάτων ηλεκτρονικού ταχυδρομείου θα δεσμευτούν και θα χρησιμοποιηθούν σε αυτόν τον λογαριασμό VIP.",
        "error_26309": "Σφάλμα: 26309. Επικοινωνήστε μαζί μας",
        "error_26310": "Μη έγκυρος κωδικός παραπομπής",
        "error_26311": "Σφάλμα: 26311. Επικοινωνήστε μαζί μας",
        "error_26312": "Μη έγκυρος κωδικός παραπομπής",
        "error_26313": "Σφάλμα: 26313. Επικοινωνήστε μαζί μας",
        "error_26314": "Το email έχει συνδεθεί",
        "activate_unbinding": "Αλλαγή Συσκευής",
        "copy_registration_code": "Αντιγράψτε τον κωδικό εγγραφής",
        "show_registration_code": "Εμφάνιση κωδικού εγγραφής",
        "hide_registration_code": "Απόκρυψη κωδικού εγγραφής",
        "other_binding_email": "Άλλα δεσμευτικά email",
        "home": "Σπίτι",
        "points_balance": "Ισοζύγιο πόντων:",
        "earn_now": "Κερδίστε τώρα >>",
        "earn_more": "Κερδίστε περισσότερους ΔΩΡΕΑΝ πόντους, εξαργυρώστε περισσότερα δώρα",
        "earn_or_redeem_points": "Κερδίστε/Εξαργυρώστε πόντους",
        "unbind_email_info": "Ο τρέχων λογαριασμός έχει συσχετιστεί με μια διεύθυνση email",
        "bind_email_title": "Εάν θέλετε να συσχετίσετε περισσότερες παραγγελίες για την αγορά διευθύνσεων ηλεκτρονικού ταχυδρομείου, κάντε κλικ στο κουμπί \"Σύνδεση ηλεκτρονικού ταχυδρομείου\" παρακάτω για συσχέτιση.",
        "bind_email_tip": "<span>Συμβουλή:</span> Προς το παρόν υποστηρίζονται μόνο αγορές παραγγελιών κωδικού εγγραφής μέσω email.",
        "email_association": "Σύνδεσμος ηλεκτρονικού ταχυδρομείου",
        "dissociation": "Διάσταση",
        "back": "Πίσω",
        "activate_benefits": "Εισαγάγετε το email αγοράς που πρέπει να συσχετιστεί για να λάβετε τον κωδικό επαλήθευσης. Εισαγάγετε τον κωδικό επαλήθευσης που λάβατε στο email στο πλαίσιο εισαγωγής κωδικού επαλήθευσης και κάντε κλικ στο κουμπί \"Επαλήθευση ενεργοποίησης\" για να ενεργοποιήσετε τα πλεονεκτήματα.",
        "e_mail": "E-mail:",
        "verification_code": "Κωδικός επαλήθευσης:",
        "obtain_verification_code": "Λάβετε τον κωδικό επαλήθευσης",
        "verification_association": "Επαλήθευση συσχέτισης",
        "current_password": "Τρέχων κωδικός πρόσβασης:",
        "account_center": "Κέντρο Λογαριασμού",
        "new_password": "Νέος κωδικός πρόσβασης:",
        "confirm_password": "Επιβεβαίωση νέου κωδικού πρόσβασης:",
        "Send": "Στέλνω",
        "history_files": "Αρχεία Ιστορίας",
        "delete_account_info_1": "1. Η διαγραφή του λογαριασμού σας στο Tipard είναι μόνιμη ενέργεια και δεν μπορεί να αναιρεθεί. Όλα τα δεδομένα σας, συμπεριλαμβανομένων των φωτογραφιών και των προτιμήσεων, θα χαθούν. Είστε βέβαιοι ότι θα διαγράψετε τον λογαριασμό σας στο Tipard;",
        "delete_account_info_2": "2. Εάν αποφασίσετε να διαγράψετε τον λογαριασμό Tipard, κάντε κλικ στο κουμπί Αποστολή και εισαγάγετε τον κωδικό επαλήθευσης από το email που σας στείλαμε.",
        "best_deals": "Οι καλύτερες προσφορές",
        "close": "Κοντά",
        "product": "Προϊόν:"
    },
    fi: {
        overtime_1: `Virhekoodi:`,
        overtime_2: `, yritä uudelleen`,
        isnetwork: `Internet -virhe. Tarkista ja yritä uudelleen`,
        email_placeholder: `Sähköposti`,
        email_empty: `Anna sähköpostiosoite`,
        email_not_valid: `Sähköpostiosoite ei kelpaa`,
        email_not_valid_1: `Anna sähköpostiosoitteesi`,
        email_not_valid_2: `Sähköpostiosoite ei kelpaa, käytä toista osoitetta.`,
        email_not_valid_3: `Ei sähköpostiosoitetta`,
        password_placeholder: `Salasana`,
        password_empty: `Anna salasana`,
        password_not_valid: `Väärä tili tai salasana`,
        password_not_valid_1: `Yli 8 merkin pituinen salasana vaaditaan`,
        password_not_valid_2: `Luo salasana`,
        password_placeholder_1: `Luo salasanasi`,
        password_placeholder_2: `Vahvista salasana`,
        password_placeholder_3: `Luo uusi salasana`,
        password_placeholder_4: `Vahvista uusi salasana`,
        password_placeholder_5: `Syötä vanha salasana`,
        copy_password_empty: `Vahvista salasana`,
        copy_password_not_valid: `Vahvista salasanasi`,
        copy_passwords_inconsistent: `Salasanavahvistus ei täsmää`,
        code_empty: `Anna vahvistuskoodi`,
        code_not_valid: `Virheellinen vahvistuskoodi`,
        code_placeholder: `Vahvistuskoodi`,
        not_received_code: `Jos postilaatikkosi ei ole saanut vahvistuskoodia pitkään aikaan, hanki vahvistuskoodi uudelleen.`,
        get_first_code: `Hanki ensin vahvistuskoodi.`,
        last_name_placeholder: `Anna sukunimesi`,
        first_name_placeholder: `Kirjoita etunimesi, ole hyvä`,
        address_placeholder: `Anna osoitteesi`,
        no_code_text: `Lähetimme vahvistuskoodin. Anna koodi. <span class='tips'>Etkö saanut koodia?`,
        no_code_text_1: `1.Varmista, että sähköpostiosoite on kelvollinen ja että se voi vastaanottaa sähköposteja.`,
        no_code_text_2: `2.Jos järjestelmä lähettää sähköpostin automaattisesti, se voidaan merkitä roskapostiksi tai roskapostiksi. Tarkista, onko sähköposti Roskakori -kansiossa.`,
        no_code_text_3: `3.Etkö voi ratkaista ongelmaa?`,
        no_code_text_3_span: `Ota sitten yhteyttä napsauttamalla tätä.`,
        order_no: `Et ole ostanut yhtään tuotetta. Jos sinulla on kysyttävää, <a href="https://fi.tipard.com/store.html">Osta nyt</a> Tai <a href="https://fi.tipard.com/contact.html"> Ota yhteyttä</a>`,
        error_24901: `Nykyisellä tilillä ei ole linkitettyä sähköpostia, eikä se löydä tilauksia. Liitä sähköposti.`,
        user_guide: `Käyttöopas >>`,
        download: `ladata`,
        order_number: `Tilausnumero`,
        Refund: `Maksun palautus`,
        Disabled: `Liikuntarajoitteinen`,
        Normal: `Normaali`,
        Modify: `Muuttaa`,
        Modify_1: `Muokkaa >>`,
        Connect: `Kytkeä`,
        unlink_success: `Linkityksen poistaminen onnistui`,
        connect_success: `Yhdistäminen onnistui`,
        feedback_title: `Kiitos palautteestasi. Jätä ongelmasi, niin vastaamme sinulle 24 tunnin kuluessa.`,
        feedback_thank_you: `Kiitos! <br /> Palautteen lähetys onnistui.`,
        feedback_email: `Kirjoita sähköpostiosoitteesi tähän!`,
        feedback_content: `Jätä kohtaamasi ongelma tai ehdotus tänne.`,
        feedback_submit: `Lähetä`,
        form_contents: `Et ole kirjoittanut kuvausta. Anna se ja lähetä se uudelleen.`,
        old_password: `Anna vanha salasana`,
        new_password: `Luo uusi salasana`,
        old_new_password: `Uusi salasana ei voi olla sama kuin vanha`,
        incorrect_password: `Väärä salasana`,
        delete_no: `Poista nyt`,
        Caps: `CAPS LOCK on päällä`,
        Get: `Saada`,
        Done: `Tehty`,
        error_20001: `Virhe: 20001. Kirjaudu sisään uudelleen.`,
        error_20002: `Virhe: 20002. Kirjaudu sisään uudelleen.`,
        error_20003: `Virhe: 20003. Kirjaudu sisään uudelleen.`,
        error_20004: `Pyyntö epäonnistui (virhekoodi: 20004). Yritä uudelleen.`,
        error_20005: `Kirjautumisistunto on vanhentunut (virhe: 20005). Kirjaudu sisään uudelleen.`,
        error_20006: `Pyyntö epäonnistui (virhekoodi: 20006). Yritä uudelleen.`,
        error_20007: `Kirjautumisistunto on vanhentunut (virhe: 20007). Kirjaudu sisään uudelleen.`,
        error_20008: `Kirjautumisistunto on vanhentunut (virhe: 20008). Kirjaudu sisään uudelleen.`,
        error_20009: `Kirjautumisistunto on vanhentunut (virhe: 20009). Kirjaudu sisään uudelleen.`,
        error_20101: `Anna sähköpostiosoitteesi (virhekoodi: 20101)`,
        error_20102: `Sähköpostiosoite ei kelpaa (virhekoodi: 20102)`,
        error_20103: `Pyyntö epäonnistui (virhekoodi: 20103). Yritä uudelleen`,
        error_20104: `Sähköposti on jo käytössä, <a href="https://account.tipard.com/login"> kirjaudu sisään </a> tai rekisteröidy uuteen`,
        error_20105: `Pyyntö epäonnistui (virhekoodi: 20105). Yritä uudelleen`,
        error_20106: `Sähköpostin lähettäminen epäonnistui. Yritä uudelleen`,
        error_20201: `Anna sähköpostiosoitteesi (virhekoodi: 20201)`,
        error_20202: `Anna salasanasi (virhekoodi: 20202)`,
        error_20203: `Anna vahvistuskoodi (virhekoodi: 20203)`,
        error_20204: `Sähköpostiosoite ei kelpaa (virhekoodi: 20204)`,
        error_20205: `Yli 8 merkin pituinen salasana vaaditaan (virhekoodi: 20205)`,
        error_20206: `Pyyntö epäonnistui (virhekoodi: 20206). Yritä uudelleen`,
        error_20207: `Virheellinen vahvistuskoodi`,
        error_20208: `Pyyntö epäonnistui (virhekoodi: 20208). Yritä uudelleen`,
        error_20209: `Pyyntö epäonnistui (virhekoodi: 20209). Yritä uudelleen`,
        error_20301: `Anna sähköpostiosoitteesi (virhekoodi: 20301)`,
        error_20302: `Virhe: 20302. Ota yhteyttä`,
        error_20303: `Sähköpostiosoite ei kelpaa (virhekoodi: 20303)`,
        error_20304: `Pyyntö epäonnistui (virhekoodi: 20304). Yritä uudelleen`,
        error_20305: `Tiliä ei ole olemassa. Anna ensin uudelleen tai <a href="https://account.tipard.com/register">Luo se</a> ensin.`,
        error_20306: `Ei vielä salasanaa. Käytä <a href="https://account.tipard.com/passwordless-login"> salasanatonta kirjautumista </a> tai <a href="create-password"> aseta salasana </a> ja kirjaudu sisään.`,
        error_20308: `Pyyntö epäonnistui (virhekoodi: 20308). Yritä uudelleen`,
        error_20401: `Uloskirjautuminen epäonnistui (virhekoodi: 20401). Yritä uudelleen`,
        error_20501: `Anna sähköpostiosoitteesi (virhekoodi: 20501)`,
        error_20502: `Sähköpostiosoite ei kelpaa (virhekoodi: 20502)`,
        error_20503: `Pyyntö epäonnistui (virhekoodi: 20503). Yritä uudelleen`,
        error_20504: `Sähköpostin lähettäminen epäonnistui. Yritä uudelleen.`,
        error_20601: `Anna sähköpostiosoitteesi (virhekoodi: 20601)`,
        error_20602: `Anna vahvistuskoodi (virhekoodi: 20602)`,
        error_20603: `Sähköpostiosoite ei kelpaa (virhekoodi: 20603)`,
        error_20604: `Pyyntö epäonnistui (virhekoodi: 20604). Yritä uudelleen`,
        error_20606: `Pyyntö epäonnistui (virhekoodi: 20606). Yritä uudelleen`,
        error_20607: `Pyyntö epäonnistui (virhekoodi: 20607). Yritä uudelleen`,
        error_20608: `Pyyntö epäonnistui (virhekoodi: 20608). Yritä uudelleen`,
        error_20701: `Anna sähköpostiosoitteesi (virhekoodi: 20701)`,
        error_20702: `Sähköpostiosoite ei kelpaa (virhekoodi: 20702)`,
        error_20703: `Pyyntö epäonnistui (virhekoodi: 20703). Yritä uudelleen`,
        error_20704: `Tiliä ei ole olemassa. Anna ensin uudelleen tai <a href="https://account.tipard.com/register">Luo se</a> ensin.`,
        error_20705: `Pyyntö epäonnistui (virhekoodi: 20705). Yritä uudelleen`,
        error_20706: `Sähköpostin lähettäminen epäonnistui. Yritä uudelleen`,
        error_20801: `Anna sähköpostiosoitteesi (virhekoodi: 20801)`,
        error_20802: `Virhe: 20802. Ota yhteyttä`,
        error_20803: `Anna vahvistuskoodi (virhekoodi: 20803)`,
        error_20804: `Sähköpostiosoite ei kelpaa (virhekoodi: 20804)`,
        error_20805: `Yli 8 merkin pituinen salasana vaaditaan (virhekoodi: 20805)`,
        error_20806: `Pyyntö epäonnistui (virhekoodi: 20806). Yritä uudelleen`,
        error_20808: `Pyyntö epäonnistui (virhekoodi: 20808). Yritä uudelleen`,
        error_20901: `Pyyntö epäonnistui (virhekoodi: 20901). Yritä uudelleen`,
        error_20902: `Pyyntö epäonnistui (virhekoodi: 20902). Yritä uudelleen`,
        error_21000: `Muutokset tallennetaan`,
        error_21001: `Tietoja ei toimiteta (virhekoodi: 21001)`,
        error_21002: `Pyyntö epäonnistui (virhekoodi: 21002). Yritä uudelleen`,
        error_21101: `Anna sähköpostiosoitteesi (virhekoodi: 21101)`,
        error_21102: `Sähköpostiosoite ei kelpaa (virhekoodi: 21102)`,
        error_21103: `Pyyntö epäonnistui (virhekoodi: 21103). Yritä uudelleen`,
        error_21104: `Sähköposti on jo yhdistetty, käytä uutta`,
        error_21105: `Pyyntö epäonnistui (virhekoodi: 21105). Yritä uudelleen`,
        error_21106: `Sähköpostin lähettäminen epäonnistui. Yritä uudelleen`,
        error_21201: `Anna sähköpostiosoitteesi (virhekoodi: 21201)`,
        error_21202: `Anna vahvistuskoodi (virhekoodi: 21202)`,
        error_21203: `Sähköpostiosoite ei kelpaa (virhekoodi: 21203)`,
        error_21204: `Virhe: 21204. Ota yhteyttä`,
        error_21205: `Virhe: 21205. Ota yhteyttä`,
        error_21206: `Yli 8 merkin pituinen salasana vaaditaan (virhekoodi: 21206)`,
        error_21207: `Pyyntö epäonnistui (virhekoodi: 21207). Yritä uudelleen`,
        error_21209: `Pyyntö epäonnistui (virhekoodi: 21209). Yritä uudelleen`,
        error_21301: `Anna vanha salasana (virhekoodi: 21301)`,
        error_21302: `Luo uusi salasana (virhekoodi: 21302)`,
        error_21303: `Uusi salasana ei voi olla sama kuin vanha. (Virhe: 21303)`,
        error_21304: `Yli 8 merkin pituinen salasana vaaditaan (virhekoodi: 21304)`,
        error_21306: `Pyyntö epäonnistui (virhekoodi: 21306). Yritä uudelleen`,
        error_21402: `Pyyntö epäonnistui (virhekoodi: 21402). Yritä uudelleen`,
        error_21403: `Vahvistuskoodin lähettäminen epäonnistui. Lähetä se uudelleen`,
        error_21500: `Tili on poistettu`,
        error_21501: `Anna vahvistuskoodi (virhekoodi: 21501)`,
        error_21502: `Kirjautumisistunto on vanhentunut (virhe: 21502). Kirjaudu sisään uudelleen.`,
        error_21503: `Pyyntö epäonnistui (virhekoodi: 21503). Yritä uudelleen`,
        error_21505: `Pyyntö epäonnistui (virhekoodi: 21505). Yritä uudelleen`,
        error_21601: `Virhe: 20601. Ota yhteyttä`,
        error_21602: `Virheellinen vahvistus (virhe: 20602). Yritä uudelleen.`,
        error_21603: `Virhe: 20603. Yritä uudelleen`,
        error_21604: `Pyyntö epäonnistui (virhekoodi: 21604). Yritä uudelleen`,
        error_21606: `Pyyntö epäonnistui (virhekoodi: 21606). Yritä uudelleen`,
        error_21611: `Pyyntö epäonnistui (virhekoodi: 21611). Yritä uudelleen`,
        error_21801: `Virhe: 21801. Ota yhteyttä`,
        error_21802: `Pyyntö epäonnistui (Virhe: 21802). Yritä uudelleen`,
        error_21803: `Virhe: 21803. Yritä uudelleen`,
        error_21804: `Pyyntö epäonnistui (virhekoodi: 21804). Yritä uudelleen`,
        error_21806: `Virhe: 21806. Yritä uudelleen`,
        error_21807: `Virhe: 21807. Ota yhteyttä`,
        error_21808: `Virhe: 21808. Ota yhteyttä`,
        error_21809: `Virhe: 21809. Ota yhteyttä`,
        error_21810: `Virhe: 21810. Ota yhteyttä`,
        error_21811: `Virhe: 21811. Ota yhteyttä`,
        error_21812: `Virhe: 21812. Ota yhteyttä`,
        error_21813: `Pyyntö epäonnistui (virhekoodi: 21813). Yritä uudelleen`,
        error_21814: `Virhe: 21814. Ota yhteyttä`,
        error_21815: `Pyyntö epäonnistui (virhekoodi: 21815). Yritä uudelleen`,
        error_21816: `Virhe: 21816. Ota yhteyttä`,
        error_21817: `Virhe: 21817. Ota yhteyttä`,
        error_21818: `Virhe: 21818. Ota yhteyttä`,
        error_21819: `Pyyntö epäonnistui (virhekoodi: 21819). Yritä uudelleen`,
        error_21820: `Virhe: 21820. Ota yhteyttä`,
        error_21821: `Virhe: 21821. Ota yhteyttä`,
        error_21822: `Virhe: 21822. Ota yhteyttä`,
        error_21823: `Pyyntö epäonnistui (virhekoodi: 21823). Yritä uudelleen`,
        error_21824: `Pyyntö epäonnistui (virhekoodi: 21824). Yritä uudelleen`,
        error_21825: `Pyyntö epäonnistui (virhekoodi: 21825). Yritä uudelleen`,
        error_21826: `Pyyntö epäonnistui (virhekoodi: 21826). Yritä uudelleen`,
        error_21901: `Virhe: 21901. Ota yhteyttä`,
        error_21902: `Pyyntö epäonnistui (virhekoodi: 21902). Yritä uudelleen`,
        error_21903: `Tilin tila on muuttunut (virhekoodi: 21903). Päivitä sivu ja yritä uudelleen`,
        error_21904: `Virhe: 21904. Yritä uudelleen`,
        error_21905: `Virhe: 21905. Yritä uudelleen`,
        error_21906: `Pyyntö epäonnistui (virhekoodi: 21906). Yritä uudelleen`,
        error_21907: `Google -tili on linkitetty toiseen tiliin`,
        error_21908: `Pyyntö epäonnistui (virhekoodi: 21908). Yritä uudelleen`,
        error_22001: `Pyyntö epäonnistui (virhekoodi: 22001). Yritä uudelleen`,
        error_22002: `Linkityksen poistaminen epäonnistui ilman lisäkirjautumista`,
        error_22003: `Pyyntö epäonnistui (virhekoodi: 22003). Yritä uudelleen`,
        error_22101: `Virhe: 22101. Ota yhteyttä`,
        error_22102: `Tilin tila on muuttunut (virhekoodi: 22102). Päivitä sivu ja yritä uudelleen`,
        error_22103: `Pyyntö epäonnistui (virhekoodi: 22103). Yritä uudelleen`,
        error_22104: `Tilin tila on muuttunut (virhekoodi: 22104). Päivitä sivu ja yritä uudelleen`,
        error_22105: `Virhe: 22105. Yritä uudelleen`,
        error_22106: `Virhe: 22106. Yritä uudelleen`,
        error_22107: `Virhe: 22107. Ota yhteyttä`,
        error_22108: `Pyyntö epäonnistui (virhekoodi: 22108). Yritä uudelleen`,
        error_22201: `Virhe: 22201. Ota yhteyttä`,
        error_22202: `Virheellinen vahvistus (virhe: 22202). Yritä uudelleen.`,
        error_22203: `Virhe: 22203. Yritä uudelleen "`,
        error_22204: `Pyyntö epäonnistui (virhekoodi: 22204). Yritä uudelleen`,
        error_22206: `Pyyntö epäonnistui (virhekoodi: 22206). Yritä uudelleen`,
        error_22401: `Virhe: 22401. Ota yhteyttä`,
        error_22402: `Virheellinen vahvistus (virhe: 22402). Yritä uudelleen.`,
        error_22403: `Virhe: 22403. Yritä uudelleen`,
        error_22404: `Pyyntö epäonnistui (virhekoodi: 22404). Yritä uudelleen`,
        error_22405: `Facebook -tili on linkitetty toiseen sähköpostiosoitteeseen`,
        error_22406: `Virhe: 22406. Yritä uudelleen`,
        error_22407: `Virhe: 22407. Ota yhteyttä`,
        error_22408: `Virhe: 22408. Ota yhteyttä`,
        error_22409: `Virhe: 22409. Ota yhteyttä`,
        error_22410: `Virhe: 224010. Ota yhteyttä`,
        error_22411: `Virhe: 224011. Ota yhteyttä`,
        error_22412: `Virhe: 224012. Ota yhteyttä`,
        error_22413: `Virhe: 22413. Ota yhteyttä`,
        error_22414: `Pyyntö epäonnistui (virhekoodi: 22414). Yritä uudelleen`,
        error_22415: `Virhe: 22415. Ota yhteyttä`,
        error_22416: `Virhe: 22416. Ota yhteyttä`,
        error_22417: `Virhe: 22417. Ota yhteyttä`,
        error_22418: `Pyyntö epäonnistui (virhekoodi: 22418). Yritä uudelleen`,
        error_22419: `Virhe: 22419. Ota yhteyttä`,
        error_22420: `Virhe: 22420. Ota yhteyttä`,
        error_22421: `Virhe: 22421. Ota yhteyttä`,
        error_22422: `Pyyntö epäonnistui (virhekoodi: 22422). Yritä uudelleen`,
        error_22423: `Pyyntö epäonnistui (virhekoodi: 22423). Yritä uudelleen`,
        error_22424: `Pyyntö epäonnistui (virhekoodi: 22424). Yritä uudelleen`,
        error_22425: `Pyyntö epäonnistui (virhekoodi: 22425). Yritä uudelleen`,
        error_20098: `Virhekoodi: 20098. Jos selaat yksityisessä tilassa, vaihda normaalitilaan ja yritä uudelleen.`,
        error_22298: `Google -pyyntö epäonnistui (virhe: 22298). Yritä uudelleen.`,
        error_22498: `Facebook -pyyntö epäonnistui (virhe: 22498). Yritä uudelleen.`,
        error_24902: `Pyyntö epäonnistui (virhekoodi: 24902). Yritä uudelleen`,
        error_24903: `Pyyntö epäonnistui (virhekoodi: 24903). Yritä uudelleen`,
        error_24904: `Pyyntö epäonnistui (virhekoodi: 24904). Yritä uudelleen`,
        error_24905: `Pyyntö epäonnistui (virhekoodi: 24905). Yritä uudelleen`,
        login_title: `Kirjaudu Tipardiin`,
        password_placeholder: `Salasana`,
        log_in: `Kirjaudu sisään`,
        no_account: `Ei tiliä?`,
        create_it: `Luo se`,
        or_log_in_with: `Tai kirjaudu sisään`,
        passwordless_login: `Salasanaton kirjautuminen`,
        log_in_done: `Kirjaudu sisään Valmis`,
        three_rd_account_connect_info: `Onnittelut! Olet kirjautunut sisään onnistuneesti. Nyt voit yhdistää yhden sähköpostitilin, jota käytetään kirjautumiseen tulevaisuudessa.`,
        see_my_account: `Katso tilini`,
        three_rd_login_merge_account: `Kolmannen osapuolen tilin sähköpostiosoite on rekisteröity, haluatko muodostaa yhteyden ja kirjautua suoraan tähän sähköpostiosoitteeseen?`,
        connect_log_in: `Yhdistä ja kirjaudu sisään`,
        create_an_account: `Luo tili`,
        back_to_log_in: `Takaisin sisäänkirjautumiseen`,
        create_password: `Luo salasana`,
        create_now: `Luo nyt`,
        password_login_subtitle: `Salasanaton kirjautuminen sähköpostilla`,
        account_login: `Tilille kirjautuminen`,
        rights: `Luomalla tämän tilin hyväksyt <a href="https://fi.tipard.com/terms.html"> käyttöehdot </a> ja <a href="https://fi.tipard.com/policy.html">Tietosuojakäytäntö </a>`,
        passwordless_login_done: `Salasanaton kirjautuminen tehty`,
        passwordless_login_info: `Onnittelut, olet suorittanut salasanaton kirjautuminen onnistuneesti. Voit luoda salasanan tälle tilille ja kirjautua sisään tilillä ja salasanalla tulevaisuudessa. <a href="/create-password" style="display: initial;"> Luo nyt </a>`,
        sign_up: `Kirjaudu`,
        register_info: `luo tilisi`,
        reset_now: `Nollaa nyt`,
        forgot_password: `Unohtuiko salasana`,
        reset_password_subtitle: `Vaihda salasana tilisi sähköpostilla`,
        plan_products: `Suunnitelma ja tuotteet`,
        nick_name: `Käyttäjätunnus`,
        email: `Sähköposti`,
        my_products: `Omat tuotteet`,
        my_orders: `Tilaukseni`,
        unlink: `Poista linkitys`,
        link: `Linkki`,
        connected_accounts: `Yhdistetyt tilit`,
        last_name: `Sukunimi`,
        first_name: `Etunimi`,
        Gender: `Sukupuoli`,
        Birth: `Syntymä`,
        Month: `Kuukausi`,
        Year: `Vuosi`,
        Country_Region: `Maa/alue`,
        Address: `Osoite`,
        Save: `Tallentaa`,
        Date: `Päivämäärä`,
        Male: `Uros`,
        Female: `Nainen`,
        Unspecified: `Määrittelemätön`,
        Security: `Turvallisuus`,
        change_password: `Vaihda salasana`,
        change_now: `Vaihda nyt`,
        connect_email: `Yhdistä sähköposti`,
        delete_account: `Poista tili`,
        delete_account_info: `Kun tilisi poistetaan, kaikki tiliisi liittyvät Plan & Products -tiedot poistetaan pysyvästi, etkä ehkä pysty palauttamaan niitä. Suosittelemme polkemista varovasti.`,
        Delete: `Poistaa`,
        Logout: `Kirjautua ulos`,
        my_profile: `Profiilini`,
        guides_faqs: `Oppaat ja usein kysytyt kysymykset`,
        More: `Lisää`,
        guides: `Oppaat`,
        register: `rekisteröidy`,
        hot_faq: `Kuumat UKK`,
        Contents: `Sisällys:`,
        contact_us: `Ota yhteyttä >>`,
        plan: `Suunnitelma`,
        unregistered: `Rekisteröimätön`,
        buy_more: `Osta lisää`,
        buy_again: `Osta uudelleen`,
        buy_now: `Osta nyt`,
        free_no_limit: `Ilmainen & Ei rajoituksia`,
        expired: `Vanhentunut`,
        lifetime: `Elinikä`,
        remain: `Jäädä jäljelle`,
        day_s: `päivä(t)`,
        day: `päivä`,
        error_24801: `Pyyntö epäonnistui (virhekoodi: 24801). Yritä uudelleen`,
        no_app_found: `Sovellusta ei löydy!<a href="/">Päivitä</a> tai <a href="https://fi.tipard.com/">siirry viralliselle verkkosivustolle</a>`,
        get_more: `Hanki lisää >>`,
        edit_photo: `Muokkaa kuvaa`,
        select_photo: `Valitse valokuva`,
        change_photo: `Vaihda kuva`,
        cancel: `Peruuttaa`,
        hide_password: `Piilota salasana`,
        show_password: `Näytä salasana`,
        zoom_in: `Lähennä`,
        zoom_out: `Loitontaa`,
        rotate: `Kiertää`,
        horizontal_flip: `Vaakasuora läppä`,
        vertical_flip: `Pystysuora kääntö`,
        country: `Maa`,
        country_1: `Valitse maasi/alueesi`,
        country_2: `Ahvenanmaa`,
        country_3: `Afganistan`,
        country_4: `Albania`,
        country_5: `Algeria`,
        country_6: `Amerikan Samoa`,
        country_7: `Andorra`,
        country_8: `Angola`,
        country_9: `Anguilla`,
        country_10: `Antarktis`,
        country_11: `Antigua & Barbuda`,
        country_12: `Argentiina`,
        country_13: `Armenia`,
        country_14: `Aruba`,
        country_15: `Australia`,
        country_16: `Itävalta`,
        country_17: `Azerbaidžan`,
        country_18: `Bahrain`,
        country_19: `Bangladesh`,
        country_20: `Barbados`,
        country_21: `Valko-Venäjä`,
        country_22: `Belgia`,
        country_23: `Belize`,
        country_24: `Benin`,
        country_25: `Bermuda`,
        country_26: `Bhutan`,
        country_27: `Bolivia`,
        country_28: `Bosnia ja Hertsegovina`,
        country_29: `Botswana`,
        country_30: `Bouvetin saari`,
        country_31: `Brasilia`,
        country_32: `Brittiläinen Intian valtameren alue`,
        country_33: `Britannian Neitsytsaaret`,
        country_34: `Brunei`,
        country_35: `Bulgaria`,
        country_36: `Burkina`,
        country_37: `Burundi`,
        country_38: `Kambodža`,
        country_39: `Kamerun`,
        country_40: `Kanada`,
        country_41: `Kap Verde`,
        country_42: `Karibian Alankomaat`,
        country_43: `Caymansaaret`,
        country_44: `Keski-Afrikan tasavalta`,
        country_45: `Tšad`,
        country_46: `Chile`,
        country_47: `Kiina`,
        country_48: `Joulusaari`,
        country_49: `Kookossaaret (Keeling).`,
        country_50: `Kolumbia`,
        country_51: `Cook saaret`,
        country_52: `Costa Rica`,
        country_53: `"Norsunluurannikko"`,
        country_54: `Kroatia`,
        country_55: `Kuuba`,
        country_56: `Kypros`,
        country_57: `Tšekin tasavalta`,
        country_58: `Kongon demokraattinen tasavalta`,
        country_59: `Tanska`,
        country_60: `Djibouti`,
        country_61: `Dominica`,
        country_62: `Dominikaaninen tasavalta`,
        country_63: `Ecuador`,
        country_64: `Egypti`,
        country_65: `El Salvador`,
        country_66: `Päiväntasaajan Guinea`,
        country_67: `Eritrea`,
        country_68: `Viro`,
        country_69: `Etiopia`,
        country_70: `Falkland saaret`,
        country_71: `Färsaaret`,
        country_72: `Mikronesian liittovaltiot`,
        country_73: `Fidži`,
        country_74: `Suomi`,
        country_75: `Ranska`,
        country_76: `Ranskan Guayana`,
        country_77: `Ranskan Polynesia`,
        country_78: `Ranskan eteläiset alueet`,
        country_79: `Gabon`,
        country_80: `Gambia`,
        country_81: `Georgia`,
        country_82: `Saksa`,
        country_83: `Ghana`,
        country_84: `Gibraltar`,
        country_85: `Iso-Britannia (Yhdistynyt kuningaskunta; Englanti)`,
        country_86: `Kreikka`,
        country_87: `Grönlanti`,
        country_88: `Grenada`,
        country_89: `Guadeloupe`,
        country_90: `Guam`,
        country_91: `Guatemala`,
        country_92: `Guernseyssä`,
        country_93: `Guinea`,
        country_94: `Guinea-Bissau`,
        country_95: `Guyana`,
        country_96: `Haiti`,
        country_97: `Heard Island ja McDonald Islands`,
        country_98: `Honduras`,
        country_99: `Hong Kong`,
        country_100: `Unkari`,
        country_101: `Islanti`,
        country_102: `Intia`,
        country_103: `Indonesia`,
        country_104: `Iran`,
        country_105: `Irak`,
        country_106: `Irlanti`,
        country_107: `Mansaari`,
        country_108: `Israel`,
        country_109: `Italia`,
        country_110: `Jamaika`,
        country_111: `Japani`,
        country_112: `Jersey`,
        country_113: `Jordania`,
        country_114: `Kazakstan`,
        country_115: `Kenia`,
        country_116: `Kiribati`,
        country_117: `Kuwait`,
        country_118: `Kirgisia`,
        country_119: `Laos`,
        country_120: `Latvia`,
        country_121: `Libanon`,
        country_122: `Lesotho`,
        country_123: `Liberia`,
        country_124: `Libya`,
        country_125: `Liechtenstein`,
        country_126: `Liettua`,
        country_127: `Luxemburg`,
        country_128: `Macao`,
        country_129: `Madagaskar`,
        country_130: `Malawi`,
        country_131: `Malesia`,
        country_132: `Malediivit`,
        country_133: `Mali`,
        country_134: `Malta`,
        country_135: `Marshallsaaret`,
        country_136: `Martinique`,
        country_137: `Mauritania`,
        country_138: `Mauritius`,
        country_139: `Mayotte`,
        country_140: `Meksiko`,
        country_141: `Moldova`,
        country_142: `Monaco`,
        country_143: `Mongolia`,
        country_144: `Montenegro`,
        country_145: `Montserrat`,
        country_146: `Marokko`,
        country_147: `Mosambik`,
        country_148: `Myanmar (Burma)`,
        country_149: `Namibia`,
        country_150: `Nauru`,
        country_151: `Nepal`,
        country_152: `Alankomaat`,
        country_153: `Uusi-Caledonia`,
        country_154: `Uusi Seelanti`,
        country_155: `Nicaragua`,
        country_156: `Niger`,
        country_157: `Nigeria`,
        country_158: `Niue`,
        country_159: `Norfolkin saari`,
        country_160: `Pohjois-Korea`,
        country_161: `Pohjois-Mariaanit`,
        country_162: `Norja`,
        country_163: `Oman`,
        country_164: `Pakistan`,
        country_165: `Palau`,
        country_166: `Palestiinan alueet`,
        country_167: `Panama`,
        country_168: `Papua-Uusi-Guinea`,
        country_169: `Paraguay`,
        country_170: `Peru`,
        country_171: `Pitcairnin saaret`,
        country_172: `Puola`,
        country_173: `Portugali`,
        country_174: `Puerto Rico`,
        country_175: `Qatar`,
        country_176: `Makedonian tasavalta (FYROM)`,
        country_177: `Kongon tasavalta`,
        country_178: `Réunion`,
        country_179: `Romania`,
        country_180: `Venäjän federaatio`,
        country_181: `Ruanda`,
        country_182: `Saint Barthélemy`,
        country_183: `Saint Martin (Ranska)`,
        country_184: `Saint-Pierre ja Miquelon`,
        country_185: `Samoa`,
        country_186: `San Marino`,
        country_187: `São Tome & Principe`,
        country_188: `Saudi-Arabia`,
        country_189: `Senegal`,
        country_190: `Serbia`,
        country_191: `Seychellit`,
        country_192: `Sierra Leone`,
        country_193: `Singapore`,
        country_194: `Slovakia`,
        country_195: `Slovenia`,
        country_196: `Solomonsaaret`,
        country_197: `Somalia`,
        country_198: `Etelä-Afrikka`,
        country_199: `Etelä-Georgia ja South Sandwich Isla`,
        country_200: `Etelä-Korea`,
        country_201: `eteläsudan`,
        country_202: `Espanja`,
        country_203: `Sri Lanka`,
        country_204: `St. Helena & Dependencies`,
        country_205: `St. Kitts & Nevis`,
        country_206: `St. Lucia`,
        country_207: `St. Vincent ja Grenadiinit`,
        country_208: `Sudan`,
        country_209: `Suriname`,
        country_210: `Swazimaa`,
        country_211: `Ruotsi`,
        country_212: `Sveitsi`,
        country_213: `Syyria`,
        country_214: `Taiwan`,
        country_215: `Tadžikistan`,
        country_216: `Tansania`,
        country_217: `Malli:Maatiedot SJM Svalbard`,
        country_218: `Thaimaa`,
        country_219: `Bahama-saaret`,
        country_220: `Komorit`,
        country_221: `Filippiinit`,
        country_222: `Itä-Timor (Itä-Timor)`,
        country_223: `Mennä`,
        country_224: `Tokelau`,
        country_225: `Tonga`,
        country_226: `Trinidad & Tobago`,
        country_227: `Tunisia`,
        country_228: `Turkki`,
        country_229: `Turkmenistan`,
        country_230: `Turks- ja Caicossaaret`,
        country_231: `Tuvalu`,
        country_232: `Uganda`,
        country_233: `Ukraina`,
        country_234: `Yhdistyneet Arabiemiirikunnat`,
        country_235: `Yhdysvaltain pienet syrjäiset saaret`,
        country_236: `Amerikan Yhdysvallat (USA)`,
        country_237: `Yhdysvaltain Neitsytsaaret`,
        country_238: `Uruguay`,
        country_239: `Uzbekistan`,
        country_240: `Vanuatu`,
        country_241: `Vatikaani (Pyhä istuin)`,
        country_242: `Venezuela`,
        country_243: `Vietnam`,
        country_244: `Wallis ja Futuna`,
        country_245: `Länsi-Sahara`,
        country_246: `Jemen`,
        country_247: `Sambia`,
        country_248: `Zimbabwe`,
        google_login: `Kirjaudu sisään Googlella`,
        State: `Osavaltio`,
        Activation_code: `Aktivointikoodi`,
        Question: `Luettele kaikki sovellukset, jotka olet kirjautunut sisään`,
        Copy_complete: `Kopiointi valmis`,
        change_password_success: `salasana vaihdettu onnistuneesti`,
        successful_login_title: `Kirjautuminen onnistui`,
        product_page: `Tuotesivu >>`,
        successful_login_info: `Kirjautuminen suoritettu. Sulje nykyinen sivu ja palaa alkuperäiseen välilehteen jatkaaksesi seuraavaa prosessia. Nykyinen sivu sulkeutuu automaattisesti 5 sekunnin kuluttua. Jos automaattinen sulkeminen tai sulkeminen napsauttamalla "Valmis"-painiketta epäonnistuu, sulje tämä välilehti suoraan.`,
        successful_login_info_firefox: `Kirjautuminen suoritettu. Sulje nykyinen sivu ja palaa alkuperäiseen välilehteen jatkaaksesi seuraavaa prosessia.`,
        my_account: `Tilini`,
        my_history: `Minun historiani`,
        remove_watermark: `Poista vesileima`,
        no_history: `Ei historiaa`,
        history_all: `Valitse kaikki`,
        history_open: `Avata`,
        history_down: `ladata`,
        history_delete: `Poistaa`,
        history_clear: `Tyhjennä virheellinen`,
        images: `kuva(t)`,
        use_this_function: `Käytä tätä toimintoa >>`,
        hd_downloading: `Alkuperäisten HD-kuvien lataamisen edut:`,
        lifetimeRemaining: `Elinikäinen jäljellä`,
        Remaining: `Jäljelle jäänyt`,
        email_verification: `sähköpostivarmistus`,
        email_verification_info: `Olemme lähettäneet vahvistusviestin sähköpostiisi <span class="email"></span> ja viimeistele vahvistus. Vahvistuksen jälkeen edut synkronoidaan automaattisesti.`,
        wrong_email: `Väärä sähköpostiosoite?`,
        click_here_to_modify: `Napsauta tästä muokataksesi`,
        get_more_help: `Saatko lisää apua?`,
        click_here: `Klikkaa tästä`,
        email_verification_info_success: `Onnittelut sähköpostitilisi vahvistamisesta.`,
        email_verification_info_error: `Vahvistus epäonnistui vanhentuneen linkin vuoksi.`,
        registration_succeeded: `Ilmoittautuminen Onnistui`,
        registration_succeeded_info_1: `Onnittelut! Olet rekisteröitynyt onnistuneesti. Olemme lähettäneet vahvistusviestin sähköpostiisi <span class="email"></span> ja viimeistele vahvistus, jotta voit hyödyntää tämän sähköpostin edut.`, registration_succeeded_info_2: `Napsauta "Valmis" palataksesi etusivulle ja käytät tätä tuotetta.`,
        registration_succeeded_info_3: `Napsauta "Valmis" sulkeaksesi nykyisen sivun ja palataksesi aloitussivulle seuraavien toimintojen suorittamiseksi. Jos sulkeminen epäonnistuu, sulje välilehti manuaalisesti.`,
        verify_email: `vahvista sähköposti`,
        registered_email_not_verified: `Rekisteröityä sähköpostiosoitetta ei ole vahvistettu, tarkista se heti.`,
        email_verification_time_1: `Etkö saanut vahvistussähköpostia?`,
        email_verification_time_2: `Jälkeen`,
        email_verification_time_3: `Napsauta tätä lähettääksesi sen uudelleen`,
        error_26301: `Virhekoodi: 26301, ota yhteyttä`,
        error_26302: `Virhekoodi: 26302, ota yhteyttä`,
        error_26303: `Sähköpostin muotovirhe (virhekoodi: 26303). Anna se uudelleen`,
        error_26304: `Yli 8 merkkiä pitkä salasana on suositeltavaa (virhekoodi: 26304)`,
        error_26305: `Reuquest epäonnistui (virhekoodi: 26305). Yritä uudelleen`,
        error_26306: `Sähköposti on rekisteröity, <a href="https://account.tipard.com/login">kirjaudu sisään</a>`,
        error_26307: `Reuquest epäonnistui (virhekoodi: 26307). Yritä uudelleen`,
        error_26308: `Reuquest epäonnistui (virhekoodi: 26308). Yritä uudelleen`,
        error_26401: `Virhekoodi: 26401, yritä uudelleen`,
        error_26402: `Sähköposti on vahvistettu (virhekoodi: 26402), yritä uudelleen`,
        error_26403: `Reuquest epäonnistui (virhekoodi: 26403). Yritä uudelleen`,
        error_26404: `Pyyntö epäonnistui (virhekoodi: 26404). Yritä uudelleen`,
        error_26501: `Virhekoodi: 26501, ota yhteyttä`,
        error_26502: `Virhekoodi: 26502, ota yhteyttä`,
        error_26503: `Sähköpostin muotovirhe (virhekoodi: 26503). Anna se uudelleen`,
        error_26504: `Reuquest epäonnistui (virhekoodi: 26504). Yritä uudelleen`,
        error_26505: `Sähköpostiosoitetta ei ole rekisteröity, <a href="https://account.tipard.com/register">rekisteröi se ensin</a>`,
        error_26506: `Sähköposti on vahvistettu.`,
        error_26507: `Reuquest epäonnistui (virhekoodi: 26507). Yritä uudelleen`,
        error_26508: `Vahvistus lähetetty (virhekoodi: 26508), yritä uudelleen`,
        error_26509: `Pyyntö epäonnistui (virhekoodi: 26509), yritä uudelleen`,
        error_26510: `Virhekoodi: 26510, ota yhteyttä`,
        error_26601: `Virhekoodi: 26601, ota yhteyttä`,
        error_26602: `Virhekoodi: 26602, ota yhteyttä`,
        error_26603: `Reuquest epäonnistui (virhekoodi: 26603). Yritä uudelleen`,
        error_26604: `Virhekoodi: 26604, ota yhteyttä`,
        error_26605: `Virhekoodi: 26605, ota yhteyttä`,
        error_26701: `Virhekoodi: 26701, ota yhteyttä`,
        error_26702: `Reuquest epäonnistui (virhekoodi: 26702). Yritä uudelleen`,
        error_26703: `Virhekoodi: 26703, ota yhteyttä`,
        error_26704: `Virhekoodi: 26704, ota yhteyttä`,
        error_26705: `Odota kirjautumista (virhekoodi: 26705). Yritä uudelleen`,
        no_cookie: `Olet ottanut käyttöön Estä kaikki evästeet -toiminnon selaimessasi, joten et voi kirjautua sisään. Mene Asetuksiin ja valitse Salli kaikki evästeet -valintaruutu.`,
        error_26801: `Virhekoodi: 26801, ota yhteyttä`,
        error_26802: `Virhekoodi: 26802, ota yhteyttä`,
        error_26803: `Pyyntö epäonnistui (virhekoodi: 26803). Yritä uudelleen`,
        error_26804: `Pyyntö epäonnistui (virhekoodi: 26804). Yritä uudelleen`,
        error_order: `Pyyntö epäonnistui (virhekoodi: 27098), yritä uudelleen!`,
        error_order1: `Tilauskysely on epätäydellinen (virhekoodi:`,
        error_order2: `）, päivitä ja yritä uudelleen.`,
        modify_email_title: `Muokkaa sähköpostia`,
        modify_email_info: `Voit kirjautua tilillesi muokatun sähköpostiosoitteen avulla.`,
        images_per: `Kuvat per`,
        error_26101: `Virhe: 26101. Ota yhteyttä`,
        error_26102: `Virhe: 26102. Ota yhteyttä`,
        error_26103: `Pyyntö epäonnistui (virhekoodi:26103). Yritä uudelleen`,
        error_26104: `Virhekoodi: 26104, yritä uudelleen`,
        error_26105: `Virhekoodi: 26105, yritä uudelleen`,
        error_26106: `Poistaminen epäonnistui (virhekoodi: 26106). Yritä uudelleen`,
        error_26201: `Virhe: 26201. Ota yhteyttä`,
        error_26202: `Pyyntö epäonnistui (virhekoodi:26202). Yritä uudelleen`,
        error_26001: `Virhe: 26001. Ota yhteyttä`,
        error_26002: `Virhe: 26002. Ota yhteyttä`,
        error_26003: `Virhe: 26003. Ota yhteyttä`,
        error_26004: `Virhe: 26004. Ota yhteyttä`,
        error_26005: `Pyyntö epäonnistui (virhekoodi:26005). Yritä uudelleen`,
        error_26006: `Virhekoodi: 26006, yritä uudelleen`,
        error_26008: `Virhe: 26008. Ota yhteyttä`,
        go_to_the_home_page: `Siirry etusivulle`,
        error_27101: `Pyyntö epäonnistui (virhekoodi: 27101). Yritä uudelleen ist`,
        error_27201: `Virhekoodi: 27201, ota yhteyttä`,
        error_27202: `Virhekoodi: 27202, yritä uudelleen`,
        error_27203: `Pyyntö epäonnistui (virhekoodi: 27203). Yritä uudelleen`,
        error_27204: `Virheellinen koodi (virhekoodi: 27204).`,
        error_27205: `Pyyntö epäonnistui (virhekoodi: 27205). Yritä uudelleen`,
        error_27206: `Pyyntö epäonnistui (virhekoodi: 27206). Yritä uudelleen`,
        error_27207: `Pyyntö epäonnistui (virhekoodi: 27207). Yritä uudelleen`,
        no_history_found: `Et ole käyttänyt mitään työkalua! <a href="/">Päivitä</a> tai <a href="https://fi.tipard.com/">siirry viralliselle verkkosivustolle</a>`,
        error_25301: `Virhe: 25301. Ota yhteyttä`,
        error_25302: `Virhe: 25302. Ota yhteyttä`,
        error_25303: `Pyyntö epäonnistui (virhekoodi: 25303). Yritä uudelleen`,
        error_25304: `Pyyntö epäonnistui (virhekoodi: 25304). Yritä uudelleen`,
        error_25305: `Pyyntö epäonnistui (virhekoodi: 25305). Yritä uudelleen`,
        error_25306: `Pyyntö epäonnistui (virhekoodi: 25306). Yritä uudelleen`,
        image_upscaler_p: `Kuva ilman vesileiman latausetuja:`,
        Available_for: `Saatavilla:`,
        credit_per: `%s saldoa HD-kuvaa kohden`,
        still_valid: `Ostetut suunnitelmat ovat edelleen voimassa`,
        credit: `luotto(t)`,
        pc_3rd_info: `Login successfully. Please go to the application for further operation.`,
        use_online: `Käytä verkkopalvelua`,
        use_download: `Käytä työpöytäohjelmaa`,
        use_immediately: `Käytä Välittömästi`,
        Use_in_browser: `Käytä selaimessa`,
        win_desktop: `Windows`,
        Mac_desktop: `Mac`,
        credits_per: `{%} hyvitystä kuukaudessa`,
        expire: `Vanhentumisaika:`,
        viewDetails: `Tarkemmat tiedot`,
        viewHistory: `Katso historian edut >>`,
        viewDetailsInfo: `Huomautus: Jos tilauksen edut uusitaan 7 päivän kuluessa voimassaolon päättymisestä, käyttämättömät edut voidaan jatkaa käyttöä. Myös päättymisaika päivitetään automaattisesti uuden tilauksen päättymisaikaan. Jos uutta tilausta ei ole 7 päivän päättymisajan jälkeen, kaikki vanhentuneet edut tyhjennetään.`,
        "connect_account": "Sido sähköposti tiliisi",
        "connect_account": "Sido sähköposti tiliisi",
        "connect_account_info": "Sidonnan jälkeen voit kirjautua sisään tällä sähköpostiosoitteella.",
        "connect_now": "Sido nyt",
        "no_email_bind": "Ei sähköpostisidosta",
        "bind_email": "Sido sähköposti",
        "connect_your_email_placeholder": "Anna sähköpostiosoitteesi",
        "bind_an_email": "Sido sähköposti",
        "bind_info": "Olet kirjautunut sisään onnistuneesti. Aktivoi edut sitomalla sähköposti tiliisi.",
        "bind_later": "Sido myöhemmin",
        "footer": "Copyright © 2024 Tipard Studio. Kaikki oikeudet pidätetään.",
        "overtime": "Virhekoodi: {%}, yritä uudelleen",
        "hi": "Hei %s!",
        "Personal_Information": "Henkilökohtaiset tiedot",
        "Access": "Pääsy",
        "Subscription_Plan": "(tilaussopimus)",
        "No_orders": "Tilauksia ei löytynyt.",
        "No_data": "Ei dataa",
        "Featured_Products": "Suositellut tuotteet",
        "More_Products": "Lisää tuotteita",
        "Free_Download": "Ilmainen lataus",
        "Get_Started": "Aloita",
        "Subscribe": "Tilaa",
        "Verified": "Vahvistettu",
        "back_to_account_center": "Takaisin tilikeskukseen",
        "success": "Menestys!",
        "successfully": "Olet onnistuneesti rekisteröinyt tilin.",
        "Continue": "Jatkaa",
        "Already": "Onko sinulla jo tili?",
        "loading_verification": "Tarkistetaan vahvistuksen tilaa...",
        "email_no_verification": "Valitettavasti rekisteröityä sähköpostiosoitetta ei ole vahvistettu. Suorita vahvistus yllä olevien ohjeiden mukaisesti ja viimeistele rekisteröinti napsauttamalla \"Valmis\"-painiketta uudelleen.",
        "error_20505": "Sähköposti on linkitetty",
        "error_20506": "Virhe: 20506. Ota yhteyttä",
        "error_28401": "Ole hyvä ja kirjoita sähköpostiosoitteesi",
        "error_28402": "Virheellinen sähköpostimuoto",
        "error_28403": "Virhe: 28403. Ota yhteyttä",
        "error_28404": "Sähköposti on rekisteröity",
        "error_28405": "Virhe: 28405. Ota yhteyttä",
        "error_28406": "Sähköposti on linkitetty",
        "error_28407": "Virhe: 28407. Ota yhteyttä",
        "error_28408": "Sähköpostin lähetys epäonnistui",
        "error_28601": "Ole hyvä ja kirjoita sähköpostiosoitteesi",
        "error_28602": "Syötä vahvistuskoodi",
        "error_28603": "Virheellinen sähköpostimuoto",
        "error_28604": "Virhe: 28604. Ota yhteyttä",
        "error_28605": "Vahvistuskoodi on virheellinen",
        "error_28606": "Virhe: 28606. Ota yhteyttä",
        "error_28607": "Sähköposti on rekisteröity",
        "error_28608": "Tiliisi ei ole sidottu sähköpostia",
        "error_28609": "Sähköpostia ei ole vahvistettu",
        "error_28610": "Virhe: 28610. Ota yhteyttä",
        "error_28611": "Sähköposti on linkitetty",
        "error_28612": "Virhe: 28612. Ota yhteyttä",
        "unbind_email_btn": "Poista sähköpostin linkitys",
        "binding_exchange": "Sido sähköposti",
        "Subscription_Status": "Tilauksen tila",
        "mask_unbind_info_1": "Haluatko varmasti poistaa linkin {%} tästä tilistä? Linkityksen poistamisen jälkeen et voi enää tarkistaa tämän sähköpostin etuja",
        "mask_unbind_info_2": "Haluatko varmasti purkaa kaikki tähän rekisteröintikoodiin liittyvät tietokoneet?<a href=\"https://fi.tipard.com/faq.html#unbind\">Lisätietoja laitteiden vaihtamisesta.</a>",
        "error_dc_link": "Virhe, ota meihin yhteyttä",
        "vcu_state": "Täysversion edut:",
        "monthly_subscription": "1 kuukauden lisenssi",
        "year_subscription": "1 vuoden lisenssi",
        "to": "to",
        "lifetime_subscription": "Elinikäinen lisenssi",
        "unbind": "Vahvistaa",
        "unbind_success": "Sidonnan purkaminen onnistui",
        "error_28401": "Ole hyvä ja kirjoita sähköpostiosoitteesi",
        "error_28702": "Virhe: 28702. Ota yhteyttä",
        "error_28703": "Vahvistuskoodi on virheellinen",
        "error_28704": "Virhe: 28704. Ota yhteyttä",
        "error_28705": "Sähköpostia ei ole linkitetty",
        "error_28706": "Syötä vahvistuskoodi",
        "error_28707": "Linkityksen poistaminen epäonnistui",
        "error_28901": "Virhe: 28901. Ota yhteyttä",
        "error_28902": "Virhe: 28902. Ota yhteyttä",
        "error_28903": "Virhe: 28903. Ota yhteyttä",
        "error_28904": "Virhe: 28904. Ota yhteyttä",
        "error_28905": "Virhe: 28905. Ota yhteyttä",
        "error_28906": "Virhe: 28906. Ota yhteyttä",
        "error_28907": "Virheellinen rekisteröintikoodi",
        "error_28908": "Virhe: 28908. Ota yhteyttä",
        "error_28909": "Tällä hetkellä vapaan jäähdytysjakson aikana. Voit purkaa vain kerran kuukaudessa.",
        "error_289010": "Virhe: 289010. Ota yhteyttä",
        "error_289011": "Virhe: 289011. Ota yhteyttä",
        "error_289012": "Virhe: 289012. Ota yhteyttä",
        "activated_computer": "Aktivoitu %s/%s tietokoneella",
        "bind_email_info": "Seuraavien sähköpostien edut sidotaan ja käytetään tämän VIP-tilin alla.",
        "error_26309": "Virhe: 26309. Ota yhteyttä",
        "error_26310": "Virheellinen viittauskoodi",
        "error_26311": "Virhe: 26311. Ota yhteyttä",
        "error_26312": "Virheellinen viittauskoodi",
        "error_26313": "Virhe: 26313. Ota yhteyttä",
        "error_26314": "Sähköposti on linkitetty",
        "activate_unbinding": "Vaihda laitetta",
        "copy_registration_code": "Kopioi rekisteröintikoodi",
        "show_registration_code": "Näytä rekisteröintikoodi",
        "hide_registration_code": "Piilota rekisteröintikoodi",
        "other_binding_email": "Muut sitovat sähköpostit",
        "home": "Kotiin",
        "points_balance": "Pisteiden saldo:",
        "earn_now": "Ansaitse nyt >>",
        "earn_more": "Ansaitse lisää ILMAISIA pisteitä, lunasta lisää lahjoja",
        "earn_or_redeem_points": "Ansaitse / lunasta pisteitä",
        "unbind_email_info": "Nykyinen tili on liitetty sähköpostiosoitteeseen",
        "bind_email_title": "Jos haluat liittää lisää tilauksia sähköpostiosoitteiden ostamiseen, liitä yhteys napsauttamalla alla olevaa \"Sähköpostiliitos\"-painiketta.",
        "bind_email_tip": "<span>Vinkki:</span> Tällä hetkellä vain sähköpostirekisteröintikooditilausten ostamista tuetaan.",
        "email_association": "Sähköpostiyhdistys",
        "dissociation": "Dissosiaatio",
        "back": "Takaisin",
        "activate_benefits": "Anna ostosähköpostiosoite, joka on liitettävä vahvistuskoodin saamiseksi. Syötä sähköpostissa saamasi vahvistuskoodi vahvistuskoodin syöttökenttään ja napsauta \"Vahvista aktivointi\" -painiketta aktivoidaksesi edut.",
        "e_mail": "Sähköposti:",
        "verification_code": "Vahvistuskoodi:",
        "obtain_verification_code": "Hanki vahvistuskoodi",
        "verification_association": "Vahvista yhteys",
        "current_password": "Nykyinen salasana:",
        "account_center": "Tilikeskus",
        "new_password": "Uusi salasana:",
        "confirm_password": "Vahvista uusi salasana:",
        "Send": "Lähetä",
        "history_files": "Historiatiedostot",
        "delete_account_info_1": "1. Tipard-tilisi poistaminen on pysyvä toimenpide, eikä sitä voi kumota. Kaikki tietosi, mukaan lukien valokuvat ja asetuksesi, menetetään. Oletko varma, että poistat Tipard-tilisi?",
        "delete_account_info_2": "2. Jos päätät poistaa Tipard-tilin, napsauta Lähetä-painiketta ja kirjoita vahvistuskoodi sähköpostista, jonka lähetimme sinulle.",
        "best_deals": "Parhaat tarjoukset",
        "close": "Lähellä",
        "product": "Tuote:"
    },
    fr: {
        overtime_1: `Code d'erreur:`,
        overtime_2: `, veuillez réessayer`,
        isnetwork: `Erreur Internet. Veuillez vérifier et réessayer`,
        email_placeholder: `E-mail`,
        email_empty: `Veuillez saisir l'e-mail`,
        email_not_valid: `L'email n'est pas valide`,
        email_not_valid_1: `Veuillez saisir votre email`,
        email_not_valid_2: `L'e-mail n'est pas valide, veuillez utiliser une autre adresse.`,
        email_not_valid_3: `Pas de saisie d'e-mail`,
        password_placeholder: `Mot de passe`,
        password_empty: `Veuillez saisir le mot de passe`,
        password_not_valid: `Compte ou mot de passe incorrect`,
        password_not_valid_1: `Un mot de passe supérieur à 8 caractères est requis`,
        password_not_valid_2: `Veuillez créer un mot de passe`,
        password_placeholder_1: `Créez votre mot de passe`,
        password_placeholder_2: `Confirmez votre mot de passe`,
        password_placeholder_3: `Créer un nouveau mot de passe`,
        password_placeholder_4: `Confirmer le nouveau mot de passe`,
        password_placeholder_5: `Saisir l'ancien mot de passe`,
        copy_password_empty: `Veuillez confirmer le mot de passe`,
        copy_password_not_valid: `Veuillez confirmer votre mot de passe`,
        copy_passwords_inconsistent: `Votre confirmation de mot de passe ne correspond pas`,
        code_empty: `Veuillez saisir le code de vérification`,
        code_not_valid: `Code de vérification invalide`,
        code_placeholder: `Code de Vérification`,
        not_received_code: `Si votre boîte aux lettres n'a pas reçu le code de vérification depuis longtemps, veuillez obtenir à nouveau le code de vérification.`,
        get_first_code: `Veuillez d'abord obtenir le code de vérification.`,
        last_name_placeholder: `Veuillez entrer votre nom de famille`,
        first_name_placeholder: `Veuillez entrer votre prénom`,
        address_placeholder: `Veuillez entrer votre adresse`,
        no_code_text: `Nous avons envoyé un code de vérification. Veuillez saisir votre code. <span class='tips'>Vous n'avez pas reçu de code ?`,
        no_code_text_1: `1.Veuillez vous assurer que l'adresse e-mail est valide et qu'elle peut recevoir des e-mails.`,
        no_code_text_2: `2.Pour que l'e-mail soit envoyé automatiquement par le système, il peut être signalé comme spam ou courrier indésirable. Veuillez vérifier si l'e-mail se trouve dans le dossier indésirable.`,
        no_code_text_3: `3.Impossible résoudre votre problème ?`,
        no_code_text_3_span: `Alors cliquez ici pour nous contacter.`,
        order_no: `Vous n'avez acheté aucun produit. Pour toute question, veuillez <a href="https://fr.tipard.com/purchase.html">Acheter maintenant</a> Ou <a href="https://fr.tipard.com/contact.html"> Contactez-nous</a>`,
        error_24901: `Le compte actuel n'a pas d'adresse e-mail liée et ne trouve pas les commandes. Veuillez ajouter un e-mail.`,
        user_guide: `Guide de l'utilisateur>>`,
        download: `Télécharger`,
        order_number: `Numéro de commande`,
        Refund: `Remboursement`,
        Disabled: `Désactivée`,
        Normal: `Normal`,
        Modify: `Modifier`,
        Modify_1: `Modifier>>`,
        Connect: `Relier`,
        unlink_success: `Dissocier avec succès`,
        connect_success: `Connectez-vous avec succès`,
        feedback_title: `Merci pour vos commentaires. Veuillez laisser votre problème et nous vous répondrons dans les 24 heures.`,
        feedback_thank_you: `Merci !<br />Vos commentaires ont été soumis avec succès.`,
        feedback_email: `Entrez votre e-mail ici !`,
        feedback_content: `Laissez ici des problèmes ou suggestions que vous avez rencontrés.`,
        feedback_submit: `Soumettre`,
        form_contents: `Vous n'avez entré aucune description. Veuillez le saisir et soumettre à nouveau.`,
        old_password: `Veuillez saisir l'ancien mot de passe`,
        new_password: `Veuillez créer un nouveau mot de passe`,
        old_new_password: `Le nouveau mot de passe ne peut pas être le même que l'ancien`,
        incorrect_password: `Mot de passe incorrect`,
        delete_no: `Effacer maintenant`,
        Caps: `Touche Maj activée`,
        Get: `Obtenir`,
        Done: `Terminé`,
        error_20001: `Erreur : 20001. Veuillez vous reconnecter.`,
        error_20002: `Erreur : 20002. Veuillez vous reconnecter.`,
        error_20003: `Erreur : 20003. Veuillez vous reconnecter.`,
        error_20004: `Échec de la demande (code d'erreur : 20004). Veuillez réessayer.`,
        error_20005: `La session de connexion a expiré (Erreur : 20005). Veuillez vous reconnecter.`,
        error_20006: `Échec de la demande (code d'erreur : 20006). Veuillez réessayer.`,
        error_20007: `La session de connexion a expiré (Erreur : 20007). Veuillez vous reconnecter.`,
        error_20008: `La session de connexion a expiré (Erreur : 20008). Veuillez vous reconnecter.`,
        error_20009: `La session de connexion a expiré (Erreur : 20009). Veuillez vous reconnecter.`,
        error_20101: `Veuillez saisir votre email (code d'erreur : 20101)`,
        error_20102: `L'e-mail n'est pas valide (code d'erreur : 20102)`,
        error_20103: `Échec de la demande (code d'erreur : 20103). Veuillez réessayer`,
        error_20104: `L'e-mail est déjà utilisé, veuillez <a href="https://account.tipard.com/login">vous connecter</a> ou vous inscrire avec un nouveau`,
        error_20105: `Échec de la demande (code d'erreur : 20105). Veuillez réessayer`,
        error_20106: `Échec de l'envoi de l'e-mail, veuillez réessayer`,
        error_20201: `Veuillez saisir votre e-mail (code d'erreur : 20201)`,
        error_20202: `Veuillez saisir votre mot de passe (code d'erreur : 20202)`,
        error_20203: `Veuillez saisir le code de vérification (code d'erreur : 20203)`,
        error_20204: `L'e-mail n'est pas valide (code d'erreur : 20204)`,
        error_20205: `Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 20205)`,
        error_20206: `Échec de la demande (code d'erreur : 20206). Veuillez réessayer`,
        error_20207: `Code de vérification invalide`,
        error_20208: `Échec de la demande (code d'erreur : 20208). Veuillez réessayer`,
        error_20209: `Échec de la demande (code d'erreur : 20209). Veuillez réessayer`,
        error_20301: `Veuillez saisir votre e-mail (code d'erreur : 20301)`,
        error_20302: `Erreur : 20302. Veuillez nous contacter`,
        error_20303: `L'e-mail n'est pas valide (code d'erreur : 20303)`,
        error_20304: `Échec de la demande (code d'erreur : 20304). Veuillez réessayer`,
        error_20305: `Le compte n'existe pas. Veuillez saisir à nouveau ou <a href="https://account.tipard.com/register">Créer un nouveau</a> d'abord.`,
        error_20306: `Pas encore de mot de passe. Utilisez la <a href="https://account.tipard.com/passwordless-login">connexion sans mot de passe</a> ou <a href="create-password">définissez un mot de passe</a> et connectez-vous.`,
        error_20308: `Échec de la demande (code d'erreur : 20308). Veuillez réessayer`,
        error_20401: `Échec de la déconnexion (code d'erreur : 20401). Veuillez réessayer`,
        error_20501: `Veuillez saisir votre e-mail (code d'erreur : 20501)`,
        error_20502: `L'e-mail n'est pas valide (code d'erreur : 20502)`,
        error_20503: `Échec de la demande (code d'erreur : 20503). Veuillez réessayer`,
        error_20504: `Échec de l'envoi de l'e-mail. Veuillez réessayer.`,
        error_20601: `Veuillez saisir votre email (code d'erreur : 20601)`,
        error_20602: `Veuillez saisir le code de vérification (code d'erreur : 20602)`,
        error_20603: `L'e-mail n'est pas valide (code d'erreur : 20603)`,
        error_20604: `Échec de la demande (code d'erreur : 20604). Veuillez réessayer`,
        error_20606: `Échec de la demande (code d'erreur : 20606). Veuillez réessayer`,
        error_20607: `Échec de la demande (code d'erreur : 20607). Veuillez réessayer`,
        error_20608: `Échec de la demande (code d'erreur : 20608). Veuillez réessayer`,
        error_20701: `Veuillez saisir votre email (code d'erreur : 20701)`,
        error_20702: `L'e-mail n'est pas valide (code d'erreur : 20702)`,
        error_20703: `Échec de la demande (code d'erreur : 20703). Veuillez réessayer`,
        error_20704: `Le compte n'existe pas. Veuillez saisir à nouveau ou <a href="https://account.tipard.com/register">Créer un nouveau</a> d'abord.`,
        error_20705: `Échec de la demande (code d'erreur : 20705). Veuillez réessayer`,
        error_20706: `Échec de l'envoi de l'e-mail. Veuillez réessayer`,
        error_20801: `Veuillez saisir votre e-mail (code d'erreur : 20801)`,
        error_20802: `Erreur : 20802. Veuillez nous contacter`,
        error_20803: `Veuillez saisir le code de vérification (code d'erreur : 20803)`,
        error_20804: `L'e-mail n'est pas valide (code d'erreur : 20804)`,
        error_20805: `Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 20805)`,
        error_20806: `Échec de la demande (code d'erreur : 20806). Veuillez réessayer`,
        error_20808: `Échec de la demande (code d'erreur : 20808). Veuillez réessayer`,
        error_20901: `Échec de la demande (code d'erreur : 20901). Veuillez réessayer`,
        error_20902: `Échec de la demande (code d'erreur : 20902). Veuillez réessayer`,
        error_21000: `Les modifications sont enregistrées`,
        error_21001: `Aucune information n'est soumise (code d'erreur : 21001)`,
        error_21002: `Échec de la demande (code d'erreur : 21002). Veuillez réessayer`,
        error_21101: `Veuillez saisir votre email (code d'erreur : 21101)`,
        error_21102: `L'e-mail n'est pas valide (code d'erreur : 21102)`,
        error_21103: `Échec de la demande (code d'erreur : 21103), veuillez réessayer`,
        error_21104: `L'e-mail est déjà connecté, veuillez en utiliser un nouveau`,
        error_21105: `Échec de la demande (code d'erreur : 21105), veuillez réessayer`,
        error_21106: `Échec de l'envoi de l'e-mail. Veuillez réessayer`,
        error_21201: `Veuillez saisir votre email (code d'erreur : 21201)`,
        error_21202: `Veuillez saisir le code de vérification (code d'erreur : 21202)`,
        error_21203: `L'e-mail n'est pas valide (code d'erreur : 21203)`,
        error_21204: `Erreur : 21204. Veuillez nous contacter`,
        error_21205: `Erreur : 21205. Veuillez nous contacter`,
        error_21206: `Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 21206)`,
        error_21207: `Échec de la demande (code d'erreur : 21207). Veuillez réessayer`,
        error_21209: `Échec de la demande (code d'erreur : 21209). Veuillez réessayer`,
        error_21301: `Veuillez saisir l'ancien mot de passe (code d'erreur : 21301)`,
        error_21302: `Veuillez créer un nouveau mot de passe (code d'erreur : 21302)`,
        error_21303: `Le nouveau mot de passe ne peut pas être le même que l'ancien. (Erreur : 21303)`,
        error_21304: `Un mot de passe supérieur à 8 caractères est requis (code d'erreur : 21304)`,
        error_21306: `Échec de la demande (code d'erreur : 21306). Veuillez réessayer`,
        error_21402: `Échec de la demande (code d'erreur : 21402). Veuillez réessayer`,
        error_21403: `Échec de l'envoi du code de vérification. Veuillez le renvoyer`,
        error_21500: `Le compte a été supprimé`,
        error_21501: `Veuillez saisir le code de vérification (code d'erreur : 21501)`,
        error_21502: `La session de connexion a expiré (Erreur : 21502). Veuillez vous reconnecter.`,
        error_21503: `Échec de la demande (code d'erreur : 21503). Veuillez réessayer`,
        error_21505: `Échec de la demande (code d'erreur : 21505), veuillez réessayer`,
        error_21601: `Erreur : 20601. Veuillez nous contacter`,
        error_21602: `Vérification non valide (erreur : 20602). Veuillez réessayer.`,
        error_21603: `Erreur : 20603. Veuillez réessayer`,
        error_21604: `Échec de la demande (code d'erreur : 21604). Veuillez réessayer`,
        error_21606: `Échec de la demande (code d'erreur : 21606). Veuillez réessayer`,
        error_21611: `Échec de la demande (code d'erreur : 21611). Veuillez réessayer`,
        error_21801: `Erreur : 21801. Veuillez nous contacter`,
        error_21802: `Échec de la demande (Erreur : 21802). Veuillez réessayer`,
        error_21803: `Erreur : 21803. Veuillez réessayer`,
        error_21804: `Échec de la demande (code d'erreur : 21804). Veuillez réessayer`,
        error_21806: `Erreur : 21806. Veuillez réessayer`,
        error_21807: `Erreur : 21807. Veuillez nous contacter`,
        error_21808: `Erreur : 21808. Veuillez nous contacter`,
        error_21809: `Erreur : 21809. Veuillez nous contacter`,
        error_21810: `Erreur : 21810. Veuillez nous contacter`,
        error_21811: `Erreur : 21811. Veuillez nous contacter`,
        error_21812: `Erreur : 21812. Veuillez nous contacter`,
        error_21813: `Échec de la demande (code d'erreur : 21813). Veuillez réessayer`,
        error_21814: `Erreur : 21814. Veuillez nous contacter`,
        error_21815: `Échec de la demande (code d'erreur : 21815). Veuillez réessayer`,
        error_21816: `Erreur : 21816. Veuillez nous contacter`,
        error_21817: `Erreur : 21817. Veuillez nous contacter`,
        error_21818: `Erreur : 21818. Veuillez nous contacter`,
        error_21819: `Échec de la demande (code d'erreur : 21819). Veuillez réessayer`,
        error_21820: `Erreur : 21820. Veuillez nous contacter`,
        error_21821: `Erreur : 21821. Veuillez nous contacter`,
        error_21822: `Erreur : 21822. Veuillez nous contacter`,
        error_21823: `Échec de la demande (code d'erreur : 21823). Veuillez réessayer`,
        error_21824: `Échec de la demande (code d'erreur : 21824). Veuillez réessayer`,
        error_21825: `Échec de la demande (code d'erreur : 21825). Veuillez réessayer`,
        error_21826: `Échec de la demande (code d'erreur : 21826). Veuillez réessayer`,
        error_21901: `Erreur : 21901. Veuillez nous contacter`,
        error_21902: `Échec de la demande (code d'erreur : 21902). Veuillez réessayer`,
        error_21903: `Le statut du compte a changé (code d'erreur : 21903), veuillez actualiser la page et réessayer`,
        error_21904: `Erreur : 21904. Veuillez réessayer`,
        error_21905: `Erreur : 21905. Veuillez réessayer`,
        error_21906: `Échec de la demande (code d'erreur : 21906). Veuillez réessayer`,
        error_21907: `Le compte Google a été associé à un autre compte`,
        error_21908: `Échec de la demande (code d'erreur : 21908). Veuillez réessayer`,
        error_22001: `Échec de la demande (code d'erreur : 22001). Veuillez réessayer`,
        error_22002: `Échec de la dissociation sans connexion supplémentaire`,
        error_22003: `Échec de la demande (code d'erreur : 22003). Veuillez réessayer`,
        error_22101: `Erreur : 22101. Veuillez nous contacter`,
        error_22102: `Le statut du compte a changé (code d'erreur : 22102), veuillez actualiser la page et réessayer`,
        error_22103: `Échec de la demande (code d'erreur : 22103). Veuillez réessayer`,
        error_22104: `Le statut du compte a changé (code d'erreur : 22104), veuillez actualiser la page et réessayer`,
        error_22105: `Erreur : 22105. Veuillez réessayer`,
        error_22106: `Erreur : 22106. Veuillez réessayer`,
        error_22107: `Erreur : 22107. Veuillez nous contacter`,
        error_22108: `Échec de la demande (code d'erreur : 22 108). Veuillez réessayer`,
        error_22201: `Erreur : 22201. Veuillez nous contacter`,
        error_22202: `Vérification non valide (Erreur : 22202). Veuillez réessayer.`,
        error_22203: `Erreur : 22203. Veuillez réessayer`,
        error_22204: `Échec de la demande (code d'erreur : 22204). Veuillez réessayer`,
        error_22206: `Échec de la demande (code d'erreur : 22206). Veuillez réessayer`,
        error_22401: `Erreur : 22401. Veuillez nous contacter`,
        error_22402: `Vérification non valide (erreur : 22402). Veuillez réessayer.`,
        error_22403: `Erreur : 22403. Veuillez réessayer`,
        error_22404: `Échec de la demande (code d'erreur : 22404). Veuillez réessayer`,
        error_22405: `Le compte Facebook a été lié à d'autres e-mails`,
        error_22406: `Erreur : 22406. Veuillez réessayer`,
        error_22407: `Erreur : 22407. Veuillez nous contacter`,
        error_22408: `Erreur : 22408. Veuillez nous contacter`,
        error_22409: `Erreur : 22409. Veuillez nous contacter`,
        error_22410: `Erreur : 224010. Veuillez nous contacter`,
        error_22411: `Erreur : 224011. Veuillez nous contacter`,
        error_22412: `Erreur : 224012. Veuillez nous contacter`,
        error_22413: `Erreur : 22413. Veuillez nous contacter`,
        error_22414: `Échec de la demande (code d'erreur : 22414). Veuillez réessayer`,
        error_22415: `Erreur : 22415. Veuillez nous contacter`,
        error_22416: `Erreur : 22416. Veuillez nous contacter`,
        error_22417: `Erreur : 22417. Veuillez nous contacter`,
        error_22418: `Échec de la demande (code d'erreur : 22418). Veuillez réessayer`,
        error_22419: `Erreur : 22419. Veuillez nous contacter`,
        error_22420: `Erreur : 22420. Veuillez nous contacter`,
        error_22421: `Erreur : 22421. Veuillez nous contacter`,
        error_22422: `Échec de la demande (code d'erreur : 22422). Veuillez réessayer`,
        error_22423: `Échec de la demande (code d'erreur : 22423). Veuillez réessayer`,
        error_22424: `Échec de la demande (code d'erreur : 22424). Veuillez réessayer`,
        error_22425: `Échec de la demande (code d'erreur : 22425). Veuillez réessayer`,
        error_20098: `Code d'erreur : 20098. Si vous naviguez en mode privé, veuillez passer en mode normal et réessayer.`,
        error_22298: `La demande pour Google a échoué (Erreur : 22298). Veuillez réessayer.`,
        error_22498: `La demande de Facebook a échoué (Erreur : 22498). Veuillez réessayer.`,
        error_24902: `Échec de la demande (code d'erreur : 24902). Veuillez réessayer`,
        error_24903: `Échec de la demande (code d'erreur : 24903). Veuillez réessayer`,
        error_24904: `Échec de la demande (code d'erreur : 24904). Veuillez réessayer`,
        error_24905: `Échec de la demande (code d'erreur : 24905). Veuillez réessayer`,
        login_title: `Connexion à Tipard`,
        password_placeholder: `Mot de passe`,
        log_in: `Connexion`,
        no_account: `Pas de compte?`,
        create_it: `Créer un nouveau`,
        or_log_in_with: `Ou connexion avec`,
        passwordless_login: `Connexion par E-mail`,
        log_in_done: `Connexion Terminé`,
        three_rd_account_connect_info: `Félicitations! Vous vous êtes connecté avec succès. Vous pouvez maintenant connecter un compte de E-mail qui sera utilisé pour vous connecter à l'avenir.`,
        see_my_account: `Voir mon compte`,
        three_rd_login_merge_account: `L'adresse e-mail du compte tiers a été enregistrée, voulez-vous vous connecter et vous connecter directement avec cette adresse e-mail ?`,
        connect_log_in: `Associer & Se Coonecter`,
        create_an_account: `Créer un compte`,
        back_to_log_in: `Retour connexion`,
        create_password: `Créer un mot de passe`,
        create_now: `Créer maintenant`,
        password_login_subtitle: `Connexion sans mot de passe avec e-mail`,
        account_login: `Connexion au compte`,
        rights: `En créant ce compte, vous acceptez les <a href="https://fr.tipard.com/terms.html">Conditions d'utilisation</a> et les <a href="https://fr.tipard.com/policy.html">Politique de confidentialité</a>`,
        passwordless_login_done: `Connexion sans mot de passe effectuée`,
        passwordless_login_info: `Félicitations, vous avez terminé avec succès la connexion sans mot de passe. Vous pouvez créer un mot de passe pour ce compte et vous connecter avec le compte et le mot de passe à l'avenir. <a href="/create-password" style="display: initial;">Créer maintenant</a>`,
        sign_up: `S'inscrire`,
        register_info: `Créez votre compte`,
        reset_now: `Réinitialiser maintenant`,
        forgot_password: `Mot de passe oublié`,
        reset_password_subtitle: `Utilisez l'e-mail de votre compte pour réinitialiser le mot de passe`,
        plan_products: `Plan & Produits`,
        nick_name: `Surnom`,
        email: `E-mail`,
        my_products: `Mes produits`,
        my_orders: `Mes commandes`,
        unlink: `Dissocier`,
        link: `Associer`,
        connected_accounts: `Comptes associés`,
        last_name: `Nom de famille`,
        first_name: `Prénom`,
        Gender: `Genre`,
        Birth: `Naissance`,
        Month: `Mois`,
        Year: `Année`,
        Country_Region: `Pays/Région`,
        Address: `Adresse`,
        Save: `Enregistrer`,
        Date: `Date`,
        Male: `Homme`,
        Female: `Femme`,
        Unspecified: `Non spécifié`,
        Security: `Sécurité`,
        change_password: `Changer le mot de passe`,
        change_now: `Changer maintenant`,
        connect_email: `Connecter email`,
        delete_account: `Supprimer le compte`,
        delete_account_info: `Lorsque votre compte est supprimé, toutes les données du forfait et des produits associées à votre compte seront définitivement supprimées et vous ne pourrez peut-être pas les récupérer. Nous vous recommandons de faire preuve de prudence.`,
        Delete: `Supprimer`,
        Logout: `Se déconnecter`,
        my_profile: `Mon profil`,
        guides_faqs: `Guides & FAQs`,
        More: `Plus`,
        guides: `Guides`,
        register: `S'inscrire`,
        hot_faq: `FAQ chaude`,
        Contents: `Contenu:`,
        contact_us: `Contactez-nous>>`,
        plan: `Plan`,
        unregistered: `Non enregistré`,
        buy_more: `Acheter plus`,
        buy_again: `Acheter à nouveau`,
        buy_now: `Acheter maintenant`,
        free_no_limit: `Gratuit et sans limite`,
        expired: `Expiré`,
        lifetime: `Durée de vie`,
        remain: `Rester`,
        day_s: `Jours)`,
        day: `Jours`,
        error_24801: `Échec de la demande (code d'erreur : 24801). Veuillez réessayer`,
        no_app_found: `Aucune application trouvée！<a href="/">Actualiser</a> ou <a href="https://fr.tipard.com/">Accéder au site officiel</a>`,
        get_more: `Obtenir plus >>`,
        edit_photo: `Modifier photo`,
        select_photo: `Sélectionnez une photo`,
        change_photo: `Changer la photo`,
        cancel: `Annuler`,
        hide_password: `Masquer le mot de passe`,
        show_password: `Montrer le mot de passe`,
        zoom_in: `Agrandir`,
        zoom_out: `Dézoomer`,
        rotate: `Tourner`,
        horizontal_flip: `Retournement horizontal`,
        vertical_flip: `Retournement vertical`,
        country: `Pays`,
        country_1: `Sélectionnez votre pays/région`,
        country_2: `Île de land`,
        country_3: `Afghanistan`,
        country_4: `Albanie`,
        country_5: `Algérie`,
        country_6: `Samoa américaines`,
        country_7: `Andorre`,
        country_8: `Angola`,
        country_9: `Anguilla`,
        country_10: `Antarctique`,
        country_11: `Antigua-et-Barbuda`,
        country_12: `Argentine`,
        country_13: `Arménie`,
        country_14: `Aruba`,
        country_15: `Australie`,
        country_16: `L'Autriche`,
        country_17: `Azerbaïdjan`,
        country_18: `Bahreïn`,
        country_19: `Bangladesh`,
        country_20: `Barbade`,
        country_21: `Biélorussie`,
        country_22: `la Belgique`,
        country_23: `Belize`,
        country_24: `Bénin`,
        country_25: `Bermudes`,
        country_26: `Bhoutan`,
        country_27: `Bolivie`,
        country_28: `Bosnie-Herzégovine`,
        country_29: `Botswana`,
        country_30: `Île Bouvet`,
        country_31: `Brésil`,
        country_32: `Territoire britannique de l'océan Indien`,
        country_33: `Îles Vierges britanniques`,
        country_34: `Brunéi`,
        country_35: `Bulgarie`,
        country_36: `Burkina`,
        country_37: `Burundi`,
        country_38: `Cambodge`,
        country_39: `Cameroun`,
        country_40: `Canada`,
        country_41: `Cap-Vert`,
        country_42: `Pays-Bas caribéens`,
        country_43: `Îles Caïmans`,
        country_44: `République centrafricaine`,
        country_45: `Tchad`,
        country_46: `Chili`,
        country_47: `Chine`,
        country_48: `L'île de noël`,
        country_49: `Îles Cocos (Keeling)`,
        country_50: `Colombie`,
        country_51: `les Îles Cook`,
        country_52: `Costa Rica`,
        country_53: `"Côte d'Ivoire"`,
        country_54: `Croatie`,
        country_55: `Cuba`,
        country_56: `Chypre`,
        country_57: `République Tchèque`,
        country_58: `République Démocratique du Congo`,
        country_59: `Danemark`,
        country_60: `Djibouti`,
        country_61: `Dominique`,
        country_62: `République dominicaine`,
        country_63: `Équateur`,
        country_64: `Egypte`,
        country_65: `Le Salvador`,
        country_66: `Guinée Équatoriale`,
        country_67: `Érythrée`,
        country_68: `Estonie`,
        country_69: `Ethiopie`,
        country_70: `les îles Falkland`,
        country_71: `Îles Féroé`,
        country_72: `États fédérés de Micronésie`,
        country_73: `Fidji`,
        country_74: `Finlande`,
        country_75: `La France`,
        country_76: `Guyane Française`,
        country_77: `Polynésie française`,
        country_78: `Terres Australes Françaises`,
        country_79: `Gabon`,
        country_80: `Gambie`,
        country_81: `Géorgie`,
        country_82: `Allemagne`,
        country_83: `Ghana`,
        country_84: `Gibraltar`,
        country_85: `Grande-Bretagne (Royaume-Uni, Angleterre)`,
        country_86: `Grèce`,
        country_87: `Groenland`,
        country_88: `Grenade`,
        country_89: `Guadeloupe`,
        country_90: `Guam`,
        country_91: `Guatemala`,
        country_92: `Guernesey`,
        country_93: `Guinée`,
        country_94: `Guinée-Bissau`,
        country_95: `Guyane`,
        country_96: `Haïti`,
        country_97: `Île Heard et Îles McDonald`,
        country_98: `Honduras`,
        country_99: `Hong Kong`,
        country_100: `Hongrie`,
        country_101: `Islande`,
        country_102: `Inde`,
        country_103: `Indonésie`,
        country_104: `L'Iran`,
        country_105: `Irak`,
        country_106: `Irlande`,
        country_107: `île de Man`,
        country_108: `Israël`,
        country_109: `Italie`,
        country_110: `Jamaïque`,
        country_111: `Japon`,
        country_112: `Jersey`,
        country_113: `Jordan`,
        country_114: `Kazakhstan`,
        country_115: `Kenya`,
        country_116: `Kiribati`,
        country_117: `Koweit`,
        country_118: `Kirghizistan`,
        country_119: `Laos`,
        country_120: `Lettonie`,
        country_121: `Liban`,
        country_122: `Lesotho`,
        country_123: `Libéria`,
        country_124: `Libye`,
        country_125: `Liechtenstein`,
        country_126: `Lituanie`,
        country_127: `Luxembourg`,
        country_128: `Macao`,
        country_129: `Madagascar`,
        country_130: `Malawi`,
        country_131: `Malaisie`,
        country_132: `Maldives`,
        country_133: `Mali`,
        country_134: `Malte`,
        country_135: `Iles Marshall`,
        country_136: `Martinique`,
        country_137: `Mauritanie`,
        country_138: `Maurice`,
        country_139: `Mayotte`,
        country_140: `Mexique`,
        country_141: `Moldavie`,
        country_142: `Monaco`,
        country_143: `Mongolie`,
        country_144: `Monténégro`,
        country_145: `Montserrat`,
        country_146: `Maroc`,
        country_147: `Mozambique`,
        country_148: `Myanmar (Birmanie)`,
        country_149: `Namibie`,
        country_150: `Nauru`,
        country_151: `Népal`,
        country_152: `Pays-Bas`,
        country_153: `Nouvelle Calédonie`,
        country_154: `Nouvelle-Zélande`,
        country_155: `Nicaragua`,
        country_156: `Niger`,
        country_157: `Nigeria`,
        country_158: `Nioué`,
        country_159: `l'ile de Norfolk`,
        country_160: `Corée du Nord`,
        country_161: `Îles Mariannes du Nord`,
        country_162: `Norvège`,
        country_163: `Oman`,
        country_164: `Pakistan`,
        country_165: `Palaos`,
        country_166: `Territoires palestiniens`,
        country_167: `Panama`,
        country_168: `Papouasie Nouvelle Guinée`,
        country_169: `Paraguay`,
        country_170: `Pérou`,
        country_171: `Îles Pitcairn`,
        country_172: `Pologne`,
        country_173: `le Portugal`,
        country_174: `Porto Rico`,
        country_175: `Qatar`,
        country_176: `République de Macédoine (ARYM)`,
        country_177: `République du Congo`,
        country_178: `Réunion`,
        country_179: `Roumanie`,
        country_180: `Fédération Russe`,
        country_181: `Rwanda`,
        country_182: `Saint-Barthélemy`,
        country_183: `Saint-Martin (France)`,
        country_184: `Saint-Pierre-et-Miquelon`,
        country_185: `Samoa`,
        country_186: `Saint Marin`,
        country_187: `Sao Tomé et Principe`,
        country_188: `Arabie Saoudite`,
        country_189: `Sénégal`,
        country_190: `Serbie`,
        country_191: `les Seychelles`,
        country_192: `Sierra Leone`,
        country_193: `Singapour`,
        country_194: `Slovaquie`,
        country_195: `Slovénie`,
        country_196: `Les îles Salomon`,
        country_197: `Somalie`,
        country_198: `Afrique du Sud`,
        country_199: `Géorgie du Sud et l'île de Sandwich du Sud`,
        country_200: `Corée du Sud`,
        country_201: `Soudan du sud`,
        country_202: `Espagne`,
        country_203: `Sri Lanka`,
        country_204: `Sainte-Hélène & Dépendances`,
        country_205: `Saint-Kitts-et-Nevis`,
        country_206: `Sainte Lucie`,
        country_207: `Saint-Vincent-et-les Grenadines`,
        country_208: `Soudan`,
        country_209: `Surinam`,
        country_210: `Swaziland`,
        country_211: `Suède`,
        country_212: `la Suisse`,
        country_213: `Syrie`,
        country_214: `Taïwan`,
        country_215: `Tadjikistan`,
        country_216: `Tanzanie`,
        country_217: `Modèle : Données du pays SJM Svalbard`,
        country_218: `Thaïlande`,
        country_219: `Les Bahamas`,
        country_220: `Les Comores`,
        country_221: `Les Philippines`,
        country_222: `Timor-Leste (Timor oriental)`,
        country_223: `Aller`,
        country_224: `Tokélaou`,
        country_225: `Tonga`,
        country_226: `Trinité-et-Tobago`,
        country_227: `Tunisie`,
        country_228: `Turquie`,
        country_229: `Turkménistan`,
        country_230: `Îles Turques et Caïques`,
        country_231: `Tuvalu`,
        country_232: `Ouganda`,
        country_233: `Ukraine`,
        country_234: `Emirats Arabes Unis`,
        country_235: `Îles mineures éloignées des États-Unis`,
        country_236: `États-Unis d'Amérique (États-Unis)`,
        country_237: `Îles Vierges des États-Unis`,
        country_238: `Uruguay`,
        country_239: `Ouzbékistan`,
        country_240: `Vanuatu`,
        country_241: `Cité du Vatican (Saint-Siège)`,
        country_242: `Venezuela`,
        country_243: `Viêt Nam`,
        country_244: `Wallis et Futuna`,
        country_245: `Sahara occidental`,
        country_246: `Yémen`,
        country_247: `Zambie`,
        country_248: `Zimbabwe`,
        google_login: `Connexion avec Google`,
        State: `État`,
        Activation_code: `Code d'activation`,
        Question: `Répertoriez toutes les applications auxquelles vous vous êtes connecté`,
        Copy_complete: `Copie terminée`,
        change_password_success: `Le mot de passe a été changé avec succès`,
        successful_login_title: `Connexion réussie`,
        product_page: `Fiche produit>>`,
        successful_login_info: `Connexion terminée. Veuillez fermer la page actuelle et revenir à l'onglet d'origine pour continuer le processus suivant. La page en cours se fermera automatiquement dans 5 secondes. Si la fermeture automatique ou la fermeture en cliquant sur le bouton "Terminé" échoue, veuillez fermer cet onglet directement.`,
        successful_login_info_firefox: `Connexion terminée. Veuillez fermer la page actuelle et revenir à l'onglet d'origine pour continuer le processus suivant.`,
        my_account: `Mon compte`,
        my_history: `Mon histoire`,
        remove_watermark: `Supprimer filigrane`,
        no_history: `Pas d'historique`,
        history_all: `Tout sélectionner`,
        history_open: `Ouvert`,
        history_down: `Télécharger`,
        history_delete: `Supprimer`,
        history_clear: `Effacer invalide`,
        images: `images)`,
        use_this_function: `Utiliser cette fonction>>`,
        hd_downloading: `Avantages du téléchargement d'images originales HD :`,
        lifetimeRemaining: `Durée de vie restante`,
        Remaining: `Restant`,
        email_verification: `vérification de l'E-mail`,
        email_verification_info: `Nous avons envoyé le message de vérification à votre adresse e-mail <span class="email"></span>, et veuillez terminer la vérification. Après vérification, les avantages seront synchronisés automatiquement.`,
        wrong_email: `Mauvaise adresse email?`,
        click_here_to_modify: `Cliquez ici pour modifier`,
        get_more_help: `Obtenir plus d'aide ?`,
        click_here: `Cliquez ici`,
        email_verification_info_success: `Félicitations pour la vérification de votre compte de messagerie.`,
        email_verification_info_error: `La vérification a échoué en raison de l'expiration du lien.`,
        registration_succeeded: `Inscription réussie`,
        registration_succeeded_info_1: `Toutes nos félicitations! Vous vous êtes bien inscrit. Nous avons envoyé le message de vérification à votre adresse e-mail <span class="email"></span>, et veuillez terminer la vérification pour utiliser les avantages de cet e-mail.`,
        registration_succeeded_info_2: `Cliquez sur "Terminé" pour revenir à la page d'accueil et utiliser ce produit.`,
        registration_succeeded_info_3: `Cliquez sur "Terminé" pour fermer la page en cours et revenir à la page d'accueil pour les opérations suivantes. Si la fermeture échoue, veuillez fermer manuellement l'onglet.`,
        verify_email: `Vérifier les courriels`,
        registered_email_not_verified: `L'e-mail enregistré n'a pas été vérifié, veuillez le vérifier immédiatement.`,
        email_verification_time_1: `Vous n'avez pas reçu d'e-mail de vérification ?`,
        email_verification_time_2: `Après`,
        email_verification_time_3: `Cliquez ici pour le ré-expédier`,
        error_26301: `Code d'erreur : 26301, veuillez nous contacter`,
        error_26302: `Code d'erreur : 26302, veuillez nous contacter`,
        error_26303: `Erreur de format d'e-mail (code d'erreur : 26303). Veuillez le saisir à nouveau`,
        error_26304: `Un mot de passe supérieur à 8 caractères est recommandé (code d'erreur : 26304)`,
        error_26305: `Reuqest a échoué (code d'erreur : 26305). Veuillez réessayer`,
        error_26306: `L'e-mail a été enregistré, veuillez <a href="https://account.tipard.com/login">aller à la connexion</a>`,
        error_26307: `Reuqest a échoué (code d'erreur : 26307). Veuillez réessayer`,
        error_26308: `Reuqest a échoué (code d'erreur : 26308). Veuillez réessayer`,
        error_26401: `Code d'erreur : 26401, veuillez réessayer`,
        error_26402: `L'e-mail a été vérifié (code d'erreur : 26402), veuillez réessayer`,
        error_26403: `Reuqest a échoué (code d'erreur : 26403). Veuillez réessayer`,
        error_26404: `Échec de la demande (code d'erreur : 26404). Veuillez réessayer`,
        error_26501: `Code d'erreur : 26501, veuillez nous contacter`,
        error_26502: `Code d'erreur : 26502, veuillez nous contacter`,
        error_26503: `Erreur de format d'e-mail (code d'erreur : 26503). Veuillez le saisir à nouveau`,
        error_26504: `Reuqest a échoué (code d'erreur : 26504). Veuillez réessayer`,
        error_26505: `L'e-mail n'a pas été enregistré, veuillez <a href="https://account.tipard.com/register">l'enregistrer d'abord</a>`,
        error_26506: `L'e-mail a été vérifié.`,
        error_26507: `Reuqest a échoué (code d'erreur : 26507). Veuillez réessayer`,
        error_26508: `La vérification a échoué (code d'erreur : 26508), veuillez réessayer`,
        error_26509: `Échec de la demande (code d'erreur : 26509), veuillez réessayer`,
        error_26510: `Code d'erreur : 26510, veuillez nous contacter`,
        error_26601: `Code d'erreur : 26601, veuillez nous contacter`,
        error_26602: `Code d'erreur : 26602, veuillez nous contacter`,
        error_26603: `Reuqest a échoué (code d'erreur : 26603). Veuillez réessayer`,
        error_26604: `Code d'erreur : 26604, veuillez nous contacter`,
        error_26605: `Code d'erreur : 26605, veuillez nous contacter`,
        error_26701: `Code d'erreur : 26701, veuillez nous contacter`,
        error_26702: `Reuqest a échoué (code d'erreur : 26702). Veuillez réessayer`,
        error_26703: `Code d'erreur : 26703, veuillez nous contacter`,
        error_26704: `Code d'erreur : 26704, veuillez nous contacter`,
        error_26705: `Attendez la connexion (code d'erreur : 26705). Veuillez réessayer`,
        no_cookie: `Vous avez activé la fonction Bloquer tous les cookies dans votre navigateur, vous ne pouvez donc pas vous connecter. Veuillez vous rendre dans Paramètres pour cocher la case Autoriser tous les cookies.`,
        error_26801: `Code d'erreur : 26801, veuillez nous contacter`,
        error_26802: `Code d'erreur : 26802, veuillez nous contacter`,
        error_26803: `La demande a échoué (code d'erreur : 26803). Veuillez réessayer`,
        error_26804: `Échec de la demande (code d'erreur : 26804). Veuillez réessayer`,
        error_order: `Échec de la demande (code d'erreur : 27098), veuillez réessayer !`,
        error_order1: `La demande de commande est incomplète (code d'erreur :`,
        error_order2: `）, veuillez actualiser et réessayer.`,
        modify_email_title: `Modifier l'e-mail`,
        modify_email_info: `Vous pouvez utiliser l'e-mail modifié pour vous connecter à votre compte.`,
        images_per: `Images par`,
        error_26101: `Erreur : 26101. Veuillez nous contacter`,
        error_26102: `Erreur : 26102. Veuillez nous contacter`,
        error_26103: `Échec de la demande (code d'erreur : 26103). Veuillez réessayer`,
        error_26104: `Code d'erreur : 26104, veuillez réessayer`,
        error_26105: `Code d'erreur : 26105, veuillez réessayer`,
        error_26106: `Échec de la suppression (code d'erreur : 26106). Veuillez réessayer`,
        error_26201: `Erreur : 26201. Veuillez nous contacter`,
        error_26202: `Échec de la demande (code d'erreur : 26202). Veuillez réessayer`,
        error_26001: `Erreur : 26001. Veuillez nous contacter`,
        error_26002: `Erreur : 26002. Veuillez nous contacter`,
        error_26003: `Erreur : 26003. Veuillez nous contacter`,
        error_26004: `Erreur : 26004. Veuillez nous contacter`,
        error_26005: `Échec de la demande (code d'erreur : 26005). Veuillez réessayer`,
        error_26006: `Code d'erreur : 26006, veuillez réessayer`,
        error_26008: `Erreur : 26008. Veuillez nous contacter`,
        go_to_the_home_page: `Aller à la page d'accueil`,
        error_27101: `Échec de la demande (code d'erreur : 27101). Veuillez réessayer`,
        error_27201: `Code d'erreur : 27201, veuillez nous contacter`,
        error_27202: `Code d'erreur : 27202, veuillez réessayer`,
        error_27203: `Échec de la demande (code d'erreur : 27203). Veuillez réessayer`,
        error_27204: `Code invalide (code d'erreur : 27204).`,
        error_27205: `Échec de la demande (code d'erreur : 27205). Veuillez réessayer`,
        error_27206: `Échec de la requête (code d'erreur : 27206). Veuillez réessayer`,
        error_27207: `Échec de la demande (code d'erreur : 27207). Veuillez réessayer`,
        no_history_found: `Vous n'avez utilisé aucun outil! <a href="/">Actualiser</a> ou <a href="https://fr.tipard.com/">Accéder au site officiel</a>`,
        error_25301: `Erreur : 25301. Veuillez nous contacter`,
        error_25302: `Erreur : 25302. Veuillez nous contacter`,
        error_25303: `Échec de la demande (code d'erreur : 25303). Veuillez réessayer`,
        error_25304: `Échec de la demande (code d'erreur : 25304). Veuillez réessayer`,
        error_25305: `Échec de la demande (code d'erreur : 25305). Veuillez réessayer`,
        error_25306: `Échec de la demande (code d'erreur : 25306). Veuillez réessayer`,
        image_upscaler_p: `Télécharger des images sans filigrane:`,
        Available_for: `Disponible pour :`,
        credit_per: `%s crédit(s) par image HD`,
        still_valid: `Les forfaits achetés sont toujours valides`,
        credit: `crédit(s)`,
        pc_3rd_info: `Vous vous êtes connecté avec succès, veuillez vous rendre sur l'application pour continuer à l'utiliser.`,
        use_online: `Utiliser le service en ligne`,
        use_download: `Utiliser le programme de bureau`,
        use_immediately: `Utiliser maintenant`,
        Use_in_browser: `Utiliser dans le navigateur`,
        win_desktop: `Windows`,
        Mac_desktop: `Mac`,
        credits_per: `{%} Crédits par Mois`,
        expire: `Date d'expiration :`,
        viewDetails: `Voir les détails`,
        viewHistory: `Voir l'historique des avantages>>`,
        viewDetailsInfo: `Remarque : Si un abonnement est renouvelé dans les 7 jours suivant son expiration, les avantages non utilisés peuvent encore être utilisés. La date d'expiration sera automatiquement mise à jour en fonction de la date d'expiration du nouvel abonnement. Après l'expiration de l'abonnement pendant 7 jours, si aucun nouvel abonnement n'est effectué, tous les avantages expirés seront supprimés.`,
        "connect_account": "Lier l'E-mail à Votre Compte",
        "connect_account_info": "Après la liaison, vous pouvez vous connecter avec cette adresse E-mail.",
        "connect_now": "Lier maintenant",
        "no_email_bind": "Pas de liaison d'E-mail",
        "bind_email": "Lier l'E-mail",
        "connect_your_email_placeholder": "Veuillez saisir votre adresse E-mail",
        "bind_an_email": "Lier un E-mail",
        "bind_info": "Vous vous êtes connecté avec succès. Veuillez lier un E-mail à votre compte pour activer vos avantages.",
        "bind_later": "Lier Plus Tard",
        "footer": "Droits d'auteur © 2024 Tipard Studio. Tous les droits sont réservés.",
        "overtime": "Code d'erreur : {%}, veuillez réessayer",
        "hi": "Bonjour, %s!",
        "Personal_Information": "Renseignements Personnels",
        "Access": "Accéder",
        "Subscription_Plan": "(Plan d'Abonnement)",
        "No_orders": "Aucune commande trouvée.",
        "No_data": "Aucune donnée",
        "Featured_Products": "Produits Phares",
        "More_Products": "Plus de Produits",
        "Free_Download": "Téléchargement Gratuit",
        "Get_Started": "Commencer",
        "Subscribe": "S'abonner",
        "Verified": "Vérifié",
        "back_to_account_center": "Retour au Centre de Compte",
        "success": "Succès !",
        "successfully": "Vous avez enregistré un compte avec succès.",
        "Continue": "Continuer",
        "Already": "Vous avez déjà un compte ?",
        "loading_verification": "Vérification du statut de vérification...",
        "email_no_verification": "Désolé, l'adresse e-mail enregistrée n'a pas été vérifiée. Veuillez effectuer la vérification selon les instructions ci-dessus et cliquez à nouveau sur le bouton \"Terminé\" pour terminer l'enregistrement.",
        "error_20505": "L'e-mail a été lié",
        "error_20506": "Erreur : 20506. Veuillez nous contacter",
        "error_28401": "Veuillez saisir votre adresse e-mail",
        "error_28402": "Format d'e-mail incorrect",
        "error_28403": "Erreur : 28403. Veuillez nous contacter",
        "error_28404": "L'adresse e-mail a été enregistrée",
        "error_28405": "Erreur : 28405. Veuillez nous contacter",
        "error_28406": "L'e-mail a été lié",
        "error_28407": "Erreur : 28407. Veuillez nous contacter",
        "error_28408": "Échec de l'envoi de l'e-mail",
        "error_28601": "Veuillez saisir votre adresse e-mail",
        "error_28602": "Veuillez saisir le code de vérification",
        "error_28603": "Format d'e-mail incorrect",
        "error_28604": "Erreur : 28604. Veuillez nous contacter",
        "error_28605": "Le code de vérification est incorrect",
        "error_28606": "Erreur : 28606. Veuillez nous contacter",
        "error_28607": "L'e-mail a été enregistré",
        "error_28608": "Aucun e-mail n'est lié à votre compte",
        "error_28609": "L'e-mail n'est pas vérifié",
        "error_28610": "Erreur : 28610. Veuillez nous contacter",
        "error_28611": "L'e-mail a été lié",
        "error_28612": "Erreur : 28612. Veuillez nous contacter",
        "unbind_email_btn": "Désassocier l'e-mail",
        "binding_exchange": "Lier l'E-mail",
        "Subscription_Status": "État de l'Abonnement",
        "mask_unbind_info_1": "Êtes-vous sûr de vouloir dissocier {%} de ce compte ? Après la dissociation, vous ne pourrez plus vérifier les avantages de cet e-mail",
        "mask_unbind_info_2": "Etes-vous sûr de vouloir dissocier tous les PC activés par ce code d'enregistrement ? <a href=\"https://fr.tipard.com/faq.html#unbind\">En savoir plus sur le changement d'appareil.</a>",
        "error_dc_link": "Erreur, veuillez nous contacter",
        "vcu_state": "Avantages de la Version Complète :",
        "monthly_subscription": "Licence 1 Mois",
        "year_subscription": "Licence 1 An",
        "to": "à",
        "lifetime_subscription": "Licence À Vie",
        "unbind": "Confirmer",
        "unbind_success": "Désassociation réussie",
        "error_28401": "Veuillez saisir votre e-mail",
        "error_28702": "Erreur : 28702. Veuillez nous contacter",
        "error_28703": "Le code de vérification est incorrect",
        "error_28704": "Erreur : 28704. Veuillez nous contacter",
        "error_28705": "L'e-mail n'a pas été associé",
        "error_28706": "Veuillez saisir le code de vérification",
        "error_28707": "Échec de la dissociation",
        "error_28901": "Erreur : 28901. Veuillez nous contacter",
        "error_28902": "Erreur : 28902. Veuillez nous contacter",
        "error_28903": "Erreur : 28903. Veuillez nous contacter",
        "error_28904": "Erreur : 28904. Veuillez nous contacter",
        "error_28905": "Erreur : 28905. Veuillez nous contacter",
        "error_28906": "Erreur : 28906. Veuillez nous contacter",
        "error_28907": "Code d'inscription non valide",
        "error_28908": "Erreur : 28908. Veuillez nous contacter",
        "error_28909": "Actuellement en période de refroidissement de déconnexion. Vous ne pouvez vous déconnecter qu'une fois par mois.",
        "error_289010": "Erreur : 289010. Veuillez nous contacter",
        "error_289011": "Erreur : 289011. Veuillez nous contacter",
        "error_289012": "Erreur : 289012. Veuillez nous contacter",
        "activated_computer": "Activé sur %s/%s ordinateur(s)",
        "bind_email_info": "Les avantages des e-mails suivants seront liés et utilisés sous ce compte VIP.",
        "error_26309": "Erreur : 26309. Veuillez nous contacter",
        "error_26310": "Code de parrainage non valide",
        "error_26311": "Erreur : 26311. Veuillez nous contacter",
        "error_26312": "Code de parrainage non valide",
        "error_26313": "Erreur : 26313. Veuillez nous contacter",
        "error_26314": "L'e-mail a été lié",
        "activate_unbinding": "Changer d'Appareil",
        "copy_registration_code": "Copier le code d'inscription",
        "show_registration_code": "Afficher le code d'inscription",
        "hide_registration_code": "Masquer le code d'inscription",
        "other_binding_email": "Autre E-mail de liaison",
        "home": "Maison",
        "points_balance": "Solde de points :",
        "earn_now": "Gagnez maintenant >>",
        "earn_more": "Gagnez plus de points GRATUITS, échangez plus de cadeaux",
        "earn_or_redeem_points": "Gagnez/échangez des points",
        "unbind_email_info": "Le compte actuel a été associé à une adresse e-mail",
        "bind_email_title": "Si vous souhaitez associer plusieurs commandes pour l'achat d'adresses e-mail, veuillez cliquer sur le bouton « Association d'e-mails » ci-dessous pour procéder à l'association.",
        "bind_email_tip": "<span>Astuce :</span> Actuellement, seuls les achats de codes d'inscription par e-mail sont pris en charge.",
        "email_association": "Association de courrier électronique",
        "dissociation": "Dissociation",
        "back": "Dos",
        "activate_benefits": "Veuillez saisir l'adresse e-mail d'achat qui doit être associée pour obtenir le code de vérification. Saisissez le code de vérification reçu dans l'e-mail dans la zone de saisie du code de vérification, puis cliquez sur le bouton « Vérifier l'activation » pour activer les avantages.",
        "e_mail": "E-mail:",
        "verification_code": "Le code de vérification:",
        "obtain_verification_code": "Obtenir le code de vérification",
        "verification_association": "Vérifier l'association",
        "current_password": "Mot de passe actuel:",
        "account_center": "Centre de compte",
        "new_password": "Nouveau mot de passe:",
        "confirm_password": "Confirmer le nouveau mot de passe :",
        "Send": "Envoyer",
        "history_files": "Dossiers historiques",
        "delete_account_info_1": "1. La suppression de votre compte Tipard est une action définitive et irréversible. Toutes vos données, y compris vos photos et vos préférences, seront perdues. Êtes-vous sûr de vouloir supprimer votre compte Tipard ?",
        "delete_account_info_2": "2. Si vous décidez de supprimer le compte Tipard, veuillez cliquer sur le bouton Envoyer et saisir le code de vérification de l'e-mail que nous vous avons envoyé.",
        "best_deals": "Les meilleures offres",
        "close": "Fermer",
        "product": "Produit:"
    },
    hu: {
        overtime_1: `Hibakód:`,
        overtime_2: `, próbálja újra`,
        isnetwork: `Internet hiba. Kérjük, ellenőrizze és próbálja újra`,
        email_placeholder: `Email`,
        email_empty: `Kérjük, adja meg az e -mailt`,
        email_not_valid: `az e-mail nem érvényes`,
        email_not_valid_1: `Kérjük, adja meg e -mail címét`,
        email_not_valid_2: `Az e -mail nem érvényes, kérjük, használjon másik címet.`,
        email_not_valid_3: `Nincs e -mail beviteli lehetőség`,
        password_placeholder: `Jelszó`,
        password_empty: `Kérjük, adja meg a jelszót`,
        password_not_valid: `Helytelen fiók vagy jelszó`,
        password_not_valid_1: `8 karakter feletti jelszó szükséges`,
        password_not_valid_2: `Kérjük, hozzon létre jelszót`,
        password_placeholder_1: `Hozd létre a jelszavad`,
        password_placeholder_2: `Erősítse meg a jelszót`,
        password_placeholder_3: `Hozzon létre új jelszót`,
        password_placeholder_4: `Erősítse meg az új jelszavát`,
        password_placeholder_5: `Írja be a régi jelszót`,
        copy_password_empty: `Kérjük, erősítse meg a jelszót`,
        copy_password_not_valid: `Kérjük, erősítse meg jelszavát`,
        copy_passwords_inconsistent: `A jelszó megerősítése nem egyezik`,
        code_empty: `Kérjük, adja meg az ellenőrző kódot`,
        code_not_valid: `Érvénytelen ellenörző kód`,
        code_placeholder: `Ellenőrző kód`,
        not_received_code: `Ha a postafiókja hosszú ideig nem kapja meg az ellenőrző kódot, kérjük, szerezze be újra az ellenőrző kódot.`,
        get_first_code: `Kérjük, először szerezze be az ellenőrző kódot.`,
        last_name_placeholder: `Kérlek írd be a vezetékneved`,
        first_name_placeholder: `Kérjük, adja meg keresztnevét`,
        address_placeholder: `Kérjük, adja meg címét`,
        no_code_text: `Elküldtünk egy ellenőrző kódot. Kérjük, írja be a kódot. <span class='tips'>Nem kapott kódot?`,
        no_code_text_1: `1. Kérjük, győződjön meg arról, hogy az e -mail cím érvényes, és képes e -maileket fogadni.`,
        no_code_text_2: `2. Ha az e -mailt a rendszer automatikusan küldi, előfordulhat, hogy spamként vagy levélszemétként van megjelölve. Kérjük, ellenőrizze, hogy az e -mail a Kuka mappában van -e.`,
        no_code_text_3: `3. Nem tudja megoldani a problémát?`,
        no_code_text_3_span: `Ezután kattintson ide, és lépjen kapcsolatba velünk.`,
        order_no: `Még nem vásárolt terméket. Bármilyen kérdése van, kérjük, <a href="https://hu.tipard.com/store.html">Vásároljon most</a> vagy <a href="https://hu.tipard.com/contact.html"> Vegye fel velünk a kapcsolatot</a>`,
        error_24901: `A jelenlegi fiókhoz nincs összekapcsolva e -mail, és nem találja a rendeléseket. Kérjük, csatlakoztasson egy e -mailt.`,
        user_guide: `Felhasználói útmutató >>`,
        download: `Letöltés`,
        order_number: `Rendelésszám`,
        Refund: `Visszatérítés`,
        Disabled: `Tiltva`,
        Normal: `Normál`,
        Modify: `Módosít`,
        Modify_1: `Módosítás >>`,
        Connect: `Csatlakozás`,
        unlink_success: `A leválasztás sikeresen megtörtént`,
        connect_success: `Csatlakozás sikeres`,
        feedback_title: `Köszönjük a visszajelzését. Hagyja el problémáját, és 24 órán belül válaszolunk.`,
        feedback_thank_you: `Köszönjük! <br /> Visszajelzését sikeresen elküldtük.`,
        feedback_email: `Írja be ide az e -mail címét!`,
        feedback_content: `Hagyjon itt bármilyen problémát vagy javaslatot, amellyel találkozott.`,
        feedback_submit: `Beküldés`,
        form_contents: `Nem adott meg leírást. Kérjük, adja meg, és küldje be újra.`,
        old_password: `Kérjük, adja meg a régi jelszót`,
        new_password: `Kérjük, hozzon létre új jelszót`,
        old_new_password: `Az új jelszó nem lehet ugyanaz, mint a régi`,
        incorrect_password: `hibás jelszó`,
        delete_no: `Törölje most`,
        Caps: `A Caps lock be van kapcsolva`,
        Get: `Kap`,
        Done: `Kész`,
        error_20001: `Hiba: 20001. Kérjük, jelentkezzen be újra.`,
        error_20002: `Hiba: 20002. Kérjük, jelentkezzen be újra.`,
        error_20003: `Hiba: 20003. Kérjük, jelentkezzen be újra.`,
        error_20004: `A kérés sikertelen (hibakód: 20004). Kérjük, próbálja újra.`,
        error_20005: `A bejelentkezési munkamenet lejárt (hiba: 20005). Kérjük, jelentkezzen be újra.`,
        error_20006: `A kérés sikertelen (hibakód: 20006). Kérjük, próbálja újra.`,
        error_20007: `A bejelentkezési munkamenet lejárt (Hiba: 20007). Kérjük, jelentkezzen be újra.`,
        error_20008: `A bejelentkezési munkamenet lejárt (Hiba: 20008). Kérjük, jelentkezzen be újra.`,
        error_20009: `A bejelentkezési munkamenet lejárt (hiba: 20009). Kérjük, jelentkezzen be újra.`,
        error_20101: `Kérjük, adja meg e -mail címét (hibakód: 20101)`,
        error_20102: `Az e -mail -cím érvénytelen (hibakód: 20102)`,
        error_20103: `A kérés sikertelen (hibakód: 20103). Kérjük, próbálja újra`,
        error_20104: `Az e -mail -cím már használatban van, kérjük, <a href="https://account.tipard.com/login"> jelentkezzen be </a>, vagy regisztráljon egy újat`,
        error_20105: `A kérés sikertelen (hibakód: 20105). Kérjük, próbálja újra`,
        error_20106: `Nem sikerült elküldeni az e -mailt, próbálja újra`,
        error_20201: `Kérjük, adja meg e -mail címét (hibakód: 20201)`,
        error_20202: `Kérjük, adja meg jelszavát (hibakód: 20202)`,
        error_20203: `Kérjük, adja meg az ellenőrző kódot (hibakód: 20203)`,
        error_20204: `Az e -mail -cím érvénytelen (hibakód: 20204)`,
        error_20205: `8 karakter feletti jelszó szükséges (hibakód: 20205)`,
        error_20206: `A kérés sikertelen (hibakód: 20206). Kérjük, próbálja újra`,
        error_20207: `Érvénytelen ellenörző kód`,
        error_20208: `A kérés sikertelen (hibakód: 20208). Kérjük, próbálja újra`,
        error_20209: `A kérés sikertelen (hibakód: 20209). Kérjük, próbálja újra`,
        error_20301: `Kérjük, adja meg e -mail címét (hibakód: 20301)`,
        error_20302: `Hiba: 20302. Kérjük, vegye fel velünk a kapcsolatot`,
        error_20303: `Az e -mail -cím érvénytelen (hibakód: 20303)`,
        error_20304: `A kérés sikertelen (hibakód: 20304). Kérjük, próbálja újra`,
        error_20305: `Fiók nem létezik. Kérjük, először írja be újra, vagy <a href="https://account.tipard.com/register">Létrehozni</a>.`,
        error_20306: `Még nincs jelszó. Használja a <a href="https://account.tipard.com/passwordless-login"> Jelszó nélküli bejelentkezés </a> vagy a <a href="create-password"> jelszó beállítását </a>, és jelentkezzen be.`,
        error_20308: `A kérés sikertelen (hibakód: 20308). Kérjük, próbálja újra`,
        error_20401: `Nem sikerült kijelentkezni (hibakód: 20401). Kérjük, próbálja újra`,
        error_20501: `Kérjük, adja meg e -mail címét (hibakód: 20501)`,
        error_20502: `Az e -mail -cím érvénytelen (hibakód: 20502)`,
        error_20503: `A kérés sikertelen (hibakód: 20503). Kérjük, próbálja újra`,
        error_20504: `Nem sikerült elküldeni az e -mailt. Kérjük, próbálja újra.`,
        error_20601: `Kérjük, adja meg e -mail címét (hibakód: 20601)`,
        error_20602: `Kérjük, adja meg az ellenőrző kódot (hibakód: 20602)`,
        error_20603: `Az e -mail -cím érvénytelen (hibakód: 20603)`,
        error_20604: `A kérés sikertelen (hibakód: 20604). Kérjük, próbálja újra`,
        error_20606: `A kérés sikertelen (hibakód: 20606). Kérjük, próbálja újra`,
        error_20607: `A kérés sikertelen (hibakód: 20607). Kérjük, próbálja újra`,
        error_20608: `A kérés sikertelen (hibakód: 20608). Kérjük, próbálja újra`,
        error_20701: `Kérjük, adja meg e -mail címét (hibakód: 20701)`,
        error_20702: `Az e -mail -cím érvénytelen (hibakód: 20702)`,
        error_20703: `A kérés sikertelen (hibakód: 20703). Kérjük, próbálja újra`,
        error_20704: `Fiók nem létezik. Kérjük, először írja be újra, vagy <a href="https://account.tipard.com/register">Létrehozni</a>.`,
        error_20705: `A kérés sikertelen (hibakód: 20705). Kérjük, próbálja újra`,
        error_20706: `Nem sikerült elküldeni az e -mailt. Kérjük, próbálja újra`,
        error_20801: `Kérjük, adja meg e -mail címét (hibakód: 20801)`,
        error_20802: `Hiba: 20802. Kérjük, vegye fel velünk a kapcsolatot`,
        error_20803: `Kérjük, adja meg az ellenőrző kódot (hibakód: 20803)`,
        error_20804: `Az e -mail -cím érvénytelen (hibakód: 20804)`,
        error_20805: `8 karakter feletti jelszó szükséges (hibakód: 20805)`,
        error_20806: `A kérés sikertelen (hibakód: 20806). Kérjük, próbálja újra`,
        error_20808: `A kérés sikertelen (hibakód: 20808). Kérjük, próbálja újra`,
        error_20901: `A kérés sikertelen (hibakód: 20901). Kérjük, próbálja újra`,
        error_20902: `A kérés sikertelen (hibakód: 20902). Kérjük, próbálja újra`,
        error_21000: `A módosítások mentésre kerülnek`,
        error_21001: `Nincs információ benyújtva (hibakód: 21001)`,
        error_21002: `A kérés sikertelen (hibakód: 21002). Kérjük, próbálja újra`,
        error_21101: `Kérjük, adja meg e -mail címét (hibakód: 21101)`,
        error_21102: `Az e -mail -cím érvénytelen (hibakód: 21102)`,
        error_21103: `A kérés sikertelen (hibakód: 21103). Kérjük, próbálja újra`,
        error_21104: `Az e -mail már csatlakoztatva van, használjon újat`,
        error_21105: `A kérés sikertelen (hibakód: 21105). Próbálkozzon újra`,
        error_21106: `Nem sikerült elküldeni az e -mailt. Kérjük, próbálja újra`,
        error_21201: `Kérjük, adja meg e -mail címét (hibakód: 21201)`,
        error_21202: `Kérjük, adja meg az ellenőrző kódot (hibakód: 21202)`,
        error_21203: `Az e -mail -cím érvénytelen (hibakód: 21203)`,
        error_21204: `Hiba: 21204. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21205: `Hiba: 21205. Kérjük, lépjen kapcsolatba velünk`,
        error_21206: `8 karakter feletti jelszó megadása szükséges (hibakód: 21206)`,
        error_21207: `A kérés sikertelen (hibakód: 21207). Kérjük, próbálja újra`,
        error_21209: `A kérés sikertelen (hibakód: 21209). Kérjük, próbálja újra`,
        error_21301: `Kérjük, adja meg a régi jelszót (hibakód: 21301)`,
        error_21302: `Kérjük, hozzon létre új jelszót (hibakód: 21302)`,
        error_21303: `Az új jelszó nem lehet ugyanaz, mint a régi. (Hiba: 21303)`,
        error_21304: `8 karakter feletti jelszó megadása szükséges (hibakód: 21304)`,
        error_21306: `A kérés sikertelen (hibakód: 21306). Kérjük, próbálja újra`,
        error_21402: `A kérés sikertelen (hibakód: 21402). Kérjük, próbálja újra`,
        error_21403: `Nem sikerült elküldeni az ellenőrző kódot. Kérjük, küldje el újra`,
        error_21500: `A fiók törölve lett`,
        error_21501: `Kérjük, adja meg az ellenőrző kódot (hibakód: 21501)`,
        error_21502: `A bejelentkezési munkamenet lejárt (hiba: 21502). Kérjük, jelentkezzen be újra.`,
        error_21503: `A kérés sikertelen (hibakód: 21503). Kérjük, próbálja újra`,
        error_21505: `A kérés sikertelen (hibakód: 21505), próbálja újra`,
        error_21601: `Hiba: 20601. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21602: `Érvénytelen ellenőrzés (hiba: 20602). Kérjük, próbálja újra.`,
        error_21603: `Hiba: 20603. Kérjük, próbálja újra`,
        error_21604: `A kérés sikertelen (hibakód: 21604). Kérjük, próbálja újra`,
        error_21606: `A kérés sikertelen (hibakód: 21606). Kérjük, próbálja újra`,
        error_21611: `A kérés sikertelen (hibakód: 21611). Kérjük, próbálja újra`,
        error_21801: `Hiba: 21801. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21802: `A kérés sikertelen (hiba: 21802). Kérjük, próbálja újra`,
        error_21803: `Hiba: 21803. Kérjük, próbálja újra`,
        error_21804: `A kérés sikertelen (hibakód: 21804). Kérjük, próbálja újra`,
        error_21806: `Hiba: 21806. Kérjük, próbálja újra`,
        error_21807: `Hiba: 21807. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21808: `Hiba: 21808. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21809: `Hiba: 21809. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21810: `Hiba: 21810. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21811: `Hiba: 21811. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21812: `Hiba: 21812. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21813: `A kérés sikertelen (hibakód: 21813). Kérjük, próbálja újra`,
        error_21814: `Hiba: 21814. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21815: `A kérés sikertelen (hibakód: 21815). Kérjük, próbálja újra`,
        error_21816: `Hiba: 21816. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21817: `Hiba: 21817. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21818: `Hiba: 21818. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21819: `A kérés sikertelen (hibakód: 21819). Kérjük, próbálja újra`,
        error_21820: `Hiba: 21820. Kérjük, lépjen kapcsolatba velünk`,
        error_21821: `Hiba: 21821. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21822: `Hiba: 21822. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21823: `A kérés sikertelen (hibakód: 21823). Kérjük, próbálja újra`,
        error_21824: `A kérés sikertelen (hibakód: 21824). Kérjük, próbálja újra`,
        error_21825: `A kérés sikertelen (hibakód: 21825). Kérjük, próbálja újra`,
        error_21826: `A kérés sikertelen (hibakód: 21826). Kérjük, próbálja újra`,
        error_21901: `Hiba: 21901. Kérjük, vegye fel velünk a kapcsolatot`,
        error_21902: `A kérés sikertelen (hibakód: 21902). Kérjük, próbálja újra`,
        error_21903: `A fiók állapota megváltozott (hibakód: 21903). Kérjük, frissítse az oldalt, és próbálja újra`,
        error_21904: `Hiba: 21904. Kérjük, próbálja újra`,
        error_21905: `Hiba: 21905. Kérjük, próbálja újra`,
        error_21906: `A kérés sikertelen (hibakód: 21906). Kérjük, próbálja újra`,
        error_21907: `A Google -fiók össze van kapcsolva egy másik fiókkal`,
        error_21908: `A kérés sikertelen (hibakód: 21908). Kérjük, próbálja újra`,
        error_22001: `A kérés sikertelen (hibakód: 22001). Kérjük, próbálja újra`,
        error_22002: `A leválasztás további bejelentkezés nélkül nem sikerült`,
        error_22003: `A kérés sikertelen (hibakód: 22003). Kérjük, próbálja újra`,
        error_22101: `Hiba: 22101. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22102: `A fiók állapota megváltozott (hibakód: 22102). Kérjük, frissítse az oldalt, és próbálja újra`,
        error_22103: `A kérés sikertelen (hibakód: 22103). Kérjük, próbálja újra`,
        error_22104: `A fiók állapota megváltozott (hibakód: 22104). Kérjük, frissítse az oldalt, és próbálja újra`,
        error_22105: `Hiba: 22105. Próbálja újra`,
        error_22106: `Hiba: 22106. Kérjük, próbálja újra`,
        error_22107: `Hiba: 22107. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22108: `A kérés sikertelen (hibakód: 22108). Kérjük, próbálja újra`,
        error_22201: `Hiba: 22201. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22202: `Érvénytelen ellenőrzés (Hiba: 22202). Kérjük, próbálja újra.`,
        error_22203: `Hiba: 22203. Kérjük, próbálja újra "`,
        error_22204: `A kérés sikertelen (hibakód: 22204). Kérjük, próbálja újra`,
        error_22206: `A kérés sikertelen (hibakód: 22206). Kérjük, próbálja újra`,
        error_22401: `Hiba: 22401. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22402: `Érvénytelen ellenőrzés (Hiba: 22402). Kérjük, próbálja újra.`,
        error_22403: `Hiba: 22403. Kérjük, próbálja újra`,
        error_22404: `A kérés sikertelen (hibakód: 22404). Kérjük, próbálja újra`,
        error_22405: `A Facebook -fiók össze van kapcsolva más e -mailekkel`,
        error_22406: `Hiba: 22406. Kérjük, próbálja újra`,
        error_22407: `Hiba: 22407. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22408: `Hiba: 22408. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22409: `Hiba: 22409. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22410: `Hiba: 224010. Kérjük, lépjen kapcsolatba velünk`,
        error_22411: `Hiba: 224011. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22412: `Hiba: 224012. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22413: `Hiba: 22413. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22414: `A kérés sikertelen (hibakód: 22414). Kérjük, próbálja újra`,
        error_22415: `Hiba: 22415. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22416: `Hiba: 22416. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22417: `Hiba: 22417. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22418: `A kérés sikertelen (hibakód: 22418). Kérjük, próbálja újra`,
        error_22419: `Hiba: 22419. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22420: `Hiba: 22420. Kérjük, lépjen kapcsolatba velünk`,
        error_22421: `Hiba: 22421. Kérjük, vegye fel velünk a kapcsolatot`,
        error_22422: `A kérés sikertelen (hibakód: 22422). Kérjük, próbálja újra`,
        error_22423: `A kérés sikertelen (hibakód: 22423). Kérjük, próbálja újra`,
        error_22424: `A kérés sikertelen (hibakód: 22424). Kérjük, próbálja újra`,
        error_22425: `A kérés sikertelen (hibakód: 22425). Kérjük, próbálja újra`,
        error_20098: `Hibakód: 20098. Ha Privát módban böngészik, váltson Normál módba, és próbálja újra.`,
        error_22298: `A Google -kérés sikertelen (hiba: 22298). Kérjük, próbálja újra.`,
        error_22498: `A Facebook -kérés sikertelen (hiba: 22498). Kérjük, próbálja újra.`,
        error_24902: `A kérés sikertelen (hibakód: 24902). Kérjük, próbálja újra`,
        error_24903: `A kérés sikertelen (hibakód: 24903). Kérjük, próbálja újra`,
        error_24904: `A kérés sikertelen (hibakód: 24904). Kérjük, próbálja újra`,
        error_24905: `A kérés sikertelen (hibakód: 24905). Kérjük, próbálja újra`,
        login_title: `Jelentkezzen be az Tipardba`,
        password_placeholder: `Jelszó`,
        log_in: `Belépés`,
        no_account: `Nincs fiókja?`,
        create_it: `Létrehozni`,
        or_log_in_with: `Vagy jelentkezzen be a`,
        passwordless_login: `Jelszó nélküli bejelentkezés`,
        log_in_done: `Bejelentkezés Kész`,
        three_rd_account_connect_info: `Gratulálunk! Sikeresen bejelentkezett. Most egy e -mail fiókot csatlakoztathat a jövőbeni bejelentkezéshez.`,
        see_my_account: `Lásd a fiókomat`,
        three_rd_login_merge_account: `A harmadik fél fiókjának e-mail címe regisztrálva van. Szeretne közvetlenül ezzel az e-mail címmel csatlakozni és bejelentkezni?`,
        connect_log_in: `Csatlakozás és bejelentkezés`,
        create_an_account: `Hozzon létre egy fiókot`,
        back_to_log_in: `Vissza a bejelentkezéshez`,
        create_password: `Jelszó létrehozása`,
        create_now: `Létrehozás most`,
        password_login_subtitle: `Jelszó nélküli bejelentkezés e -mailben`,
        account_login: `Fiók Belépés`,
        rights: `A fiók létrehozásával elfogadja az <a href="https://hu.tipard.com/terms.html"> Általános Szerződési Feltételeket </a> és <a href="https://hu.tipard.com/policy.html">Adatvédelmi irányelvek </a>`,
        passwordless_login_done: `Jelszó nélküli bejelentkezés megtörtént`,
        passwordless_login_info: `Gratulálunk, sikeresen befejezte a jelszó nélküli bejelentkezést. Létrehozhat jelszót ehhez a fiókhoz, és a jövőben bejelentkezhet ezzel a fiókkal és jelszóval. <a href="/create-password" style="display: initial;"> Létrehozás </a>`,
        sign_up: `Regisztrálj`,
        register_info: `Hozd létre fiókodat`,
        reset_now: `Állítsa vissza most`,
        forgot_password: `Elfelejtett jelszó`,
        reset_password_subtitle: `Használja fiókja e -mail címét a jelszó visszaállításához`,
        plan_products: `Terv és termékek`,
        nick_name: `Felhasználónév`,
        email: `Email`,
        my_products: `Saját termékek`,
        my_orders: `Rendeléseim`,
        unlink: `Leválasztás`,
        link: `Link`,
        connected_accounts: `Összekapcsolt fiókok`,
        last_name: `Vezetéknév`,
        first_name: `Keresztnév`,
        Gender: `Nem`,
        Birth: `Születés`,
        Month: `Hónap`,
        Year: `Év`,
        Country_Region: `Ország/régió`,
        Address: `Cím`,
        Save: `Megment`,
        Date: `Dátum`,
        Male: `Férfi`,
        Female: `Női`,
        Unspecified: `Meg nem határozott`,
        Security: `Biztonság`,
        change_password: `Jelszó módosítása`,
        change_now: `Változtass most`,
        connect_email: `Csatlakoztassa az e -mailt`,
        delete_account: `Fiók törlése`,
        delete_account_info: `A fiók törlésekor a tervhez és termékekhez tartozó összes, a fiókjához társított adat véglegesen törlődik, és előfordulhat, hogy nem tudja helyreállítani. Javasoljuk, hogy óvatosan lépjen.`,
        Delete: `Töröl`,
        Logout: `Kijelentkezés`,
        my_profile: `A profilom`,
        guides_faqs: `Útmutatók és GYIK`,
        More: `Több`,
        guides: `Útmutatók`,
        register: `Regisztráció`,
        hot_faq: `Forró GYIK`,
        Contents: `Tartalom:`,
        contact_us: `Vegye fel velünk a kapcsolatot >>`,
        plan: `Terv`,
        unregistered: `Nem regisztrált`,
        buy_more: `Többet vásárolnak`,
        buy_again: `Vásároljon újra`,
        buy_now: `Vásárolj most`,
        free_no_limit: `Ingyenes és nincs korlátozás`,
        expired: `Lejárt`,
        lifetime: `Élettartam`,
        remain: `Marad`,
        day_s: `Napok)`,
        day: `Napok`,
        error_24801: `A kérés sikertelen (hibakód: 24801). Kérjük, próbálja meg újra`,
        no_app_found: `Nem található alkalmazás!<a href="/">Frissítés</a> vagy <a href="https://hu.tipard.com/">Ugrás a hivatalos webhelyre</a>`,
        get_more: `További információ >>`,
        edit_photo: `Fénykép szerkesztése`,
        select_photo: `Válassza ki a fényképet`,
        change_photo: `Fotó megváltoztatása`,
        cancel: `Megszünteti`,
        hide_password: `Jelszó elrejtése`,
        show_password: `Mutasd a jelszót`,
        zoom_in: `Nagyítás`,
        zoom_out: `Kicsinyítés`,
        rotate: `Forog`,
        horizontal_flip: `Vízszintes flip`,
        vertical_flip: `Függőleges flip`,
        country: `Ország`,
        country_1: `Válassza ki országát/régióját`,
        country_2: `Åaland-sziget`,
        country_3: `Afganisztán`,
        country_4: `Albánia`,
        country_5: `Algéria`,
        country_6: `Amerikai Szamoa`,
        country_7: `Andorra`,
        country_8: `Angola`,
        country_9: `Anguilla`,
        country_10: `Antarktisz`,
        country_11: `Antigua és Barbuda`,
        country_12: `Argentína`,
        country_13: `Örményország`,
        country_14: `Aruba`,
        country_15: `Ausztrália`,
        country_16: `Ausztria`,
        country_17: `Azerbajdzsán`,
        country_18: `Bahrein`,
        country_19: `Banglades`,
        country_20: `Barbados`,
        country_21: `Fehéroroszország`,
        country_22: `Belgium`,
        country_23: `Belize`,
        country_24: `Benin`,
        country_25: `Bermuda`,
        country_26: `Bhután`,
        country_27: `Bolívia`,
        country_28: `Bosznia és Hercegovina`,
        country_29: `Botswana`,
        country_30: `Bouvet-sziget`,
        country_31: `Brazília`,
        country_32: `Brit Indiai-óceáni Terület`,
        country_33: `Brit Virgin szigetek`,
        country_34: `Brunei`,
        country_35: `Bulgária`,
        country_36: `Burkina`,
        country_37: `Burundi`,
        country_38: `Kambodzsa`,
        country_39: `Kamerun`,
        country_40: `Kanada`,
        country_41: `zöld-fok`,
        country_42: `karibi Hollandia`,
        country_43: `Kajmán-szigetek`,
        country_44: `Közép-Afrikai Köztársaság`,
        country_45: `Csád`,
        country_46: `Chile`,
        country_47: `Kína`,
        country_48: `Karácsony-sziget`,
        country_49: `Kókusz (Keeling)-szigetek`,
        country_50: `Colombia`,
        country_51: `Cook-szigetek`,
        country_52: `Costa Rica`,
        country_53: `"Elefántcsontpart"`,
        country_54: `Horvátország`,
        country_55: `Kuba`,
        country_56: `Ciprus`,
        country_57: `Cseh Köztársaság`,
        country_58: `Kongói Demokratikus Köztársaság`,
        country_59: `Dánia`,
        country_60: `Dzsibuti`,
        country_61: `Dominika`,
        country_62: `Dominikai Köztársaság`,
        country_63: `Ecuador`,
        country_64: `Egyiptom`,
        country_65: `El Salvador`,
        country_66: `Egyenlítői-Guinea`,
        country_67: `Eritrea`,
        country_68: `Észtország`,
        country_69: `Etiópia`,
        country_70: `Falkland-szigetek`,
        country_71: `Faroe Szigetek`,
        country_72: `Mikronéziai Szövetségi Államok`,
        country_73: `Fidzsi-szigetek`,
        country_74: `Finnország`,
        country_75: `Franciaország`,
        country_76: `Francia Guyana`,
        country_77: `Francia Polinézia`,
        country_78: `Francia déli területek`,
        country_79: `Gabon`,
        country_80: `Gambia`,
        country_81: `Grúzia`,
        country_82: `Németország`,
        country_83: `Ghána`,
        country_84: `Gibraltár`,
        country_85: `Nagy-Britannia (Egyesült Királyság; Anglia)`,
        country_86: `Görögország`,
        country_87: `Grönland`,
        country_88: `Grenada`,
        country_89: `Guadeloupe`,
        country_90: `Guam`,
        country_91: `Guatemala`,
        country_92: `Guernsey`,
        country_93: `Guinea`,
        country_94: `Bissau-Guinea`,
        country_95: `Guyana`,
        country_96: `Haiti`,
        country_97: `Heard-sziget és McDonald-szigetek`,
        country_98: `Honduras`,
        country_99: `Hong Kong`,
        country_100: `Magyarország`,
        country_101: `Izland`,
        country_102: `India`,
        country_103: `Indonézia`,
        country_104: `Irán`,
        country_105: `Irak`,
        country_106: `Írország`,
        country_107: `Man-sziget`,
        country_108: `Izrael`,
        country_109: `Olaszország`,
        country_110: `Jamaica`,
        country_111: `Japán`,
        country_112: `Jersey`,
        country_113: `Jordánia`,
        country_114: `Kazahsztán`,
        country_115: `Kenya`,
        country_116: `Kiribati`,
        country_117: `Kuvait`,
        country_118: `Kirgizisztán`,
        country_119: `Laosz`,
        country_120: `Lettország`,
        country_121: `Libanon`,
        country_122: `Lesotho`,
        country_123: `Libéria`,
        country_124: `Líbia`,
        country_125: `Liechtenstein`,
        country_126: `Litvánia`,
        country_127: `Luxemburg`,
        country_128: `Makaó`,
        country_129: `Madagaszkár`,
        country_130: `Malawi`,
        country_131: `Malaysia`,
        country_132: `Maldív-szigetek`,
        country_133: `Mali`,
        country_134: `Málta`,
        country_135: `Marshall-szigetek`,
        country_136: `Martinique`,
        country_137: `Mauritánia`,
        country_138: `Mauritius`,
        country_139: `Mayotte`,
        country_140: `Mexikó`,
        country_141: `Moldova`,
        country_142: `Monaco`,
        country_143: `Mongólia`,
        country_144: `Montenegró`,
        country_145: `Montserrat`,
        country_146: `Marokkó`,
        country_147: `Mozambik`,
        country_148: `Mianmar (Burma)`,
        country_149: `Namíbia`,
        country_150: `Nauru`,
        country_151: `Nepál`,
        country_152: `Hollandia`,
        country_153: `Új-Kaledónia`,
        country_154: `Új Zéland`,
        country_155: `Nicaragua`,
        country_156: `Niger`,
        country_157: `Nigéria`,
        country_158: `Niue`,
        country_159: `Norfolk-sziget`,
        country_160: `Észak Kórea`,
        country_161: `Észak Mariana szigetek`,
        country_162: `Norvégia`,
        country_163: `Omán`,
        country_164: `Pakisztán`,
        country_165: `Palau`,
        country_166: `palesztin területek`,
        country_167: `Panama`,
        country_168: `Pápua Új-Guinea`,
        country_169: `Paraguay`,
        country_170: `Peru`,
        country_171: `Pitcairn-szigetek`,
        country_172: `Lengyelország`,
        country_173: `Portugália`,
        country_174: `Puerto Rico`,
        country_175: `Katar`,
        country_176: `Macedónia Köztársaság (FYROM)`,
        country_177: `Kongói Köztársaság`,
        country_178: `Réunion`,
        country_179: `Románia`,
        country_180: `Orosz Föderáció`,
        country_181: `Ruanda`,
        country_182: `Szent Barthélemy`,
        country_183: `Saint Martin (Franciaország)`,
        country_184: `Saint-Pierre és Miquelon`,
        country_185: `Szamoa`,
        country_186: `San Marino`,
        country_187: `Sao Tome és Principe`,
        country_188: `Szaud-Arábia`,
        country_189: `Szenegál`,
        country_190: `Szerbia`,
        country_191: `Seychelle-szigetek`,
        country_192: `Sierra Leone`,
        country_193: `Szingapúr`,
        country_194: `Szlovákia`,
        country_195: `Szlovénia`,
        country_196: `Salamon-szigetek`,
        country_197: `Szomália`,
        country_198: `Dél-Afrika`,
        country_199: `Dél-Georgia és a South Sandwich-sziget`,
        country_200: `Dél-Korea`,
        country_201: `Dél Szudán`,
        country_202: `Spanyolország`,
        country_203: `Sri Lanka`,
        country_204: `St. Helena & Dependencies`,
        country_205: `St. Kitts & Nevis`,
        country_206: `Szent Lucia`,
        country_207: `St. Vincent és a Grenadine-szigetek`,
        country_208: `Szudán`,
        country_209: `Suriname`,
        country_210: `Szváziföld`,
        country_211: `Svédország`,
        country_212: `Svájc`,
        country_213: `Szíria`,
        country_214: `Tajvan`,
        country_215: `Tádzsikisztán`,
        country_216: `Tanzánia`,
        country_217: `Sablon: Országadatok SJM Svalbard`,
        country_218: `Thaiföld`,
        country_219: `A Bahamák`,
        country_220: `A Comore-szigetek`,
        country_221: `Fülöp-szigetek`,
        country_222: `Kelet-Timor (Kelet-Timor)`,
        country_223: `Menni`,
        country_224: `Tokelau`,
        country_225: `Tonga`,
        country_226: `Trinidad és Tobago`,
        country_227: `Tunézia`,
        country_228: `pulyka`,
        country_229: `Türkmenisztán`,
        country_230: `Turks és Caicos-szigetek`,
        country_231: `Tuvalu`,
        country_232: `Uganda`,
        country_233: `Ukrajna`,
        country_234: `Egyesült Arab Emírségek`,
        country_235: `Az Egyesült Államok külső szigetei`,
        country_236: `Amerikai Egyesült Államok (USA)`,
        country_237: `Egyesült Államok Virgin-szigetek`,
        country_238: `Uruguay`,
        country_239: `Üzbegisztán`,
        country_240: `Vanuatu`,
        country_241: `Vatikánváros (a Szentszék)`,
        country_242: `Venezuela`,
        country_243: `Vietnam`,
        country_244: `Wallis és Futuna`,
        country_245: `nyugat-Szahara`,
        country_246: `Jemen`,
        country_247: `Zambia`,
        country_248: `Zimbabwe`,
        google_login: `Jelentkezzen be a Google-lal`,
        State: `Állapot`,
        Activation_code: `Aktiváló kód`,
        Question: `Sorolja fel az összes alkalmazást, amellyel bejelentkezett`,
        Copy_complete: `Másolás kész`,
        change_password_success: `A jelszó sikeresen megváltozott`,
        successful_login_title: `Sikeres bejelentkezés`,
        product_page: `Termékoldal >>`,
        successful_login_info: `A bejelentkezés befejeződött. Kérjük, zárja be az aktuális oldalt, és lépjen vissza az eredeti lapra a következő folyamat folytatásához. Az aktuális oldal 5 másodpercen belül automatikusan bezárul. Ha az automatikus bezárás vagy bezárás a „Kész” gombra kattintva nem sikerül, kérjük, zárja be közvetlenül ezt a lapot.`,
        successful_login_info_firefox: `A bejelentkezés befejeződött. Kérjük, zárja be az aktuális oldalt, és lépjen vissza az eredeti lapra a következő folyamat folytatásához.`,
        my_account: `Fiókom`,
        my_history: `Az én történetem`,
        remove_watermark: `Távolítsa el a vízjelet`,
        no_history: `Nincs előzmény`,
        history_all: `Mindet kiválaszt`,
        history_open: `Nyisd ki`,
        history_down: `Letöltés`,
        history_delete: `Töröl`,
        history_clear: `Törölje az érvénytelen`,
        images: `kép(ek)`,
        use_this_function: `Használja ezt a funkciót>>`,
        hd_downloading: `Az eredeti HD képek letöltésének előnyei:`,
        lifetimeRemaining: `Élettartam hátralévő`,
        Remaining: `Többi`,
        email_verification: `Email megerősítés`,
        email_verification_info: `Elküldtük az ellenőrző üzenetet az Ön e-mail-címére: <span class="email"></span>, és kérjük, fejezze be az ellenőrzést. Az ellenőrzés után az előnyök automatikusan szinkronban lesznek.`,
        wrong_email: `Rossz email cím?`,
        click_here_to_modify: `Kattintson ide a módosításhoz`,
        get_more_help: `További segítséget kér?`,
        click_here: `Kattints ide`,
        email_verification_info_success: `Gratulálunk e-mail fiókjának igazolásához.`,
        email_verification_info_error: `Az ellenőrzés meghiúsult a lejárt link miatt.`,
        registration_succeeded: `Sikerült a regisztráció`,
        registration_succeeded_info_1: `Gratulálunk! Sikeresen regisztráltál. Elküldtük az ellenőrző üzenetet az Ön e-mail-címére: <span class="email"></span>, és kérjük, fejezze be az ellenőrzést, hogy kihasználhassa ennek az e-mailnek az előnyeit.`,
        registration_succeeded_info_2: `Kattintson a „Kész” gombra, hogy visszatérjen a Kezdőlapra, és használja ezt a terméket.`,
        registration_succeeded_info_3: `Kattintson a „Kész” gombra az aktuális oldal bezárásához, és a kezdőlapra való visszatéréshez a következő műveletekhez. Ha a bezárás sikertelen, zárja be manuálisan a lapot.`,
        verify_email: `E-mail megerősítés`,
        registered_email_not_verified: `A regisztrált e-mail-címet nem ellenőriztük, kérjük, azonnal ellenőrizze.`,
        email_verification_time_1: `Nem kapott ellenőrző e-mailt?`,
        email_verification_time_2: `Utána`,
        email_verification_time_3: `Kattintson ide az újraküldéshez`,
        error_26301: `Hibakód: 26301, kérjük lépjen kapcsolatba velünk`,
        error_26302: `Hibakód: 26302, kérjük lépjen kapcsolatba velünk`,
        error_26303: `E-mail formátum hiba (hibakód: 26303). Kérjük, írja be újra`,
        error_26304: `8 karakternél hosszabb jelszó ajánlott (hibakód: 26304)`,
        error_26305: `A visszaküldés sikertelen (hibakód: 26305). Kérjük, próbálja meg újra`,
        error_26306: `Az e-mail-cím regisztrálva, kérjük, <a href="https://account.aisesoft.com/login">lépjen be a bejelentkezéshez</a>`,
        error_26307: `A visszaküldés sikertelen (hibakód: 26307). Kérjük, próbálja újra`,
        error_26308: `A visszaküldés sikertelen (hibakód: 26308). Kérjük, próbálja meg újra`,
        error_26401: `Hibakód: 26401, kérjük, próbálja újra`,
        error_26402: `Az e-mail-cím ellenőrzése megtörtént (hibakód: 26402), kérjük, próbálja újra`,
        error_26403: `A visszaküldés sikertelen (hibakód: 26403). Kérjük, próbálja újra`,
        error_26404: `A kérés sikertelen (hibakód: 26404). Kérjük, próbálja meg újra`,
        error_26501: `Hibakód: 26501, kérjük lépjen kapcsolatba velünk`,
        error_26502: `Hibakód: 26502, kérjük lépjen kapcsolatba velünk`,
        error_26503: `E-mail formátum hiba (hibakód: 26503). Kérjük, írja be újra`,
        error_26504: `A visszaküldés sikertelen (hibakód: 26504). Kérjük, próbálja meg újra`,
        error_26505: `Az e-mail-cím nincs regisztrálva, kérjük, <a href="https://account.aisesoft.com/register">először regisztrálja</a>`,
        error_26506: `Az e-mail-cím ellenőrzése megtörtént.`,
        error_26507: `A visszaküldés sikertelen (hibakód: 26507). Kérjük, próbálja meg újra`,
        error_26508: `Az ellenőrzés megtörtént (hibakód: 26508), kérjük, próbálja meg újra`,
        error_26509: `A kérés sikertelen (hibakód: 26509), kérjük, próbálja újra`,
        error_26510: `Hibakód: 26510, kérjük lépjen kapcsolatba velünk`,
        error_26601: `Hibakód: 26601, kérjük lépjen kapcsolatba velünk`,
        error_26602: `Hibakód: 26602, kérjük lépjen kapcsolatba velünk`,
        error_26603: `A visszaküldés sikertelen (hibakód: 26603). Kérjük, próbálja meg újra`,
        error_26604: `Hibakód: 26604, kérjük lépjen kapcsolatba velünk`,
        error_26605: `Hibakód: 26605, kérjük lépjen kapcsolatba velünk`,
        error_26701: `Hibakód: 26701, kérjük lépjen kapcsolatba velünk`,
        error_26702: `A visszaküldés sikertelen (hibakód: 26702). Kérjük, próbálja meg újra`,
        error_26703: `Hibakód: 26703, kérjük lépjen kapcsolatba velünk`,
        error_26704: `Hibakód: 26704, kérjük lépjen kapcsolatba velünk`,
        error_26705: `Várjon a bejelentkezésre (hibakód: 26705). Kérjük, próbálja újra`,
        no_cookie: `Böngészőjében bekapcsolta az Összes cookie letiltása funkciót, így nem tud bejelentkezni. Kérjük, lépjen a Beállításokba, és jelölje be az Összes cookie engedélyezése négyzetet.`,
        error_26801: `Hibakód: 26801, kérjük lépjen kapcsolatba velünk`,
        error_26802: `Hibakód: 26802, kérjük lépjen kapcsolatba velünk`,
        error_26803: `A kérés sikertelen (hibakód: 26803). Kérjük, próbálja újra`,
        error_26804: `A kérés sikertelen (hibakód: 26804). Kérjük, próbálja újra`,
        error_order: `A kérés sikertelen (hibakód: 27098), kérjük, próbálja újra!`,
        error_order1: `A rendelés lekérdezése hiányos (hibakód:`,
        error_order2: `）, kérjük, frissítse, és próbálja újra.`,
        modify_email_title: `E-mail módosítása`,
        modify_email_info: `A módosított e-mail címmel bejelentkezhet fiókjába.`,
        images_per: `Képek per`,
        error_26101: `Hiba: 26101. Kérjük, lépjen kapcsolatba velünk`,
        error_26102: `Hiba: 26102. Kérjük, lépjen kapcsolatba velünk`,
        error_26103: `A kérés sikertelen (hibakód: 26103). Kérjük, próbálja újra`,
        error_26104: `Hibakód: 26104, kérjük, próbálja újra`,
        error_26105: `Hibakód: 26105, kérjük, próbálja újra`,
        error_26106: `A törlés sikertelen (hibakód: 26106). Kérjük, próbálja meg újra`,
        error_26201: `Hiba: 26201. Kérjük, lépjen kapcsolatba velünk`,
        error_26202: `A kérés sikertelen (hibakód: 26202). Kérjük, próbálja újra`,
        error_26001: `Hiba: 26001. Kérjük, lépjen kapcsolatba velünk`,
        error_26002: `Hiba: 26002. Kérjük, lépjen kapcsolatba velünk`,
        error_26003: `Hiba: 26003. Kérjük, lépjen kapcsolatba velünk`,
        error_26004: `Hiba: 26004. Kérjük, lépjen kapcsolatba velünk`,
        error_26005: `A kérés sikertelen (hibakód: 26005). Kérjük, próbálja újra`,
        error_26006: `Hibakód: 26006, kérjük, próbálja újra`,
        error_26008: `Hiba: 26008. Kérjük, lépjen kapcsolatba velünk`,
        go_to_the_home_page: `Menjen a Kezdőlapra`,
        error_27101: `A kérés sikertelen (hibakód: 27101). Kérjük, próbálja újra`,
        error_27201: `Hibakód: 27201, kérjük lépjen kapcsolatba velünk`,
        error_27202: `Hibakód: 27202, kérjük, próbálja újra`,
        error_27203: `A kérés sikertelen (hibakód: 27203). Kérjük, próbálja újra`,
        error_27204: `Érvénytelen kód (hibakód: 27204).`,
        error_27205: `A kérés sikertelen (hibakód: 27205). Kérjük, próbálja újra`,
        error_27206: `A kérés sikertelen (hibakód: 27206). Kérjük, próbálja újra`,
        error_27207: `A kérés sikertelen (hibakód: 27207). Kérjük, próbálja újra`,
        no_history_found: `Nem használtál semmilyen eszközt! <a href="/">Frissítés</a> vagy <a href="https://hu.tipard.com/">Ugrás a hivatalos webhelyre</a>`,
        error_25301: `Hiba: 25301. Kérjük, lépjen kapcsolatba velünk`,
        error_25302: `Hiba: 25302. Kérjük, lépjen kapcsolatba velünk`,
        error_25303: `A kérés sikertelen (hibakód: 25303). Kérjük, próbálja meg újra`,
        error_25304: `A kérés sikertelen (hibakód: 25304). Kérjük, próbálja meg újra`,
        error_25305: `A kérés sikertelen (hibakód: 25305). Kérjük, próbálja meg újra`,
        error_25306: `A kérés sikertelen (hibakód: 25306). Kérjük, próbálja meg újra`,
        image_upscaler_p: `Kép vízjel letöltés nélkül:`,
        Available_for: `Available for:`,
        credit_per: `%s credit(s) per HD image`,
        still_valid: `Purchased plan(s) is still valid`,
        credit: `credit(s)`,
        pc_3rd_info: `Login successfully. Please go to the application for further operation.`,
        use_online: `Use the online service`,
        use_download: `Use the desktop program`,
        use_immediately: `Use Immediately`,
        Use_in_browser: `Use in Browser`,
        win_desktop: `Windows`,
        Mac_desktop: `Mac`,
        credits_per: `{%} Credits per Month`,
        expire: `Expiration Time:`,
        viewDetails: `View details`,
        viewHistory: `View history benefits>>`,
        viewDetailsInfo: `Notice: If the subscription benefits are renewed within 7 days after expiration, the unused benefits can continue to be used. Also, the expiration time will be automatically updated to the expiration time of the new subscription. If there is no new subscription after 7 days of expiration time, all expired benefits will be cleared.`,
        "connect_account": "Kösd az e-mailt a fiókodhoz",
        "connect_account_info": "Kötés után ezen az email címen tudsz bejelentkezni.",
        "connect_now": "Bind Now",
        "no_email_bind": "Nincs e-mail kötés",
        "bind_email": "Bind E-mail",
        "connect_your_email_placeholder": "Kérjük, adja meg e-mail címét",
        "bind_an_email": "Kötsön egy e-mailt",
        "bind_info": "Sikeresen bejelentkeztél. Az előnyök aktiválásához kössön egy e-mailt a fiókjához.",
        "bind_later": "Kötés később",
        "footer": "Copyright © 2024 Tipard Studio. Minden jog fenntartva.",
        "overtime": "Hibakód: {%}, kérjük, próbálja újra",
        "hi": "Szia %s!",
        "Personal_Information": "Személyes adatok",
        "Access": "Hozzáférés",
        "Subscription_Plan": "(Előfizetési csomag)",
        "No_orders": "Nem található megrendelés.",
        "No_data": "Nincs adat",
        "Featured_Products": "Kiemelt termékek",
        "More_Products": "További termékek",
        "Free_Download": "Ingyenes letöltés",
        "Get_Started": "Kezdje el",
        "Subscribe": "Iratkozz fel",
        "Verified": "Ellenőrzött",
        "back_to_account_center": "Vissza a Fiókközpontba",
        "success": "Siker!",
        "successfully": "Sikeresen regisztrált egy fiókot.",
        "Continue": "Folytatás",
        "Already": "Már van fiókja?",
        "loading_verification": "Ellenőrzés állapotának ellenőrzése...",
        "email_no_verification": "Sajnáljuk, a regisztrált e-mail címet nem ellenőriztük. Kérjük, fejezze be az ellenőrzést a fenti utasítások szerint, majd kattintson ismét a \"Kész\" gombra a regisztráció befejezéséhez.",
        "error_20505": "Az e-mail linkelve",
        "error_20506": "Hiba: 20506. Kérjük, lépjen kapcsolatba velünk",
        "error_28401": "Kérjük, adja meg e-mail címét",
        "error_28402": "Helytelen email formátum",
        "error_28403": "Hiba: 28403. Kérjük, lépjen kapcsolatba velünk",
        "error_28404": "Az email feliratkozás megtörtént",
        "error_28405": "Hiba: 28405. Kérjük, lépjen kapcsolatba velünk",
        "error_28406": "Az e-mail linkelve",
        "error_28407": "Hiba: 28407. Kérjük, lépjen kapcsolatba velünk",
        "error_28408": "Nem sikerült elküldeni az e-mailt",
        "error_28601": "Kérjük, adja meg e-mail címét",
        "error_28602": "Kérjük, adja meg az ellenőrző kódot",
        "error_28603": "Helytelen email formátum",
        "error_28604": "Hiba: 28604. Kérjük, lépjen kapcsolatba velünk",
        "error_28605": "Az ellenőrző kód helytelen",
        "error_28606": "Hiba: 28606. Kérjük, lépjen kapcsolatba velünk",
        "error_28607": "Az email feliratkozás megtörtént",
        "error_28608": "Nincs fiókjához kötött e-mail",
        "error_28609": "Az e-mail nincs ellenőrizve",
        "error_28610": "Hiba: 28610. Kérjük, lépjen kapcsolatba velünk",
        "error_28611": "Az e-mail linkelve",
        "error_28612": "Hiba: 28612. Kérjük, lépjen kapcsolatba velünk",
        "unbind_email_btn": "Az e-mail leválasztása",
        "binding_exchange": "Bind E-mail",
        "Subscription_Status": "Előfizetés állapota",
        "mask_unbind_info_1": "Biztosan leválasztja a(z) {%} szolgáltatást erről a fiókról? A leválasztás után nem tudja többé ellenőrizni ennek az e-mailnek az előnyeit",
        "mask_unbind_info_2": "Biztosan feloldja a regisztrációs kóddal társított összes számítógépet?<a href=\"https://hu.tipard.com/faq.html#unbind\">További információ az eszközök cseréjéről.</a>",
        "error_dc_link": "Hiba, kérjük lépjen kapcsolatba velünk",
        "vcu_state": "A teljes verzió előnyei:",
        "monthly_subscription": "1 hónapos licenc",
        "year_subscription": "1 éves licenc",
        "to": "hogy",
        "lifetime_subscription": "Élettartamra szóló licenc",
        "unbind": "Erősítse meg",
        "unbind_success": "Sikeresen feloldva",
        "error_28401": "Kérjük, adja meg e-mail címét",
        "error_28702": "Hiba: 28702. Kérjük, lépjen kapcsolatba velünk",
        "error_28703": "Az ellenőrző kód helytelen",
        "error_28704": "Hiba: 28704. Kérjük, lépjen kapcsolatba velünk",
        "error_28705": "Az e-mail nincs linkelve",
        "error_28706": "Kérjük, adja meg az ellenőrző kódot",
        "error_28707": "Nem sikerült leválasztani",
        "error_28901": "Hiba: 28901. Kérjük, lépjen kapcsolatba velünk",
        "error_28902": "Hiba: 28902. Kérjük, lépjen kapcsolatba velünk",
        "error_28903": "Hiba: 28903. Kérjük, lépjen kapcsolatba velünk",
        "error_28904": "Hiba: 28904. Kérjük, lépjen kapcsolatba velünk",
        "error_28905": "Hiba: 28905. Kérjük, lépjen kapcsolatba velünk",
        "error_28906": "Hiba: 28906. Kérjük, lépjen kapcsolatba velünk",
        "error_28907": "Érvénytelen regisztrációs kód",
        "error_28908": "Hiba: 28908. Kérjük, lépjen kapcsolatba velünk",
        "error_28909": "Jelenleg a kötetlen lehűlési időszakban. Havonta csak egyszer lehet feloldani.",
        "error_289010": "Hiba: 289010. Kérjük, lépjen kapcsolatba velünk",
        "error_289011": "Hiba: 289011. Kérjük, lépjen kapcsolatba velünk",
        "error_289012": "Hiba: 289012. Kérjük, lépjen kapcsolatba velünk",
        "activated_computer": "Aktiválva %s/%s számítógépen",
        "bind_email_info": "A következő e-mailek előnyeit ez a VIP fiók köti és használja fel.",
        "error_26309": "Hiba: 26309. Kérjük, lépjen kapcsolatba velünk",
        "error_26310": "Érvénytelen hivatkozási kód",
        "error_26311": "Hiba: 26311. Kérjük, lépjen kapcsolatba velünk",
        "error_26312": "Érvénytelen hivatkozási kód",
        "error_26313": "Hiba: 26313. Kérjük, lépjen kapcsolatba velünk",
        "error_26314": "Az e-mail linkelve",
        "activate_unbinding": "Eszköz módosítása",
        "copy_registration_code": "Regisztrációs kód másolása",
        "show_registration_code": "Regisztrációs kód megjelenítése",
        "hide_registration_code": "Regisztrációs kód elrejtése",
        "other_binding_email": "Egyéb kötelező e-mail(ek)",
        "home": "Otthon",
        "points_balance": "Pontegyenleg:",
        "earn_now": "Keressen most >>",
        "earn_more": "Szerezzen több INGYENES pontot, váltson be több ajándékot",
        "earn_or_redeem_points": "Szerezzen/beváltsa pontokat",
        "unbind_email_info": "Az aktuális fiók egy e-mail címhez lett társítva",
        "bind_email_title": "Ha több rendelést szeretne társítani e-mail címek vásárlásához, kattintson az alábbi \"E-mail társítás\" gombra a társításhoz.",
        "bind_email_tip": "<span>Tipp:</span> Jelenleg csak az e-mailben történő regisztrációs kód vásárlása támogatott.",
        "email_association": "E-mail Egyesület",
        "dissociation": "Disszociáció",
        "back": "Vissza",
        "activate_benefits": "Kérjük, adja meg a vásárlás e-mail címét, amelyet társítani kell az ellenőrző kód megszerzéséhez. Írja be az e-mailben kapott ellenőrző kódot az ellenőrző kód beviteli mezőbe, majd kattintson az \"Aktiválás ellenőrzése\" gombra az előnyök aktiválásához.",
        "e_mail": "Email:",
        "verification_code": "Ellenőrző kód:",
        "obtain_verification_code": "Szerezze be az ellenőrző kódot",
        "verification_association": "Társítás igazolása",
        "current_password": "Jelenlegi jelszó:",
        "account_center": "Fiókközpont",
        "new_password": "Új jelszó:",
        "confirm_password": "Új jelszó megerősítése:",
        "Send": "Elküld",
        "history_files": "Előzmények fájlok",
        "delete_account_info_1": "1. A Tipard-fiók törlése végleges művelet, és nem vonható vissza. Minden adata, beleértve a fényképeket és a beállításokat, elvész. Biztosan törli Tipard-fiókját?",
        "delete_account_info_2": "2. Ha úgy dönt, hogy törli a Tipard fiókot, kattintson a Küldés gombra, és írja be az általunk küldött e-mailben kapott ellenőrző kódot.",
        "best_deals": "Legjobb ajánlatok",
        "close": "Közeli",
        "product": "Termék:"
    },
    it: {
        overtime_1: `Codice di errore:`,
        overtime_2: `, per favore riprova`,
        isnetwork: `Errore Internet. Si prega di controllare e riprovare`,
        email_placeholder: `E-mail`,
        email_empty: `Si prega di inserire l'e-mail`,
        email_not_valid: `L'email non è valida`,
        email_not_valid_1: `Per favore inserisci la tua email`,
        email_not_valid_2: `L'email non è valida, utilizza un indirizzo diverso.`,
        email_not_valid_3: `Nessun input e-mail`,
        password_placeholder: `Parola d'ordine`,
        password_empty: `Si prega di inserire la password`,
        password_not_valid: `Account o password errati`,
        password_not_valid_1: `È richiesta una password superiore a 8 caratteri`,
        password_not_valid_2: `Si prega di creare una password`,
        password_placeholder_1: `crea la tua password`,
        password_placeholder_2: `Conferma la tua password`,
        password_placeholder_3: `Crea nuova password`,
        password_placeholder_4: `Conferma la nuova password`,
        password_placeholder_5: `Inserisci la vecchia password`,
        copy_password_empty: `Si prega di confermare la password`,
        copy_password_not_valid: `Si prega di confermare la password`,
        copy_passwords_inconsistent: `La conferma della password non corrisponde`,
        code_empty: `Inserisci il codice di verifica`,
        code_not_valid: `Codice di verifica non valido`,
        code_placeholder: `Codice di verifica`,
        not_received_code: `Se la tua casella di posta non riceve il codice di verifica da molto tempo, richiedi di nuovo il codice di verifica.`,
        get_first_code: `Si prega di ottenere prima il codice di verifica.`,
        last_name_placeholder: `Per favore inserisci il tuo cognome`,
        first_name_placeholder: `Per favore immettere il proprio Nome`,
        address_placeholder: `Per favore inserisci il tuo indirizzo`,
        no_code_text: `Abbiamo inviato un codice di verifica. Inserisci il tuo codice. <span class='tips'>Non hai ricevuto il codice?`,
        no_code_text_1: `1. Assicurati che l'indirizzo e-mail sia valido e che possa ricevere e-mail.`,
        no_code_text_2: `2.Se l'e-mail viene inviata automaticamente dal sistema, potrebbe essere contrassegnata come spam o posta indesiderata. Si prega di verificare se l'e-mail si trova nella cartella Cestino.`,
        no_code_text_3: `3.Non riesci a risolvere il tuo problema?`,
        no_code_text_3_span: `Allora clicca qui per contattarci.`,
        order_no: `Non hai acquistato alcun prodotto. Per qualsiasi domanda, <a href="https://it.tipard.com/store.html">Acquista ora</a> oppure <a href="https://it.tipard.com/contact.html"> Contattaci</a>`,
        error_24901: `L'account corrente non ha un indirizzo email collegato e non riesce a trovare gli ordini. Si prega di collegare un'e-mail.`,
        user_guide: `Guida per l'utente>>`,
        download: `Scarica`,
        order_number: `Numero d'ordine`,
        Refund: `Rimborso`,
        Disabled: `Disabilitato`,
        Normal: `Normale`,
        Modify: `Modificare`,
        Modify_1: `Modifica>>`,
        Connect: `Collegare`,
        unlink_success: `Scollega con successo`,
        connect_success: `Connetti con successo`,
        feedback_title: `Grazie per il tuo feedback. Per favore lascia il tuo problema e ti risponderemo entro 24 ore.`,
        feedback_thank_you: `Grazie!<br />Il tuo feedback è stato inviato con successo.`,
        feedback_email: `Inserisci la tua email qua!`,
        feedback_content: `Lascia qui qualsiasi problema o suggerimento che hai riscontrato.`,
        feedback_submit: `Invia`,
        form_contents: `Non hai inserito alcuna descrizione. Inseriscilo e invia di nuovo.`,
        old_password: `Si prega di inserire la vecchia password`,
        new_password: `Si prega di creare una nuova password`,
        old_new_password: `La nuova password non può essere uguale a quella vecchia`,
        incorrect_password: `password errata`,
        delete_no: `Cancella ora`,
        Caps: `Blocco maiuscolo attivo`,
        Get: `Ottenere`,
        Done: `Fatto`,
        error_20001: `Errore: 20001. Effettua nuovamente l'accesso.`,
        error_20002: `Errore: 20002. Effettua nuovamente l'accesso.`,
        error_20003: `Errore: 20003. Effettua nuovamente l'accesso.`,
        error_20004: `Richiesta non riuscita (codice errore: 20004). Si prega di riprovare.`,
        error_20005: `La sessione di accesso è scaduta (errore: 20005). Per favore esegui l'accesso di nuovo.`,
        error_20006: `Richiesta non riuscita (codice errore: 20006). Si prega di riprovare.`,
        error_20007: `La sessione di accesso è scaduta (Errore: 20007). Per favore esegui l'accesso di nuovo.`,
        error_20008: `La sessione di accesso è scaduta (Errore: 20008). Per favore esegui l'accesso di nuovo.`,
        error_20009: `La sessione di accesso è scaduta (Errore: 20009). Per favore esegui l'accesso di nuovo.`,
        error_20101: `Per favore inserisci la tua email (codice errore: 20101)`,
        error_20102: `L'e-mail non è valida (codice errore: 20102)`,
        error_20103: `Richiesta non riuscita (codice errore: 20103). Per favore riprova`,
        error_20104: `L'email è già in uso, per favore <a href="https://account.tipard.com/login">accedi</a> o registrati con una nuova`,
        error_20105: `Richiesta non riuscita (codice errore: 20105). Per favore riprova`,
        error_20106: `Impossibile inviare l'e-mail, riprovare`,
        error_20201: `Inserisci la tua email (codice errore: 20201)`,
        error_20202: `Inserisci la tua password (codice di errore: 20202)`,
        error_20203: `Inserisci il codice di verifica (codice errore: 20203)`,
        error_20204: `Email non valida (codice errore: 20204)`,
        error_20205: `È richiesta una password superiore a 8 caratteri (codice errore: 20205)`,
        error_20206: `Richiesta non riuscita (codice errore: 20206). Per favore riprova`,
        error_20207: `Codice di verifica non valido`,
        error_20208: `Richiesta non riuscita (codice errore: 20208). Per favore riprova`,
        error_20209: `Richiesta non riuscita (codice errore: 20209). Per favore riprova`,
        error_20301: `Per favore inserisci la tua email (codice errore: 20301)`,
        error_20302: `Errore: 20302. Contattaci`,
        error_20303: `Email non valida (codice errore: 20303)`,
        error_20304: `Richiesta non riuscita (codice errore: 20304). Per favore riprova`,
        error_20305: `L'account non esiste. Si prega di reinserire o <a href="https://account.tipard.com/register">Crealo</a> prima.`,
        error_20306: `Nessuna password ancora. Usa <a href="https://account.tipard.com/passwordless-login">Accesso senza password</a> o <a href="create-password">imposta una password</a> e accedi.`,
        error_20308: `Richiesta non riuscita (codice errore: 20308). Per favore riprova`,
        error_20401: `Impossibile disconnettersi (codice errore: 20401). Per favore riprova`,
        error_20501: `Per favore inserisci la tua email (codice errore: 20501)`,
        error_20502: `Email non valida (codice errore: 20502)`,
        error_20503: `Richiesta non riuscita (codice errore: 20503). Per favore riprova`,
        error_20504: `Impossibile inviare l'e-mail. Si prega di riprovare.`,
        error_20601: `Per favore inserisci la tua email (codice errore: 20601)`,
        error_20602: `Inserisci il codice di verifica (codice errore: 20602)`,
        error_20603: `Email non valida (codice errore: 20603)`,
        error_20604: `Richiesta non riuscita (codice errore: 20604). Per favore riprova`,
        error_20606: `Richiesta non riuscita (codice errore: 20606). Per favore riprova`,
        error_20607: `Richiesta non riuscita (codice errore: 20607). Per favore riprova`,
        error_20608: `Richiesta non riuscita (codice errore: 20608). Per favore riprova`,
        error_20701: `Per favore inserisci la tua email (codice errore: 20701)`,
        error_20702: `Email non valida (codice errore: 20702)`,
        error_20703: `Richiesta non riuscita (codice errore: 20703). Per favore riprova`,
        error_20704: `L'account non esiste. Si prega di reinserire o <a href="https://account.tipard.com/register">Crealo</a> prima.`,
        error_20705: `Richiesta non riuscita (codice errore: 20705). Per favore riprova`,
        error_20706: `Impossibile inviare l'e-mail. Per favore riprova`,
        error_20801: `Per favore inserisci la tua email (codice errore: 20801)`,
        error_20802: `Errore: 20802. Contattaci`,
        error_20803: `Inserisci il codice di verifica (codice errore: 20803)`,
        error_20804: `Email non valida (codice errore: 20804)`,
        error_20805: `È richiesta una password superiore a 8 caratteri (codice errore: 20805)`,
        error_20806: `Richiesta non riuscita (codice errore: 20806). Per favore riprova`,
        error_20808: `Richiesta non riuscita (codice errore: 20808). Per favore riprova`,
        error_20901: `Richiesta non riuscita (codice errore: 20901). Per favore riprova`,
        error_20902: `Richiesta non riuscita (codice errore: 20902). Per favore riprova`,
        error_21000: `Le modifiche vengono salvate`,
        error_21001: `Nessuna informazione inviata (codice errore: 21001)`,
        error_21002: `Richiesta non riuscita (codice errore: 21002). Per favore riprova`,
        error_21101: `Per favore inserisci la tua email (codice errore: 21101)`,
        error_21102: `Email non valida (codice errore: 21102)`,
        error_21103: `Richiesta non riuscita (codice errore: 21103), riprovare di nuovo`,
        error_21104: `L'email è già collegata, usane una nuova`,
        error_21105: `Richiesta non riuscita (codice errore: 21105), riprovare di nuovo`,
        error_21106: `Impossibile inviare l'e-mail. Per favore riprova`,
        error_21201: `Per favore inserisci la tua email (codice errore: 21201)`,
        error_21202: `Inserisci il codice di verifica (codice errore: 21202)`,
        error_21203: `Email non valida (codice errore: 21203)`,
        error_21204: `Errore: 21204. Contattaci`,
        error_21205: `Errore: 21205. Contattaci`,
        error_21206: `È richiesta una password superiore a 8 caratteri (codice errore: 21206)`,
        error_21207: `Richiesta non riuscita (codice errore: 21207). Per favore riprova`,
        error_21209: `Richiesta non riuscita (codice errore: 21209). Per favore riprova`,
        error_21301: `Si prega di inserire la vecchia password (codice di errore: 21301)`,
        error_21302: `Si prega di creare una nuova password (codice di errore: 21302)`,
        error_21303: `La nuova password non può essere uguale a quella vecchia. (Errore: 21303)`,
        error_21304: `È richiesta una password superiore a 8 caratteri (codice errore: 21304)`,
        error_21306: `Richiesta non riuscita (codice errore: 21306). Per favore riprova`,
        error_21402: `Richiesta non riuscita (codice errore: 21402). Per favore riprova`,
        error_21403: `Impossibile inviare il codice di verifica. Per favore invialo di nuovo`,
        error_21500: `L'account è stato cancellato`,
        error_21501: `Inserisci il codice di verifica (codice errore: 21501)`,
        error_21502: `La sessione di accesso è scaduta (Errore: 21502). Per favore esegui l'accesso di nuovo.`,
        error_21503: `Richiesta non riuscita (codice errore: 21503). Per favore riprova`,
        error_21505: `Richiesta non riuscita (codice errore: 21505), riprovare`,
        error_21601: `Errore: 20601. Contattaci`,
        error_21602: `Verifica non valida (errore: 20602). Si prega di riprovare.`,
        error_21603: `Errore: 20603. Riprova`,
        error_21604: `Richiesta non riuscita (codice errore: 21604). Per favore riprova`,
        error_21606: `Richiesta non riuscita (codice errore: 21606). Per favore riprova`,
        error_21611: `Richiesta non riuscita (codice errore: 21611). Per favore riprova`,
        error_21801: `Errore: 21801. Contattaci`,
        error_21802: `Richiesta non riuscita (errore: 21802). Per favore riprova`,
        error_21803: `Errore: 21803. Riprova`,
        error_21804: `Richiesta non riuscita (codice errore: 21804). Per favore riprova`,
        error_21806: `Errore: 21806. Riprova`,
        error_21807: `Errore: 21807. Contattaci`,
        error_21808: `Errore: 21808. Contattaci`,
        error_21809: `Errore: 21809. Contattaci`,
        error_21810: `Errore: 21810. Contattaci`,
        error_21811: `Errore: 21811. Contattaci`,
        error_21812: `Errore: 21812. Contattaci`,
        error_21813: `Richiesta non riuscita (codice errore: 21813). Per favore riprova`,
        error_21814: `Errore: 21814. Contattaci`,
        error_21815: `Richiesta non riuscita (codice errore: 21815). Per favore riprova`,
        error_21816: `Errore: 21816. Contattaci`,
        error_21817: `Errore: 21817. Contattaci`,
        error_21818: `Errore: 21818. Contattaci`,
        error_21819: `Richiesta non riuscita (codice errore: 21819). Per favore riprova`,
        error_21820: `Errore: 21820. Contattaci`,
        error_21821: `Errore: 21821. Contattaci`,
        error_21822: `Errore: 21822. Contattaci`,
        error_21823: `Richiesta non riuscita (codice errore: 21823). Per favore riprova`,
        error_21824: `Richiesta non riuscita (codice errore: 21824). Per favore riprova`,
        error_21825: `Richiesta non riuscita (codice errore: 21825). Per favore riprova`,
        error_21826: `Richiesta non riuscita (codice errore: 21826). Per favore riprova`,
        error_21901: `Errore: 21901. Contattaci`,
        error_21902: `Richiesta non riuscita (codice errore: 21902). Per favore riprova`,
        error_21903: `Lo stato dell'account è cambiato (codice errore: 21903), aggiorna la pagina e riprova`,
        error_21904: `Errore: 21904. Riprova`,
        error_21905: `Errore: 21905. Riprova`,
        error_21906: `Richiesta non riuscita (codice errore: 21906). Per favore riprova`,
        error_21907: `L'account Google è stato collegato ad un altro account`,
        error_21908: `Richiesta non riuscita (codice errore: 21908). Per favore riprova`,
        error_22001: `Richiesta non riuscita (codice errore: 22001). Per favore riprova`,
        error_22002: `Scollegamento non riuscito senza accesso aggiuntivo`,
        error_22003: `Richiesta non riuscita (codice errore: 22003). Per favore riprova`,
        error_22101: `Errore: 22101. Contattaci`,
        error_22102: `Lo stato dell'account è cambiato (codice errore: 22102), aggiorna la pagina e riprova`,
        error_22103: `Richiesta non riuscita (codice errore: 22103). Per favore riprova`,
        error_22104: `Lo stato dell'account è cambiato (codice errore: 22104), aggiorna la pagina e riprova`,
        error_22105: `Errore: 22105. Riprova`,
        error_22106: `Errore: 22106. Riprova`,
        error_22107: `Errore: 22107. Contattaci`,
        error_22108: `Richiesta non riuscita (codice errore: 22108). Per favore riprova`,
        error_22201: `Errore: 22201. Contattaci`,
        error_22202: `Verifica non valida (errore: 22202). Si prega di riprovare.`,
        error_22203: `Errore: 22203. Riprova"`,
        error_22204: `Richiesta non riuscita (codice errore: 22204). Per favore riprova`,
        error_22206: `Richiesta non riuscita (codice errore: 22206). Per favore riprova`,
        error_22401: `Errore: 22401. Contattaci`,
        error_22402: `Verifica non valida (errore: 22402). Si prega di riprovare.`,
        error_22403: `Errore: 22403. Riprova`,
        error_22404: `Richiesta non riuscita (codice errore: 22404). Per favore riprova`,
        error_22405: `L'account Facebook è stato collegato ad altre email`,
        error_22406: `Errore: 22406. Riprova`,
        error_22407: `Errore: 22407. Contattaci`,
        error_22408: `Errore: 22408. Contattaci`,
        error_22409: `Errore: 22409. Contattaci`,
        error_22410: `Errore: 224010. Contattaci`,
        error_22411: `Errore: 224011. Contattaci`,
        error_22412: `Errore: 224012. Contattaci`,
        error_22413: `Errore: 22413. Contattaci`,
        error_22414: `Richiesta non riuscita (codice errore: 22414). Per favore riprova`,
        error_22415: `Errore: 22415. Contattaci`,
        error_22416: `Errore: 22416. Contattaci`,
        error_22417: `Errore: 22417. Contattaci`,
        error_22418: `Richiesta non riuscita (codice errore: 22418). Per favore riprova`,
        error_22419: `Errore: 22419. Contattaci`,
        error_22420: `Errore: 22420. Contattaci`,
        error_22421: `Errore: 22421. Contattaci`,
        error_22422: `Richiesta non riuscita (codice errore: 22422). Per favore riprova`,
        error_22423: `Richiesta non riuscita (codice errore: 22423). Per favore riprova`,
        error_22424: `Richiesta non riuscita (codice errore: 22424). Per favore riprova`,
        error_22425: `Richiesta non riuscita (codice errore: 22425). Per favore riprova`,
        error_20098: `Codice di errore: 20098. Se stai navigando in modalità Privata, passa alla modalità Normale e riprova.`,
        error_22298: `Richiesta per Google non riuscita (errore: 22298). Si prega di riprovare.`,
        error_22498: `Richiesta per Facebook non riuscita (Errore: 22498). Si prega di riprovare.`,
        error_24902: `Richiesta non riuscita (codice errore: 24902). Per favore riprova`,
        error_24903: `Richiesta non riuscita (codice errore: 24903). Per favore riprova`,
        error_24904: `Richiesta non riuscita (codice errore: 24904). Per favore riprova`,
        error_24905: `Richiesta non riuscita (codice errore: 24905). Per favore riprova`,
        login_title: `Accedi ad Tipard`,
        password_placeholder: `Parola d'ordine`,
        log_in: `Accesso`,
        no_account: `Nessun conto?`,
        create_it: `Crealo`,
        or_log_in_with: `Oppure accedi con`,
        passwordless_login: `Accesso senza password`,
        log_in_done: `Accedi Fatto`,
        three_rd_account_connect_info: `Congratulazioni! Hai effettuato l'accesso con successo. Ora puoi connettere un account e-mail che verrà utilizzato per accedere in futuro.`,
        see_my_account: `Vedi il mio account`,
        three_rd_login_merge_account: `L'indirizzo email dell'account di terze parti è stato registrato, vuoi connetterti e accedere direttamente con questo indirizzo email?`,
        connect_log_in: `Connettiti e accedi`,
        create_an_account: `Creare un account`,
        back_to_log_in: `Torna al login`,
        create_password: `Crea password`,
        create_now: `Crea ora`,
        password_login_subtitle: `Accesso senza password con e-mail`,
        account_login: `Account login`,
        rights: `Creando questo account, accetti i <a href="https://it.tipard.com/terms.html">Termini di servizio</a> e <a href="https://it.tipard.com/policy.html">Informativa sulla privacy</a>`,
        passwordless_login_done: `Accesso senza password effettuato`,
        passwordless_login_info: `Congratulazioni, hai completato con successo l'accesso senza password. Puoi creare una password per questo account e accedere con l'account e la password in futuro. <a href="/create-password" style="display: initial;">Crea ora</a>`,
        sign_up: `Iscriviti`,
        register_info: `crea il tuo account`,
        reset_now: `Ripristina ora`,
        forgot_password: `Ha dimenticato la password`,
        reset_password_subtitle: `Usa l'email del tuo account per reimpostare la password`,
        plan_products: `Piano e prodotti`,
        nick_name: `Nome utente`,
        email: `E-mail`,
        my_products: `I miei prodotti`,
        my_orders: `I miei ordini`,
        unlink: `Scollega`,
        link: `Collegamento`,
        connected_accounts: `Account collegati`,
        last_name: `Cognome`,
        first_name: `nome di battesimo`,
        Gender: `Genere`,
        Birth: `Nascita`,
        Month: `Mese`,
        Year: `Anno`,
        Country_Region: `Paese/regione`,
        Address: `Indirizzo`,
        Save: `Salva`,
        Date: `Data`,
        Male: `Maschio`,
        Female: `Femmina`,
        Unspecified: `Non specificato`,
        Security: `Sicurezza`,
        change_password: `Cambia la password`,
        change_now: `Cambia ora`,
        connect_email: `Connetti e-mail`,
        delete_account: `Eliminare l'account`,
        delete_account_info: `Quando il tuo account viene eliminato, tutti i dati in Piano e prodotti associati al tuo account verranno eliminati in modo permanente e potresti non essere in grado di recuperarli. Ti consigliamo di procedere con cautela.`,
        Delete: `Elimina`,
        Logout: `Disconnettersi`,
        my_profile: `Il mio profilo`,
        guides_faqs: `Guide e FAQ`,
        More: `Di più`,
        guides: `Guide`,
        register: `Registrati`,
        hot_faq: `Domande frequenti`,
        Contents: `Contenuti:`,
        contact_us: `Contattaci>>`,
        plan: `Piano`,
        unregistered: `Non registrato`,
        buy_more: `Comprare di più`,
        buy_again: `Acquista di nuovo`,
        buy_now: `Acquista ora`,
        free_no_limit: `Gratuito e senza limiti`,
        expired: `Scaduto`,
        lifetime: `Tutta la vita`,
        remain: `Rimanere`,
        day_s: `Giorno/i`,
        day: `Giorno`,
        error_24801: `Richiesta non riuscita (codice errore: 24801). Per favore riprova`,
        no_app_found: `Nessuna app trovata！<a href="/">Aggiorna</a> o <a href="https://it.tipard.com/">Vai al sito web ufficiale</a>`,
        get_more: `Ottieni di più >>`,
        edit_photo: `Modifica foto`,
        select_photo: `Seleziona foto`,
        change_photo: `Cambia foto`,
        cancel: `Annulla`,
        hide_password: `Nascondi password`,
        show_password: `Mostra password`,
        zoom_in: `Ingrandire`,
        zoom_out: `Rimpicciolisci`,
        rotate: `Ruotare`,
        horizontal_flip: `Capovolgimento orizzontale`,
        vertical_flip: `Capovolgimento verticale`,
        country: `Nazione`,
        country_1: `Seleziona il tuo paese/regione`,
        country_2: `isola di aland`,
        country_3: `Afghanistan`,
        country_4: `Albania`,
        country_5: `Algeria`,
        country_6: `Samoa americane`,
        country_7: `Andorra`,
        country_8: `Angola`,
        country_9: `Anguilla`,
        country_10: `Antartide`,
        country_11: `Antigua e Barbuda`,
        country_12: `Argentina`,
        country_13: `Armenia`,
        country_14: `Aruba`,
        country_15: `Australia`,
        country_16: `Austria`,
        country_17: `Azerbaigian`,
        country_18: `Bahrein`,
        country_19: `Bangladesh`,
        country_20: `Barbados`,
        country_21: `Bielorussia`,
        country_22: `Belgio`,
        country_23: `Belize`,
        country_24: `Benin`,
        country_25: `Bermuda`,
        country_26: `Bhutan`,
        country_27: `Bolivia`,
        country_28: `Bosnia ed Erzegovina`,
        country_29: `Botswana`,
        country_30: `Isola Bouvet`,
        country_31: `Brasile`,
        country_32: `Territorio britannico dell'Oceano Indiano`,
        country_33: `Isole Vergini Britanniche`,
        country_34: `Brunei`,
        country_35: `Bulgaria`,
        country_36: `Burkina`,
        country_37: `Burundi`,
        country_38: `Cambogia`,
        country_39: `Camerun`,
        country_40: `Canada`,
        country_41: `capo Verde`,
        country_42: `Caraibi Paesi Bassi`,
        country_43: `Isole Cayman`,
        country_44: `Repubblica Centrafricana`,
        country_45: `Chad`,
        country_46: `Chile`,
        country_47: `Cina`,
        country_48: `Isola di Natale`,
        country_49: `Isole Cocos (Keeling)`,
        country_50: `Colombia`,
        country_51: `Isole Cook`,
        country_52: `Costa Rica`,
        country_53: `"Costa d'Avorio"`,
        country_54: `Croazia`,
        country_55: `Cuba`,
        country_56: `Cipro`,
        country_57: `Repubblica Ceca`,
        country_58: `Repubblica Democratica del Congo`,
        country_59: `Danimarca`,
        country_60: `Gibuti`,
        country_61: `dominica`,
        country_62: `Repubblica Dominicana`,
        country_63: `Ecuador`,
        country_64: `Egitto`,
        country_65: `El Salvador`,
        country_66: `Guinea Equatoriale`,
        country_67: `Eritrea`,
        country_68: `Estonia`,
        country_69: `Etiopia`,
        country_70: `Isole Falkland`,
        country_71: `Isole Faroe`,
        country_72: `Stati Federati di Micronesia`,
        country_73: `Figi`,
        country_74: `Finlandia`,
        country_75: `Francia`,
        country_76: `Guiana francese`,
        country_77: `Polinesia francese`,
        country_78: `Territori della Francia del sud`,
        country_79: `Gabon`,
        country_80: `Gambia`,
        country_81: `Georgia`,
        country_82: `Germania`,
        country_83: `Ghana`,
        country_84: `Gibilterra`,
        country_85: `Gran Bretagna (Regno Unito; Inghilterra)`,
        country_86: `Grecia`,
        country_87: `Groenlandia`,
        country_88: `Grenada`,
        country_89: `Guadalupa`,
        country_90: `Guami`,
        country_91: `Guatemala`,
        country_92: `Guernsey`,
        country_93: `Guinea`,
        country_94: `Guinea-Bissau`,
        country_95: `Guyana`,
        country_96: `Haiti`,
        country_97: `Isole Heard e McDonald`,
        country_98: `Honduras`,
        country_99: `Hong Kong`,
        country_100: `Ungheria`,
        country_101: `Islanda`,
        country_102: `India`,
        country_103: `Indonesia`,
        country_104: `Iran`,
        country_105: `Iraq`,
        country_106: `Irlanda`,
        country_107: `Isola di Man`,
        country_108: `Israele`,
        country_109: `Italia`,
        country_110: `Giamaica`,
        country_111: `Giappone`,
        country_112: `maglia`,
        country_113: `Giordania`,
        country_114: `Kazakistan`,
        country_115: `Kenia`,
        country_116: `Kiribati`,
        country_117: `Kuwait`,
        country_118: `Kirghizistan`,
        country_119: `Laos`,
        country_120: `Lettonia`,
        country_121: `Libano`,
        country_122: `Lesotho`,
        country_123: `Liberia`,
        country_124: `Libia`,
        country_125: `Liechtenstein`,
        country_126: `Lituania`,
        country_127: `Lussemburgo`,
        country_128: `Macao`,
        country_129: `Madagascar`,
        country_130: `Malawi`,
        country_131: `Malaysia`,
        country_132: `Maldive`,
        country_133: `Mali`,
        country_134: `Malta`,
        country_135: `Isole Marshall`,
        country_136: `Martinica`,
        country_137: `Mauritania`,
        country_138: `Mauritius`,
        country_139: `Mayotte`,
        country_140: `Messico`,
        country_141: `Moldavia`,
        country_142: `Monaco`,
        country_143: `Mongolia`,
        country_144: `Montenegro`,
        country_145: `Montserrat`,
        country_146: `Marocco`,
        country_147: `Mozambico`,
        country_148: `Birmania (Birmania)`,
        country_149: `Namibia`,
        country_150: `Nauru`,
        country_151: `Nepal`,
        country_152: `Olanda`,
        country_153: `Nuova Caledonia`,
        country_154: `Nuova Zelanda`,
        country_155: `Nicaragua`,
        country_156: `Niger`,
        country_157: `Nigeria`,
        country_158: `Niue`,
        country_159: `Isola Norfolk`,
        country_160: `Corea del nord`,
        country_161: `Isole Marianne settentrionali`,
        country_162: `Norvegia`,
        country_163: `Oman`,
        country_164: `Pakistan`,
        country_165: `Palau`,
        country_166: `Territori palestinesi`,
        country_167: `Panama`,
        country_168: `Papua Nuova Guinea`,
        country_169: `Paraguay`,
        country_170: `Perù`,
        country_171: `Isole Pitcairn`,
        country_172: `Polonia`,
        country_173: `Portogallo`,
        country_174: `Porto Rico`,
        country_175: `Qatar`,
        country_176: `Repubblica di Macedonia (FYROM)`,
        country_177: `Repubblica del Congo`,
        country_178: `Riunione`,
        country_179: `Romania`,
        country_180: `Federazione Russa`,
        country_181: `Ruanda`,
        country_182: `San Bartolomeo`,
        country_183: `Saint Martin (Francia)`,
        country_184: `Saint-Pierre e Miquelon`,
        country_185: `Samoa`,
        country_186: `San Marino`,
        country_187: `Sao Tomé e Principe`,
        country_188: `Arabia Saudita`,
        country_189: `Senegal`,
        country_190: `Serbia`,
        country_191: `Seychelles`,
        country_192: `Sierra Leone`,
        country_193: `Singapore`,
        country_194: `Slovacchia`,
        country_195: `Slovenia`,
        country_196: `Isole Salomone`,
        country_197: `Somalia`,
        country_198: `Sud Africa`,
        country_199: `Georgia del Sud e Isola Sandwich Meridionale`,
        country_200: `Corea del Sud`,
        country_201: `Sudan del Sud`,
        country_202: `Spagna`,
        country_203: `Sri Lanka`,
        country_204: `Sant'Elena e dipendenze`,
        country_205: `St. Kitts e Nevis`,
        country_206: `Santa Lucia`,
        country_207: `St. Vincent e Grenadine`,
        country_208: `Sudan`,
        country_209: `Suriname`,
        country_210: `Swaziland`,
        country_211: `Svezia`,
        country_212: `Svizzera`,
        country_213: `Siria`,
        country_214: `Taiwan`,
        country_215: `Tagikistan`,
        country_216: `Tanzania`,
        country_217: `Modello:Dati nazionali SJM Svalbard`,
        country_218: `Tailandia`,
        country_219: `Le Bahamas`,
        country_220: `Le Comore`,
        country_221: `Le Filippine`,
        country_222: `Timor Est (Timor Est)`,
        country_223: `Andare`,
        country_224: `Tokelau`,
        country_225: `Tonga`,
        country_226: `Trinidad & Tobago`,
        country_227: `Tunisia`,
        country_228: `tacchino`,
        country_229: `Turkmenistan`,
        country_230: `Isole Turks e Caicos`,
        country_231: `Tuvalu`,
        country_232: `Uganda`,
        country_233: `Ucraina`,
        country_234: `Emirati Arabi Uniti`,
        country_235: `Isole Minori Esterne degli Stati Uniti`,
        country_236: `Stati Uniti d'America (USA)`,
        country_237: `Isole Vergini americane`,
        country_238: `Uruguay`,
        country_239: `Uzbekistan`,
        country_240: `Vanuatu`,
        country_241: `Città del Vaticano (Santa Sede)`,
        country_242: `Venezuela`,
        country_243: `Vietnam`,
        country_244: `Wallis e Futuna`,
        country_245: `Sahara occidentale`,
        country_246: `Yemen`,
        country_247: `Zambia`,
        country_248: `Zimbabwe`,
        google_login: `Accedi con Google`,
        State: `Stato`,
        Activation_code: `Codice di attivazione`,
        Question: `Elenca tutte le app a cui hai effettuato l'accesso`,
        Copy_complete: `Copia completata`,
        change_password_success: `password cambiata con successo`,
        successful_login_title: `Accesso riuscito`,
        product_page: `Pagina del prodotto>>`,
        successful_login_info: `Accesso completato. Chiudi la pagina corrente e torna alla scheda originale per continuare con il seguente processo. La pagina corrente verrà chiusa automaticamente entro 5 secondi. Se la chiusura automatica o la chiusura facendo clic sul pulsante "Fine" non riesce, chiudere direttamente questa scheda.`,
        successful_login_info_firefox: `Accesso completato. Chiudi la pagina corrente e torna alla scheda originale per continuare con il seguente processo.`,
        my_account: `Il mio account`,
        my_history: `La mia storia`,
        remove_watermark: `Rimuovi filigrana`,
        no_history: `Nessuna storia`,
        history_all: `Seleziona tutto`,
        history_open: `Aprire`,
        history_down: `Scarica`,
        history_delete: `Eliminare`,
        history_clear: `Cancella non valido`,
        images: `immagini)`,
        use_this_function: `Usa questa funzione>>`,
        hd_downloading: `Vantaggi del download di immagini originali HD:`,
        lifetimeRemaining: `Rimanente a vita`,
        Remaining: `Residuo`,
        email_verification: `verifica email`,
        email_verification_info: `Abbiamo inviato il messaggio di verifica alla tua e-mail <span class="email"></span> e completa la verifica. Dopo la verifica, i vantaggi verranno sincronizzati automaticamente.`,
        wrong_email: `Indirizzo email sbagliato?`,
        click_here_to_modify: `Clicca qui per modificare`,
        get_more_help: `Ottenere più aiuto?`,
        click_here: `Clicca qui`,
        email_verification_info_success: `Congratulazioni per aver verificato il tuo account di posta elettronica.`,
        email_verification_info_error: `Verifica non riuscita a causa del collegamento scaduto.`,
        registration_succeeded: `Registrazione riuscita`,
        registration_succeeded_info_1: `Congratulazioni! Hai completato la registrazione con successo. Abbiamo inviato il messaggio di verifica alla tua email <span class="email"></span> e completa la verifica per utilizzare i vantaggi di questa email.`,
        registration_succeeded_info_2: `Fare clic su "Fatto" per tornare alla home page e utilizzare questo prodotto.`,
        registration_succeeded_info_3: `Fare clic su "Fatto" per chiudere la pagina corrente e tornare alla Home page per le seguenti operazioni. Se la chiusura non riesce, chiudere manualmente la scheda.`,
        verify_email: `Verifica Email`,
        registered_email_not_verified: `L'e-mail registrata non è stata verificata, verificala immediatamente.`,
        email_verification_time_1: `Non hai ricevuto un'email di verifica?`,
        email_verification_time_2: `A seguito di`,
        email_verification_time_3: `Clicca qui per inviarlo nuovamente`,
        error_26301: `Codice di errore: 26301, contattaci`,
        error_26302: `Codice di errore: 26302, contattaci`,
        error_26303: `Errore nel formato dell'e-mail (codice di errore: 26303). Si prega di inserirlo di nuovo`,
        error_26304: `Si consiglia una password superiore a 8 caratteri (codice di errore: 26304)`,
        error_26305: `Reuqest non riuscito (codice di errore: 26305). Si prega di riprovare`,
        error_26306: `L'email è stata registrata, per favore <a href="https://account.tipard.com/login">accedi</a>`,
        error_26307: `Reuqest non riuscito (codice di errore: 26307). Si prega di riprovare`,
        error_26308: `Reuqest non riuscito (codice di errore: 26308). Si prega di riprovare`,
        error_26401: `Codice di errore: 26401, riprovare`,
        error_26402: `L'email è stata verificata (codice di errore: 26402), riprova`,
        error_26403: `Reuqest non riuscito (codice di errore: 26403). Si prega di riprovare`,
        error_26404: `Richiesta non riuscita (codice errore: 26404). Si prega di riprovare`,
        error_26501: `Codice di errore: 26501, contattaci`,
        error_26502: `Codice di errore: 26502, contattaci`,
        error_26503: `Errore formato email (codice errore: 26503). Si prega di inserirlo di nuovo`,
        error_26504: `Reuqest non riuscito (codice di errore: 26504). Si prega di riprovare`,
        error_26505: `L'email non è stata registrata, per favore <a href="https://account.tipard.com/register">registrala prima</a>`,
        error_26506: `L'e-mail è stata verificata.`,
        error_26507: `Reuqest non riuscito (codice di errore: 26507). Si prega di riprovare`,
        error_26508: `Verifica fallita (codice di errore: 26508), riprova`,
        error_26509: `Richiesta non riuscita (codice di errore: 26509), riprovare`,
        error_26510: `Codice di errore: 26510, contattaci`,
        error_26601: `Codice di errore: 26601, contattaci`,
        error_26602: `Codice di errore: 26602, contattaci`,
        error_26603: `Reuqest non riuscito (codice di errore: 26603). Si prega di riprovare`,
        error_26604: `Codice di errore: 26604, contattaci`,
        error_26605: `Codice di errore: 26605, contattaci`,
        error_26701: `Codice di errore: 26701, contattaci`,
        error_26702: `Reuqest non riuscito (codice di errore: 26702). Si prega di riprovare`,
        error_26703: `Codice di errore: 26703, contattaci`,
        error_26704: `Codice di errore: 26704, contattaci`,
        error_26705: `Attendi l'accesso (codice di errore: 26705). Si prega di riprovare`,
        no_cookie: `Hai attivato la funzione Blocca tutti i cookie nel tuo browser, quindi non puoi accedere. Vai su Impostazioni per selezionare la casella di Consenti tutti i cookie.`,
        error_26801: `Codice di errore: 26801, contattaci`,
        error_26802: `Codice di errore: 26802, contattaci`,
        error_26803: `Richiesta non riuscita (codice errore: 26803). Si prega di riprovare`,
        error_26804: `Richiesta non riuscita (codice errore: 26804). Si prega di riprovare`,
        error_order: `Richiesta non riuscita (codice di errore: 27098), riprova!`,
        error_order1: `La query dell'ordine è incompleta（codice di errore:`,
        error_order2: `）, aggiorna e riprova.`,
        modify_email_title: `Modifica e-mail`,
        modify_email_info: `Puoi utilizzare l'e-mail modificata per accedere al tuo account.`,
        images_per: `Immagini per`,
        error_26101: `Errore: 26101. Contattaci`,
        error_26102: `Errore: 26102. Contattaci`,
        error_26103: `Richiesta non riuscita (codice di errore:26103). Per favore, riprova`,
        error_26104: `Codice di errore: 26104, riprovare`,
        error_26105: `Codice di errore: 26105, riprovare`,
        error_26106: `Eliminazione non riuscita (codice di errore: 26106). Si prega di riprovare`,
        error_26201: `Errore: 26201. Contattaci`,
        error_26202: `Richiesta non riuscita (codice di errore:26202). Per favore, riprova`,
        error_26001: `Errore: 26001. Contattaci`,
        error_26002: `Errore: 26002. Contattaci`,
        error_26003: `Errore: 26003. Contattaci`,
        error_26004: `Errore: 26004. Contattaci`,
        error_26005: `Richiesta non riuscita (codice di errore:26005). Per favore, riprova`,
        error_26006: `Codice di errore: 26006, riprovare`,
        error_26008: `Errore: 26008. Contattaci`,
        go_to_the_home_page: `Vai alla home page`,
        error_27101: `Richiesta non riuscita (codice errore: 27101). Si prega di riprovare ist`,
        error_27201: `Codice di errore: 27201, contattaci`,
        error_27202: `Codice di errore: 27202, riprovare`,
        error_27203: `Richiesta non riuscita (codice errore: 27203). Si prega di riprovare`,
        error_27204: `Codice non valido (codice errore: 27204).`,
        error_27205: `Richiesta non riuscita (codice errore: 27205). Si prega di riprovare`,
        error_27206: `Richiesta non riuscita (codice errore: 27206). Si prega di riprovare`,
        error_27207: `Richiesta non riuscita (codice errore: 27207). Si prega di riprovare`,
        no_history_found: `Non hai utilizzato alcuno strumento! <a href="/">Aggiorna</a> o <a href="https://it.tipard.com/">Vai al sito web ufficiale</a>`,
        error_25301: `Errore: 25301. Contattaci`,
        error_25302: `Errore: 25302. Contattaci`,
        error_25303: `Richiesta non riuscita (codice di errore: 25303). Si prega di riprovare`,
        error_25304: `Richiesta non riuscita (codice di errore: 25304). Si prega di riprovare`,
        error_25305: `Richiesta non riuscita (codice di errore: 25305). Si prega di riprovare`,
        error_25306: `Richiesta non riuscita (codice di errore: 25306). Si prega di riprovare`,
        image_upscaler_p: `Vantaggi del download di immagini senza filigrana:`,
        Available_for: `Disponibile per:`,
        credit_per: `%s credito/i per immagine HD`,
        still_valid: `I piani acquistati sono ancora validi`,
        credit: `crediti)`,
        pc_3rd_info: `Accedi con successo. Si prega di andare all'applicazione per ulteriori operazioni.`,
        use_online: `Utilizza il servizio online`,
        use_download: `Utilizzare il programma desktop`,
        use_immediately: `Utilizzare immediatamente`,
        Use_in_browser: `Utilizzare nel browser`,
        win_desktop: `finestre`,
        Mac_desktop: `Mac`,
        credits_per: `{%} crediti al mese`,
        expire: `Data di scadenza:`,
        viewDetails: `Visualizza dettagli`,
        viewHistory: `Visualizza i vantaggi della cronologia>>`,
        viewDetailsInfo: `Avviso: se i vantaggi dell'abbonamento vengono rinnovati entro 7 giorni dalla scadenza, i vantaggi non utilizzati potranno continuare a essere utilizzati. Inoltre, la data di scadenza verrà automaticamente aggiornata alla data di scadenza del nuovo abbonamento. Se non è presente alcun nuovo abbonamento dopo 7 giorni dalla scadenza, tutti i vantaggi scaduti verranno cancellati.`,
        "connect_account": "Associa l'e-mail al tuo account",
        "connect_account_info": "Dopo l'associazione, puoi accedere con questo indirizzo email.",
        "connect_now": "Associa ora",
        "no_email_bind": "Nessun vincolo e-mail",
        "bind_email": "Associa e-mail",
        "connect_your_email_placeholder": "Inserisci il tuo indirizzo email",
        "bind_an_email": "Associa un'e-mail",
        "bind_info": "Hai effettuato l'accesso con successo. Associa un'e-mail al tuo account per attivare i vantaggi.",
        "bind_later": "Rilega più tardi",
        "footer": "Copyright © 2024 Tipard Studio. Tutti i diritti riservati.",
        "overtime": "Codice di errore: {%}, riprovare",
        "hi": "Il suo!",
        "Personal_Information": "Informazioni personali",
        "Access": "Accesso",
        "Subscription_Plan": "(Piano di abbonamento)",
        "No_orders": "Nessun ordine trovato.",
        "No_data": "Nessun dato",
        "Featured_Products": "Prodotti in evidenza",
        "More_Products": "Altri prodotti",
        "Free_Download": "Download gratuito",
        "Get_Started": "Iniziare",
        "Subscribe": "Iscriviti",
        "Verified": "Verificato",
        "back_to_account_center": "Torna al Centro Account",
        "success": "Successo!",
        "successfully": "Hai registrato un account con successo.",
        "Continue": "Continuare",
        "Already": "Hai già un account?",
        "loading_verification": "Controllo dello stato di verifica...",
        "email_no_verification": "Spiacenti, l'indirizzo email registrato non è stato verificato. Completa la verifica seguendo le istruzioni sopra riportate e clicca nuovamente sul pulsante \"Fatto\" per completare la registrazione.",
        "error_20505": "L'email è stata collegata",
        "error_20506": "Errore: 20506. Contattaci",
        "error_28401": "Inserisci la tua email",
        "error_28402": "Formato email non corretto",
        "error_28403": "Errore: 28403. Contattaci",
        "error_28404": "L'email è stata registrata",
        "error_28405": "Errore: 28405. Contattaci",
        "error_28406": "L'email è stata collegata",
        "error_28407": "Errore: 28407. Contattaci",
        "error_28408": "Impossibile inviare l'e-mail",
        "error_28601": "Inserisci la tua email",
        "error_28602": "Inserisci il codice di verifica",
        "error_28603": "Formato email non corretto",
        "error_28604": "Errore: 28604. Contattaci",
        "error_28605": "Il codice di verifica non è corretto",
        "error_28606": "Errore: 28606. Contattaci",
        "error_28607": "L'email è stata registrata",
        "error_28608": "Non c'è nessuna email associata al tuo account",
        "error_28609": "L'email non è verificata",
        "error_28610": "Errore: 28610. Contattaci",
        "error_28611": "L'email è stata collegata",
        "error_28612": "Errore: 28612. Contattaci",
        "unbind_email_btn": "Scollega l'email",
        "binding_exchange": "Associa e-mail",
        "Subscription_Status": "Stato dell'abbonamento",
        "mask_unbind_info_1": "Vuoi davvero scollegare {%} da questo account? Dopo aver scollegato, non potrai più controllare i vantaggi di questa email",
        "mask_unbind_info_2": "Vuoi davvero annullare l'associazione di tutti i PC associati a questo codice di registrazione?<a href=\"https://it.tipard.com/faq.html#unbind\">Scopri di più sulla modifica dei dispositivi.</a>",
        "error_dc_link": "Errore, contattaci",
        "vcu_state": "Vantaggi della versione completa:",
        "monthly_subscription": "Licenza di 1 mese",
        "year_subscription": "Licenza di 1 anno",
        "to": "A",
        "lifetime_subscription": "Licenza a vita",
        "unbind": "Confermare",
        "unbind_success": "Slegato con successo",
        "error_28401": "Inserisci la tua email",
        "error_28702": "Errore: 28702. Contattaci",
        "error_28703": "Il codice di verifica non è corretto",
        "error_28704": "Errore: 28704. Contattaci",
        "error_28705": "L'email non è stata collegata",
        "error_28706": "Inserisci il codice di verifica",
        "error_28707": "Impossibile scollegare",
        "error_28901": "Errore: 28901. Contattaci",
        "error_28902": "Errore: 28902. Contattaci",
        "error_28903": "Errore: 28903. Contattaci",
        "error_28904": "Errore: 28904. Contattaci",
        "error_28905": "Errore: 28905. Contattaci",
        "error_28906": "Errore: 28906. Contattaci",
        "error_28907": "Codice di registrazione non valido",
        "error_28908": "Errore: 28908. Contattaci",
        "error_28909": "Attualmente nel periodo di raffreddamento per lo svincolo. Puoi svincolare solo una volta al mese.",
        "error_289010": "Errore: 289010. Contattaci",
        "error_289011": "Errore: 289011. Contattaci",
        "error_289012": "Errore: 289012. Contattaci",
        "activated_computer": "Attivato su %s/%s computer",
        "bind_email_info": "I vantaggi delle seguenti e-mail saranno vincolati e utilizzati tramite questo account VIP.",
        "error_26309": "Errore: 26309. Contattaci",
        "error_26310": "Codice di riferimento non valido",
        "error_26311": "Errore: 26311. Contattaci",
        "error_26312": "Codice di riferimento non valido",
        "error_26313": "Errore: 26313. Contattaci",
        "error_26314": "L'email è stata collegata",
        "activate_unbinding": "Cambia dispositivo",
        "copy_registration_code": "Copia il codice di registrazione",
        "show_registration_code": "Mostra codice di registrazione",
        "hide_registration_code": "Nascondi il codice di registrazione",
        "other_binding_email": "Altre e-mail vincolanti",
        "home": "Casa",
        "points_balance": "Saldo punti:",
        "earn_now": "Guadagna ora >>",
        "earn_more": "Guadagna più punti GRATUITI, riscatta più regali",
        "earn_or_redeem_points": "Guadagna/Riscatta punti",
        "unbind_email_info": "L'account corrente è stato associato a un indirizzo email",
        "bind_email_title": "Se desideri associare più ordini per l'acquisto di indirizzi e-mail, clicca sul pulsante \"Associazione e-mail\" qui sotto per effettuare l'associazione.",
        "bind_email_tip": "<span>Suggerimento:</span> al momento sono supportati solo gli acquisti tramite codice di registrazione e-mail.",
        "email_association": "Associazione e-mail",
        "dissociation": "Dissociazione",
        "back": "Indietro",
        "activate_benefits": "Inserisci l'email di acquisto che deve essere associata per ottenere il codice di verifica. Inserisci il codice di verifica ricevuto nell'email nella casella di input del codice di verifica e clicca sul pulsante \"Verifica attivazione\" per attivare i vantaggi.",
        "e_mail": "E-mail:",
        "verification_code": "Codice di verifica:",
        "obtain_verification_code": "Ottieni il codice di verifica",
        "verification_association": "Verifica associazione",
        "current_password": "Password attuale:",
        "account_center": "Centro account",
        "new_password": "Nuova password:",
        "confirm_password": "Conferma nuova password:",
        "Send": "Inviare",
        "history_files": "File di cronologia",
        "delete_account_info_1": "1. L'eliminazione del tuo account Tipard è un'azione permanente e non può essere annullata. Tutti i tuoi dati, comprese foto e preferenze, andranno persi. Vuoi davvero eliminare il tuo account Tipard?",
        "delete_account_info_2": "2. Se decidi di eliminare l'account Tipard, clicca sul pulsante Invia e inserisci il codice di verifica che ti abbiamo inviato tramite email.",
        "best_deals": "Le migliori offerte",
        "close": "Vicino",
        "product": "Prodotto:"
    },
    ja: {
        overtime_1: `エラーコード：`,
        overtime_2: `再試行してください`,
        isnetwork: `インターネットエラー。確認して再試行してください`,
        email_placeholder: `Eメール`,
        email_empty: `メールアドレスを入力してください`,
        email_not_valid: `メールが無効です`,
        email_not_valid_1: `メールアドレスを入力してください`,
        email_not_valid_2: `メールは無効です。メールを変えて再度入力してください。`,
        email_not_valid_3: `メール入力なし`,
        password_placeholder: `パスワード`,
        password_empty: `パスワードを入力してください`,
        password_not_valid: `間違えたメールまたはパスワードです`,
        password_not_valid_1: `8文字以上のパスワードが必要です`,
        password_not_valid_2: `パスワードを作成してください`,
        password_placeholder_1: `パスワードを作成してください`,
        password_placeholder_2: `パスワードを確認しましょう`,
        password_placeholder_3: `新しいパスワードを作成します`,
        password_placeholder_4: `新しいパスワードを確認します`,
        password_placeholder_5: `古いパスワードを入力してください`,
        copy_password_empty: `パスワードを確認してください`,
        copy_password_not_valid: `パスワードを確認してください`,
        copy_passwords_inconsistent: `パスワードが一致しません`,
        code_empty: `検証コードを入力してください`,
        code_not_valid: `無効な検証コード`,
        code_placeholder: `検証コード`,
        not_received_code: `メールボックスが検証コードを長期間受信していない場合は、検証コーを再度取得してください。`,
        get_first_code: `検証コーを取得してください。`,
        last_name_placeholder: `姓を入力してください`,
        first_name_placeholder: `名を入力してください`,
        address_placeholder: `住所を入力してください`,
        no_code_text: `検証コードを送信しました。コードを入力してください。<span class='tips'>コードを受け取りませんでしたか？`,
        no_code_text_1: `1.メールアドレスが有効で、メールを受信できることを確認してください。`,
        no_code_text_2: `2.メールはシステムによって自動的に送信されるため、スパムまたはジャンクメールとして認識する場合があります。 メールがゴミ箱フォルダにあるかどうかを確認してください。`,
        no_code_text_3: `3.問題を解決できませんか？`,
        no_code_text_3_span: `ここをクリックしてこちらへ連絡してください。`,
        order_no: `ご購入履歴があります。ご不明な点がございましたら、<a href="https://jp.tipard.com/store.html">ストア</a>または<a href="https://jp.tipard.com/contact.html">お問い合わせ</a>までご連絡してください。`,
        error_24901: `現在のアカウントにはリンクされたメールがなく、注文を見つけることができません。メールで連絡してください。`,
        user_guide: `ユーザーガイド>>`,
        download: `ダウンロード`,
        order_number: `注文番号`,
        Refund: `返金`,
        Disabled: `無効`,
        Normal: `正常`,
        Modify: `変更`,
        Modify_1: `変更>>`,
        Connect: `接続`,
        unlink_success: `正常にリンク解除`,
        connect_success: `正常に接続します`,
        feedback_title: `ご意見をいただきありがとうございます。問題を残してください。24時間以内に対応いたします。`,
        feedback_thank_you: `ありがとうございます！<br/>フィードバックは送信されました。`,
        feedback_email: `メールアドレスをここに入力してください！`,
        feedback_content: `ここでアドバイスとか問題とかを残してください。`,
        feedback_submit: `提出`,
        form_contents: `内容が入力されません。 内容を入力してもう一度提出してください。`,
        old_password: `古いパスワードを入力してください`,
        new_password: `新しいパスワードを作成してください`,
        old_new_password: `新しいパスワードは古いパスワードと同じにすることはできません`,
        incorrect_password: `間違ったパスワード`,
        delete_no: `今すぐ削除`,
        Caps: `CapsLockがオンになっています`,
        Get: `取得する`,
        Done: `終わり`,
        error_20001: `エラー：20001。再度ログインしてください。`,
        error_20002: `エラー：20002。再度ログインしてください。`,
        error_20003: `エラー：20003。再度ログインしてください。`,
        error_20004: `請求が失敗しました (エラーコード: 20004)。再試行してください。`,
        error_20005: `ログインセッションの有効期限が切れました（エラー：20005）。もう一度ログインしてください。`,
        error_20006: `請求が失敗しました（エラーコード：20006）。もう一度お試しください。`,
        error_20007: `ログインセッションの有効期限が切れました（エラー：20007）。もう一度ログインしてください。`,
        error_20008: `ログインセッションの有効期限が切れました（エラー：20008）。もう一度ログインしてください。`,
        error_20009: `ログインセッションの有効期限が切れました（エラー：20009）。もう一度ログインしてください。`,
        error_20101: `メールアドレスを入力してください（エラーコード：20101）`,
        error_20102: `メールが無効です（エラーコード：20102）`,
        error_20103: `請求が失敗しました（エラーコード：20103）。もう一度お試しください`,
        error_20104: `メールはすでに使用されています。<a href="https://account.tipard.com/login">ログイン</a>するか、新しいメールに登録してください`,
        error_20105: `請求が失敗しました（エラーコード：20105）。もう一度お試しください`,
        error_20106: `メールの送信に失敗しました。再試行してください`,
        error_20201: `メールアドレスを入力してください（エラーコード：20201）`,
        error_20202: `パスワードを入力してください（エラーコード：20202）`,
        error_20203: `検証コードを入力してください（エラーコード：20203）`,
        error_20204: `メールが無効です（エラーコード：20204）`,
        error_20205: `8文字以上のパスワードが必要です（エラーコード：20205）`,
        error_20206: `請求が失敗しました（エラーコード：20206）。もう一度お試しください`,
        error_20207: `無効な検証コード`,
        error_20208: `請求が失敗しました（エラーコード：20208）。もう一度お試しください`,
        error_20209: `請求トが失敗しました（エラーコード：20209）。もう一度お試しください`,
        error_20301: `メールアドレスを入力してください（エラーコード：20301）`,
        error_20302: `エラー：20302。お問い合わせください`,
        error_20303: `メールが無効です（エラーコード：20303）`,
        error_20304: `請求が失敗しました（エラーコード：20304）。もう一度お試しください`,
        error_20305: `アカウントが存在しません。再度入力するか、<a href="https://account.tipard.com/register">作成する</a>してください。`,
        error_20306: `パスワードはまだありません。 <a href="https://account.tipard.com/passwordless-login">パスワードなしのログイン</a>または<a href="create-password">パスワードの設定</a>を使用してログインします。`,
        error_20308: `請求が失敗しました（エラーコード：20308）。もう一度お試しください`,
        error_20401: `ログアウトに失敗しました（エラーコード：20401）。もう一度お試しください`,
        error_20501: `メールアドレスを入力してください（エラーコード：20501）`,
        error_20502: `メールが無効です（エラーコード：20502）`,
        error_20503: `請求が失敗しました（エラーコード：20503）。もう一度お試しください`,
        error_20504: `メール送信に失敗しました。もう一度お試しください。`,
        error_20601: `メールアドレスを入力してください（エラーコード：20601）`,
        error_20602: `検証コード（エラーコード：20602）を入力してください`,
        error_20603: `メールが無効です（エラーコード：20603）`,
        error_20604: `請求が失敗しました（エラーコード：20604）。もう一度お試しください`,
        error_20606: `請求が失敗しました（エラーコード：20606）。もう一度お試しください`,
        error_20607: `請求が失敗しました（エラーコード：20607）。もう一度お試しください`,
        error_20608: `請求が失敗しました（エラーコード：20608）。もう一度お試しください`,
        error_20701: `メールアドレスを入力してください（エラーコード：20701）`,
        error_20702: `メールが無効です（エラーコード：20702）`,
        error_20703: `請求が失敗しました（エラーコード：20703）。もう一度お試しください`,
        error_20704: `アカウントが存在しません。再度入力するか、<a href="https://account.tipard.com/register">作成する</a>してください。`,
        error_20705: `請求が失敗しました（エラーコード：20705）。もう一度お試しください`,
        error_20706: `メール送信に失敗しました。もう一度お試しください`,
        error_20801: `メールアドレスを入力してください（エラーコード：20801）`,
        error_20802: `エラー：20802。お問い合わせください`,
        error_20803: `検証コード（エラーコード：20803）を入力してください`,
        error_20804: `メールが無効です（エラーコード：20804）`,
        error_20805: `8文字以上のパスワードが必要です（エラーコード：20805）`,
        error_20806: `請求が失敗しました（エラーコード：20806）。もう一度お試しください`,
        error_20808: `請求が失敗しました（エラーコード：20808）。もう一度お試しください`,
        error_20901: `請求が失敗しました（エラーコード：20901）。もう一度お試しください`,
        error_20902: `請求が失敗しました（エラーコード：20902）。もう一度お試しください`,
        error_21000: `変更が保存されます`,
        error_21001: `内容は送信されません（エラーコード：21001）`,
        error_21002: `請求が失敗しました（エラーコード：21002）。もう一度お試しください`,
        error_21101: `メールアドレスを入力してください（エラーコード：21101）`,
        error_21102: `メールが無効です（エラーコード：21102）`,
        error_21103: `請求が失敗しました（エラーコード：21103）、もう一度やり直してください`,
        error_21104: `このメールは使用された状態です。新しいメールを使用してください`,
        error_21105: `請求が失敗しました（エラーコード：21105）、もう一度やり直してください`,
        error_21106: `メール送信に失敗しました。もう一度お試しください`,
        error_21201: `メールアドレスを入力してください（エラーコード：21201）`,
        error_21202: `検証コード（エラーコード：21202）を入力してください`,
        error_21203: `メールが無効です（エラーコード：21203）`,
        error_21204: `エラー：21204。お問い合わせください`,
        error_21205: `エラー：21205。お問い合わせください`,
        error_21206: `8文字以上のパスワードが必要です（エラーコード：21206）`,
        error_21207: `請求が失敗しました（エラーコード：21207）。もう一度お試しください`,
        error_21209: `請求が失敗しました（エラーコード：21209）。もう一度お試しください`,
        error_21301: `古いパスワードを入力してください（エラーコード：21301）`,
        error_21302: `新しいパスワードを作成してください（エラーコード：21302）`,
        error_21303: `新しいパスワードは古いパスワードと同じにすることはできません。 （エラー：21303）`,
        error_21304: `8文字以上のパスワードが必要です（エラーコード：21304）`,
        error_21306: `請求が失敗しました（エラーコード：21306）。もう一度お試しください`,
        error_21402: `請求が失敗しました（エラーコード：21402）。もう一度お試しください`,
        error_21403: `検証コードは送信に失敗しました。再度送信してください`,
        error_21500: `アカウントが削除されました`,
        error_21501: `検証コード（エラーコード：21501）を入力してください`,
        error_21502: `ログインセッションの有効期限が切れました（エラー：21502）。もう一度ログインしてください。`,
        error_21503: `請求が失敗しました（エラーコード：21503）。もう一度お試しください`,
        error_21505: `請求が失敗しました（エラーコード：21505）、もう一度やり直してください`,
        error_21601: `エラー：20601。お問い合わせください`,
        error_21602: `無効な検証（エラー：20602）。もう一度お試しください。`,
        error_21603: `エラー：20603。もう一度お試しください`,
        error_21604: `請求が失敗しました（エラーコード：21604）。もう一度お試しください`,
        error_21606: `請求が失敗しました（エラーコード：21606）。もう一度お試しください`,
        error_21611: `請求が失敗しました（エラーコード：21611）。もう一度お試しください`,
        error_21801: `エラー：21801。お問い合わせください`,
        error_21802: `請求が失敗しました（エラー：21802）。もう一度お試しください`,
        error_21803: `エラー：21803。もう一度お試しください`,
        error_21804: `請求が失敗しました（エラーコード：21804）。もう一度お試しください`,
        error_21806: `エラー：21806。もう一度お試しください`,
        error_21807: `エラー：21807。お問い合わせください`,
        error_21808: `エラー：21808。お問い合わせください`,
        error_21809: `エラー：21809。お問い合わせください`,
        error_21810: `エラー：21810。お問い合わせください`,
        error_21811: `エラー：21811。お問い合わせください`,
        error_21812: `エラー：21812。お問い合わせください`,
        error_21813: `請求が失敗しました（エラーコード：21813）。もう一度お試しください`,
        error_21814: `エラー：21814。お問い合わせください`,
        error_21815: `請求が失敗しました（エラーコード：21815）。もう一度お試しください`,
        error_21816: `エラー：21816。お問い合わせください`,
        error_21817: `エラー：21817。お問い合わせください`,
        error_21818: `エラー：21818。お問い合わせください`,
        error_21819: `請求が失敗しました（エラーコード：21819）。もう一度お試しください`,
        error_21820: `エラー：21820。お問い合わせください`,
        error_21821: `エラー：21821。お問い合わせください`,
        error_21822: `エラー：21822。お問い合わせください`,
        error_21823: `請求が失敗しました（エラーコード：21823）。もう一度お試しください`,
        error_21824: `請求が失敗しました（エラーコード：21824）。もう一度お試しください`,
        error_21825: `請求が失敗しました（エラーコード：21825）。もう一度お試しください`,
        error_21826: `請求が失敗しました（エラーコード：21826）。もう一度お試しください`,
        error_21901: `エラー：21901。お問い合わせください`,
        error_21902: `請求が失敗しました（エラーコード：21902）。もう一度お試しください`,
        error_21903: `アカウント状態が変更されました（エラーコード：21903）。ページを更新してもう一度お試しください`,
        error_21904: `エラー：21904。もう一度お試しください`,
        error_21905: `エラー：21905。もう一度お試しください`,
        error_21906: `請求が失敗しました（エラーコード：21906）。もう一度お試しください`,
        error_21907: `Googleアカウントは他のアカウントにリンクされています`,
        error_21908: `請求が失敗しました（エラーコード：21908）。もう一度お試しください`,
        error_22001: `請求が失敗しました（エラーコード：22001）。もう一度お試しください`,
        error_22002: `ログインなしでリンク解除に失敗しました`,
        error_22003: `請求が失敗しました（エラーコード：22003）。もう一度お試しください`,
        error_22101: `エラー：22101。お問い合わせください`,
        error_22102: `アカウントの状態が変更されました（エラーコード：22102）。ページを更新して再試行してください`,
        error_22103: `請求が失敗しました（エラーコード：22103）。もう一度お試しください`,
        error_22104: `アカウントの状態が変更されました（エラーコード：22104）。ページを更新してもう一度お試しください`,
        error_22105: `エラー：22105。もう一度お試しください`,
        error_22106: `エラー：22106。もう一度お試しください`,
        error_22107: `エラー：22107。お問い合わせください`,
        error_22108: `請求が失敗しました（エラーコード：22108）。もう一度お試しください`,
        error_22201: `エラー：22201。お問い合わせください`,
        error_22202: `無効な検証（エラー：22202）。もう一度お試しください。`,
        error_22203: `エラー：22203。もう一度お試しください。」`,
        error_22204: `請求が失敗しました（エラーコード：22204）。もう一度お試しください`,
        error_22206: `請求が失敗しました（エラーコード：22206）。もう一度お試しください`,
        error_22401: `エラー：22401。お問い合わせください`,
        error_22402: `無効な検証（エラー：22402）。もう一度お試しください。`,
        error_22403: `エラー：22403。もう一度お試しください`,
        error_22404: `請求が失敗しました（エラーコード：22404）。もう一度お試しください`,
        error_22405: `Facebookアカウントは他のメールにリンクされています`,
        error_22406: `エラー：22406。もう一度お試しください`,
        error_22407: `エラー：22407。お問い合わせください`,
        error_22408: `エラー：22408。お問い合わせください`,
        error_22409: `エラー：22409。お問い合わせください`,
        error_22410: `エラー：224010。お問い合わせください`,
        error_22411: `エラー：224011。お問い合わせください`,
        error_22412: `エラー：224012。お問い合わせください`,
        error_22413: `エラー：22413。お問い合わせください`,
        error_22414: `請求が失敗しました（エラーコード：22414）。もう一度お試しください`,
        error_22415: `エラー：22415。お問い合わせください`,
        error_22416: `エラー：22416。お問い合わせください`,
        error_22417: `エラー：22417。お問い合わせください`,
        error_22418: `請求が失敗しました（エラーコード：22418）。もう一度お試しください`,
        error_22419: `エラー：22419。お問い合わせください`,
        error_22420: `エラー：22420。お問い合わせください`,
        error_22421: `エラー：22421。お問い合わせください`,
        error_22422: `請求が失敗しました（エラーコード：22422）。もう一度お試しください`,
        error_22423: `請求が失敗しました（エラーコード：22423）。もう一度お試しください`,
        error_22424: `請求が失敗しました（エラーコード：22424）。もう一度お試しください`,
        error_22425: `請求が失敗しました（エラーコード：22425）。もう一度お試しください`,
        error_20098: `エラーコード：20098。プライベートモードで閲覧している場合は、通常モードに切り替えて再試行してください。`,
        error_22298: `Googleの請求に失敗しました（エラー：22298）。もう一度お試しください。`,
        error_22498: `Facebookの請求に失敗しました（エラー：22498）。もう一度お試しください。`,
        error_24902: `請求が失敗しました（エラーコード：24902）。もう一度お試しください`,
        error_24903: `請求が失敗しました（エラーコード：24903）。もう一度お試しください`,
        error_24904: `請求が失敗しました（エラーコード：24904）。もう一度お試しください`,
        error_24905: `請求が失敗しました（エラーコード：24905）。もう一度お試しください`,
        login_title: `Tipardにログインします`,
        password_placeholder: `パスワード`,
        log_in: `ログインする`,
        no_account: `アカウントがありませんか？`,
        create_it: `作成する`,
        or_log_in_with: `またはログイン`,
        passwordless_login: `パスワード不要のログイン`,
        log_in_done: `ログイン完了`,
        three_rd_account_connect_info: `おめでとう！成功にログインしました。これで、ログイン用の電子メールアカウントに接続できます。`,
        see_my_account: `アカウントを確認`,
        three_rd_login_merge_account: `サードパーティアカウントの電子メールアドレスがサインアップされました。この電子メールアドレスに直接接続してログインしますか？`,
        connect_log_in: `接続してログイン`,
        create_an_account: `アカウントを作成する`,
        back_to_log_in: `ログインに戻る`,
        create_password: `パスワードの作成`,
        create_now: `今すぐ作成`,
        password_login_subtitle: `スワードなしメールでパログイン`,
        account_login: `アカウントログイン`,
        rights: `このアカウントを作成する場合、<a href="https://jp.tipard.com/terms.html">利用規約</a>と<a href="https://jp.tipard.com/policy.html">プライバシーポリシー</a>を同意します`,
        passwordless_login_done: `パスワードなしログインしました`,
        passwordless_login_info: `おめでとうございます。パスワードなしログインしました。アカウントのパスワードを作成し、そのアカウントとパスワードでログインすることができます。 <a href="/create-password" style="display:initial;">今すぐ作成</a>`,
        sign_up: `サインアップ`,
        register_info: `アカウントを作成`,
        reset_now: `今すぐリセット`,
        forgot_password: `パスワードをお忘れですか`,
        reset_password_subtitle: `アカウントのメールアドレスを使用してパスワードをリセットします`,
        plan_products: `プランと製品`,
        nick_name: `ニックネーム`,
        email: `Eメール`,
        my_products: `私の製品`,
        my_orders: `私の注文`,
        unlink: `リンクを解除する`,
        link: `リンク`,
        connected_accounts: `接続されたアカウント`,
        last_name: `名`,
        first_name: `姓`,
        Gender: `性別`,
        Birth: `誕生日`,
        Month: `月`,
        Year: `年`,
        Country_Region: `国/地域`,
        Address: `住所`,
        Save: `保存`,
        Date: `日付け`,
        Male: `男`,
        Female: `女`,
        Unspecified: `詳細不明`,
        Security: `安全＆プライバシー`,
        change_password: `パスワードを変更する`,
        change_now: `今すぐ変更`,
        connect_email: `メールを接続する`,
        delete_account: `アカウントを削除する`,
        delete_account_info: `アカウントを削除すると、関連されているプランと注文情報のデータがすべて削除され、復元するのはできません。アカウントを削除してもよろしいでしょうか?`,
        Delete: `削除`,
        Logout: `ログアウト`,
        my_profile: `プロフィール`,
        guides_faqs: `ガイドとFAQ`,
        More: `もっと`,
        guides: `ガイド`,
        register: `登録`,
        hot_faq: `ホットFAQ`,
        Contents: `コンテンツ：`,
        contact_us: `お問い合わせ>>`,
        plan: `プラン`,
        unregistered: `未登録`,
        buy_more: `もっと買う`,
        buy_again: `もう一度購入する`,
        buy_now: `今すぐ購入`,
        free_no_limit: `無料＆無制限`,
        expired: `期限切れ`,
        lifetime: `一生`,
        remain: `残る`,
        day_s: `日々）`,
        day: `日々`,
        error_24801: `リクエストが失敗しました（エラーコード：24801）。もう一度お試しください`,
        no_app_found: `ソフトリストがありません。<a href="/">更新</a>または<a href="https://jp.tipard.com">公式ウェブサイト</a>にアクセスしてください。`,
        get_more: `もっと見る>>`,
        edit_photo: `写真を編集する`,
        select_photo: `写真を選択`,
        change_photo: `写真を変更する`,
        cancel: `キャンセル`,
        hide_password: `パスワードを隠す`,
        show_password: `パスワードを表示`,
        zoom_in: `ズームイン`,
        zoom_out: `ズームアウトする`,
        rotate: `回転する`,
        horizontal_flip: `水平フリップ`,
        vertical_flip: `垂直フリップ`,
        country: `国`,
        country_1: `国/地域を選択してください`,
        country_2: `オーランド島`,
        country_3: `アフガニスタン`,
        country_4: `アルバニア`,
        country_5: `アルジェリア`,
        country_6: `アメリカ領サモア`,
        country_7: `アンドラ`,
        country_8: `アンゴラ`,
        country_9: `アンギラ`,
        country_10: `南極大陸`,
        country_11: `アンティグアバーブーダ`,
        country_12: `アルゼンチン`,
        country_13: `アルメニア`,
        country_14: `アルバ`,
        country_15: `オーストラリア`,
        country_16: `オーストリア`,
        country_17: `アゼルバイジャン`,
        country_18: `バーレーン`,
        country_19: `バングラデシュ`,
        country_20: `バルバドス`,
        country_21: `ベラルーシ`,
        country_22: `ベルギー`,
        country_23: `ベリーズ`,
        country_24: `ベナン`,
        country_25: `バミューダ`,
        country_26: `ブータン`,
        country_27: `ボリビア`,
        country_28: `ボスニア・ヘルツェゴビナ`,
        country_29: `ボツワナ`,
        country_30: `ブーベ島`,
        country_31: `ブラジル`,
        country_32: `イギリス領インド洋地域`,
        country_33: `イギリス領バージン諸島`,
        country_34: `ブルネイ`,
        country_35: `ブルガリア`,
        country_36: `ブルキナファソ`,
        country_37: `ブルンジ`,
        country_38: `カンボジア`,
        country_39: `カメルーン`,
        country_40: `カナダ`,
        country_41: `カーボベルデ`,
        country_42: `カリブ海オランダ`,
        country_43: `ケイマン諸島`,
        country_44: `中央アフリカ共和国`,
        country_45: `チャド`,
        country_46: `チリ`,
        country_47: `中国`,
        country_48: `クリスマス島`,
        country_49: `ココス（キーリング）諸島`,
        country_50: `コロンビア`,
        country_51: `クック諸島`,
        country_52: `コスタリカ`,
        country_53: `「コートジボワール」`,
        country_54: `クロアチア`,
        country_55: `キューバ`,
        country_56: `キプロス`,
        country_57: `チェコ共和国`,
        country_58: `コンゴ民主共和国`,
        country_59: `デンマーク`,
        country_60: `ジブチ`,
        country_61: `ドミニカ`,
        country_62: `ドミニカ共和国`,
        country_63: `エクアドル`,
        country_64: `エジプト`,
        country_65: `エルサルバドル`,
        country_66: `赤道ギニア`,
        country_67: `エリトリア`,
        country_68: `エストニア`,
        country_69: `エチオピア`,
        country_70: `フォークランド諸島`,
        country_71: `フェロー諸島`,
        country_72: `ミクロネシア連邦`,
        country_73: `フィジー`,
        country_74: `フィンランド`,
        country_75: `フランス`,
        country_76: `フランス領ギアナ`,
        country_77: `フランス領ポリネシア`,
        country_78: `フランス領南方南方領土`,
        country_79: `ガボン`,
        country_80: `ガンビア`,
        country_81: `ジョージア`,
        country_82: `ドイツ`,
        country_83: `ガーナ`,
        country_84: `ジブラルタル`,
        country_85: `イギリス（イギリス;イギリス）`,
        country_86: `ギリシャ`,
        country_87: `グリーンランド`,
        country_88: `グレナダ`,
        country_89: `グアドループ`,
        country_90: `グアム`,
        country_91: `グアテマラ`,
        country_92: `ガーンジー`,
        country_93: `ギニア`,
        country_94: `ギニアビサウ`,
        country_95: `ガイアナ`,
        country_96: `ハイチ`,
        country_97: `ハード島とマクドナルド諸島`,
        country_98: `ホンジュラス`,
        country_99: `香港`,
        country_100: `ハンガリー`,
        country_101: `アイスランド`,
        country_102: `インド`,
        country_103: `インドネシア`,
        country_104: `イラン`,
        country_105: `イラク`,
        country_106: `アイルランド`,
        country_107: `マン島`,
        country_108: `イスラエル`,
        country_109: `イタリア`,
        country_110: `ジャマイカ`,
        country_111: `日本`,
        country_112: `ジャージー`,
        country_113: `ヨルダン`,
        country_114: `カザフスタン`,
        country_115: `ケニア`,
        country_116: `キリバス`,
        country_117: `クウェート`,
        country_118: `キルギスタン`,
        country_119: `ラオス`,
        country_120: `ラトビア`,
        country_121: `レバノン`,
        country_122: `レソト`,
        country_123: `リベリア`,
        country_124: `リビア`,
        country_125: `リヒテンシュタイン`,
        country_126: `リトアニア`,
        country_127: `ルクセンブルク`,
        country_128: `マカオ`,
        country_129: `マダガスカル`,
        country_130: `マラウイ`,
        country_131: `マレーシア`,
        country_132: `モルディブ`,
        country_133: `マリ`,
        country_134: `マルタ`,
        country_135: `マーシャル諸島`,
        country_136: `マルティニーク`,
        country_137: `モーリタニア`,
        country_138: `モーリシャス`,
        country_139: `マヨット`,
        country_140: `メキシコ`,
        country_141: `モルドバ`,
        country_142: `モナコ`,
        country_143: `モンゴル`,
        country_144: `モンテネグロ`,
        country_145: `モントセラト`,
        country_146: `モロッコ`,
        country_147: `モザンビーク`,
        country_148: `ミャンマー（ビルマ）`,
        country_149: `ナミビア`,
        country_150: `ナウル`,
        country_151: `ネパール`,
        country_152: `オランダ`,
        country_153: `ニューカレドニア`,
        country_154: `ニュージーランド`,
        country_155: `ニカラグア`,
        country_156: `ニジェール`,
        country_157: `ナイジェリア`,
        country_158: `ニウエ`,
        country_159: `ノーフォーク島`,
        country_160: `北朝鮮`,
        country_161: `北マリアナ諸島`,
        country_162: `ノルウェー`,
        country_163: `オマーン`,
        country_164: `パキスタン`,
        country_165: `パラオ`,
        country_166: `パレスチナ領土`,
        country_167: `パナマ`,
        country_168: `パプアニューギニア`,
        country_169: `パラグアイ`,
        country_170: `ペルー`,
        country_171: `ピトケアン諸島`,
        country_172: `ポーランド`,
        country_173: `ポルトガル`,
        country_174: `プエルトリコ`,
        country_175: `カタール`,
        country_176: `マケドニア共和国（FYROM）`,
        country_177: `コンゴ共和国`,
        country_178: `再会`,
        country_179: `ルーマニア`,
        country_180: `ロシア連邦`,
        country_181: `ルワンダ`,
        country_182: `サンバルテルミー`,
        country_183: `サンマルタン（フランス）`,
        country_184: `サンピエール島とミクロン島`,
        country_185: `サモア`,
        country_186: `サンマリノ`,
        country_187: `サントメ・プリンシペ`,
        country_188: `サウジアラビア`,
        country_189: `セネガル`,
        country_190: `セルビア`,
        country_191: `セイシェル`,
        country_192: `シエラレオネ`,
        country_193: `シンガポール`,
        country_194: `スロバキア`,
        country_195: `スロベニア`,
        country_196: `ソロモン諸島`,
        country_197: `ソマリア`,
        country_198: `南アフリカ`,
        country_199: `サウスジョージア島とサウスサンドイッチ島`,
        country_200: `韓国`,
        country_201: `南スーダン`,
        country_202: `スペイン`,
        country_203: `スリランカ`,
        country_204: `セントヘレナと依存関係`,
        country_205: `セントクリストファーネイビス`,
        country_206: `セントルシア`,
        country_207: `セントビンセントおよびグレナディーン諸島`,
        country_208: `スーダン`,
        country_209: `スリナム`,
        country_210: `スワジランド`,
        country_211: `スウェーデン`,
        country_212: `スイス`,
        country_213: `シリア`,
        country_214: `台湾`,
        country_215: `タジキスタン`,
        country_216: `タンザニア`,
        country_217: `テンプレート：国データSJMスバールバル`,
        country_218: `タイ`,
        country_219: `バハマ`,
        country_220: `コモロ`,
        country_221: `フィリピン`,
        country_222: `東ティモール（東ティモール）`,
        country_223: `トーゴ`,
        country_224: `トケラウ`,
        country_225: `トンガ`,
        country_226: `トリニダード・トバゴ`,
        country_227: `チュニジア`,
        country_228: `七面鳥`,
        country_229: `トルクメニスタン`,
        country_230: `タークス・カイコス諸島`,
        country_231: `ツバル`,
        country_232: `ウガンダ`,
        country_233: `ウクライナ`,
        country_234: `アラブ首長国連邦`,
        country_235: `合衆国領有小島`,
        country_236: `アメリカ合衆国（USA）`,
        country_237: `アメリカ領バージン諸島`,
        country_238: `ウルグアイ`,
        country_239: `ウズベキスタン`,
        country_240: `バヌアツ`,
        country_241: `バチカン市国（聖座）`,
        country_242: `ベネズエラ`,
        country_243: `ベトナム`,
        country_244: `ウォリス・フツナ`,
        country_245: `西サハラ`,
        country_246: `イエメン`,
        country_247: `ザンビア`,
        country_248: `ジンバブエ`,
        google_login: `Googleでサインイン`,
        State: `州`,
        Activation_code: `アクティベーションコード`,
        Question: `登録したソフトを表示します`,
        Copy_complete: `コピー完了`,
        change_password_success: `パスワードは正常に変更されました`,
        successful_login_title: `ログイン成功`,
        product_page: `製品ページ>>`,
        successful_login_info: `ログインが完了しました。現在のページを閉じて元のタブに戻り、次のプロセスを続行してください。現在のページは5秒後に自動的に閉じられます。自動クローズまたは[完了]ボタンをクリックして閉じることができない場合は、このタブを直接閉じてください。`,
        successful_login_info_firefox: `ログインが完了しました。現在のページを閉じて元のタブに戻り、次のプロセスを続行してください。`,
        my_account: `マイアカウント`,
        my_history: `私の歴史`,
        remove_watermark: `ウォーターマークを削除する`,
        no_history: `履歴はありません`,
        history_all: `すべて選択`,
        history_open: `開ける`,
        history_down: `ダウンロード`,
        history_delete: `消去`,
        history_clear: `無効をクリア`,
        images: `画像`,
        use_this_function: `この関数を使用する>>`,
        hd_downloading: `HDオリジナル画像のダウンロードのメリット：`,
        lifetimeRemaining: `生涯-残り`,
        Remaining: `残り`,
        email_verification: `メールによる確認`,
        email_verification_info: `確認メッセージをメール<spanclass = "email"> </ span>に送信しましたので、確認を完了してください。確認後、メリットは自動的に同期されます。`,
        wrong_email: `間違ったメールアドレス？`,
        click_here_to_modify: `変更するには、ここをクリックしてください`,
        get_more_help: `さらにヘルプが必要ですか？`,
        click_here: `ここをクリック`,
        email_verification_info_success: `メールアカウントを確認していただき、ありがとうございます。`,
        email_verification_info_error: `リンクの有効期限が切れているため、検証に失敗しました。`,
        registration_succeeded: `登録に成功しました`,
        registration_succeeded_info_1: `おめでとう！あなたが正常に登録されています。確認メッセージをメール<spanclass = "email"> </ span>に送信しました。このメールのメリットを利用するには、確認を完了してください。`,
        registration_succeeded_info_2: `「完了」をクリックしてホームページに戻り、この製品を使用してください。`,
        registration_succeeded_info_3: `「完了」をクリックして現在のページを閉じ、ホームページに戻って次の操作を行います。閉じられない場合は、手動でタブを閉じてください。`,
        verify_email: `Eメールを確認します`,
        registered_email_not_verified: `登録されたメールアドレスは確認されていません。すぐに確認してください。`,
        email_verification_time_1: `確認メールが届きませんでしたか？`,
        email_verification_time_2: `後`,
        email_verification_time_3: `ここをクリックして再送してください`,
        error_26301: `エラーコード：26301、お問い合わせください`,
        error_26302: `エラーコード：26302、お問い合わせください`,
        error_26303: `メールフォーマットエラー（エラーコード：26303）。もう一度入力してください`,
        error_26304: `8文字を超えるパスワードをお勧めします（エラーコード：26304）`,
        error_26305: `Reuqestが失敗しました（エラーコード：26305）。再試行してください`,
        error_26306: `メールが登録されました。<ahref = "https://account.tipard.com/login">ログインしてください</a>`,
        error_26307: `Reuqestが失敗しました（エラーコード：26307）。再試行してください`,
        error_26308: `Reuqestが失敗しました（エラーコード：26308）。再試行してください`,
        error_26401: `エラーコード：26401、再試行してください`,
        error_26402: `メールが確認されました（エラーコード：26402）。再試行してください`,
        error_26403: `Reuqestが失敗しました（エラーコード：26403）。再試行してください`,
        error_26404: `リクエストが失敗しました（エラーコード：26404）。再試行してください`,
        error_26501: `エラーコード：26501、お問い合わせください`,
        error_26502: `エラーコード：26502、お問い合わせください`,
        error_26503: `メールフォーマットエラー（エラーコード：26503）。もう一度入力してください`,
        error_26504: `Reuqestが失敗しました（エラーコード：26504）。再試行してください`,
        error_26505: `メールはまだ登録されていません。<ahref = "https://account.tipard.com/register">最初に登録</a>してください`,
        error_26506: `メールが確認されました。`,
        error_26507: `Reuqestが失敗しました（エラーコード：26507）。再試行してください`,
        error_26508: `検証が失敗しました（エラーコード：26508）、再試行してください`,
        error_26509: `リクエストに失敗しました（エラーコード：26509）。再試行してください`,
        error_26510: `エラーコード：26510、お問い合わせください`,
        error_26601: `エラーコード：26601、お問い合わせください`,
        error_26602: `エラーコード：26602、お問い合わせください`,
        error_26603: `Reuqestが失敗しました（エラーコード：26603）。再試行してください`,
        error_26604: `エラーコード：26604、お問い合わせください`,
        error_26605: `エラーコード：26605、お問い合わせください`,
        error_26701: `エラーコード：26701、お問い合わせください`,
        error_26702: `Reuqestが失敗しました（エラーコード：26702）。再試行してください`,
        error_26703: `エラーコード：26703、お問い合わせください`,
        error_26704: `エラーコード：26704、お問い合わせください`,
        error_26705: `ログインを待ちます（エラーコード：26705）。再試行してください`,
        no_cookie: `ブラウザで[すべてのCookieをブロックする]機能をオンにしているため、ログインできません。[設定]に移動して、[すべてのCookieを許可する]チェックボックスをオンにしてください。`,
        error_26801: `エラーコード：26801、お問い合わせください`,
        error_26802: `エラーコード：26802、お問い合わせください`,
        error_26803: `リクエストが失敗しました（エラーコード：26803）。再試行してください`,
        error_26804: `リクエストが失敗しました（エラーコード：26804）。再試行してください`,
        error_order: `リクエストに失敗しました（エラーコード：27098）。もう一度やり直してください。`,
        error_order1: `注文クエリが不完全です（エラーコード：`,
        error_order2: `）、更新してもう一度お試しください。`,
        modify_email_title: `メールを変更する`,
        modify_email_info: `変更したメールを使用して、アカウントにログインできます。`,
        images_per: `あたりの画像`,
        error_26101: `エラー：26101。お問い合わせください`,
        error_26102: `エラー：26102。お問い合わせください`,
        error_26103: `リクエストが失敗しました（エラーコード：26103）。もう一度お試しください`,
        error_26104: `エラーコード：26104、plesase再試行してください`,
        error_26105: `エラーコード：26105、もう一度やり直してください`,
        error_26106: `削除に失敗しました（エラーコード：26106）。もう一度お試しください`,
        error_26201: `エラー：26201。お問い合わせください`,
        error_26202: `リクエストが失敗しました（エラーコード：26202）。もう一度お試しください`,
        error_26001: `エラー：26001。お問い合わせください`,
        error_26002: `エラー：26002。お問い合わせください`,
        error_26003: `エラー：26003。お問い合わせください`,
        error_26004: `エラー：26004。お問い合わせください`,
        error_26005: `リクエストが失敗しました（エラーコード：26005）。もう一度お試しください`,
        error_26006: `エラーコード：26006、もう一度やり直してください`,
        error_26008: `エラー：26008。お問い合わせください`,
        go_to_the_home_page: `ホームページに移動します`,
        error_27101: `リクエストが失敗しました（エラーコード：27101）。 istを再試行してください`,
        error_27201: `エラーコード：27201、お問い合わせください`,
        error_27202: `エラーコード：27202、再試行してください`,
        error_27203: `リクエストが失敗しました（エラーコード：27203）。再試行してください`,
        error_27204: `無効なコード（エラーコード：27204）。`,
        error_27205: `リクエストが失敗しました（エラーコード：27205）。再試行してください`,
        error_27206: `リクエストが失敗しました（エラーコード：27206）。再試行してください`,
        error_27207: `リクエストが失敗しました（エラーコード：27207）。再試行してください`,
        no_history_found: `あなたはどんな道具も使ったことがありません！<a href="/">更新</a>または<a href="https://jp.tipard.com">公式ウェブサイト</a>にアクセスしてください。`,
        error_25301: `エラー：25301。お問い合わせください`,
        error_25302: `エラー：25302。お問い合わせください`,
        error_25303: `リクエストが失敗しました（エラーコード：25303）。もう一度お試しください`,
        error_25304: `リクエストが失敗しました（エラーコード：25304）。もう一度お試しください`,
        error_25305: `リクエストが失敗しました（エラーコード：25305）。もう一度お試しください`,
        error_25306: `リクエストが失敗しました（エラーコード：25306）。もう一度お試しください`,
        image_upscaler_p: `透かしなし画像ダウンロードのメリット:`,
        Available_for: `できることは下記の通り：`,
        credit_per: `HD画像を1枚保存するには%sクレジットずつかかります`,
        still_valid: `購入したプランは依然として有効である`,
        credit: `クレジット`,
        pc_3rd_info: `登録済み。ソフト内で継続使用してください。`,
        use_online: `オンラインサービスを利用します`,
        use_download: `デスクトップアプリケーションを利用します`,
        use_immediately: `今すぐ利用`,
        Use_in_browser: `ブラウザで利用`,
        win_desktop: `Windows`,
        Mac_desktop: `Mac`,
        credits_per: `{%}クレジット/月`,
        expire: `有効期限`,
        viewDetails: `詳細を見る`,
        viewHistory: `履歴特典を見る>>`,
        viewDetailsInfo: `説明：有効期間満了後7日以内に更新された場合、未使用の特典は引き続きご利用いただけます。また、有効期限は自動的に新しいサブスクリプションの有効期限に更新されます。有効期限後7日を経過しても新規のご契約がない場合、失効した特典はすべて消去されます。`,
        "connect_account": "アカウントにメールアドレスをバインドする",
        "connect_account_info": "バインド後、このメールアドレスでログインできます。",
        "connect_now": "今すぐバインド",
        "no_email_bind": "メールアドレスをバインドしない",
        "bind_email": "メールアドレスをバインドする",
        "connect_your_email_placeholder": "メールアドレスを入力してください。",
        "bind_an_email": "メールアドレスをバインド",
        "bind_info": "ログインに成功しました。特典を有効にするには、アカウントにメールアドレスをバインドしてください。",
        "bind_later": "後でバインド",
        "footer": "著作権©2024 Tipardスタジオ。 全著作権所有。",
        "overtime": "エラーコード: {%}、再試行してください",
        "hi": "こんにちは、%s!",
        "Personal_Information": "個人情報",
        "Access": "アクセス",
        "Subscription_Plan": "(サブス​​クリプション プラン)",
        "No_orders": "注文が見つかりません。",
        "No_data": "データなし",
        "Featured_Products": "おすすめ製品",
        "More_Products": "その他の製品",
        "Free_Download": "無料ダウンロード",
        "Get_Started": "開始",
        "Subscribe": "購読",
        "Verified": "確認済み",
        "back_to_account_center": "アカウントセンターに戻る",
        "success": "成功しました!",
        "successfully": "アカウントの登録が完了しました。",
        "Continue": "続行",
        "Already": "すでにアカウントをお持ちですか?",
        "loading_verification": "検証状況を確認しています...",
        "email_no_verification": "登録されたメールアドレスは認証されていません。上記の手順に従って確認し、もう一度「完了」ボタンをクリックして登録してください。",
        "error_20505": "メールがリンクされました",
        "error_20506": "エラー: 20506。お問い合わせください",
        "error_28401": "メールアドレスを入力してください",
        "error_28402": "メールの形式が正しくありません",
        "error_28403": "エラー: 28403。お問い合わせください",
        "error_28404": "メールが登録されました",
        "error_28405": "エラー: 28405。お問い合わせください",
        "error_28406": "メールがリンクされました",
        "error_28407": "エラー: 28407。お問い合わせください",
        "error_28408": "メールを送信できませんでした",
        "error_28601": "メールアドレスを入力してください",
        "error_28602": "認証コードを入力してください",
        "error_28603": "メールの形式が正しくありません",
        "error_28604": "エラー: 28604。お問い合わせください",
        "error_28605": "認証コードが正しくありません",
        "error_28606": "エラー: 28606。お問い合わせください",
        "error_28607": "メールが登録されました",
        "error_28608": "アカウントにバインドされたメールがありません",
        "error_28609": "メールが認証されていません",
        "error_28610": "エラー: 28610。お問い合わせください",
        "error_28611": "メールはリンクされています",
        "error_28612": "エラー: 28612。お問い合わせください",
        "unbind_email_btn": "メールのリンクを解除する",
        "binding_exchange": "メールをバインドする",
        "Subscription_Status": "サブスクリプションの状態",
        "mask_unbind_info_1": "このアカウントから {%} のリンクを解除してもよろしいですか? リンクを解除すると、このメールの特典を確認できなくなります",
        "mask_unbind_info_2": "この登録コードでアクティブ化されたすべてのPCのバインドを解除してもよろしいですか? <a href=\"https://ja.tipard.com/faq.html#unbind\">デバイスの変更の詳細については、こちらをご覧ください。</a>",
        "error_dc_link": "エラーです。お問い合わせください",
        "vcu_state": "無料版の特典:",
        "monthly_subscription": "1か月ライセンス",
        "year_subscription": "1年間ライセンス",
        "to": "から",
        "lifetime_subscription": "永久ライセンス",
        "unbind": "確認",
        "unbind_success": "正常に解除されました",
        "error_28401": "メールアドレスを入力してください",
        "error_28702": "エラー: 28702。お問い合わせください",
        "error_28703": "認証コードが正しくありません",
        "error_28704": "エラー: 28704。お問い合わせください",
        "error_28705": "メールがリンクされていません",
        "error_28706": "認証コードを入力してください",
        "error_28707": "リンク解除に失敗しました",
        "error_28901": "エラー: 28901。お問い合わせください",
        "error_28902": "エラー: 28902。お問い合わせください",
        "error_28903": "エラー: 28903。お問い合わせください",
        "error_28904": "エラー: 28904。お問い合わせください",
        "error_28905": "エラー: 28905。お問い合わせください",
        "error_28906": "エラー: 28906。お問い合わせください",
        "error_28907": "登録コードが無効です",
        "error_28908": "エラー: 28908。お問い合わせください",
        "error_28909": "現在、アンバインドクールダウン期間中です。アンバインドできるのは月に1回のみです。",
        "error_289010": "エラー: 289010。お問い合わせください",
        "error_289011": "エラー: 289011。お問い合わせください",
        "error_289012": "エラー: 289012。お問い合わせください",
        "activated_computer": "%s/%s台のパソコンで有効化されました",
        "bind_email_info": "次のメールの特典は、このVIPアカウントにバインドされ、使用されます。",
        "error_26309": "エラー: 26309。お問い合わせください",
        "error_26310": "紹介コードが無効です",
        "error_26311": "エラー: 26311。お問い合わせください",
        "error_26312": "紹介コードが無効です",
        "error_26313": "エラー: 26313。お問い合わせください",
        "error_26314": "メールがリンクされました",
        "activate_unbinding": "デバイスを変更",
        "copy_registration_code": "登録コードをコピー",
        "show_registration_code": "登録コードを表示",
        "hide_registration_code": "登録コードを非表示",
        "other_binding_email": "その他のバインドメールアドレス",
        "home": "家",
        "points_balance": "ポイント残高:",
        "earn_now": "今すぐ稼ぐ >>",
        "earn_more": "より多くの無料ポイントを獲得し、より多くのギフトと交換しましょう",
        "earn_or_redeem_points": "ポイントを獲得/交換",
        "unbind_email_info": "現在のアカウントはメールアドレスに関連付けられています",
        "bind_email_title": "メールアドレスの購入にさらに注文を関連付けたい場合は、下の「メールの関連付け」ボタンをクリックして関連付けてください。",
        "bind_email_tip": "<span>ヒント:</span> 現在、電子メール登録コード注文の購入のみがサポートされています。",
        "email_association": "電子メールの関連付け",
        "dissociation": "解離",
        "back": "戻る",
        "activate_benefits": "認証コードを取得するために関連付ける必要がある購入メールを入力してください。メールに記載されている認証コードを認証コード入力ボックスに入力し、「有効化を確認」ボタンをクリックして特典を有効にしてください。",
        "e_mail": "メールアドレス:",
        "verification_code": "検証コード：",
        "obtain_verification_code": "認証コードを取得する",
        "verification_association": "関連付けを確認する",
        "current_password": "現在のパスワード：",
        "account_center": "アカウントセンター",
        "new_password": "新しいパスワード：",
        "confirm_password": "新しいパスワードを確認:",
        "Send": "送信",
        "history_files": "履歴ファイル",
        "delete_account_info_1": "1. Tipard アカウントを削除すると、元に戻すことはできません。写真や設定など、すべてのデータが失われます。Tipard アカウントを削除してもよろしいですか?",
        "delete_account_info_2": "2. Tipard アカウントを削除する場合は、「送信」ボタンをクリックして、送信されたメールに記載されている確認コードを入力してください。",
        "best_deals": "ベストディール",
        "close": "近い",
        "product":"製品："
    },
    nl: {
        overtime_1: `Foutcode:`,
        overtime_2: `, probeer het alstublieft opnieuw`,
        isnetwork: `Internetfout. Controleer en probeer het opnieuw`,
        email_placeholder: `E-mail`,
        email_empty: `Voer e-mail in`,
        email_not_valid: `E-mail is niet geldig`,
        email_not_valid_1: `Voer uw e-mailadres in`,
        email_not_valid_2: `E-mail is niet geldig, gebruik een ander adres.`,
        email_not_valid_3: `Geen e-mailinvoer`,
        password_placeholder: `Wachtwoord`,
        password_empty: `Voer wachtwoord in`,
        password_not_valid: `Onjuist account of wachtwoord`,
        password_not_valid_1: `Wachtwoord van meer dan 8 tekens is vereist`,
        password_not_valid_2: `Maak een wachtwoord aan`,
        password_placeholder_1: `Maak je wachtwoord aan`,
        password_placeholder_2: `Bevestig uw wachtwoord`,
        password_placeholder_3: `Nieuw wachtwoord aanmaken`,
        password_placeholder_4: `Bevestig nieuw wachtwoord`,
        password_placeholder_5: `oud wachtwoord invoeren`,
        copy_password_empty: `Bevestig het wachtwoord a.u.b.`,
        copy_password_not_valid: `bevestig alstublieft uw wachtwoord`,
        copy_passwords_inconsistent: `Uw wachtwoordbevestiging komt niet overeen`,
        code_empty: `Voer de verificatiecode in`,
        code_not_valid: `Ongeldige verificatie code`,
        code_placeholder: `Verificatie code`,
        not_received_code: `Als uw mailbox de verificatiecode lange tijd niet heeft ontvangen, vraag dan de verificatiecode opnieuw aan.`,
        get_first_code: `Vraag eerst de verificatiecode aan.`,
        last_name_placeholder: `Gelieve uw achternaam in te voeren`,
        first_name_placeholder: `Gelieve uw voornaam in te vullen`,
        address_placeholder: `Voer uw adres in`,
        no_code_text: `We hebben een verificatiecode gestuurd. Voer uw code in. <span class='tips'>Geen code ontvangen?`,
        no_code_text_1: `1.Zorg ervoor dat het e-mailadres geldig is en dat het e-mails kan ontvangen.`,
        no_code_text_2: `2.Omdat de e-mail automatisch door het systeem wordt verzonden, kan deze worden gemarkeerd als spam of ongewenste e-mail. Controleer of de e-mail zich in de Prullenbak bevindt.`,
        no_code_text_3: `3.Kan je je probleem niet oplossen?`,
        no_code_text_3_span: `Klik dan hier om contact met ons op te nemen.`,
        order_no: `U heeft geen producten gekocht. Voor vragen kunt u <a href="https://nl.tipard.com/store.html">Nu winkelen</a> Of <a href="https://nl.tipard.com/contact.html"> Neem contact met ons op</a>`,
        error_24901: `Aan de huidige account is geen e-mail gekoppeld en kan de bestellingen niet vinden. Sluit een e-mail aan.`,
        user_guide: `Gebruikershandleiding>>`,
        download: `Downloaden`,
        order_number: `Bestelnummer`,
        Refund: `Terugbetaling`,
        Disabled: `Gehandicapt`,
        Normal: `normaal`,
        Modify: `Aanpassen`,
        Modify_1: `Wijzigen>>`,
        Connect: `Aansluiten`,
        unlink_success: `Ontkoppel succesvol`,
        connect_success: `Succesvol verbinden`,
        feedback_title: `Bedankt voor je feedback. Laat uw probleem achter en wij zullen binnen 24 uur op u reageren.`,
        feedback_thank_you: `Bedankt!<br />Uw feedback is succesvol verzonden.`,
        feedback_email: `Vul je email hier in!`,
        feedback_content: `Laat hier een probleem of suggestie achter.`,
        feedback_submit: `Indienen`,
        form_contents: `U heeft geen omschrijving ingevuld. Vul het in en verzend het opnieuw.`,
        old_password: `Voer a.u.b. oud wachtwoord in`,
        new_password: `Maak een nieuw wachtwoord aan`,
        old_new_password: `Het nieuwe wachtwoord mag niet hetzelfde zijn als het oude`,
        incorrect_password: `Incorrect wachtwoord`,
        delete_no: `Verwijder nu`,
        Caps: `Caps Lock staat aan`,
        Get: `Krijgen`,
        Done: `Gedaan`,
        error_20001: `Fout: 20001. Log opnieuw in.`,
        error_20002: `Fout: 20002. Log opnieuw in.`,
        error_20003: `Fout: 20003. Log opnieuw in.`,
        error_20004: `Verzoek mislukt (foutcode: 20004). Probeer het opnieuw.`,
        error_20005: `Inlogsessie is verlopen (Fout: 20005). Log alsjeblieft nogmaals in.`,
        error_20006: `Verzoek mislukt (foutcode: 20006). Probeer het opnieuw.`,
        error_20007: `Inlogsessie is verlopen (Fout: 20007). Log alsjeblieft nogmaals in.`,
        error_20008: `Inlogsessie is verlopen (Fout: 20008). Log alsjeblieft nogmaals in.`,
        error_20009: `Inlogsessie is verlopen (Fout: 20009). Log alsjeblieft nogmaals in.`,
        error_20101: `Voer uw e-mailadres in (foutcode: 20101)`,
        error_20102: `E-mail is niet geldig (foutcode: 20102)`,
        error_20103: `Verzoek mislukt (foutcode: 20103). Probeer het alstublieft nog een keer`,
        error_20104: `E-mail is al in gebruik, <a href="https://account.tipard.com/login">log in</a> of registreer met een nieuwe`,
        error_20105: `Verzoek mislukt (foutcode: 20105). Probeer het alstublieft nog een keer`,
        error_20106: `Kan e-mail niet verzenden, probeer het opnieuw`,
        error_20201: `Voer uw e-mailadres in (foutcode: 20201)`,
        error_20202: `Voer uw wachtwoord in (foutcode: 20202)`,
        error_20203: `Voer de verificatiecode in (foutcode: 20203)`,
        error_20204: `E-mail is niet geldig (foutcode: 20204)`,
        error_20205: `Wachtwoord van meer dan 8 tekens is vereist (foutcode: 20205)`,
        error_20206: `Verzoek mislukt (foutcode: 20206). Probeer het alstublieft nog een keer`,
        error_20207: `Ongeldige verificatie code`,
        error_20208: `Verzoek mislukt (foutcode: 20208). Probeer het alstublieft nog een keer`,
        error_20209: `Verzoek mislukt (foutcode: 20209). Probeer het alstublieft nog een keer`,
        error_20301: `Voer uw e-mailadres in (foutcode: 20301)`,
        error_20302: `Fout: 20302. Neem contact met ons op`,
        error_20303: `E-mail is niet geldig (foutcode: 20303)`,
        error_20304: `Verzoek mislukt (foutcode: 20304). Probeer het alstublieft nog een keer`,
        error_20305: `Account bestaat niet. Voer eerst opnieuw in of <a href="https://account.tipard.com/register">Creëer het</a>.`,
        error_20306: `Nog geen wachtwoord. Gebruik <a href="https://account.tipard.com/passwordless-login">Inloggen zonder wachtwoord</a> of <a href="create-password">stel een wachtwoord in</a> en log in.`,
        error_20308: `Verzoek mislukt (foutcode: 20308). Probeer het alstublieft nog een keer`,
        error_20401: `Uitloggen mislukt (foutcode: 20401). Probeer het alstublieft nog een keer`,
        error_20501: `Voer uw e-mailadres in (foutcode: 20501)`,
        error_20502: `E-mail is niet geldig (foutcode: 20502)`,
        error_20503: `Verzoek mislukt (foutcode: 20503). Probeer het alstublieft nog een keer`,
        error_20504: `Kan e-mail niet verzenden. Probeer het opnieuw.`,
        error_20601: `Voer uw e-mailadres in (foutcode: 20601)`,
        error_20602: `Voer de verificatiecode in (foutcode: 20602)`,
        error_20603: `E-mail is niet geldig (foutcode: 20603)`,
        error_20604: `Verzoek mislukt (foutcode: 20604). Probeer het alstublieft nog een keer`,
        error_20606: `Verzoek mislukt (foutcode: 20606). Probeer het alstublieft nog een keer`,
        error_20607: `Verzoek mislukt (foutcode: 20607). Probeer het alstublieft nog een keer`,
        error_20608: `Verzoek mislukt (foutcode: 20608). Probeer het alstublieft nog een keer`,
        error_20701: `Voer uw e-mailadres in (foutcode: 20701)`,
        error_20702: `E-mail is niet geldig (foutcode: 20702)`,
        error_20703: `Verzoek mislukt (foutcode: 20703). Probeer het alstublieft nog een keer`,
        error_20704: `Account bestaat niet. Voer eerst opnieuw in of <a href="https://account.tipard.com/register">Creëer het</a>.`,
        error_20705: `Verzoek mislukt (foutcode: 20705). Probeer het alstublieft nog een keer`,
        error_20706: `Kan e-mail niet verzenden. Probeer het alstublieft nog een keer`,
        error_20801: `Voer uw e-mailadres in (foutcode: 20801)`,
        error_20802: `Fout: 20802. Neem contact met ons op`,
        error_20803: `Voer de verificatiecode in (foutcode: 20803)`,
        error_20804: `E-mail is niet geldig (foutcode: 20804)`,
        error_20805: `Wachtwoord van meer dan 8 tekens is vereist (foutcode: 20805)`,
        error_20806: `Verzoek mislukt (foutcode: 20806). Probeer het alstublieft nog een keer`,
        error_20808: `Verzoek mislukt (foutcode: 20808). Probeer het alstublieft nog een keer`,
        error_20901: `Verzoek mislukt (foutcode: 20901). Probeer het alstublieft nog een keer`,
        error_20902: `Verzoek mislukt (foutcode: 20902). Probeer het alstublieft nog een keer`,
        error_21000: `Wijzigingen worden opgeslagen`,
        error_21001: `Er wordt geen informatie ingediend (foutcode: 21001)`,
        error_21002: `Verzoek mislukt (foutcode: 21002). Probeer het alstublieft nog een keer`,
        error_21101: `Voer uw e-mailadres in (foutcode: 21101)`,
        error_21102: `E-mail is niet geldig (foutcode: 21102)`,
        error_21103: `Verzoek mislukt (foutcode: 21103), probeer het opnieuw`,
        error_21104: `E-mail is al verbonden, gebruik een nieuwe`,
        error_21105: `Verzoek mislukt (foutcode: 21105), probeer het opnieuw`,
        error_21106: `Kan e-mail niet verzenden. Probeer het alstublieft nog een keer`,
        error_21201: `Voer uw e-mailadres in (foutcode: 21201)`,
        error_21202: `Voer de verificatiecode in (foutcode: 21202)`,
        error_21203: `E-mail is niet geldig (foutcode: 21203)`,
        error_21204: `Fout: 21204. Neem contact met ons op`,
        error_21205: `Fout: 21205. Neem contact met ons op`,
        error_21206: `Wachtwoord van meer dan 8 tekens is vereist (foutcode: 21206)`,
        error_21207: `Verzoek mislukt (foutcode: 21207). Probeer het alstublieft nog een keer`,
        error_21209: `Verzoek mislukt (foutcode: 21209). Probeer het alstublieft nog een keer`,
        error_21301: `Gelieve het oude wachtwoord in te voeren (foutcode: 21301)`,
        error_21302: `Maak een nieuw wachtwoord aan (foutcode: 21302)`,
        error_21303: `Het nieuwe wachtwoord mag niet hetzelfde zijn als het oude. (Fout: 21303)`,
        error_21304: `Wachtwoord van meer dan 8 tekens is vereist (foutcode: 21304)`,
        error_21306: `Verzoek mislukt (foutcode: 21306). Probeer het alstublieft nog een keer`,
        error_21402: `Verzoek mislukt (foutcode: 21402). Probeer het alstublieft nog een keer`,
        error_21403: `Kan verificatiecode niet verzenden. Stuur het alstublieft opnieuw`,
        error_21500: `Het account is verwijderd`,
        error_21501: `Voer de verificatiecode in (foutcode: 21501)`,
        error_21502: `Inlogsessie is verlopen (Fout: 21502). Log alsjeblieft nogmaals in.`,
        error_21503: `Verzoek mislukt (foutcode: 21503). Probeer het alstublieft nog een keer`,
        error_21505: `Verzoek mislukt (foutcode: 21505), probeer het opnieuw`,
        error_21601: `Fout: 20601. Neem contact met ons op`,
        error_21602: `Ongeldige verificatie (Fout: 20602). Probeer het opnieuw.`,
        error_21603: `Fout: 20603. Probeer het opnieuw`,
        error_21604: `Verzoek mislukt (foutcode: 21604). Probeer het alstublieft nog een keer`,
        error_21606: `Verzoek mislukt (foutcode: 21606). Probeer het alstublieft nog een keer`,
        error_21611: `Verzoek mislukt (foutcode: 21611). Probeer het alstublieft nog een keer`,
        error_21801: `Fout: 21801. Neem contact met ons op`,
        error_21802: `Verzoek mislukt (Fout: 21802). Probeer het alstublieft nog een keer`,
        error_21803: `Fout: 21803. Probeer het opnieuw`,
        error_21804: `Verzoek mislukt (foutcode: 21804). Probeer het alstublieft nog een keer`,
        error_21806: `Fout: 21806. Probeer het opnieuw`,
        error_21807: `Fout: 21807. Neem contact met ons op`,
        error_21808: `Fout: 21808. Neem contact met ons op`,
        error_21809: `Fout: 21809. Neem contact met ons op`,
        error_21810: `Fout: 21810. Neem contact met ons op`,
        error_21811: `Fout: 21811. Neem contact met ons op`,
        error_21812: `Fout: 21812. Neem contact met ons op`,
        error_21813: `Verzoek mislukt (foutcode: 21813). Probeer het alstublieft nog een keer`,
        error_21814: `Fout: 21814. Neem contact met ons op`,
        error_21815: `Verzoek mislukt (foutcode: 21815). Probeer het alstublieft nog een keer`,
        error_21816: `Fout: 21816. Neem contact met ons op`,
        error_21817: `Fout: 21817. Neem contact met ons op`,
        error_21818: `Fout: 21818. Neem contact met ons op`,
        error_21819: `Verzoek mislukt (foutcode: 21819). Probeer het alstublieft nog een keer`,
        error_21820: `Fout: 21820. Neem contact met ons op`,
        error_21821: `Fout: 21821. Neem contact met ons op`,
        error_21822: `Fout: 21822. Neem contact met ons op`,
        error_21823: `Verzoek mislukt (foutcode: 21823). Probeer het alstublieft nog een keer`,
        error_21824: `Verzoek mislukt (foutcode: 21824). Probeer het alstublieft nog een keer`,
        error_21825: `Verzoek mislukt (foutcode: 21825). Probeer het alstublieft nog een keer`,
        error_21826: `Verzoek mislukt (foutcode: 21826). Probeer het alstublieft nog een keer`,
        error_21901: `Fout: 21901. Neem contact met ons op`,
        error_21902: `Verzoek mislukt (foutcode: 21902). Probeer het alstublieft nog een keer`,
        error_21903: `De accountstatus is gewijzigd (foutcode: 21903), ververs de pagina en probeer het opnieuw`,
        error_21904: `Fout: 21904. Probeer het opnieuw`,
        error_21905: `Fout: 21905. Probeer het opnieuw`,
        error_21906: `Verzoek mislukt (foutcode: 21906). Probeer het alstublieft nog een keer`,
        error_21907: `Google-account is gekoppeld aan een ander account`,
        error_21908: `Verzoek mislukt (foutcode: 21908). Probeer het alstublieft nog een keer`,
        error_22001: `Verzoek mislukt (foutcode: 22001). Probeer het alstublieft nog een keer`,
        error_22002: `Ontkoppelen mislukt zonder extra aanmelding`,
        error_22003: `Verzoek mislukt (foutcode: 22003). Probeer het alstublieft nog een keer`,
        error_22101: `Fout: 22101. Neem contact met ons op`,
        error_22102: `De accountstatus is gewijzigd (foutcode: 22102), ververs de pagina en probeer het opnieuw`,
        error_22103: `Verzoek mislukt (foutcode: 22103). Probeer het alstublieft nog een keer`,
        error_22104: `De accountstatus is gewijzigd (foutcode: 22104), ververs de pagina en probeer het opnieuw`,
        error_22105: `Fout: 22105. Probeer het opnieuw`,
        error_22106: `Fout: 22106. Probeer het opnieuw`,
        error_22107: `Fout: 22107. Neem contact met ons op`,
        error_22108: `Verzoek mislukt (foutcode: 22108). Probeer het alstublieft nog een keer`,
        error_22201: `Fout: 22201. Neem contact met ons op`,
        error_22202: `Ongeldige verificatie (Fout: 22202). Probeer het opnieuw.`,
        error_22203: `Fout: 22203. Probeer het opnieuw"`,
        error_22204: `Verzoek mislukt (foutcode: 22204). Probeer het alstublieft nog een keer`,
        error_22206: `Verzoek mislukt (foutcode: 22206). Probeer het alstublieft nog een keer`,
        error_22401: `Fout: 22401. Neem contact met ons op`,
        error_22402: `Ongeldige verificatie (Fout: 22402). Probeer het opnieuw.`,
        error_22403: `Fout: 22403. Probeer het opnieuw`,
        error_22404: `Verzoek mislukt (foutcode: 22404). Probeer het alstublieft nog een keer`,
        error_22405: `Facebook-account is gekoppeld aan andere e-mail`,
        error_22406: `Fout: 22406. Probeer het opnieuw`,
        error_22407: `Fout: 22407. Neem contact met ons op`,
        error_22408: `Fout: 22408. Neem contact met ons op`,
        error_22409: `Fout: 22409. Neem contact met ons op`,
        error_22410: `Fout: 224010. Neem contact met ons op`,
        error_22411: `Fout: 224011. Neem contact met ons op`,
        error_22412: `Fout: 224012. Neem contact met ons op`,
        error_22413: `Fout: 22413. Neem contact met ons op`,
        error_22414: `Verzoek mislukt (foutcode: 22414). Probeer het alstublieft nog een keer`,
        error_22415: `Fout: 22415. Neem contact met ons op`,
        error_22416: `Fout: 22416. Neem contact met ons op`,
        error_22417: `Fout: 22417. Neem contact met ons op`,
        error_22418: `Verzoek mislukt (foutcode: 22418). Probeer het alstublieft nog een keer`,
        error_22419: `Fout: 22419. Neem contact met ons op`,
        error_22420: `Fout: 22420. Neem contact met ons op`,
        error_22421: `Fout: 22421. Neem contact met ons op`,
        error_22422: `Verzoek mislukt (foutcode: 22422). Probeer het alstublieft nog een keer`,
        error_22423: `Verzoek mislukt (foutcode: 22423). Probeer het alstublieft nog een keer`,
        error_22424: `Verzoek mislukt (foutcode: 22424). Probeer het alstublieft nog een keer`,
        error_22425: `Verzoek mislukt (foutcode: 22425). Probeer het alstublieft nog een keer`,
        error_20098: `Foutcode: 20098. Als u browst in de privémodus, schakelt u over naar de normale modus en probeert u het opnieuw.`,
        error_22298: `Verzoek voor Google is mislukt (Fout: 22298). Probeer het opnieuw.`,
        error_22498: `Verzoek om Facebook is mislukt (Fout: 22498). Probeer het opnieuw.`,
        error_24902: `Verzoek mislukt (foutcode: 24902). Probeer het alstublieft nog een keer`,
        error_24903: `Verzoek mislukt (foutcode: 24903). Probeer het alstublieft nog een keer`,
        error_24904: `Verzoek mislukt (foutcode: 24904). Probeer het alstublieft nog een keer`,
        error_24905: `Verzoek mislukt (foutcode: 24905). Probeer het alstublieft nog een keer`,
        login_title: `Log in op Tipard`,
        password_placeholder: `Wachtwoord`,
        log_in: `Log in`,
        no_account: `Geen account?`,
        create_it: `Creëer het`,
        or_log_in_with: `Of log in met`,
        passwordless_login: `Wachtwoordloos inloggen`,
        log_in_done: `Inloggen Gereed`,
        three_rd_account_connect_info: `Gefeliciteerd! U bent succesvol ingelogd. Nu kunt u één e-mailaccount koppelen dat wordt gebruikt om in de toekomst in te loggen.`,
        see_my_account: `Zie mijn account`,
        three_rd_login_merge_account: `Het e-mailadres van het account van de derde partij is aangemeld, wil je direct verbinding maken en inloggen met dit e-mailadres?`,
        connect_log_in: `Verbinding maken en inloggen`,
        create_an_account: `Account aanmaken`,
        back_to_log_in: `Terug naar Inloggen`,
        create_password: `Maak een wachtwoord`,
        create_now: `Nu maken`,
        password_login_subtitle: `Wachtwoordloos inloggen met e-mail`,
        account_login: `Account login`,
        rights: `Door dit account aan te maken, gaat u akkoord met de <a href="https://nl.tipard.com/terms.html">Servicevoorwaarden</a> en <a href="https://nl.tipard.com/policy.html">Privacybeleid</a>`,
        passwordless_login_done: `Inloggen zonder wachtwoord voltooid`,
        passwordless_login_info: `Gefeliciteerd, u heeft succesvol inloggen zonder wachtwoord voltooid. U kunt voor dit account een wachtwoord aanmaken en in de toekomst inloggen met het account en wachtwoord. <a href="/create-password" style="display: initial;">Nu maken</a>`,
        sign_up: `Inschrijven`,
        register_info: `Maak een account aan`,
        reset_now: `Nu resetten`,
        forgot_password: `Wachtwoord vergeten`,
        reset_password_subtitle: `Gebruik het e-mailadres van uw account om het wachtwoord opnieuw in te stellen`,
        plan_products: `Plan & Producten`,
        nick_name: `gebruikersnaam`,
        email: `E-mail`,
        my_products: `Mijn producten`,
        my_orders: `Mijn bestellingen`,
        unlink: `Ontkoppelen`,
        link: `Koppeling`,
        connected_accounts: `Verbonden accounts`,
        last_name: `Achternaam`,
        first_name: `Voornaam`,
        Gender: `Geslacht`,
        Birth: `Geboorte`,
        Month: `Maand`,
        Year: `Jaar`,
        Country_Region: `Land/Regio`,
        Address: `Adres`,
        Save: `Opslaan`,
        Date: `Datum`,
        Male: `Mannelijk`,
        Female: `Vrouwelijk`,
        Unspecified: `Niet gespecificeerd`,
        Security: `Veiligheid`,
        change_password: `Verander wachtwoord`,
        change_now: `Verander nu`,
        connect_email: `E-mail koppelen`,
        delete_account: `Account verwijderen`,
        delete_account_info: `Wanneer uw account wordt verwijderd, worden alle gegevens in Plan & Producten die aan uw account zijn gekoppeld, permanent verwijderd en kunt u deze mogelijk niet meer herstellen. We raden u aan om voorzichtig te werk te gaan.`,
        Delete: `Verwijderen`,
        Logout: `Uitloggen`,
        my_profile: `Mijn profiel`,
        guides_faqs: `Gidsen en veelgestelde vragen`,
        More: `Meer`,
        guides: `Gidsen`,
        register: `register`,
        hot_faq: `Veelgestelde vragen`,
        Contents: `Inhoud:`,
        contact_us: `Neem contact met ons op>>`,
        plan: `Plan`,
        unregistered: `niet geregistreerd`,
        buy_more: `Koop meer`,
        buy_again: `Opnieuw kopen`,
        buy_now: `Nu kopen`,
        free_no_limit: `Gratis & Geen limiet`,
        expired: `Verlopen`,
        lifetime: `Levenslang`,
        remain: `Blijven`,
        day_s: `Dag(en)`,
        day: `Dag`,
        error_24801: `Verzoek mislukt (foutcode: 24801). Probeer het alstublieft nog een keer`,
        no_app_found: `Geen app gevonden！<a href="/">Vernieuwen</a> of <a href="https://nl.tipard.com/">Ga naar de officiële website</a>`,
        get_more: `Krijg meer >>`,
        edit_photo: `Bewerk foto`,
        select_photo: `Selecteer foto`,
        change_photo: `Verander foto`,
        cancel: `Annuleren`,
        hide_password: `Verberg wachtwoord`,
        show_password: `Laat wachtwoord zien`,
        zoom_in: `In zoomen`,
        zoom_out: `Uitzoomen`,
        rotate: `Draaien`,
        horizontal_flip: `Horizontaal omdraaien`,
        vertical_flip: `Verticale omslag`,
        country: `Land`,
        country_1: `Selecteer uw land/regio`,
        country_2: `Åaland-eiland`,
        country_3: `Afghanistan`,
        country_4: `Albanië`,
        country_5: `Algerije`,
        country_6: `Amerikaans Samoa`,
        country_7: `Andorra`,
        country_8: `Angola`,
        country_9: `Anguilla`,
        country_10: `Antarctica`,
        country_11: `Antigua en Barbuda`,
        country_12: `Argentinië`,
        country_13: `Armenië`,
        country_14: `Aruba`,
        country_15: `Australië`,
        country_16: `Oostenrijk`,
        country_17: `Azerbeidzjan`,
        country_18: `Bahrein`,
        country_19: `Bangladesh`,
        country_20: `Barbados`,
        country_21: `Wit-Rusland`,
        country_22: `België`,
        country_23: `Belize`,
        country_24: `Benin`,
        country_25: `Bermuda`,
        country_26: `Bhutan`,
        country_27: `Bolivia`,
        country_28: `Bosnië en Herzegovina`,
        country_29: `Botswana`,
        country_30: `Bouvet-eiland`,
        country_31: `Brazilië`,
        country_32: `Brits-Indisch oceaan gebied`,
        country_33: `Britse Maagdeneilanden`,
        country_34: `Brunei`,
        country_35: `Bulgarije`,
        country_36: `Burkina`,
        country_37: `Burundi`,
        country_38: `Cambodja`,
        country_39: `Kameroen`,
        country_40: `Canada`,
        country_41: `Kaapverdië`,
        country_42: `Caribisch Nederland`,
        country_43: `Kaaiman Eilanden`,
        country_44: `Centraal Afrikaanse Republiek`,
        country_45: `Tsjaad`,
        country_46: `Chili`,
        country_47: `China`,
        country_48: `Kersteiland`,
        country_49: `Cocos (Keeling) Eilanden`,
        country_50: `Colombia`,
        country_51: `Cook Eilanden`,
        country_52: `Costa Rica`,
        country_53: `"Ivoorkust"`,
        country_54: `Kroatië`,
        country_55: `Cuba`,
        country_56: `Cyprus`,
        country_57: `Tsjechië`,
        country_58: `Democratische Republiek Congo`,
        country_59: `Denemarken`,
        country_60: `Djibouti`,
        country_61: `Dominica`,
        country_62: `Dominicaanse Republiek`,
        country_63: `Ecuador`,
        country_64: `Egypte`,
        country_65: `El Salvador`,
        country_66: `Equatoriaal-Guinea`,
        country_67: `Eritrea`,
        country_68: `Estland`,
        country_69: `Ethiopië`,
        country_70: `Falkland Eilanden`,
        country_71: `Faeröer`,
        country_72: `Federale Staten van Micronesië`,
        country_73: `Fiji`,
        country_74: `Finland`,
        country_75: `Frankrijk`,
        country_76: `Frans Guyana`,
        country_77: `Frans-Polynesië`,
        country_78: `Franse zuidelijke gebieden`,
        country_79: `Gabon`,
        country_80: `Gambia`,
        country_81: `Georgië`,
        country_82: `Duitsland`,
        country_83: `Ghana`,
        country_84: `Gibraltar`,
        country_85: `Groot-Brittannië (Verenigd Koninkrijk; Engeland)`,
        country_86: `Griekenland`,
        country_87: `Groenland`,
        country_88: `Grenada`,
        country_89: `Guadeloupe`,
        country_90: `Guam`,
        country_91: `Guatemala`,
        country_92: `Guernsey`,
        country_93: `Guinea`,
        country_94: `Guinee-Bissau`,
        country_95: `Guyana`,
        country_96: `Haïti`,
        country_97: `Heard- en McDonaldeilanden`,
        country_98: `Honduras`,
        country_99: `Hongkong`,
        country_100: `Hongarije`,
        country_101: `IJsland`,
        country_102: `India`,
        country_103: `Indonesië`,
        country_104: `Iran`,
        country_105: `Irak`,
        country_106: `Ierland`,
        country_107: `eiland Man`,
        country_108: `Israël`,
        country_109: `Italië`,
        country_110: `Jamaica`,
        country_111: `Japan`,
        country_112: `Jersey`,
        country_113: `Jordanië`,
        country_114: `Kazachstan`,
        country_115: `Kenia`,
        country_116: `Kiribati`,
        country_117: `Koeweit`,
        country_118: `Kirgizië`,
        country_119: `Laos`,
        country_120: `Letland`,
        country_121: `Libanon`,
        country_122: `Lesotho`,
        country_123: `Liberia`,
        country_124: `Libië`,
        country_125: `Liechtenstein`,
        country_126: `Litouwen`,
        country_127: `Luxemburg`,
        country_128: `Macau`,
        country_129: `Madagascar`,
        country_130: `Malawi`,
        country_131: `Maleisië`,
        country_132: `Maldiven`,
        country_133: `Mali`,
        country_134: `Malta`,
        country_135: `Marshall eilanden`,
        country_136: `Martinique`,
        country_137: `Mauritanië`,
        country_138: `Mauritius`,
        country_139: `Mayotte`,
        country_140: `Mexico`,
        country_141: `Moldavië`,
        country_142: `Monaco`,
        country_143: `Mongolië`,
        country_144: `Montenegro`,
        country_145: `Montserrat`,
        country_146: `Marokko`,
        country_147: `Mozambique`,
        country_148: `Birma (Birma)`,
        country_149: `Namibië`,
        country_150: `Nauru`,
        country_151: `Nepal`,
        country_152: `Nederland`,
        country_153: `Nieuw-Caledonië`,
        country_154: `Nieuw-Zeeland`,
        country_155: `Nicaragua`,
        country_156: `Niger`,
        country_157: `Nigeria`,
        country_158: `Niue`,
        country_159: `Norfolkeiland`,
        country_160: `Noord Korea`,
        country_161: `noordelijke Mariana eilanden`,
        country_162: `Noorwegen`,
        country_163: `Oman`,
        country_164: `Pakistan`,
        country_165: `Palau`,
        country_166: `Palestijnse grondgebieden`,
        country_167: `Panama`,
        country_168: `Papoea-Nieuw-Guinea`,
        country_169: `Paraguay`,
        country_170: `Peru`,
        country_171: `Pitcairneilanden`,
        country_172: `Polen`,
        country_173: `Portugal`,
        country_174: `Puerto Rico`,
        country_175: `Qatar`,
        country_176: `Republiek Macedonië (FYROM)`,
        country_177: `Republiek Congo`,
        country_178: `Bijeenkomst`,
        country_179: `Roemenië`,
        country_180: `Russische Federatie`,
        country_181: `Rwanda`,
        country_182: `Sint-Bartholomeus`,
        country_183: `Sint Maarten (Frankrijk)`,
        country_184: `Saint-Pierre en Miquelon`,
        country_185: `Samoa`,
        country_186: `San Marino`,
        country_187: `Sao Tomé & Principe`,
        country_188: `Saoedi-Arabië`,
        country_189: `Senegal`,
        country_190: `Servië`,
        country_191: `Seychellen`,
        country_192: `Sierra Leone`,
        country_193: `Singapore`,
        country_194: `Slowakije`,
        country_195: `Slovenië`,
        country_196: `Solomon eilanden`,
        country_197: `Somalië`,
        country_198: `Zuid-Afrika`,
        country_199: `Zuid-Georgië en de Zuid-Sandwich Isla`,
        country_200: `Zuid-Korea`,
        country_201: `Zuid Soedan`,
        country_202: `Spanje`,
        country_203: `Sri Lanka`,
        country_204: `St. Helena en afhankelijkheden`,
        country_205: `St. Kitts en Nevis`,
        country_206: `St Lucia`,
        country_207: `St. Vincent en de Grenadines`,
        country_208: `Soedan`,
        country_209: `Suriname`,
        country_210: `Swaziland`,
        country_211: `Zweden`,
        country_212: `Zwitserland`,
        country_213: `Syrië`,
        country_214: `Taiwan`,
        country_215: `Tadzjikistan`,
        country_216: `Tanzania`,
        country_217: `Sjabloon:Landgegevens SJM Svalbard`,
        country_218: `Thailand`,
        country_219: `De Bahamas`,
        country_220: `de Comoren`,
        country_221: `De Filipijnen`,
        country_222: `Oost-Timor (Oost-Timor)`,
        country_223: `Gaan`,
        country_224: `Tokelau`,
        country_225: `Tonga`,
        country_226: `Trinidad en Tobago`,
        country_227: `Tunesië`,
        country_228: `kalkoen`,
        country_229: `Turkmenistan`,
        country_230: `Turks- en Caicoseilanden`,
        country_231: `Tuvalu`,
        country_232: `Oeganda`,
        country_233: `Oekraïne`,
        country_234: `Verenigde Arabische Emiraten`,
        country_235: `Kleine afgelegen eilanden van de Verenigde Staten`,
        country_236: `Verenigde Staten van Amerika (VS)`,
        country_237: `Amerikaanse Maagdeneilanden`,
        country_238: `Uruguay`,
        country_239: `Oezbekistan`,
        country_240: `Vanuatu`,
        country_241: `Vaticaanstad (De Heilige Stoel)`,
        country_242: `Venezuela`,
        country_243: `Vietnam`,
        country_244: `Wallis en Futuna`,
        country_245: `Westelijke Sahara`,
        country_246: `Jemen`,
        country_247: `Zambia`,
        country_248: `Zimbabwe`,
        google_login: `Inloggen met Google`,
        State: `Staat`,
        Activation_code: `Activatie code`,
        Question: `Maak een lijst van alle apps die u hebt aangemeld`,
        Copy_complete: `Kopie voltooid`,
        change_password_success: `wachtwoord succesvol veranderd`,
        successful_login_title: `Succesvol ingelogd`,
        product_page: `Productpagina>>`,
        successful_login_info: `Inloggen voltooid. Sluit de huidige pagina en ga terug naar het oorspronkelijke tabblad om door te gaan met het volgende proces. De huidige pagina wordt automatisch gesloten in 5 seconden. Als automatisch sluiten of sluiten door op de knop "Gereed" te klikken niet lukt, sluit dit tabblad dan direct.`,
        successful_login_info_firefox: `Inloggen voltooid. Sluit de huidige pagina en ga terug naar het oorspronkelijke tabblad om door te gaan met het volgende proces.`,
        my_account: `Mijn rekening`,
        my_history: `Mijn geschiedenis`,
        remove_watermark: `Verwijder watermerk`,
        no_history: `Geen geschiedenis`,
        history_all: `Selecteer alles`,
        history_open: `Open`,
        history_down: `Downloaden`,
        history_delete: `Verwijderen`,
        history_clear: `Wis ongeldig`,
        images: `afbeeldingen)`,
        use_this_function: `Gebruik deze functie>>`,
        hd_downloading: `Voordelen voor het downloaden van originele HD-afbeeldingen:`,
        lifetimeRemaining: `Resterende levensduur`,
        Remaining: `Overig`,
        email_verification: `email verificatie`,
        email_verification_info: `We hebben het verificatiebericht naar uw e-mailadres <span class="email"></span> gestuurd en voltooi de verificatie. Na verificatie worden de voordelen automatisch gesynchroniseerd.`,
        wrong_email: `Verkeerd email adres?`,
        click_here_to_modify: `Klik hier om te wijzigen`,
        get_more_help: `Meer hulp krijgen?`,
        click_here: `Klik hier`,
        email_verification_info_success: `Gefeliciteerd met het verifiëren van uw e-mailaccount.`,
        email_verification_info_error: `Verificatie mislukt vanwege de verlopen link.`,
        registration_succeeded: `Registratie geslaagd`,
        registration_succeeded_info_1: `Gefeliciteerd! U bent succesvol geregistreerd. We hebben het verificatiebericht naar uw e-mailadres <span class="email"></span> gestuurd en voltooi de verificatie om gebruik te kunnen maken van de voordelen van deze e-mail.`,
        registration_succeeded_info_2: `Klik op "Gereed" om terug te keren naar de startpagina en dit product te gebruiken.`,
        registration_succeeded_info_3: `Klik op "Gereed" om de huidige pagina te sluiten en terug te keren naar de startpagina voor de volgende bewerkingen. Als het sluiten mislukt, sluit u het tabblad handmatig.`,
        verify_email: `Verifieer Email`,
        registered_email_not_verified: `Het geregistreerde e-mailadres is niet geverifieerd. Verifieer het alstublieft meteen.`,
        email_verification_time_1: `Geen verificatie-e-mail ontvangen?`,
        email_verification_time_2: `Na`,
        email_verification_time_3: `Klik hier om het opnieuw te verzenden`,
        error_26301: `Foutcode: 26301, neem contact met ons op`,
        error_26302: `Foutcode: 26302, neem contact met ons op`,
        error_26303: `E-mailformaatfout (foutcode: 26303). Voer het opnieuw in`,
        error_26304: `Wachtwoord van meer dan 8 tekens wordt aanbevolen (foutcode: 26304)`,
        error_26305: `Reuqest mislukt (foutcode: 26305). Probeer het opnieuw`,
        error_26306: `E-mail is geregistreerd, <a href="https://account.tipard.com/login">ga naar inloggen</a>`,
        error_26307: `Reuqest mislukt (foutcode: 26307). Probeer het opnieuw`,
        error_26308: `Reuqest mislukt (foutcode: 26308). Probeer het opnieuw`,
        error_26401: `Foutcode: 26401, probeer het opnieuw`,
        error_26402: `E-mail is geverifieerd (foutcode: 26402), probeer het opnieuw`,
        error_26403: `Reuqest mislukt (foutcode: 26403). Probeer het opnieuw`,
        error_26404: `Verzoek mislukt (foutcode: 26404). Probeer het opnieuw`,
        error_26501: `Foutcode: 26501, neem contact met ons op`,
        error_26502: `Foutcode: 26502, neem contact met ons op`,
        error_26503: `E-mailformaatfout (foutcode: 26503). Voer het opnieuw in`,
        error_26504: `Reuqest mislukt (foutcode: 26504). Probeer het opnieuw`,
        error_26505: `E-mail is niet geregistreerd, <a href="https://account.tipard.com/register">registreer het eerst</a>`,
        error_26506: `E-mail is geverifieerd.`,
        error_26507: `Reuqest mislukt (foutcode: 26507). Probeer het opnieuw`,
        error_26508: `Verificatie mislukt (foutcode: 26508), probeer het opnieuw`,
        error_26509: `Verzoek mislukt (foutcode: 26509), probeer het opnieuw`,
        error_26510: `Foutcode: 26510, neem contact met ons op`,
        error_26601: `Foutcode: 26601, neem contact met ons op`,
        error_26602: `Foutcode: 26602, neem contact met ons op`,
        error_26603: `Reuqest mislukt (foutcode: 26603). Probeer het opnieuw`,
        error_26604: `Foutcode: 26604, neem contact met ons op`,
        error_26605: `Foutcode: 26605, neem contact met ons op`,
        error_26701: `Foutcode: 26701, neem contact met ons op`,
        error_26702: `Reuqest mislukt (foutcode: 26702). Probeer het opnieuw`,
        error_26703: `Foutcode: 26703, neem contact met ons op`,
        error_26704: `Foutcode: 26704, neem contact met ons op`,
        error_26705: `Wacht met inloggen (foutcode: 26705). Probeer het opnieuw`,
        no_cookie: `U heeft de functie Alle cookies blokkeren in uw browser ingeschakeld, dus u kunt niet inloggen. Ga naar Instellingen om het vakje Alle cookies toestaan aan te vinken.`,
        error_26801: `Foutcode: 26801, neem contact met ons op`,
        error_26802: `Foutcode: 26802, neem contact met ons op`,
        error_26803: `Verzoek mislukt (foutcode: 26803). Probeer het opnieuw`,
        error_26804: `Verzoek mislukt (foutcode: 26804). Probeer het opnieuw`,
        error_order: `Aanvraag mislukt (foutcode: 27098), probeer het opnieuw!`,
        error_order1: `De bestellingsquery is onvolledig, foutcode:`,
        error_order2: `, ververs a.u.b. en probeer het opnieuw.`,
        modify_email_title: `E-mail wijzigen`,
        modify_email_info: `U kunt het aangepaste e-mailadres gebruiken om in te loggen op uw account.`,
        images_per: `Afbeeldingen per`,
        error_26101: `Fout: 26101. Neem contact met ons op`,
        error_26102: `Fout: 26102. Neem contact met ons op`,
        error_26103: `Verzoek mislukt (foutcode:26103). Probeer het nog een keer`,
        error_26104: `Foutcode: 26104, probeer het opnieuw`,
        error_26105: `Foutcode: 26105, probeer het opnieuw`,
        error_26106: `Verwijderen mislukt (foutcode: 26106). Probeer het alstublieft nog een keer`,
        error_26201: `Fout: 26201. Neem contact met ons op`,
        error_26202: `Verzoek mislukt (foutcode:26202). Probeer het nog een keer`,
        error_26001: `Fout: 26001. Neem contact met ons op`,
        error_26002: `Fout: 26002. Neem contact met ons op`,
        error_26003: `Fout: 26003. Neem contact met ons op`,
        error_26004: `Fout: 26004. Neem contact met ons op`,
        error_26005: `Verzoek mislukt (foutcode:26005). Probeer het nog een keer`,
        error_26006: `Foutcode: 26006, probeer het opnieuw`,
        error_26008: `Fout: 26008. Neem contact met ons op`,
        go_to_the_home_page: `Ga naar de startpagina`,
        error_27101: `Verzoek mislukt (foutcode: 27101). Probeer het opnieuw`,
        error_27201: `Foutcode: 27201, neem contact met ons op`,
        error_27202: `Foutcode: 27202, probeer het opnieuw`,
        error_27203: `Verzoek mislukt (foutcode: 27203). Probeer het opnieuw`,
        error_27204: `Ongeldige code (foutcode: 27204).`,
        error_27205: `Verzoek mislukt (foutcode: 27205). Probeer het opnieuw`,
        error_27206: `Verzoek mislukt (foutcode: 27206). Probeer het opnieuw`,
        error_27207: `Verzoek mislukt (foutcode: 27207). Probeer het opnieuw`,
        no_history_found: `Je hebt geen gereedschap gebruikt! <a href="/">Vernieuwen</a> of <a href="https://nl.tipard.com/">Ga naar de officiële website</a>`,
        error_25301: `Fout: 25301. Neem contact met ons op`,
        error_25302: `Fout: 25302. Neem contact met ons op`,
        error_25303: `Verzoek mislukt (foutcode: 25303). Probeer het alstublieft nog een keer`,
        error_25304: `Verzoek mislukt (foutcode: 25304). Probeer het alstublieft nog een keer`,
        error_25305: `Verzoek mislukt (foutcode: 25305). Probeer het alstublieft nog een keer`,
        error_25306: `Verzoek mislukt (foutcode: 25306). Probeer het alstublieft nog een keer`,
        image_upscaler_p: `Afbeelding zonder watermerk downloaden voordelen:`,
        Available_for: `Beschikbaar voor:`,
        credit_per: `%s credit(s) per HD-afbeelding`,
        still_valid: `Gekochte abonnement(en) zijn nog steeds geldig`,
        credit: `tegoed(en)`,
        pc_3rd_info: `Succesvol inloggen. Ga naar de applicatie voor verdere bediening.`,
        use_online: `Maak gebruik van de onlinedienst`,
        use_download: `Gebruik het desktopprogramma`,
        use_immediately: `Gebruik onmiddellijk`,
        Use_in_browser: `Gebruik in browser`,
        win_desktop: `ramen`,
        Mac_desktop: `Mac`,
        credits_per: `{%} Tegoeden per maand`,
        expire: `Vervaltijd:`,
        viewDetails: `Bekijk details`,
        viewHistory: `Bekijk de geschiedenisvoordelen >>`,
        viewDetailsInfo: `Let op: Als de abonnementsvoordelen binnen 7 dagen na het verlopen worden verlengd, kunnen de ongebruikte voordelen gebruikt blijven worden. Bovendien wordt de vervaltijd automatisch bijgewerkt naar de vervaltijd van het nieuwe abonnement. Als er na 7 dagen vervaltijd geen nieuw abonnement is, worden alle verlopen voordelen gewist.`,
        "connect_account": "E-mail aan uw account koppelen",
        "connect_account_info": "Na het binden kunt u met dit e-mailadres inloggen.",
        "connect_now": "Bind nu",
        "no_email_bind": "Geen e-mailbinding",
        "bind_email": "E-mail binden",
        "connect_your_email_placeholder": "Vul alstublieft uw e-mail adres in",
        "bind_an_email": "Een e-mail binden",
        "bind_info": "U bent succesvol ingelogd. Koppel een e-mail aan uw account om uw voordelen te activeren.",
        "bind_later": "Later binden",
        "footer": "Copyright © 2024 Tipard Studio. Alle rechten voorbehouden.",
        "overtime": "Foutcode: {%}, probeer het opnieuw",
        "hi": "Zijn!",
        "Personal_Information": "Persoonlijke informatie",
        "Access": "Toegang",
        "Subscription_Plan": "(Abonnementsplan)",
        "No_orders": "Geen bestellingen gevonden.",
        "No_data": "Geen gegevens",
        "Featured_Products": "Aanbevolen producten",
        "More_Products": "Meer producten",
        "Free_Download": "Gratis downloaden",
        "Get_Started": "Aan de slag",
        "Subscribe": "Abonneren",
        "Verified": "Geverifieerd",
        "back_to_account_center": "Terug naar Accountcentrum",
        "success": "Succes!",
        "successfully": "U heeft succesvol een account aangemaakt.",
        "Continue": "Doorgaan",
        "Already": "Heeft u al een account?",
        "loading_verification": "Verificatiestatus controleren...",
        "email_no_verification": "Sorry, het geregistreerde e-mailadres is niet geverifieerd. Voltooi de verificatie volgens de bovenstaande instructies en klik nogmaals op de knop \"Gereed\" om de registratie te voltooien.",
        "error_20505": "De e-mail is gekoppeld",
        "error_20506": "Fout: 20506. Neem contact met ons op",
        "error_28401": "Voer uw e-mailadres in",
        "error_28402": "Onjuist e-mailformaat",
        "error_28403": "Fout: 28403. Neem contact met ons op",
        "error_28404": "De e-mail is aangemeld",
        "error_28405": "Fout: 28405. Neem contact met ons op",
        "error_28406": "De e-mail is gekoppeld",
        "error_28407": "Fout: 28407. Neem contact met ons op",
        "error_28408": "Het is niet gelukt om de e-mail te verzenden",
        "error_28601": "Voer uw e-mailadres in",
        "error_28602": "Voer de verificatiecode in",
        "error_28603": "Onjuist e-mailformaat",
        "error_28604": "Fout: 28604. Neem contact met ons op",
        "error_28605": "De verificatiecode is onjuist",
        "error_28606": "Fout: 28606. Neem contact met ons op",
        "error_28607": "De e-mail is aangemeld",
        "error_28608": "Er is geen e-mailadres aan uw account gekoppeld",
        "error_28609": "Het e-mailadres is niet geverifieerd",
        "error_28610": "Fout: 28610. Neem contact met ons op",
        "error_28611": "De e-mail is gekoppeld",
        "error_28612": "Fout: 28612. Neem contact met ons op",
        "unbind_email_btn": "Koppel de e-mail los",
        "binding_exchange": "E-mail binden",
        "Subscription_Status": "Abonnementsstatus",
        "mask_unbind_info_1": "Weet u zeker dat u {%} van dit account wilt loskoppelen? Na het loskoppelen kunt u de voordelen van deze e-mail niet meer controleren",
        "mask_unbind_info_2": "Weet u zeker dat u alle pc's die aan deze registratiecode zijn gekoppeld, wilt loskoppelen?<a href=\"https://nl.tipard.com/faq.html#unbind\">Lees meer over het wijzigen van apparaten.</a>",
        "error_dc_link": "Fout, neem contact met ons op",
        "vcu_state": "Voordelen van de volledige versie:",
        "monthly_subscription": "1 maand licentie",
        "year_subscription": "1 jaar licentie",
        "to": "naar",
        "lifetime_subscription": "Levenslange licentie",
        "unbind": "Bevestigen",
        "unbind_success": "Succesvol losgemaakt",
        "error_28401": "Voer uw e-mailadres in",
        "error_28702": "Fout: 28702. Neem contact met ons op",
        "error_28703": "De verificatiecode is onjuist",
        "error_28704": "Fout: 28704. Neem contact met ons op",
        "error_28705": "De e-mail is niet gekoppeld",
        "error_28706": "Voer de verificatiecode in",
        "error_28707": "Het is niet gelukt om de koppeling te verbreken",
        "error_28901": "Fout: 28901. Neem contact met ons op",
        "error_28902": "Fout: 28902. Neem contact met ons op",
        "error_28903": "Fout: 28903. Neem contact met ons op",
        "error_28904": "Fout: 28904. Neem contact met ons op",
        "error_28905": "Fout: 28905. Neem contact met ons op",
        "error_28906": "Fout: 28906. Neem contact met ons op",
        "error_28907": "Ongeldige registratiecode",
        "error_28908": "Fout: 28908. Neem contact met ons op",
        "error_28909": "Momenteel in de afkoelperiode voor het loskoppelen. Je kunt je maar één keer per maand loskoppelen.",
        "error_289010": "Fout: 289010. Neem contact met ons op",
        "error_289011": "Fout: 289011. Neem contact met ons op",
        "error_289012": "Fout: 289012. Neem contact met ons op",
        "activated_computer": "Geactiveerd op %s/%s computer(s)",
        "bind_email_info": "De voordelen van de volgende e-mails worden gekoppeld en gebruikt onder dit VIP-account.",
        "error_26309": "Fout: 26309. Neem contact met ons op",
        "error_26310": "Ongeldige verwijzingscode",
        "error_26311": "Fout: 26311. Neem contact met ons op",
        "error_26312": "Ongeldige verwijzingscode",
        "error_26313": "Fout: 26313. Neem contact met ons op",
        "error_26314": "De e-mail is gekoppeld",
        "activate_unbinding": "Apparaat wijzigen",
        "copy_registration_code": "Kopieer registratiecode",
        "show_registration_code": "Registratiecode weergeven",
        "hide_registration_code": "Registratiecode verbergen",
        "other_binding_email": "Andere bindende e-mail(s)",
        "home": "Thuis",
        "points_balance": "Puntensaldo:",
        "earn_now": "Verdien nu >>",
        "earn_more": "Verdien meer GRATIS punten, wissel meer cadeaus in",
        "earn_or_redeem_points": "Punten verdienen/inwisselen",
        "unbind_email_info": "De huidige rekening is gekoppeld aan een e-mailadres",
        "bind_email_title": "Als u meer bestellingen wilt koppelen aan aankoop-e-mailadressen, klikt u op de onderstaande knop 'E-mail koppelen' om dit te doen.",
        "bind_email_tip": "<span>Tip:</span> Momenteel worden alleen aankopen van e-mailregistratiecodes ondersteund.",
        "email_association": "E-mail associatie",
        "dissociation": "Dissociatie",
        "back": "Rug",
        "activate_benefits": "Voer het aankoop-e-mailadres in dat gekoppeld moet worden om de verificatiecode te verkrijgen. Voer de verificatiecode die u in de e-mail hebt ontvangen in het invoerveld voor de verificatiecode in en klik op de knop \"Activering verifiëren\" om de voordelen te activeren.",
        "e_mail": "E-mailadres:",
        "verification_code": "Verificatiecode:",
        "obtain_verification_code": "Verificatiecode verkrijgen",
        "verification_association": "Verificatie van de associatie",
        "current_password": "Huidig wachtwoord:",
        "account_center": "Accountcentrum",
        "new_password": "Nieuw wachtwoord:",
        "confirm_password": "Bevestig nieuw wachtwoord:",
        "Send": "Versturen",
        "history_files": "Geschiedenisbestanden",
        "delete_account_info_1": "1. Het verwijderen van uw Tipard-account is een permanente actie en kan niet ongedaan worden gemaakt. Al uw gegevens, inclusief foto's en voorkeuren, gaan verloren. Weet u zeker dat u uw Tipard-account wilt verwijderen?",
        "delete_account_info_2": "2. Als u besluit om uw Tipard-account te verwijderen, klikt u op de knop Verzenden en voert u de verificatiecode in die u in de e-mail vindt die wij u hebben gestuurd.",
        "best_deals": "Beste aanbiedingen",
        "close": "Dichtbij",
        "product":"Producten:"
    },
    no: {
        overtime_1: `Feil kode:`,
        overtime_2: `, prøv igjen`,
        isnetwork: `Internett -feil. Sjekk og prøv igjen`,
        email_placeholder: `E -post`,
        email_empty: `Skriv inn e -post`,
        email_not_valid: `E -post er ikke gyldig`,
        email_not_valid_1: `Skriv inn e -posten din`,
        email_not_valid_2: `E -post er ikke gyldig. Bruk en annen adresse.`,
        email_not_valid_3: `Ingen e -postinngang`,
        password_placeholder: `Passord`,
        password_empty: `Skriv inn passord`,
        password_not_valid: `Feil konto eller passord`,
        password_not_valid_1: `Passord over 8 tegn kreves`,
        password_not_valid_2: `Opprett et passord`,
        password_placeholder_1: `lag ditt passord`,
        password_placeholder_2: `Bekreft passordet ditt`,
        password_placeholder_3: `Opprett nytt passord`,
        password_placeholder_4: `Bekrefte nytt passord`,
        password_placeholder_5: `Skriv inn det gamle passordet`,
        copy_password_empty: `Bekreft passord`,
        copy_password_not_valid: `Bekreft passordet ditt`,
        copy_passwords_inconsistent: `Passordbekreftelsen din stemmer ikke overens`,
        code_empty: `Skriv inn bekreftelseskoden`,
        code_not_valid: `Ugyldig bekreftelseskode`,
        code_placeholder: `Bekreftelseskode`,
        not_received_code: `Hvis postboksen din ikke har mottatt bekreftelseskoden på lenge, må du få bekreftelseskoden igjen.`,
        get_first_code: `Vennligst få bekreftelseskoden først.`,
        last_name_placeholder: `Vennligst fyll inn ditt etternavn`,
        first_name_placeholder: `Skriv inn fornavnet ditt`,
        address_placeholder: `Skriv inn adressen din`,
        no_code_text: `Vi har sendt en bekreftelseskode. Skriv inn koden din. <span class='tips'>Mottok du ikke en kode?`,
        no_code_text_1: `1.Vennligst sørg for at e -postadressen er gyldig og at den kan motta e -post.`,
        no_code_text_2: `2.For e -posten blir sendt av systemet automatisk, kan den bli flagget som søppelpost eller søppelpost. Kontroller om e -posten er i papirkurven.`,
        no_code_text_3: `3. kan ikke løse problemet ditt?`,
        no_code_text_3_span: `Klikk deretter her for å kontakte oss.`,
        order_no: `Du har ikke kjøpt noen produkter. Eventuelle spørsmål, vennligst <a href="https://no.tipard.com/store.html">Kjøp nå</a> eller <a href="https://no.tipard.com/contact.html"> Kontakt oss</a>`,
        error_24901: `Nåværende konto har ingen e -postkobling, og kan ikke finne bestillingene. Vennligst koble til en e -post.`,
        user_guide: `Brukerhåndbok >>`,
        download: `nedlasting`,
        order_number: `Ordrenummer`,
        Refund: `Refusjon`,
        Disabled: `Funksjonshemmet`,
        Normal: `Vanlig`,
        Modify: `Endre`,
        Modify_1: `Endre >>`,
        Connect: `Koble`,
        unlink_success: `Koble fra koblingen`,
        connect_success: `Koble til`,
        feedback_title: `Takk for din tilbakemelding. Vennligst la problemet være, så svarer vi deg innen 24 timer.`,
        feedback_thank_you: `Takk! <br /> Tilbakemeldingen din er sendt.`,
        feedback_email: `Skriv inn din epost her!`,
        feedback_content: `Legg igjen et problem eller forslag du møtte her.`,
        feedback_submit: `Sende inn`,
        form_contents: `Du har ikke angitt noen beskrivelse. Skriv inn det og send det på nytt.`,
        old_password: `Skriv inn gammelt passord`,
        new_password: `Opprett et nytt passord`,
        old_new_password: `Det nye passordet kan ikke være det samme som det gamle`,
        incorrect_password: `feil passord`,
        delete_no: `Slett nå`,
        Caps: `Caps Lock er på`,
        Get: `Få`,
        Done: `Ferdig`,
        error_20001: `Feil: 20001. Logg på igjen.`,
        error_20002: `Feil: 20002. Logg på igjen.`,
        error_20003: `Feil: 20003. Logg på igjen.`,
        error_20004: `Forespørselen mislyktes (feilkode: 20004). Prøv igjen.`,
        error_20005: `Påloggingsøkten er utløpt (Feil: 20005). Logg inn igjen.`,
        error_20006: `Forespørselen mislyktes (feilkode: 20006). Prøv igjen.`,
        error_20007: `Påloggingsøkten er utløpt (Feil: 20007). Logg inn igjen.`,
        error_20008: `Påloggingsøkten er utløpt (Feil: 20008). Logg inn igjen.`,
        error_20009: `Påloggingsøkten er utløpt (Feil: 20009). Logg inn igjen.`,
        error_20101: `Skriv inn e -posten din (feilkode: 20101)`,
        error_20102: `E -postadressen er ikke gyldig (feilkode: 20102)`,
        error_20103: `Forespørselen mislyktes (feilkode: 20103). Prøv igjen`,
        error_20104: `E -post er allerede i bruk. <a href="https://account.tipard.com/login"> logg inn </a> eller registrer deg hos en ny`,
        error_20105: `Forespørselen mislyktes (feilkode: 20105). Prøv igjen`,
        error_20106: `Kunne ikke sende e -post. Prøv den på nytt`,
        error_20201: `Skriv inn e -posten din (feilkode: 20201)`,
        error_20202: `Skriv inn passordet ditt (feilkode: 20202)`,
        error_20203: `Skriv inn bekreftelseskode (feilkode: 20203)`,
        error_20204: `E -postadressen er ikke gyldig (feilkode: 20204)`,
        error_20205: `Passord over 8 tegn kreves (feilkode: 20205)`,
        error_20206: `Forespørselen mislyktes (feilkode: 20206). Prøv igjen`,
        error_20207: `Ugyldig bekreftelseskode`,
        error_20208: `Forespørselen mislyktes (feilkode: 20208). Prøv igjen`,
        error_20209: `Forespørselen mislyktes (feilkode: 20209). Prøv igjen`,
        error_20301: `Skriv inn e -posten din (feilkode: 20301)`,
        error_20302: `Feil: 20302. Ta kontakt med oss`,
        error_20303: `E -postadressen er ikke gyldig (feilkode: 20303)`,
        error_20304: `Forespørselen mislyktes (feilkode: 20304). Prøv igjen`,
        error_20305: `Kontoen eksisterer ikke. Skriv inn på nytt eller <a href="https://account.tipard.com/register">Lag det</a> på nytt.`,
        error_20306: `Ingen passord ennå. Bruk <a href="https://account.tipard.com/passwordless-login"> Påloggingsløs pålogging </a> eller <a href="create-password"> angi et passord </a> og logg inn.`,
        error_20308: `Forespørselen mislyktes (feilkode: 20308). Prøv igjen`,
        error_20401: `Kunne ikke logge ut (feilkode: 20401). Prøv igjen`,
        error_20501: `Skriv inn e -posten din (feilkode: 20501)`,
        error_20502: `E -postadressen er ikke gyldig (feilkode: 20502)`,
        error_20503: `Forespørselen mislyktes (feilkode: 20503). Prøv igjen`,
        error_20504: `Kunne ikke sende e -post. Prøv igjen.`,
        error_20601: `Skriv inn e -posten din (feilkode: 20601)`,
        error_20602: `Skriv inn bekreftelseskode (feilkode: 20602)`,
        error_20603: `E -postadressen er ikke gyldig (feilkode: 20603)`,
        error_20604: `Forespørselen mislyktes (feilkode: 20604). Prøv igjen`,
        error_20606: `Forespørselen mislyktes (feilkode: 20606). Prøv igjen`,
        error_20607: `Forespørselen mislyktes (feilkode: 20607). Prøv igjen`,
        error_20608: `Forespørselen mislyktes (feilkode: 20608). Prøv igjen`,
        error_20701: `Skriv inn e -posten din (feilkode: 20701)`,
        error_20702: `E -post er ikke gyldig (feilkode: 20702)`,
        error_20703: `Forespørselen mislyktes (feilkode: 20703). Prøv igjen`,
        error_20704: `Kontoen eksisterer ikke. Skriv inn på nytt eller <a href="https://account.tipard.com/register">Lag det</a> på nytt.`,
        error_20705: `Forespørselen mislyktes (feilkode: 20705). Prøv igjen`,
        error_20706: `Kunne ikke sende e -post. Prøv igjen`,
        error_20801: `Skriv inn e -posten din (feilkode: 20801)`,
        error_20802: `Feil: 20802. Ta kontakt med oss`,
        error_20803: `Skriv inn bekreftelseskode (feilkode: 20803)`,
        error_20804: `E -postadressen er ikke gyldig (feilkode: 20804)`,
        error_20805: `Passord over 8 tegn kreves (feilkode: 20805)`,
        error_20806: `Forespørselen mislyktes (feilkode: 20806). Prøv igjen`,
        error_20808: `Forespørselen mislyktes (feilkode: 20808). Prøv igjen`,
        error_20901: `Forespørselen mislyktes (feilkode: 20901). Prøv igjen`,
        error_20902: `Forespørselen mislyktes (feilkode: 20902). Prøv igjen`,
        error_21000: `Endringer lagres`,
        error_21001: `Ingen informasjon er gitt (feilkode: 21001)`,
        error_21002: `Forespørselen mislyktes (feilkode: 21002). Prøv igjen`,
        error_21101: `Skriv inn e -posten din (feilkode: 21101)`,
        error_21102: `E -post er ikke gyldig (feilkode: 21102)`,
        error_21103: `Forespørselen mislyktes (feilkode: 21103). Prøv den på nytt`,
        error_21104: `E -post er allerede tilkoblet. Bruk en ny`,
        error_21105: `Forespørselen mislyktes (feilkode: 21105). Prøv den på nytt`,
        error_21106: `Kunne ikke sende e -post. Prøv igjen`,
        error_21201: `Skriv inn e -posten din (feilkode: 21201)`,
        error_21202: `Skriv inn bekreftelseskode (feilkode: 21202)`,
        error_21203: `E -post er ikke gyldig (feilkode: 21203)`,
        error_21204: `Feil: 21204. Ta kontakt med oss`,
        error_21205: `Feil: 21205. Ta kontakt med oss`,
        error_21206: `Passord over 8 tegn kreves (feilkode: 21206)`,
        error_21207: `Forespørselen mislyktes (feilkode: 21207). Prøv igjen`,
        error_21209: `Forespørselen mislyktes (feilkode: 21209). Prøv igjen`,
        error_21301: `Skriv inn gammelt passord (feilkode: 21301)`,
        error_21302: `Opprett et nytt passord (feilkode: 21302)`,
        error_21303: `Det nye passordet kan ikke være det samme som det gamle. (Feil: 21303)`,
        error_21304: `Passord over 8 tegn kreves (feilkode: 21304)`,
        error_21306: `Forespørselen mislyktes (feilkode: 21306). Prøv igjen`,
        error_21402: `Forespørselen mislyktes (feilkode: 21402). Prøv igjen`,
        error_21403: `Kunne ikke sende bekreftelseskoden. Send den på nytt`,
        error_21500: `Kontoen er slettet`,
        error_21501: `Skriv inn bekreftelseskode (feilkode: 21501)`,
        error_21502: `Påloggingsøkten er utløpt (Feil: 21502). Logg inn igjen.`,
        error_21503: `Forespørselen mislyktes (feilkode: 21503). Prøv igjen`,
        error_21505: `Forespørselen mislyktes (feilkode: 21505). Prøv den på nytt`,
        error_21601: `Feil: 20601. Ta kontakt med oss`,
        error_21602: `Ugyldig bekreftelse (feil: 20602). Prøv igjen.`,
        error_21603: `Feil: 20603. Prøv igjen`,
        error_21604: `Forespørselen mislyktes (feilkode: 21604). Prøv igjen`,
        error_21606: `Forespørselen mislyktes (feilkode: 21606). Prøv igjen`,
        error_21611: `Forespørselen mislyktes (feilkode: 21611). Prøv igjen`,
        error_21801: `Feil: 21801. Ta kontakt med oss`,
        error_21802: `Forespørselen mislyktes (feil: 21802). Prøv igjen`,
        error_21803: `Feil: 21803. Prøv igjen`,
        error_21804: `Forespørselen mislyktes (feilkode: 21804). Prøv igjen`,
        error_21806: `Feil: 21806. Prøv igjen`,
        error_21807: `Feil: 21807. Ta kontakt med oss`,
        error_21808: `Feil: 21808. Ta kontakt med oss`,
        error_21809: `Feil: 21809. Ta kontakt med oss`,
        error_21810: `Feil: 21810. Ta kontakt med oss`,
        error_21811: `Feil: 21811. Ta kontakt med oss`,
        error_21812: `Feil: 21812. Ta kontakt med oss`,
        error_21813: `Forespørselen mislyktes (feilkode: 21813). Prøv igjen`,
        error_21814: `Feil: 21814. Ta kontakt med oss`,
        error_21815: `Forespørselen mislyktes (feilkode: 21815). Prøv igjen`,
        error_21816: `Feil: 21816. Ta kontakt med oss`,
        error_21817: `Feil: 21817. Ta kontakt med oss`,
        error_21818: `Feil: 21818. Ta kontakt med oss`,
        error_21819: `Forespørselen mislyktes (feilkode: 21819). Prøv igjen`,
        error_21820: `Feil: 21820. Ta kontakt med oss`,
        error_21821: `Feil: 21821. Ta kontakt med oss`,
        error_21822: `Feil: 21822. Ta kontakt med oss`,
        error_21823: `Forespørselen mislyktes (feilkode: 21823). Prøv igjen`,
        error_21824: `Forespørselen mislyktes (feilkode: 21824). Prøv igjen`,
        error_21825: `Forespørselen mislyktes (feilkode: 21825). Prøv igjen`,
        error_21826: `Forespørselen mislyktes (feilkode: 21826). Prøv igjen`,
        error_21901: `Feil: 21901. Ta kontakt med oss`,
        error_21902: `Forespørselen mislyktes (feilkode: 21902). Prøv igjen`,
        error_21903: `Kontostatusen er endret (feilkode: 21903). Oppdater siden og prøv igjen`,
        error_21904: `Feil: 21904. Prøv igjen`,
        error_21905: `Feil: 21905. Prøv igjen`,
        error_21906: `Forespørselen mislyktes (feilkode: 21906). Prøv igjen`,
        error_21907: `Google -kontoen er knyttet til en annen konto`,
        error_21908: `Forespørselen mislyktes (feilkode: 21908). Prøv igjen`,
        error_22001: `Forespørselen mislyktes (feilkode: 22001). Prøv igjen`,
        error_22002: `Koble fra mislyktes uten ytterligere pålogging`,
        error_22003: `Forespørselen mislyktes (feilkode: 22003). Prøv igjen`,
        error_22101: `Feil: 22101. Ta kontakt med oss`,
        error_22102: `Kontostatusen er endret (feilkode: 22102). Oppdater siden og prøv igjen`,
        error_22103: `Forespørselen mislyktes (feilkode: 22103). Prøv igjen`,
        error_22104: `Kontostatusen er endret (feilkode: 22104). Oppdater siden og prøv igjen`,
        error_22105: `Feil: 22105. Prøv igjen`,
        error_22106: `Feil: 22106. Prøv igjen`,
        error_22107: `Feil: 22107. Ta kontakt med oss`,
        error_22108: `Forespørselen mislyktes (feilkode: 22108). Prøv igjen`,
        error_22201: `Feil: 22201. Ta kontakt med oss`,
        error_22202: `Ugyldig bekreftelse (feil: 22202). Prøv igjen.`,
        error_22203: `Feil: 22203. Prøv det igjen "`,
        error_22204: `Forespørselen mislyktes (feilkode: 22204). Prøv igjen`,
        error_22206: `Forespørselen mislyktes (feilkode: 22206). Prøv igjen`,
        error_22401: `Feil: 22401. Ta kontakt med oss`,
        error_22402: `Ugyldig bekreftelse (feil: 22402). Prøv igjen.`,
        error_22403: `Feil: 22403. Prøv igjen`,
        error_22404: `Forespørselen mislyktes (feilkode: 22404). Prøv igjen`,
        error_22405: `Facebook -kontoen er knyttet til annen e -post`,
        error_22406: `Feil: 22406. Prøv igjen`,
        error_22407: `Feil: 22407. Ta kontakt med oss`,
        error_22408: `Feil: 22408. Ta kontakt med oss`,
        error_22409: `Feil: 22409. Ta kontakt med oss`,
        error_22410: `Feil: 224010. Ta kontakt med oss`,
        error_22411: `Feil: 224011. Ta kontakt med oss`,
        error_22412: `Feil: 224012. Ta kontakt med oss`,
        error_22413: `Feil: 22413. Ta kontakt med oss`,
        error_22414: `Forespørselen mislyktes (feilkode: 22414). Prøv igjen`,
        error_22415: `Feil: 22415. Ta kontakt med oss`,
        error_22416: `Feil: 22416. Ta kontakt med oss`,
        error_22417: `Feil: 22417. Ta kontakt med oss`,
        error_22418: `Forespørselen mislyktes (feilkode: 22418). Prøv igjen`,
        error_22419: `Feil: 22419. Ta kontakt med oss`,
        error_22420: `Feil: 22420. Ta kontakt med oss`,
        error_22421: `Feil: 22421. Ta kontakt med oss`,
        error_22422: `Forespørselen mislyktes (feilkode: 22422). Prøv igjen`,
        error_22423: `Forespørselen mislyktes (feilkode: 22423). Prøv igjen`,
        error_22424: `Forespørselen mislyktes (feilkode: 22424). Prøv igjen`,
        error_22425: `Forespørselen mislyktes (feilkode: 22425). Prøv igjen`,
        error_20098: `Feilkode: 20098. Hvis du surfer i privat modus, kan du bytte til normal modus og prøve igjen.`,
        error_22298: `Forespørsel om Google mislyktes (feil: 22298). Prøv igjen.`,
        error_22498: `Forespørsel om Facebook mislyktes (feil: 22498). Prøv igjen.`,
        error_24902: `Forespørselen mislyktes (feilkode: 24902). Prøv igjen`,
        error_24903: `Forespørselen mislyktes (feilkode: 24903). Prøv igjen`,
        error_24904: `Forespørselen mislyktes (feilkode: 24904). Prøv igjen`,
        error_24905: `Forespørselen mislyktes (feilkode: 24905). Prøv igjen`,
        login_title: `Logg på Tipard`,
        password_placeholder: `Passord`,
        log_in: `Logg Inn`,
        no_account: `Ingen konto?`,
        create_it: `Lag det`,
        or_log_in_with: `Eller logg inn med`,
        passwordless_login: `Passordløs pålogging`,
        log_in_done: `Logg på Ferdig`,
        three_rd_account_connect_info: `Gratulerer! Du har logget deg på. Nå kan du koble til en e -postkonto som brukes til å logge på i fremtiden.`,
        see_my_account: `Se kontoen min`,
        three_rd_login_merge_account: `Tredjeparts konto-e-postadressen er registrert. Vil du koble deg til og logge på med denne e-postadressen direkte?`,
        connect_log_in: `Koble til og logg inn`,
        create_an_account: `Opprett en konto`,
        back_to_log_in: `Tilbake til innlogging`,
        create_password: `Lag passord`,
        create_now: `Opprett nå`,
        password_login_subtitle: `Passordløs pålogging med e -post`,
        account_login: `Kontoinnlogging`,
        rights: `Ved å opprette denne kontoen godtar du <a href="https://no.tipard.com/terms.html"> vilkår for bruk </a> og <a href="https://no.tipard.com/policy.html"> Personvernpolicy </a>`,
        passwordless_login_done: `Passordløs pålogging utført`,
        passwordless_login_info: `Gratulerer, du har fullført passordløs pålogging. Du kan opprette et passord for denne kontoen og logge på med kontoen og passordet i fremtiden. <a href="/create-password" style="display: initial;"> Opprett nå </a>`,
        sign_up: `Melde deg på`,
        register_info: `lag din konto`,
        reset_now: `Tilbakestill nå`,
        forgot_password: `Glemt passord`,
        reset_password_subtitle: `Bruk kontoen din for å tilbakestille passordet`,
        plan_products: `Plan og produkter`,
        nick_name: `Brukernavn`,
        email: `E -post`,
        my_products: `Mine produkter`,
        my_orders: `Mine bestillinger`,
        unlink: `Fjern koblingen`,
        link: `Lenke`,
        connected_accounts: `Tilkoblede kontoer`,
        last_name: `Etternavn`,
        first_name: `Fornavn`,
        Gender: `Kjønn`,
        Birth: `Fødsel`,
        Month: `Måned`,
        Year: `År`,
        Country_Region: `Land/region`,
        Address: `Adresse`,
        Save: `Lagre`,
        Date: `Dato`,
        Male: `Hann`,
        Female: `Hunn`,
        Unspecified: `Uspesifisert`,
        Security: `Sikkerhet`,
        change_password: `Bytt passord`,
        change_now: `Endre nå`,
        connect_email: `Koble til e -post`,
        delete_account: `Slett konto`,
        delete_account_info: `Når kontoen din slettes, blir alle dataene i Plan og produkter knyttet til kontoen din slettet permanent, og det er ikke sikkert du kan gjenopprette den. Vi anbefaler at du går forsiktig.`,
        Delete: `Slett`,
        Logout: `Logg ut`,
        my_profile: `Min profil`,
        guides_faqs: `Guider og vanlige spørsmål`,
        More: `Mer`,
        guides: `Guider`,
        register: `registrere`,
        hot_faq: `Hot FAQ`,
        Contents: `Innhold:`,
        contact_us: `Kontakt oss >>`,
        plan: `Plan`,
        unregistered: `Uregistrert`,
        buy_more: `Kjøp mer`,
        buy_again: `Kjøp igjen`,
        buy_now: `Kjøp nå`,
        free_no_limit: `Gratis og ingen grense`,
        expired: `Utløpt`,
        lifetime: `Livstid`,
        remain: `Forbli`,
        day_s: `Dager)`,
        day: `Dager`,
        error_24801: `Forespørselen mislyktes (feilkode: 24801). Vennligst prøv igjen`,
        no_app_found: `Ingen app funnet!<a href="/">Oppdater</a> eller <a href="https://no.tipard.com/">gå til det offisielle nettstedet</a>`,
        get_more: `Få mer >>`,
        edit_photo: `Rediger bilde`,
        select_photo: `Velg bilde`,
        change_photo: `Endre Foto`,
        cancel: `Avbryt`,
        hide_password: `Skjul passord`,
        show_password: `Vis passord`,
        zoom_in: `Zoom inn`,
        zoom_out: `Zoome ut`,
        rotate: `Rotere`,
        horizontal_flip: `Horisontal flipp`,
        vertical_flip: `Vertikal flipp`,
        country: `Land`,
        country_1: `Velg ditt land/region`,
        country_2: `Åaland Island`,
        country_3: `Afghanistan`,
        country_4: `Albania`,
        country_5: `Algerie`,
        country_6: `Amerikansk Samoa`,
        country_7: `Andorra`,
        country_8: `Angola`,
        country_9: `Anguilla`,
        country_10: `Antarktis`,
        country_11: `Antigua og Barbuda`,
        country_12: `Argentina`,
        country_13: `Armenia`,
        country_14: `Aruba`,
        country_15: `Australia`,
        country_16: `Østerrike`,
        country_17: `Aserbajdsjan`,
        country_18: `Bahrain`,
        country_19: `Bangladesh`,
        country_20: `Barbados`,
        country_21: `Hviterussland`,
        country_22: `Belgia`,
        country_23: `Belize`,
        country_24: `Benin`,
        country_25: `Bermuda`,
        country_26: `Bhutan`,
        country_27: `Bolivia`,
        country_28: `Bosnia og Hercegovina`,
        country_29: `Botswana`,
        country_30: `Bouvetøya`,
        country_31: `Brasil`,
        country_32: `Britisk territorium i det indiske hav`,
        country_33: `De britiske jomfruøyene`,
        country_34: `Brunei`,
        country_35: `Bulgaria`,
        country_36: `Burkina`,
        country_37: `Burundi`,
        country_38: `Kambodsja`,
        country_39: `Kamerun`,
        country_40: `Canada`,
        country_41: `Kapp Verde`,
        country_42: `Karibiske Nederland`,
        country_43: `Caymanøyene`,
        country_44: `Den sentralafrikanske republikk`,
        country_45: `Tsjad`,
        country_46: `Chile`,
        country_47: `Kina`,
        country_48: `Juleøya`,
        country_49: `Kokosøyene (Keeling).`,
        country_50: `Colombia`,
        country_51: `Cook-øyene`,
        country_52: `Costa Rica`,
        country_53: `"Elfenbenskysten"`,
        country_54: `Kroatia`,
        country_55: `Cuba`,
        country_56: `Kypros`,
        country_57: `Tsjekkisk Republikk`,
        country_58: `Den demokratiske republikken Kongo`,
        country_59: `Danmark`,
        country_60: `Djibouti`,
        country_61: `Dominica`,
        country_62: `den dominikanske republikk`,
        country_63: `Ecuador`,
        country_64: `Egypt`,
        country_65: `El Salvador`,
        country_66: `Ekvatorial-Guinea`,
        country_67: `Eritrea`,
        country_68: `Estland`,
        country_69: `Etiopia`,
        country_70: `Falklandsøyene`,
        country_71: `Færøyene`,
        country_72: `Mikronesias fødererte stater`,
        country_73: `Fiji`,
        country_74: `Finland`,
        country_75: `Frankrike`,
        country_76: `fransk Guyana`,
        country_77: `Fransk Polynesia`,
        country_78: `franske sørlige territorier`,
        country_79: `Gabon`,
        country_80: `Gambia`,
        country_81: `Georgia`,
        country_82: `Tyskland`,
        country_83: `Ghana`,
        country_84: `Gibraltar`,
        country_85: `Storbritannia (Storbritannia; England)`,
        country_86: `Hellas`,
        country_87: `Grønland`,
        country_88: `Grenada`,
        country_89: `Guadeloupe`,
        country_90: `Guam`,
        country_91: `Guatemala`,
        country_92: `Guernsey`,
        country_93: `Guinea`,
        country_94: `Guinea-Bissau`,
        country_95: `Guyana`,
        country_96: `Haiti`,
        country_97: `Heard Island og McDonald Islands`,
        country_98: `Honduras`,
        country_99: `Hong Kong`,
        country_100: `Ungarn`,
        country_101: `Island`,
        country_102: `India`,
        country_103: `Indonesia`,
        country_104: `Iran`,
        country_105: `Irak`,
        country_106: `Irland`,
        country_107: `Isle of Man`,
        country_108: `Israel`,
        country_109: `Italia`,
        country_110: `Jamaica`,
        country_111: `Japan`,
        country_112: `Jersey`,
        country_113: `Jordan`,
        country_114: `Kasakhstan`,
        country_115: `Kenya`,
        country_116: `Kiribati`,
        country_117: `Kuwait`,
        country_118: `Kirgisistan`,
        country_119: `Laos`,
        country_120: `Latvia`,
        country_121: `Libanon`,
        country_122: `Lesotho`,
        country_123: `Liberia`,
        country_124: `Libya`,
        country_125: `Liechtenstein`,
        country_126: `Litauen`,
        country_127: `Luxembourg`,
        country_128: `Macao`,
        country_129: `Madagaskar`,
        country_130: `Malawi`,
        country_131: `Malaysia`,
        country_132: `Maldivene`,
        country_133: `Mali`,
        country_134: `Malta`,
        country_135: `Marshalløyene`,
        country_136: `Martinique`,
        country_137: `Mauritania`,
        country_138: `Mauritius`,
        country_139: `Mayotte`,
        country_140: `Mexico`,
        country_141: `Moldova`,
        country_142: `Monaco`,
        country_143: `Mongolia`,
        country_144: `Montenegro`,
        country_145: `Montserrat`,
        country_146: `Marokko`,
        country_147: `Mosambik`,
        country_148: `Myanmar (Burma)`,
        country_149: `Namibia`,
        country_150: `Nauru`,
        country_151: `Nepal`,
        country_152: `Nederland`,
        country_153: `Ny Caledonia`,
        country_154: `New Zealand`,
        country_155: `Nicaragua`,
        country_156: `Niger`,
        country_157: `Nigeria`,
        country_158: `Niue`,
        country_159: `Norfolkøya`,
        country_160: `Nord-Korea`,
        country_161: `Nord-Marianene`,
        country_162: `Norge`,
        country_163: `Oman`,
        country_164: `Pakistan`,
        country_165: `Palau`,
        country_166: `Palestinske områder`,
        country_167: `Panama`,
        country_168: `Papua Ny-Guinea`,
        country_169: `Paraguay`,
        country_170: `Peru`,
        country_171: `Pitcairnøyene`,
        country_172: `Polen`,
        country_173: `Portugal`,
        country_174: `Puerto Rico`,
        country_175: `Qatar`,
        country_176: `Republikken Makedonia (FYROM)`,
        country_177: `Republikken Kongo`,
        country_178: `Gjenforening`,
        country_179: `Romania`,
        country_180: `Den russiske føderasjonen`,
        country_181: `Rwanda`,
        country_182: `Saint Barthélemy`,
        country_183: `Saint Martin (Frankrike)`,
        country_184: `Saint-Pierre og Miquelon`,
        country_185: `Samoa`,
        country_186: `San Marino`,
        country_187: `Sao Tome og Principe`,
        country_188: `Saudi-Arabia`,
        country_189: `Senegal`,
        country_190: `Serbia`,
        country_191: `Seychellene`,
        country_192: `Sierra Leone`,
        country_193: `Singapore`,
        country_194: `Slovakia`,
        country_195: `Slovenia`,
        country_196: `Solomon øyene`,
        country_197: `Somalia`,
        country_198: `Sør-Afrika`,
        country_199: `Sør-Georgia og Sør-Sandwich-øya`,
        country_200: `Sør-Korea`,
        country_201: `Sør-Sudan`,
        country_202: `Spania`,
        country_203: `Sri Lanka`,
        country_204: `St. Helena & Dependencies`,
        country_205: `St. Kitts og Nevis`,
        country_206: `St. Lucia`,
        country_207: `St. Vincent og Grenadinene`,
        country_208: `Sudan`,
        country_209: `Surinam`,
        country_210: `Swaziland`,
        country_211: `Sverige`,
        country_212: `Sveits`,
        country_213: `Syria`,
        country_214: `Taiwan`,
        country_215: `Tadsjikistan`,
        country_216: `Tanzania`,
        country_217: `Mal:Landsdata SJM Svalbard`,
        country_218: `Thailand`,
        country_219: `Bahamas`,
        country_220: `Komorene`,
        country_221: `Filippinene`,
        country_222: `Timor-Leste (Øst-Timor)`,
        country_223: `Å gå`,
        country_224: `Tokelau`,
        country_225: `Tonga`,
        country_226: `Trinidad og Tobago`,
        country_227: `Tunisia`,
        country_228: `Tyrkia`,
        country_229: `Turkmenistan`,
        country_230: `Turks- og Caicosøyene`,
        country_231: `Tuvalu`,
        country_232: `Uganda`,
        country_233: `Ukraina`,
        country_234: `De forente arabiske emirater`,
        country_235: `USAs mindre ytre øyer`,
        country_236: `Amerikas forente stater (USA)`,
        country_237: `De amerikanske jomfruøyene`,
        country_238: `Uruguay`,
        country_239: `Usbekistan`,
        country_240: `Vanuatu`,
        country_241: `Vatikanstaten (Den hellige stol)`,
        country_242: `Venezuela`,
        country_243: `Vietnam`,
        country_244: `Wallis og Futuna`,
        country_245: `Vestre Sahara`,
        country_246: `Jemen`,
        country_247: `Zambia`,
        country_248: `Zimbabwe`,
        google_login: `Logg på med Google`,

        State: `Stat`,
        Activation_code: `Aktiviseringskode`,
        Question: `List opp alle appene du har logget på`,
        Copy_complete: `Kopi fullført`,
        change_password_success: `Passordet ble endret`,
        successful_login_title: `Innlogging vellykket`,
        product_page: `Produktside >>`,
        successful_login_info: `Innlogging fullført. Lukk gjeldende side og gå tilbake til den opprinnelige fanen for å fortsette med følgende prosess. Den gjeldende siden lukkes automatisk om 5 sekunder. Hvis automatisk lukking eller lukking ved å klikke på "Ferdig"-knappen mislykkes, vennligst lukk denne fanen direkte.`,
        successful_login_info_firefox: `Innlogging fullført. Lukk gjeldende side og gå tilbake til den opprinnelige fanen for å fortsette med følgende prosess.`,
        my_account: `Min konto`,
        my_history: `Min historie`,
        remove_watermark: `Fjern vannmerke`,
        no_history: `Ingen historie`,
        history_all: `Velg alle`,
        history_open: `Åpen`,
        history_down: `nedlasting`,
        history_delete: `Slett`,
        history_clear: `Fjern ugyldig`,
        images: `Bilder)`,
        use_this_function: `Bruk denne funksjonen >>`,
        hd_downloading: `Fordeler med å laste ned originale HD-bilder:`,
        lifetimeRemaining: `Levetid gjenstår`,
        Remaining: `Gjenstående`,
        email_verification: `epostbekreftelse`,
        email_verification_info: `Vi har sendt bekreftelsesmeldingen til e-posten din <span class="email"></span>, og fullfør bekreftelsen. Etter verifisering vil fordelene synkroniseres automatisk.`,
        wrong_email: `Feil e-postadresse?`,
        click_here_to_modify: `Klikk her for å endre`,
        get_more_help: `Få mer hjelp?`,
        click_here: `Klikk her`,
        email_verification_info_success: `Gratulerer for bekreftelsen av e-postkontoen din.`,
        email_verification_info_error: `Bekreftelsen mislyktes på grunn av den utløpte koblingen.`,
        registration_succeeded: `Registrering lyktes`,
        registration_succeeded_info_1: `Gratulerer! Registreringen var vellykket. Vi har sendt bekreftelsesmeldingen til e-posten din <span class="email"></span>, og fullfør bekreftelsen for å bruke fordelene med denne e-posten.`, registration_succeeded_info_2: `Klikk "Ferdig" for å gå tilbake til hjemmesiden og bruke dette produktet.`,
        registration_succeeded_info_3: `Klikk "Ferdig" for å lukke gjeldende side og gå tilbake til startsiden for følgende operasjoner. Hvis lukkingen mislykkes, må du lukke fanen manuelt.`,
        verify_email: `verifiser e-post`,
        registered_email_not_verified: `Den registrerte e-posten er ikke bekreftet, vennligst bekreft den med en gang.`,
        email_verification_time_1: `Har du ikke mottatt en bekreftelses-e-post?`,
        email_verification_time_2: `Etter`,
        email_verification_time_3: `Klikk her for å sende den på nytt`,
        error_26301: `Feilkode: 26301, vennligst kontakt oss`,
        error_26302: `Feilkode: 26302, vennligst kontakt oss`,
        error_26303: `E-postformatfeil (feilkode: 26303). Vennligst skriv den inn igjen`,
        error_26304: `Passord over 8 tegn anbefales (feilkode: 26304)`,
        error_26305: `Reuqest mislyktes (feilkode: 26305). Vennligst prøv på nytt`,
        error_26306: `E-post er registrert, vennligst <a href="https://account.tipard.com/login">gå til pålogging</a>`,
        error_26307: `Reuqest mislyktes (feilkode: 26307). Vennligst prøv på nytt`,
        error_26308: `Reuqest mislyktes (feilkode: 26308). Vennligst prøv på nytt`,
        error_26401: `Feilkode: 26401, prøv på nytt`,
        error_26402: `E-posten er bekreftet (feilkode: 26402), prøv på nytt`,
        error_26403: `Reuquest mislyktes (feilkode: 26403). Vennligst prøv på nytt`,
        error_26404: `Forespørselen mislyktes (feilkode: 26404). Vennligst prøv på nytt`,
        error_26501: `Feilkode: 26501, vennligst kontakt oss`,
        error_26502: `Feilkode: 26502, vennligst kontakt oss`,
        error_26503: `E-postformatfeil (feilkode: 26503). Vennligst skriv den inn igjen`,
        error_26504: `Reuqest mislyktes (feilkode: 26504). Vennligst prøv på nytt`,
        error_26505: `E-post har ikke blitt registrert, vennligst <a href="https://account.tipard.com/register">registrer den først</a>`,
        error_26506: `E-post er bekreftet.`,
        error_26507: `Reuqest mislyktes (feilkode: 26507). Vennligst prøv på nytt`,
        error_26508: `Bekreftelse mislyktes (feilkode: 26508), prøv på nytt`,
        error_26509: `Forespørselen mislyktes (feilkode: 26509), prøv på nytt`,
        error_26510: `Feilkode: 26510, vennligst kontakt oss`,
        error_26601: `Feilkode: 26601, vennligst kontakt oss`,
        error_26602: `Feilkode: 26602, vennligst kontakt oss`,
        error_26603: `Reuqest mislyktes (feilkode: 26603). Vennligst prøv på nytt`,
        error_26604: `Feilkode: 26604, vennligst kontakt oss`,
        error_26605: `Feilkode: 26605, vennligst kontakt oss`,
        error_26701: `Feilkode: 26701, vennligst kontakt oss`,
        error_26702: `Reuqest mislyktes (feilkode: 26702). Vennligst prøv på nytt`,
        error_26703: `Feilkode: 26703, vennligst kontakt oss`,
        error_26704: `Feilkode: 26704, vennligst kontakt oss`,
        error_26705: `Vent på innlogging (feilkode: 26705). Vennligst prøv på nytt`,
        no_cookie: `Du har slått på funksjonen Blokker alle informasjonskapsler i nettleseren din, så du kan ikke logge på. Gå til Innstillinger for å merke av i boksen Tillat alle informasjonskapsler.`,
        error_26801: `Feilkode: 26801, vennligst kontakt oss`,
        error_26802: `Feilkode: 26802, vennligst kontakt oss`,
        error_26803: `Forespørselen mislyktes (feilkode: 26803). Vennligst prøv på nytt`,
        error_26804: `Forespørselen mislyktes (feilkode: 26804). Vennligst prøv på nytt`,
        error_order: `Forespørselen mislyktes (feilkode: 27098), vennligst prøv på nytt!`,
        error_order1: `Bestillingsforespørselen er ufullstendig (feilkode:`,
        error_order2: `）, vennligst oppdater og prøv på nytt.`,
        modify_email_title: `Endre e-post`,
        modify_email_info: `Du kan bruke den endrede e-posten til å logge på kontoen din.`,
        images_per: `Bilder pr`,
        error_26101: `Feil: 26101. Ta kontakt med oss`,
        error_26102: `Feil: 26102. Ta kontakt med oss`,
        error_26103: `Forespørselen mislyktes (feilkode:26103). Prøv det på nytt`,
        error_26104: `Feilkode: 26104, vennligst prøv på nytt`,
        error_26105: `Feilkode: 26105, vennligst prøv på nytt`,
        error_26106: `Sletting mislyktes (feilkode: 26106). Vennligst prøv igjen`,
        error_26201: `Feil: 26201. Ta kontakt med oss`,
        error_26202: `Forespørselen mislyktes (feilkode:26202). Prøv det på nytt`,
        error_26001: `Feil: 26001. Ta kontakt med oss`,
        error_26002: `Feil: 26002. Ta kontakt med oss`,
        error_26003: `Feil: 26003. Ta kontakt med oss`,
        error_26004: `Feil: 26004. Ta kontakt med oss`,
        error_26005: `Forespørselen mislyktes (feilkode:26005). Prøv det på nytt`,
        error_26006: `Feilkode: 26006, prøv på nytt`,
        error_26008: `Feil: 26008. Ta kontakt med oss`,
        go_to_the_home_page: `Gå til hjemmesiden`,
        error_27101: `Forespørselen mislyktes (feilkode: 27101). Vennligst prøv igjen`,
        error_27201: `Feilkode: 27201, vennligst kontakt oss`,
        error_27202: `Feilkode: 27202, prøv på nytt`,
        error_27203: `Forespørselen mislyktes (feilkode: 27203). Vennligst prøv på nytt`,
        error_27204: `Ugyldig kode (feilkode: 27204).`,
        error_27205: `Forespørselen mislyktes (feilkode: 27205). Vennligst prøv på nytt`,
        error_27206: `Forespørselen mislyktes (feilkode: 27206). Vennligst prøv på nytt`,
        error_27207: `Forespørselen mislyktes (feilkode: 27207). Vennligst prøv på nytt`,
        no_history_found: `Du har ikke brukt noe verktøy! <a href="/">Oppdater</a> eller <a href="https://no.tipard.com/">gå til det offisielle nettstedet</a>`,
        error_25301: `Feil: 25301. Ta kontakt med oss`,
        error_25302: `Feil: 25302. Ta kontakt med oss`,
        error_25303: `Forespørselen mislyktes (feilkode: 25303). Vennligst prøv igjen`,
        error_25304: `Forespørselen mislyktes (feilkode: 25304). Vennligst prøv igjen`,
        error_25305: `Forespørselen mislyktes (feilkode: 25305). Vennligst prøv igjen`,
        error_25306: `Forespørselen mislyktes (feilkode: 25306). Vennligst prøv igjen`,
        image_upscaler_p: `Fordeler ved nedlasting av bilde uten vannmerke:`,
        Available_for: `Tilgjengelig for:`,
        credit_per: `%s kreditt(er) per HD-bilde`,
        still_valid: `Kjøpte plan(er) er fortsatt gyldige`,
        credit: `kreditt(er)`,
        pc_3rd_info: `Logg på vellykket. Vennligst gå til applikasjonen for videre drift.`,
        use_online: `Bruk den elektroniske tjenesten`,
        use_download: `Bruk skrivebordsprogrammet`,
        use_immediately: `Bruk umiddelbart`,
        Use_in_browser: `Bruk i nettleser`,
        win_desktop: `Windows`,
        Mac_desktop: `Mac`,
        credits_per: `{%} studiepoeng per måned`,
        expire: `Utløpstid:`,
        viewDetails: `Vis detaljer`,
        viewHistory: `Se historiefordeler >>`,
        viewDetailsInfo: `Merknad: Hvis abonnementsfordelene fornyes innen 7 dager etter utløp, kan de ubrukte fordelene fortsatt brukes. Utløpstiden vil også automatisk oppdateres til utløpstiden for det nye abonnementet. Hvis det ikke er noe nytt abonnement etter 7 dagers utløpstid, vil alle utgåtte fordeler slettes.`,
        "connect_account": "Bind e-post til kontoen din",
        "connect_account_info": "Etter binding kan du logge inn med denne e-postadressen.",
        "connect_now": "Bind nå",
        "no_email_bind": "Ingen e-postbinding",
        "bind_email": "Bind e-post",
        "connect_your_email_placeholder": "Vennligst skriv inn E-postadressen din",
        "bind_an_email": "Bind en e-post",
        "bind_info": "Du har logget på. Vennligst bind en e-post til kontoen din for å aktivere fordelene dine.",
        "bind_later": "Bind senere",
        "footer": "Copyright © 2024 Tipard Studio. Alle rettigheter reservert.",
        "overtime": "Feilkode: {%}, vennligst prøv på nytt",
        "hi": "Hei, %s!",
        "Personal_Information": "Personlig informasjon",
        "Access": "Adgang",
        "Subscription_Plan": "(Abonnement)",
        "No_orders": "Ingen bestillinger funnet.",
        "No_data": "Ingen data",
        "Featured_Products": "Utvalgte produkter",
        "More_Products": "Flere produkter",
        "Free_Download": "Gratis nedlasting",
        "Get_Started": "Kom i gang",
        "Subscribe": "Abonner",
        "Verified": "Verifisert",
        "back_to_account_center": "Tilbake til kontosenteret",
        "success": "Suksess!",
        "successfully": "Du har registrert en konto.",
        "Continue": "Fortsette",
        "Already": "Har du allerede en konto?",
        "loading_verification": "Sjekker bekreftelsesstatus ...",
        "email_no_verification": "Beklager, den registrerte e-postadressen er ikke bekreftet. Fullfør bekreftelsen i henhold til instruksjonene ovenfor og klikk på \"Ferdig\"-knappen igjen for å fullføre registreringen.",
        "error_20505": "E-posten er koblet til",
        "error_20506": "Feil: 20506. Ta kontakt med oss",
        "error_28401": "Vennligst skriv inn e-posten din",
        "error_28402": "Feil e-postformat",
        "error_28403": "Feil: 28403. Ta kontakt med oss",
        "error_28404": "E-posten er registrert",
        "error_28405": "Feil: 28405. Ta kontakt med oss",
        "error_28406": "E-posten er koblet til",
        "error_28407": "Feil: 28407. Ta kontakt med oss",
        "error_28408": "Kunne ikke sende e-posten",
        "error_28601": "Vennligst skriv inn e-posten din",
        "error_28602": "Vennligst skriv inn bekreftelseskoden",
        "error_28603": "Feil e-postformat",
        "error_28604": "Feil: 28604. Ta kontakt med oss",
        "error_28605": "Bekreftelseskoden er feil",
        "error_28606": "Feil: 28606. Ta kontakt med oss",
        "error_28607": "E-posten er registrert",
        "error_28608": "Det er ingen e-post knyttet til kontoen din",
        "error_28609": "E-posten er ikke bekreftet",
        "error_28610": "Feil: 28610. Ta kontakt med oss",
        "error_28611": "E-posten er koblet til",
        "error_28612": "Feil: 28612. Ta kontakt med oss",
        "unbind_email_btn": "Fjern koblingen til e-posten",
        "binding_exchange": "Bind e-post",
        "Subscription_Status": "Abonnementsstatus",
        "mask_unbind_info_1": "Er du sikker på at du vil koble fra {%} fra denne kontoen? Etter at du har fjernet koblingen, kan du ikke lenger sjekke fordelene med denne e-posten",
        "mask_unbind_info_2": "Er du sikker på at du vil fjerne bindingen til alle PC-er knyttet til denne registreringskoden?<a href=\"https://no.tipard.com/faq.html#unbind\">Finn ut mer om hvordan du bytter enheter.</a>",
        "error_dc_link": "Feil, vennligst kontakt oss",
        "vcu_state": "Fordeler med full versjon:",
        "monthly_subscription": "1 måneds lisens",
        "year_subscription": "1 års lisens",
        "to": "til",
        "lifetime_subscription": "Livstidslisens",
        "unbind": "Bekrefte",
        "unbind_success": "Opphevet bindingen",
        "error_28401": "Vennligst skriv inn e-posten din",
        "error_28702": "Feil: 28702. Ta kontakt med oss",
        "error_28703": "Bekreftelseskoden er feil",
        "error_28704": "Feil: 28704. Ta kontakt med oss",
        "error_28705": "E-posten er ikke koblet",
        "error_28706": "Vennligst skriv inn bekreftelseskoden",
        "error_28707": "Kunne ikke fjerne tilknytningen",
        "error_28901": "Feil: 28901. Ta kontakt med oss",
        "error_28902": "Feil: 28902. Ta kontakt med oss",
        "error_28903": "Feil: 28903. Ta kontakt med oss",
        "error_28904": "Feil: 28904. Ta kontakt med oss",
        "error_28905": "Feil: 28905. Ta kontakt med oss",
        "error_28906": "Feil: 28906. Ta kontakt med oss",
        "error_28907": "Ugyldig registreringskode",
        "error_28908": "Feil: 28908. Ta kontakt med oss",
        "error_28909": "For øyeblikket i nedkjølingsperioden for oppheving. Du kan bare løsne en gang i måneden.",
        "error_289010": "Feil: 289010. Ta kontakt med oss",
        "error_289011": "Feil: 289011. Ta kontakt med oss",
        "error_289012": "Feil: 289012. Ta kontakt med oss",
        "activated_computer": "Aktivert på %s/%s datamaskin(er)",
        "bind_email_info": "Fordelene med følgende e-poster vil bli bundet og brukt under denne VIP-kontoen.",
        "error_26309": "Feil: 26309. Ta kontakt med oss",
        "error_26310": "Ugyldig henvisningskode",
        "error_26311": "Feil: 26311. Ta kontakt med oss",
        "error_26312": "Ugyldig henvisningskode",
        "error_26313": "Feil: 26313. Ta kontakt med oss",
        "error_26314": "E-posten er koblet til",
        "activate_unbinding": "Bytt enhet",
        "copy_registration_code": "Kopier registreringskoden",
        "show_registration_code": "Vis registreringskode",
        "hide_registration_code": "Skjul registreringskoden",
        "other_binding_email": "Andre bindende e-post(er)",
        "home": "Hjem",
        "points_balance": "Poengbalanse:",
        "earn_now": "Tjen nå >>",
        "earn_more": "Tjen flere GRATIS poeng, løs inn flere gaver",
        "earn_or_redeem_points": "Tjen/løs inn poeng",
        "unbind_email_info": "Den nåværende kontoen er knyttet til en e-postadresse",
        "bind_email_title": "Hvis du ønsker å tilknytte flere bestillinger for kjøp av e-postadresser, vennligst klikk på \"E-posttilknytning\"-knappen nedenfor for å tilknytte.",
        "bind_email_tip": "<span>Tips:</span> For øyeblikket støttes bare kjøp av e-postregistreringskodebestillinger.",
        "email_association": "E-postforeningen",
        "dissociation": "Dissosiasjon",
        "back": "Tilbake",
        "activate_benefits": "Vennligst skriv inn kjøps-e-posten som må tilknyttes for å få bekreftelseskoden. Skriv inn bekreftelseskoden mottatt i e-posten i inndataboksen for bekreftelseskoden, og klikk på \"Bekreft aktivering\"-knappen for å aktivere fordelene.",
        "e_mail": "E-post:",
        "verification_code": "Bekreftelseskode:",
        "obtain_verification_code": "Få bekreftelseskode",
        "verification_association": "Bekreft tilknytning",
        "current_password": "Nåværende passord:",
        "account_center": "Kontosenter",
        "new_password": "Nytt passord:",
        "confirm_password": "Bekreft nytt passord:",
        "Send": "Sende",
        "history_files": "Historiefiler",
        "delete_account_info_1": "1. Sletting av Tipard-kontoen din er en permanent handling og kan ikke angres. Alle dataene dine, inkludert bilder og preferanser, vil gå tapt. Er du sikker på at du vil slette Tipard-kontoen din?",
        "delete_account_info_2": "2. Hvis du bestemmer deg for å slette Tipard-kontoen, vennligst klikk på Send-knappen og skriv inn bekreftelseskoden fra e-posten vi sendte deg.",
        "best_deals": "Beste tilbud",
        "close": "Lukke",
        "product":"Produkt:"
    },
    pl: {
        overtime_1: `Kod błędu:`,
        overtime_2: `, spróbuj ponownie`,
        isnetwork: `Błąd internetowy. Sprawdź i spróbuj ponownie`,
        email_placeholder: `E-mail`,
        email_empty: `Proszę wpisać e-mail`,
        email_not_valid: `adres email jest nieprawidłowy`,
        email_not_valid_1: `Wprowadź swój adres e-mail`,
        email_not_valid_2: `E-mail jest nieprawidłowy, użyj innego adresu.`,
        email_not_valid_3: `Brak wpisu e-mail`,
        password_placeholder: `Hasło`,
        password_empty: `Wprowadź hasło`,
        password_not_valid: `Nieprawidłowe konto lub hasło`,
        password_not_valid_1: `Wymagane jest hasło powyżej 8 znaków`,
        password_not_valid_2: `Proszę utwórz hasło`,
        password_placeholder_1: `Stwórz swoje hasło`,
        password_placeholder_2: `Potwierdź swoje hasło`,
        password_placeholder_3: `Utwórz nowe hasło`,
        password_placeholder_4: `Potwierdź nowe hasło`,
        password_placeholder_5: `Wprowadź stare hasło`,
        copy_password_empty: `Potwierdź hasło`,
        copy_password_not_valid: `Proszę, potwierdź swoje hasło`,
        copy_passwords_inconsistent: `Twoje potwierdzenie hasła nie pasuje`,
        code_empty: `Wprowadź kod weryfikacyjny`,
        code_not_valid: `nieprawidłowy kod weryfikacyjny`,
        code_placeholder: `Kod weryfikacyjny`,
        not_received_code: `Jeśli Twoja skrzynka pocztowa od dłuższego czasu nie otrzymuje kodu weryfikacyjnego, pobierz go ponownie.`,
        get_first_code: `Najpierw pobierz kod weryfikacyjny.`,
        last_name_placeholder: `Proszę podać swoje nazwisko`,
        first_name_placeholder: `Proszę podaj swoje imię`,
        address_placeholder: `Podaj swój adres`,
        no_code_text: `Wysłaliśmy kod weryfikacyjny. Wprowadź swój kod. <span class='tips'>Nie otrzymałeś kodu?`,
        no_code_text_1: `1. Upewnij się, że adres e-mail jest prawidłowy i może odbierać wiadomości e-mail.`,
        no_code_text_2: `2. Ponieważ wiadomość e-mail jest wysyłana przez system automatycznie, może zostać oznaczona jako spam lub wiadomości-śmieci. Sprawdź, czy wiadomość e-mail znajduje się w folderze Kosz.`,
        no_code_text_3: `3. Nie możesz rozwiązać swojego problemu?`,
        no_code_text_3_span: `Kliknij tutaj, aby się z nami skontaktować.`,
        order_no: `Nie kupiłeś żadnych produktów. Wszelkie pytania proszę <a href="https://pl.tipard.com/store.html">Kup teraz</a> Lub <a href="https://pl.tipard.com/contact.html"> Skontaktuj się z nami</a>`,
        error_24901: `Bieżące konto nie ma powiązanego adresu e-mail i nie można znaleźć zamówień. Proszę połączyć e-mail.`,
        user_guide: `Podręcznik użytkownika>>`,
        download: `Pobierać`,
        order_number: `Numer zamówienia`,
        Refund: `Zwrot`,
        Disabled: `Wyłączone`,
        Normal: `Normalna`,
        Modify: `Modyfikować`,
        Modify_1: `Modyfikuj>>`,
        Connect: `Łączyć`,
        unlink_success: `Odłącz pomyślnie`,
        connect_success: `Połącz się pomyślnie`,
        feedback_title: `Dziękuję za twój komentarz. Zostaw swój problem, a my odpowiemy w ciągu 24 godzin.`,
        feedback_thank_you: `Dziękujemy!<br />Twoja opinia została pomyślnie przesłana.`,
        feedback_email: `Wpisz tutaj swój adres e-mail!`,
        feedback_content: `Zostaw tutaj wszelkie napotkane problemy lub sugestie.`,
        feedback_submit: `Składać`,
        form_contents: `Nie podałeś żadnego opisu. Wprowadź go i prześlij ponownie.`,
        old_password: `Proszę wpisać stare hasło`,
        new_password: `Utwórz nowe hasło`,
        old_new_password: `Nowe hasło nie może być takie samo jak stare`,
        incorrect_password: `niepoprawne hasło`,
        delete_no: `Usuń teraz`,
        Caps: `Caps Lock jest włączony`,
        Get: `Dostwać`,
        Done: `Gotowe`,
        error_20001: `Błąd: 20001. Zaloguj się ponownie.`,
        error_20002: `Błąd: 20002. Zaloguj się ponownie.`,
        error_20003: `Błąd: 20003. Zaloguj się ponownie.`,
        error_20004: `Żądanie nie powiodło się (kod błędu: 20004). Spróbuj ponownie.`,
        error_20005: `Sesja logowania wygasła (Błąd: 20005). Proszę, zaloguj się ponownie.`,
        error_20006: `Żądanie nie powiodło się (kod błędu: 20006). Spróbuj ponownie.`,
        error_20007: `Sesja logowania wygasła (Błąd: 20007). Proszę, zaloguj się ponownie.`,
        error_20008: `Sesja logowania wygasła (Błąd: 20008). Proszę, zaloguj się ponownie.`,
        error_20009: `Sesja logowania wygasła (Błąd: 20009). Proszę, zaloguj się ponownie.`,
        error_20101: `Wprowadź swój adres e-mail (kod błędu: 20101)`,
        error_20102: `E-mail jest nieprawidłowy (kod błędu: 20102)`,
        error_20103: `Żądanie nie powiodło się (kod błędu: 20103). Spróbuj ponownie`,
        error_20104: `E-mail jest już w użyciu, <a href="https://account.tipard.com/login">zaloguj się</a> lub zarejestruj się na nowy`,
        error_20105: `Żądanie nie powiodło się (kod błędu: 20105). Spróbuj ponownie`,
        error_20106: `Nie udało się wysłać e-maila, spróbuj ponownie`,
        error_20201: `Wprowadź swój adres e-mail (kod błędu: 20201)`,
        error_20202: `Wprowadź swoje hasło (kod błędu: 20202)`,
        error_20203: `Wprowadź kod weryfikacyjny (kod błędu: 20203)`,
        error_20204: `E-mail jest nieprawidłowy (kod błędu: 20204)`,
        error_20205: `Wymagane jest hasło powyżej 8 znaków (kod błędu: 20205)`,
        error_20206: `Żądanie nie powiodło się (kod błędu: 20206). Spróbuj ponownie`,
        error_20207: `nieprawidłowy kod weryfikacyjny`,
        error_20208: `Żądanie nie powiodło się (kod błędu: 20208). Spróbuj ponownie`,
        error_20209: `Żądanie nie powiodło się (kod błędu: 20209). Spróbuj ponownie`,
        error_20301: `Wprowadź swój adres e-mail (kod błędu: 20301)`,
        error_20302: `Błąd: 20302. Skontaktuj się z nami`,
        error_20303: `E-mail jest nieprawidłowy (kod błędu: 20303)`,
        error_20304: `Żądanie nie powiodło się (kod błędu: 20304). Spróbuj ponownie`,
        error_20305: `Konto nie istnieje. Wprowadź ponownie lub najpierw <a href="https://account.tipard.com/register">Stwórz To</a>.`,
        error_20306: `Brak hasła. Użyj <a href="https://account.tipard.com/passwordless-login">logowania bez hasła</a> lub <a href="create-password">ustaw hasło</a> i zaloguj się.`,
        error_20308: `Żądanie nie powiodło się (kod błędu: 20308). Spróbuj ponownie`,
        error_20401: `Nie udało się wylogować (kod błędu: 20401). Spróbuj ponownie`,
        error_20501: `Wprowadź swój adres e-mail (kod błędu: 20501)`,
        error_20502: `E-mail jest nieprawidłowy (kod błędu: 20502)`,
        error_20503: `Żądanie nie powiodło się (kod błędu: 20503). Spróbuj ponownie`,
        error_20504: `Nie udało się wysłać e-maila. Spróbuj ponownie.`,
        error_20601: `Wprowadź swój adres e-mail (kod błędu: 20601)`,
        error_20602: `Wprowadź kod weryfikacyjny (kod błędu: 20602)`,
        error_20603: `E-mail jest nieprawidłowy (kod błędu: 20603)`,
        error_20604: `Żądanie nie powiodło się (kod błędu: 20604). Spróbuj ponownie`,
        error_20606: `Żądanie nie powiodło się (kod błędu: 20606). Spróbuj ponownie`,
        error_20607: `Żądanie nie powiodło się (kod błędu: 20607). Spróbuj ponownie`,
        error_20608: `Żądanie nie powiodło się (kod błędu: 20608). Spróbuj ponownie`,
        error_20701: `Wprowadź swój adres e-mail (kod błędu: 20701)`,
        error_20702: `E-mail jest nieprawidłowy (kod błędu: 20702)`,
        error_20703: `Żądanie nie powiodło się (kod błędu: 20703). Spróbuj ponownie`,
        error_20704: `Konto nie istnieje. Wprowadź ponownie lub najpierw <a href="https://account.tipard.com/register">Stwórz To</a>.`,
        error_20705: `Żądanie nie powiodło się (kod błędu: 20705). Spróbuj ponownie`,
        error_20706: `Nie udało się wysłać e-maila. Spróbuj ponownie`,
        error_20801: `Wprowadź swój adres e-mail (kod błędu: 20801)`,
        error_20802: `Błąd: 20802. Skontaktuj się z nami`,
        error_20803: `Wprowadź kod weryfikacyjny (kod błędu: 20803)`,
        error_20804: `E-mail jest nieprawidłowy (kod błędu: 20804)`,
        error_20805: `Wymagane jest hasło powyżej 8 znaków (kod błędu: 20805)`,
        error_20806: `Żądanie nie powiodło się (kod błędu: 20806). Spróbuj ponownie`,
        error_20808: `Żądanie nie powiodło się (kod błędu: 20808). Spróbuj ponownie`,
        error_20901: `Żądanie nie powiodło się (kod błędu: 20901). Spróbuj ponownie`,
        error_20902: `Żądanie nie powiodło się (kod błędu: 20902). Spróbuj ponownie`,
        error_21000: `Zmiany są zapisywane`,
        error_21001: `Nie przesłano żadnych informacji (kod błędu: 21001)`,
        error_21002: `Żądanie nie powiodło się (kod błędu: 21002). Spróbuj ponownie`,
        error_21101: `Wprowadź swój adres e-mail (kod błędu: 21101)`,
        error_21102: `E-mail jest nieprawidłowy (kod błędu: 21102)`,
        error_21103: `Żądanie nie powiodło się (kod błędu: 21103), spróbuj ponownie`,
        error_21104: `Poczta e-mail jest już podłączona, użyj nowego`,
        error_21105: `Żądanie nie powiodło się (kod błędu: 21105), spróbuj ponownie`,
        error_21106: `Nie udało się wysłać e-maila. Spróbuj ponownie`,
        error_21201: `Wprowadź swój adres e-mail (kod błędu: 21201)`,
        error_21202: `Wprowadź kod weryfikacyjny (kod błędu: 21202)`,
        error_21203: `E-mail jest nieprawidłowy (kod błędu: 21203)`,
        error_21204: `Błąd: 21204. Skontaktuj się z nami`,
        error_21205: `Błąd: 21205. Skontaktuj się z nami`,
        error_21206: `Wymagane jest hasło powyżej 8 znaków (kod błędu: 21206)`,
        error_21207: `Żądanie nie powiodło się (kod błędu: 21207). Spróbuj ponownie`,
        error_21209: `Żądanie nie powiodło się (kod błędu: 21209). Spróbuj ponownie`,
        error_21301: `Wprowadź stare hasło (kod błędu: 21301)`,
        error_21302: `Utwórz nowe hasło (kod błędu: 21302)`,
        error_21303: `Nowe hasło nie może być takie samo jak stare. (Błąd: 21303)`,
        error_21304: `Wymagane jest hasło powyżej 8 znaków (kod błędu: 21304)`,
        error_21306: `Żądanie nie powiodło się (kod błędu: 21306). Spróbuj ponownie`,
        error_21402: `Żądanie nie powiodło się (kod błędu: 21402). Spróbuj ponownie`,
        error_21403: `Nie udało się wysłać kodu weryfikacyjnego. Proszę, wyślij go ponownie`,
        error_21500: `Konto zostało usunięte`,
        error_21501: `Wprowadź kod weryfikacyjny (kod błędu: 21501)`,
        error_21502: `Sesja logowania wygasła (Błąd: 21502). Proszę, zaloguj się ponownie.`,
        error_21503: `Żądanie nie powiodło się (kod błędu: 21503). Spróbuj ponownie`,
        error_21505: `Żądanie nie powiodło się (kod błędu: 21505), spróbuj ponownie`,
        error_21601: `Błąd: 20601. Skontaktuj się z nami`,
        error_21602: `Nieprawidłowa weryfikacja (błąd: 20602). Spróbuj ponownie.`,
        error_21603: `Błąd: 20603. Spróbuj ponownie`,
        error_21604: `Żądanie nie powiodło się (kod błędu: 21604). Spróbuj ponownie`,
        error_21606: `Żądanie nie powiodło się (kod błędu: 21606). Spróbuj ponownie`,
        error_21611: `Żądanie nie powiodło się (kod błędu: 21611). Spróbuj ponownie`,
        error_21801: `Błąd: 21801. Skontaktuj się z nami`,
        error_21802: `Żądanie nie powiodło się (błąd: 21802). Spróbuj ponownie`,
        error_21803: `Błąd: 21803. Spróbuj ponownie`,
        error_21804: `Żądanie nie powiodło się (kod błędu: 21804). Spróbuj ponownie`,
        error_21806: `Błąd: 21806. Spróbuj ponownie`,
        error_21807: `Błąd: 21807. Skontaktuj się z nami`,
        error_21808: `Błąd: 21808. Skontaktuj się z nami`,
        error_21809: `Błąd: 21809. Skontaktuj się z nami`,
        error_21810: `Błąd: 21810. Skontaktuj się z nami`,
        error_21811: `Błąd: 21811. Skontaktuj się z nami`,
        error_21812: `Błąd: 21812. Skontaktuj się z nami`,
        error_21813: `Żądanie nie powiodło się (kod błędu: 21813). Spróbuj ponownie`,
        error_21814: `Błąd: 21814. Skontaktuj się z nami`,
        error_21815: `Żądanie nie powiodło się (kod błędu: 21815). Spróbuj ponownie`,
        error_21816: `Błąd: 21816. Skontaktuj się z nami`,
        error_21817: `Błąd: 21817. Skontaktuj się z nami`,
        error_21818: `Błąd: 21818. Skontaktuj się z nami`,
        error_21819: `Żądanie nie powiodło się (kod błędu: 21819). Spróbuj ponownie`,
        error_21820: `Błąd: 21820. Skontaktuj się z nami`,
        error_21821: `Błąd: 21821. Skontaktuj się z nami`,
        error_21822: `Błąd: 21822. Skontaktuj się z nami`,
        error_21823: `Żądanie nie powiodło się (kod błędu: 21823). Spróbuj ponownie`,
        error_21824: `Żądanie nie powiodło się (kod błędu: 21824). Spróbuj ponownie`,
        error_21825: `Żądanie nie powiodło się (kod błędu: 21825). Spróbuj ponownie`,
        error_21826: `Żądanie nie powiodło się (kod błędu: 21826). Spróbuj ponownie`,
        error_21901: `Błąd: 21901. Skontaktuj się z nami`,
        error_21902: `Żądanie nie powiodło się (kod błędu: 21902). Spróbuj ponownie`,
        error_21903: `Zmienił się status konta (kod błędu: 21903), odśwież stronę i spróbuj ponownie`,
        error_21904: `Błąd: 21904. Spróbuj ponownie`,
        error_21905: `Błąd: 21905. Spróbuj ponownie`,
        error_21906: `Żądanie nie powiodło się (kod błędu: 21906). Spróbuj ponownie`,
        error_21907: `Konto Google zostało połączone z innym kontem`,
        error_21908: `Żądanie nie powiodło się (kod błędu: 21908). Spróbuj ponownie`,
        error_22001: `Żądanie nie powiodło się (kod błędu: 22001). Spróbuj ponownie`,
        error_22002: `Odłączenie nie powiodło się bez dodatkowego logowania`,
        error_22003: `Żądanie nie powiodło się (kod błędu: 22003). Spróbuj ponownie`,
        error_22101: `Błąd: 22101. Skontaktuj się z nami`,
        error_22102: `Zmienił się status konta (kod błędu: 22102), odśwież stronę i spróbuj ponownie`,
        error_22103: `Żądanie nie powiodło się (kod błędu: 22103). Spróbuj ponownie`,
        error_22104: `Zmienił się status konta (kod błędu: 22104), odśwież stronę i spróbuj ponownie`,
        error_22105: `Błąd: 22105. Spróbuj ponownie`,
        error_22106: `Błąd: 22106. Spróbuj ponownie`,
        error_22107: `Błąd: 22107. Skontaktuj się z nami`,
        error_22108: `Żądanie nie powiodło się (kod błędu: 22108). Spróbuj ponownie`,
        error_22201: `Błąd: 22201. Skontaktuj się z nami`,
        error_22202: `Nieprawidłowa weryfikacja (błąd: 22202). Spróbuj ponownie.`,
        error_22203: `Błąd: 22203. Spróbuj ponownie"`,
        error_22204: `Żądanie nie powiodło się (kod błędu: 22204). Spróbuj ponownie`,
        error_22206: `Żądanie nie powiodło się (kod błędu: 22206). Spróbuj ponownie`,
        error_22401: `Błąd: 22401. Skontaktuj się z nami`,
        error_22402: `Nieprawidłowa weryfikacja (błąd: 22402). Spróbuj ponownie.`,
        error_22403: `Błąd: 22403. Spróbuj ponownie`,
        error_22404: `Żądanie nie powiodło się (kod błędu: 22404). Spróbuj ponownie`,
        error_22405: `Konto na Facebooku zostało połączone z innym adresem e-mail`,
        error_22406: `Błąd: 22406. Spróbuj ponownie`,
        error_22407: `Błąd: 22407. Skontaktuj się z nami`,
        error_22408: `Błąd: 22408. Skontaktuj się z nami`,
        error_22409: `Błąd: 22409. Skontaktuj się z nami`,
        error_22410: `Błąd: 224010. Skontaktuj się z nami`,
        error_22411: `Błąd: 224011. Skontaktuj się z nami`,
        error_22412: `Błąd: 224012. Skontaktuj się z nami`,
        error_22413: `Błąd: 22413. Skontaktuj się z nami`,
        error_22414: `Żądanie nie powiodło się (kod błędu: 22414). Spróbuj ponownie`,
        error_22415: `Błąd: 22415. Skontaktuj się z nami`,
        error_22416: `Błąd: 22416. Skontaktuj się z nami`,
        error_22417: `Błąd: 22417. Skontaktuj się z nami`,
        error_22418: `Żądanie nie powiodło się (kod błędu: 22418). Spróbuj ponownie`,
        error_22419: `Błąd: 22419. Skontaktuj się z nami`,
        error_22420: `Błąd: 22420. Skontaktuj się z nami`,
        error_22421: `Błąd: 22421. Skontaktuj się z nami`,
        error_22422: `Żądanie nie powiodło się (kod błędu: 22422). Spróbuj ponownie`,
        error_22423: `Żądanie nie powiodło się (kod błędu: 22423). Spróbuj ponownie`,
        error_22424: `Żądanie nie powiodło się (kod błędu: 22424). Spróbuj ponownie`,
        error_22425: `Żądanie nie powiodło się (kod błędu: 22425). Spróbuj ponownie`,
        error_20098: `Kod błędu: 20098. Jeśli przeglądasz w trybie prywatnym, przełącz się na tryb normalny i spróbuj ponownie.`,
        error_22298: `Żądanie do Google nie powiodło się (błąd: 22298). Spróbuj ponownie.`,
        error_22498: `Żądanie dla Facebooka nie powiodło się (błąd: 22498). Spróbuj ponownie.`,
        error_24902: `Żądanie nie powiodło się (kod błędu: 24902). Spróbuj ponownie`,
        error_24903: `Żądanie nie powiodło się (kod błędu: 24903). Spróbuj ponownie`,
        error_24904: `Żądanie nie powiodło się (kod błędu: 24904). Spróbuj ponownie`,
        error_24905: `Żądanie nie powiodło się (kod błędu: 24905). Spróbuj ponownie`,
        login_title: `Zaloguj się do Tipard`,
        password_placeholder: `Hasło`,
        log_in: `Zaloguj sie`,
        no_account: `Brak konta?`,
        create_it: `Stwórz To`,
        or_log_in_with: `Lub zaloguj się za pomocą`,
        passwordless_login: `Logowanie bez hasła`,
        log_in_done: `Zaloguj się Gotowe`,
        three_rd_account_connect_info: `Gratulacje! Zalogowałeś się pomyślnie. Teraz możesz podłączyć jedno konto e-mail, które będzie używane do logowania w przyszłości.`,
        see_my_account: `Zobacz moje konto`,
        three_rd_login_merge_account: `Adres e-mail konta innej firmy został zarejestrowany, czy chcesz się połączyć i zalogować bezpośrednio przy użyciu tego adresu e-mail?`,
        connect_log_in: `Połącz i zaloguj się`,
        create_an_account: `Utwórz konto`,
        back_to_log_in: `Powrót do logowania`,
        create_password: `Stwórz hasło`,
        create_now: `Stwórz teraz`,
        password_login_subtitle: `Logowanie bez hasła przez e-mail`,
        account_login: `Login do konta`,
        rights: `Tworząc to konto, zgadzasz się na <a href="https://pl.tipard.com/terms.html">Warunki korzystania z usługi</a> i <a href="https://pl.tipard.com/policy.html">Polityka prywatności</a>`,
        passwordless_login_done: `Logowanie bez hasła gotowe`,
        passwordless_login_info: `Gratulacje, pomyślnie zakończyłeś logowanie bez hasła. Możesz utworzyć hasło do tego konta i logować się za pomocą konta i hasła w przyszłości. <a href="/create-password" style="display: initial;">Utwórz teraz</a>`,
        sign_up: `Zapisać się`,
        register_info: `Utwórz swoje konto`,
        reset_now: `Zresetuj teraz`,
        forgot_password: `Zapomniałeś hasła`,
        reset_password_subtitle: `Użyj swojego adresu e-mail, aby zresetować hasło`,
        plan_products: `Plan i produkty`,
        nick_name: `Nazwa użytkownika`,
        email: `E-mail`,
        my_products: `Moje produkty`,
        my_orders: `Moje zamówienia`,
        unlink: `Odczepić`,
        link: `Połączyć`,
        connected_accounts: `Połączone konta`,
        last_name: `Nazwisko`,
        first_name: `Imię`,
        Gender: `Płeć`,
        Birth: `Narodziny`,
        Month: `Miesiąc`,
        Year: `Rok`,
        Country_Region: `Kraj/region`,
        Address: `Adres`,
        Save: `Zapisać`,
        Date: `Data`,
        Male: `Męski`,
        Female: `Płeć żeńska`,
        Unspecified: `Nieokreślony`,
        Security: `Bezpieczeństwo`,
        change_password: `Zmień hasło`,
        change_now: `Zmień teraz`,
        connect_email: `Połącz e-mail`,
        delete_account: `Usuń konto`,
        delete_account_info: `Gdy Twoje konto zostanie usunięte, wszystkie dane w Planach i produktach powiązane z Twoim kontem zostaną trwale usunięte i możesz nie być w stanie ich odzyskać. Zalecamy ostrożne stąpanie.`,
        Delete: `Kasować`,
        Logout: `Wyloguj`,
        my_profile: `Mój profil`,
        guides_faqs: `Przewodniki i często zadawane pytania`,
        More: `Więcej`,
        guides: `Przewodniki`,
        register: `Zarejestruj się`,
        hot_faq: `Gorące FAQ`,
        Contents: `Zawartość:`,
        contact_us: `Skontaktuj się z nami>>`,
        plan: `Plan`,
        unregistered: `Niezarejestrowany`,
        buy_more: `Kup więcej`,
        buy_again: `Kup znowu`,
        buy_now: `Kup Teraz`,
        free_no_limit: `Bezpłatnie i bez limitu`,
        expired: `Wygasły`,
        lifetime: `Dożywotni`,
        remain: `Pozostawać`,
        day_s: `Dni)`,
        day: `Dni`,
        error_24801: `Żądanie nie powiodło się (kod błędu: 24801). Spróbuj ponownie`,
        no_app_found: `Nie znaleziono aplikacji！<a href="/">Odśwież</a> lub <a href="https://pl.tipard.com/">Przejdź do oficjalnej strony internetowej</a>`,
        get_more: `Dowiedz się więcej >>`,
        edit_photo: `Edytuj zdjęcie`,
        select_photo: `Wybierz zdjęcie`,
        change_photo: `Zmień zdjęcie`,
        cancel: `Anulować`,
        hide_password: `Ukryj hasło`,
        show_password: `Pokaż hasło`,
        zoom_in: `Zbliżenie`,
        zoom_out: `Pomniejsz`,
        rotate: `Obracać się`,
        horizontal_flip: `Odwróć w poziomie`,
        vertical_flip: `Odwróć w pionie`,
        country: `Kraj`,
        country_1: `Wybierz swój kraj/region`,
        country_2: `Wyspy Alandzkie`,
        country_3: `Afganistan`,
        country_4: `Albania`,
        country_5: `Algieria`,
        country_6: `Samoa Amerykańskie`,
        country_7: `Andora`,
        country_8: `Angola`,
        country_9: `Anguilla`,
        country_10: `Antarktyda`,
        country_11: `Antigua i Barbuda`,
        country_12: `Argentyna`,
        country_13: `Armenia`,
        country_14: `Aruba`,
        country_15: `Australia`,
        country_16: `Austria`,
        country_17: `Azerbejdżan`,
        country_18: `Bahrajn`,
        country_19: `Bangladesz`,
        country_20: `Barbados`,
        country_21: `Białoruś`,
        country_22: `Belgia`,
        country_23: `Belize`,
        country_24: `Benin`,
        country_25: `Bermudy`,
        country_26: `Bhutan`,
        country_27: `Boliwia`,
        country_28: `Bośnia i Hercegowina`,
        country_29: `Botswana`,
        country_30: `Wyspa Bouveta`,
        country_31: `Brazylia`,
        country_32: `Brytyjskie Terytorium Oceanu Indyjskiego`,
        country_33: `Brytyjskie Wyspy Dziewicze`,
        country_34: `Brunei`,
        country_35: `Bułgaria`,
        country_36: `Burkina`,
        country_37: `Burundi`,
        country_38: `Kambodża`,
        country_39: `Kamerun`,
        country_40: `Kanada`,
        country_41: `Wyspy Zielonego Przylądka`,
        country_42: `Karaiby Holandia`,
        country_43: `Kajmany`,
        country_44: `Republika Środkowoafrykańska`,
        country_45: `Czad`,
        country_46: `Chile`,
        country_47: `Chiny`,
        country_48: `Wyspa Bożego Narodzenia`,
        country_49: `Wyspy Kokosowe (Keelinga)`,
        country_50: `Kolumbia`,
        country_51: `Wyspy Cooka`,
        country_52: `Kostaryka`,
        country_53: `„Wybrzeże Kości Słoniowej”`,
        country_54: `Chorwacja`,
        country_55: `Kuba`,
        country_56: `Cypr`,
        country_57: `Republika Czeska`,
        country_58: `Demokratyczna Republika Konga`,
        country_59: `Dania`,
        country_60: `Dżibuti`,
        country_61: `Dominika`,
        country_62: `Republika Dominikany`,
        country_63: `Ekwador`,
        country_64: `Egipt`,
        country_65: `Salwador`,
        country_66: `Gwinea Równikowa`,
        country_67: `Erytrea`,
        country_68: `Estonia`,
        country_69: `Etiopia`,
        country_70: `Falklandy`,
        country_71: `Wyspy Owcze`,
        country_72: `Sfederowane Stany Mikronezji`,
        country_73: `Fidżi`,
        country_74: `Finlandia`,
        country_75: `Francja`,
        country_76: `Gujana Francuska`,
        country_77: `Polinezja Francuska`,
        country_78: `Francuskie Terytoria Południowe`,
        country_79: `Gabon`,
        country_80: `Gambia`,
        country_81: `Gruzja`,
        country_82: `Niemcy`,
        country_83: `Ghana`,
        country_84: `Gibraltar`,
        country_85: `Wielka Brytania (Wielka Brytania; Anglia)`,
        country_86: `Grecja`,
        country_87: `Grenlandia`,
        country_88: `Grenada`,
        country_89: `Gwadelupa`,
        country_90: `Guam`,
        country_91: `Gwatemala`,
        country_92: `Guernsey`,
        country_93: `Gwinea`,
        country_94: `Gwinea Bissau`,
        country_95: `Gujana`,
        country_96: `Haiti`,
        country_97: `Wyspy Heard i Wyspy McDonalda`,
        country_98: `Honduras`,
        country_99: `Hongkong`,
        country_100: `Węgry`,
        country_101: `Islandia`,
        country_102: `Indie`,
        country_103: `Indonezja`,
        country_104: `Iran`,
        country_105: `Irak`,
        country_106: `Irlandia`,
        country_107: `Wyspa Man`,
        country_108: `Izrael`,
        country_109: `Włochy`,
        country_110: `Jamajka`,
        country_111: `Japonia`,
        country_112: `Golf`,
        country_113: `Jordania`,
        country_114: `Kazachstan`,
        country_115: `Kenia`,
        country_116: `Kiribati`,
        country_117: `Kuwejt`,
        country_118: `Kirgistan`,
        country_119: `Laos`,
        country_120: `Łotwa`,
        country_121: `Liban`,
        country_122: `Lesoto`,
        country_123: `Liberia`,
        country_124: `Libia`,
        country_125: `Liechtenstein`,
        country_126: `Litwa`,
        country_127: `Luksemburg`,
        country_128: `Makao`,
        country_129: `Madagaskar`,
        country_130: `Malawi`,
        country_131: `Malezja`,
        country_132: `Malediwy`,
        country_133: `Mali`,
        country_134: `Malta`,
        country_135: `Wyspy Marshalla`,
        country_136: `Martynika`,
        country_137: `Mauretania`,
        country_138: `Mauritius`,
        country_139: `Majotta`,
        country_140: `Meksyk`,
        country_141: `Moldova`,
        country_142: `Monako`,
        country_143: `Mongolia`,
        country_144: `Czarnogóra`,
        country_145: `Montserrat`,
        country_146: `Maroko`,
        country_147: `Mozambik`,
        country_148: `Birma (Birma)`,
        country_149: `Namibia`,
        country_150: `Nauru`,
        country_151: `Nepal`,
        country_152: `Holandia`,
        country_153: `Nowa Kaledonia`,
        country_154: `Nowa Zelandia`,
        country_155: `Nikaragua`,
        country_156: `Niger`,
        country_157: `Nigeria`,
        country_158: `Niue`,
        country_159: `Wyspa Norfolk`,
        country_160: `Korea Północna`,
        country_161: `Mariany Północne`,
        country_162: `Norwegia`,
        country_163: `Oman`,
        country_164: `Pakistan`,
        country_165: `Palau`,
        country_166: `terytoria palestyńskie`,
        country_167: `Panama`,
        country_168: `Papua Nowa Gwinea`,
        country_169: `Paragwaj`,
        country_170: `Peru`,
        country_171: `Wyspy Pitcairn`,
        country_172: `Polska`,
        country_173: `Portugalia`,
        country_174: `Portoryko`,
        country_175: `Katar`,
        country_176: `Republika Macedonii (BJRM)`,
        country_177: `Republika Konga`,
        country_178: `Zjazd`,
        country_179: `Rumunia`,
        country_180: `Federacja Rosyjska`,
        country_181: `Rwanda`,
        country_182: `Saint Barthelemy`,
        country_183: `Święty Marcin (Francja)`,
        country_184: `Saint-Pierre i Miquelon`,
        country_185: `Samoa`,
        country_186: `San Marino`,
        country_187: `Wyspy Świętego Tomasza i Książęca`,
        country_188: `Arabia Saudyjska`,
        country_189: `Senegal`,
        country_190: `Serbia`,
        country_191: `Seszele`,
        country_192: `Sierra Leone`,
        country_193: `Singapur`,
        country_194: `Słowacja`,
        country_195: `Słowenia`,
        country_196: `Wyspy Salomona`,
        country_197: `Somali`,
        country_198: `Afryka Południowa`,
        country_199: `Georgia Południowa i South Sandwich Isla`,
        country_200: `Korea Południowa`,
        country_201: `Południowy Sudan`,
        country_202: `Hiszpania`,
        country_203: `Sri Lanka`,
        country_204: `Św. Helena i Zależności`,
        country_205: `St. Kitts i Nevis`,
        country_206: `Św. Łucja`,
        country_207: `Saint Vincent i Grenadyny`,
        country_208: `Sudan`,
        country_209: `Surinam`,
        country_210: `Suazi`,
        country_211: `Szwecja`,
        country_212: `Szwajcaria`,
        country_213: `Syria`,
        country_214: `Tajwan`,
        country_215: `Tadżykistan`,
        country_216: `Tanzania`,
        country_217: `Szablon:Dane krajowe SJM Svalbard`,
        country_218: `Tajlandia`,
        country_219: `Bahamy`,
        country_220: `Komory`,
        country_221: `Filipiny`,
        country_222: `Timor Wschodni (Timor Wschodni)`,
        country_223: `Iść`,
        country_224: `Tokelau`,
        country_225: `Tonga`,
        country_226: `Trynidad i Tobago`,
        country_227: `Tunezja`,
        country_228: `indyk`,
        country_229: `Turkmenia`,
        country_230: `Turks i Caicos`,
        country_231: `Tuvalu`,
        country_232: `Uganda`,
        country_233: `Ukraina`,
        country_234: `Zjednoczone Emiraty Arabskie`,
        country_235: `Stany Zjednoczone Dalekie Wyspy Mniejsze`,
        country_236: `Stany Zjednoczone Ameryki (USA)`,
        country_237: `Wyspy Dziewicze Stanów Zjednoczonych`,
        country_238: `Urugwaj`,
        country_239: `Uzbekistan`,
        country_240: `Vanuatu`,
        country_241: `Watykan (Stolica Apostolska)`,
        country_242: `Wenezuela`,
        country_243: `Wietnam`,
        country_244: `Wallis i Futuna`,
        country_245: `Sahara Zachodnia`,
        country_246: `Jemen`,
        country_247: `Zambia`,
        country_248: `Zimbabwe`,
        google_login: `Zaloguj się przez Google`,
        State: `Stan`,
        Activation_code: `Kod aktywacyjny`,
        Question: `Wymień wszystkie aplikacje, w których się zalogowałeś`,
        Copy_complete: `Kopiowanie zakończone`,
        change_password_success: `Hasło zostało pomyślnie zmienione`,
        successful_login_title: `Sukces logowania`,
        product_page: `Strona produktu>>`,
        successful_login_info: `Logowanie zakończone. Zamknij bieżącą stronę i wróć do oryginalnej karty, aby kontynuować następujący proces. Bieżąca strona zostanie automatycznie zamknięta za 5 sekund. Jeśli automatyczne zamknięcie lub zamknięcie przez kliknięcie przycisku „Gotowe” nie powiedzie się, zamknij tę kartę bezpośrednio.`,
        successful_login_info_firefox: `Logowanie zakończone. Zamknij bieżącą stronę i wróć do oryginalnej karty, aby kontynuować następujący proces.`,
        my_account: `Moje konto`,
        my_history: `Moja historia`,
        remove_watermark: `Usuń znak wodny`,
        no_history: `Brak historii`,
        history_all: `Zaznacz wszystko`,
        history_open: `otwarty`,
        history_down: `Ściągnij`,
        history_delete: `Kasować`,
        history_clear: `Usuń nieprawidłowe`,
        images: `obrazy)`,
        use_this_function: `Użyj tej funkcji>>`,
        hd_downloading: `Korzyści z pobierania oryginalnych obrazów HD:`,
        lifetimeRemaining: `Pozostały czas życia`,
        Remaining: `Pozostały`,
        email_verification: `Weryfikacja adresu e-mail`,
        email_verification_info: `Wysłaliśmy wiadomość weryfikacyjną na Twój adres e-mail <span class="email"></span> i prosimy o dokończenie weryfikacji. Po weryfikacji korzyści zostaną automatycznie zsynchronizowane.`,
        wrong_email: `Zły adres email?`,
        click_here_to_modify: `Kliknij tutaj, aby zmodyfikować`,
        get_more_help: `Uzyskaj dodatkową pomoc?`,
        click_here: `Kliknij tutaj`,
        email_verification_info_success: `Gratulujemy weryfikacji konta e-mail.`,
        email_verification_info_error: `Weryfikacja nie powiodła się z powodu wygasłego linku.`,
        registration_succeeded: `Rejestracja powiodła się`,
        registration_succeeded_info_1: `Gratulacje! Zarejestrowałeś się pomyślnie. Wysłaliśmy wiadomość weryfikacyjną na Twój adres e-mail <span class="email"></span> i zakończ weryfikację, aby skorzystać z zalet tego e-maila.`,
        registration_succeeded_info_2: `Kliknij „Gotowe”, aby powrócić do strony głównej i korzystać z tego produktu.`,
        registration_succeeded_info_3: `Kliknij „Gotowe”, aby zamknąć bieżącą stronę i powrócić do strony głównej, aby wykonać następujące operacje. Jeśli zamknięcie się nie powiedzie, zamknij kartę ręcznie.`,
        verify_email: `zweryfikuj adres e-mail`,
        registered_email_not_verified: `Zarejestrowany adres e-mail nie został zweryfikowany, zweryfikuj go natychmiast.`,
        email_verification_time_1: `Nie otrzymałeś e-maila weryfikacyjnego?`,
        email_verification_time_2: `Później`,
        email_verification_time_3: `Kliknij tutaj, aby wysłać go ponownie`,
        error_26301: `Kod błędu: 26301, prosimy o kontakt`,
        error_26302: `Kod błędu: 26302, skontaktuj się z nami`,
        error_26303: `Błąd formatu e-mail (kod błędu: 26303). Proszę wpisać go ponownie`,
        error_26304: `Zalecane jest hasło powyżej 8 znaków (kod błędu: 26304)`,
        error_26305: `Reuqest nie powiodło się (kod błędu: 26305). Proszę spróbować ponownie`,
        error_26306: `E-mail został zarejestrowany, <a href="https://account.tipard.com/login">przejdź do logowania</a>`,
        error_26307: `Reuqest nie powiodło się (kod błędu: 26307). Proszę spróbować ponownie`,
        error_26308: `Reuqest nie powiodło się (kod błędu: 26308). Proszę spróbować ponownie`,
        error_26401: `Kod błędu: 26401, spróbuj ponownie`,
        error_26402: `E-mail został zweryfikowany (kod błędu: 26402), spróbuj ponownie`,
        error_26403: `Reuqest nie powiodło się (kod błędu: 26403). Proszę spróbować ponownie`,
        error_26404: `Żądanie nie powiodło się (kod błędu: 26404). Proszę spróbować ponownie`,
        error_26501: `Kod błędu: 26501, prosimy o kontakt`,
        error_26502: `Kod błędu: 26502, prosimy o kontakt`,
        error_26503: `Błąd formatu e-mail (kod błędu: 26503). Proszę wpisać go ponownie`,
        error_26504: `Reuqest nie powiodło się (kod błędu: 26504). Proszę spróbować ponownie`,
        error_26505: `E-mail nie został zarejestrowany, proszę <a href="https://account.tipard.com/register">najpierw go zarejestruj</a>`,
        error_26506: `E-mail został zweryfikowany.`,
        error_26507: `Reuqest nie powiodło się (kod błędu: 26507). Proszę spróbować ponownie`,
        error_26508: `Weryfikacja nie powiodła się (kod błędu: 26508), spróbuj ponownie`,
        error_26509: `Żądanie nie powiodło się (kod błędu: 26509), spróbuj ponownie`,
        error_26510: `Kod błędu: 26510, prosimy o kontakt`,
        error_26601: `Kod błędu: 26601, prosimy o kontakt`,
        error_26602: `Kod błędu: 26602, skontaktuj się z nami`,
        error_26603: `Reuqest nie powiodło się (kod błędu: 26603). Proszę spróbować ponownie`,
        error_26604: `Kod błędu: 26604, prosimy o kontakt`,
        error_26605: `Kod błędu: 26605, prosimy o kontakt`,
        error_26701: `Kod błędu: 26701, skontaktuj się z nami`,
        error_26702: `Reuqest nie powiodło się (kod błędu: 26702). Proszę spróbować ponownie`,
        error_26703: `Kod błędu: 26703, prosimy o kontakt`,
        error_26704: `Kod błędu: 26704, prosimy o kontakt`,
        error_26705: `Poczekaj na zalogowanie się (kod błędu: 26705). Proszę spróbować ponownie`,
        no_cookie: `W przeglądarce została włączona funkcja Blokuj wszystkie pliki cookie, więc nie możesz się zalogować. Przejdź do Ustawień, aby zaznaczyć pole Zezwalaj na wszystkie pliki cookie.`,
        error_26801: `Kod błędu: 26801, prosimy o kontakt`,
        error_26802: `Kod błędu: 26802, prosimy o kontakt`,
        error_26803: `Żądanie nie powiodło się (kod błędu: 26803). Proszę spróbować ponownie`,
        error_26804: `Żądanie nie powiodło się (kod błędu: 26804). Proszę spróbować ponownie`,
        error_order: `Żądanie nie powiodło się (kod błędu: 27098), spróbuj ponownie!`,
        error_order1: `Zapytanie o zamówienie jest niekompletne, kod błędu:`,
        error_order2: `）, odśwież i spróbuj ponownie.`,
        modify_email_title: `Zmodyfikuj e-mail`,
        modify_email_info: `Możesz użyć zmodyfikowanego adresu e-mail, aby zalogować się na swoje konto.`,
        images_per: `Obrazy na`,
        error_26101: `Błąd: 26101. Skontaktuj się z nami`,
        error_26102: `Błąd: 26102. Skontaktuj się z nami`,
        error_26103: `Żądanie nie powiodło się (kod błędu: 26103). Spróbuj ponownie`,
        error_26104: `Kod błędu: 26104, spróbuj ponownie`,
        error_26105: `Kod błędu: 26105, spróbuj ponownie`,
        error_26106: `Nie udało się usunąć (kod błędu: 26106). Spróbuj ponownie`,
        error_26201: `Błąd: 26201. Skontaktuj się z nami`,
        error_26202: `Żądanie nie powiodło się (kod błędu: 26202). Spróbuj ponownie`,
        error_26001: `Błąd: 26001. Skontaktuj się z nami`,
        error_26002: `Błąd: 26002. Skontaktuj się z nami`,
        error_26003: `Błąd: 26003. Skontaktuj się z nami`,
        error_26004: `Błąd: 26004. Skontaktuj się z nami`,
        error_26005: `Żądanie nie powiodło się (kod błędu: 26005). Spróbuj ponownie`,
        error_26006: `Kod błędu: 26006, spróbuj ponownie`,
        error_26008: `Błąd: 26008. Skontaktuj się z nami`,
        go_to_the_home_page: `Przejdź do strony głównej`,
        error_27101: `Żądanie nie powiodło się (kod błędu: 27101). Spróbuj ponownie ist`,
        error_27201: `Kod błędu: 27201, prosimy o kontakt`,
        error_27202: `Kod błędu: 27202, spróbuj ponownie`,
        error_27203: `Żądanie nie powiodło się (kod błędu: 27203). Proszę spróbować ponownie`,
        error_27204: `Nieprawidłowy kod (kod błędu: 27204).`,
        error_27205: `Żądanie nie powiodło się (kod błędu: 27205). Proszę spróbować ponownie`,
        error_27206: `Żądanie nie powiodło się (kod błędu: 27206). Proszę spróbować ponownie`,
        error_27207: `Żądanie nie powiodło się (kod błędu: 27207). Proszę spróbować ponownie`,
        no_history_found: `Nie użyłeś żadnego narzędzia! <a href="/">Odśwież</a> lub <a href="https://pl.tipard.com/">Przejdź do oficjalnej strony internetowej</a>`,
        error_25301: `Błąd: 25301. Skontaktuj się z nami`,
        error_25302: `Błąd: 25302. Skontaktuj się z nami`,
        error_25303: `Żądanie nie powiodło się (kod błędu: 25303). Spróbuj ponownie`,
        error_25304: `Żądanie nie powiodło się (kod błędu: 25304). Spróbuj ponownie`,
        error_25305: `Żądanie nie powiodło się (kod błędu: 25305). Spróbuj ponownie`,
        error_25306: `Żądanie nie powiodło się (kod błędu: 25306). Spróbuj ponownie`,
        image_upscaler_p: `Obraz bez korzyści związanych z pobieraniem znaku wodnego:`,
        Available_for: `Dostępne dla:`,
        credit_per: `%s kredytów na obraz HD`,
        still_valid: `Zakupione plany są nadal ważne`,
        credit: `kredyt(y)`,
        pc_3rd_info: `Zaloguj się pomyślnie. Proszę przejść do aplikacji w celu dalszej obsługi.`,
        use_online: `Skorzystaj z usługi online`,
        use_download: `Skorzystaj z programu komputerowego`,
        use_immediately: `Użyj natychmiast`,
        Use_in_browser: `Użyj w przeglądarce`,
        win_desktop: `Okna`,
        Mac_desktop: `Prochowiec`,
        credits_per: `{%} Kredytów miesięcznie`,
        expire: `Data ważności:`,
        viewDetails: `Pokaż szczegóły`,
        viewHistory: `Zobacz korzyści z historii >>`,
        viewDetailsInfo: `Uwaga: Jeśli korzyści z subskrypcji zostaną odnowione w ciągu 7 dni od wygaśnięcia, niewykorzystane korzyści będą mogły być nadal wykorzystywane. Ponadto czas wygaśnięcia zostanie automatycznie zaktualizowany do czasu wygaśnięcia nowej subskrypcji. Jeśli po 7 dniach od wygaśnięcia nie zostanie złożona nowa subskrypcja, wszystkie wygasłe korzyści zostaną usunięte.`,
        "connect_account": "Powiąż e-mail ze swoim kontem",
        "connect_account_info": "Po powiązaniu możesz zalogować się przy użyciu tego adresu e-mail.",
        "connect_now": "Powiąż teraz",
        "no_email_bind": "Brak powiązania e-mailowego",
        "bind_email": "Powiąż e-mail",
        "connect_your_email_placeholder": "Podaj swój adres e-mail",
        "bind_an_email": "Powiąż e-mail",
        "bind_info": "Zalogowałeś się pomyślnie. Aby aktywować korzyści, powiąż adres e-mail ze swoim kontem.",
        "bind_later": "Zwiąż później",
        "footer": "Copyright © 2024 Tipard Studio. Wszelkie prawa zastrzeżone.",
        "overtime": "Kod błędu: {%}, spróbuj ponownie",
        "hi": "Jego!",
        "Personal_Information": "Informacje osobiste",
        "Access": "Dostęp",
        "Subscription_Plan": "(Plan subskrypcji)",
        "No_orders": "Nie znaleziono zamówień.",
        "No_data": "Brak danych",
        "Featured_Products": "Polecane produkty",
        "More_Products": "Więcej produktów",
        "Free_Download": "Bezpłatne pobieranie",
        "Get_Started": "Rozpocznij",
        "Subscribe": "Subskrybować",
        "Verified": "Zweryfikowano",
        "back_to_account_center": "Powrót do Centrum kont",
        "success": "Sukces!",
        "successfully": "Pomyślnie zarejestrowałeś konto.",
        "Continue": "Kontynuować",
        "Already": "Masz już konto?",
        "loading_verification": "Sprawdzanie statusu weryfikacji...",
        "email_no_verification": "Przepraszamy, zarejestrowany adres e-mail nie został zweryfikowany. Proszę ukończyć weryfikację zgodnie z powyższymi instrukcjami i kliknąć przycisk „Gotowe” ponownie, aby ukończyć rejestrację.",
        "error_20505": "E-mail został połączony",
        "error_20506": "Błąd: 20506. Prosimy o kontakt",
        "error_28401": "Proszę podać swój adres e-mail",
        "error_28402": "Nieprawidłowy format wiadomości e-mail",
        "error_28403": "Błąd: 28403. Prosimy o kontakt",
        "error_28404": "E-mail został zapisany",
        "error_28405": "Błąd: 28405. Prosimy o kontakt",
        "error_28406": "E-mail został połączony",
        "error_28407": "Błąd: 28407. Prosimy o kontakt",
        "error_28408": "Nie udało się wysłać wiadomości e-mail",
        "error_28601": "Proszę podać swój adres e-mail",
        "error_28602": "Proszę wprowadzić kod weryfikacyjny",
        "error_28603": "Nieprawidłowy format wiadomości e-mail",
        "error_28604": "Błąd: 28604. Prosimy o kontakt",
        "error_28605": "Kod weryfikacyjny jest nieprawidłowy",
        "error_28606": "Błąd: 28606. Prosimy o kontakt",
        "error_28607": "E-mail został zapisany",
        "error_28608": "Do Twojego konta nie jest przypisany żaden adres e-mail",
        "error_28609": "Adres e-mail nie jest zweryfikowany",
        "error_28610": "Błąd: 28610. Prosimy o kontakt",
        "error_28611": "E-mail został połączony",
        "error_28612": "Błąd: 28612. Prosimy o kontakt",
        "unbind_email_btn": "Odłącz e-mail",
        "binding_exchange": "Powiąż e-mail",
        "Subscription_Status": "Status subskrypcji",
        "mask_unbind_info_1": "Czy na pewno chcesz odłączyć {%} od tego konta? Po odłączeniu nie będziesz mógł już sprawdzić korzyści z tego adresu e-mail",
        "mask_unbind_info_2": "Czy na pewno chcesz odłączyć wszystkie komputery PC powiązane z tym kodem rejestracyjnym?<a href=\"https://pl.tipard.com/faq.html#unbind\">Dowiedz się więcej o zmianie urządzeń.</a>",
        "error_dc_link": "Błąd, skontaktuj się z nami",
        "vcu_state": "Zalety pełnej wersji:",
        "monthly_subscription": "Licencja na 1 miesiąc",
        "year_subscription": "Licencja na 1 rok",
        "to": "Do",
        "lifetime_subscription": "Licencja dożywotnia",
        "unbind": "Potwierdzać",
        "unbind_success": "Pomyślnie rozłączono",
        "error_28401": "Proszę podać swój adres e-mail",
        "error_28702": "Błąd: 28702. Prosimy o kontakt",
        "error_28703": "Kod weryfikacyjny jest nieprawidłowy",
        "error_28704": "Błąd: 28704. Prosimy o kontakt",
        "error_28705": "E-mail nie został powiązany",
        "error_28706": "Proszę wprowadzić kod weryfikacyjny",
        "error_28707": "Nie udało się odłączyć",
        "error_28901": "Błąd: 28901. Prosimy o kontakt",
        "error_28902": "Błąd: 28902. Prosimy o kontakt",
        "error_28903": "Błąd: 28903. Prosimy o kontakt",
        "error_28904": "Błąd: 28904. Prosimy o kontakt",
        "error_28905": "Błąd: 28905. Prosimy o kontakt",
        "error_28906": "Błąd: 28906. Prosimy o kontakt",
        "error_28907": "Nieprawidłowy kod rejestracyjny",
        "error_28908": "Błąd: 28908. Prosimy o kontakt",
        "error_28909": "Obecnie w okresie cooldownu unbind. Możesz unbindować tylko raz w miesiącu.",
        "error_289010": "Błąd: 289010. Prosimy o kontakt",
        "error_289011": "Błąd: 289011. Prosimy o kontakt",
        "error_289012": "Błąd: 289012. Prosimy o kontakt",
        "activated_computer": "Aktywowano na %s/%s komputerach",
        "bind_email_info": "Korzyści wynikające z poniższych wiadomości e-mail będą przypisane i wykorzystywane w ramach konta VIP.",
        "error_26309": "Błąd: 26309. Prosimy o kontakt",
        "error_26310": "Nieprawidłowy kod polecający",
        "error_26311": "Błąd: 26311. Prosimy o kontakt",
        "error_26312": "Nieprawidłowy kod polecający",
        "error_26313": "Błąd: 26313. Prosimy o kontakt",
        "error_26314": "E-mail został połączony",
        "activate_unbinding": "Zmień urządzenie",
        "copy_registration_code": "Skopiuj kod rejestracyjny",
        "show_registration_code": "Pokaż kod rejestracyjny",
        "hide_registration_code": "Ukryj kod rejestracyjny",
        "other_binding_email": "Inne wiążące e-maile",
        "home": "Dom",
        "points_balance": "Saldo punktów:",
        "earn_now": "Zarabiaj teraz >>",
        "earn_more": "Zdobądź więcej darmowych punktów, odbierz więcej prezentów",
        "earn_or_redeem_points": "Zdobywaj/wykorzystuj punkty",
        "unbind_email_info": "Obecne konto zostało powiązane z adresem e-mail",
        "bind_email_title": "Jeśli chcesz powiązać więcej zamówień z adresami e-mail dotyczącymi zakupów, kliknij przycisk „Powiązanie adresów e-mail” poniżej.",
        "bind_email_tip": "<span>Wskazówka:</span> Obecnie obsługiwane są jedynie zakupy polegające na zamówieniach z kodem rejestracyjnym e-mail.",
        "email_association": "Stowarzyszenie e-mailowe",
        "dissociation": "Dysocjacja",
        "back": "Z powrotem",
        "activate_benefits": "Wprowadź adres e-mail zakupu, który musi zostać powiązany, aby uzyskać kod weryfikacyjny. Wprowadź kod weryfikacyjny otrzymany w wiadomości e-mail w polu wprowadzania kodu weryfikacyjnego i kliknij przycisk „Sprawdź aktywację”, aby aktywować korzyści.",
        "e_mail": "E-mail:",
        "verification_code": "Kod weryfikacyjny:",
        "obtain_verification_code": "Uzyskaj kod weryfikacyjny",
        "verification_association": "Zweryfikuj skojarzenie",
        "current_password": "Aktualne hasło:",
        "account_center": "Centrum kont",
        "new_password": "Nowe hasło:",
        "confirm_password": "Potwierdź nowe hasło:",
        "Send": "Wysłać",
        "history_files": "Pliki historii",
        "delete_account_info_1": "1. Usunięcie konta Tipard jest działaniem trwałym i nie można go cofnąć. Wszystkie Twoje dane, w tym zdjęcia i preferencje, zostaną utracone. Czy na pewno chcesz usunąć swoje konto Tipard?",
        "delete_account_info_2": "2. Jeśli zdecydujesz się usunąć konto Tipard, kliknij przycisk Wyślij i wprowadź kod weryfikacyjny z wiadomości e-mail, którą do Ciebie wysłaliśmy.",
        "best_deals": "Najlepsze oferty",
        "close": "Zamknąć",
        "product":"Produkt:"
    },
    ru: {
        overtime_1: `Код ошибки:`,
        overtime_2: `Пожалуйста, попробуйте еще раз`,
        isnetwork: `Ошибка Интернета. Пожалуйста, проверьте и повторите попытку`,
        email_placeholder: `Эл. адрес`,
        email_empty: `Пожалуйста, введите адрес электронной почты`,
        email_not_valid: `Email не является допустимым`,
        email_not_valid_1: `Пожалуйста, введите свой адрес электронной почты`,
        email_not_valid_2: `Электронная почта недействительна, используйте другой адрес.`,
        email_not_valid_3: `Нет ввода электронной почты`,
        password_placeholder: `Пароль`,
        password_empty: `Пожалуйста, введите пароль`,
        password_not_valid: `Неверный аккаунт или пароль`,
        password_not_valid_1: `Требуется пароль длиной более 8 символов.`,
        password_not_valid_2: `Пожалуйста, придумайте пароль`,
        password_placeholder_1: `Создайте свой пароль`,
        password_placeholder_2: `Подтвердите ваш пароль`,
        password_placeholder_3: `Создать новый пароль`,
        password_placeholder_4: `Подтвердите новый пароль`,
        password_placeholder_5: `Введите старый пароль`,
        copy_password_empty: `Пожалуйста, подтвердите пароль`,
        copy_password_not_valid: `Пожалуйста, подтвердите свой пароль`,
        copy_passwords_inconsistent: `Подтверждение вашего пароля не совпадает`,
        code_empty: `Пожалуйста, введите проверочный код`,
        code_not_valid: `неверный проверочный код`,
        code_placeholder: `Код подтверждения`,
        not_received_code: `Если ваш почтовый ящик долгое время не получал проверочный код, пожалуйста, получите проверочный код еще раз.`,
        get_first_code: `Пожалуйста, сначала получите код подтверждения.`,
        last_name_placeholder: `Пожалуйста, введите свою фамилию`,
        first_name_placeholder: `Пожалуйста, введите ваше имя`,
        address_placeholder: `Пожалуйста, введите ваш адрес`,
        no_code_text: `Мы отправили проверочный код. Введите свой код. <span class='tips'>Не получили код?`,
        no_code_text_1: `1.Убедитесь, что адрес электронной почты действителен и на него можно получать электронные письма.`,
        no_code_text_2: `2. Поскольку электронное письмо отправляется системой автоматически, оно может быть помечено как спам или нежелательная почта. Пожалуйста, проверьте, находится ли электронное письмо в папке "Корзина".`,
        no_code_text_3: `3. Не можете решить вашу проблему?`,
        no_code_text_3_span: `Тогда нажмите здесь, чтобы связаться с нами.`,
        order_no: `Вы не купили никаких товаров. По любым вопросам <a href="https://ru.tipard.com/store.html"> Делайте покупки сейчас </a> Или <a href="https://ru.tipard.com/contact.html"> Свяжитесь с нами </a>`,
        error_24901: `Текущий счет не связан с электронной почтой и не может найти заказы. Пожалуйста, подключите электронную почту.`,
        user_guide: `Руководство пользователя >>`,
        download: `Скачать`,
        order_number: `Номер заказа`,
        Refund: `Возвращать деньги`,
        Disabled: `Отключено`,
        Normal: `Обычный`,
        Modify: `Изменить`,
        Modify_1: `Изменить >>`,
        Connect: `Соединять`,
        unlink_success: `Отключить успешно`,
        connect_success: `Подключиться успешно`,
        feedback_title: `Спасибо за ваш отзыв. Оставьте проблему, и мы ответим вам в течение 24 часов.`,
        feedback_thank_you: `Спасибо! <br /> Ваш отзыв был успешно отправлен.`,
        feedback_email: `Введите сюда свой адрес электронной почты!`,
        feedback_content: `Оставьте здесь любую проблему или предложение, с которыми вы столкнулись.`,
        feedback_submit: `Представлять на рассмотрение`,
        form_contents: `Вы не ввели описание. Пожалуйста, введите его и отправьте еще раз.`,
        old_password: `Пожалуйста, введите старый пароль`,
        new_password: `Пожалуйста, создайте новый пароль`,
        old_new_password: `Новый пароль не может совпадать со старым.`,
        incorrect_password: `неверный пароль`,
        delete_no: `Удалить сейчас`,
        Caps: `Включен верхний регистр`,
        Get: `Получать`,
        Done: `Выполнено`,
        error_20001: `Ошибка: 20001. Пожалуйста, войдите снова.`,
        error_20002: `Ошибка: 20002. Пожалуйста, войдите снова.`,
        error_20003: `Ошибка: 20003. Пожалуйста, войдите снова.`,
        error_20004: `Запрос не выполнен (код ошибки: 20004). Пожалуйста, попробуйте еще раз.`,
        error_20005: `Срок действия сеанса входа истек (ошибка: 20005). Пожалуйста, войдите снова.`,
        error_20006: `Запрос не выполнен (код ошибки: 20006). Пожалуйста, попробуйте еще раз.`,
        error_20007: `Срок действия сеанса входа истек (ошибка: 20007). Пожалуйста, войдите снова.`,
        error_20008: `Срок действия сеанса входа истек (ошибка: 20008). Пожалуйста, войдите снова.`,
        error_20009: `Срок действия сеанса входа истек (ошибка: 20009). Пожалуйста, войдите снова.`,
        error_20101: `Введите свой адрес электронной почты (код ошибки: 20101)`,
        error_20102: `Электронная почта недействительна (код ошибки: 20102)`,
        error_20103: `Ошибка запроса (код ошибки: 20103). Пожалуйста, попробуйте еще раз`,
        error_20104: `Электронная почта уже используется, пожалуйста, <a href="https://account.tipard.com/login"> войдите в систему </a> или зарегистрируйтесь, используя новую.`,
        error_20105: `Ошибка запроса (код ошибки: 20105). Пожалуйста, попробуйте еще раз`,
        error_20106: `Не удалось отправить электронное письмо. Повторите попытку.`,
        error_20201: `Введите свой адрес электронной почты (код ошибки: 20201)`,
        error_20202: `Пожалуйста, введите свой пароль (код ошибки: 20202)`,
        error_20203: `Пожалуйста, введите проверочный код (код ошибки: 20203)`,
        error_20204: `Электронная почта недействительна (код ошибки: 20204)`,
        error_20205: `Требуется пароль длиной более 8 символов (код ошибки: 20205)`,
        error_20206: `Ошибка запроса (код ошибки: 20206). Пожалуйста, попробуйте еще раз`,
        error_20207: `неверный проверочный код`,
        error_20208: `Ошибка запроса (код ошибки: 20208). Пожалуйста, попробуйте еще раз`,
        error_20209: `Ошибка запроса (код ошибки: 20209). Пожалуйста, попробуйте еще раз`,
        error_20301: `Введите свой адрес электронной почты (код ошибки: 20301)`,
        error_20302: `Ошибка: 20302. Свяжитесь с нами.`,
        error_20303: `Электронная почта недействительна (код ошибки: 20303)`,
        error_20304: `Ошибка запроса (код ошибки: 20304). Пожалуйста, попробуйте еще раз`,
        error_20305: `Пользователь не существует. Пожалуйста, войдите повторно или сначала <a href="https://account.tipard.com/register">Создать это</a>.`,
        error_20306: `Пароль еще нет. Используйте <a href="https://account.tipard.com/passwordless-login"> вход без пароля </a> или <a href="create-password"> задайте пароль </a> и войдите в систему.`,
        error_20308: `Ошибка запроса (код ошибки: 20308). Пожалуйста, попробуйте еще раз`,
        error_20401: `Не удалось выйти из системы (код ошибки: 20401). Пожалуйста, попробуйте еще раз`,
        error_20501: `Введите свой адрес электронной почты (код ошибки: 20501)`,
        error_20502: `Электронная почта недействительна (код ошибки: 20502)`,
        error_20503: `Ошибка запроса (код ошибки: 20503). Пожалуйста, попробуйте еще раз`,
        error_20504: `Не удалось отправить электронное письмо. Пожалуйста, попробуйте еще раз.`,
        error_20601: `Введите свой адрес электронной почты (код ошибки: 20601)`,
        error_20602: `Введите проверочный код (код ошибки: 20602)`,
        error_20603: `Электронная почта недействительна (код ошибки: 20603)`,
        error_20604: `Ошибка запроса (код ошибки: 20604). Пожалуйста, попробуйте еще раз`,
        error_20606: `Ошибка запроса (код ошибки: 20606). Пожалуйста, попробуйте еще раз`,
        error_20607: `Ошибка запроса (код ошибки: 20607). Пожалуйста, попробуйте еще раз`,
        error_20608: `Ошибка запроса (код ошибки: 20608). Пожалуйста, попробуйте еще раз`,
        error_20701: `Введите свой адрес электронной почты (код ошибки: 20701)`,
        error_20702: `Электронная почта недействительна (код ошибки: 20702)`,
        error_20703: `Ошибка запроса (код ошибки: 20703). Пожалуйста, попробуйте еще раз`,
        error_20704: `Пользователь не существует. Пожалуйста, войдите повторно или сначала <a href="https://account.tipard.com/register">Создать это</a>.`,
        error_20705: `Ошибка запроса (код ошибки: 20705). Пожалуйста, попробуйте еще раз`,
        error_20706: `Не удалось отправить электронное письмо. Пожалуйста, попробуйте еще раз`,
        error_20801: `Введите свой адрес электронной почты (код ошибки: 20801)`,
        error_20802: `Ошибка: 20802. Свяжитесь с нами.`,
        error_20803: `Введите проверочный код (код ошибки: 20803)`,
        error_20804: `Электронная почта недействительна (код ошибки: 20804)`,
        error_20805: `Требуется пароль длиной более 8 символов (код ошибки: 20805)`,
        error_20806: `Ошибка запроса (код ошибки: 20806). Пожалуйста, попробуйте еще раз`,
        error_20808: `Ошибка запроса (код ошибки: 20808). Пожалуйста, попробуйте еще раз`,
        error_20901: `Ошибка запроса (код ошибки: 20901). Пожалуйста, попробуйте еще раз`,
        error_20902: `Ошибка запроса (код ошибки: 20902). Пожалуйста, попробуйте еще раз`,
        error_21000: `Изменения сохранены`,
        error_21001: `Информация не отправлена (код ошибки: 21001)`,
        error_21002: `Ошибка запроса (код ошибки: 21002). Пожалуйста, попробуйте еще раз`,
        error_21101: `Введите свой адрес электронной почты (код ошибки: 21101)`,
        error_21102: `Электронная почта недействительна (код ошибки: 21102)`,
        error_21103: `Запрос не выполнен (код ошибки: 21103), пожалуйста, попробуйте еще раз.`,
        error_21104: `Электронная почта уже подключена, используйте новую`,
        error_21105: `Запрос не выполнен (код ошибки: 21105), пожалуйста, повторите его еще раз`,
        error_21106: `Не удалось отправить электронное письмо. Пожалуйста, попробуйте еще раз`,
        error_21201: `Введите свой адрес электронной почты (код ошибки: 21201)`,
        error_21202: `Пожалуйста, введите проверочный код (код ошибки: 21202)`,
        error_21203: `Электронная почта недействительна (код ошибки: 21203)`,
        error_21204: `Ошибка: 21204. Свяжитесь с нами.`,
        error_21205: `Ошибка: 21205. Свяжитесь с нами.`,
        error_21206: `Требуется пароль длиной более 8 символов (код ошибки: 21206)`,
        error_21207: `Ошибка запроса (код ошибки: 21207). Пожалуйста, попробуйте еще раз`,
        error_21209: `Ошибка запроса (код ошибки: 21209). Пожалуйста, попробуйте еще раз`,
        error_21301: `Введите старый пароль (код ошибки: 21301)`,
        error_21302: `Пожалуйста, создайте новый пароль (код ошибки: 21302)`,
        error_21303: `Новый пароль не может совпадать со старым. (Ошибка: 21303)`,
        error_21304: `Требуется пароль длиной более 8 символов (код ошибки: 21304)`,
        error_21306: `Ошибка запроса (код ошибки: 21306). Пожалуйста, попробуйте еще раз`,
        error_21402: `Ошибка запроса (код ошибки: 21402). Пожалуйста, попробуйте еще раз`,
        error_21403: `Не удалось отправить проверочный код. Пожалуйста, отправьте его повторно`,
        error_21500: `Аккаунт удален`,
        error_21501: `Пожалуйста, введите проверочный код (код ошибки: 21501)`,
        error_21502: `Срок действия сеанса входа истек (ошибка: 21502). Пожалуйста, войдите снова.`,
        error_21503: `Ошибка запроса (код ошибки: 21503). Пожалуйста, попробуйте еще раз`,
        error_21505: `Запрос не выполнен (код ошибки: 21505), пожалуйста, повторите попытку.`,
        error_21601: `Ошибка: 20601. Свяжитесь с нами.`,
        error_21602: `Недействительная проверка (ошибка: 20602). Пожалуйста, попробуйте еще раз.`,
        error_21603: `Ошибка: 20603. Повторите попытку.`,
        error_21604: `Ошибка запроса (код ошибки: 21604). Пожалуйста, попробуйте еще раз`,
        error_21606: `Ошибка запроса (код ошибки: 21606). Пожалуйста, попробуйте еще раз`,
        error_21611: `Ошибка запроса (код ошибки: 21611). Пожалуйста, попробуйте еще раз`,
        error_21801: `Ошибка: 21801. Свяжитесь с нами.`,
        error_21802: `Ошибка запроса (ошибка 21802). Пожалуйста, попробуйте еще раз`,
        error_21803: `Ошибка: 21803. Повторите попытку.`,
        error_21804: `Ошибка запроса (код ошибки: 21804). Пожалуйста, попробуйте еще раз`,
        error_21806: `Ошибка: 21806. Повторите попытку.`,
        error_21807: `Ошибка: 21807. Свяжитесь с нами.`,
        error_21808: `Ошибка: 21808. Свяжитесь с нами.`,
        error_21809: `Ошибка: 21809. Свяжитесь с нами.`,
        error_21810: `Ошибка: 21810. Свяжитесь с нами.`,
        error_21811: `Ошибка: 21811. Свяжитесь с нами.`,
        error_21812: `Ошибка: 21812. Свяжитесь с нами.`,
        error_21813: `Ошибка запроса (код ошибки: 21813). Пожалуйста, попробуйте еще раз`,
        error_21814: `Ошибка: 21814. Свяжитесь с нами.`,
        error_21815: `Ошибка запроса (код ошибки: 21815). Пожалуйста, попробуйте еще раз`,
        error_21816: `Ошибка: 21816. Свяжитесь с нами.`,
        error_21817: `Ошибка: 21817. Свяжитесь с нами.`,
        error_21818: `Ошибка: 21818. Свяжитесь с нами.`,
        error_21819: `Ошибка запроса (код ошибки: 21819). Пожалуйста, попробуйте еще раз`,
        error_21820: `Ошибка: 21820. Свяжитесь с нами.`,
        error_21821: `Ошибка: 21821. Свяжитесь с нами.`,
        error_21822: `Ошибка: 21822. Свяжитесь с нами.`,
        error_21823: `Ошибка запроса (код ошибки: 21823). Пожалуйста, попробуйте еще раз`,
        error_21824: `Ошибка запроса (код ошибки: 21824). Пожалуйста, попробуйте еще раз`,
        error_21825: `Ошибка запроса (код ошибки: 21825). Пожалуйста, попробуйте еще раз`,
        error_21826: `Ошибка запроса (код ошибки: 21826). Пожалуйста, попробуйте еще раз`,
        error_21901: `Ошибка: 21901. Свяжитесь с нами.`,
        error_21902: `Ошибка запроса (код ошибки: 21902). Пожалуйста, попробуйте еще раз`,
        error_21903: `Статус учетной записи изменился (код ошибки: 21903), обновите страницу и повторите попытку.`,
        error_21904: `Ошибка: 21904. Повторите попытку.`,
        error_21905: `Ошибка: 21905. Повторите попытку.`,
        error_21906: `Ошибка запроса (код ошибки: 21906). Пожалуйста, попробуйте еще раз`,
        error_21907: `Аккаунт Google был связан с другим аккаунтом`,
        error_21908: `Ошибка запроса (код ошибки: 21908). Пожалуйста, попробуйте еще раз`,
        error_22001: `Ошибка запроса (код ошибки: 22001). Пожалуйста, попробуйте еще раз`,
        error_22002: `Не удалось отменить связь без дополнительного входа`,
        error_22003: `Ошибка запроса (код ошибки: 22003). Пожалуйста, попробуйте еще раз`,
        error_22101: `Ошибка: 22101. Свяжитесь с нами.`,
        error_22102: `Статус учетной записи изменился (код ошибки: 22102), обновите страницу и повторите попытку.`,
        error_22103: `Ошибка запроса (код ошибки: 22103). Пожалуйста, попробуйте еще раз`,
        error_22104: `Статус учетной записи изменился (код ошибки: 22104), обновите страницу и повторите попытку.`,
        error_22105: `Ошибка: 22105. Повторите попытку.`,
        error_22106: `Ошибка: 22106. Повторите попытку.`,
        error_22107: `Ошибка: 22107. Свяжитесь с нами.`,
        error_22108: `Ошибка запроса (код ошибки: 22108). Пожалуйста, попробуйте еще раз`,
        error_22201: `Ошибка: 22201. Свяжитесь с нами.`,
        error_22202: `Недействительная проверка (ошибка: 22202). Пожалуйста, попробуйте еще раз.`,
        error_22203: `Ошибка: 22203. Пожалуйста, попробуйте еще раз »`,
        error_22204: `Ошибка запроса (код ошибки: 22204). Пожалуйста, попробуйте еще раз`,
        error_22206: `Ошибка запроса (код ошибки: 22206). Пожалуйста, попробуйте еще раз`,
        error_22401: `Ошибка: 22401. Свяжитесь с нами.`,
        error_22402: `Неверная проверка (ошибка: 22402). Пожалуйста, попробуйте еще раз.`,
        error_22403: `Ошибка: 22403. Повторите попытку.`,
        error_22404: `Ошибка запроса (код ошибки: 22404). Пожалуйста, попробуйте еще раз`,
        error_22405: `Аккаунт Facebook был связан с другим адресом электронной почты`,
        error_22406: `Ошибка: 22406. Повторите попытку.`,
        error_22407: `Ошибка: 22407. Свяжитесь с нами.`,
        error_22408: `Ошибка: 22408. Свяжитесь с нами.`,
        error_22409: `Ошибка: 22409. Свяжитесь с нами.`,
        error_22410: `Ошибка: 224010. Свяжитесь с нами.`,
        error_22411: `Ошибка: 224011. Свяжитесь с нами.`,
        error_22412: `Ошибка: 224012. Свяжитесь с нами.`,
        error_22413: `Ошибка: 22413. Свяжитесь с нами.`,
        error_22414: `Ошибка запроса (код ошибки: 22414). Пожалуйста, попробуйте еще раз`,
        error_22415: `Ошибка: 22415. Свяжитесь с нами.`,
        error_22416: `Ошибка: 22416. Свяжитесь с нами.`,
        error_22417: `Ошибка: 22417. Свяжитесь с нами.`,
        error_22418: `Ошибка запроса (код ошибки: 22418). Пожалуйста, попробуйте еще раз`,
        error_22419: `Ошибка: 22419. Свяжитесь с нами.`,
        error_22420: `Ошибка: 22420. Свяжитесь с нами.`,
        error_22421: `Ошибка: 22421. Свяжитесь с нами.`,
        error_22422: `Ошибка запроса (код ошибки: 22422). Пожалуйста, попробуйте еще раз`,
        error_22423: `Ошибка запроса (код ошибки: 22423). Пожалуйста, попробуйте еще раз`,
        error_22424: `Ошибка запроса (код ошибки: 22424). Пожалуйста, попробуйте еще раз`,
        error_22425: `Ошибка запроса (код ошибки: 22425). Пожалуйста, попробуйте еще раз`,
        error_20098: `Код ошибки: 20098. Если вы просматриваете Интернет в приватном режиме, переключитесь в нормальный режим и попробуйте еще раз.`,
        error_22298: `Запрос Google не удался (ошибка: 22298). Пожалуйста, попробуйте еще раз.`,
        error_22498: `Запрос Facebook не удался (ошибка: 22498). Пожалуйста, попробуйте еще раз.`,
        error_24902: `Ошибка запроса (код ошибки: 24902). Пожалуйста, попробуйте еще раз`,
        error_24903: `Ошибка запроса (код ошибки: 24903). Пожалуйста, попробуйте еще раз`,
        error_24904: `Ошибка запроса (код ошибки: 24904). Пожалуйста, попробуйте еще раз`,
        error_24905: `Ошибка запроса (код ошибки: 24905). Пожалуйста, попробуйте еще раз`,
        login_title: `Войти в Tipard`,
        password_placeholder: `Пароль`,
        log_in: `Авторизоваться`,
        no_account: `Нет аккаунта?`,
        create_it: `Создать это`,
        or_log_in_with: `Или войдите с помощью`,
        passwordless_login: `Вход без пароля`,
        log_in_done: `Войти Готово`,
        three_rd_account_connect_info: `Поздравляю! Вы успешно вошли в систему. Теперь вы можете подключить одну учетную запись электронной почты, которая будет использоваться для входа в систему в будущем.`,
        see_my_account: `Посмотреть мой аккаунт`,
        three_rd_login_merge_account: `Адрес электронной почты сторонней учетной записи был зарегистрирован, вы хотите подключиться и войти в систему с этим адресом электронной почты напрямую?`,
        connect_log_in: `Подключиться и войти`,
        create_an_account: `Завести аккаунт`,
        back_to_log_in: `Вернуться на страницу входа`,
        create_password: `Придумать пароль`,
        create_now: `Создать сейчас`,
        password_login_subtitle: `Вход без пароля по электронной почте`,
        account_login: `Логин аккаунта`,
        rights: `Создавая эту учетную запись, вы соглашаетесь с <a href="https://ru.tipard.com/terms.html"> Условиями использования </a> и <a href="https://ru.tipard.com/policy.html"> Политика конфиденциальности </a>`,
        passwordless_login_done: `Вход без пароля выполнен`,
        passwordless_login_info: `Поздравляем, вы успешно выполнили вход без пароля. Вы можете создать пароль для этой учетной записи и в будущем войти в систему с этой учетной записью и паролем. <a href="/create-password" style="display: initial;"> Создать сейчас </a>`,
        sign_up: `Зарегистрироваться`,
        register_info: `Создать аккаунт`,
        reset_now: `Сбросить сейчас`,
        forgot_password: `Забыл пароль`,
        reset_password_subtitle: `Используйте адрес электронной почты вашего аккаунта, чтобы сбросить пароль`,
        plan_products: `План и продукты`,
        nick_name: `Имя пользователя`,
        email: `Эл. адрес`,
        my_products: `Мои продукты`,
        my_orders: `Мои заказы`,
        unlink: `Отменить связь`,
        link: `Ссылка`,
        connected_accounts: `Подключенные аккаунты`,
        last_name: `Фамилия`,
        first_name: `Имя`,
        Gender: `Пол`,
        Birth: `Рождение`,
        Month: `Месяц`,
        Year: `Год`,
        Country_Region: `Страна / регион`,
        Address: `Адрес`,
        Save: `Сохранить`,
        Date: `Дата`,
        Male: `Мужчина`,
        Female: `женский`,
        Unspecified: `Неопределенные`,
        Security: `Безопасность`,
        change_password: `Измени пароль`,
        change_now: `Изменить сейчас`,
        connect_email: `Подключить электронную почту`,
        delete_account: `Удалить аккаунт`,
        delete_account_info: `Когда ваша учетная запись будет удалена, все данные в Планах и продуктах, связанные с вашей учетной записью, будут безвозвратно удалены, и вы не сможете их восстановить. Мы рекомендуем действовать осторожно.`,
        Delete: `Удалить`,
        Logout: `Выйти`,
        my_profile: `Мой профайл`,
        guides_faqs: `Руководства и часто задаваемые вопросы`,
        More: `Более`,
        guides: `Гиды`,
        register: `регистр`,
        hot_faq: `Горячие FAQ`,
        Contents: `Содержание:`,
        contact_us: `Свяжитесь с нами >>`,
        plan: `План`,
        unregistered: `Незарегистрированный`,
        buy_more: `Купить больше`,
        buy_again: `Купить снова`,
        buy_now: `купить сейчас`,
        free_no_limit: `Бесплатно и без ограничений`,
        expired: `Истекший`,
        lifetime: `Продолжительность жизни`,
        remain: `Оставаться`,
        day_s: `День (дни)`,
        day: `День`,
        error_24801: `Ошибка запроса (код ошибки: 24801). Пожалуйста, попробуйте еще раз`,
        no_app_found: `Приложение не найдено！ <a href="/"> Обновите </a> или <a href="https://ru.tipard.com/"> перейдите на официальный сайт </a>`,
        get_more: `Получить больше >>`,
        edit_photo: `Редактировать фотографию`,
        select_photo: `Выбрать фото`,
        change_photo: `Измени фотографию`,
        cancel: `Отмена`,
        hide_password: `Скрыть пароль`,
        show_password: `Показать пароль`,
        zoom_in: `Увеличить`,
        zoom_out: `Уменьшить`,
        rotate: `Повернуть`,
        horizontal_flip: `Горизонтальный флип`,
        vertical_flip: `Вертикальный флип`,
        country: `Страна`,
        country_1: `Выберите вашу страну / регион`,
        country_2: `Остров Аланд`,
        country_3: `Афганистан`,
        country_4: `Албания`,
        country_5: `Алжир`,
        country_6: `американское Самоа`,
        country_7: `Андорра`,
        country_8: `Ангола`,
        country_9: `Ангилья`,
        country_10: `Антарктида`,
        country_11: `Антигуа и Барбуда`,
        country_12: `Аргентина`,
        country_13: `Армения`,
        country_14: `Аруба`,
        country_15: `Австралия`,
        country_16: `Австрия`,
        country_17: `Азербайджан`,
        country_18: `Бахрейн`,
        country_19: `Бангладеш`,
        country_20: `Барбадос`,
        country_21: `Беларусь`,
        country_22: `Бельгия`,
        country_23: `Белиз`,
        country_24: `Бенин`,
        country_25: `Бермуды`,
        country_26: `Бутан`,
        country_27: `Боливия`,
        country_28: `Босния и Герцеговина`,
        country_29: `Ботсвана`,
        country_30: `Остров Буве`,
        country_31: `Бразилия`,
        country_32: `Британская территория Индийского океана`,
        country_33: `Британские Виргинские острова`,
        country_34: `Бруней`,
        country_35: `Болгария`,
        country_36: `Буркина`,
        country_37: `Бурунди`,
        country_38: `Камбоджа`,
        country_39: `Камерун`,
        country_40: `Канада`,
        country_41: `Кабо-Верде`,
        country_42: `Карибские Нидерланды`,
        country_43: `Каймановы острова`,
        country_44: `Центрально-Африканская Республика`,
        country_45: `Чад`,
        country_46: `Чили`,
        country_47: `Китай`,
        country_48: `Остров Рождества`,
        country_49: `Кокосовые (Килинг) острова`,
        country_50: `Колумбия`,
        country_51: `Острова Кука`,
        country_52: `Коста-Рика`,
        country_53: `"Берег Слоновой Кости"`,
        country_54: `Хорватия`,
        country_55: `Куба`,
        country_56: `Кипр`,
        country_57: `Чехия`,
        country_58: `Демократическая Республика Конго`,
        country_59: `Дания`,
        country_60: `Джибути`,
        country_61: `Доминика`,
        country_62: `Доминиканская Республика`,
        country_63: `Эквадор`,
        country_64: `Египет`,
        country_65: `Сальвадор`,
        country_66: `Экваториальная Гвинея`,
        country_67: `Эритрея`,
        country_68: `Эстония`,
        country_69: `Эфиопия`,
        country_70: `Фолклендские острова`,
        country_71: `Фарерские острова`,
        country_72: `Федеративные Штаты Микронезии`,
        country_73: `Фиджи`,
        country_74: `Финляндия`,
        country_75: `Франция`,
        country_76: `Французская Гвиана`,
        country_77: `Французская Полинезия`,
        country_78: `Южные Французские Территории`,
        country_79: `Габон`,
        country_80: `Гамбия`,
        country_81: `Грузия`,
        country_82: `Германия`,
        country_83: `Гана`,
        country_84: `Гибралтар`,
        country_85: `Великобритания (Великобритания; Англия)`,
        country_86: `Греция`,
        country_87: `Гренландия`,
        country_88: `Гренада`,
        country_89: `Гваделупа`,
        country_90: `Гуам`,
        country_91: `Гватемала`,
        country_92: `Гернси`,
        country_93: `Гвинея`,
        country_94: `Гвинея-Бисау`,
        country_95: `Гайана`,
        country_96: `Гаити`,
        country_97: `Остров Херд и острова Макдональд`,
        country_98: `Гондурас`,
        country_99: `Гонконг`,
        country_100: `Венгрия`,
        country_101: `Исландия`,
        country_102: `Индия`,
        country_103: `Индонезия`,
        country_104: `Иран`,
        country_105: `Ирак`,
        country_106: `Ирландия`,
        country_107: `Остров Мэн`,
        country_108: `Израиль`,
        country_109: `Италия`,
        country_110: `Ямайка`,
        country_111: `Япония`,
        country_112: `Джерси`,
        country_113: `Иордания`,
        country_114: `Казахстан`,
        country_115: `Кения`,
        country_116: `Кирибати`,
        country_117: `Кувейт`,
        country_118: `Кыргызстан`,
        country_119: `Лаос`,
        country_120: `Латвия`,
        country_121: `Ливан`,
        country_122: `Лесото`,
        country_123: `Либерия`,
        country_124: `Ливия`,
        country_125: `Лихтенштейн`,
        country_126: `Литва`,
        country_127: `Люксембург`,
        country_128: `Макао`,
        country_129: `Мадагаскар`,
        country_130: `Малави`,
        country_131: `Малайзия`,
        country_132: `Мальдивы`,
        country_133: `Мали`,
        country_134: `Мальта`,
        country_135: `Маршалловы острова`,
        country_136: `Мартиника`,
        country_137: `Мавритания`,
        country_138: `Маврикий`,
        country_139: `Майотта`,
        country_140: `Мексика`,
        country_141: `Молдова`,
        country_142: `Монако`,
        country_143: `Монголия`,
        country_144: `Черногория`,
        country_145: `Монтсеррат`,
        country_146: `Марокко`,
        country_147: `Мозамбик`,
        country_148: `Мьянма (Бирма)`,
        country_149: `Намибия`,
        country_150: `Науру`,
        country_151: `Непал`,
        country_152: `Нидерланды`,
        country_153: `Новая Каледония`,
        country_154: `Новая Зеландия`,
        country_155: `Никарагуа`,
        country_156: `Нигер`,
        country_157: `Нигерия`,
        country_158: `Ниуэ`,
        country_159: `Остров Норфолк`,
        country_160: `Северная Корея`,
        country_161: `Северные Марианские острова`,
        country_162: `Норвегия`,
        country_163: `Оман`,
        country_164: `Пакистан`,
        country_165: `Палау`,
        country_166: `Территории Палестины`,
        country_167: `Панама`,
        country_168: `Папуа - Новая Гвинея`,
        country_169: `Парагвай`,
        country_170: `Перу`,
        country_171: `Острова Питкэрн`,
        country_172: `Польша`,
        country_173: `Португалия`,
        country_174: `Пуэрто-Рико`,
        country_175: `Катар`,
        country_176: `Республика Македония (БЮРМ)`,
        country_177: `Республика Конго`,
        country_178: `Реюньон`,
        country_179: `Румыния`,
        country_180: `Российская Федерация`,
        country_181: `Руанда`,
        country_182: `Сен-Бартелеми`,
        country_183: `Сен-Мартен (Франция)`,
        country_184: `Сен-Пьер и Микелон`,
        country_185: `Самоа`,
        country_186: `Сан-Марино`,
        country_187: `Сан-Томе и Принсипи`,
        country_188: `Саудовская Аравия`,
        country_189: `Сенегал`,
        country_190: `Сербия`,
        country_191: `Сейшельские острова`,
        country_192: `Сьерра-Леоне`,
        country_193: `Сингапур`,
        country_194: `Словакия`,
        country_195: `Словения`,
        country_196: `Соломоновы острова`,
        country_197: `Сомали`,
        country_198: `Южная Африка`,
        country_199: `Южная Георгия и Южный Сэндвич-Айла`,
        country_200: `Южная Корея`,
        country_201: `южный Судан`,
        country_202: `Испания`,
        country_203: `Шри-Ланка`,
        country_204: `Остров Св. Елены и зависимые территории`,
        country_205: `Сент-Китс и Невис`,
        country_206: `Сент-Люсия`,
        country_207: `Сент-Винсент и Гренадины`,
        country_208: `Судан`,
        country_209: `Суринам`,
        country_210: `Свазиленд`,
        country_211: `Швеция`,
        country_212: `Швейцария`,
        country_213: `Сирия`,
        country_214: `Тайвань`,
        country_215: `Таджикистан`,
        country_216: `Танзания`,
        country_217: `Шаблон: Данные страны SJM Svalbard`,
        country_218: `Таиланд`,
        country_219: `Багамские острова`,
        country_220: `Коморские острова`,
        country_221: `Филиппины`,
        country_222: `Тимор-Лешти (Восточный Тимор)`,
        country_223: `Идти`,
        country_224: `Токелау`,
        country_225: `Тонга`,
        country_226: `Тринидад и Тобаго`,
        country_227: `Тунис`,
        country_228: `Турция`,
        country_229: `Туркменистан`,
        country_230: `Острова Теркс и Кайкос`,
        country_231: `Тувалу`,
        country_232: `Уганда`,
        country_233: `Украина`,
        country_234: `Объединенные Арабские Эмираты`,
        country_235: `Внешние малые острова США`,
        country_236: `Соединенные Штаты Америки (США)`,
        country_237: `Виргинские острова США`,
        country_238: `Уругвай`,
        country_239: `Узбекистан`,
        country_240: `Вануату`,
        country_241: `Ватикан (Святой Престол)`,
        country_242: `Венесуэла`,
        country_243: `Вьетнам`,
        country_244: `Уоллис и Футуна`,
        country_245: `Западная Сахара`,
        country_246: `Йемен`,
        country_247: `Замбия`,
        country_248: `Зимбабве`,
        google_login: `Войти через Google`,
        State: `Состояние`,
        Activation_code: `Код активации`,
        Question: `Список всех приложений, в которые вы вошли`,
        Copy_complete: `Копирование завершено`,
        change_password_success: `Пароль успешно изменен`,
        successful_login_title: `Успешный вход в систему`,
        product_page: `Страница продукта >>`,
        successful_login_info: `Авторизация завершена. Закройте текущую страницу и вернитесь на исходную вкладку, чтобы продолжить следующий процесс. Текущая страница будет закрыта автоматически через 5 секунд. Если автоматическое закрытие или закрытие нажатием кнопки «Готово» не удается, закройте эту вкладку напрямую.`,
        successful_login_info_firefox: `Авторизация завершена. Закройте текущую страницу и вернитесь на исходную вкладку, чтобы продолжить следующий процесс.`,
        my_account: `Мой счет`,
        my_history: `Моя история`,
        remove_watermark: `Удалить водяной знак`,
        no_history: `Нет истории`,
        history_all: `Выбрать все`,
        history_open: `Открытым`,
        history_down: `Скачать`,
        history_delete: `Удалить`,
        history_clear: `Удалить недействительный`,
        images: `картинки)`,
        use_this_function: `Используйте эту функцию >>`,
        hd_downloading: `Преимущества загрузки исходных изображений HD:`,
        lifetimeRemaining: `Оставшееся время жизни`,
        Remaining: `Оставшийся`,
        email_verification: `Подтверждение по элетронной почте`,
        email_verification_info: `Мы отправили сообщение с подтверждением на ваш адрес электронной почты <span class="email"></span>, и, пожалуйста, завершите проверку. После проверки преимущества будут синхронизированы автоматически.`,
        wrong_email: `Неверный адрес электронной почты?`,
        click_here_to_modify: `Нажмите здесь, чтобы изменить`,
        get_more_help: `Получить дополнительную помощь?`,
        click_here: `кликните сюда`,
        email_verification_info_success: `Поздравляем с подтверждением вашей учетной записи электронной почты.`,
        email_verification_info_error: `Проверка не удалась из-за просроченной ссылки.`,
        registration_succeeded: `Регистрация прошла успешно`,
        registration_succeeded_info_1: `Поздравляем! Вы успешно зарегистрировались. Мы отправили сообщение с подтверждением на ваш адрес электронной почты <span class="email"></span>. Пожалуйста, завершите проверку, чтобы воспользоваться преимуществами этого сообщения.`,
        registration_succeeded_info_2: `Нажмите «Готово», чтобы вернуться на домашнюю страницу и использовать этот продукт.`,
        registration_succeeded_info_3: `Нажмите «Готово», чтобы закрыть текущую страницу и вернуться на домашнюю страницу для следующих операций. Если закрыть не удается, закройте вкладку вручную.`,
        verify_email: `подтвердить электронную почту`,
        registered_email_not_verified: `Зарегистрированный адрес электронной почты не был подтвержден, пожалуйста, подтвердите его немедленно.`,
        email_verification_time_1: `Не получили письмо с подтверждением?`,
        email_verification_time_2: `После`,
        email_verification_time_3: `Нажмите здесь, чтобы отправить его повторно`,
        error_26301: `Код ошибки: 26301, свяжитесь с нами`,
        error_26302: `Код ошибки: 26302, свяжитесь с нами`,
        error_26303: `Ошибка формата электронной почты (код ошибки: 26303). Пожалуйста, введите его снова`,
        error_26304: `Рекомендуется пароль длиной более 8 символов (код ошибки: 26304)`,
        error_26305: `Ошибка повторного запроса (код ошибки: 26305). Пожалуйста, повторите попытку`,
        error_26306: `Электронная почта зарегистрирована, пожалуйста, <a href="https://account.tipard.com/login">перейдите, чтобы войти</a>`,
        error_26307: `Ошибка повторного запроса (код ошибки: 26307). Пожалуйста, повторите попытку`,
        error_26308: `Ошибка повторного запроса (код ошибки: 26308). Пожалуйста, повторите попытку`,
        error_26401: `Код ошибки: 26401, повторите попытку.`,
        error_26402: `Электронная почта подтверждена (код ошибки: 26402), повторите попытку.`,
        error_26403: `Ошибка повторного запроса (код ошибки: 26403). Пожалуйста, повторите попытку`,
        error_26404: `Запрос не выполнен (код ошибки: 26404). Пожалуйста, повторите попытку`,
        error_26501: `Код ошибки: 26501, свяжитесь с нами`,
        error_26502: `Код ошибки: 26502, свяжитесь с нами`,
        error_26503: `Ошибка формата электронной почты (код ошибки: 26503). Пожалуйста, введите его снова`,
        error_26504: `Ошибка повторного запроса (код ошибки: 26504). Пожалуйста, повторите попытку`,
        error_26505: `Электронная почта не зарегистрирована, <a href="https://account.tipard.com/register">сначала зарегистрируйте ее</a>`,
        error_26506: `Электронная почта подтверждена.`,
        error_26507: `Ошибка повторного запроса (код ошибки: 26507). Пожалуйста, повторите попытку`,
        error_26508: `Пройдено подтверждение (код ошибки: 26508). Повторите попытку.`,
        error_26509: `Запрос не выполнен (код ошибки: 26509), повторите попытку.`,
        error_26510: `Код ошибки: 26510, свяжитесь с нами`,
        error_26601: `Код ошибки: 26601, свяжитесь с нами`,
        error_26602: `Код ошибки: 26602, свяжитесь с нами`,
        error_26603: `Ошибка повторного запроса (код ошибки: 26603). Пожалуйста, повторите попытку`,
        error_26604: `Код ошибки: 26604, свяжитесь с нами`,
        error_26605: `Код ошибки: 26605, свяжитесь с нами`,
        error_26701: `Код ошибки: 26701, свяжитесь с нами`,
        error_26702: `Ошибка повторного запроса (код ошибки: 26702). Пожалуйста, повторите попытку`,
        error_26703: `Код ошибки: 26703, свяжитесь с нами`,
        error_26704: `Код ошибки: 26704, свяжитесь с нами`,
        error_26705: `Дождитесь входа в систему (код ошибки: 26705). Пожалуйста, повторите попытку`,
        no_cookie: `Вы включили функцию «Блокировать все файлы cookie» в своем браузере, поэтому не можете войти в систему. Перейдите в «Настройки» и установите флажок «Разрешить все файлы cookie».`,
        error_26801: `Код ошибки: 26801, свяжитесь с нами`,
        error_26802: `Код ошибки: 26802, свяжитесь с нами`,
        error_26803: `Запрос не выполнен (код ошибки: 26803). Пожалуйста, повторите попытку`,
        error_26804: `Запрос не выполнен (код ошибки: 26804). Пожалуйста, повторите попытку`,
        error_order: `Запрос не выполнен (код ошибки: 27098), попробуйте еще раз!`,
        error_order1: `Запрос заказа неполный (код ошибки:`,
        error_order2: `）, обновите и повторите попытку.`,
        modify_email_title: `Изменить адрес электронной почты`,
        modify_email_info: `Вы можете использовать измененный адрес электронной почты для входа в свою учетную запись.`,
        images_per: `Изображения за`,
        error_26101: `Ошибка: 26101. Пожалуйста, свяжитесь с нами`,
        error_26102: `Ошибка: 26102. Пожалуйста, свяжитесь с нами`,
        error_26103: `Запрос не выполнен (код ошибки: 26103). Пожалуйста, попробуйте еще раз`,
        error_26104: `Код ошибки: 26104, повторите попытку.`,
        error_26105: `Код ошибки: 26105, повторите попытку.`,
        error_26106: `Не удалось удалить (код ошибки: 26106). Пожалуйста, попробуйте еще раз`,
        error_26201: `Ошибка: 26201. Пожалуйста, свяжитесь с нами`,
        error_26202: `Запрос не выполнен (код ошибки: 26202). Пожалуйста, попробуйте еще раз`,
        error_26001: `Ошибка: 26001. Пожалуйста, свяжитесь с нами`,
        error_26002: `Ошибка: 26002. Пожалуйста, свяжитесь с нами`,
        error_26003: `Ошибка: 26003. Пожалуйста, свяжитесь с нами`,
        error_26004: `Ошибка: 26004. Пожалуйста, свяжитесь с нами`,
        error_26005: `Запрос не выполнен (код ошибки: 26005). Пожалуйста, попробуйте еще раз`,
        error_26006: `Код ошибки: 26006, повторите попытку.`,
        error_26008: `Ошибка: 26008. Пожалуйста, свяжитесь с нами`,
        go_to_the_home_page: `Перейти на главную страницу`,
        error_27101: `Запрос не выполнен (код ошибки: 27101). Пожалуйста, повторите попытку`,
        error_27201: `Код ошибки: 27201, свяжитесь с нами`,
        error_27202: `Код ошибки: 27202, повторите попытку.`,
        error_27203: `Запрос не выполнен (код ошибки: 27203). Пожалуйста, повторите попытку`,
        error_27204: `Неверный код (код ошибки: 27204).`,
        error_27205: `Запрос не выполнен (код ошибки: 27205). Пожалуйста, повторите попытку`,
        error_27206: `Запрос не выполнен (код ошибки: 27206). Пожалуйста, повторите попытку`,
        error_27207: `Запрос не выполнен (код ошибки: 27207). Пожалуйста, повторите попытку`,
        no_history_found: `Вы не использовали ни один инструмент! <a href="/"> Обновите </a> или <a href="https://ru.tipard.com/"> перейдите на официальный сайт </a>`,
        error_25301: `Ошибка: 25301. Пожалуйста, свяжитесь с нами`,
        error_25302: `Ошибка: 25302. Пожалуйста, свяжитесь с нами`,
        error_25303: `Запрос не выполнен (код ошибки: 25303). Пожалуйста, попробуйте еще раз`,
        error_25304: `Запрос не выполнен (код ошибки: 25304). Пожалуйста, попробуйте еще раз`,
        error_25305: `Запрос не выполнен (код ошибки: 25305). Пожалуйста, попробуйте еще раз`,
        error_25306: `Запрос не выполнен (код ошибки: 25306). Пожалуйста, попробуйте еще раз`,
        image_upscaler_p: `Преимущества загрузки изображения без водяного знака:`,
        Available_for: `Доступны для:`,
        credit_per: `%s кредитов за изображение HD`,
        still_valid: `Приобретенные планы все еще действительны.`,
        credit: `кредит(ы)`,
        pc_3rd_info: `Войдите успешно. Пожалуйста, перейдите в приложение для дальнейшей работы.`,
        use_online: `Воспользуйтесь онлайн-сервисом`,
        use_download: `Используйте настольную программу`,
        use_immediately: `Использовать немедленно`,
        Use_in_browser: `Использовать в браузере`,
        win_desktop: `Окна`,
        Mac_desktop: `Мак`,
        credits_per: `{%} кредитов в месяц`,
        expire: `Время истечения:`,
        viewDetails: `Посмотреть детали`,
        viewHistory: `Посмотреть преимущества истории>>`,
        viewDetailsInfo: `Примечание. Если преимущества подписки будут продлены в течение 7 дней после истечения срока их действия, неиспользованные преимущества можно будет продолжать использовать. Кроме того, время истечения срока действия будет автоматически обновлено до истечения срока действия новой подписки. Если по истечении 7 дней после истечения срока действия новой подписки не будет, все преимущества с истекшим сроком действия будут аннулированы.`,
        "connect_account": "Привяжите электронную почту к своей учетной записи",
        "connect_account_info": "После привязки вы сможете войти под этим адресом электронной почты.",
        "connect_now": "Связать сейчас",
        "no_email_bind": "Нет привязки к электронной почте",
        "bind_email": "Привязать электронную почту",
        "connect_your_email_placeholder": "Пожалуйста, введите Ваш адрес электронной почты",
        "bind_an_email": "Привязать электронную почту",
        "bind_info": "Вы успешно вошли в систему. Привяжите адрес электронной почты к своей учетной записи, чтобы активировать преимущества.",
        "bind_later": "Привязать позже",
        "footer": "Copyright © 2024 Типард Студия. Все права защищены.",
        "overtime": "Код ошибки: {%}, повторите попытку.",
        "hi": "Его!",
        "Personal_Information": "Персональная информация",
        "Access": "Доступ",
        "Subscription_Plan": "(План подписки)",
        "No_orders": "Заказы не найдены.",
        "No_data": "Нет данных",
        "Featured_Products": "Рекомендуемые продукты",
        "More_Products": "Больше продуктов",
        "Free_Download": "Бесплатная загрузка",
        "Get_Started": "Начать",
        "Subscribe": "Подписаться",
        "Verified": "Проверено",
        "back_to_account_center": "Вернуться в Центр счетов",
        "success": "Успех!",
        "successfully": "Вы успешно зарегистрировали учетную запись.",
        "Continue": "Продолжать",
        "Already": "У вас уже есть аккаунт?",
        "loading_verification": "Проверка статуса проверки...",
        "email_no_verification": "Извините, зарегистрированный адрес электронной почты не был проверен. Пожалуйста, завершите проверку в соответствии с инструкциями выше и нажмите кнопку \"Готово\" еще раз, чтобы завершить регистрацию.",
        "error_20505": "Электронная почта была связана",
        "error_20506": "Ошибка: 20506. Свяжитесь с нами.",
        "error_28401": "Пожалуйста, введите свой адрес электронной почты",
        "error_28402": "Неправильный формат электронной почты",
        "error_28403": "Ошибка: 28403. Свяжитесь с нами.",
        "error_28404": "Электронная почта была зарегистрирована",
        "error_28405": "Ошибка: 28405. Свяжитесь с нами.",
        "error_28406": "Электронная почта была связана",
        "error_28407": "Ошибка: 28407. Свяжитесь с нами.",
        "error_28408": "Не удалось отправить электронное письмо.",
        "error_28601": "Пожалуйста, введите свой адрес электронной почты",
        "error_28602": "Пожалуйста, введите проверочный код",
        "error_28603": "Неправильный формат электронной почты",
        "error_28604": "Ошибка: 28604. Свяжитесь с нами.",
        "error_28605": "Неверный код подтверждения",
        "error_28606": "Ошибка: 28606. Свяжитесь с нами.",
        "error_28607": "Электронная почта была зарегистрирована",
        "error_28608": "К вашей учетной записи не привязан адрес электронной почты.",
        "error_28609": "Электронная почта не проверена",
        "error_28610": "Ошибка: 28610. Свяжитесь с нами.",
        "error_28611": "Электронная почта была связана",
        "error_28612": "Ошибка: 28612. Свяжитесь с нами.",
        "unbind_email_btn": "Отменить привязку к электронной почте",
        "binding_exchange": "Привязать электронную почту",
        "Subscription_Status": "Статус подписки",
        "mask_unbind_info_1": "Вы уверены, что хотите отменить привязку {%} к этому аккаунту? После отмены привязки вы больше не сможете пользоваться преимуществами этого электронного письма",
        "mask_unbind_info_2": "Вы уверены, что хотите отменить привязку всех ПК, связанных с этим регистрационным кодом?<a href=\"https://ru.tipard.com/faq.html#unbind\">Узнайте больше об изменении устройств.</a>",
        "error_dc_link": "Ошибка. Свяжитесь с нами.",
        "vcu_state": "Преимущества полной версии:",
        "monthly_subscription": "Лицензия на 1 месяц",
        "year_subscription": "Лицензия на 1 год",
        "to": "к",
        "lifetime_subscription": "Пожизненная лицензия",
        "unbind": "Подтверждать",
        "unbind_success": "Отвязано успешно",
        "error_28401": "Пожалуйста, введите свой адрес электронной почты",
        "error_28702": "Ошибка: 28702. Свяжитесь с нами.",
        "error_28703": "Неверный код подтверждения",
        "error_28704": "Ошибка: 28704. Свяжитесь с нами.",
        "error_28705": "Электронная почта не была связана",
        "error_28706": "Пожалуйста, введите проверочный код",
        "error_28707": "Не удалось отменить связь",
        "error_28901": "Ошибка: 28901. Свяжитесь с нами.",
        "error_28902": "Ошибка: 28902. Свяжитесь с нами.",
        "error_28903": "Ошибка: 28903. Свяжитесь с нами.",
        "error_28904": "Ошибка: 28904. Свяжитесь с нами.",
        "error_28905": "Ошибка: 28905. Свяжитесь с нами.",
        "error_28906": "Ошибка: 28906. Свяжитесь с нами.",
        "error_28907": "Неверный регистрационный код",
        "error_28908": "Ошибка: 28908. Свяжитесь с нами.",
        "error_28909": "В настоящее время идет период отвязки. Отвязать можно только один раз в месяц.",
        "error_289010": "Ошибка: 289010. Свяжитесь с нами.",
        "error_289011": "Ошибка: 289011. Свяжитесь с нами.",
        "error_289012": "Ошибка: 289012. Свяжитесь с нами.",
        "activated_computer": "Активировано на %s/%s компьютере(ах)",
        "bind_email_info": "Преимущества следующих электронных писем будут привязаны и использованы в рамках данной учетной записи VIP.",
        "error_26309": "Ошибка: 26309. Свяжитесь с нами.",
        "error_26310": "Неверный реферальный код",
        "error_26311": "Ошибка: 26311. Свяжитесь с нами.",
        "error_26312": "Неверный реферальный код",
        "error_26313": "Ошибка: 26313. Свяжитесь с нами.",
        "error_26314": "Электронная почта была связана",
        "activate_unbinding": "Изменить устройство",
        "copy_registration_code": "Скопировать регистрационный код",
        "show_registration_code": "Показать регистрационный код",
        "hide_registration_code": "Скрыть регистрационный код",
        "other_binding_email": "Другие обязательные электронные письма",
        "home": "Дом",
        "points_balance": "Баланс баллов:",
        "earn_now": "Заработайте сейчас >>",
        "earn_more": "Зарабатывайте больше БЕСПЛАТНЫХ баллов, получайте больше подарков",
        "earn_or_redeem_points": "Заработать/обменять баллы",
        "unbind_email_info": "Текущая учетная запись связана с адресом электронной почты.",
        "bind_email_title": "Если вы хотите связать больше заказов с адресами электронной почты для покупки, нажмите кнопку «Связать адреса электронной почты» ниже, чтобы связать их.",
        "bind_email_tip": "<span>Совет:</span> В настоящее время поддерживаются только заказы с кодом регистрации по электронной почте.",
        "email_association": "Ассоциация электронной почты",
        "dissociation": "Диссоциация",
        "back": "Назад",
        "activate_benefits": "Введите адрес электронной почты, который необходимо связать с покупкой, чтобы получить код подтверждения. Введите код подтверждения, полученный в электронном письме, в поле ввода кода подтверждения и нажмите кнопку «Проверить активацию», чтобы активировать преимущества.",
        "e_mail": "Электронная почта:",
        "verification_code": "Проверочный код:",
        "obtain_verification_code": "Получить проверочный код",
        "verification_association": "Проверить ассоциацию",
        "current_password": "Текущий пароль:",
        "account_center": "Центр учета",
        "new_password": "Новый пароль:",
        "confirm_password": "Подтвердите новый пароль:",
        "Send": "Отправлять",
        "history_files": "Файлы истории",
        "delete_account_info_1": "1. Удаление вашего аккаунта Tipard — это постоянное действие, которое нельзя отменить. Все ваши данные, включая фотографии и настройки, будут утеряны. Вы уверены, что хотите удалить свой аккаунт Tipard?",
        "delete_account_info_2": "2. Если вы решили удалить учетную запись Tipard, нажмите кнопку «Отправить» и введите проверочный код из письма, которое мы вам отправили.",
        "best_deals": "Лучшие предложения",
        "close": "Закрывать",
        "product":"Продукт:"
    },
    sv: {
        overtime_1: `Felkod:`,
        overtime_2: `, försök igen`,
        isnetwork: `Internetfel. Kontrollera och försök igen`,
        email_placeholder: `E-post`,
        email_empty: `Ange e -post`,
        email_not_valid: `E-post är inte giltig`,
        email_not_valid_1: `Ange din e -post`,
        email_not_valid_2: `E -postadressen är ogiltig, använd en annan adress.`,
        email_not_valid_3: `Ingen e -postinmatning`,
        password_placeholder: `Lösenord`,
        password_empty: `Ange lösenord`,
        password_not_valid: `Fel konto eller lösenord`,
        password_not_valid_1: `Lösenord över 8 tecken krävs`,
        password_not_valid_2: `Skapa ett lösenord`,
        password_placeholder_1: `Skapa ditt lösenord`,
        password_placeholder_2: `Bekräfta ditt lösenord`,
        password_placeholder_3: `Skapa nytt lösenord`,
        password_placeholder_4: `Bekräfta nytt lösenord`,
        password_placeholder_5: `Ange gammalt lösenord`,
        copy_password_empty: `Bekräfta lösenordet`,
        copy_password_not_valid: `Bekräfta ditt lösenord`,
        copy_passwords_inconsistent: `Din lösenordsbekräftelse matchar inte`,
        code_empty: `Ange verifieringskod`,
        code_not_valid: `Ogiltig verifieringskod`,
        code_placeholder: `Verifierings kod`,
        not_received_code: `Om din brevlåda inte har fått verifieringskoden på länge, skicka verifieringskoden igen.`,
        get_first_code: `Hämta verifieringskoden först.`,
        last_name_placeholder: `Ange ditt efternamn`,
        first_name_placeholder: `Ange ditt förnamn`,
        address_placeholder: `Ange din adress`,
        no_code_text: `Vi har skickat en verifieringskod. Ange din kod. <span class='tips'>Fick du ingen kod?`,
        no_code_text_1: `1.Vänligen se till att e -postadressen är giltig och att den kan ta emot e -post.`,
        no_code_text_2: `2. För att e -postmeddelandet skickas av systemet automatiskt kan det flaggas som skräppost eller skräppost. Kontrollera om e -postmeddelandet finns i papperskorgen.`,
        no_code_text_3: `3.Kan inte lösa ditt problem?`,
        no_code_text_3_span: `Klicka sedan här för att kontakta oss.`,
        order_no: `Du har inte köpt några produkter. Har du frågor, vänligen <a href="https://sv.tipard.com/store.html">Handla nu</a> Eller <a href="https://sv.tipard.com/contact.html"> Kontakta oss</a>`,
        error_24901: `Nuvarande konto har ingen länkad e -postadress och kan inte hitta beställningarna. Anslut ett mejl.`,
        user_guide: `Användarhandbok >>`,
        download: `Ladda ner`,
        order_number: `Ordernummer`,
        Refund: `Återbetalning`,
        Disabled: `Inaktiverad`,
        Normal: `Vanligt`,
        Modify: `Ändra`,
        Modify_1: `Ändra >>`,
        Connect: `Ansluta`,
        unlink_success: `Koppla bort länken`,
        connect_success: `Anslut framgångsrikt`,
        feedback_title: `Tack för din feedback. Lämna ditt problem så svarar vi dig inom 24 timmar.`,
        feedback_thank_you: `Tack! <br /> Din feedback har skickats in.`,
        feedback_email: `Ange din e -postadress här!`,
        feedback_content: `Lämna alla problem eller förslag du stötte på här.`,
        feedback_submit: `Skicka in`,
        form_contents: `Du har inte angett någon beskrivning. Ange det och skicka igen.`,
        old_password: `Ange det gamla lösenordet`,
        new_password: `Skapa ett nytt lösenord`,
        old_new_password: `Det nya lösenordet kan inte vara detsamma som det gamla`,
        incorrect_password: `fel lösenord`,
        delete_no: `Radera nu`,
        Caps: `Caps Lock är på`,
        Get: `Skaffa sig`,
        Done: `Gjort`,
        error_20001: `Fel: 20001. Logga in igen.`,
        error_20002: `Fel: 20002. Logga in igen.`,
        error_20003: `Fel: 20003. Logga in igen.`,
        error_20004: `Begäran misslyckades (felkod: 20004). Försök igen.`,
        error_20005: `Inloggningssessionen har gått ut (fel: 20005). Logga in igen.`,
        error_20006: `Begäran misslyckades (felkod: 20006). Försök igen.`,
        error_20007: `Inloggningssessionen har gått ut (fel: 20007). Logga in igen.`,
        error_20008: `Inloggningssessionen har gått ut (fel: 20008). Logga in igen.`,
        error_20009: `Inloggningssessionen har gått ut (fel: 20009). Logga in igen.`,
        error_20101: `Ange din e -post (felkod: 20101)`,
        error_20102: `E -postadressen är ogiltig (felkod: 20102)`,
        error_20103: `Begäran misslyckades (felkod: 20103). Försök igen`,
        error_20104: `E -posten används redan. <a href="https://account.tipard.com/login"> logga in </a> eller registrera dig med en ny`,
        error_20105: `Begäran misslyckades (felkod: 20105). Försök igen`,
        error_20106: `Det gick inte att skicka e -post, försök igen`,
        error_20201: `Ange din e -post (felkod: 20201)`,
        error_20202: `Ange ditt lösenord (felkod: 20202)`,
        error_20203: `Ange verifieringskod (felkod: 20203)`,
        error_20204: `E -postadressen är ogiltig (felkod: 20204)`,
        error_20205: `Lösenord över 8 tecken krävs (felkod: 20205)`,
        error_20206: `Begäran misslyckades (felkod: 20206). Försök igen`,
        error_20207: `Ogiltig verifieringskod`,
        error_20208: `Begäran misslyckades (felkod: 20208). Försök igen`,
        error_20209: `Begäran misslyckades (felkod: 20209). Försök igen`,
        error_20301: `Ange din e -post (felkod: 20301)`,
        error_20302: `Fel: 20302. Vänligen kontakta oss`,
        error_20303: `E -postadressen är ogiltig (felkod: 20303)`,
        error_20304: `Begäran misslyckades (felkod: 20304). Försök igen`,
        error_20305: `Kontot finns inte. Ange igen eller <a href="https://account.tipard.com/register">Skapa det</a> först.`,
        error_20306: `Inget lösenord ännu. Använd <a href="https://account.tipard.com/passwordless-login"> inloggning utan lösenord </a> eller <a href="create-password"> ange ett lösenord </a> och logga in.`,
        error_20308: `Begäran misslyckades (felkod: 20308). Försök igen`,
        error_20401: `Det gick inte att logga ut (felkod: 20401). Försök igen`,
        error_20501: `Ange din e -post (felkod: 20501)`,
        error_20502: `E -postadressen är ogiltig (felkod: 20502)`,
        error_20503: `Begäran misslyckades (felkod: 20503). Försök igen`,
        error_20504: `Det gick inte att skicka e -post. Försök igen.`,
        error_20601: `Ange din e -post (felkod: 20601)`,
        error_20602: `Ange verifieringskod (felkod: 20602)`,
        error_20603: `E -postadressen är ogiltig (felkod: 20603)`,
        error_20604: `Begäran misslyckades (felkod: 20604). Försök igen`,
        error_20606: `Begäran misslyckades (felkod: 20606). Försök igen`,
        error_20607: `Begäran misslyckades (felkod: 20607). Försök igen`,
        error_20608: `Begäran misslyckades (felkod: 20608). Försök igen`,
        error_20701: `Ange din e -post (felkod: 20701)`,
        error_20702: `E -postadressen är ogiltig (felkod: 20702)`,
        error_20703: `Begäran misslyckades (felkod: 20703). Försök igen`,
        error_20704: `Kontot finns inte. Ange igen eller <a href="https://account.tipard.com/register">Skapa det</a> först.`,
        error_20705: `Begäran misslyckades (felkod: 20705). Försök igen`,
        error_20706: `Det gick inte att skicka e -post. Försök igen`,
        error_20801: `Ange din e -post (felkod: 20801)`,
        error_20802: `Fel: 20802. Vänligen kontakta oss`,
        error_20803: `Ange verifieringskod (felkod: 20803)`,
        error_20804: `E -postadressen är ogiltig (felkod: 20804)`,
        error_20805: `Lösenord över 8 tecken krävs (felkod: 20805)`,
        error_20806: `Begäran misslyckades (felkod: 20806). Försök igen`,
        error_20808: `Begäran misslyckades (felkod: 20808). Försök igen`,
        error_20901: `Begäran misslyckades (felkod: 20901). Försök igen`,
        error_20902: `Begäran misslyckades (felkod: 20902). Försök igen`,
        error_21000: `Ändringar sparas`,
        error_21001: `Ingen information lämnas (felkod: 21001)`,
        error_21002: `Begäran misslyckades (felkod: 21002). Försök igen`,
        error_21101: `Ange din e -post (felkod: 21101)`,
        error_21102: `E -postadressen är ogiltig (felkod: 21102)`,
        error_21103: `Begäran misslyckades (felkod: 21103), försök igen`,
        error_21104: `E -post är redan ansluten, använd en ny`,
        error_21105: `Begäran misslyckades (felkod: 21105), försök igen`,
        error_21106: `Det gick inte att skicka e -post. Försök igen`,
        error_21201: `Ange din e -post (felkod: 21201)`,
        error_21202: `Ange verifieringskod (felkod: 21202)`,
        error_21203: `E -postadressen är ogiltig (felkod: 21203)`,
        error_21204: `Fel: 21204. Vänligen kontakta oss`,
        error_21205: `Fel: 21205. Vänligen kontakta oss`,
        error_21206: `Lösenord över 8 tecken krävs (felkod: 21206)`,
        error_21207: `Begäran misslyckades (felkod: 21207). Försök igen`,
        error_21209: `Begäran misslyckades (felkod: 21209). Försök igen`,
        error_21301: `Ange gammalt lösenord (felkod: 21301)`,
        error_21302: `Skapa ett nytt lösenord (felkod: 21302)`,
        error_21303: `Det nya lösenordet kan inte vara detsamma som det gamla. (Fel: 21303)`,
        error_21304: `Lösenord över 8 tecken krävs (felkod: 21304)`,
        error_21306: `Begäran misslyckades (felkod: 21306). Försök igen`,
        error_21402: `Begäran misslyckades (felkod: 21402). Försök igen`,
        error_21403: `Det gick inte att skicka verifieringskoden. Skicka den igen`,
        error_21500: `Kontot har raderats`,
        error_21501: `Ange verifieringskod (felkod: 21501)`,
        error_21502: `Inloggningssessionen har gått ut (fel: 21502). Logga in igen.`,
        error_21503: `Begäran misslyckades (felkod: 21503). Försök igen`,
        error_21505: `Begäran misslyckades (felkod: 21505), försök igen`,
        error_21601: `Fel: 20601. Vänligen kontakta oss`,
        error_21602: `Ogiltig verifiering (fel: 20602). Försök igen.`,
        error_21603: `Fel: 20603. Försök igen`,
        error_21604: `Begäran misslyckades (felkod: 21604). Försök igen`,
        error_21606: `Begäran misslyckades (felkod: 21606). Försök igen`,
        error_21611: `Begäran misslyckades (felkod: 21611). Försök igen`,
        error_21801: `Fel: 21801. Vänligen kontakta oss`,
        error_21802: `Begäran misslyckades (fel: 21802). Försök igen`,
        error_21803: `Fel: 21803. Försök igen`,
        error_21804: `Begäran misslyckades (felkod: 21804). Försök igen`,
        error_21806: `Fel: 21806. Försök igen`,
        error_21807: `Fel: 21807. Vänligen kontakta oss`,
        error_21808: `Fel: 21808. Kontakta oss`,
        error_21809: `Fel: 21809. Vänligen kontakta oss`,
        error_21810: `Fel: 21810. Vänligen kontakta oss`,
        error_21811: `Fel: 21811. Vänligen kontakta oss`,
        error_21812: `Fel: 21812. Vänligen kontakta oss`,
        error_21813: `Begäran misslyckades (felkod: 21813). Försök igen`,
        error_21814: `Fel: 21814. Vänligen kontakta oss`,
        error_21815: `Begäran misslyckades (felkod: 21815). Försök igen`,
        error_21816: `Fel: 21816. Vänligen kontakta oss`,
        error_21817: `Fel: 21817. Vänligen kontakta oss`,
        error_21818: `Fel: 21818. Kontakta oss`,
        error_21819: `Begäran misslyckades (felkod: 21819). Försök igen`,
        error_21820: `Fel: 21820. Kontakta oss`,
        error_21821: `Fel: 21821. Vänligen kontakta oss`,
        error_21822: `Fel: 21822. Kontakta oss`,
        error_21823: `Begäran misslyckades (felkod: 21823). Försök igen`,
        error_21824: `Begäran misslyckades (felkod: 21824). Försök igen`,
        error_21825: `Begäran misslyckades (felkod: 21825). Försök igen`,
        error_21826: `Begäran misslyckades (felkod: 21826). Försök igen`,
        error_21901: `Fel: 21901. Vänligen kontakta oss`,
        error_21902: `Begäran misslyckades (felkod: 21902). Försök igen`,
        error_21903: `Kontostatusen har ändrats (felkod: 21903). Uppdatera sidan och försök igen`,
        error_21904: `Fel: 21904. Försök igen`,
        error_21905: `Fel: 21905. Försök igen`,
        error_21906: `Begäran misslyckades (felkod: 21906). Försök igen`,
        error_21907: `Google -kontot har länkats till ett annat konto`,
        error_21908: `Begäran misslyckades (felkod: 21908). Försök igen`,
        error_22001: `Begäran misslyckades (felkod: 22001). Försök igen`,
        error_22002: `Avlänkning misslyckades utan ytterligare inloggning`,
        error_22003: `Begäran misslyckades (felkod: 22003). Försök igen`,
        error_22101: `Fel: 22101. Vänligen kontakta oss`,
        error_22102: `Kontostatusen har ändrats (felkod: 22102). Uppdatera sidan och försök igen`,
        error_22103: `Begäran misslyckades (felkod: 22103). Försök igen`,
        error_22104: `Kontostatusen har ändrats (felkod: 22104). Uppdatera sidan och försök igen`,
        error_22105: `Fel: 22105. Försök igen`,
        error_22106: `Fel: 22106. Försök igen`,
        error_22107: `Fel: 22107. Vänligen kontakta oss`,
        error_22108: `Begäran misslyckades (felkod: 22108). Försök igen`,
        error_22201: `Fel: 22201. Vänligen kontakta oss`,
        error_22202: `Ogiltig verifiering (fel: 22202). Försök igen.`,
        error_22203: `Fel: 22203. Försök igen "`,
        error_22204: `Begäran misslyckades (felkod: 22204). Försök igen`,
        error_22206: `Begäran misslyckades (felkod: 22206). Försök igen`,
        error_22401: `Fel: 22401. Vänligen kontakta oss`,
        error_22402: `Ogiltig verifiering (fel: 22402). Försök igen.`,
        error_22403: `Fel: 22403. Försök igen`,
        error_22404: `Begäran misslyckades (felkod: 22404). Försök igen`,
        error_22405: `Facebook -kontot har länkats till annan e -post`,
        error_22406: `Fel: 22406. Försök igen`,
        error_22407: `Fel: 22407. Kontakta oss`,
        error_22408: `Fel: 22408. Kontakta oss`,
        error_22409: `Fel: 22409. Kontakta oss`,
        error_22410: `Fel: 224010. Vänligen kontakta oss`,
        error_22411: `Fel: 224011. Kontakta oss`,
        error_22412: `Fel: 224012. Vänligen kontakta oss`,
        error_22413: `Fel: 22413. Vänligen kontakta oss`,
        error_22414: `Begäran misslyckades (felkod: 22414). Försök igen`,
        error_22415: `Fel: 22415. Kontakta oss`,
        error_22416: `Fel: 22416. Vänligen kontakta oss`,
        error_22417: `Fel: 22417. Kontakta oss`,
        error_22418: `Begäran misslyckades (felkod: 22418). Försök igen`,
        error_22419: `Fel: 22419. Kontakta oss`,
        error_22420: `Fel: 22420. Vänligen kontakta oss`,
        error_22421: `Fel: 22421. Vänligen kontakta oss`,
        error_22422: `Begäran misslyckades (felkod: 22422). Försök igen`,
        error_22423: `Begäran misslyckades (felkod: 22423). Försök igen`,
        error_22424: `Begäran misslyckades (felkod: 22424). Försök igen`,
        error_22425: `Begäran misslyckades (felkod: 22425). Försök igen`,
        error_20098: `Felkod: 20098. Om du surfar i privat läge, växla till normalt läge och försök igen.`,
        error_22298: `Begäran om Google misslyckades (fel: 22298). Försök igen.`,
        error_22498: `Begäran om Facebook misslyckades (fel: 22498). Försök igen.`,
        error_24902: `Begäran misslyckades (felkod: 24902). Försök igen`,
        error_24903: `Begäran misslyckades (felkod: 24903). Försök igen`,
        error_24904: `Begäran misslyckades (felkod: 24904). Försök igen`,
        error_24905: `Begäran misslyckades (felkod: 24905). Försök igen`,
        login_title: `Logga in på Tipard`,
        password_placeholder: `Lösenord`,
        log_in: `Logga in`,
        no_account: `Inget konto?`,
        create_it: `Skapa det`,
        or_log_in_with: `Eller logga in med`,
        passwordless_login: `Lösenordslös inloggning`,
        log_in_done: `Logga in Klar`,
        three_rd_account_connect_info: `Grattis! Du har loggat in. Nu kan du ansluta ett e -postkonto som används för att logga in i framtiden.`,
        see_my_account: `Se mitt konto`,
        three_rd_login_merge_account: `Tredjepartskontots e-postadress har registrerats, vill du ansluta och logga in med den här e-postadressen direkt?`,
        connect_log_in: `Anslut och logga in`,
        create_an_account: `Skapa ett konto`,
        back_to_log_in: `Tillbaka till login`,
        create_password: `Skapa lösenord`,
        create_now: `Skapa nu`,
        password_login_subtitle: `Lösenordslös inloggning med e -post`,
        account_login: `Kontoinloggning`,
        rights: `Genom att skapa detta konto godkänner du <a href="https://sv.tipard.com/terms.html"> användarvillkor </a> och <a href="https://sv.tipard.com/policy.html"> Sekretesspolicy </a>`,
        passwordless_login_done: `Lösenordslös inloggning klar`,
        passwordless_login_info: `Grattis, du har slutfört inloggning utan lösenord. Du kan skapa ett lösenord för det här kontot och logga in med kontot och lösenordet i framtiden. <a href="/create-password" style="display: initial;"> Skapa nu </a>`,
        sign_up: `Bli Medlem`,
        register_info: `Skapa ditt konto`,
        reset_now: `Återställ nu`,
        forgot_password: `Glömt ditt lösenord`,
        reset_password_subtitle: `Använd ditt e -postkonto för att återställa lösenordet`,
        plan_products: `Plan & Produkter`,
        nick_name: `Användarnamn`,
        email: `E-post`,
        my_products: `Mina produkter`,
        my_orders: `Mina order`,
        unlink: `Ta bort länken`,
        link: `Länk`,
        connected_accounts: `Anslutna konton`,
        last_name: `Efternamn`,
        first_name: `Förnamn`,
        Gender: `Kön`,
        Birth: `Födelse`,
        Month: `Månad`,
        Year: `År`,
        Country_Region: `Land/region`,
        Address: `Adress`,
        Save: `Spara`,
        Date: `Datum`,
        Male: `Manlig`,
        Female: `Kvinna`,
        Unspecified: `Ospecificerad`,
        Security: `säkerhet`,
        change_password: `ändra lösenord`,
        change_now: `Ändra nu`,
        connect_email: `Anslut e -post`,
        delete_account: `Radera konto`,
        delete_account_info: `När ditt konto raderas kommer all data i Plan & Produkter som är kopplade till ditt konto att raderas permanent, och du kanske inte kan återställa den. Vi rekommenderar att du går försiktigt.`,
        Delete: `Radera`,
        Logout: `Logga ut`,
        my_profile: `Min profil`,
        guides_faqs: `Guider och vanliga frågor`,
        More: `Mer`,
        guides: `Guider`,
        register: `Registrera`,
        hot_faq: `Hot FAQ`,
        Contents: `Innehåll:`,
        contact_us: `Kontakta oss >>`,
        plan: `Planen`,
        unregistered: `Oregistrerad`,
        buy_more: `Köp mer`,
        buy_again: `Köp igen`,
        buy_now: `Köp nu`,
        free_no_limit: `Gratis & ingen gräns`,
        expired: `Utgånget`,
        lifetime: `Livstid`,
        remain: `Förbli`,
        day_s: `Dag(ar)`,
        day: `Dag`,
        error_24801: `Begäran misslyckades (felkod: 24801). Försök igen`,
        no_app_found: `Ingen app hittades!<a href="/">Uppdatera</a> eller <a href="https://sv.tipard.com/">gå till den officiella webbplatsen</a>`,
        get_more: `Få mer >>`,
        edit_photo: `Redigera Foto`,
        select_photo: `Välj foto`,
        change_photo: `Förändring Foto`,
        cancel: `Avbryt`,
        hide_password: `Dölj lösenord`,
        show_password: `Visa lösenord`,
        zoom_in: `Zooma in`,
        zoom_out: `Zooma ut`,
        rotate: `Rotera`,
        horizontal_flip: `Horisontell vändning`,
        vertical_flip: `Vertikal flip`,
        country: `Land`,
        country_1: `Välj ditt land/din region`,
        country_2: `Åaland`,
        country_3: `Afghanistan`,
        country_4: `Albanien`,
        country_5: `Algeriet`,
        country_6: `Amerikanska Samoa`,
        country_7: `Andorra`,
        country_8: `Angola`,
        country_9: `Anguilla`,
        country_10: `Antarktis`,
        country_11: `Antigua och Barbuda`,
        country_12: `Argentina`,
        country_13: `Armenien`,
        country_14: `Aruba`,
        country_15: `Australien`,
        country_16: `Österrike`,
        country_17: `Azerbajdzjan`,
        country_18: `Bahrain`,
        country_19: `Bangladesh`,
        country_20: `Barbados`,
        country_21: `Vitryssland`,
        country_22: `Belgien`,
        country_23: `Belize`,
        country_24: `Benin`,
        country_25: `Bermuda`,
        country_26: `Bhutan`,
        country_27: `Bolivia`,
        country_28: `Bosnien och Hercegovina`,
        country_29: `Botswana`,
        country_30: `Bouvetön`,
        country_31: `Brasilien`,
        country_32: `Brittiska territoriet i Indiska oceanen`,
        country_33: `Brittiska Jungfruöarna`,
        country_34: `Brunei`,
        country_35: `Bulgarien`,
        country_36: `Burkina`,
        country_37: `Burundi`,
        country_38: `Kambodja`,
        country_39: `Kamerun`,
        country_40: `Kanada`,
        country_41: `Cap Verde`,
        country_42: `Karibiska Nederländerna`,
        country_43: `Caymanöarna`,
        country_44: `Centralafrikanska republiken`,
        country_45: `Tchad`,
        country_46: `Chile`,
        country_47: `Kina`,
        country_48: `Julön`,
        country_49: `Cocos (Keeling) Islands`,
        country_50: `Colombia`,
        country_51: `Cooköarna`,
        country_52: `Costa Rica`,
        country_53: `"Elfenbenskusten"`,
        country_54: `Kroatien`,
        country_55: `Kuba`,
        country_56: `Cypern`,
        country_57: `Tjeckien`,
        country_58: `Demokratiska republiken Kongo`,
        country_59: `Danmark`,
        country_60: `Djibouti`,
        country_61: `Dominica`,
        country_62: `Dominikanska republiken`,
        country_63: `Ecuador`,
        country_64: `Egypten`,
        country_65: `El Salvador`,
        country_66: `Ekvatorialguinea`,
        country_67: `Eritrea`,
        country_68: `Estland`,
        country_69: `Etiopien`,
        country_70: `Falklandsöarna`,
        country_71: `Färöarna`,
        country_72: `Mikronesiens federerade stater`,
        country_73: `Fiji`,
        country_74: `Finland`,
        country_75: `Frankrike`,
        country_76: `Franska Guyana`,
        country_77: `franska polynesien`,
        country_78: `franska sydterritorierna`,
        country_79: `Gabon`,
        country_80: `Gambia`,
        country_81: `Georgien`,
        country_82: `Tyskland`,
        country_83: `Ghana`,
        country_84: `Gibraltar`,
        country_85: `Storbritannien (Storbritannien; England)`,
        country_86: `Grekland`,
        country_87: `Grönland`,
        country_88: `Grenada`,
        country_89: `Guadeloupe`,
        country_90: `Guam`,
        country_91: `Guatemala`,
        country_92: `Guernsey`,
        country_93: `Guinea`,
        country_94: `Guinea-Bissau`,
        country_95: `Guyana`,
        country_96: `Haiti`,
        country_97: `Heard Island och McDonald Islands`,
        country_98: `Honduras`,
        country_99: `Hong Kong`,
        country_100: `Ungern`,
        country_101: `Island`,
        country_102: `Indien`,
        country_103: `Indonesien`,
        country_104: `Iran`,
        country_105: `Irak`,
        country_106: `Irland`,
        country_107: `ö av man`,
        country_108: `Israel`,
        country_109: `Italien`,
        country_110: `Jamaica`,
        country_111: `Japan`,
        country_112: `Jersey`,
        country_113: `Jordanien`,
        country_114: `Kazakstan`,
        country_115: `Kenya`,
        country_116: `Kiribati`,
        country_117: `Kuwait`,
        country_118: `Kirgizistan`,
        country_119: `Laos`,
        country_120: `Lettland`,
        country_121: `Libanon`,
        country_122: `Lesotho`,
        country_123: `Liberia`,
        country_124: `Libyen`,
        country_125: `Liechtenstein`,
        country_126: `Litauen`,
        country_127: `Luxemburg`,
        country_128: `Macao`,
        country_129: `Madagaskar`,
        country_130: `Malawi`,
        country_131: `Malaysia`,
        country_132: `Maldiverna`,
        country_133: `Mali`,
        country_134: `Malta`,
        country_135: `Marshallöarna`,
        country_136: `Martinique`,
        country_137: `Mauretanien`,
        country_138: `Mauritius`,
        country_139: `Mayotte`,
        country_140: `Mexiko`,
        country_141: `Moldavien`,
        country_142: `Monaco`,
        country_143: `mongoliet`,
        country_144: `Montenegro`,
        country_145: `Montserrat`,
        country_146: `Marocko`,
        country_147: `Moçambique`,
        country_148: `Myanmar (Burma)`,
        country_149: `Namibia`,
        country_150: `Nauru`,
        country_151: `Nepal`,
        country_152: `Nederländerna`,
        country_153: `Nya Kaledonien`,
        country_154: `Nya Zeeland`,
        country_155: `Nicaragua`,
        country_156: `Niger`,
        country_157: `Nigeria`,
        country_158: `Niue`,
        country_159: `Norfolkön`,
        country_160: `Nordkorea`,
        country_161: `Nordmarianerna`,
        country_162: `Norge`,
        country_163: `oman`,
        country_164: `Pakistan`,
        country_165: `Palau`,
        country_166: `Palestinska territorier`,
        country_167: `Panama`,
        country_168: `Papua Nya Guinea`,
        country_169: `Paraguay`,
        country_170: `Peru`,
        country_171: `Pitcairnöarna`,
        country_172: `Polen`,
        country_173: `Portugal`,
        country_174: `Puerto Rico`,
        country_175: `Qatar`,
        country_176: `Republiken Makedonien (FYROM)`,
        country_177: `Republiken Kongo`,
        country_178: `Återförening`,
        country_179: `Rumänien`,
        country_180: `Ryska Federationen`,
        country_181: `Rwanda`,
        country_182: `Saint Barthélemy`,
        country_183: `Saint Martin (Frankrike)`,
        country_184: `Saint-Pierre och Miquelon`,
        country_185: `Samoa`,
        country_186: `San Marino`,
        country_187: `Sao Tomé och Principe`,
        country_188: `Saudiarabien`,
        country_189: `Senegal`,
        country_190: `Serbien`,
        country_191: `Seychellerna`,
        country_192: `Sierra Leone`,
        country_193: `Singapore`,
        country_194: `Slovakien`,
        country_195: `Slovenien`,
        country_196: `Salomonöarna`,
        country_197: `Somalia`,
        country_198: `Sydafrika`,
        country_199: `South Georgia och South Sandwich Island`,
        country_200: `Sydkorea`,
        country_201: `södra Sudan`,
        country_202: `Spanien`,
        country_203: `Sri Lanka`,
        country_204: `S:t Helena & Beroende`,
        country_205: `St. Kitts och Nevis`,
        country_206: `Sankta Lucia`,
        country_207: `St. Vincent och Grenadinerna`,
        country_208: `Sudan`,
        country_209: `Surinam`,
        country_210: `Swaziland`,
        country_211: `Sverige`,
        country_212: `Schweiz`,
        country_213: `Syrien`,
        country_214: `Taiwan`,
        country_215: `Tadzjikistan`,
        country_216: `Tanzania`,
        country_217: `Mall:Landsdata SJM Svalbard`,
        country_218: `Thailand`,
        country_219: `Bahamas`,
        country_220: `Komorerna`,
        country_221: `Filippinerna`,
        country_222: `Östtimor (Östtimor)`,
        country_223: `Togo`,
        country_224: `Tokelau`,
        country_225: `Tonga`,
        country_226: `Trinidad och Tobago`,
        country_227: `Tunisien`,
        country_228: `Kalkon`,
        country_229: `Turkmenistan`,
        country_230: `Turks- och Caicosöarna`,
        country_231: `Tuvalu`,
        country_232: `Uganda`,
        country_233: `Ukraina`,
        country_234: `Förenade arabemiraten`,
        country_235: `Förenta staternas mindre avlägsna öar`,
        country_236: `USA (USA)`,
        country_237: `USA Jungfruöarna`,
        country_238: `Uruguay`,
        country_239: `Uzbekistan`,
        country_240: `Vanuatu`,
        country_241: `Vatikanstaten (den Heliga stolen)`,
        country_242: `Venezuela`,
        country_243: `Vietnam`,
        country_244: `Wallis och Futuna`,
        country_245: `Västra Sahara`,
        country_246: `Jemen`,
        country_247: `Zambia`,
        country_248: `Zimbabwe`,
        google_login: `Logga in med Google`,
        State: `stat`,
        Activation_code: `Aktiveringskod`,
        Question: `Lista alla appar du har loggat in`,
        Copy_complete: `Kopian klar`,
        change_password_success: `lösenordet har ändrats`,
        successful_login_title: `Inloggning lyckad`,
        product_page: `Produktsida >>`,
        successful_login_info: `Inloggningen är klar. Stäng den aktuella sidan och gå tillbaka till den ursprungliga fliken för att fortsätta med följande process. Den aktuella sidan stängs automatiskt om 5 sekunder. Om automatisk stängning eller stängning genom att klicka på knappen "Klar" misslyckas, stäng den här fliken direkt.`,
        successful_login_info_firefox: `Inloggningen är klar. Stäng den aktuella sidan och gå tillbaka till den ursprungliga fliken för att fortsätta med följande process.`,
        my_account: `Mitt konto`,
        my_history: `Min historia`,
        remove_watermark: `Ta bort vattenstämpel`,
        no_history: `Ingen historia`,
        history_all: `Välj alla`,
        history_open: `Öppen`,
        history_down: `Ladda ner`,
        history_delete: `Radera`,
        history_clear: `Rensa ogiltig`,
        images: `bilder)`,
        use_this_function: `Använd den här funktionen >>`,
        hd_downloading: `Fördelar med att ladda ner HD-originalbilder:`,
        lifetimeRemaining: `Livstid kvar`,
        Remaining: `Återstående`,
        email_verification: `mejl verifikation`,
        email_verification_info: `Vi har skickat verifieringsmeddelandet till din e-post <span class="email"></span> och avsluta verifieringen. Efter verifiering synkroniseras förmånerna automatiskt.`,
        wrong_email: `Fel e-postadress?`,
        click_here_to_modify: `Klicka här för att ändra`,
        get_more_help: `Få mer hjälp?`,
        click_here: `Klicka här`,
        email_verification_info_success: `Grattis för att du har verifierat ditt e-postkonto.`,
        email_verification_info_error: `Verifieringen misslyckades på grund av den utgångna länken.`,
        registration_succeeded: `Registreringen lyckades`,
        registration_succeeded_info_1: `Grattis! Din registrering lyckades. Vi har skickat verifieringsmeddelandet till din e-post <span class="email"></span>, och slutför verifieringen för att använda fördelarna med detta e-postmeddelande.`, registration_succeeded_info_2: `Klicka på "Klar" för att gå tillbaka till startsidan och använda den här produkten.`,
        registration_succeeded_info_3: `Klicka på "Klar" för att stänga den aktuella sidan och återgå till startsidan för följande åtgärder. Om stängningen misslyckas, stäng fliken manuellt.`,
        verify_email: `Verifiera Email`,
        registered_email_not_verified: `Den registrerade e-postadressen har inte verifierats, vänligen verifiera den på en gång.`,
        email_verification_time_1: `Fick du inget verifieringsmail?`,
        email_verification_time_2: `Efter`,
        email_verification_time_3: `Klicka här för att skicka den igen`,
        error_26301: `Felkod: 26301, vänligen kontakta oss`,
        error_26302: `Felkod: 26302, vänligen kontakta oss`,
        error_26303: `E-postformatfel (felkod: 26303). Vänligen ange den igen`,
        error_26304: `Lösenord över 8 tecken rekommenderas (felkod: 26304)`,
        error_26305: `Reuqest misslyckades (felkod: 26305). Försök igen`,
        error_26306: `E-post har registrerats, vänligen <a href="https://account.tipard.com/login">gå till att logga in</a>`,
        error_26307: `Reuqest misslyckades (felkod: 26307). Försök igen`,
        error_26308: `Reuqest misslyckades (felkod: 26308). Försök igen`,
        error_26401: `Felkod: 26401, försök igen`,
        error_26402: `E-posten har verifierats (felkod: 26402), försök igen`,
        error_26403: `Reuqest misslyckades (felkod: 26403). Försök igen`,
        error_26404: `Begäran misslyckades (felkod: 26404). Försök igen`,
        error_26501: `Felkod: 26501, vänligen kontakta oss`,
        error_26502: `Felkod: 26502, vänligen kontakta oss`,
        error_26503: `E-postformatfel (felkod: 26503). Vänligen ange den igen`,
        error_26504: `Reuqest misslyckades (felkod: 26504). Försök igen`,
        error_26505: `E-post har inte registrerats, vänligen <a href="https://account.tipard.com/register">registrera det först</a>`,
        error_26506: `E-post har verifierats.`,
        error_26507: `Reuqest misslyckades (felkod: 26507). Försök igen`,
        error_26508: `Verifieringen misslyckades (felkod: 26508), försök igen`,
        error_26509: `Begäran misslyckades (felkod: 26509), försök igen`,
        error_26510: `Felkod: 26510, vänligen kontakta oss`,
        error_26601: `Felkod: 26601, vänligen kontakta oss`,
        error_26602: `Felkod: 26602, vänligen kontakta oss`,
        error_26603: `Reuqest misslyckades (felkod: 26603). Försök igen`,
        error_26604: `Felkod: 26604, vänligen kontakta oss`,
        error_26605: `Felkod: 26605, vänligen kontakta oss`,
        error_26701: `Felkod: 26701, vänligen kontakta oss`,
        error_26702: `Reuqest misslyckades (felkod: 26702). Försök igen`,
        error_26703: `Felkod: 26703, vänligen kontakta oss`,
        error_26704: `Felkod: 26704, vänligen kontakta oss`,
        error_26705: `Vänta på att logga in (felkod: 26705). Försök igen`,
        no_cookie: `Du har aktiverat funktionen Blockera alla cookies i din webbläsare, så du kan inte logga in. Gå till Inställningar för att markera rutan Tillåt alla cookies.`,
        error_26801: `Felkod: 26801, vänligen kontakta oss`,
        error_26802: `Felkod: 26802, vänligen kontakta oss`,
        error_26803: `Begäran misslyckades (felkod: 26803). Försök igen`,
        error_26804: `Begäran misslyckades (felkod: 26804). Försök igen`,
        error_order: `Begäran misslyckades (felkod: 27098), försök igen!`,
        error_order1: `Beställningsfrågan är ofullständig (felkod:`,
        error_order2: `）, vänligen uppdatera och försök igen.`,
        modify_email_title: `Ändra e-post`,
        modify_email_info: `Du kan använda den ändrade e-posten för att logga in på ditt konto.`,
        images_per: `Bilder pr`,
        error_26101: `Fel: 26101. Kontakta oss`,
        error_26102: `Fel: 26102. Kontakta oss`,
        error_26103: `Begäran misslyckades (felkod:26103). Vänligen försök igen`,
        error_26104: `Felkod: 26104, försök igen`,
        error_26105: `Felkod: 26105, försök igen`,
        error_26106: `Det gick inte att radera (felkod: 26106). Försök igen`,
        error_26201: `Fel: 26201. Kontakta oss`,
        error_26202: `Begäran misslyckades (felkod:26202). Vänligen försök igen`,
        error_26001: `Fel: 26001. Kontakta oss`,
        error_26002: `Fel: 26002. Kontakta oss`,
        error_26003: `Fel: 26003. Kontakta oss`,
        error_26004: `Fel: 26004. Kontakta oss`,
        error_26005: `Begäran misslyckades (felkod:26005). Vänligen försök igen`,
        error_26006: `Felkod: 26006, försök igen`,
        error_26008: `Fel: 26008. Kontakta oss`,
        go_to_the_home_page: `Gå till startsidan`,
        error_27101: `Begäran misslyckades (felkod: 27101). Vänligen försök igen`,
        error_27201: `Felkod: 27201, vänligen kontakta oss`,
        error_27202: `Felkod: 27202, försök igen`,
        error_27203: `Begäran misslyckades (felkod: 27203). Försök igen`,
        error_27204: `Ogiltig kod (felkod: 27204).`,
        error_27205: `Begäran misslyckades (felkod: 27205). Försök igen`,
        error_27206: `Begäran misslyckades (felkod: 27206). Försök igen`,
        error_27207: `Begäran misslyckades (felkod: 27207). Försök igen`,
        no_history_found: `Du har inte använt något verktyg! <a href="/">Uppdatera</a> eller <a href="https://sv.tipard.com/">gå till den officiella webbplatsen</a>`,
        error_25301: `Fel: 25301. Kontakta oss`,
        error_25302: `Fel: 25302. Kontakta oss`,
        error_25303: `Begäran misslyckades (felkod: 25303). Försök igen`,
        error_25304: `Begäran misslyckades (felkod: 25304). Försök igen`,
        error_25305: `Begäran misslyckades (felkod: 25305). Försök igen`,
        error_25306: `Begäran misslyckades (felkod: 25306). Försök igen`,
        image_upscaler_p: `Fördelar med nedladdning av bild utan vattenstämpel:`,
        Available_for: `Tillgänglig för:`,
        credit_per: `%s kredit(er) per HD-bild`,
        still_valid: `Köpta plan(er) är fortfarande giltiga`,
        credit: `kredit(er)`,
        pc_3rd_info: `Logga in framgångsrikt. Gå till applikationen för vidare drift.`,
        use_online: `Använd onlinetjänsten`,
        use_download: `Använd skrivbordsprogrammet`,
        use_immediately: `Använd omedelbart`,
        Use_in_browser: `Använd i webbläsare`,
        win_desktop: `Windows`,
        Mac_desktop: `Mac`,
        credits_per: `{%} krediter per månad`,
        expire: `Utgångstid:`,
        viewDetails: `Visa detaljer`,
        viewHistory: `Visa historikfördelar >>`,
        viewDetailsInfo: `Observera: Om prenumerationsförmånerna förnyas inom 7 dagar efter utgången kan de outnyttjade förmånerna fortsätta att användas. Utgångstiden kommer också att uppdateras automatiskt till den nya prenumerationens förfallotid. Om det inte finns någon ny prenumeration efter 7 dagars utgångstid kommer alla utgångna förmåner att rensas.`,
        "connect_account": "Bind e-post till ditt konto",
        "connect_account_info": "Efter bindning kan du logga in med denna e-postadress.",
        "connect_now": "Bind nu",
        "no_email_bind": "Ingen e-postbindning",
        "bind_email": "Bind e-post",
        "connect_your_email_placeholder": "Var god skriv in din e-postadress",
        "bind_an_email": "Bind ett e-postmeddelande",
        "bind_info": "Du har loggat in framgångsrikt. Vänligen bind ett e-postmeddelande till ditt konto för att aktivera dina förmåner.",
        "bind_later": "Bind senare",
        "footer": "Copyright © 2024 Tipard Studio. Alla rättigheter förbehållna.",
        "overtime": "Felkod: {%}, försök igen",
        "hi": "Hej %s!",
        "Personal_Information": "Personlig information",
        "Access": "Tillträde",
        "Subscription_Plan": "(prenumerationsplan)",
        "No_orders": "Inga beställningar hittades.",
        "No_data": "Inga data",
        "Featured_Products": "Utvalda produkter",
        "More_Products": "Fler produkter",
        "Free_Download": "Gratis nedladdning",
        "Get_Started": "Kom igång",
        "Subscribe": "Prenumerera",
        "Verified": "Verifierad",
        "back_to_account_center": "Tillbaka till kontocenter",
        "success": "Framgång!",
        "successfully": "Du har framgångsrikt registrerat ett konto.",
        "Continue": "Fortsätta",
        "Already": "Har du redan ett konto?",
        "loading_verification": "Kontrollerar verifieringsstatus...",
        "email_no_verification": "Tyvärr, den registrerade e-postadressen har inte verifierats. Vänligen slutför verifieringen enligt instruktionerna ovan och klicka på knappen \"Klar\" igen för att slutföra registreringen.",
        "error_20505": "E-postmeddelandet har länkats",
        "error_20506": "Fel: 20506. Kontakta oss",
        "error_28401": "Vänligen ange din e-post",
        "error_28402": "Felaktigt e-postformat",
        "error_28403": "Fel: 28403. Kontakta oss",
        "error_28404": "Mejlet har registrerats",
        "error_28405": "Fel: 28405. Kontakta oss",
        "error_28406": "E-postmeddelandet har länkats",
        "error_28407": "Fel: 28407. Kontakta oss",
        "error_28408": "Det gick inte att skicka e-postmeddelandet",
        "error_28601": "Vänligen ange din e-post",
        "error_28602": "Vänligen ange verifieringskoden",
        "error_28603": "Felaktigt e-postformat",
        "error_28604": "Fel: 28604. Kontakta oss",
        "error_28605": "Verifieringskoden är felaktig",
        "error_28606": "Fel: 28606. Kontakta oss",
        "error_28607": "Mejlet har registrerats",
        "error_28608": "Det finns ingen e-post bunden till ditt konto",
        "error_28609": "E-postmeddelandet är inte verifierat",
        "error_28610": "Fel: 28610. Kontakta oss",
        "error_28611": "E-postmeddelandet har länkats",
        "error_28612": "Fel: 28612. Kontakta oss",
        "unbind_email_btn": "Ta bort länken till e-postmeddelandet",
        "binding_exchange": "Bind e-post",
        "Subscription_Status": "Prenumerationsstatus",
        "mask_unbind_info_1": "Är du säker på att ta bort länken till {%} från det här kontot? När du har tagit bort länken kan du inte längre kontrollera fördelarna med detta e-postmeddelande",
        "mask_unbind_info_2": "Är du säker på att du vill koppla bort alla datorer som är kopplade till denna registreringskod?<a href=\"https://sv.tipard.com/faq.html#unbind\">Läs mer om att byta enhet.</a>",
        "error_dc_link": "Fel, vänligen kontakta oss",
        "vcu_state": "Fördelar med full version:",
        "monthly_subscription": "1 månads licens",
        "year_subscription": "1 års licens",
        "to": "till",
        "lifetime_subscription": "Livstidslicens",
        "unbind": "Bekräfta",
        "unbind_success": "Avbindningen lyckades",
        "error_28401": "Vänligen ange din e-post",
        "error_28702": "Fel: 28702. Kontakta oss",
        "error_28703": "Verifieringskoden är felaktig",
        "error_28704": "Fel: 28704. Kontakta oss",
        "error_28705": "E-postmeddelandet har inte länkats",
        "error_28706": "Vänligen ange verifieringskoden",
        "error_28707": "Det gick inte att ta bort länken",
        "error_28901": "Fel: 28901. Kontakta oss",
        "error_28902": "Fel: 28902. Kontakta oss",
        "error_28903": "Fel: 28903. Kontakta oss",
        "error_28904": "Fel: 28904. Kontakta oss",
        "error_28905": "Fel: 28905. Kontakta oss",
        "error_28906": "Fel: 28906. Kontakta oss",
        "error_28907": "Ogiltig registreringskod",
        "error_28908": "Fel: 28908. Kontakta oss",
        "error_28909": "För närvarande inne i avkylningsperioden. Du kan bara koppla upp en gång i månaden.",
        "error_289010": "Fel: 289010. Kontakta oss",
        "error_289011": "Fel: 289011. Kontakta oss",
        "error_289012": "Fel: 289012. Kontakta oss",
        "activated_computer": "Aktiverad på %s/%s dator(er)",
        "bind_email_info": "Fördelarna med följande e-postmeddelanden kommer att bindas och användas under detta VIP-konto.",
        "error_26309": "Fel: 26309. Kontakta oss",
        "error_26310": "Ogiltig hänvisningskod",
        "error_26311": "Fel: 26311. Kontakta oss",
        "error_26312": "Ogiltig hänvisningskod",
        "error_26313": "Fel: 26313. Kontakta oss",
        "error_26314": "E-postmeddelandet har länkats",
        "activate_unbinding": "Byt enhet",
        "copy_registration_code": "Kopiera registreringskoden",
        "show_registration_code": "Visa registreringskod",
        "hide_registration_code": "Dölj registreringskoden",
        "other_binding_email": "Andra bindande e-post(ar)",
        "home": "Hem",
        "points_balance": "Poängsaldo:",
        "earn_now": "Tjäna nu >>",
        "earn_more": "Tjäna fler GRATISpoäng, lös in fler gåvor",
        "earn_or_redeem_points": "Tjäna/lös in poäng",
        "unbind_email_info": "Det aktuella kontot har kopplats till en e-postadress",
        "bind_email_title": "Om du vill associera fler beställningar för att köpa e-postadresser, klicka på knappen \"E-postförening\" nedan för att associera.",
        "bind_email_tip": "<span>Tips:</span> För närvarande stöds endast köp av e-postregistreringskoder.",
        "email_association": "E-postförening",
        "dissociation": "Dissociation",
        "back": "Tillbaka",
        "activate_benefits": "Vänligen ange köpe-e-postadressen som måste kopplas för att få verifieringskoden. Ange verifieringskoden som du fick i e-postmeddelandet i inmatningsrutan för verifieringskoden och klicka på knappen \"Verifiera aktivering\" för att aktivera förmånerna.",
        "e_mail": "E-post:",
        "verification_code": "Verifieringskod:",
        "obtain_verification_code": "Skaffa verifieringskod",
        "verification_association": "Verifiera kopplingen",
        "current_password": "Aktuellt lösenord:",
        "account_center": "Kontocenter",
        "new_password": "Nytt lösenord:",
        "confirm_password": "Bekräfta nytt lösenord:",
        "Send": "Skicka",
        "history_files": "Historikfiler",
        "delete_account_info_1": "1. Att ta bort ditt Tipard-konto är en permanent åtgärd och kan inte ångras. All din data, inklusive foton och inställningar, kommer att gå förlorade. Är du säker på att ta bort ditt Tipard-konto?",
        "delete_account_info_2": "2. Om du bestämmer dig för att ta bort Tipard-kontot, klicka på knappen Skicka och ange verifieringskoden från e-postmeddelandet vi skickade till dig.",
        "best_deals": "Bästa erbjudandena",
        "close": "Nära"
    },
    tr: {
        overtime_1: `Hata kodu:`,
        overtime_2: `, lütfen tekrar deneyin`,
        isnetwork: `İnternet hatası. Lütfen kontrol edip tekrar deneyin`,
        email_placeholder: `E-posta`,
        email_empty: `Lütfen e-posta girin`,
        email_not_valid: `E-posta geçerli değil`,
        email_not_valid_1: `Lütfen e-postanızı girin`,
        email_not_valid_2: `E-posta geçerli değil, lütfen farklı bir adres kullanın.`,
        email_not_valid_3: `E-posta girişi yok`,
        password_placeholder: `Parola`,
        password_empty: `Lütfen şifrenizi girin`,
        password_not_valid: `Yanlış hesap veya şifre`,
        password_not_valid_1: `8 karakterden uzun şifre gerekli`,
        password_not_valid_2: `Lütfen bir şifre oluşturun`,
        password_placeholder_1: `Şifreni oluştur`,
        password_placeholder_2: `Parolanızı doğrulayın`,
        password_placeholder_3: `Yeni şifre oluştur`,
        password_placeholder_4: `Yeni şifreyi onayla`,
        password_placeholder_5: `Eski şifreyi girin`,
        copy_password_empty: `Lütfen şifreyi onaylayın`,
        copy_password_not_valid: `Lütfen şifrenizi onaylayınız`,
        copy_passwords_inconsistent: `Şifre onayınız eşleşmiyor`,
        code_empty: `Lütfen doğrulama kodunu girin`,
        code_not_valid: `Geçersiz doğrulama kodu`,
        code_placeholder: `Doğrulama kodu`,
        not_received_code: `Posta kutunuza uzun bir süre doğrulama kodu gelmediyse, lütfen doğrulama kodunu tekrar alın.`,
        get_first_code: `Lütfen önce doğrulama kodunu alın.`,
        last_name_placeholder: `Lütfen soyadınızı giriniz`,
        first_name_placeholder: `Lütfen isminizi giriniz`,
        address_placeholder: `Lütfen adresinizi girin`,
        no_code_text: `Bir doğrulama kodu gönderdik. Lütfen kodunuzu girin. <span class='tips'>Bir kod almadınız mı?`,
        no_code_text_1: `1.Lütfen e-posta adresinin geçerli olduğundan ve e-posta alabildiğinden emin olun.`,
        no_code_text_2: `2. E-posta sistem tarafından otomatik olarak gönderildiği için spam veya önemsiz e-posta olarak işaretlenebilir. Lütfen e-postanın Çöp Kutusu klasöründe olup olmadığını kontrol edin.`,
        no_code_text_3: `3. Sorununuzu çözemiyor musunuz?`,
        no_code_text_3_span: `O halde bizimle iletişime geçmek için buraya tıklayın.`,
        order_no: `Herhangi bir ürün satın almadınız. Herhangi bir sorunuz varsa lütfen <a href="https://tr.tipard.com/store.html">Şimdi alışveriş yapın</a> Veya <a href="https://tr.tipard.com/contact.html"> Bize ulaşın</a>`,
        error_24901: `Cari hesaba bağlı e-posta yok ve siparişleri bulamıyor. Lütfen bir e-posta bağlayın.`,
        user_guide: `Kullanım Kılavuzu >>`,
        download: `İndirmek`,
        order_number: `Sipariş numarası`,
        Refund: `Geri ödeme`,
        Disabled: `Engelli`,
        Normal: `Normal`,
        Modify: `değiştir`,
        Modify_1: `Değiştir >>`,
        Connect: `Bağlamak`,
        unlink_success: `Bağlantıyı başarıyla kaldır`,
        connect_success: `Başarıyla bağlan`,
        feedback_title: `Geri bildiriminiz için teşekkürler. Lütfen sorununuzu bırakın, size 24 saat içinde cevap vereceğiz.`,
        feedback_thank_you: `Teşekkürler!<br />Geri bildiriminiz başarıyla gönderildi.`,
        feedback_email: `E-posta adresinizi buraya girin!`,
        feedback_content: `Karşılaştığınız herhangi bir sorunu veya öneriyi burada bırakın.`,
        feedback_submit: `Göndermek`,
        form_contents: `Herhangi bir açıklama girmediniz. Lütfen girin ve tekrar gönderin.`,
        old_password: `Lütfen eski şifreyi girin`,
        new_password: `Lütfen yeni bir şifre oluşturun`,
        old_new_password: `Yeni şifre eskisi ile aynı olamaz`,
        incorrect_password: `yanlış parola`,
        delete_no: `Şimdi sil`,
        Caps: `Caps Lock açık`,
        Get: `Elde etmek`,
        Done: `Tamamlandı`,
        error_20001: `Hata: 20001. Lütfen tekrar giriş yapın.`,
        error_20002: `Hata: 20002. Lütfen tekrar giriş yapın.`,
        error_20003: `Hata: 20003. Lütfen tekrar giriş yapın.`,
        error_20004: `İstek başarısız oldu (hata kodu: 20004). Lütfen tekrar deneyin.`,
        error_20005: `Oturum açma oturumunun süresi doldu (Hata: 20005). Lütfen tekrar giriş yapın.`,
        error_20006: `İstek başarısız oldu (hata kodu: 20006). Lütfen tekrar deneyin.`,
        error_20007: `Oturum açma oturumunun süresi doldu (Hata: 20007). Lütfen tekrar giriş yapın.`,
        error_20008: `Oturum açma oturumunun süresi doldu (Hata: 20008). Lütfen tekrar giriş yapın.`,
        error_20009: `Oturum açma oturumunun süresi doldu (Hata: 20009). Lütfen tekrar giriş yapın.`,
        error_20101: `Lütfen e-posta adresinizi girin (hata kodu: 20101)`,
        error_20102: `E-posta geçerli değil (hata kodu: 20102)`,
        error_20103: `İstek başarısız oldu (hata kodu: 20103). Lütfen tekrar deneyin`,
        error_20104: `E-posta zaten kullanılıyor, lütfen <a href="https://account.tipard.com/login">giriş yapın</a> veya yeni bir tane ile kaydolun`,
        error_20105: `İstek başarısız oldu (hata kodu: 20105). Lütfen tekrar deneyin`,
        error_20106: `E-posta gönderilemedi, lütfen tekrar deneyin`,
        error_20201: `Lütfen e-posta adresinizi girin (hata kodu: 20201)`,
        error_20202: `Lütfen şifrenizi girin (hata kodu: 20202)`,
        error_20203: `Lütfen doğrulama kodunu girin (hata kodu: 20203)`,
        error_20204: `E-posta geçerli değil (hata kodu: 20204)`,
        error_20205: `8 karakterden uzun şifre gerekli (hata kodu: 20205)`,
        error_20206: `İstek başarısız oldu (hata kodu: 20206). Lütfen tekrar deneyin`,
        error_20207: `Geçersiz doğrulama kodu`,
        error_20208: `İstek başarısız oldu (hata kodu: 20208). Lütfen tekrar deneyin`,
        error_20209: `İstek başarısız oldu (hata kodu: 20209). Lütfen tekrar deneyin`,
        error_20301: `Lütfen e-posta adresinizi girin (hata kodu: 20301)`,
        error_20302: `Hata: 20302. Lütfen bizimle iletişime geçin`,
        error_20303: `E-posta geçerli değil (hata kodu: 20303)`,
        error_20304: `İstek başarısız oldu (hata kodu: 20304). Lütfen tekrar deneyin`,
        error_20305: `Hesap mevcut değil. Lütfen önce yeniden girin veya <a href="https://account.tipard.com/register">Oluştur</a>.`,
        error_20306: `Henüz şifre yok. <a href="https://account.tipard.com/passwordless-login">Parolasız giriş</a> kullanın veya <a href="create-password">bir şifre belirleyin</a> ve giriş yapın.`,
        error_20308: `İstek başarısız oldu (hata kodu: 20308). Lütfen tekrar deneyin`,
        error_20401: `Oturum kapatılamadı (hata kodu: 20401). Lütfen tekrar deneyin`,
        error_20501: `Lütfen e-posta adresinizi girin (hata kodu: 20501)`,
        error_20502: `E-posta geçerli değil (hata kodu: 20502)`,
        error_20503: `İstek başarısız oldu (hata kodu: 20503). Lütfen tekrar deneyin`,
        error_20504: `E-posta gönderilemedi. Lütfen tekrar deneyin.`,
        error_20601: `Lütfen e-posta adresinizi girin (hata kodu: 20601)`,
        error_20602: `Lütfen doğrulama kodunu girin (hata kodu: 20602)`,
        error_20603: `E-posta geçerli değil (hata kodu: 20603)`,
        error_20604: `İstek başarısız oldu (hata kodu: 20604). Lütfen tekrar deneyin`,
        error_20606: `İstek başarısız oldu (hata kodu: 20606). Lütfen tekrar deneyin`,
        error_20607: `İstek başarısız oldu (hata kodu: 20607). Lütfen tekrar deneyin`,
        error_20608: `İstek başarısız oldu (hata kodu: 20608). Lütfen tekrar deneyin`,
        error_20701: `Lütfen e-posta adresinizi girin (hata kodu: 20701)`,
        error_20702: `E-posta geçerli değil (hata kodu: 20702)`,
        error_20703: `İstek başarısız oldu (hata kodu: 20703). Lütfen tekrar deneyin`,
        error_20704: `Hesap mevcut değil. Lütfen önce yeniden girin veya <a href="https://account.tipard.com/register">Oluştur</a>.`,
        error_20705: `İstek başarısız oldu (hata kodu: 20705). Lütfen tekrar deneyin`,
        error_20706: `E-posta gönderilemedi. Lütfen tekrar deneyin`,
        error_20801: `Lütfen e-posta adresinizi girin (hata kodu: 20801)`,
        error_20802: `Hata: 20802. Lütfen bize ulaşın`,
        error_20803: `Lütfen doğrulama kodunu girin (hata kodu: 20803)`,
        error_20804: `E-posta geçerli değil (hata kodu: 20804)`,
        error_20805: `8 karakterden uzun şifre gerekli (hata kodu: 20805)`,
        error_20806: `İstek başarısız oldu (hata kodu: 20806). Lütfen tekrar deneyin`,
        error_20808: `İstek başarısız oldu (hata kodu: 20808). Lütfen tekrar deneyin`,
        error_20901: `İstek başarısız oldu (hata kodu: 20901). Lütfen tekrar deneyin`,
        error_20902: `İstek başarısız oldu (hata kodu: 20902). Lütfen tekrar deneyin`,
        error_21000: `Değişiklikler kaydedildi`,
        error_21001: `Hiçbir bilgi gönderilmedi (hata kodu: 21001)`,
        error_21002: `İstek başarısız oldu (hata kodu: 21002). Lütfen tekrar deneyin`,
        error_21101: `Lütfen e-posta adresinizi girin (hata kodu: 21101)`,
        error_21102: `E-posta geçerli değil (hata kodu: 21102)`,
        error_21103: `İstek başarısız oldu (hata kodu: 21103), lütfen tekrar deneyin`,
        error_21104: `E-posta zaten bağlı, lütfen yeni bir tane kullanın`,
        error_21105: `İstek başarısız oldu (hata kodu: 21105), lütfen tekrar deneyin`,
        error_21106: `E-posta gönderilemedi. Lütfen tekrar deneyin`,
        error_21201: `Lütfen e-posta adresinizi girin (hata kodu: 21201)`,
        error_21202: `Lütfen doğrulama kodunu girin (hata kodu: 21202)`,
        error_21203: `E-posta geçerli değil (hata kodu: 21203)`,
        error_21204: `Hata: 21204. Lütfen bize ulaşın`,
        error_21205: `Hata: 21205. Lütfen bize ulaşın`,
        error_21206: `8 karakterden uzun şifre gerekli (hata kodu: 21206)`,
        error_21207: `İstek başarısız oldu (hata kodu: 21207). Lütfen tekrar deneyin`,
        error_21209: `İstek başarısız oldu (hata kodu: 21209). Lütfen tekrar deneyin`,
        error_21301: `Lütfen eski şifreyi girin (hata kodu: 21301)`,
        error_21302: `Lütfen yeni bir şifre oluşturun (hata kodu: 21302)`,
        error_21303: `Yeni şifre eskisi ile aynı olamaz. (Hata: 21303)`,
        error_21304: `8 karakterden uzun şifre gerekli (hata kodu: 21304)`,
        error_21306: `İstek başarısız oldu (hata kodu: 21306). Lütfen tekrar deneyin`,
        error_21402: `İstek başarısız oldu (hata kodu: 21402). Lütfen tekrar deneyin`,
        error_21403: `Doğrulama kodu gönderilemedi. lütfen tekrar gönderin`,
        error_21500: `hesap silindi`,
        error_21501: `Lütfen doğrulama kodunu girin (hata kodu: 21501)`,
        error_21502: `Oturum açma oturumunun süresi doldu (Hata: 21502). Lütfen tekrar giriş yapın.`,
        error_21503: `İstek başarısız oldu (hata kodu: 21503). Lütfen tekrar deneyin`,
        error_21505: `İstek başarısız oldu (hata kodu: 21505), lütfen yeniden deneyin`,
        error_21601: `Hata: 20601. Lütfen bizimle iletişime geçin`,
        error_21602: `Geçersiz Doğrulama (Hata: 20602). Lütfen tekrar deneyin.`,
        error_21603: `Hata: 20603. Lütfen tekrar deneyin`,
        error_21604: `İstek başarısız oldu (hata kodu: 21604). Lütfen tekrar deneyin`,
        error_21606: `İstek başarısız oldu (hata kodu: 21606). Lütfen tekrar deneyin`,
        error_21611: `İstek başarısız oldu (hata kodu: 21611). Lütfen tekrar deneyin`,
        error_21801: `Hata: 21801. Lütfen bizimle iletişime geçin`,
        error_21802: `İstek başarısız oldu (Hata: 21802). Lütfen tekrar deneyin`,
        error_21803: `Hata: 21803. Lütfen tekrar deneyin`,
        error_21804: `İstek başarısız oldu (hata kodu: 21804). Lütfen tekrar deneyin`,
        error_21806: `Hata: 21806. Lütfen tekrar deneyin`,
        error_21807: `Hata: 21807. Lütfen bizimle iletişime geçin`,
        error_21808: `Hata: 21808. Lütfen bize ulaşın`,
        error_21809: `Hata: 21809. Lütfen bize ulaşın`,
        error_21810: `Hata: 21810. Lütfen bize ulaşın`,
        error_21811: `Hata: 21811. Lütfen bize ulaşın`,
        error_21812: `Hata: 21812. Lütfen bize ulaşın`,
        error_21813: `İstek başarısız oldu (hata kodu: 21813). Lütfen tekrar deneyin`,
        error_21814: `Hata: 21814. Lütfen bize ulaşın`,
        error_21815: `İstek başarısız oldu (hata kodu: 21815). Lütfen tekrar deneyin`,
        error_21816: `Hata: 21816. Lütfen bize ulaşın`,
        error_21817: `Hata: 21817. Lütfen bizimle iletişime geçin`,
        error_21818: `Hata: 21818. Lütfen bize ulaşın`,
        error_21819: `İstek başarısız oldu (hata kodu: 21819). Lütfen tekrar deneyin`,
        error_21820: `Hata: 21820. Lütfen bize ulaşın`,
        error_21821: `Hata: 21821. Lütfen bize ulaşın`,
        error_21822: `Hata: 21822. Lütfen bize ulaşın`,
        error_21823: `İstek başarısız oldu (hata kodu: 21823). Lütfen tekrar deneyin`,
        error_21824: `İstek başarısız oldu (hata kodu: 21824). Lütfen tekrar deneyin`,
        error_21825: `İstek başarısız oldu (hata kodu: 21825). Lütfen tekrar deneyin`,
        error_21826: `İstek başarısız oldu (hata kodu: 21826). Lütfen tekrar deneyin`,
        error_21901: `Hata: 21901. Lütfen bizimle iletişime geçin`,
        error_21902: `İstek başarısız oldu (hata kodu: 21902). Lütfen tekrar deneyin`,
        error_21903: `Hesap durumu değişti (hata kodu: 21903), lütfen sayfayı yenileyin ve tekrar deneyin`,
        error_21904: `Hata: 21904. Lütfen tekrar deneyin`,
        error_21905: `Hata: 21905. Lütfen tekrar deneyin`,
        error_21906: `İstek başarısız oldu (hata kodu: 21906). Lütfen tekrar deneyin`,
        error_21907: `Google hesabı başka bir hesaba bağlandı`,
        error_21908: `İstek başarısız oldu (hata kodu: 21908). Lütfen tekrar deneyin`,
        error_22001: `İstek başarısız oldu (hata kodu: 22001). Lütfen tekrar deneyin`,
        error_22002: `Ek giriş yapılmadan bağlantı kaldırılamadı`,
        error_22003: `İstek başarısız oldu (hata kodu: 2203). Lütfen tekrar deneyin`,
        error_22101: `Hata: 22101. Lütfen bizimle iletişime geçin`,
        error_22102: `Hesap durumu değişti (hata kodu: 22102), lütfen sayfayı yenileyin ve tekrar deneyin`,
        error_22103: `İstek başarısız oldu (hata kodu: 22103). Lütfen tekrar deneyin`,
        error_22104: `Hesap durumu değişti (hata kodu: 22104), lütfen sayfayı yenileyin ve tekrar deneyin`,
        error_22105: `Hata: 22105. Lütfen tekrar deneyin`,
        error_22106: `Hata: 22106. Lütfen tekrar deneyin`,
        error_22107: `Hata: 22107. Lütfen bizimle iletişime geçin`,
        error_22108: `İstek başarısız oldu (hata kodu: 22108). Lütfen tekrar deneyin`,
        error_22201: `Hata: 22201. Lütfen bize ulaşın`,
        error_22202: `Geçersiz Doğrulama (Hata: 22202). Lütfen tekrar deneyin.`,
        error_22203: `Hata: 22203. Lütfen tekrar deneyin"`,
        error_22204: `İstek başarısız oldu (hata kodu: 22204). Lütfen tekrar deneyin`,
        error_22206: `İstek başarısız oldu (hata kodu: 22206). Lütfen tekrar deneyin`,
        error_22401: `Hata: 22401. Lütfen bize ulaşın`,
        error_22402: `Geçersiz Doğrulama (Hata: 22402). Lütfen tekrar deneyin.`,
        error_22403: `Hata: 22403. Lütfen tekrar deneyin`,
        error_22404: `İstek başarısız oldu (hata kodu: 22404). Lütfen tekrar deneyin`,
        error_22405: `Facebook hesabı başka bir e-postaya bağlandı`,
        error_22406: `Hata: 22406. Lütfen tekrar deneyin`,
        error_22407: `Hata: 22407. Lütfen bize ulaşın`,
        error_22408: `Hata: 22408. Lütfen bize ulaşın`,
        error_22409: `Hata: 22409. Lütfen bize ulaşın`,
        error_22410: `Hata: 224010. Lütfen bize ulaşın`,
        error_22411: `Hata: 224011. Lütfen bize ulaşın`,
        error_22412: `Hata: 224012. Lütfen bize ulaşın`,
        error_22413: `Hata: 22413. Lütfen bize ulaşın`,
        error_22414: `İstek başarısız oldu (hata kodu: 22414). Lütfen tekrar deneyin`,
        error_22415: `Hata: 22415. Lütfen bize ulaşın`,
        error_22416: `Hata: 22416. Lütfen bize ulaşın`,
        error_22417: `Hata: 22417. Lütfen bize ulaşın`,
        error_22418: `İstek başarısız oldu (hata kodu: 22418). Lütfen tekrar deneyin`,
        error_22419: `Hata: 22419. Lütfen bize ulaşın`,
        error_22420: `Hata: 22420. Lütfen bize ulaşın`,
        error_22421: `Hata: 22421. Lütfen bize ulaşın`,
        error_22422: `İstek başarısız oldu (hata kodu: 22422). Lütfen tekrar deneyin`,
        error_22423: `İstek başarısız oldu (hata kodu: 22423). Lütfen tekrar deneyin`,
        error_22424: `İstek başarısız oldu (hata kodu: 22424). Lütfen tekrar deneyin`,
        error_22425: `İstek başarısız oldu (hata kodu: 22425). Lütfen tekrar deneyin`,
        error_20098: `Hata kodu: 20098. Özel modda göz atıyorsanız, lütfen Normal moda geçin ve tekrar deneyin.`,
        error_22298: `Google isteği başarısız oldu (Hata: 22298). Lütfen tekrar deneyin.`,
        error_22498: `Facebook isteği başarısız oldu (Hata: 22498). Lütfen tekrar deneyin.`,
        error_24902: `İstek başarısız oldu (hata kodu: 24902). Lütfen tekrar deneyin`,
        error_24903: `İstek başarısız oldu (hata kodu: 24903). Lütfen tekrar deneyin`,
        error_24904: `İstek başarısız oldu (hata kodu: 24904). Lütfen tekrar deneyin`,
        error_24905: `İstek başarısız oldu (hata kodu: 24905). Lütfen tekrar deneyin`,
        login_title: `Tipard'ta oturum açın`,
        password_placeholder: `Parola`,
        log_in: `Giriş yapmak`,
        no_account: `Hesap yok?`,
        create_it: `Oluştur`,
        or_log_in_with: `Veya ile giriş yapın`,
        passwordless_login: `Şifresiz oturum açma`,
        log_in_done: `Giriş Yap Yapıldı`,
        three_rd_account_connect_info: `Tebrikler! Başarıyla giriş yaptınız. Artık gelecekte oturum açmak için kullanılan bir e-posta hesabını bağlayabilirsiniz.`,
        see_my_account: `hesabımı gör`,
        three_rd_login_merge_account: `Üçüncü taraf hesap e-posta adresi kaydedildi, doğrudan bu e-posta adresiyle bağlanmak ve giriş yapmak istiyor musunuz?`,
        connect_log_in: `Bağlan ve Giriş Yap`,
        create_an_account: `Bir hesap oluşturun`,
        back_to_log_in: `Giriş yapmak için geri dön`,
        create_password: `Şifre oluştur`,
        create_now: `Şimdi oluştur`,
        password_login_subtitle: `E-posta ile şifresiz giriş`,
        account_login: `Hesap girişi`,
        rights: `Bu hesabı oluşturarak, <a href="https://tr.tipard.com/terms.html">Hizmet Şartları</a> ve <a href="https://tr.tipard.com/policy.html">Gizlilik Politikası</a>`,
        passwordless_login_done: `Şifresiz giriş yapıldı`,
        passwordless_login_info: `Tebrikler, şifresiz oturum açma işlemini başarıyla tamamladınız. Bu hesap için bir şifre oluşturabilir ve gelecekte hesap ve şifre ile giriş yapabilirsiniz. <a href="/create-password" style="display: initial;">Şimdi Oluşturun</a>`,
        sign_up: `Üye olmak`,
        register_info: `hesabını oluştur`,
        reset_now: `Şimdi Sıfırla`,
        forgot_password: `Parolanızı mı unuttunuz`,
        reset_password_subtitle: `Şifreyi sıfırlamak için hesap e-postanızı kullanın`,
        plan_products: `Plan ve Ürünler`,
        nick_name: `Kullanıcı adı`,
        email: `E-posta`,
        my_products: `Ürünlerim`,
        my_orders: `Siparişlerim`,
        unlink: `Bağlantıyı kaldır`,
        link: `Bağlantı`,
        connected_accounts: `Bağlı hesaplar`,
        last_name: `Soyadı`,
        first_name: `İlk adı`,
        Gender: `Cinsiyet`,
        Birth: `Doğum`,
        Month: `Ay`,
        Year: `Yıl`,
        Country_Region: `Ülke/Bölge`,
        Address: `Adres`,
        Save: `Kaydetmek`,
        Date: `Tarih`,
        Male: `Erkek`,
        Female: `Dişi`,
        Unspecified: `Belirtilmemiş`,
        Security: `Güvenlik`,
        change_password: `Şifre değiştir`,
        change_now: `Şimdi değiştir`,
        connect_email: `E-postayı bağla`,
        delete_account: `Hesabı sil`,
        delete_account_info: `Hesabınız silindiğinde, Plan ve Ürünler'deki hesabınızla ilişkili tüm veriler kalıcı olarak silinecek ve bunları kurtaramayabilirsiniz. Dikkatli yürümenizi öneririz.`,
        Delete: `Silmek`,
        Logout: `Çıkış Yap`,
        my_profile: `Benim profilim`,
        guides_faqs: `Kılavuzlar ve SSS`,
        More: `Daha`,
        guides: `Kılavuzlar`,
        register: `Kayıt ol`,
        hot_faq: `Sıcak SSS`,
        Contents: `İçindekiler:`,
        contact_us: `Bize ulaşın>>`,
        plan: `Plan`,
        unregistered: `Kayıtsız`,
        buy_more: `Daha fazla satın al`,
        buy_again: `Tekrar satın al`,
        buy_now: `Şimdi al`,
        free_no_limit: `Ücretsiz ve Sınırsız`,
        expired: `Süresi doldu`,
        lifetime: `Ömür`,
        remain: `Geriye kalmak`,
        day_s: `Gün(ler)`,
        day: `Gün`,
        error_24801: `İstek başarısız oldu (hata kodu: 24801). Lütfen tekrar deneyin`,
        no_app_found: `Uygulama bulunamadı！<a href="/">Yenile</a> veya <a href="https://tr.tipard.com/">Resmi web sitesine gidin</a>`,
        get_more: `Daha Fazlasını Alın >>`,
        edit_photo: `Fotoğrafı Düzenle`,
        select_photo: `Fotoğraf seç`,
        change_photo: `Fotoğrafı değiştir`,
        cancel: `İptal`,
        hide_password: `Şifreyi gizle`,
        show_password: `Şifreyi göster`,
        zoom_in: `Yakınlaştır`,
        zoom_out: `Uzaklaştırmak`,
        rotate: `Döndür`,
        horizontal_flip: `yatay çevirme`,
        vertical_flip: `Dikey çevirme`,
        country: `Ülke`,
        country_1: `Ülkenizi/bölgenizi seçin`,
        country_2: `Åaland Adası`,
        country_3: `Afganistan`,
        country_4: `Arnavutluk`,
        country_5: `Cezayir`,
        country_6: `Amerikan Samoası`,
        country_7: `Andora`,
        country_8: `Angola`,
        country_9: `anguilla`,
        country_10: `Antarktika`,
        country_11: `Antigua ve Barbuda`,
        country_12: `Arjantin`,
        country_13: `Ermenistan`,
        country_14: `Aruba`,
        country_15: `Avustralya`,
        country_16: `Avusturya`,
        country_17: `Azerbaycan`,
        country_18: `Bahreyn`,
        country_19: `Bangladeş`,
        country_20: `Barbados`,
        country_21: `Belarus`,
        country_22: `Belçika`,
        country_23: `Belize`,
        country_24: `Benin`,
        country_25: `Bermuda`,
        country_26: `Butan`,
        country_27: `Bolivya`,
        country_28: `Bosna-Hersek`,
        country_29: `Botsvana`,
        country_30: `Bouvet Adası`,
        country_31: `Brezilya`,
        country_32: `Britanya Hint Okyanusu Toprakları`,
        country_33: `Britanya Virjin Adaları`,
        country_34: `Brunei`,
        country_35: `Bulgaristan`,
        country_36: `Burkina`,
        country_37: `Burundi`,
        country_38: `Kamboçya`,
        country_39: `Kamerun`,
        country_40: `Kanada`,
        country_41: `Yeşil Burun Adaları`,
        country_42: `Karayip Hollandası`,
        country_43: `Cayman Adaları`,
        country_44: `Orta Afrika Cumhuriyeti`,
        country_45: `Çad`,
        country_46: `Şili`,
        country_47: `Çin`,
        country_48: `Noel Adası`,
        country_49: `Cocos (Keyling) Adaları`,
        country_50: `Kolombiya`,
        country_51: `Cook Adaları`,
        country_52: `Kosta Rika`,
        country_53: `"Fildişi Sahili"`,
        country_54: `Hırvatistan`,
        country_55: `Küba`,
        country_56: `Kıbrıs`,
        country_57: `Çek Cumhuriyeti`,
        country_58: `Kongo Demokratik Cumhuriyeti`,
        country_59: `Danimarka`,
        country_60: `Cibuti`,
        country_61: `Dominika`,
        country_62: `Dominik Cumhuriyeti`,
        country_63: `Ekvador`,
        country_64: `Mısır`,
        country_65: `El Salvador`,
        country_66: `Ekvator Ginesi`,
        country_67: `Eritre`,
        country_68: `Estonya`,
        country_69: `Etiyopya`,
        country_70: `Falkland adaları`,
        country_71: `Faroe Adaları`,
        country_72: `Mikronezya Federe Devletleri`,
        country_73: `Fiji`,
        country_74: `Finlandiya`,
        country_75: `Fransa`,
        country_76: `Fransız Guyanası`,
        country_77: `Fransız Polinezyası`,
        country_78: `Fransız Güney Toprakları`,
        country_79: `Gabon`,
        country_80: `Gambiya`,
        country_81: `Gürcistan`,
        country_82: `Almanya`,
        country_83: `Gana`,
        country_84: `Cebelitarık`,
        country_85: `Büyük Britanya (Birleşik Krallık; İngiltere)`,
        country_86: `Yunanistan`,
        country_87: `Grönland`,
        country_88: `Grenada`,
        country_89: `Guadelup`,
        country_90: `Guam`,
        country_91: `Guatemala`,
        country_92: `Guernsey`,
        country_93: `Gine`,
        country_94: `Gine-Bissau`,
        country_95: `Guyana`,
        country_96: `Haiti`,
        country_97: `Heard Adası ve McDonald Adaları`,
        country_98: `Honduras`,
        country_99: `Hong Kong`,
        country_100: `Macaristan`,
        country_101: `İzlanda`,
        country_102: `Hindistan`,
        country_103: `Endonezya`,
        country_104: `İran`,
        country_105: `Irak`,
        country_106: `İrlanda`,
        country_107: `Man Adası`,
        country_108: `İsrail`,
        country_109: `İtalya`,
        country_110: `Jamaika`,
        country_111: `Japonya`,
        country_112: `Jersey`,
        country_113: `Ürdün`,
        country_114: `Kazakistan`,
        country_115: `Kenya`,
        country_116: `Kiribati`,
        country_117: `Kuveyt`,
        country_118: `Kırgızistan`,
        country_119: `Laos`,
        country_120: `Letonya`,
        country_121: `Lübnan`,
        country_122: `Lesoto`,
        country_123: `Liberya`,
        country_124: `Libya`,
        country_125: `Lihtenştayn`,
        country_126: `Litvanya`,
        country_127: `Lüksemburg`,
        country_128: `Makao`,
        country_129: `Madagaskar`,
        country_130: `Malawi`,
        country_131: `Malezya`,
        country_132: `Maldivler`,
        country_133: `Mali`,
        country_134: `Malta`,
        country_135: `Marşal Adaları`,
        country_136: `Martinik`,
        country_137: `Moritanya`,
        country_138: `Mauritius`,
        country_139: `mayo`,
        country_140: `Meksika`,
        country_141: `Moldova`,
        country_142: `Monako`,
        country_143: `Moğolistan`,
        country_144: `Karadağ`,
        country_145: `Montserrat`,
        country_146: `Fas`,
        country_147: `Mozambik`,
        country_148: `Myanmar (Burma)`,
        country_149: `Namibya`,
        country_150: `Nauru`,
        country_151: `Nepal`,
        country_152: `Hollanda`,
        country_153: `Yeni Kaledonya`,
        country_154: `Yeni Zelanda`,
        country_155: `Nikaragua`,
        country_156: `Nijer`,
        country_157: `Nijerya`,
        country_158: `Niue`,
        country_159: `Norfolk Adası`,
        country_160: `Kuzey Kore`,
        country_161: `Kuzey Mariana Adaları`,
        country_162: `Norveç`,
        country_163: `Umman`,
        country_164: `Pakistan`,
        country_165: `Palau`,
        country_166: `Filistin Bölgesi`,
        country_167: `Panama`,
        country_168: `Papua Yeni Gine`,
        country_169: `Paraguay`,
        country_170: `Peru`,
        country_171: `Pitcairn Adaları`,
        country_172: `Polonya`,
        country_173: `Portekiz`,
        country_174: `Porto Riko`,
        country_175: `Katar`,
        country_176: `Makedonya Cumhuriyeti (FYROM)`,
        country_177: `Kongo Cumhuriyeti`,
        country_178: `yeniden birleşme`,
        country_179: `Romanya`,
        country_180: `Rusya Federasyonu`,
        country_181: `Ruanda`,
        country_182: `Aziz Barthelemy`,
        country_183: `Saint Martin (Fransa)`,
        country_184: `Saint-Pierre ve Miquelon`,
        country_185: `Samoa`,
        country_186: `San Marino`,
        country_187: `Sao Kitabı ve Prensibi`,
        country_188: `Suudi Arabistan`,
        country_189: `Senegal`,
        country_190: `Sırbistan`,
        country_191: `Seyşeller`,
        country_192: `Sierra Leone`,
        country_193: `Singapur`,
        country_194: `Slovakya`,
        country_195: `Slovenya`,
        country_196: `Solomon Adaları`,
        country_197: `Somali`,
        country_198: `Güney Afrika`,
        country_199: `Güney Georgia ve Güney Sandwich Isla`,
        country_200: `Güney Kore`,
        country_201: `Güney Sudan`,
        country_202: `ispanya`,
        country_203: `Sri Lanka`,
        country_204: `St. Helena ve Bağımlılıklar`,
        country_205: `Aziz Kitts ve Nevis`,
        country_206: `Aziz Lucia`,
        country_207: `Saint Vincent ve Grenadinler`,
        country_208: `Sudan`,
        country_209: `Surinam`,
        country_210: `Svaziland`,
        country_211: `İsveç`,
        country_212: `İsviçre`,
        country_213: `Suriye`,
        country_214: `Tayvan`,
        country_215: `Tacikistan`,
        country_216: `Tanzanya`,
        country_217: `Şablon:Ülke verileri SJM Svalbard`,
        country_218: `Tayland`,
        country_219: `Bahamalar`,
        country_220: `Komorlar`,
        country_221: `Filipinler`,
        country_222: `Doğu Timor (Doğu Timor)`,
        country_223: `Gitmek`,
        country_224: `Tokelau`,
        country_225: `Tonga`,
        country_226: `Trinidad & Tobago`,
        country_227: `Tunus`,
        country_228: `Türkiye`,
        country_229: `Türkmenistan`,
        country_230: `Turks ve Caicos Adaları`,
        country_231: `Tuvalu`,
        country_232: `Uganda`,
        country_233: `Ukrayna`,
        country_234: `Birleşik Arap Emirlikleri`,
        country_235: `Amerika Birleşik Devletleri Küçük Dış Adaları`,
        country_236: `Amerika Birleşik Devletleri (ABD)`,
        country_237: `Amerika Birleşik Devletleri Virjin Adaları`,
        country_238: `Uruguay`,
        country_239: `Özbekistan`,
        country_240: `Vanuatu`,
        country_241: `Vatikan Şehri (Vatikan)`,
        country_242: `Venezuela`,
        country_243: `Vietnam`,
        country_244: `Wallis ve Futuna`,
        country_245: `Batı Sahra`,
        country_246: `Yemen`,
        country_247: `Zambiya`,
        country_248: `Zimbabve`,
        google_login: `Google ile giriş yap`,
        State: `Durum`,
        Activation_code: `Aktivasyon kodu`,
        Question: `Giriş yaptığınız tüm uygulamaları listeleyin`,
        Copy_complete: `Kopyalama tamamlandı`,
        change_password_success: `parola başarıyla değiştirildi`,
        successful_login_title: `Giriş başarılı`,
        product_page: `Ürün Sayfası>>`,
        successful_login_info: `Giriş tamamlandı. Lütfen mevcut sayfayı kapatın ve aşağıdaki işleme devam etmek için orijinal sekmeye dönün. Geçerli sayfa 5 saniye içinde otomatik olarak kapatılacaktır. "Bitti" düğmesine tıklayarak otomatik kapanma veya kapanma başarısız olursa, lütfen bu sekmeyi doğrudan kapatın.`,
        successful_login_info_firefox: `Giriş tamamlandı. Lütfen mevcut sayfayı kapatın ve aşağıdaki işleme devam etmek için orijinal sekmeye dönün.`,
        my_account: `Hesabım`,
        my_history: `Benim tarihim`,
        remove_watermark: `Filigranı Kaldır`,
        no_history: `Geçmiş Yok`,
        history_all: `Hepsini seç`,
        history_open: `Açık`,
        history_down: `İndirmek`,
        history_delete: `Silmek`,
        history_clear: `Geçersizi temizle`,
        images: `Görüntüler)`,
        use_this_function: `Bu İşlevi Kullan >>`,
        hd_downloading: `HD orijinal görüntüleri indirme avantajları:`,
        lifetimeRemaining: `Ömür boyu-Kalan`,
        Remaining: `Geriye kalan`,
        email_verification: `Eposta Doğrulama`,
        email_verification_info: `Doğrulama mesajını <span class="email"></span> e-posta adresinize gönderdik ve lütfen doğrulamayı tamamlayın. Doğrulamadan sonra, avantajlar otomatik olarak senkronize edilecektir.`,
        wrong_email: `Yanlış elektronik posta adresi?`,
        click_here_to_modify: `Değiştirmek için buraya tıklayın`,
        get_more_help: `Daha fazla yardım mı alıyorsunuz?`,
        click_here: `Buraya Tıkla`,
        email_verification_info_success: `E-posta hesabınızı doğruladığınız için tebrikler.`,
        email_verification_info_error: `Süresi dolmuş bağlantı nedeniyle doğrulama başarısız oldu.`,
        registration_succeeded: `Kayıt Başarılı`,
        registration_succeeded_info_1: `Tebrikler! Başarıyla kayıt oldunuz. Doğrulama mesajını <span class="email"></span> e-posta adresinize gönderdik ve bu e-postanın avantajlarından yararlanmak için lütfen doğrulama işlemini tamamlayın.`,
        registration_succeeded_info_2: `Ana sayfaya dönmek ve bu ürünü kullanmak için "Bitti"ye tıklayın.`,
        registration_succeeded_info_3: `Mevcut sayfayı kapatmak ve aşağıdaki işlemler için Ana sayfaya dönmek için "Bitti"ye tıklayın. Kapatma başarısız olursa, lütfen sekmeyi manuel olarak kapatın.`,
        verify_email: `E-mail'i doğrula`,
        registered_email_not_verified: `Kayıtlı e-posta doğrulanmadı, lütfen bir kerede doğrulayın.`,
        email_verification_time_1: `Doğrulama e-postası almadınız mı?`,
        email_verification_time_2: `Sonrasında`,
        email_verification_time_3: `Yeniden göndermek için buraya tıklayın`,
        error_26301: `Hata kodu: 26301, lütfen bize ulaşın`,
        error_26302: `Hata kodu: 26302, lütfen bize ulaşın`,
        error_26303: `E-posta biçimi hatası (hata kodu: 26303). Lütfen tekrar girin`,
        error_26304: `8 karakterden uzun şifre önerilir (hata kodu: 26304)`,
        error_26305: `Reuqest başarısız oldu (hata kodu: 26305). lütfen tekrar deneyin`,
        error_26306: `E-posta kaydedildi, lütfen <a href="https://account.tipard.com/login">giriş yapmak için gidin</a>`,
        error_26307: `Reuqest başarısız oldu (hata kodu: 26307). lütfen tekrar deneyin`,
        error_26308: `Reuqest başarısız oldu (hata kodu: 26308). lütfen tekrar deneyin`,
        error_26401: `Hata kodu: 26401, lütfen tekrar deneyin`,
        error_26402: `E-posta doğrulandı (hata kodu: 26402), lütfen tekrar deneyin`,
        error_26403: `Reuqest başarısız oldu (hata kodu: 26403). lütfen tekrar deneyin`,
        error_26404: `İstek başarısız oldu (hata kodu: 26404). lütfen tekrar deneyin`,
        error_26501: `Hata kodu: 26501, lütfen bize ulaşın`,
        error_26502: `Hata kodu: 26502, lütfen bize ulaşın`,
        error_26503: `E-posta biçimi hatası (hata kodu: 26503). Lütfen tekrar girin`,
        error_26504: `Reuqest başarısız oldu (hata kodu: 26504). lütfen tekrar deneyin`,
        error_26505: `E-posta kaydedilmedi, lütfen <a href="https://account.tipard.com/register">önce onu kaydedin</a>`,
        error_26506: `E-posta doğrulandı.`,
        error_26507: `Reuqest başarısız oldu (hata kodu: 26507). lütfen tekrar deneyin`,
        error_26508: `Doğrulama gerçekleşti (hata kodu: 26508), lütfen tekrar deneyin`,
        error_26509: `İstek başarısız oldu (hata kodu: 26509), lütfen tekrar deneyin`,
        error_26510: `Hata kodu: 26510, lütfen bize ulaşın`,
        error_26601: `Hata kodu: 26601, lütfen bize ulaşın`,
        error_26602: `Hata kodu: 26602, lütfen bize ulaşın`,
        error_26603: `Reuqest başarısız oldu (hata kodu: 26603). lütfen tekrar deneyin`,
        error_26604: `Hata kodu: 26604, lütfen bize ulaşın`,
        error_26605: `Hata kodu: 26605, lütfen bize ulaşın`,
        error_26701: `Hata kodu: 26701, lütfen bize ulaşın`,
        error_26702: `Reuqest başarısız oldu (hata kodu: 26702). lütfen tekrar deneyin`,
        error_26703: `Hata kodu: 26703, lütfen bize ulaşın`,
        error_26704: `Hata kodu: 26704, lütfen bize ulaşın`,
        error_26705: `Oturum açmayı bekleyin (hata kodu: 26705). lütfen tekrar deneyin`,
        no_cookie: `Tarayıcınızda Tüm çerezleri engelle işlevini açtınız, bu nedenle giriş yapamazsınız. Lütfen Ayarlar'a giderek Tüm çerezlere izin ver kutusunu işaretleyin.`,
        error_26801: `Hata kodu: 26801, lütfen bize ulaşın`,
        error_26802: `Hata kodu: 26802, lütfen bize ulaşın`,
        error_26803: `İstek başarısız oldu (hata kodu: 26803). lütfen tekrar deneyin`,
        error_26804: `İstek başarısız oldu (hata kodu: 26804). lütfen tekrar deneyin`,
        error_order: `İstek başarısız oldu (hata kodu: 27098), lütfen tekrar deneyin!`,
        error_order1: `Sipariş sorgusu eksik; hata kodu:`,
        error_order2: `）, lütfen yenileyin ve tekrar deneyin.`,
        modify_email_title: `E-postayı Değiştir`,
        modify_email_info: `Hesabınıza giriş yapmak için değiştirilen e-postayı kullanabilirsiniz.`,
        images_per: `Görüntü başına`,
        error_26101: `Hata: 26101. Lütfen bize ulaşın`,
        error_26102: `Hata: 26102. Lütfen bize ulaşın`,
        error_26103: `İstek başarısız oldu (hata kodu:26103). Lütfen tekrar deneyin`,
        error_26104: `Hata kodu: 26104, lütfen tekrar deneyin`,
        error_26105: `Hata kodu: 26105, lütfen tekrar deneyin`,
        error_26106: `Silme başarısız oldu (hata kodu: 26106). Lütfen tekrar deneyin`,
        error_26201: `Hata: 26201. Lütfen bizimle iletişime geçin`,
        error_26202: `İstek başarısız oldu (hata kodu:26202). Lütfen tekrar deneyin`,
        error_26001: `Hata: 26001. Lütfen bize ulaşın`,
        error_26002: `Hata: 26002. Lütfen bize ulaşın`,
        error_26003: `Hata: 26003. Lütfen bizimle iletişime geçin`,
        error_26004: `Hata: 26004. Lütfen bize ulaşın`,
        error_26005: `İstek başarısız oldu (hata kodu:26005). Lütfen tekrar deneyin`,
        error_26006: `Hata kodu: 26006, lütfen tekrar deneyin`,
        error_26008: `Hata: 26008. Lütfen bizimle iletişime geçin`,
        go_to_the_home_page: `Ana sayfaya git`,
        error_27101: `İstek başarısız oldu (hata kodu: 27101). lütfen tekrar deneyin`,
        error_27201: `Hata kodu: 27201, lütfen bize ulaşın`,
        error_27202: `Hata kodu: 27202, lütfen tekrar deneyin`,
        error_27203: `İstek başarısız oldu (hata kodu: 27203). lütfen tekrar deneyin`,
        error_27204: `Geçersiz kod (hata kodu: 27204).`,
        error_27205: `İstek başarısız oldu (hata kodu: 27205). lütfen tekrar deneyin`,
        error_27206: `İstek başarısız oldu (hata kodu: 27206). lütfen tekrar deneyin`,
        error_27207: `İstek başarısız oldu (hata kodu: 27207). lütfen tekrar deneyin`,
        no_history_found: `Herhangi bir araç kullanmadınız! <a href="/">Yenile</a> veya <a href="https://tr.tipard.com/">Resmi web sitesine gidin</a>`,
        error_25301: `Hata: 25301. Lütfen bizimle iletişime geçin`,
        error_25302: `Hata: 25302. Lütfen bize ulaşın`,
        error_25303: `İstek başarısız oldu (hata kodu: 25303). Lütfen tekrar deneyin`,
        error_25304: `İstek başarısız oldu (hata kodu: 25304). Lütfen tekrar deneyin`,
        error_25305: `İstek başarısız oldu (hata kodu: 25305). Lütfen tekrar deneyin`,
        error_25306: `İstek başarısız oldu (hata kodu: 25306). Lütfen tekrar deneyin`,
        image_upscaler_p: `Filigransız resim indirme avantajları:`,
        Available_for: `İçin uygun:`,
        credit_per: `HD görüntü başına %s kredi`,
        still_valid: `Satın alınan plan(lar) hala geçerlidir`,
        credit: `kredi)`,
        pc_3rd_info: `Giriş başarılı. Daha fazla işlem için lütfen uygulamaya gidin.`,
        use_online: `Çevrimiçi hizmeti kullanın`,
        use_download: `Masaüstü programını kullanın`,
        use_immediately: `Hemen Kullanın`,
        Use_in_browser: `Tarayıcıda Kullan`,
        win_desktop: `pencereler`,
        Mac_desktop: `Mac`,
        credits_per: `Aylık {%} Kredi`,
        expire: `Son Kullanma Süresi:`,
        viewDetails: `Detayları göster`,
        viewHistory: `Geçmişin avantajlarını görüntüleyin>>`,
        viewDetailsInfo: `Uyarı: Abonelik avantajlarının sona ermesinden sonraki 7 gün içinde yenilenmesi durumunda kullanılmayan avantajlardan yararlanmaya devam edilebilir. Ayrıca sona erme süresi, yeni aboneliğin sona erme süresine göre otomatik olarak güncellenecektir. 7 günlük sona erme süresinden sonra yeni abonelik olmazsa, süresi dolan tüm avantajlar silinecektir.`,
        "connect_account": "E-postayı Hesabınıza Bağlayın",
        "connect_account_info": "Bağlandıktan sonra bu e-posta adresiyle giriş yapabilirsiniz.",
        "connect_now": "Şimdi Bağla",
        "no_email_bind": "E-posta bağlantısı yok",
        "bind_email": "E-postayı Bağla",
        "connect_your_email_placeholder": "lütfen e-mail adresinizi giriniz",
        "bind_an_email": "Bir E-postayı Bağla",
        "bind_info": "Başarıyla giriş yaptınız. Avantajlarınızı etkinleştirmek için lütfen hesabınıza bir e-posta bağlayın.",
        "bind_later": "Daha sonra bağla",
        "footer": "Telif Hakkı © 2024 Tipard Studio. Tüm hakları Saklıdır.",
        "overtime": "Hata kodu: {%}, lütfen tekrar deneyin",
        "hi": "Onun!",
        "Personal_Information": "Kişisel Bilgiler",
        "Access": "Erişim",
        "Subscription_Plan": "(Abonelik Planı)",
        "No_orders": "Sipariş bulunamadı.",
        "No_data": "Veri yok",
        "Featured_Products": "Öne Çıkan Ürünler",
        "More_Products": "Daha Fazla Ürün",
        "Free_Download": "Ücretsiz İndir",
        "Get_Started": "Başlayın",
        "Subscribe": "Abone",
        "Verified": "Doğrulandı",
        "back_to_account_center": "Hesap Merkezine Geri Dön",
        "success": "Başarı!",
        "successfully": "Başarıyla bir hesap oluşturdunuz.",
        "Continue": "Devam etmek",
        "Already": "Zaten bir hesabınız var mı?",
        "loading_verification": "Doğrulama durumu kontrol ediliyor...",
        "email_no_verification": "Üzgünüz, kayıtlı e-posta adresi doğrulanmadı. Lütfen yukarıdaki talimatlara göre doğrulamayı tamamlayın ve kaydı tamamlamak için tekrar \"Bitti\" düğmesine tıklayın.",
        "error_20505": "E-posta bağlandı",
        "error_20506": "Hata: 20506. Lütfen bizimle iletişime geçin",
        "error_28401": "Lütfen e-postanızı girin",
        "error_28402": "Yanlış e-posta biçimi",
        "error_28403": "Hata: 28403. Lütfen bizimle iletişime geçin",
        "error_28404": "E-posta kaydedildi",
        "error_28405": "Hata: 28405. Lütfen bizimle iletişime geçin",
        "error_28406": "E-posta bağlandı",
        "error_28407": "Hata: 28407. Lütfen bizimle iletişime geçin",
        "error_28408": "E-posta gönderilemedi",
        "error_28601": "Lütfen e-postanızı girin",
        "error_28602": "Lütfen doğrulama kodunu girin",
        "error_28603": "Yanlış e-posta biçimi",
        "error_28604": "Hata: 28604. Lütfen bizimle iletişime geçin",
        "error_28605": "Doğrulama kodu yanlış",
        "error_28606": "Hata: 28606. Lütfen bizimle iletişime geçin",
        "error_28607": "E-posta kaydedildi",
        "error_28608": "Hesabınıza bağlı bir e-posta adresi yok",
        "error_28609": "E-posta doğrulanmadı",
        "error_28610": "Hata: 28610. Lütfen bizimle iletişime geçin",
        "error_28611": "E-posta bağlandı",
        "error_28612": "Hata: 28612. Lütfen bizimle iletişime geçin",
        "unbind_email_btn": "E-postanın bağlantısını kaldır",
        "binding_exchange": "E-postayı Bağla",
        "Subscription_Status": "Abonelik Durumu",
        "mask_unbind_info_1": "{%} hesabını bu hesaptan bağlantısını kaldırmak istediğinizden emin misiniz? Bağlantıyı kaldırdıktan sonra, bu e-postanın avantajlarını artık kontrol edemezsiniz",
        "mask_unbind_info_2": "Bu kayıt koduyla ilişkili tüm bilgisayarların bağlantısını kaldırmak istediğinizden emin misiniz?<a href=\"https://tr.tipard.com/faq.html#unbind\">Cihazları değiştirme hakkında daha fazla bilgi edinin.</a>",
        "error_dc_link": "Hata, Lütfen bizimle iletişime geçin",
        "vcu_state": "Tam Sürüm Avantajları:",
        "monthly_subscription": "1 Aylık Lisans",
        "year_subscription": "1 Yıllık Lisans",
        "to": "ile",
        "lifetime_subscription": "Ömür boyu lisans",
        "unbind": "Onaylamak",
        "unbind_success": "Başarıyla çözüldü",
        "error_28401": "Lütfen e-postanızı girin",
        "error_28702": "Hata: 28702. Lütfen bizimle iletişime geçin",
        "error_28703": "Doğrulama kodu yanlış",
        "error_28704": "Hata: 28704. Lütfen bizimle iletişime geçin",
        "error_28705": "E-posta bağlanmadı",
        "error_28706": "Lütfen doğrulama kodunu girin",
        "error_28707": "Bağlantıyı kaldırma başarısız oldu",
        "error_28901": "Hata: 28901. Lütfen bizimle iletişime geçin",
        "error_28902": "Hata: 28902. Lütfen bizimle iletişime geçin",
        "error_28903": "Hata: 28903. Lütfen bizimle iletişime geçin",
        "error_28904": "Hata: 28904. Lütfen bizimle iletişime geçin",
        "error_28905": "Hata: 28905. Lütfen bizimle iletişime geçin",
        "error_28906": "Hata: 28906. Lütfen bizimle iletişime geçin",
        "error_28907": "Geçersiz kayıt kodu",
        "error_28908": "Hata: 28908. Lütfen bizimle iletişime geçin",
        "error_28909": "Şu anda bağlanmayı kaldırma soğuma süresinde. Ayda yalnızca bir kez bağlanmayı kaldırabilirsiniz.",
        "error_289010": "Hata: 289010. Lütfen bizimle iletişime geçin",
        "error_289011": "Hata: 289011. Lütfen bizimle iletişime geçin",
        "error_289012": "Hata: 289012. Lütfen bizimle iletişime geçin",
        "activated_computer": "%s/%s bilgisayarda etkinleştirildi",
        "bind_email_info": "Aşağıdaki e-postaların avantajları bu VIP hesabı altında bağlanacak ve kullanılacaktır.",
        "error_26309": "Hata: 26309. Lütfen bizimle iletişime geçin",
        "error_26310": "Geçersiz yönlendirme kodu",
        "error_26311": "Hata: 26311. Lütfen bizimle iletişime geçin",
        "error_26312": "Geçersiz yönlendirme kodu",
        "error_26313": "Hata: 26313. Lütfen bizimle iletişime geçin",
        "error_26314": "E-posta bağlandı",
        "activate_unbinding": "Cihazı Değiştir",
        "copy_registration_code": "Kayıt kodunu kopyala",
        "show_registration_code": "Kayıt kodunu göster",
        "hide_registration_code": "Kayıt kodunu gizle",
        "other_binding_email": "Diğer bağlayıcı e-posta(lar)",
        "home": "Ev",
        "points_balance": "Puan bakiyesi:",
        "earn_now": "Şimdi Kazan >>",
        "earn_more": "Daha Fazla ÜCRETSİZ Puan Kazanın, Daha Fazla Hediyeyi Kullanın",
        "earn_or_redeem_points": "Puan Kazan/Kullan",
        "unbind_email_info": "Cari hesap bir e-posta adresiyle ilişkilendirildi",
        "bind_email_title": "Daha fazla siparişi satın alma e-posta adresleriyle ilişkilendirmek istiyorsanız, ilişkilendirmek için lütfen aşağıdaki \"E-posta İlişkilendirme\" düğmesine tıklayın.",
        "bind_email_tip": "<span>İpucu:</span> Şu anda yalnızca e-posta kayıt kodu siparişlerinin satın alınması destekleniyor.",
        "email_association": "E-posta Derneği",
        "dissociation": "Ayrışma",
        "back": "Geri",
        "activate_benefits": "Lütfen doğrulama kodunu almak için ilişkilendirilmesi gereken satın alma e-postasını girin. E-postada alınan doğrulama kodunu doğrulama kodu giriş kutusuna girin ve faydaları etkinleştirmek için \"Aktivasyonu Doğrula\" düğmesine tıklayın.",
        "e_mail": "E-posta:",
        "verification_code": "Doğrulama Kodu:",
        "obtain_verification_code": "Doğrulama kodunu al",
        "verification_association": "İlişkilendirmeyi doğrulayın",
        "current_password": "Mevcut Şifre:",
        "account_center": "Hesap Merkezi",
        "new_password": "Yeni Şifre:",
        "confirm_password": "Yeni Şifreyi Onayla:",
        "Send": "Göndermek",
        "history_files": "Geçmiş Dosyaları",
        "delete_account_info_1": "1. Tipard hesabınızı silmek kalıcı bir işlemdir ve geri alınamaz. Fotoğraflarınız ve tercihleriniz dahil tüm verileriniz kaybolacaktır. Tipard hesabınızı silmek istediğinizden emin misiniz?",
        "delete_account_info_2": "2. Tipard hesabınızı silmeye karar verirseniz lütfen Gönder butonuna tıklayın ve size gönderdiğimiz e-postadaki doğrulama kodunu girin.",
        "best_deals": "En İyi Fırsatlar",
        "close": "Kapalı",
        "product":"Ürün:"
    },
    pt: {
        overtime_1: `Erro de código:`,
        overtime_2: `, tente novamente`,
        isnetwork: `Erro de Internet. Verifique e tente novamente`,
        email_placeholder: `E-mail`,
        email_empty: `Por favor, insira o e-mail`,
        email_not_valid: `E-mail não é válido`,
        email_not_valid_1: `Por favor, insira seu e-mail`,
        email_not_valid_2: `O e-mail não é válido, use um endereço diferente.`,
        email_not_valid_3: `Sem entrada de e-mail`,
        password_placeholder: `Senha`,
        password_empty: `Por favor, insira a senha`,
        password_not_valid: `Conta ou senha incorreta`,
        password_not_valid_1: `A senha acima de 8 caracteres é obrigatória`,
        password_not_valid_2: `Por favor, crie uma senha`,
        password_placeholder_1: `Crie sua senha`,
        password_placeholder_2: `Confirme sua senha`,
        password_placeholder_3: `Criar nova senha`,
        password_placeholder_4: `Confirme a nova senha`,
        password_placeholder_5: `Insira a senha antiga`,
        copy_password_empty: `Por favor, confirme a senha`,
        copy_password_not_valid: `Por favor, confirme sua senha`,
        copy_passwords_inconsistent: `A confirmação da sua senha não corresponde`,
        code_empty: `Insira o código de verificação`,
        code_not_valid: `Código de verificação inválido`,
        code_placeholder: `Código de verificação`,
        not_received_code: `Se sua caixa postal não recebeu o código de verificação por um longo tempo, obtenha o código de verificação novamente.`,
        get_first_code: `Obtenha o código de verificação primeiro.`,
        last_name_placeholder: `Por favor insira seu sobrenome`,
        first_name_placeholder: `por favor entre com seu primeiro nome`,
        address_placeholder: `Por favor, digite seu endereço`,
        no_code_text: `Enviamos um código de verificação. Por favor, digite seu código. <span class='tips'>Não recebeu um código?`,
        no_code_text_1: `1. Verifique se o endereço de e-mail é válido e pode receber e-mails.`,
        no_code_text_2: `2. Para o e-mail ser enviado pelo sistema automaticamente, ele pode ser sinalizado como spam ou lixo eletrônico. Verifique se o e-mail está na pasta Lixeira.`,
        no_code_text_3: `3. Não consegue resolver seu problema?`,
        no_code_text_3_span: `Então clique aqui para entrar em contato conosco.`,
        order_no: `Você não comprou nenhum produto, <a href="https://pt.tipard.com/store.html">compre agora</a>! Se você tiver alguma dúvida, <a href="https://pt.tipard.com/contact.html">entre em contato conosco</a>.`,
        error_24901: `A conta atual não tem e-mail vinculado e não consegue encontrar os pedidos. Por favor, conecte um e-mail.`,
        user_guide: `Guia do usuário>>`,
        download: `Download`,
        order_number: `Número do pedido:`,
        Refund: `Reembolso`,
        Disabled: `Desativado`,
        Normal: `Normal`,
        Modify: `Modificar`,
        Modify_1: `Modificar>>`,
        Connect: `Conectar`,
        unlink_success: `Desvincular com sucesso`,
        connect_success: `Conecte-se com sucesso`,
        feedback_title: `Obrigado pelo seu feedback! Por favor, deixe seu problema e nós responderemos a você dentro de 24 horas.`,
        feedback_thank_you: `Obrigado!<br />Sua opinião foi enviada com sucesso.`,
        feedback_email: `Digite seu email aqui!`,
        feedback_content: `Deixe aqui qualquer problema ou sugestão que você encontrou.`,
        feedback_submit: `Enviar`,
        form_contents: `Você não inseriu nenhuma descrição. Insira-o e envie novamente.`,
        old_password: `Por favor, insira a senha antiga`,
        new_password: `Por favor, crie uma nova senha`,
        old_new_password: `A nova senha não pode ser a mesma que a antiga`,
        incorrect_password: `Senha incorreta`,
        delete_no: `Excluir agora`,
        Caps: `Caps Lock está ativado`,
        Get: `Obter`,
        Done: `Feito`,
        error_20001: `Erro: 20001. Faça login novamente.`,
        error_20002: `Erro: 20002. Faça login novamente.`,
        error_20003: `Erro: 20003. Faça login novamente.`,
        error_20004: `Falha na solicitação (código de erro: 20004). Por favor, tente novamente.`,
        error_20005: `A sessão de login expirou (Erro: 20005). Por favor faça login novamente.`,
        error_20006: `Falha na solicitação (código de erro: 20006). Por favor, tente novamente.`,
        error_20007: `A sessão de logon expirou (Erro: 20007). Por favor faça login novamente.`,
        error_20008: `A sessão de login expirou (Erro: 20008). Por favor faça login novamente.`,
        error_20009: `A sessão de login expirou (Erro: 20009). Por favor faça login novamente.`,
        error_20101: `Por favor, insira seu e-mail (código de erro: 20101)`,
        error_20102: `O e-mail não é válido (código de erro: 20102)`,
        error_20103: `Falha na solicitação (código de erro: 20103). Por favor, tente novamente`,
        error_20104: `O e-mail já está em uso, <a href="https://account.tipard.com/login">faça login</a> ou registre-se com um novo`,
        error_20105: `Falha na solicitação (código de erro: 20105). Por favor, tente novamente`,
        error_20106: `Falha ao enviar e-mail, tente novamente`,
        error_20201: `Por favor, insira seu e-mail (código de erro: 20201)`,
        error_20202: `Por favor, insira sua senha (código de erro: 20202)`,
        error_20203: `Insira o código de verificação (código de erro: 20203)`,
        error_20204: `O e-mail não é válido (código de erro: 20204)`,
        error_20205: `É necessária uma senha acima de 8 caracteres (código de erro: 20205)`,
        error_20206: `Falha na solicitação (código de erro: 20206). Por favor, tente novamente`,
        error_20207: `Código de verificação inválido`,
        error_20208: `Falha na solicitação (código de erro: 20208). Por favor, tente novamente`,
        error_20209: `Falha na solicitação (código de erro: 20209). Por favor, tente novamente`,
        error_20301: `Por favor, insira seu e-mail (código de erro: 20301)`,
        error_20302: `Erro: 20302. Entre em contato conosco`,
        error_20303: `O e-mail não é válido (código de erro: 20303)`,
        error_20304: `Falha na solicitação (código de erro: 20304). Por favor, tente novamente`,
        error_20305: `Conta não existe. Digite novamente ou <a href="https://account.tipard.com/register">Crie-o</a> primeiro.`,
        error_20306: `Nenhuma senha ainda. Use <a href="https://account.tipard.com/passwordless-login">Login sem senha</a> ou <a href="create-password">defina uma senha</a> e faça login.`,
        error_20308: `Falha na solicitação (código de erro: 20308). Por favor, tente novamente`,
        error_20401: `Falha ao sair (código de erro: 20401). Por favor, tente novamente`,
        error_20501: `Por favor, insira seu e-mail (código de erro: 20501)`,
        error_20502: `O e-mail não é válido (código de erro: 20502)`,
        error_20503: `Falha na solicitação (código de erro: 20503). Por favor, tente novamente`,
        error_20504: `Falha ao enviar e-mail. Por favor, tente novamente.`,
        error_20601: `Por favor, insira seu e-mail (código de erro: 20601)`,
        error_20602: `Insira o código de verificação (código de erro: 20602)`,
        error_20603: `O e-mail não é válido (código de erro: 20603)`,
        error_20604: `Falha na solicitação (código de erro: 20604). Por favor, tente novamente`,
        error_20606: `Falha na solicitação (código de erro: 20606). Por favor, tente novamente`,
        error_20607: `Falha na solicitação (código de erro: 20607). Por favor, tente novamente`,
        error_20608: `Falha na solicitação (código de erro: 20608). Por favor, tente novamente`,
        error_20701: `Por favor, insira seu e-mail (código de erro: 20701)`,
        error_20702: `O e-mail não é válido (código de erro: 20702)`,
        error_20703: `Falha na solicitação (código de erro: 20703). Por favor, tente novamente`,
        error_20704: `Conta não existe. Digite novamente ou <a href="https://account.tipard.com/register">Crie-o</a> primeiro.`,
        error_20705: `Falha na solicitação (código de erro: 20705). Por favor, tente novamente`,
        error_20706: `Falha ao enviar e-mail. Por favor, tente novamente`,
        error_20801: `Por favor, insira seu e-mail (código de erro: 20801)`,
        error_20802: `Erro: 20802. Entre em contato conosco`,
        error_20803: `Insira o código de verificação (código de erro: 20803)`,
        error_20804: `O e-mail não é válido (código de erro: 20804)`,
        error_20805: `É necessária uma senha acima de 8 caracteres (código de erro: 20805)`,
        error_20806: `Falha na solicitação (código de erro: 20806). Por favor, tente novamente`,
        error_20808: `Falha na solicitação (código de erro: 20808). Por favor, tente novamente`,
        error_20901: `Falha na solicitação (código de erro: 20901). Por favor, tente novamente`,
        error_20902: `Falha na solicitação (código de erro: 20902). Por favor, tente novamente`,
        error_21000: `As alterações são salvas`,
        error_21001: `Nenhuma informação é enviada (código de erro: 21001)`,
        error_21002: `Falha na solicitação (código de erro: 21002). Por favor, tente novamente`,
        error_21101: `Por favor, insira seu e-mail (código de erro: 21101)`,
        error_21102: `O e-mail não é válido (código de erro: 21102)`,
        error_21103: `Falha na solicitação (código de erro: 21103), tente novamente`,
        error_21104: `O e-mail já está conectado, use um novo`,
        error_21105: `Falha na solicitação (código de erro: 21105), tente novamente`,
        error_21106: `Falha ao enviar e-mail. Por favor, tente novamente`,
        error_21201: `Por favor, insira seu e-mail (código de erro: 21201)`,
        error_21202: `Insira o código de verificação (código de erro: 21202)`,
        error_21203: `O e-mail não é válido (código de erro: 21203)`,
        error_21204: `Erro: 21204. Entre em contato conosco`,
        error_21205: `Erro: 21205. Entre em contato conosco`,
        error_21206: `É necessária uma senha acima de 8 caracteres (código de erro: 21206)`,
        error_21207: `Falha na solicitação (código de erro: 21207). Por favor, tente novamente`,
        error_21209: `Falha na solicitação (código de erro: 21209). Por favor, tente novamente`,
        error_21301: `Insira a senha antiga (código de erro: 21301)`,
        error_21302: `Crie uma nova senha (código de erro: 21302)`,
        error_21303: `A nova senha não pode ser a mesma que a antiga. (Erro: 21303)`,
        error_21304: `É necessária uma senha acima de 8 caracteres (código de erro: 21304)`,
        error_21306: `Falha na solicitação (código de erro: 21306). Por favor, tente novamente`,
        error_21402: `Falha na solicitação (código de erro: 21402). Por favor, tente novamente`,
        error_21403: `Falha ao enviar o código de verificação. Por favor, reenvie`,
        error_21500: `A conta foi excluída`,
        error_21501: `Insira o código de verificação (código de erro: 21501)`,
        error_21502: `A sessão de login expirou (Erro: 21502). Por favor faça login novamente.`,
        error_21503: `Falha na solicitação (código de erro: 21503). Por favor, tente novamente`,
        error_21505: `Falha na solicitação (código de erro: 21505), tente novamente`,
        error_21601: `Erro: 20601. Entre em contato conosco`,
        error_21602: `Verificação inválida (erro: 20602). Por favor, tente novamente.`,
        error_21603: `Erro: 20603. Tente novamente`,
        error_21604: `Falha na solicitação (código de erro: 21604). Por favor, tente novamente`,
        error_21606: `Falha na solicitação (código de erro: 21606). Por favor, tente novamente`,
        error_21611: `Falha na solicitação (código de erro: 21611). Por favor, tente novamente`,
        error_21801: `Erro: 21801. Entre em contato conosco`,
        error_21802: `Falha na solicitação (erro: 21802). Por favor, tente novamente`,
        error_21803: `Erro: 21803. Tente novamente`,
        error_21804: `Falha na solicitação (código de erro: 21804). Por favor, tente novamente`,
        error_21806: `Erro: 21806. Tente novamente`,
        error_21807: `Erro: 21807. Entre em contato conosco`,
        error_21808: `Erro: 21808. Entre em contato conosco`,
        error_21809: `Erro: 21809. Entre em contato conosco`,
        error_21810: `Erro: 21810. Entre em contato conosco`,
        error_21811: `Erro: 21811. Entre em contato conosco`,
        error_21812: `Erro: 21812. Entre em contato conosco`,
        error_21813: `Falha na solicitação (código de erro: 21813). Por favor, tente novamente`,
        error_21814: `Erro: 21814. Entre em contato conosco`,
        error_21815: `Falha na solicitação (código de erro: 21815). Por favor, tente novamente`,
        error_21816: `Erro: 21816. Entre em contato conosco`,
        error_21817: `Erro: 21817. Entre em contato conosco`,
        error_21818: `Erro: 21818. Entre em contato conosco`,
        error_21819: `Falha na solicitação (código de erro: 21819). Por favor, tente novamente`,
        error_21820: `Erro: 21820. Entre em contato conosco`,
        error_21821: `Erro: 21821. Entre em contato conosco`,
        error_21822: `Erro: 21822. Entre em contato conosco`,
        error_21823: `Falha na solicitação (código de erro: 21823). Por favor, tente novamente`,
        error_21824: `Falha na solicitação (código de erro: 21824). Por favor, tente novamente`,
        error_21825: `Falha na solicitação (código de erro: 21825). Por favor, tente novamente`,
        error_21826: `Falha na solicitação (código de erro: 21826). Por favor, tente novamente`,
        error_21901: `Erro: 21901. Entre em contato conosco`,
        error_21902: `Falha na solicitação (código de erro: 21902). Por favor, tente novamente`,
        error_21903: `O status da conta mudou (código de erro: 21903), atualize a página e tente novamente`,
        error_21904: `Erro: 21904. Tente novamente`,
        error_21905: `Erro: 21905. Tente novamente`,
        error_21906: `Falha na solicitação (código de erro: 21906). Por favor, tente novamente`,
        error_21907: `A conta do Google foi vinculada a outra conta`,
        error_21908: `Falha na solicitação (código de erro: 21908). Por favor, tente novamente`,
        error_22001: `Falha na solicitação (código de erro: 22001). Por favor, tente novamente`,
        error_22002: `Falha ao desvincular sem login adicional`,
        error_22003: `Falha na solicitação (código de erro: 22003). Por favor, tente novamente`,
        error_22101: `Erro: 22101. Entre em contato conosco`,
        error_22102: `O status da conta mudou (código de erro: 22102), atualize a página e tente novamente`,
        error_22103: `Falha na solicitação (código de erro: 22103). Por favor, tente novamente`,
        error_22104: `O status da conta mudou (código de erro: 22104), atualize a página e tente novamente`,
        error_22105: `Erro: 22105. Tente novamente`,
        error_22106: `Erro: 22106. Tente novamente`,
        error_22107: `Erro: 22107. Entre em contato conosco`,
        error_22108: `Falha na solicitação (código de erro: 22108). Por favor, tente novamente`,
        error_22201: `Erro: 22201. Entre em contato conosco`,
        error_22202: `Verificação inválida (Erro: 22202). Por favor, tente novamente.`,
        error_22203: `Erro: 22203. Tente novamente"`,
        error_22204: `Falha na solicitação (código de erro: 22204). Por favor, tente novamente`,
        error_22206: `Falha na solicitação (código de erro: 22206). Por favor, tente novamente`,
        error_22401: `Erro: 22401. Entre em contato conosco`,
        error_22402: `Verificação inválida (Erro: 22402). Por favor, tente novamente.`,
        error_22403: `Erro: 22403. Tente novamente`,
        error_22404: `Falha na solicitação (código de erro: 22404). Por favor, tente novamente`,
        error_22405: `A conta do Facebook foi vinculada a outro e-mail`,
        error_22406: `Erro: 22406. Tente novamente`,
        error_22407: `Erro: 22407. Entre em contato conosco`,
        error_22408: `Erro: 22408. Entre em contato conosco`,
        error_22409: `Erro: 22409. Entre em contato conosco`,
        error_22410: `Erro: 224010. Entre em contato conosco`,
        error_22411: `Erro: 224011. Entre em contato conosco`,
        error_22412: `Erro: 224012. Entre em contato conosco`,
        error_22413: `Erro: 22413. Entre em contato conosco`,
        error_22414: `Falha na solicitação (código de erro: 22414). Por favor, tente novamente`,
        error_22415: `Erro: 22415. Entre em contato conosco`,
        error_22416: `Erro: 22416. Entre em contato conosco`,
        error_22417: `Erro: 22417. Entre em contato conosco`,
        error_22418: `Falha na solicitação (código de erro: 22418). Por favor, tente novamente`,
        error_22419: `Erro: 22419. Entre em contato conosco`,
        error_22420: `Erro: 22420. Entre em contato conosco`,
        error_22421: `Erro: 22421. Entre em contato conosco`,
        error_22422: `Falha na solicitação (código de erro: 22422). Por favor, tente novamente`,
        error_22423: `Falha na solicitação (código de erro: 22423). Por favor, tente novamente`,
        error_22424: `Falha na solicitação (código de erro: 22424). Por favor, tente novamente`,
        error_22425: `Falha na solicitação (código de erro: 22425). Por favor, tente novamente`,
        error_20098: `Código de erro: 20098. Se você estiver navegando no modo Privado, alterne para o modo Normal e tente novamente.`,
        error_22298: `Falha na solicitação do Google (erro: 22298). Por favor, tente novamente.`,
        error_22498: `Falha na solicitação do Facebook (erro: 22498). Por favor, tente novamente.`,
        error_24902: `Falha na solicitação (código de erro: 24902). Por favor, tente novamente`,
        error_24903: `Falha na solicitação (código de erro: 24903). Por favor, tente novamente`,
        error_24904: `Falha na solicitação (código de erro: 24904). Por favor, tente novamente`,
        error_24905: `Falha na solicitação (código de erro: 24905). Por favor, tente novamente`,
        login_title: `Faça login no Tipard`,
        password_placeholder: `Senha`,
        log_in: `Conecte-se`,
        no_account: `Sem conta?`,
        create_it: `Crie`,
        or_log_in_with: `Ou faça login com`,
        passwordless_login: `Login sem senha`,
        log_in_done: `Login concluído`,
        three_rd_account_connect_info: `Parabéns! Você fez login com sucesso. Agora você pode conectar uma conta de e-mail que será usada para fazer login no futuro.`,
        see_my_account: `Ver minha conta`,
        three_rd_login_merge_account: `O endereço de e-mail da conta de terceiros foi cadastrado, você deseja se conectar e fazer login com este endereço de e-mail diretamente?`,
        connect_log_in: `Conecte-se e faça login`,
        create_an_account: `Crie a sua conta aqui`,
        back_to_log_in: `Volte ao login`,
        create_password: `Criar senha`,
        create_now: `Crie agora`,
        password_login_subtitle: `Login sem senha com e-mail`,
        account_login: `Login da conta`,
        rights: `Ao criar esta conta, você concorda com os <a href="https://pt.tipard.com/terms.html">Termos de Serviço</a> e  <a href="https://pt.tipard.com/policy.html">Política de Privacidade</a>`,
        passwordless_login_done: `Login sem senha concluído`,
        passwordless_login_info: `Parabéns, você concluiu o login sem senha com sucesso. Você pode criar uma senha para esta conta e fazer login com a conta e a senha no futuro. <a href="/create-password" style="display: initial;">Criar agora</a>`,
        sign_up: `Inscrever-se`,
        register_info: `Crie sua conta`,
        reset_now: `Redefinir agora`,
        forgot_password: `Esqueceu sua senha`,
        reset_password_subtitle: `Use o e-mail da sua conta para redefinir a senha`,
        plan_products: `Planos e produtos`,
        nick_name: `Nome do usuário:`,
        email: `E-mail:`,
        my_products: `Meus produtos`,
        my_orders: `Minhas ordens`,
        unlink: `Desvincular`,
        link: `Link`,
        connected_accounts: `Contas conectadas`,
        last_name: `Último nome:`,
        first_name: `Primeiro nome:`,
        Gender: `Gênero:`,
        Birth: `Aniversário:`,
        Month: `Mês`,
        Year: `Ano`,
        Country_Region: `País/Região:`,
        Address: `Endereço:`,
        Save: `Salve`,
        Date: `Encontro`,
        Male: `Macho`,
        Female: `Fêmea`,
        Unspecified: `Não especificado`,
        Security: `Segurança`,
        change_password: `Mudar senha`,
        change_now: `Mude agora`,
        connect_email: `Conectar e-mail`,
        delete_account: `Deletar conta`,
        delete_account_info: `Quando sua conta for excluída, todos os dados do Plano e Produtos associados à sua conta serão excluídos permanentemente e talvez você não consiga recuperá-los. Recomendamos que você pise com cautela.`,
        Delete: `Excluir`,
        Logout: `Sair`,
        my_profile: `Meu perfil`,
        guides_faqs: `Guias e perguntas frequentes`,
        More: `Mais`,
        guides: `Guias`,
        register: `registro`,
        hot_faq: `Perguntas frequentes quentes`,
        Contents: `Conteúdo:`,
        contact_us: `Entre em contato>>`,
        plan: `Plano`,
        unregistered: `Não registrado`,
        buy_more: `Comprar mais`,
        buy_again: `Comprar novamente`,
        buy_now: `Compre Agora`,
        free_no_limit: `Gratuito e sem limite`,
        expired: `Expirado`,
        lifetime: `Vida`,
        remain: `Permanecer`,
        day_s: `Dia(s)`,
        day: `Dia`,
        error_24801: `Falha na solicitação (código de erro: 24801). Por favor, tente novamente`,
        no_app_found: `Nenhum aplicativo encontrado！<a href="/">Atualize</a> ou <a href="https://pt.tipard.com/">Acesse o site oficial</a>`,
        get_more: `Obter mais >>`,
        edit_photo: `Editar foto`,
        select_photo: `Selecionar foto`,
        change_photo: `Mudar foto`,
        cancel: `Cancelar`,
        hide_password: `Esconder a senha`,
        show_password: `Mostrar senha`,
        zoom_in: `Mais Zoom`,
        zoom_out: `Reduzir o zoom`,
        rotate: `Girar`,
        horizontal_flip: `Inversão horizontal`,
        vertical_flip: `Inversão vertical`,
        country: `País`,
        country_1: `Selecione seu país/região`,
        country_2: `Ilha de Åaland`,
        country_3: `Afeganistão`,
        country_4: `Albânia`,
        country_5: `Argélia`,
        country_6: `Samoa Americana`,
        country_7: `Andorra`,
        country_8: `Angola`,
        country_9: `Anguila`,
        country_10: `Antártica`,
        country_11: `Antígua e Barbuda`,
        country_12: `Argentina`,
        country_13: `Armênia`,
        country_14: `Aruba`,
        country_15: `Austrália`,
        country_16: `Áustria`,
        country_17: `Azerbaijão`,
        country_18: `Bahrein`,
        country_19: `Bangladesh`,
        country_20: `Barbados`,
        country_21: `Bielorrússia`,
        country_22: `Bélgica`,
        country_23: `Belize`,
        country_24: `Benim`,
        country_25: `Bermudas`,
        country_26: `Butão`,
        country_27: `Bolívia`,
        country_28: `Bósnia e Herzegovina`,
        country_29: `Botsuana`,
        country_30: `Ilha Bouvet`,
        country_31: `Brasil`,
        country_32: `Território oceânico indiano britânico`,
        country_33: `Ilhas Virgens Britânicas`,
        country_34: `Brunei`,
        country_35: `Bulgária`,
        country_36: `Burquina`,
        country_37: `Burundi`,
        country_38: `Camboja`,
        country_39: `Camarões`,
        country_40: `Canadá`,
        country_41: `Cabo Verde`,
        country_42: `Caribe Holanda`,
        country_43: `Ilhas Cayman`,
        country_44: `República Africana Central`,
        country_45: `Chade`,
        country_46: `Chile`,
        country_47: `China`,
        country_48: `Ilha do Natal`,
        country_49: `Ilhas de Cocos (Keeling)`,
        country_50: `Colômbia`,
        country_51: `Ilhas Cook`,
        country_52: `Costa Rica`,
        country_53: `Costa do Marfim`,
        country_54: `Croácia`,
        country_55: `Cuba`,
        country_56: `Chipre`,
        country_57: `República Checa`,
        country_58: `República Democrática do Congo`,
        country_59: `Dinamarca`,
        country_60: `Djibuti`,
        country_61: `Dominica`,
        country_62: `República Dominicana`,
        country_63: `Equador`,
        country_64: `Egito`,
        country_65: `El Salvador`,
        country_66: `Guiné Equatorial`,
        country_67: `Eritreia`,
        country_68: `Estônia`,
        country_69: `Etiópia`,
        country_70: `Ilhas Malvinas`,
        country_71: `Ilhas Faroe`,
        country_72: `Estados Federados da Micronésia`,
        country_73: `Fiji`,
        country_74: `Finlândia`,
        country_75: `França`,
        country_76: `Guiana Francesa`,
        country_77: `Polinésia Francesa`,
        country_78: `Territórios do Sul da França`,
        country_79: `Gabão`,
        country_80: `Gâmbia`,
        country_81: `Geórgia`,
        country_82: `Alemanha`,
        country_83: `Gana`,
        country_84: `Gibraltar`,
        country_85: `Grã-Bretanha (Reino Unido; Inglaterra)`,
        country_86: `Grécia`,
        country_87: `Groenlândia`,
        country_88: `Granada`,
        country_89: `Guadalupe`,
        country_90: `Guam`,
        country_91: `Guatemala`,
        country_92: `Guernsey`,
        country_93: `Guiné`,
        country_94: `Guiné-Bissau`,
        country_95: `Guiana`,
        country_96: `Haiti`,
        country_97: `Ouvido Ilha e McDonald Ilhas`,
        country_98: `Honduras`,
        country_99: `Hong Kong`,
        country_100: `Hungria`,
        country_101: `Islândia`,
        country_102: `Índia`,
        country_103: `Indonésia`,
        country_104: `Irã`,
        country_105: `Iraque`,
        country_106: `Irlanda`,
        country_107: `Ilha de Man`,
        country_108: `Israel`,
        country_109: `Itália`,
        country_110: `Jamaica`,
        country_111: `Japão`,
        country_112: `Jersey`,
        country_113: `Jordânia`,
        country_114: `Cazaquistão`,
        country_115: `Quênia`,
        country_116: `Kiribati`,
        country_117: `Kuwait`,
        country_118: `Quirguistão`,
        country_119: `Laos`,
        country_120: `Letônia`,
        country_121: `Líbano`,
        country_122: `Lesoto`,
        country_123: `Libéria`,
        country_124: `Líbia`,
        country_125: `Listenstaine`,
        country_126: `Lituânia`,
        country_127: `Luxemburgo`,
        country_128: `Macau`,
        country_129: `Madagáscar`,
        country_130: `Malawi`,
        country_131: `Malásia`,
        country_132: `Maldivas`,
        country_133: `Mali`,
        country_134: `Malta`,
        country_135: `Ilhas Marshall`,
        country_136: `Martinica`,
        country_137: `Mauritânia`,
        country_138: `Maurício`,
        country_139: `Maiote`,
        country_140: `México`,
        country_141: `Moldávia`,
        country_142: `Mônaco`,
        country_143: `Mongólia`,
        country_144: `Montenegro`,
        country_145: `Montserrat`,
        country_146: `Marrocos`,
        country_147: `Moçambique`,
        country_148: `Mianmar (Birmânia)`,
        country_149: `Namíbia`,
        country_150: `Nauru`,
        country_151: `Nepal`,
        country_152: `Holanda`,
        country_153: `Nova Caledônia`,
        country_154: `Nova Zelândia`,
        country_155: `Nicarágua`,
        country_156: `Níger`,
        country_157: `Nigéria`,
        country_158: `Niue`,
        country_159: `Ilha Norfolk`,
        country_160: `Coreia do Norte`,
        country_161: `Ilhas Marianas do Norte`,
        country_162: `Noruega`,
        country_163: `Omã`,
        country_164: `Paquistão`,
        country_165: `Palau`,
        country_166: `Territórios Palestinos`,
        country_167: `Panamá`,
        country_168: `Papua Nova Guiné`,
        country_169: `Paraguai`,
        country_170: `Peru`,
        country_171: `Ilhas Pitcairn`,
        country_172: `Polônia`,
        country_173: `Portugal`,
        country_174: `Porto Rico`,
        country_175: `Catar`,
        country_176: `República da Macedônia (FYROM)`,
        country_177: `República do Congo`,
        country_178: `Reunião`,
        country_179: `Romênia`,
        country_180: `Federação Russa`,
        country_181: `Ruanda`,
        country_182: `São Bartolomeu`,
        country_183: `Saint Martin (França)`,
        country_184: `Saint-Pierre e Miquelon`,
        country_185: `Samoa`,
        country_186: `San Marino`,
        country_187: `São Tomé e Príncipe`,
        country_188: `Arábia Saudita`,
        country_189: `Senegal`,
        country_190: `Sérvia`,
        country_191: `Seicheles`,
        country_192: `Serra Leoa`,
        country_193: `Cingapura`,
        country_194: `Eslováquia`,
        country_195: `Eslovênia`,
        country_196: `Ilhas Salomão`,
        country_197: `Somália`,
        country_198: `África do Sul`,
        country_199: `Sul da Geórgia e a Ilha Sanduíche Sul`,
        country_200: `Coreia do Sul`,
        country_201: `Sudão do Sul`,
        country_202: `Espanha`,
        country_203: `Sri Lanka`,
        country_204: `Santa Helena e Dependências`,
        country_205: `São Cristóvão e Nevis`,
        country_206: `Santa Lúcia`,
        country_207: `São Vicente e Granadinas`,
        country_208: `Sudão`,
        country_209: `Suriname`,
        country_210: `Suazilândia`,
        country_211: `Suécia`,
        country_212: `Suíça`,
        country_213: `Síria`,
        country_214: `Taiwan`,
        country_215: `Tajiquistão`,
        country_216: `Tanzânia`,
        country_217: `Modelo: dados do país SJM Svalbard`,
        country_218: `Tailândia`,
        country_219: `As Bahamas`,
        country_220: `As Comores`,
        country_221: `As Filipinas`,
        country_222: `Timor-Leste (Timor Leste)`,
        country_223: `Ir`,
        country_224: `Toquelau`,
        country_225: `Tonga`,
        country_226: `Trinidad e Tobago`,
        country_227: `Tunísia`,
        country_228: `Peru`,
        country_229: `Turcomenistão`,
        country_230: `Ilhas Turcas e Caicos`,
        country_231: `Tuvalu`,
        country_232: `Uganda`,
        country_233: `Ucrânia`,
        country_234: `Emirados Árabes Unidos`,
        country_235: `Ilhas menores dos Estados Unidos `,
        country_236: `Estados Unidos da América (EUA)`,
        country_237: `Ilhas Virgens dos Estados Unidos`,
        country_238: `Uruguai`,
        country_239: `Uzbequistão`,
        country_240: `Vanuatu`,
        country_241: `Cidade do Vaticano (A Santa Sé)`,
        country_242: `Venezuela`,
        country_243: `Vietnã`,
        country_244: `Wallis e Futuna`,
        country_245: `Saara Ocidental`,
        country_246: `Iémen`,
        country_247: `Zâmbia`,
        country_248: `Zimbábue`,
        google_login: `Faça login no Google`,
        State: `Estado`,
        Activation_code: `Código de ativação`,
        Question: `Liste todos os aplicativos que você fez login`,
        Copy_complete: `Cópia concluída`,
        change_password_success: `Senha alterada com sucesso`,
        successful_login_title: `Sucesso de login`,
        product_page: `Página do produto>>`,
        successful_login_info: `Login concluído. Feche a página atual e volte para a guia original para continuar com o processo a seguir. A página atual será fechada automaticamente em 5 segundos. Se o fechamento automático ou o fechamento ao clicar no botão "Concluído" falhar, feche esta guia diretamente.`,
        successful_login_info_firefox: `Login concluído. Feche a página atual e volte para a guia original para continuar com o processo a seguir.`,
        my_account: `Minha conta`,
        my_history: `Minha história`,
        remove_watermark: `Remover marca d'água`,
        no_history: `Sem histórico`,
        history_all: `Selecionar tudo`,
        history_open: `Aberto`,
        history_down: `Download`,
        history_delete: `Excluir`,
        history_clear: `Limpar inválido`,
        images: `imagem(ns)`,
        use_this_function: `Use este produto>>`,
        hd_downloading: `Benefícios de download de imagens originais em HD:`,
        lifetimeRemaining: `Tempo de vida restante`,
        Remaining: `Remanescente`,
        email_verification: `verificação de e-mail`,
        email_verification_info: `Enviamos a mensagem de verificação para seu e-mail <span class="email"></span> e conclua a verificação. Após a verificação, os benefícios serão sincronizados automaticamente.`,
        wrong_email: `Endereço de email errado?`,
        click_here_to_modify: `Clique aqui para modificar`,
        get_more_help: `Obter mais ajuda?`,
        click_here: `Clique aqui`,
        email_verification_info_success: `Parabéns por verificar sua conta de e-mail.`,
        email_verification_info_error: `A verificação falhou devido ao link expirado.`,
        registration_succeeded: `Registro bem-sucedido`,
        registration_succeeded_info_1: `Parabéns! Você se registrou com sucesso. Enviamos a mensagem de verificação para seu e-mail <span class="email"></span> e conclua a verificação para usar os benefícios deste e-mail.`,
        registration_succeeded_info_2: `Clique em "Concluído" para retornar à página inicial e usar este produto.`,
        registration_succeeded_info_3: `Clique em "Concluído" para fechar a página atual e retornar à página inicial para as seguintes operações. Se o fechamento falhar, feche a guia manualmente.`,
        verify_email: `Verificar e-mail`,
        registered_email_not_verified: `O e-mail registrado não foi verificado, verifique-o imediatamente.`,
        email_verification_time_1: `Não recebeu um e-mail de verificação?`,
        email_verification_time_2: `Depois`,
        email_verification_time_3: `Clique aqui para reenviar`,
        error_26301: `Código de erro: 26301, entre em contato conosco`,
        error_26302: `Código de erro: 26302, entre em contato conosco`,
        error_26303: `Erro de formato de e-mail (código de erro: 26303). Por favor, insira-o novamente`,
        error_26304: `Senha acima de 8 caracteres é recomendada (código de erro: 26304)`,
        error_26305: `Reuqest falhou (código de erro: 26305). Por favor, tente novamente`,
        error_26306: `O e-mail foi registrado, <a href="https://account.tipard.com/login">vá para fazer login</a>`,
        error_26307: `Reuqest falhou (código de erro: 26307). Por favor, tente novamente`,
        error_26308: `Reuqest falhou (código de erro: 26308). Por favor, tente novamente`,
        error_26401: `Código de erro: 26401, tente novamente`,
        error_26402: `O e-mail foi verificado (código de erro: 26402), tente novamente`,
        error_26403: `Reuqest falhou (código de erro: 26403). Por favor, tente novamente`,
        error_26404: `Falha na solicitação (código de erro: 26404). Por favor, tente novamente`,
        error_26501: `Código de erro: 26501, entre em contato conosco`,
        error_26502: `Código de erro: 26502, entre em contato conosco`,
        error_26503: `Erro de formato de e-mail (código de erro: 26503). Por favor, insira-o novamente`,
        error_26504: `Reuqest falhou (código de erro: 26504). Por favor, tente novamente`,
        error_26505: `O e-mail não foi registrado, <a href="https://account.tipard.com/register">registre-o primeiro</a>`,
        error_26506: `O e-mail foi verificado.`,
        error_26507: `Reuqest falhou (código de erro: 26507). Por favor, tente novamente`,
        error_26508: `Falha na verificação (código de erro: 26508), tente novamente`,
        error_26509: `Falha na solicitação (código de erro: 26509), tente novamente`,
        error_26510: `Código de erro: 26510, entre em contato conosco`,
        error_26601: `Código de erro: 26601, entre em contato conosco`,
        error_26602: `Código de erro: 26602, entre em contato conosco`,
        error_26603: `Reuqest falhou (código de erro: 26603). Por favor, tente novamente`,
        error_26604: `Código de erro: 26604, entre em contato conosco`,
        error_26605: `Código de erro: 26605, entre em contato conosco`,
        error_26701: `Código de erro: 26701, entre em contato conosco`,
        error_26702: `Reuqest falhou (código de erro: 26702). Por favor, tente novamente`,
        error_26703: `Código de erro: 26703, entre em contato conosco`,
        error_26704: `Código de erro: 26704, entre em contato conosco`,
        error_26705: `Aguarde o login (código de erro: 26705). Por favor, tente novamente`,
        no_cookie: `Você ativou a função Bloquear todos os cookies em seu navegador, portanto não pode fazer login. Vá para Configurações para marcar a caixa Permitir todos os cookies.`,
        error_26801: `Código de erro: 26801, entre em contato conosco`,
        error_26802: `Código de erro: 26802, entre em contato conosco`,
        error_26803: `Falha na solicitação (código de erro: 26803). Por favor, tente novamente`,
        error_26804: `Falha na solicitação (código de erro: 26804). Por favor, tente novamente`,
        error_order: `Falha na solicitação (código de erro: 27098), tente novamente!`,
        error_order1: `A consulta do pedido está incompleta（código de erro:`,
        error_order2: `）, atualize e tente novamente.`,
        modify_email_title: `Modificar e-mail`,
        modify_email_info: `Você pode usar o e-mail modificado para fazer login em sua conta.`,
        images_per: `Imagens por`,
        error_26101: `Erro: 26101. Entre em contato conosco`,
        error_26102: `Erro: 26102. Entre em contato conosco`,
        error_26103: `Falha na solicitação (código de erro: 26103). Por favor, tente novamente`,
        error_26104: `Código de erro: 26104, tente novamente`,
        error_26105: `Código de erro: 26105, tente novamente`,
        error_26106: `Falha na exclusão (código de erro: 26106). Por favor, tente novamente`,
        error_26201: `Erro: 26201. Entre em contato conosco`,
        error_26202: `Falha na solicitação (código de erro: 26202). Por favor, tente novamente`,
        error_26001: `Erro: 26001. Entre em contato conosco`,
        error_26002: `Erro: 26002. Entre em contato conosco`,
        error_26003: `Erro: 26003. Entre em contato conosco`,
        error_26004: `Erro: 26004. Entre em contato conosco`,
        error_26005: `Falha na solicitação (código de erro: 26005). Por favor, tente novamente`,
        error_26006: `Código de erro: 26006, tente novamente`,
        error_26008: `Erro: 26008. Entre em contato conosco`,
        go_to_the_home_page: `Vá para a página inicial`,
        error_27101: `Falha na solicitação (código de erro: 27101). Por favor, tente novamente`,
        error_27201: `Código de erro: 27201, entre em contato conosco`,
        error_27202: `Código de erro: 27202, tente novamente`,
        error_27203: `Falha na solicitação (código de erro: 27203). Por favor, tente novamente`,
        error_27204: `Código inválido (código de erro: 27204).`,
        error_27205: `Falha na solicitação (código de erro: 27205). Por favor, tente novamente`,
        error_27206: `Falha na solicitação (código de erro: 27206). Por favor, tente novamente`,
        error_27207: `Falha na solicitação (código de erro: 27207). Por favor, tente novamente`,
        no_history_found: `Você não usou nenhuma ferramenta! <a href="/">Atualize</a> ou <a href="https://pt.tipard.com/">Acesse o site oficial</a>`,
        error_25301: `Ошибка: 25301. Свяжитесь с usisTSArrayType.`,
        error_25302: `Ошибка: 25302. Пожалуйста, свяжитесь с usisTSArrayType`,
        error_25303: `Запрос не выполнен (код ошибки: 25303). Пожалуйста, попробуйте еще разisTSArrayType`,
        error_25304: `Запрос не выполнен (код ошибки: 25304). Пожалуйста, попробуйте еще разisTSArrayType`,
        error_25305: `Запрос не выполнен (код ошибки: 25305). Пожалуйста, попробуйте еще разisTSArrayType`,
        error_25306: `Запрос не выполнен (код ошибки: 25306). Пожалуйста, попробуйте еще разisTSArrayType`,
        image_upscaler_p: `Benefícios de download de imagem sem marca d'água:`,
        Available_for: `Disponível para:`,
        credit_per: `%s crédito(s) por imagem HD`,
        still_valid: `O(s) plano(s) adquirido(s) ainda é válido(s)`,
        credit: `crédito(s)`,
        pc_3rd_info: `Login com sucesso. Por favor, acesse o aplicativo para operações adicionais.`,
        use_online: `Utilize o serviço on-line`,
        use_download: `Use o programa de desktop`,
        use_immediately: `Use imediatamente`,
        Use_in_browser: `Usar no navegador`,
        win_desktop: `janelas`,
        Mac_desktop: `Mac`,
        credits_per: `{%} créditos por mês`,
        expire: `Data de validade:`,
        viewDetails: `Ver detalhes`,
        viewHistory: `Veja os benefícios do histórico >>`,
        viewDetailsInfo: `Aviso: Se os benefícios da assinatura forem renovados dentro de 7 dias após o vencimento, os benefícios não utilizados poderão continuar a ser usados. Além disso, o prazo de validade será atualizado automaticamente para o prazo de validade da nova assinatura. Se não houver nenhuma nova assinatura após 7 dias do vencimento, todos os benefícios expirados serão cancelados.`,
        "connect_account": "Vincule e-mail à sua conta",
        "connect_account_info": "Após a vinculação, você pode fazer login com este endereço de e-mail.",
        "connect_now": "Vincular agora",
        "no_email_bind": "Sem vinculação de e-mail",
        "bind_email": "Vincular e-mail",
        "connect_your_email_placeholder": "Por favor, indique o seu endereço de e-mail",
        "bind_an_email": "Vincular um e-mail",
        "bind_info": "Você fez login com sucesso. Vincule um e-mail à sua conta para ativar seus benefícios.",
        "bind_later": "Vincular mais tarde",
        "footer": "Direitos autorais © 2024 Tipard Studio. Todos os direitos reservados.",
        "overtime": "Código de erro: {%}, tente novamente",
        "hi": "Dele!",
        "Personal_Information": "Informações pessoais",
        "Access": "Acesso",
        "Subscription_Plan": "(Plano de Assinatura)",
        "No_orders": "Nenhum pedido encontrado.",
        "No_data": "Nenhum dado",
        "Featured_Products": "Produtos em destaque",
        "More_Products": "Mais produtos",
        "Free_Download": "Download grátis",
        "Get_Started": "Começar",
        "Subscribe": "Inscrever-se",
        "Verified": "Verificado",
        "back_to_account_center": "Voltar para o Centro de Contas",
        "success": "Sucesso!",
        "successfully": "Você registrou uma conta com sucesso.",
        "Continue": "Continuar",
        "Already": "Já tem uma conta?",
        "loading_verification": "Verificando o status da verificação...",
        "email_no_verification": "Desculpe, o endereço de e-mail registrado não foi verificado. Por favor, conclua a verificação de acordo com as instruções acima e clique no botão \"Concluído\" novamente para concluir o registro.",
        "error_20505": "O e-mail foi vinculado",
        "error_20506": "Erro: 20506. Entre em contato conosco",
        "error_28401": "Por favor insira seu e-mail",
        "error_28402": "Formato de e-mail incorreto",
        "error_28403": "Erro: 28403. Entre em contato conosco",
        "error_28404": "O e-mail foi registrado",
        "error_28405": "Erro: 28405. Entre em contato conosco",
        "error_28406": "O e-mail foi vinculado",
        "error_28407": "Erro: 28407. Entre em contato conosco",
        "error_28408": "Falha ao enviar o e-mail",
        "error_28601": "Por favor insira seu e-mail",
        "error_28602": "Por favor, insira o código de verificação",
        "error_28603": "Formato de e-mail incorreto",
        "error_28604": "Erro: 28604. Entre em contato conosco",
        "error_28605": "O código de verificação está incorreto",
        "error_28606": "Erro: 28606. Entre em contato conosco",
        "error_28607": "O e-mail foi registrado",
        "error_28608": "Não há e-mail vinculado à sua conta",
        "error_28609": "O e-mail não foi verificado",
        "error_28610": "Erro: 28610. Entre em contato conosco",
        "error_28611": "O e-mail foi vinculado",
        "error_28612": "Erro: 28612. Entre em contato conosco",
        "unbind_email_btn": "Desvincular o e-mail",
        "binding_exchange": "Vincular e-mail",
        "Subscription_Status": "Status da assinatura",
        "mask_unbind_info_1": "Tem certeza de que deseja desvincular {%} desta conta? Após desvincular, você não poderá mais verificar os benefícios deste e-mail",
        "mask_unbind_info_2": "Tem certeza de que deseja desvincular todos os PCs associados a este código de registro?<a href=\"https://pt.tipard.com/faq.html#unbind\">Saiba mais sobre como alterar dispositivos.</a>",
        "error_dc_link": "Erro, entre em contato conosco",
        "vcu_state": "Benefícios da versão completa:",
        "monthly_subscription": "Licença de 1 mês",
        "year_subscription": "Licença de 1 ano",
        "to": "para",
        "lifetime_subscription": "Licença vitalícia",
        "unbind": "Confirmar",
        "unbind_success": "Desvinculado com sucesso",
        "error_28401": "Por favor insira seu e-mail",
        "error_28702": "Erro: 28702. Entre em contato conosco",
        "error_28703": "O código de verificação está incorreto",
        "error_28704": "Erro: 28704. Entre em contato conosco",
        "error_28705": "O e-mail não foi vinculado",
        "error_28706": "Por favor, insira o código de verificação",
        "error_28707": "Falha ao desvincular",
        "error_28901": "Erro: 28901. Entre em contato conosco",
        "error_28902": "Erro: 28902. Entre em contato conosco",
        "error_28903": "Erro: 28903. Entre em contato conosco",
        "error_28904": "Erro: 28904. Entre em contato conosco",
        "error_28905": "Erro: 28905. Entre em contato conosco",
        "error_28906": "Erro: 28906. Entre em contato conosco",
        "error_28907": "Código de registro inválido",
        "error_28908": "Erro: 28908. Entre em contato conosco",
        "error_28909": "Atualmente no período de resfriamento de desvinculação. Você só pode desvincular uma vez por mês.",
        "error_289010": "Erro: 289010. Entre em contato conosco",
        "error_289011": "Erro: 289011. Entre em contato conosco",
        "error_289012": "Erro: 289012. Entre em contato conosco",
        "activated_computer": "Ativado em %s/%s computador(es)",
        "bind_email_info": "Os benefícios dos seguintes e-mails serão vinculados e usados nesta conta VIP.",
        "error_26309": "Erro: 26309. Entre em contato conosco",
        "error_26310": "Código de referência inválido",
        "error_26311": "Erro: 26311. Entre em contato conosco",
        "error_26312": "Código de referência inválido",
        "error_26313": "Erro: 26313. Entre em contato conosco",
        "error_26314": "O e-mail foi vinculado",
        "activate_unbinding": "Alterar dispositivo",
        "copy_registration_code": "Copiar código de registro",
        "show_registration_code": "Mostrar código de registro",
        "hide_registration_code": "Ocultar código de registro",
        "other_binding_email": "Outros e-mails vinculativos",
        "home": "Lar",
        "points_balance": "Saldo de pontos:",
        "earn_now": "Ganhe agora >>",
        "earn_more": "Ganhe mais pontos GRÁTIS, resgate mais presentes",
        "earn_or_redeem_points": "Ganhe/Resgate pontos",
        "unbind_email_info": "A conta corrente foi associada a um endereço de e-mail",
        "bind_email_title": "Se você quiser associar mais pedidos para comprar endereços de e-mail, clique no botão \"Associação de e-mail\" abaixo para associar.",
        "bind_email_tip": "<span>Dica:</span> Atualmente, somente compras de pedidos com código de registro de e-mail são suportadas.",
        "email_association": "Associação de e-mail",
        "dissociation": "Dissociação",
        "back": "Voltar",
        "activate_benefits": "Insira o e-mail de compra que precisa ser associado para obter o código de verificação. Insira o código de verificação recebido no e-mail na caixa de entrada do código de verificação e clique no botão \"Verificar ativação\" para ativar os benefícios.",
        "e_mail": "E-mail:",
        "verification_code": "Código de verificação:",
        "obtain_verification_code": "Obter código de verificação",
        "verification_association": "Verificar associação",
        "current_password": "Senha atual:",
        "account_center": "Central de contas",
        "new_password": "Nova Senha:",
        "confirm_password": "Confirme a nova senha:",
        "Send": "Enviar",
        "history_files": "Arquivos de Histórico",
        "delete_account_info_1": "1. Excluir sua conta Tipard é uma ação permanente e não pode ser desfeita. Todos os seus dados, incluindo fotos e preferências, serão perdidos. Tem certeza de que deseja excluir sua conta Tipard?",
        "delete_account_info_2": "2. Se você decidir excluir a conta Tipard, clique no botão Enviar e insira o código de verificação do e-mail que lhe enviamos.",
        "best_deals": "Melhores ofertas",
        "close": "Fechar",
        "product":"Ürün:"
    },
    es: {
        overtime_1: `Código de error:`,
        overtime_2: `, por favor vuelve a intentarlo`,
        isnetwork: `Error de Internet. Por favor verifique y vuelva a intentarlo`,
        email_placeholder: `Correo electrónico`,
        email_empty: `Por favor ingrese el correo electrónico`,
        email_not_valid: `El correo no es válido`,
        email_not_valid_1: `Por favor ingrese su correo electrónico`,
        email_not_valid_2: `El correo electrónico no es válido, utilice una dirección diferente.`,
        email_not_valid_3: `Sin entrada de correo electrónico`,
        password_placeholder: `Clave`,
        password_empty: `Por favor ingrese la contraseña`,
        password_not_valid: `Cuenta o contraseña incorrecta`,
        password_not_valid_1: `Se requiere una contraseña de más de 8 caracteres`,
        password_not_valid_2: `Por favor crea una contraseña`,
        password_placeholder_1: `Crea tu contraseña`,
        password_placeholder_2: `Confirmar la contraseña`,
        password_placeholder_3: `Crear nueva contraseña`,
        password_placeholder_4: `Confirmar nueva contraseña`,
        password_placeholder_5: `Ingrese la contraseña anterior`,
        copy_password_empty: `Por favor confirme la contraseña`,
        copy_password_not_valid: `Por favor, confirme su contraseña`,
        copy_passwords_inconsistent: `Su confirmación de contraseña no coincide`,
        code_empty: `Por favor ingrese el código de verificación`,
        code_not_valid: `código de verificación invalido`,
        code_placeholder: `Código de verificación`,
        not_received_code: `Si su buzón no ha recibido el código de verificación durante mucho tiempo, obtenga el código de verificación nuevamente.`,
        get_first_code: `Primero obtenga el código de verificación.`,
        last_name_placeholder: `Por favor ingrese su apellido`,
        first_name_placeholder: `Por favor, introduzca su nombre de pila`,
        address_placeholder: `Por favor ingrese su dirección`,
        no_code_text: `Hemos enviado un código de verificación. Por favor, introduzca su código. <span class='tips'>¿No recibió un código?`,
        no_code_text_1: `1. Asegúrese de que la dirección de correo electrónico sea válida y pueda recibir correos electrónicos.`,
        no_code_text_2: `2. Para que el sistema envíe el correo electrónico automáticamente, se puede marcar como spam o correo no deseado. Compruebe si el correo electrónico está en la carpeta Papelera.`,
        no_code_text_3: `3. ¿No puede resolver su problema?`,
        no_code_text_3_span: `Entonces haz clic aquí para contactarnos.`,
        order_no: `¡No has comprado ningún producto, <a href="https://es.tipard.com/store.html">compra ahora</a>! Si tiene alguna pregunta, <a href="https://es.tipard.com/contact.html">contáctenos</a>.`,
        error_24901: `La cuenta actual no tiene un correo electrónico vinculado y no puede encontrar los pedidos. Por favor conecte un correo electrónico.`,
        user_guide: `Guía del usuario>>`,
        download: `Descargar`,
        order_number: `Número de orden:`,
        Refund: `Reembolso`,
        Disabled: `Desactivado`,
        Normal: `Normal`,
        Modify: `Modificar`,
        Modify_1: `Modificar>>`,
        Connect: `Conectar`,
        unlink_success: `Desvincular con éxito`,
        connect_success: `Conectar con éxito`,
        feedback_title: `Gracias por tus comentarios. Deje su problema y le responderemos dentro de las 24 horas.`,
        feedback_thank_you: `¡Gracias!<br />Sus comentarios se han enviado correctamente.`,
        feedback_email: `¡Introduzca su correo electrónico aquí!`,
        feedback_content: `Deje cualquier problema o sugerencia que haya encontrado aquí.`,
        feedback_submit: `Entregar`,
        form_contents: `No has introducido ninguna descripción. Por favor, introdúzcalo y envíelo de nuevo.`,
        old_password: `Por favor ingrese la contraseña anterior`,
        new_password: `Por favor crea una nueva contraseña`,
        old_new_password: `La nueva contraseña no puede ser la misma que la anterior`,
        incorrect_password: `Contraseña incorrecta`,
        delete_no: `Elimina ahora`,
        Caps: `Bloqueo de mayúsculas está activado`,
        Get: `Conseguir`,
        Done: `Hecho`,
        error_20001: `Error: 20001. Vuelva a iniciar sesión.`,
        error_20002: `Error: 20002. Vuelva a iniciar sesión.`,
        error_20003: `Error: 20003. Vuelva a iniciar sesión.`,
        error_20004: `Solicitud fallida (código de error: 20004). Inténtalo de nuevo.`,
        error_20005: `La sesión de inicio de sesión ha caducado (Error: 20005). Inicie sesión de nuevo.`,
        error_20006: `Solicitud fallida (código de error: 20006). Inténtalo de nuevo.`,
        error_20007: `La sesión de inicio de sesión ha caducado (Error: 20007). Inicie sesión de nuevo.`,
        error_20008: `La sesión de inicio de sesión ha caducado (Error: 20008). Inicie sesión de nuevo.`,
        error_20009: `La sesión de inicio de sesión ha caducado (Error: 20009). Inicie sesión de nuevo.`,
        error_20101: `Ingrese su correo electrónico (código de error: 20101)`,
        error_20102: `El correo electrónico no es válido (código de error: 20102)`,
        error_20103: `Solicitud fallida (código de error: 20103). Inténtalo de nuevo`,
        error_20104: `El correo electrónico ya está en uso, <a href="https://account.tipard.com/login">iniciar sesión</a> o registrarse con uno nuevo`,
        error_20105: `Solicitud fallida (código de error: 20105). Inténtalo de nuevo`,
        error_20106: `No se pudo enviar el correo electrónico, por favor vuelva a intentarlo`,
        error_20201: `Ingrese su correo electrónico (código de error: 20201)`,
        error_20202: `Ingrese su contraseña (código de error: 20202)`,
        error_20203: `Ingrese el código de verificación (código de error: 20203)`,
        error_20204: `El correo electrónico no es válido (código de error: 20204)`,
        error_20205: `Se requiere una contraseña de más de 8 caracteres (código de error: 20205)`,
        error_20206: `Solicitud fallida (código de error: 20206). Inténtalo de nuevo`,
        error_20207: `Código de verificación invalido`,
        error_20208: `Solicitud fallida (código de error: 20208). Inténtalo de nuevo`,
        error_20209: `Solicitud fallida (código de error: 20209). Inténtalo de nuevo`,
        error_20301: `Ingrese su correo electrónico (código de error: 20301)`,
        error_20302: `Error: 20302. Por favor contáctenos`,
        error_20303: `El correo electrónico no es válido (código de error: 20303)`,
        error_20304: `Solicitud fallida (código de error: 20304). Inténtalo de nuevo`,
        error_20305: `La cuenta no existe. Vuelva a ingresar o <a href="https://account.tipard.com/register">Créelo</a> primero.`,
        error_20306: `Sin contraseña todavía. Use <a href="https://account.tipard.com/passwordless-login">Inicio de sesión sin contraseña</a> o <a href="create-password">establezca una contraseña</a> e inicie sesión.`,
        error_20308: `Solicitud fallida (código de error: 20308). Inténtalo de nuevo`,
        error_20401: `Error al cerrar sesión (código de error: 20401). Inténtalo de nuevo`,
        error_20501: `Ingrese su correo electrónico (código de error: 20501)`,
        error_20502: `El correo electrónico no es válido (código de error: 20502)`,
        error_20503: `Solicitud fallida (código de error: 20503). Inténtalo de nuevo`,
        error_20504: `No se pudo enviar el correo electrónico. Inténtalo de nuevo.`,
        error_20601: `Ingrese su correo electrónico (código de error: 20601)`,
        error_20602: `Ingrese el código de verificación (código de error: 20602)`,
        error_20603: `El correo electrónico no es válido (código de error: 20603)`,
        error_20604: `Solicitud fallida (código de error: 20604). Inténtalo de nuevo`,
        error_20606: `Solicitud fallida (código de error: 20606). Inténtalo de nuevo`,
        error_20607: `Solicitud fallida (código de error: 20607). Inténtalo de nuevo`,
        error_20608: `Solicitud fallida (código de error: 20608). Inténtalo de nuevo`,
        error_20701: `Ingrese su correo electrónico (código de error: 20701)`,
        error_20702: `El correo electrónico no es válido (código de error: 20702)`,
        error_20703: `Solicitud fallida (código de error: 20703). Inténtalo de nuevo`,
        error_20704: `La cuenta no existe. Vuelva a ingresar o <a href="https://account.tipard.com/register">Créelo</a> primero.`,
        error_20705: `Solicitud fallida (código de error: 20705). Inténtalo de nuevo`,
        error_20706: `No se pudo enviar el correo electrónico. Inténtalo de nuevo`,
        error_20801: `Ingrese su correo electrónico (código de error: 20801)`,
        error_20802: `Error: 20802. Por favor contáctenos`,
        error_20803: `Ingrese el código de verificación (código de error: 20803)`,
        error_20804: `El correo electrónico no es válido (código de error: 20804)`,
        error_20805: `Se requiere una contraseña de más de 8 caracteres (código de error: 20805)`,
        error_20806: `Solicitud fallida (código de error: 20806). Inténtalo de nuevo`,
        error_20808: `Solicitud fallida (código de error: 20808). Inténtalo de nuevo`,
        error_20901: `Solicitud fallida (código de error: 20901). Inténtalo de nuevo`,
        error_20902: `Solicitud fallida (código de error: 20902). Inténtalo de nuevo`,
        error_21000: `Los cambios se guardan`,
        error_21001: `No se envía información (código de error: 21001)`,
        error_21002: `Solicitud fallida (código de error: 21002). Inténtalo de nuevo`,
        error_21101: `Ingrese su correo electrónico (código de error: 21101)`,
        error_21102: `El correo electrónico no es válido (código de error: 21102)`,
        error_21103: `Solicitud fallida (código de error: 21103), por favor vuelva a intentarlo`,
        error_21104: `El correo electrónico ya está conectado, utilice uno nuevo`,
        error_21105: `Solicitud fallida (código de error: 21105), por favor vuelva a intentarlo`,
        error_21106: `No se pudo enviar el correo electrónico. Inténtalo de nuevo`,
        error_21201: `Ingrese su correo electrónico (código de error: 21201)`,
        error_21202: `Ingrese el código de verificación (código de error: 21202)`,
        error_21203: `El correo electrónico no es válido (código de error: 21203)`,
        error_21204: `Error: 21204. Por favor contáctenos`,
        error_21205: `Error: 21205. Por favor contáctenos`,
        error_21206: `Se requiere una contraseña de más de 8 caracteres (código de error: 21206)`,
        error_21207: `Solicitud fallida (código de error: 21207). Inténtalo de nuevo`,
        error_21209: `Solicitud fallida (código de error: 21209). Inténtalo de nuevo`,
        error_21301: `Ingrese la contraseña anterior (código de error: 21301)`,
        error_21302: `Cree una nueva contraseña (código de error: 21302)`,
        error_21303: `La nueva contraseña no puede ser la misma que la anterior. (Error: 21303)`,
        error_21304: `Se requiere una contraseña de más de 8 caracteres (código de error: 21304)`,
        error_21306: `Solicitud fallida (código de error: 21306). Inténtalo de nuevo`,
        error_21402: `Solicitud fallida (código de error: 21402). Inténtalo de nuevo`,
        error_21403: `No se pudo enviar el código de verificación. Por favor reenvialo`,
        error_21500: `La cuenta ha sido eliminada`,
        error_21501: `Ingrese el código de verificación (código de error: 21501)`,
        error_21502: `La sesión de inicio de sesión ha caducado (Error: 21502). Inicie sesión de nuevo.`,
        error_21503: `Solicitud fallida (código de error: 21503). Inténtalo de nuevo`,
        error_21505: `Solicitud fallida (código de error: 21505), por favor vuelva a intentarlo`,
        error_21601: `Error: 20601. Por favor contáctenos`,
        error_21602: `Verificación no válida (Error: 20602). Inténtalo de nuevo.`,
        error_21603: `Error: 20603. Vuelva a intentarlo.`,
        error_21604: `Solicitud fallida (código de error: 21604). Inténtalo de nuevo`,
        error_21606: `Solicitud fallida (código de error: 21606). Inténtalo de nuevo`,
        error_21611: `Solicitud fallida (código de error: 21611). Inténtalo de nuevo`,
        error_21801: `Error: 21801. Por favor contáctenos`,
        error_21802: `Solicitud fallida (Error: 21802). Inténtalo de nuevo`,
        error_21803: `Error: 21803. Vuelva a intentarlo.`,
        error_21804: `Solicitud fallida (código de error: 21804). Inténtalo de nuevo`,
        error_21806: `Error: 21806. Vuelva a intentarlo.`,
        error_21807: `Error: 21807. Por favor contáctenos`,
        error_21808: `Error: 21808. Por favor contáctenos`,
        error_21809: `Error: 21809. Por favor contáctenos`,
        error_21810: `Error: 21810. Por favor contáctenos`,
        error_21811: `Error: 21811. Por favor contáctenos`,
        error_21812: `Error: 21812. Por favor contáctenos`,
        error_21813: `Solicitud fallida (código de error: 21813). Inténtalo de nuevo`,
        error_21814: `Error: 21814. Por favor contáctenos`,
        error_21815: `Solicitud fallida (código de error: 21815). Inténtalo de nuevo`,
        error_21816: `Error: 21816. Por favor contáctenos`,
        error_21817: `Error: 21817. Por favor contáctenos`,
        error_21818: `Error: 21818. Por favor contáctenos`,
        error_21819: `Solicitud fallida (código de error: 21819). Inténtalo de nuevo`,
        error_21820: `Error: 21820. Por favor contáctenos`,
        error_21821: `Error: 21821. Por favor contáctenos`,
        error_21822: `Error: 21822. Por favor contáctenos`,
        error_21823: `Solicitud fallida (código de error: 21823). Inténtalo de nuevo`,
        error_21824: `Solicitud fallida (código de error: 21824). Inténtalo de nuevo`,
        error_21825: `Solicitud fallida (código de error: 21825). Inténtalo de nuevo`,
        error_21826: `Solicitud fallida (código de error: 21826). Inténtalo de nuevo`,
        error_21901: `Error: 21901. Por favor contáctenos`,
        error_21902: `Solicitud fallida (código de error: 21902). Inténtalo de nuevo`,
        error_21903: `El estado de la cuenta ha cambiado (código de error: 21903), actualice la página e intente nuevamente`,
        error_21904: `Error: 21904. Vuelva a intentarlo.`,
        error_21905: `Error: 21905. Vuelva a intentarlo.`,
        error_21906: `Solicitud fallida (código de error: 21906). Inténtalo de nuevo`,
        error_21907: `La cuenta de Google se ha vinculado a otra cuenta`,
        error_21908: `Solicitud fallida (código de error: 21908). Inténtalo de nuevo`,
        error_22001: `Solicitud fallida (código de error: 22001). Inténtalo de nuevo`,
        error_22002: `Error al desvincular sin inicio de sesión adicional`,
        error_22003: `Solicitud fallida (código de error: 22003). Inténtalo de nuevo`,
        error_22101: `Error: 22101. Por favor contáctenos`,
        error_22102: `El estado de la cuenta ha cambiado (código de error: 22102), actualice la página e intente nuevamente`,
        error_22103: `Solicitud fallida (código de error: 22103). Inténtalo de nuevo`,
        error_22104: `El estado de la cuenta ha cambiado (código de error: 22104), actualice la página e intente nuevamente`,
        error_22105: `Error: 22105. Vuelva a intentarlo.`,
        error_22106: `Error: 22106. Vuelva a intentarlo.`,
        error_22107: `Error: 22107. Por favor contáctenos`,
        error_22108: `Solicitud fallida (código de error: 22108). Inténtalo de nuevo`,
        error_22201: `Error: 22201. Por favor contáctenos`,
        error_22202: `Verificación no válida (Error: 22202). Inténtalo de nuevo.`,
        error_22203: `Error: 22203. Vuelve a intentarlo"`,
        error_22204: `Solicitud fallida (código de error: 22204). Inténtalo de nuevo`,
        error_22206: `Solicitud fallida (código de error: 22206). Inténtalo de nuevo`,
        error_22401: `Error: 22401. Por favor contáctenos`,
        error_22402: `Verificación no válida (Error: 22402). Inténtalo de nuevo.`,
        error_22403: `Error: 22403. Vuelva a intentarlo.`,
        error_22404: `Solicitud fallida (código de error: 22404). Inténtalo de nuevo`,
        error_22405: `La cuenta de Facebook se ha vinculado a otro correo electrónico`,
        error_22406: `Error: 22406. Vuelva a intentarlo.`,
        error_22407: `Error: 22407. Por favor contáctenos`,
        error_22408: `Error: 22408. Por favor contáctenos`,
        error_22409: `Error: 22409. Por favor contáctenos`,
        error_22410: `Error: 224010. Por favor contáctenos`,
        error_22411: `Error: 224011. Por favor contáctenos`,
        error_22412: `Error: 224012. Por favor contáctenos`,
        error_22413: `Error: 22413. Por favor contáctenos`,
        error_22414: `Solicitud fallida (código de error: 22414). Inténtalo de nuevo`,
        error_22415: `Error: 22415. Por favor contáctenos`,
        error_22416: `Error: 22416. Por favor contáctenos`,
        error_22417: `Error: 22417. Por favor contáctenos`,
        error_22418: `Solicitud fallida (código de error: 22418). Inténtalo de nuevo`,
        error_22419: `Error: 22419. Por favor contáctenos`,
        error_22420: `Error: 22420. Por favor contáctenos`,
        error_22421: `Error: 22421. Por favor contáctenos`,
        error_22422: `Solicitud fallida (código de error: 22422). Inténtalo de nuevo`,
        error_22423: `Solicitud fallida (código de error: 22423). Inténtalo de nuevo`,
        error_22424: `Solicitud fallida (código de error: 22424). Inténtalo de nuevo`,
        error_22425: `Solicitud fallida (código de error: 22425). Inténtalo de nuevo`,
        error_20098: `Código de error: 20098. Si está navegando en modo Privado, cambie al modo Normal y vuelva a intentarlo.`,
        error_22298: `La solicitud de Google falló (Error: 22298). Inténtalo de nuevo.`,
        error_22498: `La solicitud de Facebook falló (Error: 22498). Inténtalo de nuevo.`,
        error_24902: `Solicitud fallida (código de error: 24902). Inténtalo de nuevo`,
        error_24903: `Solicitud fallida (código de error: 24903). Inténtalo de nuevo`,
        error_24904: `Solicitud fallida (código de error: 24904). Inténtalo de nuevo`,
        error_24905: `Solicitud fallida (código de error: 24905). Inténtalo de nuevo`,
        login_title: `Iniciar sesión en Tipard`,
        password_placeholder: `Clave`,
        log_in: `Iniciar sesión`,
        no_account: `¿Sin cuenta?`,
        create_it: `créalo`,
        or_log_in_with: `O inicia sesión con`,
        passwordless_login: `Inicio de sesión sin contraseña`,
        log_in_done: `Iniciar sesión Listo`,
        three_rd_account_connect_info: `¡Felicidades! Has iniciado sesión correctamente. Ahora puede conectar una cuenta de correo electrónico que se utiliza para iniciar sesión en el futuro.`,
        see_my_account: `Ver mi cuenta`,
        three_rd_login_merge_account: `La dirección de correo electrónico de la cuenta de terceros se ha registrado, ¿quieres conectarte e iniciar sesión directamente con esta dirección de correo electrónico?`,
        connect_log_in: `Conectar e iniciar sesión`,
        create_an_account: `Crea una cuenta`,
        back_to_log_in: `Atrás para iniciar sesión`,
        create_password: `Crear contraseña`,
        create_now: `Crea ahora`,
        password_login_subtitle: `Inicio de sesión sin contraseña con correo electrónico`,
        account_login: `Cuenta de Ingreso`,
        rights: `Al crear esta cuenta, acepta las <a href="https://es.tipard.com/terms.html">Condiciones de servicio</a> y  <a href="https://es.tipard.com/policy.html">Política de privacidad</a>`,
        passwordless_login_done: `Inicio de sesión sin contraseña realizado`,
        passwordless_login_info: `Enhorabuena, ha completado correctamente el inicio de sesión sin contraseña. Puede crear una contraseña para esta cuenta e iniciar sesión con la cuenta y la contraseña en el futuro. <a href="/create-password" style="display: initial;">Crear ahora</a>`,
        sign_up: `Inscribirse`,
        register_info: `Crea tu cuenta`,
        reset_now: `Restablecer ahora`,
        forgot_password: `Has olvidado tu contraseña`,
        reset_password_subtitle: `Use el correo electrónico de su cuenta para restablecer la contraseña`,
        plan_products: `Planes y productos`,
        nick_name: `Nombre de usuario:`,
        email: `Email:`,
        my_products: `Mis productos`,
        my_orders: `Mis ordenes`,
        unlink: `Desconectar`,
        link: `Enlace`,
        connected_accounts: `Cuentas conectadas`,
        last_name: `Apellido:`,
        first_name: `Nombre de pila:`,
        Gender: `Género:`,
        Birth: `Nacimiento:`,
        Month: `Mes`,
        Year: `Año`,
        Country_Region: `País/Región:`,
        Address: `Dirección:`,
        Save: `Guardar`,
        Date: `Fecha`,
        Male: `Masculino`,
        Female: `Femenino`,
        Unspecified: `Sin especificar`,
        Security: `Seguridad`,
        change_password: `Cambia la contraseña`,
        change_now: `Cambia ahora`,
        connect_email: `Conectar correo electrónico`,
        delete_account: `Borrar cuenta`,
        delete_account_info: `Cuando se elimine su cuenta, todos los datos en Plan y productos asociados con su cuenta se eliminarán de forma permanente y es posible que no pueda recuperarlos. Te recomendamos andar con cautela.`,
        Delete: `Borrar`,
        Logout: `Cerrar sesión`,
        my_profile: `Mi perfil`,
        guides_faqs: `Guías y preguntas frecuentes`,
        More: `Más`,
        guides: `Guías`,
        register: `Registrarse`,
        hot_faq: `Preguntas más frecuentes`,
        Contents: `Contenido:`,
        contact_us: `Contáctenos>>`,
        plan: `Plan`,
        unregistered: `No registrado`,
        buy_more: `Compra más`,
        buy_again: `Compra otra vez`,
        buy_now: `Compra ahora`,
        free_no_limit: `Gratis y sin límite`,
        expired: `Venció`,
        lifetime: `Toda la vida`,
        remain: `Permanecer`,
        day_s: `Dia(s)`,
        day: `Dia`,
        error_24801: `Solicitud fallida (código de error: 24801). Inténtalo de nuevo`,
        no_app_found: `No se encontró ninguna aplicación.<a href="/">Actualizar</a> o <a href="https://es.tipard.com/">Ir al sitio web oficial</a>`,
        get_more: `Obtener más >>`,
        edit_photo: `Editar foto`,
        select_photo: `Seleccione Foto`,
        change_photo: `Cambiar foto`,
        cancel: `Cancelar`,
        hide_password: `Contraseña oculta`,
        show_password: `Mostrar contraseña`,
        zoom_in: `Acercarse`,
        zoom_out: `Disminuir el zoom`,
        rotate: `Girar`,
        horizontal_flip: `Volteo horizontal`,
        vertical_flip: `Volteo vertical`,
        country: `País`,
        country_1: `Seleccione su país/región`,
        country_2: `Isla de Åaland`,
        country_3: `Afganistán`,
        country_4: `Albania`,
        country_5: `Argelia`,
        country_6: `Samoa Americana`,
        country_7: `Andorra`,
        country_8: `angola`,
        country_9: `anguila`,
        country_10: `Antártida`,
        country_11: `Antigua y Barbuda`,
        country_12: `Argentina`,
        country_13: `Armenia`,
        country_14: `Aruba`,
        country_15: `Australia`,
        country_16: `Austria`,
        country_17: `Azerbaiyán`,
        country_18: `Baréin`,
        country_19: `bangladesh`,
        country_20: `barbados`,
        country_21: `Bielorrusia`,
        country_22: `Bélgica`,
        country_23: `Belice`,
        country_24: `Benín`,
        country_25: `Bermudas`,
        country_26: `Bután`,
        country_27: `Bolivia`,
        country_28: `Bosnia y Herzegovina`,
        country_29: `Botsuana`,
        country_30: `Isla Bouvet`,
        country_31: `Brasil`,
        country_32: `Territorio Británico del Océano Índico`,
        country_33: `Islas Vírgenes Británicas`,
        country_34: `Brunéi`,
        country_35: `Bulgaria`,
        country_36: `Burkina Faso`,
        country_37: `Burundi`,
        country_38: `Camboya`,
        country_39: `Camerún`,
        country_40: `Canadá`,
        country_41: `Cabo Verde`,
        country_42: `Caribe Países Bajos`,
        country_43: `Islas Caimán`,
        country_44: `República Centroafricana`,
        country_45: `Chad`,
        country_46: `Chile`,
        country_47: `Porcelana`,
        country_48: `Isla de Navidad`,
        country_49: `Islas Cocos (Keeling)`,
        country_50: `Colombia`,
        country_51: `Islas Cook`,
        country_52: `Costa Rica`,
        country_53: `Costa de Marfil`,
        country_54: `Croacia`,
        country_55: `Cuba`,
        country_56: `Chipre`,
        country_57: `Republica checa`,
        country_58: `República Democrática del Congo`,
        country_59: `Dinamarca`,
        country_60: `Yibuti`,
        country_61: `Dominica`,
        country_62: `República Dominicana`,
        country_63: `Ecuador`,
        country_64: `Egipto`,
        country_65: `El Salvador`,
        country_66: `Guinea Ecuatorial`,
        country_67: `Eritrea`,
        country_68: `Estonia`,
        country_69: `Etiopía`,
        country_70: `Islas Malvinas`,
        country_71: `Islas Faroe`,
        country_72: `Estados Federados de Micronesia`,
        country_73: `Fiyi`,
        country_74: `Finlandia`,
        country_75: `Francia`,
        country_76: `Guayana Francesa`,
        country_77: `Polinesia francés`,
        country_78: `Territorios Franceses del Sur`,
        country_79: `Gabón`,
        country_80: `Gambia`,
        country_81: `Georgia`,
        country_82: `Alemania`,
        country_83: `Ghana`,
        country_84: `Gibraltar`,
        country_85: `Gran Bretaña (Reino Unido; Inglaterra)`,
        country_86: `Grecia`,
        country_87: `Groenlandia`,
        country_88: `Granada`,
        country_89: `Guadalupe`,
        country_90: `Guam`,
        country_91: `Guatemala`,
        country_92: `Guernesey`,
        country_93: `Guinea`,
        country_94: `Guinea-Bisáu`,
        country_95: `Guayana`,
        country_96: `Haití`,
        country_97: `Isla Heard e Islas McDonald`,
        country_98: `Honduras`,
        country_99: `Hong Kong`,
        country_100: `Hungría`,
        country_101: `Islandia`,
        country_102: `India`,
        country_103: `Indonesia`,
        country_104: `Irán`,
        country_105: `Irak`,
        country_106: `Irlanda`,
        country_107: `Isla del hombre`,
        country_108: `Israel`,
        country_109: `Italia`,
        country_110: `Jamaica`,
        country_111: `Japón`,
        country_112: `Jersey`,
        country_113: `Jordán`,
        country_114: `Kazajistán`,
        country_115: `Kenia`,
        country_116: `Kiribati`,
        country_117: `Kuwait`,
        country_118: `Kirguistán`,
        country_119: `Laos`,
        country_120: `letonia`,
        country_121: `Líbano`,
        country_122: `Lesoto`,
        country_123: `Liberia`,
        country_124: `Libia`,
        country_125: `Liechtenstein`,
        country_126: `Lituania`,
        country_127: `luxemburgo`,
        country_128: `Macao`,
        country_129: `Madagascar`,
        country_130: `Malaui`,
        country_131: `Malasia`,
        country_132: `Maldivas`,
        country_133: `Malí`,
        country_134: `Malta`,
        country_135: `Islas Marshall`,
        country_136: `Martinica`,
        country_137: `Mauritania`,
        country_138: `Mauricio`,
        country_139: `Mayotte`,
        country_140: `México`,
        country_141: `Moldavia`,
        country_142: `Mónaco`,
        country_143: `Mongolia`,
        country_144: `Montenegro`,
        country_145: `Montserrat`,
        country_146: `Marruecos`,
        country_147: `Mozambique`,
        country_148: `Myanmar (Birmania)`,
        country_149: `Namibia`,
        country_150: `Nauru`,
        country_151: `Nepal`,
        country_152: `Países Bajos`,
        country_153: `Nueva Caledonia`,
        country_154: `Nueva Zelanda`,
        country_155: `Nicaragua`,
        country_156: `Níger`,
        country_157: `Nigeria`,
        country_158: `Niue`,
        country_159: `Isla Norfolk`,
        country_160: `Corea del Norte`,
        country_161: `Islas Marianas del Norte`,
        country_162: `Noruega`,
        country_163: `Omán`,
        country_164: `Pakistán`,
        country_165: `Palaos`,
        country_166: `Territorios palestinos`,
        country_167: `Panamá`,
        country_168: `Papúa Nueva Guinea`,
        country_169: `Paraguay`,
        country_170: `Perú`,
        country_171: `Islas Pitcairn`,
        country_172: `Polonia`,
        country_173: `Portugal`,
        country_174: `Puerto Rico`,
        country_175: `Katar`,
        country_176: `República de Macedonia (FYROM)`,
        country_177: `República del Congo`,
        country_178: `Reunión`,
        country_179: `Rumania`,
        country_180: `Federación Rusa`,
        country_181: `Ruanda`,
        country_182: `San Bartolomé`,
        country_183: `San Martín (Francia)`,
        country_184: `San Pedro y Miquelón`,
        country_185: `Samoa`,
        country_186: `San Marino`,
        country_187: `Santo Tomé y Príncipe`,
        country_188: `Arabia Saudita`,
        country_189: `Senegal`,
        country_190: `Serbia`,
        country_191: `Seychelles`,
        country_192: `Sierra Leona`,
        country_193: `Singapur`,
        country_194: `Eslovaquia`,
        country_195: `Eslovenia`,
        country_196: `Islas Salomón`,
        country_197: `Somalia`,
        country_198: `Sudáfrica`,
        country_199: `Georgia del Sur y la Isla Sándwich del Sur`,
        country_200: `Corea del Sur`,
        country_201: `Sudán del Sur`,
        country_202: `España`,
        country_203: `Sri Lanka`,
        country_204: `Santa Elena y Dependencias`,
        country_205: `San Cristóbal y Nieves`,
        country_206: `Santa Lucía`,
        country_207: `San Vicente y las Granadinas`,
        country_208: `Sudán`,
        country_209: `Surinam`,
        country_210: `Suazilandia`,
        country_211: `Suecia`,
        country_212: `Suiza`,
        country_213: `Siria`,
        country_214: `Taiwán`,
        country_215: `Tayikistán`,
        country_216: `Tanzania`,
        country_217: `Plantilla: datos del país SJM Svalbard`,
        country_218: `Tailandia`,
        country_219: `Las Bahamas`,
        country_220: `Las Comoras`,
        country_221: `Las Filipinas`,
        country_222: `Timor-Leste (Timor Oriental)`,
        country_223: `Ir`,
        country_224: `Tokelau`,
        country_225: `Tonga`,
        country_226: `Trinidad y Tobago`,
        country_227: `Túnez`,
        country_228: `Pavo`,
        country_229: `Turkmenistán`,
        country_230: `Islas Turcas y Caicos`,
        country_231: `Tuvalu`,
        country_232: `Uganda`,
        country_233: `Ucrania`,
        country_234: `Emiratos Árabes Unidos`,
        country_235: `Islas menores alejadas de los Estados Unidos`,
        country_236: `Estados Unidos de América (EE. UU.)`,
        country_237: `Islas Vírgenes de los Estados Unidos`,
        country_238: `Uruguay`,
        country_239: `Uzbekistán`,
        country_240: `Vanuatu`,
        country_241: `Ciudad del Vaticano (La Santa Sede)`,
        country_242: `Venezuela`,
        country_243: `Vietnam`,
        country_244: `Wallis y Futuna`,
        country_245: `Sahara Occidental`,
        country_246: `Yemen`,
        country_247: `Zambia`,
        country_248: `Zimbabue`,
        google_login: `Inicia sesión con Google`,
        State: `Expresar`,
        Activation_code: `Código de activación`,
        Question: `Enumera todas las aplicaciones en las que has iniciado sesión`,
        Copy_complete: `Copia completa`,
        change_password_success: `Contraseña cambiada con éxito`,
        successful_login_title: `Acceso exitoso`,
        product_page: `Página del producto>>`,
        successful_login_info: `Inicio de sesión completado. Cierre la página actual y vuelva a la pestaña original para continuar con el siguiente proceso. La página actual se cerrará automáticamente en 5 segundos. Si falla el cierre automático o el cierre haciendo clic en el botón "Listo", cierre esta pestaña directamente.`,
        successful_login_info_firefox: `Inicio de sesión completado. Cierre la página actual y vuelva a la pestaña original para continuar con el siguiente proceso.`,
        my_account: `Mi cuenta`,
        my_history: `Mi historia`,
        remove_watermark: `Quitar marca de agua`,
        no_history: `No historia`,
        history_all: `Seleccionar todo`,
        history_open: `Abierto`,
        history_down: `Descargar`,
        history_delete: `Borrar`,
        history_clear: `Borrar inválido`,
        images: `imagen(es)`,
        use_this_function: `Utilice este producto>>`,
        hd_downloading: `Beneficios de la descarga de imágenes originales en HD:`,
        lifetimeRemaining: `Vida útil restante`,
        Remaining: `Restante`,
        email_verification: `Verificacion de email`,
        email_verification_info: `Hemos enviado el mensaje de verificación a su correo electrónico <span class="email"></span> y finalice la verificación. Después de verificar, los beneficios se sincronizarán automáticamente.`,
        wrong_email: `¿Dirección de correo errónea?`,
        click_here_to_modify: `Haga clic aquí para modificar`,
        get_more_help: `¿Obtener más ayuda?`,
        click_here: `Haga clic aquí`,
        email_verification_info_success: `Felicitaciones por verificar su cuenta de correo electrónico.`,
        email_verification_info_error: `La verificación falló debido a que el enlace caducó.`,
        registration_succeeded: `Registro Exitoso`,
        registration_succeeded_info_1: `¡Felicidades! Te has registrado exitosamente. Hemos enviado el mensaje de verificación a su correo electrónico <span class="email"></span>, y termine de verificar para usar los beneficios de este correo electrónico.`,
        registration_succeeded_info_2: `Haga clic en "Listo" para volver a la página de inicio y utilizar este producto.`,
        registration_succeeded_info_3: `Haga clic en "Listo" para cerrar la página actual y volver a la página de inicio para las siguientes operaciones. Si el cierre falla, cierre manualmente la pestaña.`,
        verify_email: `Verificar correo electrónico`,
        registered_email_not_verified: `El correo electrónico registrado no ha sido verificado, por favor verifíquelo de inmediato.`,
        email_verification_time_1: `¿No recibiste un correo electrónico de verificación?`,
        email_verification_time_2: `Después`,
        email_verification_time_3: `Haga clic aquí para volver a enviarlo`,
        error_26301: `Código de error: 26301, contáctenos`,
        error_26302: `Código de error: 26302, contáctenos`,
        error_26303: `Error de formato de correo electrónico (código de error: 26303). Por favor, introdúcelo de nuevo.`,
        error_26304: `Se recomienda una contraseña de más de 8 caracteres (código de error: 26304)`,
        error_26305: `Solicitud fallida (código de error: 26305). Vuelva a intentarlo`,
        error_26306: `El correo electrónico se ha registrado, por favor <a href="https://account.tipard.com/login">ir a iniciar sesión</a>`,
        error_26307: `Solicitud fallida (código de error: 26307). Vuelva a intentarlo`,
        error_26308: `Solicitud fallida (código de error: 26308). Vuelva a intentarlo`,
        error_26401: `Código de error: 26401, vuelva a intentarlo`,
        error_26402: `El correo electrónico ha sido verificado (código de error: 26402), vuelve a intentarlo`,
        error_26403: `Solicitud fallida (código de error: 26403). Vuelva a intentarlo`,
        error_26404: `Solicitud fallida (código de error: 26404). Vuelva a intentarlo`,
        error_26501: `Código de error: 26501, contáctenos`,
        error_26502: `Código de error: 26502, contáctenos`,
        error_26503: `Error de formato de correo electrónico (código de error: 26503). Por favor, introdúcelo de nuevo.`,
        error_26504: `Solicitud fallida (código de error: 26504). Vuelva a intentarlo`,
        error_26505: `El correo electrónico no se ha registrado, <a href="https://account.tipard.com/register">regístrelo primero</a>`,
        error_26506: `El correo electrónico ha sido verificado.`,
        error_26507: `Solicitud fallida (código de error: 26507). Vuelva a intentarlo`,
        error_26508: `Verificación fallida (código de error: 26508), vuelva a intentarlo`,
        error_26509: `Solicitud fallida (código de error: 26509), vuelva a intentarlo`,
        error_26510: `Código de error: 26510, contáctenos`,
        error_26601: `Código de error: 26601, contáctenos`,
        error_26602: `Código de error: 26602, contáctenos`,
        error_26603: `Solicitud fallida (código de error: 26603). Vuelva a intentarlo`,
        error_26604: `Código de error: 26604, contáctenos`,
        error_26605: `Código de error: 26605, contáctenos`,
        error_26701: `Código de error: 26701, contáctenos`,
        error_26702: `Solicitud fallida (código de error: 26702). Vuelva a intentarlo`,
        error_26703: `Código de error: 26703, contáctenos`,
        error_26704: `Código de error: 26704, contáctenos`,
        error_26705: `Espere a iniciar sesión (código de error: 26705). Vuelva a intentarlo`,
        no_cookie: `Ha activado la función Bloquear todas las cookies en su navegador, por lo que no puede iniciar sesión. Vaya a Configuración para marcar la casilla Permitir todas las cookies.`,
        error_26801: `Código de error: 26801, contáctenos`,
        error_26802: `Código de error: 26802, contáctenos`,
        error_26803: `Solicitud fallida (código de error: 26803). Vuelva a intentarlo`,
        error_26804: `Solicitud fallida (código de error: 26804). Vuelva a intentarlo`,
        error_order: `Solicitud fallida (código de error: 27098), inténtalo de nuevo.`,
        error_order1: `La consulta del pedido está incompleta (código de error:`,
        error_order2: `）, actualice y vuelva a intentarlo.`,
        modify_email_title: `Modificar correo electrónico`,
        modify_email_info: `Puede utilizar el correo electrónico modificado para iniciar sesión en su cuenta.`,
        images_per: `Imágenes por`,
        error_26101: `Error: 26101. Por favor contáctenos`,
        error_26102: `Error: 26102. Por favor contáctenos`,
        error_26103: `Solicitud fallida (código de error: 26103). Por favor, inténtalo de nuevo`,
        error_26104: `Código de error: 26104, vuelva a intentarlo`,
        error_26105: `Código de error: 26105, vuelva a intentarlo`,
        error_26106: `Error al eliminar (código de error: 26106). Inténtalo de nuevo`,
        error_26201: `Error: 26201. Por favor contáctenos`,
        error_26202: `Solicitud fallida (código de error: 26202). Por favor, inténtalo de nuevo`,
        error_26001: `Error: 26001. Por favor contáctenos`,
        error_26002: `Error: 26002. Por favor contáctenos`,
        error_26003: `Error: 26003. Por favor contáctenos`,
        error_26004: `Error: 26004. Por favor contáctenos`,
        error_26005: `Solicitud fallida (código de error: 26005). Por favor, inténtalo de nuevo`,
        error_26006: `Código de error: 26006, vuelva a intentarlo`,
        error_26008: `Error: 26008. Por favor contáctenos`,
        go_to_the_home_page: `Ir a la pagina principal`,
        error_27101: `Solicitud fallida (código de error: 27101). Vuelva a intentarlo`,
        error_27201: `Código de error: 27201, contáctenos`,
        error_27202: `Código de error: 27202, vuelva a intentarlo`,
        error_27203: `Solicitud fallida (código de error: 27203). Vuelva a intentarlo`,
        error_27204: `Código no válido (código de error: 27204).`,
        error_27205: `Solicitud fallida (código de error: 27205). Vuelva a intentarlo`,
        error_27206: `Solicitud fallida (código de error: 27206). Vuelva a intentarlo`,
        error_27207: `Solicitud fallida (código de error: 27207). Vuelva a intentarlo`,
        no_history_found: `¡No has usado ninguna herramienta! <a href="/">Actualizar</a> o <a href="https://es.tipard.com/">Ir al sitio web oficial</a>`,
        error_25301: `Error: 25301. Póngase en contacto con nosotrosisTSArrayType`,
        error_25302: `Error: 25302. Póngase en contacto con nosotrosisTSArrayType`,
        error_25303: `Solicitud fallida (código de error: 25303). Inténtalo de nuevoisTSArrayType`,
        error_25304: `Solicitud fallida (código de error: 25304). Inténtalo de nuevoisTSArrayType`,
        error_25305: `Solicitud fallida (código de error: 25305). Inténtalo de nuevoisTSArrayType`,
        error_25306: `Solicitud fallida (código de error: 25306). Inténtalo de nuevoisTSArrayType`,
        image_upscaler_p: `Beneficios de la descarga de imágenes sin marca de agua:`,
        Available_for: `Disponible para:`,
        credit_per: `%s crédito(s) por imagen HD`,
        still_valid: `Los planes adquiridos siguen siendo válidos`,
        credit: `crédito(s)`,
        pc_3rd_info: `Inicie sesión exitosamente. Vaya a la aplicación para realizar más operaciones.`,
        use_online: `Utilice el servicio en línea`,
        use_download: `Utilice el programa de escritorio`,
        use_immediately: `Usar inmediatamente`,
        Use_in_browser: `Usar en el navegador`,
        win_desktop: `ventanas`,
        Mac_desktop: `Mac`,
        credits_per: `{%} Créditos por mes`,
        expire: `Tiempo de expiración:`,
        viewDetails: `Ver detalles`,
        viewHistory: `Ver historial de beneficios>>`,
        viewDetailsInfo: `Aviso: si los beneficios de la suscripción se renuevan dentro de los 7 días posteriores al vencimiento, los beneficios no utilizados se pueden seguir utilizando. Además, el tiempo de vencimiento se actualizará automáticamente al tiempo de vencimiento de la nueva suscripción. Si no hay una nueva suscripción después de 7 días de vencimiento, se borrarán todos los beneficios vencidos.`,
        "connect_account": "Vincular correo electrónico a su cuenta",
        "connect_account_info": "Después de vincular, puede iniciar sesión con esta dirección de correo electrónico.",
        "connect_now": "Vincular ahora",
        "no_email_bind": "Sin enlace de correo electrónico",
        "bind_email": "Vincular correo electrónico",
        "connect_your_email_placeholder": "Por favor, introduzca su dirección de correo electrónico",
        "bind_an_email": "Vincular un correo electrónico",
        "bind_info": "Has iniciado sesión correctamente. Vincula un correo electrónico a tu cuenta para activar tus beneficios.",
        "bind_later": "Enlazar más tarde",
        "footer": "Copyright © 2024 Tipard Studio. Todos los derechos reservados.",
        "overtime": "Código de error: {%}, inténtalo de nuevo",
        "hi": "¡Su!",
        "Personal_Information": "Información personal",
        "Access": "Acceso",
        "Subscription_Plan": "(Plan de suscripción)",
        "No_orders": "No se encontraron pedidos.",
        "No_data": "Sin datos",
        "Featured_Products": "Productos destacados",
        "More_Products": "Más productos",
        "Free_Download": "Descarga gratuita",
        "Get_Started": "Empezar",
        "Subscribe": "Suscribir",
        "Verified": "Verificado",
        "back_to_account_center": "Volver al Centro de cuentas",
        "success": "¡Éxito!",
        "successfully": "Ha registrado una cuenta exitosamente.",
        "Continue": "Continuar",
        "Already": "¿Ya tienes una cuenta?",
        "loading_verification": "Comprobando el estado de verificación...",
        "email_no_verification": "Lo sentimos, la dirección de correo electrónico registrada no ha sido verificada. Complete la verificación según las instrucciones anteriores y haga clic en el botón \"Listo\" nuevamente para completar el registro.",
        "error_20505": "El correo electrónico ha sido vinculado",
        "error_20506": "Error: 20506. Por favor, póngase en contacto con nosotros.",
        "error_28401": "Por favor ingrese su correo electrónico",
        "error_28402": "Formato de correo electrónico incorrecto",
        "error_28403": "Error: 28403. Por favor, póngase en contacto con nosotros.",
        "error_28404": "El correo electrónico ha sido registrado",
        "error_28405": "Error: 28405. Por favor, póngase en contacto con nosotros.",
        "error_28406": "El correo electrónico ha sido vinculado",
        "error_28407": "Error: 28407. Por favor, póngase en contacto con nosotros.",
        "error_28408": "No se pudo enviar el correo electrónico",
        "error_28601": "Por favor ingrese su correo electrónico",
        "error_28602": "Por favor ingrese el código de verificación",
        "error_28603": "Formato de correo electrónico incorrecto",
        "error_28604": "Error: 28604. Por favor, póngase en contacto con nosotros",
        "error_28605": "El código de verificación es incorrecto",
        "error_28606": "Error: 28606. Por favor, póngase en contacto con nosotros",
        "error_28607": "El correo electrónico ha sido registrado",
        "error_28608": "No hay ningún correo electrónico vinculado a su cuenta",
        "error_28609": "El correo electrónico no está verificado",
        "error_28610": "Error: 28610. Por favor, póngase en contacto con nosotros.",
        "error_28611": "El correo electrónico ha sido vinculado",
        "error_28612": "Error: 28612. Por favor, póngase en contacto con nosotros",
        "unbind_email_btn": "Desvincular el correo electrónico",
        "binding_exchange": "Vincular correo electrónico",
        "Subscription_Status": "Estado de suscripción",
        "mask_unbind_info_1": "¿Estás seguro de desvincular {%} de esta cuenta? Después de desvincularla, ya no podrás consultar los beneficios de este correo electrónico",
        "mask_unbind_info_2": "¿Está seguro de que desea desvincular todas las PC asociadas con este código de registro?<a href=\"https://es.tipard.com/faq.html#unbind\">Obtenga más información sobre cómo cambiar dispositivos.</a>",
        "error_dc_link": "Error, por favor contáctenos",
        "vcu_state": "Beneficios de la versión completa:",
        "monthly_subscription": "Licencia por 1 mes",
        "year_subscription": "Licencia por 1 año",
        "to": "a",
        "lifetime_subscription": "Licencia de por vida",
        "unbind": "Confirmar",
        "unbind_success": "Desvinculado exitosamente",
        "error_28401": "Por favor ingrese su correo electrónico",
        "error_28702": "Error: 28702. Por favor, póngase en contacto con nosotros",
        "error_28703": "El código de verificación es incorrecto",
        "error_28704": "Error: 28704. Por favor, póngase en contacto con nosotros.",
        "error_28705": "El correo electrónico no ha sido vinculado",
        "error_28706": "Por favor ingrese el código de verificación",
        "error_28707": "No se pudo desvincular",
        "error_28901": "Error: 28901. Por favor, póngase en contacto con nosotros",
        "error_28902": "Error: 28902. Por favor, póngase en contacto con nosotros.",
        "error_28903": "Error: 28903. Por favor, póngase en contacto con nosotros.",
        "error_28904": "Error: 28904. Por favor, póngase en contacto con nosotros.",
        "error_28905": "Error: 28905. Por favor, póngase en contacto con nosotros.",
        "error_28906": "Error: 28906. Por favor, póngase en contacto con nosotros.",
        "error_28907": "Código de registro no válido",
        "error_28908": "Error: 28908. Por favor, póngase en contacto con nosotros.",
        "error_28909": "Actualmente se encuentra en período de recuperación de desvinculación. Solo se puede desvincular una vez al mes.",
        "error_289010": "Error: 289010. Por favor, póngase en contacto con nosotros.",
        "error_289011": "Error: 289011. Por favor, póngase en contacto con nosotros",
        "error_289012": "Error: 289012. Por favor, póngase en contacto con nosotros.",
        "activated_computer": "Activado en %s/%s computadoras",
        "bind_email_info": "Los beneficios de los siguientes correos electrónicos estarán vinculados y serán utilizados bajo esta cuenta VIP.",
        "error_26309": "Error: 26309. Por favor, póngase en contacto con nosotros.",
        "error_26310": "Código de referencia no válido",
        "error_26311": "Error: 26311. Por favor, póngase en contacto con nosotros",
        "error_26312": "Código de referencia no válido",
        "error_26313": "Error: 26313. Por favor, póngase en contacto con nosotros",
        "error_26314": "El correo electrónico ha sido vinculado",
        "activate_unbinding": "Cambiar dispositivo",
        "copy_registration_code": "Copiar código de registro",
        "show_registration_code": "Mostrar código de registro",
        "hide_registration_code": "Ocultar código de registro",
        "other_binding_email": "Otro correo electrónico vinculante",
        "home": "Hogar",
        "points_balance": "Saldo de puntos:",
        "earn_now": "Gana ahora >>",
        "earn_more": "Gane más puntos GRATIS, canjee más regalos",
        "earn_or_redeem_points": "Ganar/Canjear puntos",
        "unbind_email_info": "La cuenta actual se ha asociado a una dirección de correo electrónico.",
        "bind_email_title": "Si desea asociar más pedidos para comprar direcciones de correo electrónico, haga clic en el botón \"Asociación de correo electrónico\" a continuación para asociar.",
        "bind_email_tip": "<span>Sugerencia:</span> Actualmente solo se admiten compras de pedidos de códigos de registro por correo electrónico.",
        "email_association": "Asociación de correo electrónico",
        "dissociation": "Disociación",
        "back": "Atrás",
        "activate_benefits": "Por favor, introduce el email de compra que necesitas asociar para obtener el código de verificación. Introduce el código de verificación recibido en el email en el cuadro de entrada del código de verificación y haz clic en el botón \"Verificar Activación\" para activar los beneficios.",
        "e_mail": "Correo electrónico:",
        "verification_code": "Código de verificación:",
        "obtain_verification_code": "Obtener código de verificación",
        "verification_association": "Verificar asociación",
        "current_password": "Contraseña actual:",
        "account_center": "Centro de cuentas",
        "new_password": "Nueva contraseña:",
        "confirm_password": "Confirmar nueva contraseña:",
        "Send": "Enviar",
        "history_files": "Archivos históricos",
        "delete_account_info_1": "1. Eliminar tu cuenta de Tipard es una acción permanente y no se puede deshacer. Se perderán todos tus datos, incluidas las fotos y las preferencias. ¿Estás seguro de que deseas eliminar tu cuenta de Tipard?",
        "delete_account_info_2": "2. Si decide eliminar la cuenta de Tipard, haga clic en el botón Enviar e ingrese el código de verificación del correo electrónico que le enviamos.",
        "best_deals": "Las mejores ofertas",
        "close": "Cerca",
        "product":"Produto:"
    },
    ar: {
        "overtime_1": "رمز الخطأ:",
        "overtime_2": "من فضلك حاول مرة أخرى",
        "isnetwork": "خطأ في الإنترنت. يرجى التحقق وإعادة المحاولة",
        "email_placeholder": "بريد إلكتروني",
        "email_empty": "الرجاء إدخال البريد الإلكتروني",
        "email_not_valid": "البريد الإلكتروني غير صالح",
        "email_not_valid_1": "الرجاء إدخال بريدك الإلكتروني",
        "email_not_valid_2": "البريد الإلكتروني غير صالح، يرجى استخدام عنوان مختلف.",
        "email_not_valid_3": "لا يوجد إدخال البريد الإلكتروني",
        "password_placeholder": "كلمة المرور",
        "password_empty": "الرجاء إدخال كلمة المرور",
        "password_not_valid": "الحساب أو كلمة المرور غير صحيحة",
        "password_not_valid_1": "يجب أن تكون كلمة المرور أكبر من 8 أحرف",
        "password_not_valid_2": "الرجاء إنشاء كلمة مرور",
        "password_placeholder_1": "إنشاء كلمة المرور الخاصة بك",
        "password_placeholder_2": "تأكيد كلمة المرور الخاصة بك",
        "password_placeholder_3": "إنشاء كلمة مرور جديدة",
        "password_placeholder_4": "تأكيد كلمة المرور الجديدة",
        "password_placeholder_5": "أدخل كلمة المرور القديمة",
        "copy_password_empty": "الرجاء تأكيد كلمة المرور",
        "copy_password_not_valid": "الرجاء تأكيد كلمة المرور الخاصة بك",
        "copy_passwords_inconsistent": "تأكيد كلمة المرور الخاصة بك لا يتطابق",
        "code_empty": "الرجاء إدخال رمز التحقق",
        "code_not_valid": "رمز التحقق غير صالح",
        "code_placeholder": "رمز التحقق",
        "not_received_code": "إذا لم يتلقى صندوق البريد الخاص بك رمز التحقق لفترة طويلة، فيرجى الحصول على رمز التحقق مرة أخرى.", 
        "get_first_code": "يرجى الحصول على رمز التحقق أولاً.",
        "last_name_placeholder": "الرجاء إدخال اسمك الأخير",
        "first_name_placeholder": "الرجاء إدخال اسمك الأول",
        "address_placeholder": "الرجاء إدخال عنوانك",
        "no_code_text": "لقد أرسلنا لك رمز التحقق. الرجاء إدخال الرمز الخاص بك. <span class='tips'>لم تتلق رمزًا؟", 
        "no_code_text_1": "1. يرجى التأكد من أن عنوان البريد الإلكتروني صالح ويمكنه تلقي رسائل البريد الإلكتروني.", 
        "no_code_text_2": "2. إذا تم إرسال البريد الإلكتروني بواسطة النظام تلقائيًا، فقد يتم تصنيفه على أنه بريد عشوائي أو غير مرغوب فيه. يرجى التحقق مما إذا كان البريد الإلكتروني موجودًا في مجلد سلة المهملات.", 
        "no_code_text_3": "3. لم تتمكن من حل مشكلتك؟", 
        "no_code_text_3_span": "ثم انقر هنا للتواصل معنا.", 
        "order_no": "لم تقم بشراء أي منتج، إذا كان لديك أي سؤال، يرجى <a href=\"https://ar.tipard.com/contact.html\">الاتصال بنا</a>.", 
        "error_24901": "الحساب الحالي ليس له بريد إلكتروني مرتبط، ولا يمكن العثور على الطلبات. يرجى ربط بريد إلكتروني.", 
        "user_guide": "دليل المستخدم>>", 
        "download": "تحميل", 
        "order_number": "رقم الطلب:", 
        "Refund": "استرداد", 
        "Disabled": "عاجز", 
        "Normal": "طبيعي", 
        "Modify": "يُعدِّل", 
        "Modify_1": "تعديل>>", 
        "Connect": "يتصل", 
        "unlink_success": "تم إلغاء الارتباط بنجاح", 
        "connect_success": "تم الاتصال بنجاح", 
        "feedback_title": "شكرًا لك على تعليقك. يرجى ترك مشكلتك وسنرد عليك خلال 24 ساعة.", 
        "feedback_thank_you": "شكرا لك!<br />تم إرسال تعليقك بنجاح.", 
        "feedback_email": "أدخل بريدك الإلكتروني هنا!", 
        "feedback_content": "اترك أي مشكلة أو اقتراح واجهته هنا.", 
        "feedback_submit": "يُقدِّم", 
        "form_contents": "لم تقم بإدخال أي وصف. يرجى إدخاله وإرساله مرة أخرى.", 
        "old_password": "الرجاء إدخال كلمة المرور القديمة", 
        "new_password": "كلمة المرور الجديدة:", 
        "old_new_password": "لا يمكن أن تكون كلمة المرور الجديدة هي نفسها القديمة", 
        "incorrect_password": "كلمة المرور غير صحيحة", 
        "delete_no": "احذف الآن", 
        "Caps": "تم تشغيل Caps Lock", 
        "Get": "يحصل", 
        "Done": "منتهي", 
        "error_20001": "خطأ: 20001. يرجى تسجيل الدخول مرة أخرى.", 
        "error_20002": "خطأ: 20002. يرجى تسجيل الدخول مرة أخرى.", 
        "error_20003": "خطأ: 20003. يرجى تسجيل الدخول مرة أخرى.", 
        "error_20004": "فشل الطلب (رمز الخطأ: 20004). يرجى المحاولة مرة أخرى.", 
        "error_20005": "انتهت صلاحية جلسة تسجيل الدخول (خطأ: 20005). يرجى تسجيل الدخول مرة أخرى.", 
        "error_20006": "فشل الطلب (رمز الخطأ: 20006). يرجى المحاولة مرة أخرى.", 
        "error_20007": "انتهت صلاحية جلسة تسجيل الدخول (خطأ: 20007). يرجى تسجيل الدخول مرة أخرى.", 
        "error_20008": "انتهت صلاحية جلسة تسجيل الدخول (خطأ: 20008). يرجى تسجيل الدخول مرة أخرى.", 
        "error_20009": "انتهت صلاحية جلسة تسجيل الدخول (خطأ: 20009). يرجى تسجيل الدخول مرة أخرى.", 
        "error_20101": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20101)", 
        "error_20102": "البريد الإلكتروني غير صالح (رمز الخطأ: 20102)", 
        "error_20103": "فشل الطلب (رمز الخطأ: 20103). يرجى المحاولة مرة أخرى", 
        "error_20104": "البريد الإلكتروني قيد الاستخدام بالفعل، يرجى <a href=\"https://account.tipard.com/login\">تسجيل الدخول</a> أو التسجيل باستخدام بريد إلكتروني جديد", 
        "error_20105": "فشل الطلب (رمز الخطأ: 20105). يرجى المحاولة مرة أخرى", 
        "error_20106": "فشل في إرسال البريد الإلكتروني، يرجى إعادة المحاولة", 
        "error_20201": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20201)", 
        "error_20202": "الرجاء إدخال كلمة المرور الخاصة بك (رمز الخطأ: 20202)", 
        "error_20203": "الرجاء إدخال رمز التحقق (رمز الخطأ: 20203)", 
        "error_20204": "البريد الإلكتروني غير صالح (رمز الخطأ: 20204)", 
        "error_20205": "يجب إدخال كلمة مرور تتكون من 8 أحرف أو أكثر (رمز الخطأ: 20205)", 
        "error_20206": "فشل الطلب (رمز الخطأ: 20206). يرجى المحاولة مرة أخرى", 
        "error_20207": "رمز التحقق غير صالح", 
        "error_20208": "فشل الطلب (رمز الخطأ: 20208). يرجى المحاولة مرة أخرى", 
        "error_20209": "فشل الطلب (رمز الخطأ: 20209). يرجى المحاولة مرة أخرى", 
        "error_20301": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20301)", 
        "error_20302": "خطأ: 20302. يرجى الاتصال بنا", 
        "error_20303": "البريد الإلكتروني غير صالح (رمز الخطأ: 20303)", 
        "error_20304": "فشل الطلب (رمز الخطأ: 20304). يرجى المحاولة مرة أخرى", 
        "error_20305": "الحساب غير موجود. يرجى إعادة إدخاله أو <a href=\"https://account.tipard.com/register\">إنشائه</a> أولاً.", 
        "error_20306": "لا توجد كلمة مرور حتى الآن. استخدم <a href=\"https://account.tipard.com/passwordless-login\">تسجيل الدخول بدون كلمة مرور</a> أو <a href=\"create-password\">قم بتعيين كلمة مرور</a> وقم بتسجيل الدخول.", 
        "error_20308": "فشل الطلب (رمز الخطأ: 20308). يرجى المحاولة مرة أخرى", 
        "error_20401": "فشل تسجيل الخروج (رمز الخطأ: 20401). يرجى المحاولة مرة أخرى", 
        "error_20501": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20501)", 
        "error_20502": "البريد الإلكتروني غير صالح (رمز الخطأ: 20502)", 
        "error_20503": "فشل الطلب (رمز الخطأ: 20503). يرجى المحاولة مرة أخرى", 
        "error_20504": "فشل إرسال البريد الإلكتروني. يرجى المحاولة مرة أخرى.", 
        "error_20601": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20601)", 
        "error_20602": "الرجاء إدخال رمز التحقق (رمز الخطأ: 20602)", 
        "error_20603": "البريد الإلكتروني غير صالح (رمز الخطأ: 20603)", 
        "error_20604": "فشل الطلب (رمز الخطأ: 20604). يرجى المحاولة مرة أخرى", 
        "error_20606": "فشل الطلب (رمز الخطأ: 20606). يرجى المحاولة مرة أخرى", 
        "error_20607": "فشل الطلب (رمز الخطأ: 20607). يرجى المحاولة مرة أخرى", 
        "error_20608": "فشل الطلب (رمز الخطأ: 20608). يرجى المحاولة مرة أخرى", 
        "error_20701": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20701)", 
        "error_20702": "البريد الإلكتروني غير صالح (رمز الخطأ: 20702)", 
        "error_20703": "فشل الطلب (رمز الخطأ: 20703). يرجى المحاولة مرة أخرى", 
        "error_20704": "الحساب غير موجود. يرجى إعادة إدخاله أو <a href=\"https://account.tipard.com/register\">إنشائه</a> أولاً.", 
        "error_20705": "فشل الطلب (رمز الخطأ: 20705). يرجى المحاولة مرة أخرى", 
        "error_20706": "فشل إرسال البريد الإلكتروني. يرجى المحاولة مرة أخرى", 
        "error_20801": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 20801)", 
        "error_20802": "خطأ: 20802. يرجى الاتصال بنا", 
        "error_20803": "الرجاء إدخال رمز التحقق (رمز الخطأ: 20803)", 
        "error_20804": "البريد الإلكتروني غير صالح (رمز الخطأ: 20804)", 
        "error_20805": "يجب إدخال كلمة مرور تتكون من 8 أحرف أو أكثر (رمز الخطأ: 20805)", 
        "error_20806": "فشل الطلب (رمز الخطأ: 20806). يرجى المحاولة مرة أخرى", 
        "error_20808": "فشل الطلب (رمز الخطأ: 20808). يرجى المحاولة مرة أخرى", 
        "error_20901": "فشل الطلب (رمز الخطأ: 20901). يرجى المحاولة مرة أخرى", 
        "error_20902": "فشل الطلب (رمز الخطأ: 20902). يرجى المحاولة مرة أخرى", 
        "error_21000": "تم حفظ التغييرات", 
        "error_21001": "لم يتم إرسال أي معلومات (رمز الخطأ: 21001)", 
        "error_21002": "فشل الطلب (رمز الخطأ: 21002). يرجى المحاولة مرة أخرى", 
        "error_21101": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 21101)", 
        "error_21102": "البريد الإلكتروني غير صالح (رمز الخطأ: 21102)", 
        "error_21103": "فشل الطلب (رمز الخطأ: 21103)، يرجى إعادة المحاولة مرة أخرى", 
        "error_21104": "تم توصيل البريد الإلكتروني بالفعل، يرجى استخدام بريد إلكتروني جديد", 
        "error_21105": "فشل الطلب (رمز الخطأ: 21105)، يرجى إعادة المحاولة مرة أخرى", 
        "error_21106": "فشل إرسال البريد الإلكتروني. يرجى المحاولة مرة أخرى", 
        "error_21201": "الرجاء إدخال بريدك الإلكتروني (رمز الخطأ: 21201)", 
        "error_21202": "الرجاء إدخال رمز التحقق (رمز الخطأ: 21202)", 
        "error_21203": "البريد الإلكتروني غير صالح (رمز الخطأ: 21203)", 
        "error_21204": "خطأ: 21204. يرجى الاتصال بنا", 
        "error_21205": "خطأ: 21205. يرجى الاتصال بنا", 
        "error_21206": "يجب إدخال كلمة مرور تتكون من 8 أحرف أو أكثر (رمز الخطأ: 21206)", 
        "error_21207": "فشل الطلب (رمز الخطأ: 21207). يرجى المحاولة مرة أخرى", 
        "error_21209": "فشل الطلب (رمز الخطأ: 21209). يرجى المحاولة مرة أخرى", 
        "error_21301": "الرجاء إدخال كلمة المرور القديمة (رمز الخطأ: 21301)", 
        "error_21302": "الرجاء إنشاء كلمة مرور جديدة (رمز الخطأ: 21302)", 
        "error_21303": "لا يمكن أن تكون كلمة المرور الجديدة هي نفسها القديمة. (خطأ: 21303)", 
        "error_21304": "يجب إدخال كلمة مرور تتكون من 8 أحرف أو أكثر (رمز الخطأ: 21304)", 
        "error_21306": "فشل الطلب (رمز الخطأ: 21306). يرجى المحاولة مرة أخرى", 
        "error_21402": "فشل الطلب (رمز الخطأ: 21402). يرجى المحاولة مرة أخرى", 
        "error_21403": "فشل إرسال رمز التحقق. يرجى إعادة إرساله", 
        "error_21500": "تم حذف الحساب", 
        "error_21501": "الرجاء إدخال رمز التحقق (رمز الخطأ: 21501)", 
        "error_21502": "انتهت صلاحية جلسة تسجيل الدخول (خطأ: 21502). يرجى تسجيل الدخول مرة أخرى.", 
        "error_21503": "فشل الطلب (رمز الخطأ: 21503). يرجى المحاولة مرة أخرى", 
        "error_21505": "فشل الطلب (رمز الخطأ: 21505)، يرجى إعادة المحاولة", 
        "error_21601": "خطأ: 20601. يرجى الاتصال بنا", 
        "error_21602": "التحقق غير صالح (خطأ: 20602). يرجى المحاولة مرة أخرى.", 
        "error_21603": "خطأ: 20603. يرجى المحاولة مرة أخرى", 
        "error_21604": "فشل الطلب (رمز الخطأ: 21604). يرجى المحاولة مرة أخرى", 
        "error_21606": "فشل الطلب (رمز الخطأ: 21606). يرجى المحاولة مرة أخرى", 
        "error_21611": "فشل الطلب (رمز الخطأ: 21611). يرجى المحاولة مرة أخرى", 
        "error_21801": "خطأ: 21801. يرجى الاتصال بنا", 
        "error_21802": "فشل الطلب (خطأ: 21802). يرجى المحاولة مرة أخرى", 
        "error_21803": "خطأ: 21803. يرجى المحاولة مرة أخرى", 
        "error_21804": "فشل الطلب (رمز الخطأ: 21804). يرجى المحاولة مرة أخرى", 
        "error_21806": "خطأ: 21806. يرجى المحاولة مرة أخرى", 
        "error_21807": "خطأ: 21807. يرجى الاتصال بنا", 
        "error_21808": "خطأ: 21808. يرجى الاتصال بنا", 
        "error_21809": "خطأ: 21809. يرجى الاتصال بنا", 
        "error_21810": "خطأ: 21810. يرجى الاتصال بنا", 
        "error_21811": "خطأ: 21811. يرجى الاتصال بنا", 
        "error_21812": "خطأ: 21812. يرجى الاتصال بنا", 
        "error_21813": "فشل الطلب (رمز الخطأ: 21813). يرجى المحاولة مرة أخرى", 
        "error_21814": "خطأ: 21814. يرجى الاتصال بنا", 
        "error_21815": "فشل الطلب (رمز الخطأ: 21815). يرجى المحاولة مرة أخرى", 
        "error_21816": "خطأ: 21816. يرجى الاتصال بنا", 
        "error_21817": "خطأ: 21817. يرجى الاتصال بنا", 
        "error_21818": "خطأ: 21818. يرجى الاتصال بنا", 
        "error_21819": "فشل الطلب (رمز الخطأ: 21819). يرجى المحاولة مرة أخرى", 
        "error_21820": "خطأ: 21820. يرجى الاتصال بنا", 
        "error_21821": "خطأ: 21821. يرجى الاتصال بنا", 
        "error_21822": "خطأ: 21822. يرجى الاتصال بنا", 
        "error_21823": "فشل الطلب (رمز الخطأ: 21823). يرجى المحاولة مرة أخرى", 
        "error_21824": "فشل الطلب (رمز الخطأ: 21824). يرجى المحاولة مرة أخرى", 
        "error_21825": "فشل الطلب (رمز الخطأ: 21825). يرجى المحاولة مرة أخرى", 
        "error_21826": "فشل الطلب (رمز الخطأ: 21826). يرجى المحاولة مرة أخرى", 
        "error_21901": "خطأ: 21901. يرجى الاتصال بنا", 
        "error_21902": "فشل الطلب (رمز الخطأ: 21902). يرجى المحاولة مرة أخرى", 
        "error_21903": "لقد تغيرت حالة الحساب (رمز الخطأ: 21903)، يرجى تحديث الصفحة والمحاولة مرة أخرى", 
        "error_21904": "خطأ: 21904. يرجى المحاولة مرة أخرى", 
        "error_21905": "خطأ: 21905. يرجى المحاولة مرة أخرى", 
        "error_21906": "فشل الطلب (رمز الخطأ: 21906). يرجى المحاولة مرة أخرى", 
        "error_21907": "تم ربط حساب Google بحساب آخر", 
        "error_21908": "فشل الطلب (رمز الخطأ: 21908). يرجى المحاولة مرة أخرى", 
        "error_22001": "فشل الطلب (رمز الخطأ: 22001). يرجى المحاولة مرة أخرى", 
        "error_22002": "فشل إلغاء الارتباط بدون تسجيل دخول إضافي", 
        "error_22003": "فشل الطلب (رمز الخطأ: 22003). يرجى المحاولة مرة أخرى", 
        "error_22101": "خطأ: 22101. يرجى الاتصال بنا", 
        "error_22102": "لقد تغيرت حالة الحساب (رمز الخطأ: 22102)، يرجى تحديث الصفحة والمحاولة مرة أخرى", 
        "error_22103": "فشل الطلب (رمز الخطأ: 22103). يرجى المحاولة مرة أخرى", 
        "error_22104": "لقد تغيرت حالة الحساب (رمز الخطأ: 22104)، يرجى تحديث الصفحة والمحاولة مرة أخرى", 
        "error_22105": "خطأ: 22105. يرجى المحاولة مرة أخرى", 
        "error_22106": "خطأ: 22106. يرجى المحاولة مرة أخرى", 
        "error_22107": "خطأ: 22107. يرجى الاتصال بنا", 
        "error_22108": "فشل الطلب (رمز الخطأ: 22108). يرجى المحاولة مرة أخرى", 
        "error_22201": "خطأ: 22201. يرجى الاتصال بنا", 
        "error_22202": "التحقق غير صالح (خطأ: 22202). يرجى المحاولة مرة أخرى.", 
        "error_22203": "خطأ: 22203. الرجاء المحاولة مرة أخرى\"", 
        "error_22204": "فشل الطلب (رمز الخطأ: 22204). يرجى المحاولة مرة أخرى", 
        "error_22206": "فشل الطلب (رمز الخطأ: 22206). يرجى المحاولة مرة أخرى", 
        "error_22401": "خطأ: 22401. يرجى الاتصال بنا", 
        "error_22402": "التحقق غير صالح (خطأ: 22402). يرجى المحاولة مرة أخرى.", 
        "error_22403": "خطأ: 22403. يرجى المحاولة مرة أخرى", 
        "error_22404": "فشل الطلب (رمز الخطأ: 22404). يرجى المحاولة مرة أخرى", 
        "error_22405": "تم ربط حساب الفيسبوك ببريد إلكتروني آخر", 
        "error_22406": "خطأ: 22406. يرجى المحاولة مرة أخرى", 
        "error_22407": "خطأ: 22407. يرجى الاتصال بنا", 
        "error_22408": "خطأ: 22408. يرجى الاتصال بنا", 
        "error_22409": "خطأ: 22409. يرجى الاتصال بنا", 
        "error_22410": "خطأ: 224010. يرجى الاتصال بنا", 
        "error_22411": "خطأ: 224011. يرجى الاتصال بنا", 
        "error_22412": "خطأ: 224012. يرجى الاتصال بنا", 
        "error_22413": "خطأ: 22413. يرجى الاتصال بنا", 
        "error_22414": "فشل الطلب (رمز الخطأ: 22414). يرجى المحاولة مرة أخرى", 
        "error_22415": "خطأ: 22415. يرجى الاتصال بنا", 
        "error_22416": "خطأ: 22416. يرجى الاتصال بنا", 
        "error_22417": "خطأ: 22417. يرجى الاتصال بنا", 
        "error_22418": "فشل الطلب (رمز الخطأ: 22418). يرجى المحاولة مرة أخرى", 
        "error_22419": "خطأ: 22419. يرجى الاتصال بنا", 
        "error_22420": "خطأ: 22420. يرجى الاتصال بنا", 
        "error_22421": "خطأ: 22421. يرجى الاتصال بنا", 
        "error_22422": "فشل الطلب (رمز الخطأ: 22422). يرجى المحاولة مرة أخرى", 
        "error_22423": "فشل الطلب (رمز الخطأ: 22423). يرجى المحاولة مرة أخرى", 
        "error_22424": "فشل الطلب (رمز الخطأ: 22424). يرجى المحاولة مرة أخرى", 
        "error_22425": "فشل الطلب (رمز الخطأ: 22425). يرجى المحاولة مرة أخرى", 
        "error_20098": "رمز الخطأ: 20098. إذا كنت تتصفح في الوضع الخاص، فيرجى التبديل إلى الوضع العادي ثم حاول مرة أخرى.", 
        "error_22298": "فشل طلب Google (خطأ: 22298). يرجى المحاولة مرة أخرى.", 
        "error_22498": "فشل طلب Facebook (خطأ: 22498). يرجى المحاولة مرة أخرى.", 
        "error_24902": "فشل الطلب (رمز الخطأ: 24902). يرجى المحاولة مرة أخرى", 
        "error_24903": "فشل الطلب (رمز الخطأ: 24903). يرجى المحاولة مرة أخرى", 
        "error_24904": "فشل الطلب (رمز الخطأ: 24904). يرجى المحاولة مرة أخرى", 
        "error_24905": "فشل الطلب (رمز الخطأ: 24905). يرجى المحاولة مرة أخرى", 
        "login_title": "تسجيل الدخول إلى Tipard", 
        "log_in": "تسجيل الدخول", 
        "no_account": "ليس لديك حساب؟", 
        "create_it": "اصنعها", 
        "or_log_in_with": "أو قم بتسجيل الدخول باستخدام", 
        "passwordless_login": "تسجيل الدخول بدون كلمة مرور", 
        "log_in_done": "تم تسجيل الدخول", 
        "three_rd_account_connect_info": "مبروك! لقد قمت بتسجيل الدخول بنجاح. يمكنك الآن ربط حساب بريد إلكتروني واحد يُستخدم لتسجيل الدخول في المستقبل.", 
        "see_my_account": "انظر حسابي", 
        "three_rd_login_merge_account": "لقد تم تسجيل عنوان البريد الإلكتروني لحساب الطرف الثالث، هل تريد الاتصال وتسجيل الدخول باستخدام عنوان البريد الإلكتروني هذا مباشرةً؟", 
        "connect_log_in": "الاتصال وتسجيل الدخول", 
        "create_an_account": "إنشاء حساب", 
        "back_to_log_in": "العودة إلى تسجيل الدخول", 
        "create_password": "إنشاء كلمة المرور", 
        "create_now": "إنشاء الآن", 
        "password_login_subtitle": "تسجيل الدخول بدون كلمة مرور باستخدام البريد الإلكتروني", 
        "account_login": "تسجيل الدخول إلى الحساب", 
        "rights": "من خلال إنشاء هذا الحساب، فإنك توافق على <a href=\"https://co.tipard.com/terms-of-use.html\">شروط الخدمة</a> و<a href=\"https://co.tipard.com/policy.html\">سياسة الخصوصية</a>", 
        "passwordless_login_done": "تم تسجيل الدخول بدون كلمة مرور", 
        "passwordless_login_info": "مبروك، لقد أكملت تسجيل الدخول بدون كلمة مرور بنجاح. يمكنك إنشاء كلمة مرور لهذا الحساب وتسجيل الدخول باستخدام الحساب وكلمة المرور في المستقبل. <a href=\"/create-password\" style=\"display: initial;\">إنشاء الآن</a>", 
        "sign_up": "اشتراك", 
        "register_info": "إنشاء حسابك", 
        "reset_now": "إعادة الضبط الآن", 
        "forgot_password": "هل نسيت كلمة السر", 
        "reset_password_subtitle": "استخدم بريدك الإلكتروني لحسابك لإعادة تعيين كلمة المرور", 
        "plan_products": "الخطط والمنتجات", 
        "nick_name": "اسم المستخدم:", 
        "email": "بريد إلكتروني:", 
        "my_products": "منتجاتي", 
        "my_orders": "طلبياتي", 
        "unlink": "إلغاء الارتباط", 
        "link": "وصلة", 
        "connected_accounts": "الحسابات المتصلة", 
        "last_name": "اسم العائلة:", 
        "first_name": "الاسم الأول:", 
        "Gender": "جنس:", 
        "Birth": "الولادة:", 
        "Month": "شهر", 
        "Year": "سنة", 
        "Country_Region": "البلد/المنطقة:", 
        "Address": "عنوان:", 
        "Save": "يحفظ", 
        "Date": "تاريخ", 
        "Male": "ذكر", 
        "Female": "أنثى", 
        "Unspecified": "غير محدد", 
        "Security": "حماية", 
        "change_password": "تغيير كلمة المرور", 
        "change_now": "تغير الآن", 
        "connect_email": "ربط البريد الإلكتروني", 
        "delete_account": "حذف الحساب", 
        "delete_account_info": "عند حذف حسابك، سيتم حذف جميع البيانات الموجودة في حسابي المرتبطة بحسابك بشكل دائم، وقد لا تتمكن من استردادها. نوصيك بالحذر.", 
        "Delete": "يمسح", 
        "Logout": "تسجيل الخروج", 
        "my_profile": "الملف الشخصي الخاص بي", 
        "guides_faqs": "الأدلة والأسئلة الشائعة", 
        "More": "أكثر", 
        "guides": "أدلة", 
        "register": "يسجل", 
        "hot_faq": "الأسئلة الشائعة الساخنة", 
        "Contents": "محتويات:", 
        "contact_us": "اتصل بنا>>", 
        "plan": "يخطط", 
        "unregistered": "غير مسجل", 
        "buy_more": "شراء المزيد", 
        "buy_again": "اشتري مرة أخرى", 
        "buy_now": "اشتري الآن", 
        "free_no_limit": "مجاني و بلا حدود", 
        "expired": "منتهي الصلاحية", 
        "lifetime": "حياة", 
        "remain": "يبقى", 
        "day_s": "أيام)", 
        "day": "يوم", 
        "error_24801": "فشل الطلب (رمز الخطأ: 24801). يرجى المحاولة مرة أخرى", 
        "no_app_found": "لم يتم العثور على التطبيق!<a href=\"/\">قم بالتحديث</a> أو <a href=\"https://co.tipard.com/\">انتقل إلى الموقع الرسمي</a>", 
        "get_more": "احصل على المزيد >>", 
        "edit_photo": "تعديل الصورة", 
        "select_photo": "حدد الصورة", 
        "change_photo": "تغيير الصورة", 
        "cancel": "يلغي", 
        "hide_password": "إخفاء كلمة المرور", 
        "show_password": "إظهار كلمة المرور", 
        "zoom_in": "تكبير الصورة", 
        "zoom_out": "تصغير الصورة", 
        "rotate": "تناوب", 
        "horizontal_flip": "انعكاس أفقي", 
        "vertical_flip": "انعكاس عمودي", 
        "country": "دولة", 
        "country_1": "حدد بلدك/منطقتك", 
        "country_2": "جزيرة آلاند", 
        "country_3": "أفغانستان", 
        "country_4": "ألبانيا", 
        "country_5": "الجزائر", 
        "country_6": "ساموا الأمريكية", 
        "country_7": "أندورا", 
        "country_8": "أنجولا", 
        "country_9": "أنغويلا", 
        "country_10": "القارة القطبية الجنوبية", 
        "country_11": "أنتيغوا وبربودا", 
        "country_12": "الأرجنتين", 
        "country_13": "أرمينيا", 
        "country_14": "أروبا", 
        "country_15": "أستراليا", 
        "country_16": "النمسا", 
        "country_17": "أذربيجان", 
        "country_18": "البحرين", 
        "country_19": "بنجلاديش", 
        "country_20": "بربادوس", 
        "country_21": "بيلاروسيا", 
        "country_22": "بلجيكا", 
        "country_23": "بليز", 
        "country_24": "بنين", 
        "country_25": "برمودا", 
        "country_26": "بوتان", 
        "country_27": "بوليفيا", 
        "country_28": "البوسنة والهرسك", 
        "country_29": "بوتسوانا", 
        "country_30": "جزيرة بوفيه", 
        "country_31": "البرازيل", 
        "country_32": "إقليم المحيط الهندي البريطاني", 
        "country_33": "جزر فيرجن البريطانية", 
        "country_34": "بروناي", 
        "country_35": "بلغاريا", 
        "country_36": "بوركينا فاسو", 
        "country_37": "بوروندي", 
        "country_38": "كمبوديا", 
        "country_39": "الكاميرون", 
        "country_40": "كندا", 
        "country_41": "الرأس الأخضر", 
        "country_42": "الكاريبي هولندا", 
        "country_43": "جزر كايمان", 
        "country_44": "جمهورية أفريقيا الوسطى", 
        "country_45": "تشاد", 
        "country_46": "تشيلي", 
        "country_47": "الصين", 
        "country_48": "جزيرة الكريسماس", 
        "country_49": "جزر كوكوس (كيلينغ)", 
        "country_50": "كولومبيا", 
        "country_51": "جزر كوك", 
        "country_52": "كوستاريكا", 
        "country_53": "ساحل العاج", 
        "country_54": "كرواتيا", 
        "country_55": "كوبا", 
        "country_56": "قبرص", 
        "country_57": "الجمهورية التشيكية", 
        "country_58": "جمهورية الكونغو الديمقراطية", 
        "country_59": "الدنمارك", 
        "country_60": "جيبوتي", 
        "country_61": "دومينيكا", 
        "country_62": "جمهورية الدومينيكان", 
        "country_63": "الإكوادور", 
        "country_64": "مصر", 
        "country_65": "السلفادور", 
        "country_66": "غينيا الاستوائية", 
        "country_67": "إريتريا", 
        "country_68": "استونيا", 
        "country_69": "أثيوبيا", 
        "country_70": "جزر فوكلاند", 
        "country_71": "جزر فارو", 
        "country_72": "ولايات ميكرونيسيا المتحدة", 
        "country_73": "فيجي", 
        "country_74": "فنلندا", 
        "country_75": "فرنسا", 
        "country_76": "غويانا الفرنسية", 
        "country_77": "بولينيزيا الفرنسية", 
        "country_78": "الأقاليم الجنوبية الفرنسية", 
        "country_79": "الجابون", 
        "country_80": "غامبيا", 
        "country_81": "جورجيا", 
        "country_82": "ألمانيا", 
        "country_83": "غانا", 
        "country_84": "جبل طارق", 
        "country_85": "بريطانيا العظمى (المملكة المتحدة؛ إنجلترا)", 
        "country_86": "اليونان", 
        "country_87": "جرينلاند", 
        "country_88": "غرينادا", 
        "country_89": "جوادلوب", 
        "country_90": "غوام", 
        "country_91": "غواتيمالا", 
        "country_92": "غيرنزي", 
        "country_93": "غينيا", 
        "country_94": "غينيا بيساو", 
        "country_95": "غيانا", 
        "country_96": "هايتي", 
        "country_97": "جزيرة هيرد وجزر ماكدونالد", 
        "country_98": "هندوراس", 
        "country_99": "هونج كونج", 
        "country_100": "هنغاريا", 
        "country_101": "أيسلندا", 
        "country_102": "الهند", 
        "country_103": "أندونيسيا", 
        "country_104": "إيران", 
        "country_105": "العراق", 
        "country_106": "أيرلندا", 
        "country_107": "جزيرة مان", 
        "country_108": "إسرائيل", 
        "country_109": "إيطاليا", 
        "country_110": "جامايكا", 
        "country_111": "اليابان", 
        "country_112": "جيرسي", 
        "country_113": "الأردن", 
        "country_114": "كازاخستان", 
        "country_115": "كينيا", 
        "country_116": "كيريباتي", 
        "country_117": "الكويت", 
        "country_118": "قرغيزستان", 
        "country_119": "لاوس", 
        "country_120": "لاتفيا", 
        "country_121": "لبنان", 
        "country_122": "ليسوتو", 
        "country_123": "ليبيريا", 
        "country_124": "ليبيا", 
        "country_125": "ليختنشتاين", 
        "country_126": "ليتوانيا", 
        "country_127": "لوكسمبورج", 
        "country_128": "ماكاو", 
        "country_129": "مدغشقر", 
        "country_130": "مالاوي", 
        "country_131": "ماليزيا", 
        "country_132": "جزر المالديف", 
        "country_133": "مالي", 
        "country_134": "مالطا", 
        "country_135": "جزر مارشال", 
        "country_136": "مارتينيك", 
        "country_137": "موريتانيا", 
        "country_138": "موريشيوس", 
        "country_139": "مايوت", 
        "country_140": "المكسيك", 
        "country_141": "مولدوفا", 
        "country_142": "موناكو", 
        "country_143": "منغوليا", 
        "country_144": "الجبل الأسود", 
        "country_145": "مونتسيرات", 
        "country_146": "المغرب", 
        "country_147": "موزمبيق", 
        "country_148": "ميانمار (بورما)", 
        "country_149": "ناميبيا", 
        "country_150": "ناورو", 
        "country_151": "نيبال", 
        "country_152": "هولندا", 
        "country_153": "كاليدونيا الجديدة", 
        "country_154": "نيوزيلندا", 
        "country_155": "نيكاراجوا", 
        "country_156": "النيجر", 
        "country_157": "نيجيريا", 
        "country_158": "نيوي", 
        "country_159": "جزيرة نورفولك", 
        "country_160": "كوريا الشمالية", 
        "country_161": "جزر ماريانا الشمالية", 
        "country_162": "النرويج", 
        "country_163": "عُمان", 
        "country_164": "باكستان", 
        "country_165": "بالاو", 
        "country_166": "الأراضي الفلسطينية", 
        "country_167": "بنما", 
        "country_168": "بابوا غينيا الجديدة", 
        "country_169": "باراغواي", 
        "country_170": "بيرو", 
        "country_171": "جزر بيتكيرن", 
        "country_172": "بولندا", 
        "country_173": "البرتغال", 
        "country_174": "بورتوريكو", 
        "country_175": "قطر", 
        "country_176": "جمهورية مقدونيا (FYROM)", 
        "country_177": "جمهورية الكونغو", 
        "country_178": "جمع شمل", 
        "country_179": "رومانيا", 
        "country_180": "الاتحاد الروسي", 
        "country_181": "رواندا", 
        "country_182": "سان بارتيليمي", 
        "country_183": "سان مارتن (فرنسا)", 
        "country_184": "سان بيير وميكلون", 
        "country_185": "ساموا", 
        "country_186": "سان مارينو", 
        "country_187": "ساو تومي وبرينسيبي", 
        "country_188": "المملكة العربية السعودية", 
        "country_189": "السنغال", 
        "country_190": "صربيا", 
        "country_191": "سيشل", 
        "country_192": "سيراليون", 
        "country_193": "سنغافورة", 
        "country_194": "سلوفاكيا", 
        "country_195": "سلوفينيا", 
        "country_196": "جزر سليمان", 
        "country_197": "الصومال", 
        "country_198": "جنوب افريقيا", 
        "country_199": "جورجيا الجنوبية وجزيرة ساندويتش الجنوبية", 
        "country_200": "كوريا الجنوبية", 
        "country_201": "جنوب السودان", 
        "country_202": "إسبانيا", 
        "country_203": "سريلانكا", 
        "country_204": "سانت هيلينا والتبعيات", 
        "country_205": "سانت كيتس ونيفيس", 
        "country_206": "سانت لوسيا", 
        "country_207": "سانت فنسنت وجزر غرينادين", 
        "country_208": "السودان", 
        "country_209": "سورينام", 
        "country_210": "سوازيلاند", 
        "country_211": "السويد", 
        "country_212": "سويسرا", 
        "country_213": "سوريا", 
        "country_214": "تايوان", 
        "country_215": "طاجيكستان", 
        "country_216": "تنزانيا", 
        "country_217": "قالب:بيانات الدولة SJM سفالبارد", 
        "country_218": "تايلاند", 
        "country_219": "جزر البهاما", 
        "country_220": "جزر القمر", 
        "country_221": "الفلبين", 
        "country_222": "تيمور الشرقية (تيمور الشرقية)", 
        "country_223": "توغو", 
        "country_224": "توكيلاو", 
        "country_225": "تونجا", 
        "country_226": "ترينيداد وتوباغو", 
        "country_227": "تونس", 
        "country_228": "ديك رومى", 
        "country_229": "تركمانستان", 
        "country_230": "جزر توركس وكايكوس", 
        "country_231": "توفالو", 
        "country_232": "اوغندا", 
        "country_233": "أوكرانيا", 
        "country_234": "الإمارات العربية المتحدة", 
        "country_235": "الجزر الصغيرة النائية التابعة للولايات المتحدة", 
        "country_236": "الولايات المتحدة الأمريكية (USA)", 
        "country_237": "جزر فيرجن الأمريكية", 
        "country_238": "أوروغواي", 
        "country_239": "أوزبكستان", 
        "country_240": "فانواتو", 
        "country_241": "مدينة الفاتيكان (الكرسي الرسولي)", 
        "country_242": "فنزويلا", 
        "country_243": "فيتنام", 
        "country_244": "واليس وفوتونا", 
        "country_245": "الصحراء الغربية", 
        "country_246": "اليمن", 
        "country_247": "زامبيا", 
        "country_248": "زيمبابوي", 
        "google_login": "تسجيل الدخول باستخدام جوجل", 
        "State": "ولاية", 
        "Activation_code": "رمز التفعيل", 
        "Question": "قم بإدراج جميع التطبيقات التي قمت بتسجيل الدخول إليها", 
        "Copy_complete": "نسخة كاملة", 
        "change_password_success": "تم تغيير كلمة المرور بنجاح", 
        "successful_login_title": "تم تسجيل الدخول بنجاح", 
        "product_page": "صفحة المنتج>>", 
        "successful_login_info": "تم تسجيل الدخول. يرجى إغلاق الصفحة الحالية والعودة إلى علامة التبويب الأصلية لمواصلة العملية التالية. سيتم إغلاق الصفحة الحالية تلقائيًا في غضون 5 ثوانٍ. إذا فشل الإغلاق التلقائي أو الإغلاق بالنقر فوق الزر \"تم\"، يرجى إغلاق علامة التبويب هذه مباشرةً.", 
        "successful_login_info_firefox": "تم تسجيل الدخول. يرجى إغلاق الصفحة الحالية والعودة إلى علامة التبويب الأصلية لمواصلة العملية التالية.", 
        "my_account": "حسابي", 
        "my_history": "تاريخي", 
        "remove_watermark": "إزالة العلامة المائية", 
        "no_history": "لا يوجد تاريخ", 
        "history_all": "حدد الكل", 
        "history_open": "يفتح", 
        "history_down": "تحميل", 
        "history_delete": "يمسح", 
        "history_clear": "مسح غير صالح", 
        "images": "الصورة(الصور)", 
        "use_this_function": "استخدم هذا المنتج>>", 
        "hd_downloading": "فوائد تنزيل الصور الأصلية عالية الدقة:", 
        "lifetimeRemaining": "المدة المتبقية من العمر", 
        "Remaining": "متبقي", 
        "email_verification": "التحقق من البريد الإلكتروني", 
        "email_verification_info": "لقد أرسلنا رسالة التحقق إلى بريدك الإلكتروني <span class=\"email\"></span>، ويرجى إكمال عملية التحقق. بعد التحقق، سيتم مزامنة المزايا تلقائيًا.", 
        "wrong_email": "عنوان البريد الإلكتروني خاطئ؟", 
        "click_here_to_modify": "انقر هنا للتعديل", 
        "get_more_help": "الحصول على مزيد من المساعدة؟", 
        "click_here": "انقر هنا", 
        "email_verification_info_success": "أهنئك على التحقق من حساب البريد الإلكتروني الخاص بك.", 
        "email_verification_info_error": "فشلت عملية التحقق بسبب انتهاء صلاحية الرابط.", 
        "registration_succeeded": "تم التسجيل بنجاح", 
        "registration_succeeded_info_1": "مبروك! لقد قمت بالتسجيل بنجاح. لقد أرسلنا رسالة التحقق إلى بريدك الإلكتروني <span class=\"email\"></span>، ويرجى إكمال عملية التحقق للاستفادة من مزايا هذا البريد الإلكتروني.", 
        "registration_succeeded_info_2": "انقر فوق \"تم\" للعودة إلى الصفحة الرئيسية واستخدام هذا المنتج.", 
        "registration_succeeded_info_3": "انقر فوق \"تم\" لإغلاق الصفحة الحالية والعودة إلى الصفحة الرئيسية لإجراء العمليات التالية. إذا فشل الإغلاق، يرجى إغلاق علامة التبويب يدويًا.", 
        "verify_email": "التحقق من البريد الإلكتروني", 
        "registered_email_not_verified": "لم يتم التحقق من البريد الإلكتروني المسجل، يرجى التحقق منه على الفور.", 
        "email_verification_time_1": "لم تتلق رسالة التحقق؟", 
        "email_verification_time_2": "بعد", 
        "email_verification_time_3": "انقر هنا لإعادة إرساله", 
        "error_26301": "رمز الخطأ: 26301، يرجى الاتصال بنا", 
        "error_26302": "رمز الخطأ: 26302، يرجى الاتصال بنا", 
        "error_26303": "خطأ في تنسيق البريد الإلكتروني (رمز الخطأ: 26303). يرجى إدخاله مرة أخرى", 
        "error_26304": "يوصى باستخدام كلمة مرور تتكون من 8 أحرف أو أكثر (رمز الخطأ: 26304)", 
        "error_26305": "فشل الطلب (رمز الخطأ: 26305). يرجى إعادة المحاولة", 
        "error_26306": "تم تسجيل البريد الإلكتروني، يرجى <a href=\"https://account.tipard.com/login\">الانتقال إلى تسجيل الدخول</a>", 
        "error_26307": "فشل الطلب (رمز الخطأ: 26307). يرجى إعادة المحاولة", 
        "error_26308": "فشل الطلب (رمز الخطأ: 26308). يرجى إعادة المحاولة", 
        "error_26401": "رمز الخطأ: 26401، يرجى إعادة المحاولة", 
        "error_26402": "تم التحقق من البريد الإلكتروني (رمز الخطأ: 26402)، يرجى إعادة المحاولة", 
        "error_26403": "فشل الطلب (رمز الخطأ: 26403). يرجى إعادة المحاولة", 
        "error_26404": "فشل الطلب (رمز الخطأ: 26404). يرجى إعادة المحاولة", 
        "error_26501": "رمز الخطأ: 26501، يرجى الاتصال بنا", 
        "error_26502": "رمز الخطأ: 26502، يرجى الاتصال بنا", 
        "error_26503": "خطأ في تنسيق البريد الإلكتروني (رمز الخطأ: 26503). يرجى إدخاله مرة أخرى", 
        "error_26504": "فشل الطلب (رمز الخطأ: 26504). يرجى إعادة المحاولة", 
        "error_26505": "لم يتم تسجيل البريد الإلكتروني، يرجى <a href=\"https://account.tipard.com/register\">تسجيله أولاً</a>", 
        "error_26506": "تم التحقق من البريد الإلكتروني.", 
        "error_26507": "فشل الطلب (رمز الخطأ: 26507). يرجى إعادة المحاولة", 
        "error_26508": "فشل التحقق (رمز الخطأ: 26508)، يرجى إعادة المحاولة", 
        "error_26509": "فشل الطلب (رمز الخطأ: 26509)، يرجى إعادة المحاولة", 
        "error_26510": "رمز الخطأ: 26510، يرجى الاتصال بنا", 
        "error_26601": "رمز الخطأ: 26601، يرجى الاتصال بنا", 
        "error_26602": "رمز الخطأ: 26602، يرجى الاتصال بنا", 
        "error_26603": "فشل الطلب (رمز الخطأ: 26603). يرجى إعادة المحاولة", 
        "error_26604": "رمز الخطأ: 26604، يرجى الاتصال بنا", 
        "error_26605": "رمز الخطأ: 26605، يرجى الاتصال بنا", 
        "error_26701": "رمز الخطأ: 26701، يرجى الاتصال بنا", 
        "error_26702": "فشل الطلب (رمز الخطأ: 26702). يرجى إعادة المحاولة", 
        "error_26703": "رمز الخطأ: 26703، يرجى الاتصال بنا", 
        "error_26704": "رمز الخطأ: 26704، يرجى الاتصال بنا", 
        "error_26705": "انتظر تسجيل الدخول (رمز الخطأ: 26705). يرجى إعادة المحاولة", 
        "no_cookie": "لقد قمت بتفعيل وظيفة حظر جميع ملفات تعريف الارتباط في متصفحك، لذا لا يمكنك تسجيل الدخول. يرجى الانتقال إلى الإعدادات لتحديد مربع السماح بجميع ملفات تعريف الارتباط.", 
        "error_26801": "رمز الخطأ: 26801، يرجى الاتصال بنا", 
        "error_26802": "رمز الخطأ: 26802، يرجى الاتصال بنا", 
        "error_26803": "فشل الطلب (رمز الخطأ: 26803). يرجى إعادة المحاولة", 
        "error_26804": "فشل الطلب (رمز الخطأ: 26804). يرجى إعادة المحاولة", 
        "error_order": "فشل الطلب (رمز الخطأ: 27098)، يرجى المحاولة مرة أخرى!", 
        "error_order1": "استعلام الطلب غير مكتمل (رمز الخطأ:", 
        "error_order2": "）, يرجى التحديث والمحاولة مرة أخرى.", 
        "modify_email_title": "تعديل البريد الإلكتروني", 
        "modify_email_info": "بإمكانك استخدام البريد الإلكتروني المعدل لتسجيل الدخول إلى حسابك.", 
        "images_per": "الصور لكل", 
        "error_26101": "خطأ: 26101. يرجى الاتصال بنا", 
        "error_26102": "خطأ: 26102. يرجى الاتصال بنا", 
        "error_26103": "فشل الطلب (رمز الخطأ: 26103). يرجى المحاولة مرة أخرى", 
        "error_26104": "رمز الخطأ: 26104، يرجى إعادة المحاولة", 
        "error_26105": "رمز الخطأ: 26105، يرجى إعادة المحاولة", 
        "error_26106": "فشل الحذف (رمز الخطأ: 26106). يرجى المحاولة مرة أخرى", 
        "error_26201": "خطأ: 26201. يرجى الاتصال بنا", 
        "error_26202": "فشل الطلب (رمز الخطأ: 26202). يرجى المحاولة مرة أخرى", 
        "error_26001": "خطأ: 26001. يرجى الاتصال بنا", 
        "error_26002": "خطأ: 26002. يرجى الاتصال بنا", 
        "error_26003": "خطأ: 26003. يرجى الاتصال بنا", 
        "error_26004": "خطأ: 26004. يرجى الاتصال بنا", 
        "error_26005": "فشل الطلب (رمز الخطأ: 26005). يرجى المحاولة مرة أخرى", 
        "error_26006": "رمز الخطأ: 26006، يرجى إعادة المحاولة", 
        "error_26008": "خطأ: 26008. يرجى الاتصال بنا", 
        "go_to_the_home_page": "انتقل إلى الصفحة الرئيسية", 
        "error_27101": "فشل الطلب (رمز الخطأ: 27101). يرجى إعادة المحاولة", 
        "error_27201": "رمز الخطأ: 27201، يرجى الاتصال بنا", 
        "error_27202": "رمز الخطأ: 27202، يرجى إعادة المحاولة", 
        "error_27203": "فشل الطلب (رمز الخطأ: 27203). يرجى إعادة المحاولة", 
        "error_27204": "رمز غير صالح (رمز الخطأ: 27204).", 
        "error_27205": "فشل الطلب (رمز الخطأ: 27205). يرجى إعادة المحاولة", 
        "error_27206": "فشل الطلب (رمز الخطأ: 27206). يرجى إعادة المحاولة", 
        "error_27207": "فشل الطلب (رمز الخطأ: 27207). يرجى إعادة المحاولة", 
        "no_history_found": "لم تستخدم أي أداة! <a href=\"/\">تحديث</a> أو <a href=\"https://co.tipard.com/\">الانتقال إلى الموقع الرسمي</a>", 
        "error_25301": "خطأ: 25301. يرجى الاتصال بنا", 
        "error_25302": "خطأ: 25302. يرجى الاتصال بنا", 
        "error_25303": "فشل الطلب (رمز الخطأ: 25303). يرجى المحاولة مرة أخرى", 
        "error_25304": "فشل الطلب (رمز الخطأ: 25304). يرجى المحاولة مرة أخرى", 
        "error_25305": "فشل الطلب (رمز الخطأ: 25305). يرجى المحاولة مرة أخرى", 
        "error_25306": "فشل الطلب (رمز الخطأ: 25306). يرجى المحاولة مرة أخرى", 
        "image_upscaler_p": "فوائد تنزيل الصورة بدون علامة مائية:", 
        "Available_for": "متاح ل:", 
        "credit_per": "%s رصيد(ات) لكل صورة عالية الدقة", 
        "still_valid": "الخطة التي تم شراؤها لا تزال صالحة", 
        "credit": "الائتمان(ات)", 
        "pc_3rd_info": "تم تسجيل الدخول بنجاح. يرجى الانتقال إلى التطبيق لإجراء المزيد من العمليات.", 
        "use_online": "استخدم الخدمة عبر الإنترنت", 
        "use_download": "استخدم برنامج سطح المكتب", 
        "use_immediately": "استخدمه فورًا", 
        "Use_in_browser": "الاستخدام في المتصفح", 
        "win_desktop": "نوافذ", 
        "Mac_desktop": "ماك", 
        "credits_per": "{%} رصيد شهريًا", 
        "expire": "وقت انتهاء الصلاحية:", 
        "viewDetails": "عرض التفاصيل", 
        "viewHistory": "عرض تاريخ الفوائد>>", 
        "viewDetailsInfo": "ملاحظة: إذا تم تجديد مزايا الاشتراك خلال 7 أيام من انتهاء صلاحيتها، يمكن الاستمرار في استخدام المزايا غير المستخدمة. كما سيتم تحديث وقت انتهاء الصلاحية تلقائيًا إلى وقت انتهاء صلاحية الاشتراك الجديد. إذا لم يكن هناك اشتراك جديد بعد 7 أيام من انتهاء الصلاحية، فسيتم مسح جميع المزايا منتهية الصلاحية.", 
        "connect_account": "ربط البريد الإلكتروني بحسابك", 
        "connect_account_info": "بعد الربط، يمكنك تسجيل الدخول باستخدام عنوان البريد الإلكتروني هذا.", 
        "connect_now": "ربط الآن", 
        "no_email_bind": "لا يوجد ربط بالبريد الإلكتروني", 
        "bind_email": "ربط البريد الإلكتروني", 
        "connect_your_email_placeholder": "الرجاء إدخال عنوان بريدك الإلكتروني", 
        "bind_an_email": "ربط البريد الإلكتروني", 
        "bind_info": "لقد قمت بتسجيل الدخول بنجاح. يرجى ربط بريد إلكتروني بحسابك لتفعيل المزايا الخاصة بك.", 
        "bind_later": "ربط في وقت لاحق", 
        "footer": "جميع الحقوق محفوظة © 2024 لشركة Tipard Studio.", 
        "overtime": "رمز الخطأ: {%}، يرجى إعادة المحاولة", 
        "hi": "له!", 
        "Personal_Information": "معلومات شخصية", 
        "Access": "وصول", 
        "Subscription_Plan": "(خطة الاشتراك)", 
        "No_orders": "لم يتم العثور على أي طلبات.", 
        "No_data": "لا يوجد بيانات", 
        "Featured_Products": "المنتجات المميزة", 
        "More_Products": "المزيد من المنتجات", 
        "Free_Download": "تنزيل مجاني", 
        "Get_Started": "البدء", 
        "Subscribe": "يشترك", 
        "Verified": "تم التحقق منه", 
        "back_to_account_center": "العودة إلى مركز الحساب", 
        "success": "نجاح!", 
        "successfully": "لقد قمت بتسجيل حساب بنجاح.", 
        "Continue": "يكمل", 
        "Already": "هل لديك حساب بالفعل؟", 
        "loading_verification": "جاري التحقق من حالة التحقق...", 
        "email_no_verification": "عذرا، لم يتم التحقق من عنوان البريد الإلكتروني المسجل. يرجى إكمال عملية التحقق وفقًا للتعليمات المذكورة أعلاه والنقر فوق الزر \"تم\" مرة أخرى لإكمال التسجيل.", 
        "error_20505": "تم ربط البريد الإلكتروني", 
        "error_20506": "خطأ: 20506. يرجى الاتصال بنا", 
        "error_28401": "الرجاء إدخال بريدك الإلكتروني", 
        "error_28402": "تنسيق البريد الإلكتروني غير صحيح", 
        "error_28403": "خطأ: 28403. يرجى الاتصال بنا", 
        "error_28404": "تم تسجيل البريد الإلكتروني", 
        "error_28405": "خطأ: 28405. يرجى الاتصال بنا", 
        "error_28406": "تم ربط البريد الإلكتروني", 
        "error_28407": "خطأ: 28407. يرجى الاتصال بنا", 
        "error_28408": "فشل في إرسال البريد الإلكتروني", 
        "error_28601": "الرجاء إدخال بريدك الإلكتروني", 
        "error_28602": "الرجاء إدخال رمز التحقق", 
        "error_28603": "تنسيق البريد الإلكتروني غير صحيح", 
        "error_28604": "خطأ: 28604. يرجى الاتصال بنا", 
        "error_28605": "رمز التحقق غير صحيح", 
        "error_28606": "خطأ: 28606. يرجى الاتصال بنا", 
        "error_28607": "تم تسجيل البريد الإلكتروني", 
        "error_28608": "لا يوجد بريد إلكتروني مرتبط بحسابك", 
        "error_28609": "لم يتم التحقق من البريد الإلكتروني", 
        "error_28610": "خطأ: 28610. يرجى الاتصال بنا", 
        "error_28611": "تم ربط البريد الإلكتروني", 
        "error_28612": "خطأ: 28612. يرجى الاتصال بنا", 
        "unbind_email_btn": "إلغاء ربط البريد الإلكتروني", 
        "binding_exchange": "ربط البريد الإلكتروني", 
        "Subscription_Status": "حالة الاشتراك", 
        "mask_unbind_info_1": "هل أنت متأكد من إلغاء ربط {%} بهذا الحساب؟ بعد إلغاء الربط، لن تتمكن من التحقق من مزايا هذا البريد الإلكتروني بعد الآن", 
        "mask_unbind_info_2": "هل أنت متأكد من أنك تريد إلغاء ربط جميع أجهزة الكمبيوتر المرتبطة برمز التسجيل هذا؟<a href=\"https://ar.tipard.com/faq.html#unbind\">تعرف على المزيد حول تغيير الأجهزة.</a>", 
        "error_dc_link": "خطأ، يرجى الاتصال بنا", 
        "vcu_state": "فوائد النسخة الكاملة:", 
        "monthly_subscription": "رخصة لمدة شهر", 
        "year_subscription": "رخصة لمدة سنة واحدة", 
        "to": "ل", 
        "lifetime_subscription": "ترخيص مدى الحياة", 
        "unbind": "يتأكد", 
        "unbind_success": "تم فك الارتباط بنجاح", 
        "error_28702": "خطأ: 28702. يرجى الاتصال بنا", 
        "error_28703": "رمز التحقق غير صحيح", 
        "error_28704": "خطأ: 28704. يرجى الاتصال بنا", 
        "error_28705": "لم يتم ربط البريد الإلكتروني", 
        "error_28706": "الرجاء إدخال رمز التحقق", 
        "error_28707": "فشل في إلغاء الارتباط", 
        "error_28901": "خطأ: 28901. يرجى الاتصال بنا", 
        "error_28902": "خطأ: 28902. يرجى الاتصال بنا", 
        "error_28903": "خطأ: 28903. يرجى الاتصال بنا", 
        "error_28904": "خطأ: 28904. يرجى الاتصال بنا", 
        "error_28905": "خطأ: 28905. يرجى الاتصال بنا", 
        "error_28906": "خطأ: 28906. يرجى الاتصال بنا",
        "error_28907": "رمز التسجيل غير صالح", 
        "error_28908": "خطأ: 28908. يرجى الاتصال بنا",
        "error_28909": "في الوقت الحالي، في فترة التهدئة لفك الارتباط. لا يمكنك فك الارتباط إلا مرة واحدة شهريًا.",
        "error_289010": "خطأ: 289010. يرجى الاتصال بنا",
        "error_289011": "خطأ: 289011. يرجى الاتصال بنا",
        "error_289012": "خطأ: 289012. يرجى الاتصال بنا",
        "activated_computer": "تم التنشيط على %s/%s من أجهزة الكمبيوتر", 
        "bind_email_info": "سيتم ربط واستخدام فوائد رسائل البريد الإلكتروني التالية ضمن حساب VIP هذا.",
        "error_26309": "خطأ: 26309. يرجى الاتصال بنا", 
        "error_26310": "رمز الإحالة غير صالح",
        "error_26311": "خطأ: 26311. يرجى الاتصال بنا", 
        "error_26312": "رمز الإحالة غير صالح",
        "error_26313": "خطأ: 26313. يرجى الاتصال بنا", 
        "error_26314": "تم ربط البريد الإلكتروني", 
        "activate_unbinding": "تغيير الجهاز",
        "copy_registration_code": "نسخ رمز التسجيل", 
        "show_registration_code": "إظهار رمز التسجيل", 
        "hide_registration_code": "إخفاء رمز التسجيل",
        "home": "بيت", 
        "points_balance": "رصيد النقاط:", 
        "earn_now": "اكسب الآن >>",
        "earn_more": "اكسب المزيد من النقاط المجانية، واستبدل المزيد من الهدايا", 
        "earn_or_redeem_points": "كسب/استرداد النقاط",
        "unbind_email_info": "تم ربط الحساب الحالي بعنوان بريد إلكتروني", 
        "bind_email_title": "إذا كنت تريد ربط المزيد من الطلبات بعناوين البريد الإلكتروني للشراء، يرجى النقر فوق الزر \"ربط البريد الإلكتروني\" أدناه للربط.", 
        "bind_email_tip": "<span>نصيحة:</span> في الوقت الحالي، يتم دعم عمليات شراء أوامر رمز تسجيل البريد الإلكتروني فقط.",
        "email_association": "جمعية البريد الإلكتروني", 
        "dissociation": "انفصال",
        "back": "خلف", 
        "activate_benefits": "الرجاء إدخال بريد الشراء الإلكتروني المطلوب ربطه للحصول على رمز التحقق. أدخل رمز التحقق الذي تلقيته في البريد الإلكتروني في مربع إدخال رمز التحقق، وانقر على زر \"التحقق من التنشيط\" لتفعيل المزايا.",
        "e_mail": "بريد إلكتروني:", 
        "verification_code": "رمز التحقق:", 
        "obtain_verification_code": "الحصول على رمز التحقق",
        "verification_association": "التحقق من الارتباط", 
        "current_password": "كلمة المرور الحالية:",
        "account_center": "مركز الحسابات", 
        "confirm_password": "تأكيد كلمة المرور الجديدة:", 
        "Send": "يرسل", 
        "history_files": "ملفات التاريخ",
        "delete_account_info_1": "1. حذف حساب Tipard الخاص بك هو إجراء دائم ولا يمكن التراجع عنه. ستفقد جميع بياناتك، بما في ذلك الصور والتفضيلات. هل أنت متأكد من حذف حساب Tipard الخاص بك؟", 
        "delete_account_info_2": "2. إذا قررت حذف حساب Tipard، يرجى النقر فوق زر الإرسال وإدخال رمز التحقق من البريد الإلكتروني الذي أرسلناه إليك.",
        "best_deals": "أفضل العروض",
        "close": "يغلق",
        "product":"منتج:",
        "other_binding_email": "رسائل البريد الإلكتروني الملزمة الأخرى"
    }
};