var i18n_private = {
  "en": {
      "video": "Video<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Utility<i></i>",
      "pdf_tools": "PDF Tools<i></i>",
      "free_tools": "Free Tools<i></i>",
      "other_tools": "Other Tools<i></i>",
      "free_online": "Free Online<i></i>",
      "desktop": "Desktop<i></i>",
      "store": "Tipard Store",
      "resource": "Resource",
      "points": "Points:",
      "redeem": "Redeem",
      "invite_friends_get_more_points": "Invite Friends & Get More Points",
      "rewards_center": "Rewards Center",
      "windows_software": "Windows Software<i></i>",
      "mac_software": "Mac Software<i></i>",
      "product_1": "Video Converter Ultimate",
      "product_2": "Mac Video Converter Ultimate",
      "product_3": "Total Media Converter",
      "product_4": "Total Media Converter for Mac",
      "product_5": "Video Converter",
      "product_6": "Video Converter for Mac",
      "product_7": "4K UHD Converter",
      "product_8": "4K Video Converter for Mac",
      "product_9": "Video Enhancer",
      "product_10": "Mac Video Enhancer",
      "product_11": "Screen Capture",
      "product_12": "Screen Capture for Mac",
      "product_13": "3D Converter",
      "product_14": "3D Converter for Mac",
      "product_15": "TS Converter",
      "product_16": "TS Converter for Mac",
      "product_17": "Screen Capture Pro",
      "product_18": "PPT to Video Converter",
      "product_19": "FixMP4 - Video Repair",
      "product_20": "FixMP4 - Video Repair for Mac",
      "product_21": "DVD Ripper",
      "product_22": "DVD Ripper for Mac",
      "product_23": "DVD Creator",
      "product_24": "DVD Creator for Mac",
      "product_25": "DVD Cloner",
      "product_26": "DVD Cloner for Mac",
      "product_27": "Blu-ray Converter",
      "product_28": "Blu-ray Converter for Mac",
      "product_29": "Blu-ray Player",
      "product_30": "Blu-ray Player for Mac",
      "product_31": "Blu-ray Creator",
      "product_32": "Blu-ray Copy",
      "product_33": "Blu-ray Toolkit",
      "product_34": "PDF Converter Platinum",
      "product_35": "PDF to Word Converter",
      "product_36": "PDF Cutter",
      "product_37": "PDF Joiner",
      "product_38": "Windows Password Reset",
      "product_39": "Data Recovery",
      "product_40": "iPhone Transfer",
      "product_41": "iOS Data Recovery",
      "product_42": "Android Data Recovery",
      "product_43": "iPhone Eraser",
      "product_44": "Watermark Remover Free Online",
      "product_45": "Free Video Editor",
      "product_46": "Free DVD to MP3 Converter",
      "product_47": "Free PDF to BMP Converter",
      "download_center": "Download Center",
      "company": "Company",
      "about": "About",
      "privacy_policy": "Privacy Policy",
      "terms_of_use": "Terms of Use",
      "affiliate": "Affiliate",
      "support_center": "Support Center",
      "contact_us": "Contact Us",
      "support": "Support",
      "hot_products": "Hot Products",
      "follow_us": "Follow Us",
      "change_language": "Change Language"
  },
  "fr": {
      "video": "Vidéo<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Utilitaire<i></i>",
      "pdf_tools": "Outils PDF<i></i>",
      "free_tools": "Outils gratuits<i></i>",
      "other_tools": "Autres outils<i></i>",
      "free_online": "Gratuit en ligne<i></i>",
      "desktop": "Bureau<i></i>",
      "store": "Magasin Tipard",
      "resource": "Ressource",
      "points": "Points:",
      "redeem": "Racheter",
      "invite_friends_get_more_points": "Invitez des amis et obtenez plus de points",
      "rewards_center": "Centre de récompenses",
      "windows_software": "Logiciel Windows<i></i>",
      "mac_software": "Logiciel Mac<i></i>",
      "product_1": "Convertisseur Vidéo Ultimate",
      "product_2": "Convertisseur vidéo ultime pour Mac",
      "product_3": "Convertisseur de média total",
      "product_4": "Total Media Converter pour Mac",
      "product_5": "Convertisseur vidéo",
      "product_6": "Convertisseur vidéo pour Mac",
      "product_7": "Convertisseur 4K UHD",
      "product_8": "Convertisseur vidéo 4K pour Mac",
      "product_9": "Amplificateur vidéo",
      "product_10": "Optimiseur de vidéo pour Mac",
      "product_11": "Capture d'écran",
      "product_12": "Capture d'écran pour Mac",
      "product_13": "Convertisseur 3D",
      "product_14": "Convertisseur 3D pour Mac",
      "product_15": "Convertisseur TS",
      "product_16": "Convertisseur TS pour Mac",
      "product_17": "Capture d'écran Pro",
      "product_18": "Convertisseur PPT en vidéo",
      "product_19": "FixMP4 - Réparation vidéo",
      "product_20": "FixMP4 – Réparation vidéo pour Mac",
      "product_21": "Extracteur de DVD",
      "product_22": "Extracteur de DVD pour Mac",
      "product_23": "Créateur de DVD",
      "product_24": "Créateur de DVD pour Mac",
      "product_25": "Cloneur de DVD",
      "product_26": "Cloneur de DVD pour Mac",
      "product_27": "Convertisseur Blu-ray",
      "product_28": "Convertisseur Blu-ray pour Mac",
      "product_29": "Lecteur Blu-ray",
      "product_30": "Lecteur Blu-ray pour Mac",
      "product_31": "Créateur de Blu-ray",
      "product_32": "Copie Blu-ray",
      "product_33": "Boîte à outils Blu-ray",
      "product_34": "Convertisseur PDF Platinum",
      "product_35": "Convertisseur PDF en Word",
      "product_36": "Découpeur PDF",
      "product_37": "PDF Joiner",
      "product_38": "Réinitialisation du mot de passe Windows",
      "product_39": "Récupération de données",
      "product_40": "Transfert iPhone",
      "product_41": "Récupération de données iOS",
      "product_42": "Récupération de données Android",
      "product_43": "Gomme pour iPhone",
      "product_44": "Suppression de filigrane gratuite en ligne",
      "product_45": "Éditeur vidéo gratuit",
      "product_46": "Convertisseur DVD en MP3 gratuit",
      "product_47": "Convertisseur PDF en BMP gratuit",
      "download_center": "Centre de téléchargement",
      "company": "Entreprise",
      "about": "À propos",
      "privacy_policy": "politique de confidentialité",
      "terms_of_use": "Conditions d'utilisation",
      "affiliate": "Filiale",
      "support_center": "Centre d'assistance",
      "contact_us": "Contactez-nous",
      "support": "Soutien",
      "hot_products": "Produits phares",
      "follow_us": "Suivez-nous",
      "change_language": "Changer la langue"
  },
  "ja": {
      "video": "ビデオ<i></i>",
      "blu_ray_dvd": "ブルーレイ/DVD<i></i>",
      "utility": "ユーティリティ<i></i>",
      "pdf_tools": "PDFツール<i></i>",
      "free_tools": "無料ツール<i></i>",
      "other_tools": "その他のツール<i></i>",
      "free_online": "無料オンライン<i></i>",
      "desktop": "デスクトップ<i></i>",
      "store": "ティパードストア",
      "resource": "リソース",
      "points": "ポイント:",
      "redeem": "償還",
      "invite_friends_get_more_points": "友達を招待してポイントをゲット",
      "rewards_center": "リワードセンター",
      "windows_software": "Windows ソフトウェア<i></i>",
      "mac_software": "Mac ソフトウェア<i></i>",
      "product_1": "動画変換 究極",
      "product_2": "Mac ビデオコンバーター 究極",
      "product_3": "トータルメディアコンバータ",
      "product_4": "Mac用トータルメディアコンバータ",
      "product_5": "ビデオコンバーター",
      "product_6": "Mac用ビデオコンバーター",
      "product_7": "4K UHDコンバータ",
      "product_8": "Mac用4Kビデオコンバーター",
      "product_9": "ビデオエンハンサー",
      "product_10": "Mac ビデオ エンハンサー",
      "product_11": "スクリーンキャプチャ",
      "product_12": "Mac用スクリーンキャプチャ",
      "product_13": "3Dコンバーター",
      "product_14": "Mac用3Dコンバーター",
      "product_15": "TSコンバータ",
      "product_16": "Mac用TSコンバータ",
      "product_17": "スクリーンキャプチャプロ",
      "product_18": "PPTからビデオへのコンバーター",
      "product_19": "FixMP4 - ビデオ修復",
      "product_20": "FixMP4 - Mac用ビデオ修復",
      "product_21": "DVDリッパー",
      "product_22": "Mac用DVDリッパー",
      "product_23": "DVD クリエーター",
      "product_24": "Mac用DVDクリエーター",
      "product_25": "DVD クローナー",
      "product_26": "Mac用DVDクローナー",
      "product_27": "ブルーレイコンバーター",
      "product_28": "Mac用ブルーレイコンバータ",
      "product_29": "ブルーレイプレーヤー",
      "product_30": "Mac用ブルーレイプレーヤー",
      "product_31": "ブルーレイ クリエーター",
      "product_32": "ブルーレイコピー",
      "product_33": "ブルーレイツールキット",
      "product_34": "PDFコンバータープラチナ",
      "product_35": "PDFからWordへのコンバーター",
      "product_36": "PDFカッター",
      "product_37": "PDF ジョイナー",
      "product_38": "Windows パスワードのリセット",
      "product_39": "データ復旧",
      "product_40": "iPhone転送",
      "product_41": "iOSデータ復旧",
      "product_42": "Android データ復旧",
      "product_43": "iPhone 消しゴム",
      "product_44": "透かし除去無料オンライン",
      "product_45": "無料ビデオエディタ",
      "product_46": "無料のDVDからMP3へのコンバーター",
      "product_47": "無料の PDF から BMP へのコンバーター",
      "download_center": "ダウンロードセンター",
      "company": "会社",
      "about": "について",
      "privacy_policy": "プライバシーポリシー",
      "terms_of_use": "利用規約",
      "affiliate": "アフィリエイト",
      "support_center": "サポートセンター",
      "contact_us": "お問い合わせ",
      "support": "サポート",
      "hot_products": "人気商品",
      "follow_us": "私たちに従ってください",
      "change_language": "言語を変更"
  },
  "es": {
      "video": "Video<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Utilidad<i></i>",
      "pdf_tools": "Herramientas PDF<i></i>",
      "free_tools": "Herramientas gratuitas<i></i>",
      "other_tools": "Otras herramientas<i></i>",
      "free_online": "Gratis en línea<i></i>",
      "desktop": "De oficina<i></i>",
      "store": "Tienda Tipard",
      "resource": "Recurso",
      "points": "Agujas:",
      "redeem": "Canjear",
      "invite_friends_get_more_points": "Invita a tus amigos y obtén más puntos",
      "rewards_center": "Centro de recompensas",
      "windows_software": "Software de Windows<i></i>",
      "mac_software": "Software para Mac<i></i>",
      "product_1": "Video Converter Ultimate",
      "product_2": "Convertidor de vídeo definitivo para Mac",
      "product_3": "Convertidor de medios total",
      "product_4": "Convertidor multimedia total para Mac",
      "product_5": "Convertidor de vídeo",
      "product_6": "Convertidor de vídeo para Mac",
      "product_7": "Convertidor 4K UHD",
      "product_8": "Convertidor de vídeo 4K para Mac",
      "product_9": "Mejorador de vídeo",
      "product_10": "Mejorador de vídeo para Mac",
      "product_11": "Captura de pantalla",
      "product_12": "Captura de pantalla para Mac",
      "product_13": "Convertidor 3D",
      "product_14": "Convertidor 3D para Mac",
      "product_15": "Convertidor TS",
      "product_16": "Convertidor TS para Mac",
      "product_17": "Captura de pantalla profesional",
      "product_18": "Convertidor de PPT a vídeo",
      "product_19": "FixMP4 - Reparación de videos",
      "product_20": "FixMP4 - Reparación de videos para Mac",
      "product_21": "Extractor de DVD",
      "product_22": "Extractor de DVD para Mac",
      "product_23": "Creador de DVD",
      "product_24": "Creador de DVD para Mac",
      "product_25": "Clonador de DVD",
      "product_26": "Clonador de DVD para Mac",
      "product_27": "Convertidor de Blu-ray",
      "product_28": "Convertidor de Blu-ray para Mac",
      "product_29": "Reproductor de Blu-ray",
      "product_30": "Reproductor de Blu-ray para Mac",
      "product_31": "Creador de Blu-ray",
      "product_32": "Copia de Blu-ray",
      "product_33": "Kit de herramientas para Blu-ray",
      "product_34": "Convertidor de PDF Platino",
      "product_35": "Convertidor de PDF a Word",
      "product_36": "Cortador de PDF",
      "product_37": "Unificador de PDF",
      "product_38": "Restablecimiento de contraseña de Windows",
      "product_39": "Recuperación de datos",
      "product_40": "Transferencia de iPhone",
      "product_41": "Recuperación de datos de iOS",
      "product_42": "Recuperación de datos de Android",
      "product_43": "Borrador de iPhone",
      "product_44": "Eliminador de marcas de agua en línea gratis",
      "product_45": "Editor de vídeo gratuito",
      "product_46": "Convertidor gratuito de DVD a MP3",
      "product_47": "Convertidor gratuito de PDF a BMP",
      "download_center": "Centro de descargas",
      "company": "Compañía",
      "about": "Acerca de",
      "privacy_policy": "política de privacidad",
      "terms_of_use": "Condiciones de uso",
      "affiliate": "Filial",
      "support_center": "Centro de soporte",
      "contact_us": "Contáctenos",
      "support": "Apoyo",
      "hot_products": "Productos destacados",
      "follow_us": "Síganos",
      "change_language": "Cambiar idioma"
  },
  "pt": {
      "video": "Vídeo<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Utilitário<i></i>",
      "pdf_tools": "Ferramentas PDF<i></i>",
      "free_tools": "Ferramentas gratuitas<i></i>",
      "other_tools": "Outras ferramentas<i></i>",
      "free_online": "Grátis Online<i></i>",
      "desktop": "Área de trabalho<i></i>",
      "store": "Loja Tipard",
      "resource": "Recurso",
      "points": "Pontos:",
      "redeem": "Resgatar",
      "invite_friends_get_more_points": "Convide amigos e ganhe mais pontos",
      "rewards_center": "Centro de recompensas",
      "windows_software": "Software Windows<i></i>",
      "mac_software": "Software para Mac<i></i>",
      "product_1": "Conversor de vídeo Ultimate",
      "product_2": "Conversor de vídeo para Mac Ultimate",
      "product_3": "Conversor de mídia total",
      "product_4": "Total Media Converter para Mac",
      "product_5": "Conversor de vídeo",
      "product_6": "Conversor de vídeo para Mac",
      "product_7": "Conversor 4K UHD",
      "product_8": "Conversor de vídeo 4K para Mac",
      "product_9": "Aprimorador de vídeo",
      "product_10": "Melhorador de vídeo para Mac",
      "product_11": "Captura de tela",
      "product_12": "Captura de tela para Mac",
      "product_13": "Conversor 3D",
      "product_14": "Conversor 3D para Mac",
      "product_15": "Conversor TS",
      "product_16": "Conversor TS para Mac",
      "product_17": "Captura de tela profissional",
      "product_18": "Conversor PPT para vídeo",
      "product_19": "FixMP4 - Reparo de Vídeo",
      "product_20": "FixMP4 - Reparo de vídeo para Mac",
      "product_21": "Ripador de DVD",
      "product_22": "DVD Ripper para Mac",
      "product_23": "Criador de DVD",
      "product_24": "Criador de DVD para Mac",
      "product_25": "Clonador de DVD",
      "product_26": "DVD Cloner para Mac",
      "product_27": "Conversor Blu-ray",
      "product_28": "Conversor Blu-ray para Mac",
      "product_29": "Leitor de Blu-ray",
      "product_30": "Leitor de Blu-ray para Mac",
      "product_31": "Criador de Blu-ray",
      "product_32": "Cópia Blu-ray",
      "product_33": "Kit de ferramentas Blu-ray",
      "product_34": "Conversor PDF Platinum",
      "product_35": "Conversor de PDF para Word",
      "product_36": "Cortador de PDF",
      "product_37": "Juntador de PDF",
      "product_38": "Redefinição de senha do Windows",
      "product_39": "Recuperação de dados",
      "product_40": "Transferência do iPhone",
      "product_41": "Recuperação de dados iOS",
      "product_42": "Recuperação de dados do Android",
      "product_43": "Borracha para iPhone",
      "product_44": "Removedor de marca d'água grátis online",
      "product_45": "Editor de vídeo grátis",
      "product_46": "Conversor de DVD para MP3 grátis",
      "product_47": "Conversor gratuito de PDF para BMP",
      "download_center": "Centro de download",
      "company": "Empresa",
      "about": "Sobre",
      "privacy_policy": "política de Privacidade",
      "terms_of_use": "Termos de Uso",
      "affiliate": "Afiliado",
      "support_center": "Centro de Suporte",
      "contact_us": "Contate-nos",
      "support": "Apoiar",
      "hot_products": "Produtos quentes",
      "follow_us": "Siga-nos",
      "change_language": "Alterar idioma"
  },
  "it": {
      "video": "Video<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Utilità<i></i>",
      "pdf_tools": "Strumenti PDF<i></i>",
      "free_tools": "Strumenti gratuiti<i></i>",
      "other_tools": "Altri strumenti<i></i>",
      "free_online": "Gratis online<i></i>",
      "desktop": "Scrivania<i></i>",
      "store": "Negozio Tipard",
      "resource": "Risorsa",
      "points": "Punti:",
      "redeem": "Riscattare",
      "invite_friends_get_more_points": "Invita gli amici e ottieni più punti",
      "rewards_center": "Centro premi",
      "windows_software": "Software per Windows<i></i>",
      "mac_software": "Software per Mac<i></i>",
      "product_1": "Convertitore video definitivo",
      "product_2": "Convertitore video per Mac Ultimate",
      "product_3": "Convertitore multimediale totale",
      "product_4": "Total Media Converter per Mac",
      "product_5": "Convertitore video",
      "product_6": "Convertitore video per Mac",
      "product_7": "Convertitore 4K UHD",
      "product_8": "Convertitore video 4K per Mac",
      "product_9": "Miglioratore video",
      "product_10": "Ottimizzatore video per Mac",
      "product_11": "Cattura dello schermo",
      "product_12": "Cattura schermo per Mac",
      "product_13": "Convertitore 3D",
      "product_14": "Convertitore 3D per Mac",
      "product_15": "Convertitore TS",
      "product_16": "Convertitore TS per Mac",
      "product_17": "Cattura schermo Pro",
      "product_18": "Convertitore da PPT a Video",
      "product_19": "FixMP4 - Riparazione video",
      "product_20": "FixMP4 - Riparazione video per Mac",
      "product_21": "Ripper per DVD",
      "product_22": "Ripper DVD per Mac",
      "product_23": "Creatore di DVD",
      "product_24": "Creatore DVD per Mac",
      "product_25": "Clonatore DVD",
      "product_26": "Clonatore DVD per Mac",
      "product_27": "Convertitore Blu-ray",
      "product_28": "Convertitore Blu-ray per Mac",
      "product_29": "Lettore Blu-ray",
      "product_30": "Lettore Blu-ray per Mac",
      "product_31": "Creatore di Blu-ray",
      "product_32": "Copia Blu-ray",
      "product_33": "Kit di strumenti Blu-ray",
      "product_34": "Convertitore PDF Platino",
      "product_35": "Convertitore da PDF a Word",
      "product_36": "Taglierina PDF",
      "product_37": "Unire PDF",
      "product_38": "Reimpostazione password di Windows",
      "product_39": "Recupero dati",
      "product_40": "Trasferimento iPhone",
      "product_41": "Recupero dati iOS",
      "product_42": "Recupero dati Android",
      "product_43": "Gomma per iPhone",
      "product_44": "Rimozione filigrana gratuita online",
      "product_45": "Editor video gratuito",
      "product_46": "Convertitore gratuito da DVD a MP3",
      "product_47": "Convertitore gratuito da PDF a BMP",
      "download_center": "Centro download",
      "company": "Azienda",
      "about": "Di",
      "privacy_policy": "politica sulla riservatezza",
      "terms_of_use": "Termini di utilizzo",
      "affiliate": "Affiliato",
      "support_center": "Centro di supporto",
      "contact_us": "Contattaci",
      "support": "Supporto",
      "hot_products": "Prodotti caldi",
      "follow_us": "Seguici",
      "change_language": "Cambia lingua"
  },
  "ru": {
      "video": "Видео<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Утилита<i></i>",
      "pdf_tools": "PDF-инструменты<i></i>",
      "free_tools": "Бесплатные инструменты<i></i>",
      "other_tools": "Другие инструменты<i></i>",
      "free_online": "Бесплатно онлайн<i></i>",
      "desktop": "Рабочий стол<i></i>",
      "store": "Магазин Типард",
      "resource": "Ресурс",
      "points": "Очки:",
      "redeem": "Выкупать",
      "invite_friends_get_more_points": "Пригласите друзей и получите больше очков",
      "rewards_center": "Центр вознаграждений",
      "windows_software": "Программное обеспечение Windows<i></i>",
      "mac_software": "Программное обеспечение для Mac<i></i>",
      "product_1": "Видео Конвертер Ultimate",
      "product_2": "Mac Video Converter Ultimate",
      "product_3": "Тотальный Медиа Конвертер",
      "product_4": "Total Media Converter для Mac",
      "product_5": "Видео Конвертер",
      "product_6": "Видеоконвертер для Mac",
      "product_7": "Конвертер 4K UHD",
      "product_8": "Конвертер видео 4K для Mac",
      "product_9": "Видеоусилитель",
      "product_10": "Улучшатель видео для Mac",
      "product_11": "Снимок экрана",
      "product_12": "Захват экрана для Mac",
      "product_13": "3D-конвертер",
      "product_14": "3D-конвертер для Mac",
      "product_15": "Конвертер TS",
      "product_16": "Конвертер TS для Mac",
      "product_17": "Профессиональный захват экрана",
      "product_18": "Конвертер PPT в видео",
      "product_19": "FixMP4 - Ремонт видео",
      "product_20": "FixMP4 — восстановление видео для Mac",
      "product_21": "DVD-риппер",
      "product_22": "DVD-риппер для Mac",
      "product_23": "Создатель DVD",
      "product_24": "Создатель DVD для Mac",
      "product_25": "DVD-клонер",
      "product_26": "DVD Cloner для Mac",
      "product_27": "Blu-ray конвертер",
      "product_28": "Конвертер Blu-ray для Mac",
      "product_29": "Blu-ray-плеер",
      "product_30": "Blu-ray-плеер для Mac",
      "product_31": "Blu-ray-создатель",
      "product_32": "Blu-ray Копия",
      "product_33": "Набор инструментов Blu-ray",
      "product_34": "PDF-конвертер Платина",
      "product_35": "Конвертер PDF в Word",
      "product_36": "PDF-резчик",
      "product_37": "PDF-объединитель",
      "product_38": "Сброс пароля Windows",
      "product_39": "Восстановление данных",
      "product_40": "Передача iPhone",
      "product_41": "Восстановление данных iOS",
      "product_42": "Восстановление данных Android",
      "product_43": "Ластик для iPhone",
      "product_44": "Бесплатное онлайн-удаление водяных знаков",
      "product_45": "Бесплатный видеоредактор",
      "product_46": "Бесплатный конвертер DVD в MP3",
      "product_47": "Бесплатный конвертер PDF в BMP",
      "download_center": "Центр загрузки",
      "company": "Компания",
      "about": "О",
      "privacy_policy": "политика конфиденциальности",
      "terms_of_use": "Условия эксплуатации",
      "affiliate": "Партнер",
      "support_center": "Центр поддержки",
      "contact_us": "Связаться с нами",
      "support": "Поддерживать",
      "hot_products": "Горячие продукты",
      "follow_us": "Подписывайтесь на нас",
      "change_language": "Изменить язык"
  },
  "tr": {
      "video": "Video<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Faydalılık<i></i>",
      "pdf_tools": "PDF Araçları<i></i>",
      "free_tools": "Ücretsiz Araçlar<i></i>",
      "other_tools": "Diğer Araçlar<i></i>",
      "free_online": "Ücretsiz Çevrimiçi<i></i>",
      "desktop": "Masaüstü<i></i>",
      "store": "Tipard Mağazası",
      "resource": "Kaynak",
      "points": "Puanlar:",
      "redeem": "Kurtarmak",
      "invite_friends_get_more_points": "Arkadaşlarınızı Davet Edin ve Daha Fazla Puan Kazanın",
      "rewards_center": "Ödül Merkezi",
      "windows_software": "Windows Yazılımı<i></i>",
      "mac_software": "Mac Yazılımı<i></i>",
      "product_1": "Video Dönüştürücü Ultimate",
      "product_2": "Mac Video Dönüştürücü Ultimate",
      "product_3": "Toplam Medya Dönüştürücü",
      "product_4": "Mac için Toplam Medya Dönüştürücü",
      "product_5": "Video dönüştürücü",
      "product_6": "Mac için Video Dönüştürücü",
      "product_7": "4K UHD Dönüştürücü",
      "product_8": "Mac için 4K Video Dönüştürücü",
      "product_9": "Video Güçlendirici",
      "product_10": "Mac Video Geliştirici",
      "product_11": "Ekran Görüntüsü",
      "product_12": "Mac için Ekran Görüntüsü Alma",
      "product_13": "3D Dönüştürücü",
      "product_14": "Mac için 3D Dönüştürücü",
      "product_15": "TS Dönüştürücü",
      "product_16": "Mac için TS Dönüştürücü",
      "product_17": "Ekran Yakalama Pro",
      "product_18": "PPT'den Video Dönüştürücü",
      "product_19": "FixMP4 - Video Onarımı",
      "product_20": "FixMP4 - Mac için Video Onarımı",
      "product_21": "DVD Kopyalayıcı",
      "product_22": "Mac için DVD Kopyalayıcı",
      "product_23": "DVD Yaratıcısı",
      "product_24": "Mac için DVD Oluşturucu",
      "product_25": "DVD Kopyalayıcı",
      "product_26": "Mac için DVD Kopyalayıcı",
      "product_27": "Blu-ray dönüştürücü",
      "product_28": "Mac için Blu-ray Dönüştürücü",
      "product_29": "Blu-ray Oynatıcı",
      "product_30": "Mac için Blu-ray Oynatıcı",
      "product_31": "Blu-ray Yaratıcısı",
      "product_32": "Blu-ray Kopyası",
      "product_33": "Blu-ray Araç Takımı",
      "product_34": "PDF Dönüştürücü Platin",
      "product_35": "PDF'den Word'e Dönüştürücü",
      "product_36": "PDF Kesici",
      "product_37": "PDF Birleştirici",
      "product_38": "Windows Parola Sıfırlama",
      "product_39": "Veri Kurtarma",
      "product_40": "iPhone Aktarımı",
      "product_41": "iOS Veri Kurtarma",
      "product_42": "Android Veri Kurtarma",
      "product_43": "iPhone Silgisi",
      "product_44": "Filigran Temizleyici Ücretsiz Çevrimiçi",
      "product_45": "Ücretsiz Video Editörü",
      "product_46": "Ücretsiz DVD'den MP3'e Dönüştürücü",
      "product_47": "Ücretsiz PDF'den BMP'ye Dönüştürücü",
      "download_center": "İndirme Merkezi",
      "company": "Şirket",
      "about": "Hakkında",
      "privacy_policy": "Gizlilik Politikası",
      "terms_of_use": "Kullanım Şartları",
      "affiliate": "Ortaklık",
      "support_center": "Destek Merkezi",
      "contact_us": "Bize Ulaşın",
      "support": "Destek",
      "hot_products": "Sıcak Ürünler",
      "follow_us": "Bizi takip edin",
      "change_language": "Dili değiştir"
  },
  "ar": {
      "video": "فيديو<i></i>",
      "blu_ray_dvd": "بلو راي/دي في دي<i></i>",
      "utility": "جدوى<i></i>",
      "pdf_tools": "أدوات PDF<i></i>",
      "free_tools": "أدوات مجانية<i></i>",
      "other_tools": "أدوات أخرى<i></i>",
      "free_online": "مجاني على الانترنت<i></i>",
      "desktop": "سطح المكتب<i></i>",
      "store": "متجر تيبارد",
      "resource": "الموارد",
      "points": "نقاط:",
      "redeem": "يسترد",
      "invite_friends_get_more_points": "قم بدعوة الأصدقاء واحصل على المزيد من النقاط",
      "rewards_center": "مركز المكافآت",
      "windows_software": "برامج الويندوز<i></i>",
      "mac_software": "برامج ماك<i></i>",
      "product_1": "محول الفيديو النهائي",
      "product_2": "محول الفيديو لنظام Mac",
      "product_3": "محول الوسائط الكامل",
      "product_4": "برنامج Total Media Converter لنظام Mac",
      "product_5": "محول الفيديو",
      "product_6": "محول الفيديو لنظام التشغيل Mac",
      "product_7": "محول 4K UHD",
      "product_8": "محول فيديو 4K لنظام Mac",
      "product_9": "مُحسِّن الفيديو",
      "product_10": "مُحسِّن الفيديو لنظام Mac",
      "product_11": "التقاط الشاشة",
      "product_12": "التقاط الشاشة لنظام التشغيل Mac",
      "product_13": "محول ثلاثي الأبعاد",
      "product_14": "محول ثلاثي الأبعاد لنظام التشغيل Mac",
      "product_15": "محول TS",
      "product_16": "محول TS لنظام Mac",
      "product_17": "التقاط الشاشة الاحترافي",
      "product_18": "محول PPT إلى فيديو",
      "product_19": "FixMP4 - إصلاح الفيديو",
      "product_20": "FixMP4 - إصلاح الفيديو لنظام التشغيل Mac",
      "product_21": "برنامج نسخ أقراص DVD",
      "product_22": "برنامج نسخ أقراص DVD لنظام Mac",
      "product_23": "مُنشئ أقراص DVD",
      "product_24": "برنامج DVD Creator لنظام Mac",
      "product_25": "استنساخ أقراص DVD",
      "product_26": "برنامج استنساخ أقراص DVD لنظام Mac",
      "product_27": "محول بلو راي",
      "product_28": "محول Blu-ray لنظام Mac",
      "product_29": "مشغل بلو راي",
      "product_30": "مشغل بلو راي لنظام ماك",
      "product_31": "منشئ بلو راي",
      "product_32": "نسخة بلو راي",
      "product_33": "مجموعة أدوات بلو راي",
      "product_34": "محول PDF البلاتيني",
      "product_35": "محول PDF إلى Word",
      "product_36": "قاطع ملفات PDF",
      "product_37": "برنامج دمج ملفات PDF",
      "product_38": "إعادة تعيين كلمة مرور Windows",
      "product_39": "استعادة البيانات",
      "product_40": "نقل الايفون",
      "product_41": "استعادة بيانات iOS",
      "product_42": "استعادة بيانات الأندرويد",
      "product_43": "ممحاة الآيفون",
      "product_44": "إزالة العلامة المائية مجانًا عبر الإنترنت",
      "product_45": "محرر فيديو مجاني",
      "product_46": "محول DVD إلى MP3 مجاني",
      "product_47": "محول PDF إلى BMP مجاني",
      "download_center": "مركز التحميل",
      "company": "شركة",
      "about": "عن",
      "privacy_policy": "سياسة الخصوصية",
      "terms_of_use": "شروط الاستخدام",
      "affiliate": "الشريك",
      "support_center": "مركز الدعم",
      "contact_us": "اتصل بنا",
      "support": "يدعم",
      "hot_products": "المنتجات الساخنة",
      "follow_us": "تابعنا",
      "change_language": "تغيير اللغة"
  },
  "pl": {
      "video": "Wideo<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Pożytek<i></i>",
      "pdf_tools": "Narzędzia PDF<i></i>",
      "free_tools": "Bezpłatne narzędzia<i></i>",
      "other_tools": "Inne narzędzia<i></i>",
      "free_online": "Bezpłatnie online<i></i>",
      "desktop": "Pulpit<i></i>",
      "store": "Sklep Tipard",
      "resource": "Ratunek",
      "points": "Zwrotnica:",
      "redeem": "Odkupić",
      "invite_friends_get_more_points": "Zaproś znajomych i zdobądź więcej punktów",
      "rewards_center": "Centrum nagród",
      "windows_software": "Oprogramowanie Windows<i></i>",
      "mac_software": "Oprogramowanie Mac<i></i>",
      "product_1": "Konwerter wideo Ultimate",
      "product_2": "Mac Video Converter Ultimate",
      "product_3": "Konwerter multimediów",
      "product_4": "Total Media Converter dla komputerów Mac",
      "product_5": "Konwerter wideo",
      "product_6": "Konwerter wideo dla komputerów Mac",
      "product_7": "Konwerter 4K UHD",
      "product_8": "Konwerter wideo 4K dla komputerów Mac",
      "product_9": "Wzmacniacz wideo",
      "product_10": "Mac Video Enhancer",
      "product_11": "Zrzut ekranu",
      "product_12": "Zrzut ekranu dla Maca",
      "product_13": "Konwerter 3D",
      "product_14": "Konwerter 3D dla komputerów Mac",
      "product_15": "Konwerter TS",
      "product_16": "Konwerter TS dla komputerów Mac",
      "product_17": "Zrzut ekranu Pro",
      "product_18": "Konwerter PPT na wideo",
      "product_19": "FixMP4 - Naprawa wideo",
      "product_20": "FixMP4 - Naprawa wideo dla komputerów Mac",
      "product_21": "Zgrywarka DVD",
      "product_22": "DVD Ripper dla Mac",
      "product_23": "Twórca DVD",
      "product_24": "DVD Creator dla komputerów Mac",
      "product_25": "Klonowanie DVD",
      "product_26": "DVD Cloner dla Mac",
      "product_27": "Konwerter Blu-ray",
      "product_28": "Konwerter Blu-ray dla komputerów Mac",
      "product_29": "Odtwarzacz Blu-ray",
      "product_30": "Odtwarzacz Blu-ray dla komputerów Mac",
      "product_31": "Twórca Blu-ray",
      "product_32": "Kopia Blu-ray",
      "product_33": "Zestaw narzędzi Blu-ray",
      "product_34": "Konwerter PDF Platinum",
      "product_35": "Konwerter PDF do Word",
      "product_36": "Obcinarka PDF",
      "product_37": "Łącznik PDF",
      "product_38": "Resetowanie hasła systemu Windows",
      "product_39": "Odzyskiwanie danych",
      "product_40": "Transfer iPhone'a",
      "product_41": "Odzyskiwanie danych iOS",
      "product_42": "Odzyskiwanie danych z Androida",
      "product_43": "Gumka do iPhone'a",
      "product_44": "Darmowy program do usuwania znaków wodnych online",
      "product_45": "Darmowy edytor wideo",
      "product_46": "Darmowy konwerter DVD na MP3",
      "product_47": "Darmowy konwerter PDF do BMP",
      "download_center": "Centrum pobierania",
      "company": "Firma",
      "about": "O",
      "privacy_policy": "Polityka prywatności",
      "terms_of_use": "Warunki korzystania",
      "affiliate": "Przyłączać",
      "support_center": "Centrum wsparcia",
      "contact_us": "Skontaktuj się z nami",
      "support": "Wsparcie",
      "hot_products": "Gorące produkty",
      "follow_us": "Obserwuj nas",
      "change_language": "Zmień język"
  },
  "nl": {
      "video": "Video<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Nutsvoorziening<i></i>",
      "pdf_tools": "PDF-hulpmiddelen<i></i>",
      "free_tools": "Gratis hulpmiddelen<i></i>",
      "other_tools": "Andere hulpmiddelen<i></i>",
      "free_online": "Gratis online<i></i>",
      "desktop": "Bureaublad<i></i>",
      "store": "Tipard-winkel",
      "resource": "Hulpbron",
      "points": "Punten:",
      "redeem": "Inwisselen",
      "invite_friends_get_more_points": "Nodig vrienden uit en ontvang meer punten",
      "rewards_center": "Beloningscentrum",
      "windows_software": "Windows-software<i></i>",
      "mac_software": "Mac-software<i></i>",
      "product_1": "Video Converter Ultieme",
      "product_2": "Mac Video Converter Ultiem",
      "product_3": "Totale mediaconverter",
      "product_4": "Total Media Converter voor Mac",
      "product_5": "Video-omzetter",
      "product_6": "Videoconverter voor Mac",
      "product_7": "4K UHD-converter",
      "product_8": "4K-videoconverter voor Mac",
      "product_9": "Video-verbeteraar",
      "product_10": "Mac-videoverbeteraar",
      "product_11": "Schermopname",
      "product_12": "Schermopname voor Mac",
      "product_13": "3D-converter",
      "product_14": "3D-converter voor Mac",
      "product_15": "TS-converter",
      "product_16": "TS Converter voor Mac",
      "product_17": "Schermopname Pro",
      "product_18": "PPT naar video-omzetter",
      "product_19": "FixMP4 - Videoreparatie",
      "product_20": "FixMP4 - Videoreparatie voor Mac",
      "product_21": "DVD-ripper",
      "product_22": "DVD Ripper voor Mac",
      "product_23": "DVD-maker",
      "product_24": "DVD Creator voor Mac",
      "product_25": "DVD-kloner",
      "product_26": "DVD Cloner voor Mac",
      "product_27": "Blu-ray-converter",
      "product_28": "Blu-ray-converter voor Mac",
      "product_29": "Blu-ray-speler",
      "product_30": "Blu-ray-speler voor Mac",
      "product_31": "Blu-ray-maker",
      "product_32": "Blu-ray-kopie",
      "product_33": "Blu-ray-toolkit",
      "product_34": "PDF-converter Platina",
      "product_35": "PDF naar Word-converter",
      "product_36": "PDF-snijder",
      "product_37": "PDF-samenvoeger",
      "product_38": "Windows-wachtwoord opnieuw instellen",
      "product_39": "Gegevensherstel",
      "product_40": "iPhone-overdracht",
      "product_41": "iOS-gegevensherstel",
      "product_42": "Android-gegevensherstel",
      "product_43": "iPhone-gum",
      "product_44": "Watermerkverwijderaar gratis online",
      "product_45": "Gratis video-editor",
      "product_46": "Gratis DVD naar MP3 converter",
      "product_47": "Gratis PDF naar BMP-converter",
      "download_center": "Downloadcentrum",
      "company": "Bedrijf",
      "about": "Over",
      "privacy_policy": "Privacybeleid",
      "terms_of_use": "Gebruiksvoorwaarden",
      "affiliate": "Partner",
      "support_center": "Ondersteuningscentrum",
      "contact_us": "Neem contact met ons op",
      "support": "Steun",
      "hot_products": "Populaire producten",
      "follow_us": "Volg ons",
      "change_language": "Taal wijzigen"
  },
  "sv": {
      "video": "Video<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Nytta<i></i>",
      "pdf_tools": "PDF-verktyg<i></i>",
      "free_tools": "Gratis verktyg<i></i>",
      "other_tools": "Andra verktyg<i></i>",
      "free_online": "Gratis online<i></i>",
      "desktop": "Skrivbord<i></i>",
      "store": "Tipard butik",
      "resource": "Resurs",
      "points": "Poäng:",
      "redeem": "Återlösa",
      "invite_friends_get_more_points": "Bjud in vänner och få fler poäng",
      "rewards_center": "Belöningscenter",
      "windows_software": "Windows-programvara<i></i>",
      "mac_software": "Mac-programvara<i></i>",
      "product_1": "Video Converter Ultimate",
      "product_2": "Mac Video Converter Ultimate",
      "product_3": "Total Media Converter",
      "product_4": "Total Media Converter för Mac",
      "product_5": "Videokonverterare",
      "product_6": "Video Converter för Mac",
      "product_7": "4K UHD-omvandlare",
      "product_8": "4K Video Converter för Mac",
      "product_9": "Video Enhancer",
      "product_10": "Mac Video Enhancer",
      "product_11": "Skärmdump",
      "product_12": "Skärmdump för Mac",
      "product_13": "3D-omvandlare",
      "product_14": "3D-konverterare för Mac",
      "product_15": "TS omvandlare",
      "product_16": "TS Converter för Mac",
      "product_17": "Screen Capture Pro",
      "product_18": "PPT till videokonverterare",
      "product_19": "FixMP4 - Videoreparation",
      "product_20": "FixMP4 - Videoreparation för Mac",
      "product_21": "DVD Ripper",
      "product_22": "DVD Ripper för Mac",
      "product_23": "DVD Creator",
      "product_24": "DVD Creator för Mac",
      "product_25": "DVD Cloner",
      "product_26": "DVD Cloner för Mac",
      "product_27": "Blu-ray-omvandlare",
      "product_28": "Blu-ray-konverterare för Mac",
      "product_29": "Blu-ray-spelare",
      "product_30": "Blu-ray-spelare för Mac",
      "product_31": "Blu-ray Creator",
      "product_32": "Blu-ray kopia",
      "product_33": "Blu-ray Toolkit",
      "product_34": "PDF Converter Platinum",
      "product_35": "PDF till Word Converter",
      "product_36": "PDF Cutter",
      "product_37": "PDF-snickare",
      "product_38": "Windows lösenordsåterställning",
      "product_39": "Dataåterställning",
      "product_40": "iPhone Transfer",
      "product_41": "iOS Data Recovery",
      "product_42": "Android dataåterställning",
      "product_43": "iPhone suddgummi",
      "product_44": "Watermark Remover gratis online",
      "product_45": "Gratis videoredigerare",
      "product_46": "Gratis DVD till MP3 Converter",
      "product_47": "Gratis PDF till BMP Converter",
      "download_center": "Nedladdningscenter",
      "company": "Företag",
      "about": "Om",
      "privacy_policy": "Sekretesspolicy",
      "terms_of_use": "Användarvillkor",
      "affiliate": "Dotterbolag",
      "support_center": "Supportcenter",
      "contact_us": "Kontakta oss",
      "support": "Stöd",
      "hot_products": "Heta produkter",
      "follow_us": "Följ oss",
      "change_language": "Byt språk"
  },
  "cs": {
      "video": "Video<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Utility<i></i>",
      "pdf_tools": "Nástroje PDF<i></i>",
      "free_tools": "Nástroje zdarma<i></i>",
      "other_tools": "Další nástroje<i></i>",
      "free_online": "Zdarma online<i></i>",
      "desktop": "Desktop<i></i>",
      "store": "Prodejna Tipard",
      "resource": "Zdroj",
      "points": "Body:",
      "redeem": "Vykoupit",
      "invite_friends_get_more_points": "Pozvěte přátele a získejte více bodů",
      "rewards_center": "Centrum odměn",
      "windows_software": "Software Windows<i></i>",
      "mac_software": "Software Mac<i></i>",
      "product_1": "Video Converter Ultimate",
      "product_2": "Mac Video Converter Ultimate",
      "product_3": "Total Media Converter",
      "product_4": "Total Media Converter pro Mac",
      "product_5": "Video konvertor",
      "product_6": "Video Converter pro Mac",
      "product_7": "4K UHD převodník",
      "product_8": "4K Video Converter pro Mac",
      "product_9": "Video Enhancer",
      "product_10": "Mac Video Enhancer",
      "product_11": "Snímání obrazovky",
      "product_12": "Screen Capture pro Mac",
      "product_13": "3D převodník",
      "product_14": "3D konvertor pro Mac",
      "product_15": "TS převodník",
      "product_16": "TS Converter pro Mac",
      "product_17": "Screen Capture Pro",
      "product_18": "PPT to Video Converter",
      "product_19": "FixMP4 - Oprava videa",
      "product_20": "FixMP4 - Video Repair pro Mac",
      "product_21": "DVD Ripper",
      "product_22": "DVD Ripper pro Mac",
      "product_23": "DVD Creator",
      "product_24": "DVD Creator pro Mac",
      "product_25": "DVD Cloner",
      "product_26": "DVD Cloner pro Mac",
      "product_27": "Blu-ray konvertor",
      "product_28": "Blu-ray konvertor pro Mac",
      "product_29": "Blu-ray přehrávač",
      "product_30": "Blu-ray přehrávač pro Mac",
      "product_31": "Blu-ray Creator",
      "product_32": "Blu-ray kopie",
      "product_33": "Blu-ray Toolkit",
      "product_34": "PDF Converter Platinum",
      "product_35": "Konvertor PDF do Wordu",
      "product_36": "Vyřezávač PDF",
      "product_37": "PDF Joiner",
      "product_38": "Obnovení hesla systému Windows",
      "product_39": "Záchrana dat",
      "product_40": "Přenos iPhone",
      "product_41": "Obnova dat iOS",
      "product_42": "Záchrana dat pro Android",
      "product_43": "Eraser pro iPhone",
      "product_44": "Odstraňovač vodoznaků online zdarma",
      "product_45": "Zdarma Video Editor",
      "product_46": "Převaděč DVD do MP3 zdarma",
      "product_47": "Zdarma převodník PDF do BMP",
      "download_center": "Centrum stahování",
      "company": "Společnost",
      "about": "O",
      "privacy_policy": "Zásady ochrany osobních údajů",
      "terms_of_use": "Podmínky použití",
      "affiliate": "Přidružený",
      "support_center": "Centrum podpory",
      "contact_us": "Kontaktujte nás",
      "support": "Podpora",
      "hot_products": "Horké produkty",
      "follow_us": "Sledujte nás",
      "change_language": "Změnit jazyk"
  },
  "da": {
      "video": "Video<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Utility<i></i>",
      "pdf_tools": "PDF-værktøjer<i></i>",
      "free_tools": "Gratis værktøjer<i></i>",
      "other_tools": "Andre værktøjer<i></i>",
      "free_online": "Gratis online<i></i>",
      "desktop": "Desktop<i></i>",
      "store": "Tipard Butik",
      "resource": "Ressource",
      "points": "Points:",
      "redeem": "Indløs",
      "invite_friends_get_more_points": "Inviter venner og få flere point",
      "rewards_center": "Belønningscenter",
      "windows_software": "Windows-software<i></i>",
      "mac_software": "Mac-software<i></i>",
      "product_1": "Video Converter Ultimate",
      "product_2": "Mac Video Converter Ultimate",
      "product_3": "Total Media Converter",
      "product_4": "Total Media Converter til Mac",
      "product_5": "Video Converter",
      "product_6": "Video Converter til Mac",
      "product_7": "4K UHD konverter",
      "product_8": "4K Video Converter til Mac",
      "product_9": "Video Enhancer",
      "product_10": "Mac Video Enhancer",
      "product_11": "Skærmoptagelse",
      "product_12": "Skærmoptagelse til Mac",
      "product_13": "3D konverter",
      "product_14": "3D Converter til Mac",
      "product_15": "TS konverter",
      "product_16": "TS Converter til Mac",
      "product_17": "Screen Capture Pro",
      "product_18": "PPT til videokonverter",
      "product_19": "FixMP4 - Videoreparation",
      "product_20": "FixMP4 - Videoreparation til Mac",
      "product_21": "DVD Ripper",
      "product_22": "DVD Ripper til Mac",
      "product_23": "DVD Creator",
      "product_24": "DVD Creator til Mac",
      "product_25": "DVD Cloner",
      "product_26": "DVD Cloner til Mac",
      "product_27": "Blu-ray konverter",
      "product_28": "Blu-ray konverter til Mac",
      "product_29": "Blu-ray-afspiller",
      "product_30": "Blu-ray-afspiller til Mac",
      "product_31": "Blu-ray Creator",
      "product_32": "Blu-ray kopi",
      "product_33": "Blu-ray Toolkit",
      "product_34": "PDF Converter Platinum",
      "product_35": "Konverter pdf til word",
      "product_36": "PDF Cutter",
      "product_37": "PDF snedker",
      "product_38": "Windows Password Reset",
      "product_39": "Datagendannelse",
      "product_40": "iPhone overførsel",
      "product_41": "iOS-datagendannelse",
      "product_42": "Android datagendannelse",
      "product_43": "iPhone viskelæder",
      "product_44": "Vandmærkefjerner gratis online",
      "product_45": "Gratis video editor",
      "product_46": "Gratis DVD til MP3 Converter",
      "product_47": "Gratis PDF til BMP konverter",
      "download_center": "Downloadcenter",
      "company": "Selskab",
      "about": "Om",
      "privacy_policy": "Privatlivspolitik",
      "terms_of_use": "Vilkår for brug",
      "affiliate": "Affiliate",
      "support_center": "Supportcenter",
      "contact_us": "Kontakt os",
      "support": "Støtte",
      "hot_products": "Hotte produkter",
      "follow_us": "Følg os",
      "change_language": "Skift sprog"
  },
  "el": {
      "video": "Βίντεο<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Χρησιμότητα<i></i>",
      "pdf_tools": "Εργαλεία PDF<i></i>",
      "free_tools": "Δωρεάν Εργαλεία<i></i>",
      "other_tools": "Άλλα εργαλεία<i></i>",
      "free_online": "Δωρεάν Online<i></i>",
      "desktop": "Επιτραπέζιος υπολογιστής<i></i>",
      "store": "Κατάστημα Tipard",
      "resource": "Πόρος",
      "points": "Πόντοι:",
      "redeem": "Εξαργυρώνω",
      "invite_friends_get_more_points": "Προσκαλέστε φίλους και κερδίστε περισσότερους πόντους",
      "rewards_center": "Κέντρο Ανταμοιβών",
      "windows_software": "Λογισμικό Windows<i></i>",
      "mac_software": "Λογισμικό Mac<i></i>",
      "product_1": "Video Converter Ultimate",
      "product_2": "Mac Video Converter Ultimate",
      "product_3": "Συνολικός μετατροπέας πολυμέσων",
      "product_4": "Total Media Converter για Mac",
      "product_5": "Μετατροπέας βίντεο",
      "product_6": "Μετατροπέας βίντεο για Mac",
      "product_7": "Μετατροπέας 4K UHD",
      "product_8": "Μετατροπέας βίντεο 4K για Mac",
      "product_9": "Βελτιωτή βίντεο",
      "product_10": "Mac Video Enhancer",
      "product_11": "Λήψη οθόνης",
      "product_12": "Λήψη οθόνης για Mac",
      "product_13": "Μετατροπέας 3D",
      "product_14": "Μετατροπέας 3D για Mac",
      "product_15": "Μετατροπέας TS",
      "product_16": "Μετατροπέας TS για Mac",
      "product_17": "Screen Capture Pro",
      "product_18": "Μετατροπέας PPT σε βίντεο",
      "product_19": "FixMP4 - Επισκευή βίντεο",
      "product_20": "FixMP4 - Επισκευή βίντεο για Mac",
      "product_21": "DVD Ripper",
      "product_22": "DVD Ripper για Mac",
      "product_23": "Δημιουργός DVD",
      "product_24": "DVD Creator για Mac",
      "product_25": "DVD Cloner",
      "product_26": "DVD Cloner για Mac",
      "product_27": "Μετατροπέας Blu-ray",
      "product_28": "Μετατροπέας Blu-ray για Mac",
      "product_29": "Blu-ray Player",
      "product_30": "Blu-ray Player για Mac",
      "product_31": "Δημιουργός Blu-ray",
      "product_32": "Αντιγραφή Blu-ray",
      "product_33": "Blu-ray Toolkit",
      "product_34": "Μετατροπέας PDF Platinum",
      "product_35": "Μετατροπέας PDF σε Word",
      "product_36": "Κόφτης PDF",
      "product_37": "PDF Joiner",
      "product_38": "Επαναφορά κωδικού πρόσβασης των Windows",
      "product_39": "Ανάκτηση δεδομένων",
      "product_40": "Μεταφορά iPhone",
      "product_41": "Ανάκτηση δεδομένων iOS",
      "product_42": "Ανάκτηση δεδομένων Android",
      "product_43": "Γόμα iPhone",
      "product_44": "Υδατογράφημα Αφαίρεσης Δωρεάν Online",
      "product_45": "Δωρεάν πρόγραμμα επεξεργασίας βίντεο",
      "product_46": "Δωρεάν μετατροπέας DVD σε MP3",
      "product_47": "Δωρεάν μετατροπέας PDF σε BMP",
      "download_center": "Κέντρο λήψης",
      "company": "Εταιρεία",
      "about": "Για",
      "privacy_policy": "Πολιτική Απορρήτου",
      "terms_of_use": "Όροι Χρήσης",
      "affiliate": "Εισδέχομαι μέλη",
      "support_center": "Κέντρο υποστήριξης",
      "contact_us": "Επικοινωνήστε μαζί μας",
      "support": "Υποστήριξη",
      "hot_products": "Καυτά προϊόντα",
      "follow_us": "Ακολουθήστε μας",
      "change_language": "Αλλαγή γλώσσας"
  },
  "fi": {
      "video": "Video<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Apuohjelma<i></i>",
      "pdf_tools": "PDF-työkalut<i></i>",
      "free_tools": "Ilmaiset työkalut<i></i>",
      "other_tools": "Muut työkalut<i></i>",
      "free_online": "Ilmainen Online<i></i>",
      "desktop": "Työpöytä<i></i>",
      "store": "Tipard Store",
      "resource": "Resurssi",
      "points": "Pisteet:",
      "redeem": "Lunastaa",
      "invite_friends_get_more_points": "Kutsu ystäviä ja hanki lisää pisteitä",
      "rewards_center": "Palkintokeskus",
      "windows_software": "Windows-ohjelmisto<i></i>",
      "mac_software": "Mac-ohjelmisto<i></i>",
      "product_1": "Video Converter Ultimate",
      "product_2": "Mac Video Converter Ultimate",
      "product_3": "Total Media Converter",
      "product_4": "Total Media Converter for Mac",
      "product_5": "Video Converter",
      "product_6": "Video Converter for Mac",
      "product_7": "4K UHD muunnin",
      "product_8": "4K Video Converter for Mac",
      "product_9": "Videon tehostin",
      "product_10": "Mac Video Enhancer",
      "product_11": "Screen Capture",
      "product_12": "Näytönkaappaus Macille",
      "product_13": "3D-muunnin",
      "product_14": "3D Converter for Mac",
      "product_15": "TS-muunnin",
      "product_16": "TS Converter for Mac",
      "product_17": "Screen Capture Pro",
      "product_18": "PPT-videomuunnin",
      "product_19": "FixMP4 - Videon korjaus",
      "product_20": "FixMP4 - Videon korjaus Macille",
      "product_21": "DVD Ripper",
      "product_22": "DVD Ripper Macille",
      "product_23": "DVD Creator",
      "product_24": "DVD Creator for Mac",
      "product_25": "DVD Cloner",
      "product_26": "DVD Cloner Macille",
      "product_27": "Blu-ray-muunnin",
      "product_28": "Blu-ray-muunnin Macille",
      "product_29": "Blu-ray-soitin",
      "product_30": "Blu-ray-soitin Macille",
      "product_31": "Blu-ray Creator",
      "product_32": "Blu-ray kopio",
      "product_33": "Blu-ray Toolkit",
      "product_34": "PDF Converter Platinum",
      "product_35": "PDF-muunnin Wordiksi",
      "product_36": "PDF-leikkuri",
      "product_37": "PDF-puuseppä",
      "product_38": "Windowsin salasanan palautus",
      "product_39": "Tietojen palautus",
      "product_40": "iPhonen siirto",
      "product_41": "iOS-tietojen palautus",
      "product_42": "Android-tietojen palautus",
      "product_43": "iPhonen pyyhekumi",
      "product_44": "Vesileimanpoistoaine ilmaiseksi verkossa",
      "product_45": "Ilmainen videoeditori",
      "product_46": "Ilmainen DVD-MP3-muunnin",
      "product_47": "Ilmainen PDF to BMP Converter",
      "download_center": "Latauskeskus",
      "company": "Yritys",
      "about": "Noin",
      "privacy_policy": "Tietosuojakäytäntö",
      "terms_of_use": "Käyttöehdot",
      "affiliate": "Yhteistyökumppani",
      "support_center": "Tukikeskus",
      "contact_us": "Ota yhteyttä",
      "support": "Tukea",
      "hot_products": "Kuumat tuotteet",
      "follow_us": "Seuraa meitä",
      "change_language": "Vaihda kieli"
  },
  "hu": {
      "video": "Videó<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Hasznosság<i></i>",
      "pdf_tools": "PDF-eszközök<i></i>",
      "free_tools": "Ingyenes eszközök<i></i>",
      "other_tools": "Egyéb eszközök<i></i>",
      "free_online": "Ingyenes online<i></i>",
      "desktop": "Asztali<i></i>",
      "store": "Tipard Store",
      "resource": "Forrás",
      "points": "Pontok:",
      "redeem": "Beváltani",
      "invite_friends_get_more_points": "Hívjon meg barátokat és szerezzen több pontot",
      "rewards_center": "Jutalomközpont",
      "windows_software": "Windows szoftver<i></i>",
      "mac_software": "Mac szoftver<i></i>",
      "product_1": "Video Converter Ultimate",
      "product_2": "Mac Video Converter Ultimate",
      "product_3": "Total Media Converter",
      "product_4": "Total Media Converter for Mac",
      "product_5": "Video Converter",
      "product_6": "Video Converter for Mac",
      "product_7": "4K UHD konverter",
      "product_8": "4K Video Converter for Mac",
      "product_9": "Video Enhancer",
      "product_10": "Mac Video Enhancer",
      "product_11": "Képernyőfelvétel",
      "product_12": "Screen Capture for Mac",
      "product_13": "3D konverter",
      "product_14": "3D Converter for Mac",
      "product_15": "TS konverter",
      "product_16": "TS Converter for Mac",
      "product_17": "Screen Capture Pro",
      "product_18": "PPT-ből Video Converter",
      "product_19": "FixMP4 – Videójavítás",
      "product_20": "FixMP4 – Videójavítás Mac-hez",
      "product_21": "DVD Ripper",
      "product_22": "DVD Ripper Mac-hez",
      "product_23": "DVD-készítő",
      "product_24": "DVD Creator for Mac",
      "product_25": "DVD klónozó",
      "product_26": "DVD Cloner for Mac",
      "product_27": "Blu-ray konverter",
      "product_28": "Blu-ray konverter Machez",
      "product_29": "Blu-ray lejátszó",
      "product_30": "Blu-ray lejátszó Machez",
      "product_31": "Blu-ray Creator",
      "product_32": "Blu-ray másolás",
      "product_33": "Blu-ray Toolkit",
      "product_34": "PDF Converter Platina",
      "product_35": "PDF-ből Word konvertáló",
      "product_36": "PDF vágó",
      "product_37": "PDF Asztalos",
      "product_38": "Windows jelszó visszaállítása",
      "product_39": "Adatmentés",
      "product_40": "iPhone átvitel",
      "product_41": "iOS adat-helyreállítás",
      "product_42": "Android Data Recovery",
      "product_43": "iPhone radír",
      "product_44": "Vízjel eltávolító ingyenes online",
      "product_45": "Ingyenes videószerkesztő",
      "product_46": "Ingyenes DVD-MP3 konverter",
      "product_47": "Ingyenes PDF-ből BMP konvertáló",
      "download_center": "Letöltő központ",
      "company": "Vállalat",
      "about": "Körülbelül",
      "privacy_policy": "Adatvédelmi szabályzat",
      "terms_of_use": "Felhasználási feltételek",
      "affiliate": "Leányvállalat",
      "support_center": "Támogatási Központ",
      "contact_us": "Lépjen kapcsolatba velünk",
      "support": "Támogatás",
      "hot_products": "Forró termékek",
      "follow_us": "Kövess minket",
      "change_language": "Nyelv módosítása"
  },
  "no": {
      "video": "Video<i></i>",
      "blu_ray_dvd": "Blu-ray/DVD<i></i>",
      "utility": "Utility<i></i>",
      "pdf_tools": "PDF-verktøy<i></i>",
      "free_tools": "Gratis verktøy<i></i>",
      "other_tools": "Andre verktøy<i></i>",
      "free_online": "Gratis på nett<i></i>",
      "desktop": "Skrivebord<i></i>",
      "store": "Tipard Store",
      "resource": "Ressurs",
      "points": "Poeng:",
      "redeem": "Løs inn",
      "invite_friends_get_more_points": "Inviter venner og få flere poeng",
      "rewards_center": "Belønningssenter",
      "windows_software": "Windows-programvare<i></i>",
      "mac_software": "Mac-programvare<i></i>",
      "product_1": "Video Converter Ultimate",
      "product_2": "Mac Video Converter Ultimate",
      "product_3": "Total Media Converter",
      "product_4": "Total Media Converter for Mac",
      "product_5": "Video Converter",
      "product_6": "Video Converter for Mac",
      "product_7": "4K UHD-konverter",
      "product_8": "4K Video Converter for Mac",
      "product_9": "Video Enhancer",
      "product_10": "Mac Video Enhancer",
      "product_11": "Skjermfangst",
      "product_12": "Skjermopptak for Mac",
      "product_13": "3D-konverter",
      "product_14": "3D Converter for Mac",
      "product_15": "TS-konverter",
      "product_16": "TS Converter for Mac",
      "product_17": "Screen Capture Pro",
      "product_18": "PPT til Video Converter",
      "product_19": "FixMP4 - Videoreparasjon",
      "product_20": "FixMP4 - Videoreparasjon for Mac",
      "product_21": "DVD Ripper",
      "product_22": "DVD Ripper for Mac",
      "product_23": "DVD Creator",
      "product_24": "DVD Creator for Mac",
      "product_25": "DVD Cloner",
      "product_26": "DVD Cloner for Mac",
      "product_27": "Blu-ray-konverter",
      "product_28": "Blu-ray Converter for Mac",
      "product_29": "Blu-ray-spiller",
      "product_30": "Blu-ray-spiller for Mac",
      "product_31": "Blu-ray Creator",
      "product_32": "Blu-ray kopi",
      "product_33": "Blu-ray Toolkit",
      "product_34": "PDF Converter Platinum",
      "product_35": "Konverter PDF til Word",
      "product_36": "PDF-kutter",
      "product_37": "PDF snekker",
      "product_38": "Tilbakestill Windows-passord",
      "product_39": "Datagjenoppretting",
      "product_40": "iPhone-overføring",
      "product_41": "iOS-datagjenoppretting",
      "product_42": "Android datagjenoppretting",
      "product_43": "iPhone viskelær",
      "product_44": "Vannmerkefjerner gratis på nett",
      "product_45": "Gratis videoredigerer",
      "product_46": "Gratis DVD til MP3 Converter",
      "product_47": "Gratis PDF til BMP Converter",
      "download_center": "Nedlastingssenter",
      "company": "Bedrift",
      "about": "Om",
      "privacy_policy": "Personvernerklæring",
      "terms_of_use": "Vilkår for bruk",
      "affiliate": "Affiliate",
      "support_center": "Støttesenter",
      "contact_us": "Kontakt oss",
      "support": "Støtte",
      "hot_products": "Varme produkter",
      "follow_us": "Følg oss",
      "change_language": "Endre språk"
  }
}
  for(var i18n_key in i18n_private){
    if(!i18n[i18n_key]){
      i18n[i18n_key]={}
    }
    for(var i18n_private_key in i18n_private[i18n_key]){
      i18n[i18n_key][i18n_private_key]=i18n_private[i18n_key][i18n_private_key]
    }
  }