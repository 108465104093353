// $.post(
//     post_url+"product/trial",
//     {
//         e_id:JSON.parse(getCookie('user')).e_id,
//         t_id:JSON.parse(getCookie('user')).t_id,
//         token:JSON.parse(getCookie('user')).token,
//         p_id:179
//     },
//     function(data){
//     }
// )
$.post(post_url+'product/usage',{
    e_id:JSON.parse(getCookie('user')).e_id,
    t_id:JSON.parse(getCookie('user')).t_id,
    token:JSON.parse(getCookie('user')).token,
},function(data){
    console.log(data);
    $('.info>.right .app>.loading').remove();
    error_fn(data.error)
    switch(data.error){
        case 0:
            //改为自己要用的数据
            var productobj=new Object();
            for(var i=0;i<data.products.length;i++){
                productobj[data.products[i].p_id]=[]
                for(var j=0;j<data.products[i].codes.length;j++){
                    productobj[data.products[i].p_id].push({'date':data.products[i].codes[j].expire,'status':data.products[i].codes[j].status});
                };
            };
            //没有数据
            if(JSON.stringify(productobj) =='{}'){
                // $('.info .app').append(`
                //     <div class="no">
                //         <img src="/images/nothing.png" alt="Nothing">
                //         <p>${i18n[lang].no_app_found}</p>
                //     </div>
                // `);
            }else{
                //循环数据
                $.ajaxSettings.async=false
                for(var id in productobj){
                    if(productJSON[id]==undefined){
                        continue;
                    }
                    if(productJSON[id][lang]==undefined){
                        var product=productJSON[id]['en']
                    }else{
                        var product=productJSON[id][lang]
                    }
                    if(product){
                        var order_product='';
                            //判断产品是否免费
                            if(product['isFree']==1){
                                order_product+=`
                                <table>
                                    <tr>
                                        <td>${i18n[lang].plan} 1</td>
                                        <td>${i18n[lang].free_no_limit}</td>
                                        <td></td>
                                    </tr>
                                </table>
                                <div class="more_box">
                                <a href="${product['link']}">${i18n[lang].product_page}</a>
                                </div>
                                `;
                                $('.info .app').append(`
                                    <div class="flex_box">
                                        <div class="left">
                                            <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${i18n[lang].product['name']}">
                                            <span>${i18n[lang].product['name']}</span>
                                        </div>
                                        <div class="right">
                                            ${order_product}
                                        </div>
                                    </div>
                                `);
                             }else if(product['isFree']==0){
                                if(productobj[id].length){
                                    // 注册码的状态
                                    var productobjStatus=[];
                                    for(var i=0;i<productobj[id].length;i++){
                                        productobj[id][i]['status'].sort((a, b) => a - b);
                                        if(productobj[id][i]['status'][0]==4103){
                                            productobjStatus.push({'status':1});
                                        }else{
                                            productobjStatus.push({'status':productobj[id][i]['status'][0],'date':productobj[id][i]['date']});
                                        }
                                    };
                                    //正常
                                    var productobjVal_1=1;
                                    //过期
                                    var productobjVal_2=1;
                                    //禁用，退款
                                    var productobjVal_3=1;
                                    productobjStatus.sort((a,b)=>a.status-b.status);
                                    for(var i=0;i<productobjStatus.length;i++){
                                        if(id==24){
                                            console.log(productobjStatus[i]['status']);
                                        }
                                        if(productobjStatus[i]['status']==0){
                                            productobjVal_1=0;
                                            order_product+=`
                                            <table>
                                                <tr>
                                                    <td>${i18n[lang].plan} ${i+1}</td>
                                                    <td>${surplusTime(productobjStatus[i]['date'])}</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                            `
                                        }else if(productobjStatus[i]['status']==1&&productobjVal_1&&productobjVal_2){
                                            productobjVal_2=0;
                                            order_product+=`
                                            <table>
                                                <tr>
                                                    <td>${i18n[lang].plan} ${i+1}</td>
                                                    <td style="color: #D80303;">${i18n[lang].expired}</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                            `
                                        }else if(productobjStatus[i]['status']==4101&&productobjVal_1&&productobjVal_3){
                                            productobjVal_3=0;
                                            order_product+=`
                                            <table>
                                                <tr>
                                                    <td>${i18n[lang].plan} ${i+1}</td>
                                                    <td status="4101">${i18n[lang].unregistered}</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                            `
                                        }else if(productobjStatus[i]['status']==3001&&productobjVal_1&&productobjVal_3){
                                            productobjVal_3=0;
                                            order_product+=`
                                            <table>
                                                <tr>
                                                    <td>${i18n[lang].plan} ${i+1}</td>
                                                    <td status="3001">${i18n[lang].unregistered}</td>
                                                    <td></td>
                                                </tr>
                                            </table>
                                            `
                                        }
                                    };
                                    //判断是正常还是过期
                                    if(!productobjVal_1){
                                        order_product+=`
                                        <div class="more_box">
                                        <a class="btn" href="${product['buy-link']}">${i18n[lang].buy_more}</a>
                                        <a href="${product['link']}">${i18n[lang].product_page}</a>
                                        </div>`;
                                        $('.info .app').append(`
                                            <div class="flex_box">
                                                <div class="left">
                                                    <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                    <span>${product['name']}</span>
                                                    <img src="/images/member.svg" loading="lazy" alt="Member">
                                                    
                                                </div>
                                                <div class="right">
                                                    ${order_product}
                                                </div>
                                            </div>
                                        `);
                                    }else{
                                        order_product+=`
                                        <div class="again_box">
                                        <a class="btn" href="${product['buy-link']}">${i18n[lang].buy_again}</a>
                                        <a href="${product['link']}">${i18n[lang].product_page}</a>
                                        </div>`;
                                        $('.info .app').append(`
                                            <div class="flex_box">
                                                <div class="left">
                                                    <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                    <span>${product['name']}</span>
                                                    <img src="/images/non-member.svg" loading="lazy" alt="No Member">
                                                    
                                                </div>
                                                <div class="right">
                                                    ${order_product}
                                                </div>
                                            </div>
                                        `);
                                    }
                                }else{
                                    if(product['buy-link']){
                                        order_product+=`
                                        <table>
                                            <tr>
                                                <td>${i18n[lang].plan} 1</td>
                                                <td status="nocode">${i18n[lang].unregistered}</td>
                                                <td></td>
                                            </tr>
                                        </table>
                                        <div class="again_box">
                                        <a class="btn" href="${product['buy-link']}">${i18n[lang].buy_now}</a>
                                        <a href="${product['link']}">${i18n[lang].product_page}</a>
                                        </div>
                                        `;
                                    }else{
                                        order_product+=`
                                        <table>
                                            <tr>
                                                <td>${i18n[lang].plan} 1</td>
                                                <td status="nocode">${i18n[lang].unregistered}</td>
                                                <td></td>
                                            </tr>
                                        </table>
                                        `;
                                    }
                                    $('.info .app').append(`
                                        <div class="flex_box">
                                            <div class="left">
                                                <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                <span>${product['name']}</span>
                                                <img src="/images/non-member.svg" loading="lazy" alt="No Member">
                                                
                                            </div>
                                            <div class="right">
                                                ${order_product}
                                            </div>
                                        </div>
                                    `);
                                }
                            }else if(product['isFree']==2){
                                if(id==1049 || id==173 || id==178){
                                    (function(id){
                                        var benefits_ind;
                                        switch(id){
                                            case '1049':
                                                benefits_ind=2;
                                                break;
                                            case '173':
                                                benefits_ind=4;
                                                break;
                                            case '178':
                                                benefits_ind=0;
                                                break;
                                        }
                                        var info=""
                                        var order_product='';
                                        var expireEffectiveProduct=""
                                        var expireEffectiveProductBox=""
                                        var expireProduct="";
                                        // 查询权益
                                        $.post(
                                            post_url+"benefit/status",
                                            {
                                                e_id:JSON.parse(getCookie('user')).e_id,
                                                t_id:JSON.parse(getCookie('user')).t_id,
                                                token:JSON.parse(getCookie('user')).token,
                                                product_id:id,
                                                all:1
                                            },function(data){
                                                error_fn(data.error)
                                                console.log(data)
                                                if(productJSON[id][lang]==undefined){
                                                    var product=productJSON[id]['en']
                                                }else{
                                                    var product=productJSON[id][lang]
                                                }
                                                switch(data.error){
                                                    case 0:
                                                        if(id==1049){
                                                            info="<p style='font-size:16px;color:#333;margin-bottom:16px;'>"+i18n[lang].hd_downloading+"</p>"
                                                        }else if(id==173){
                                                            info="<p style='font-size:16px;color:#333;margin-bottom:16px;'>"+i18n[lang].image_upscaler_p+"</p>"
                                                        }else{
                                                            info="<p style='font-size:16px;color:#333;margin-bottom:16px;'>"+i18n[lang].hd_downloading+"</p>"
                                                        }
                                                        $('.info>.right .app>.loading').remove();
                                                        var totalM=0;
                                                        var useM=0;
                                                        var total=0;
                                                        var use=0;
                                                        var islifetime=0;
                                                        // 实际过期
                                                        var totalExpire=0
                                                        var useExpire=0
                                                        // 实际没过期
                                                        var totalExpireEffective=0
                                                        var useExpireEffective=0
                                                        var timeExpireEffective
                                                        for(var i=0;i<data.subscriptions.length;i++){
                                                            if(data.subscriptions[i].expire!=0){
                                                                if(data.subscriptions[i].expire_effective*1>Date.parse(new Date())/1000 || data.subscriptions[i].expire*1>Date.parse(new Date())/1000){
                                                                    timeExpireEffective=data.subscriptions[i].expire_effective*1
                                                                    expireEffectiveProductBox+=`
                                                                    <table class="isfree2">
                                                                        <tr>
                                                                            <td>${data.subscriptions[i].benefits[benefits_ind].quota} ${i18n[lang].images}/${i18n[lang].Month} (${getdate(data.subscriptions[i].since)} - ${getdate(data.subscriptions[i].expire)})</td>
                                                                            <td>${i18n[lang].Remaining} <span><i class="num">${data.subscriptions[i].benefits[benefits_ind].quota-data.subscriptions[i].benefits[benefits_ind].usage}</i> ${i18n[lang].images}</span></td>
                                                                        </tr>
                                                                    </table>
                                                                    `;
                                                                    try{
                                                                        totalExpireEffective+=data.subscriptions[i].benefits[benefits_ind].quota*1
                                                                        useExpireEffective+=data.subscriptions[i].benefits[benefits_ind].cost*1
                                                                    }catch(e){
                                                                        console.log(e)
                                                                    }
                                                                }else{
                                                                    expireProduct+=`
                                                                    <table class="isfree2">
                                                                        <tr>
                                                                            <td>${data.subscriptions[i].benefits[benefits_ind].quota} ${i18n[lang].credit}/${i18n[lang].Month} (${getdate(data.subscriptions[i].since)} - ${getdate(data.subscriptions[i].expire)})</td>
                                                                            <td>${i18n[lang].Remaining} <span><i class="num">${data.subscriptions[i].benefits[benefits_ind].quota-data.subscriptions[i].benefits[benefits_ind].cost}</i> ${i18n[lang].credit}</span></td>
                                                                        </tr>
                                                                    </table>
                                                                    `
                                                                }
                                                            }else{
                                                                if(data.subscriptions[i].product_feature==0){
                                                                    var isShow=true
                                                                    if(isShow){
                                                                        order_product+=`
                                                                        <table class="isfree2">
                                                                            <tr>
                                                                                <td>${data.subscriptions[i].benefits[8].quota*1} ${i18n[lang].images}/${i18n[lang].lifetime}<i class="free">Free</i></td>
                                                                                <td>${i18n[lang].Remaining} <span><i class="num">${data.subscriptions[i].benefits[8].quota*1-data.subscriptions[i].benefits[8].usage*1}</i> ${i18n[lang].images}</span></td>
                                                                            </tr>
                                                                        </table>
                                                                        `;
                                                                    }
                                                                }else{
                                                                    islifetime=1;
                                                                    try{
                                                                        total+=data.subscriptions[i].benefits[benefits_ind].quota*1
                                                                        use+=data.subscriptions[i].benefits[benefits_ind].usage*1
                                                                    }catch(e){
                                                                        console.log(e)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        if(islifetime){
                                                            order_product+=`
                                                            <table class="isfree2">
                                                                <tr>
                                                                    <td>${total} ${i18n[lang].images}/${i18n[lang].lifetime}</td>
                                                                    <td>${i18n[lang].Remaining} <span><i class="num">${total-use}</i> ${i18n[lang].images}</span></td>
                                                                </tr>
                                                            </table>
                                                            `;
                                                        }
                                                        if(totalExpireEffective-useExpireEffective){
                                                            expireEffectiveProduct+=`
                                                            <table class="isfree2">
                                                                <tr>
                                                                    <td><span style="font-weight: bold;">${totalExpireEffective} ${i18n[lang].credit}</span> (${i18n[lang].expire}${getdate(timeExpireEffective)})</td>
                                                                    <td>${i18n[lang].Remaining} <span><i class="num">${totalExpireEffective-useExpireEffective}</i> ${i18n[lang].credit}</span></td>
                                                                    <td>
                                                                        <span class="tabClickExpireEffective click">${i18n[lang].viewDetails}</span>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            `
                                                        }
                                                        $('.info .app').append(`
                                                            <div class="flex_box" product="${id}">
                                                                <div class="left">
                                                                    <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                                    <span>${product['name']}</span>
                                                                </div>
                                                                <div class="right">
                                                                    ${info}
                                                                    <div class="product_inner">
                                                                        <div class='scroll_box'>
                                                                            <div class="expireEffectiveList">
                                                                                ${expireEffectiveProductBox}
                                                                                
                                                                            </div>
                                                                            <div class="orderList">
                                                                                ${expireEffectiveProduct}
                                                                                ${order_product}
                                                                            </div>
                                                                            <div class="expireList">
                                                                                ${expireProduct}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    ${expireProduct?'<span class="tabClickExpire click">'+i18n[lang].viewHistory+'</span>':''}
                                                                    <p class="expireEffectiveInfo">${i18n[lang].viewDetailsInfo}</p>
                                                                    <i class="back"></i>
                                                                    <div class="more_box">
                                                                        <a class="btn" href="${product['buy-link']}" target="_blank">${i18n[lang].buy_more}</a>
                                                                        <a style="color:#3774FF;" href="${product['link']}" target="_blank">${i18n[lang].use_this_function}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        `);
                                                        break;
                                                    case 26801:
                                                        error(i18n[lang].error_26801);
                                                        break;
                                                    case 26802:
                                                        error(i18n[lang].error_26802);
                                                        break;
                                                    case 26803:
                                                        error(i18n[lang].error_26803);
                                                        break;
                                                    case 26804:
                                                        error(i18n[lang].error_26804);
                                                        break;
                                                }
                                            }
                                        ).error(function(xhr){
                                            overtime(26899);
                                        });
                                    }(id))
                                }
                            }else if(product['isFree']==3){
                                if(id==179){
                                    (function(id){
                                        var benefits_ind;
                                        switch(id){
                                            case '179':
                                                benefits_ind=0;
                                                break;
                                        }
                                        var order_product="";
                                        var expireEffectiveProduct=""
                                        var expireEffectiveProductBox=""
                                        var expireProduct="";
                                        // 查询权益
                                        $.post(
                                            post_url+"benefit/status",
                                            {
                                                e_id:JSON.parse(getCookie('user')).e_id,
                                                t_id:JSON.parse(getCookie('user')).t_id,
                                                token:JSON.parse(getCookie('user')).token,
                                                product_id:id,
                                                all:1
                                            },function(data){
                                                error_fn(data.error)
                                                console.log(data)
                                                if(productJSON[id][lang]==undefined){
                                                    var product=productJSON[id]['en']
                                                }else{
                                                    var product=productJSON[id][lang]
                                                }
                                                switch(data.error){
                                                    case 0:
                                                        $('.info>.right .app>.loading').remove();
                                                        var totalM=0;
                                                        var useM=0;
                                                        var total=0;
                                                        var use=0;
                                                        var islifetime=0;
                                                        // 实际过期
                                                        var totalExpire=0
                                                        var useExpire=0
                                                        // 实际没过期
                                                        var totalExpireEffective=0
                                                        var useExpireEffective=0
                                                        var timeExpireEffective
                                                        for(var i=0;i<data.subscriptions.length;i++){
                                                            try{
                                                                if(data.subscriptions[i].benefits[benefits_ind].cost==undefined){
                                                                    continue;
                                                                }
                                                            }catch(e){
                                                                console.log(e)
                                                                continue;
                                                            }
                                                            console.log(data.subscriptions[i].id,data.subscriptions[i].expire_effective*1,Date.parse(new Date())/1000)
                                                            if(data.subscriptions[i].expire!=0){
                                                                if(data.subscriptions[i].expire_effective*1>Date.parse(new Date())/1000 || data.subscriptions[i].expire*1>Date.parse(new Date())/1000){
                                                                    timeExpireEffective=data.subscriptions[i].expire_effective*1
                                                                    expireEffectiveProductBox+=`
                                                                    <table class="isfree2" order_id="${data.subscriptions[i].id}">
                                                                        <tr>
                                                                            <td>${data.subscriptions[i].benefits[benefits_ind].quota} ${i18n[lang].credit}/${i18n[lang].Month} (${getdate(data.subscriptions[i].since)} - ${getdate(data.subscriptions[i].expire)})</td>
                                                                            <td>${i18n[lang].Remaining} <span><i class="num">${data.subscriptions[i].benefits[benefits_ind].quota-data.subscriptions[i].benefits[benefits_ind].cost}</i> ${i18n[lang].credit}</span></td>
                                                                        </tr>
                                                                    </table>
                                                                    `;
                                                                    try{
                                                                        totalExpireEffective+=data.subscriptions[i].benefits[benefits_ind].quota*1
                                                                        useExpireEffective+=data.subscriptions[i].benefits[benefits_ind].cost*1
                                                                    }catch(e){
                                                                        console.log(e)
                                                                    }
                                                                }else{
                                                                    expireProduct+=`
                                                                    <table class="isfree2" order_id="${data.subscriptions[i].id}">
                                                                        <tr>
                                                                            <td>${data.subscriptions[i].benefits[benefits_ind].quota} ${i18n[lang].credit}/${i18n[lang].Month} (${getdate(data.subscriptions[i].since)} - ${getdate(data.subscriptions[i].expire)})</td>
                                                                            <td>${i18n[lang].Remaining} <span><i class="num">${data.subscriptions[i].benefits[benefits_ind].quota-data.subscriptions[i].benefits[benefits_ind].cost}</i> ${i18n[lang].credit}</span></td>
                                                                        </tr>
                                                                    </table>
                                                                    `
                                                                }
                                                            }else{
                                                                if(data.subscriptions[i].product_feature==101&&(data.subscriptions[i].benefits[benefits_ind].quota*1-data.subscriptions[i].benefits[benefits_ind].cost*1)!=0){
                                                                    order_product+=`
                                                                    <table class="isfree2" order_id="${data.subscriptions[i].id}">
                                                                        <tr>
                                                                            <td>${data.subscriptions[i].benefits[benefits_ind].quota*1} ${i18n[lang].credit}/${i18n[lang].lifetime}<i class="free">Free</i></td>
                                                                            <td>${i18n[lang].Remaining} <span><i class="num">${data.subscriptions[i].benefits[benefits_ind].quota*1-data.subscriptions[i].benefits[benefits_ind].cost*1}</i> ${i18n[lang].credit}</span></td>
                                                                        </tr>
                                                                    </table>
                                                                    `;
                                                                }else if(data.subscriptions[i].product_feature==0){
                                                                    
                                                                }else{
                                                                    islifetime=1;
                                                                    try{
                                                                        total+=data.subscriptions[i].benefits[benefits_ind].quota*1
                                                                        use+=data.subscriptions[i].benefits[benefits_ind].cost*1
                                                                    }catch(e){
                                                                        console.log(e)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        if(islifetime){
                                                            order_product+=`
                                                            <table class="isfree2">
                                                                <tr>
                                                                    <td>${total} ${i18n[lang].credit}/${i18n[lang].lifetime}</td>
                                                                    <td>${i18n[lang].Remaining} <span><i class="num">${total-use}</i> ${i18n[lang].credit}</span></td>
                                                                </tr>
                                                            </table>
                                                            `;
                                                        }
                                                        if(totalExpireEffective-useExpireEffective){
                                                            expireEffectiveProduct+=`
                                                            <table class="isfree2">
                                                                <tr>
                                                                    <td><span style="font-weight: bold;">${totalExpireEffective} ${i18n[lang].credit}</span> (${i18n[lang].expire}${getdate(timeExpireEffective)})</td>
                                                                    <td>${i18n[lang].Remaining} <span><i class="num">${totalExpireEffective-useExpireEffective}</i> ${i18n[lang].credit}</span></td>
                                                                    <td>
                                                                        <span class="tabClickExpireEffective click">${i18n[lang].viewDetails}</span>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            `
                                                        }
                                                        order_product+=``;
                                                        $('.info .app').append(`
                                                            <div class="picwand">
                                                                <div class="flex_box">
                                                                    <div class="left">
                                                                        <img src="/images/icon/${product['icon'].split('.')[0]+'-66.'+product['icon'].split('.')[1]}" loading="lazy" alt="${product['name']}">
                                                                        <span>${product['name']}</span>
                                                                    </div>
                                                                    <div class="right">
                                                                        <div class="scroll_box">
                                                                            <div class="expireEffectiveList">
                                                                                ${expireEffectiveProductBox}
                                                                                
                                                                            </div>
                                                                            <div class="orderList">
                                                                                ${expireEffectiveProduct}
                                                                                ${order_product}
                                                                            </div>
                                                                            <div class="expireList">
                                                                                ${expireProduct}
                                                                            </div>
                                                                        </div>
                                                                        ${expireProduct?'<span class="tabClickExpire click">'+i18n[lang].viewHistory+'</span>':''}
                                                                        <p class="expireEffectiveInfo">${i18n[lang].viewDetailsInfo}</p>
                                                                        <i class="back"></i>
                                                                        <div class="more_box">
                                                                            <a class="btn" href="${product['buy-link']}" target="_blank">${i18n[lang].buy_more}</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="product_child">
                                                                    <p>${i18n[lang].Available_for}</p>
                                                                    <div>
                                                                        <div class="top flex_box">
                                                                            <img src="/images/icon/${productJSON['173'][lang].icon}" alt="${productJSON['173'][lang].name}">
                                                                            <div>
                                                                                <p class="name">${productJSON['173'][lang].name}</p>
                                                                                <p class="info">${i18n[lang].credit_per.replace(/%s/g,2)}</p>
                                                                            </div>
                                                                            <a href="${productJSON['173'][lang].link}">${i18n[lang].use_immediately}</a>
                                                                        </div>
                                                                        <div class="top flex_box">
                                                                            <img src="/images/icon/${productJSON['182'][lang].icon}" alt="${productJSON['182'][lang].name}">
                                                                            <div>
                                                                                <p class="name">${productJSON['182'][lang].name}</p>
                                                                                <p class="info">${i18n[lang].credit_per.replace(/%s/g,2)}</p>
                                                                            </div>
                                                                            <span class="btn">${i18n[lang].use_immediately}
                                                                            <div><!-- <i class="online"></i><span></span> --><i class="win"></i><span></span><i class="mac"></i></div>
                                                                            <div>
                                                                                <!-- <a href="${productJSON['182'][lang].link}"><i class="online"></i>${i18n[lang].Use_in_browser}</a> -->
                                                                                <a href="${device_confirm()?'https://download.aiseesoft.com/ai-photo-editor.exe':'https://www.aiseesoft.com/ai-photo-editor/'}"><i class="win"></i>${i18n[lang].win_desktop}</a>
                                                                                <a href="${device_confirm()?'https://download.aiseesoft.com/mac/ai-photo-editor.dmg':'https://www.aiseesoft.com/ai-photo-editor/'}"><i class="mac"></i>${i18n[lang].Mac_desktop}</a>
                                                                            </div>
                                                                            </span>
                                                                        </div>
                                                                        <div class="top flex_box">
                                                                            <img src="/images/icon/${productJSON['178'][lang].icon}" alt="${productJSON['178'][lang].name}">
                                                                            <div>
                                                                                <p class="name">${productJSON['178'][lang].name}</p>
                                                                                <p class="info">${i18n[lang].credit_per.replace(/%s/g,1)}</p>
                                                                            </div>
                                                                            <a href="${productJSON['178'][lang].link}">${i18n[lang].use_immediately}</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        `);
                                                        break;
                                                    case 26801:
                                                        error(i18n[lang].error_26801);
                                                        break;
                                                    case 26802:
                                                        error(i18n[lang].error_26802);
                                                        break;
                                                    case 26803:
                                                        error(i18n[lang].error_26803);
                                                        break;
                                                    case 26804:
                                                        error(i18n[lang].error_26804);
                                                        break;
                                                }
                                            }
                                        ).error(function(xhr){
                                            overtime(26899);
                                        });
                                    }(id))
                                }
                            }
                        $('.warning .all_software').append(`
                            <div>
                                <img width="64" height="64" src="/images/icon/${product['icon']}" loading="lazy" alt="${product['name']}">
                                <p>${product['name']}</p>
                            </div>
                        `);
                    }
                };
                // picwand
                $.ajaxSettings.async=true
                var product_child_arr=[178]
                for(var p=0;p<product_child_arr.length;p++){
                    var product=$('.info>.right .app>div[product$="'+product_child_arr[p]+'"]')
                    // 老套餐没有点数隐藏
                    var isINum=0
                    for(var iNum=0;iNum<product.find('i.num').length;iNum++){
                        console.log(product.find('i.num')[iNum].innerHTML)
                        if(Number(product.find('i.num')[iNum].innerHTML)<=0){
                            isINum=0
                        }else{
                            isINum=1
                            break;
                        }
                    }
                    if(!isINum){
                        product.hide() 
                    }
                }
            };
            break;
        case 24801:
            error(i18n[lang].error_24801);
            break;
    }
}).error(function(xhr){
    overtime(24899);
});
// 订单切换
$(document).on('click','.info>.right .app>div .right .tabClickExpireEffective',function(e){
    e.stopPropagation();
    $(this).parents('.scroll_box').parent().find('.expireEffectiveList').show().siblings('div').hide()
    $(this).parents('.scroll_box').parent().find('.expireEffectiveInfo').show()
    $(this).parents('.scroll_box').parent().find('.info>.right .app>div .right .tabClickExpire').hide()
    $(this).parents('.scroll_box').parent().find('.back').show()
})
$(document).on('click','.info>.right .app>div .right .tabClickExpire',function(e){
    e.stopPropagation();
    $(this).parent().find('.expireList').show().siblings('div').hide()
    $(this).parent().find('.info>.right .app>div .right .tabClickExpire').hide()
    $(this).parent().find('.expireEffectiveInfo').show()
    $(this).parent().find('.back').show()
})
$(document).on('click','.info>.right .app>div .right .back',function(){
    $(this).parent().find('.tabClickExpire').show()
    $(this).hide()
    $(this).siblings('.expireEffectiveInfo').hide()
    $(this).parent().find('.expireEffectiveList').hide().siblings('.expireList').hide().siblings('.orderList').show()
})
infoUserProfileFn.push(registerCodeList);
function registerCodeList(){
    // 注册码订单
    $.post(
        post_url+"order/list3",
        {
            e_id:JSON.parse(getCookie('user')).e_id,
            t_id:JSON.parse(getCookie('user')).t_id,
            token:JSON.parse(getCookie('user')).token
        },
        function(data){
            console.log(data);
            error_fn(data.error)
            if(!data.error){
                for(var i=0;i<data.products.length;i++){
                    data.products[i].codes.sort((a,b)=>b.ts_since-a.ts_since);
                    var order_product='';
                    var feature=productJSON[data.products[i].p_id];
                    if(feature[lang]==undefined){
                        var product=feature['en']
                    }else{
                        var product=feature[lang]
                    }
                    if(product){
                        var time
                        for(var j=0;j<data.products[i].codes.length;j++){
                            time=getdate(data.products[i].codes[j].ts_since)
                            var code=data.products[i].codes[j].code
                            // i18n[lang].monthly_subscription
                            
                            order_product+=`
                                <div class="desktop_order">
                                    <div>
                                        ${data.products[i].codes[j].ts_expire?`<p class="time"><span>${isTime(data.products[i].codes[j].ts_expire,data.products[i].codes[j].ts_since)}</span> (${getdate(data.products[i].codes[j].ts_since)} ${i18n[lang].to} ${getdate(data.products[i].codes[j].ts_expire)})</p>`:`<p class="time"><span>${i18n[lang].lifetime_subscription}</span></p>`}
                                        <p class="code">${(i18n[lang].activated_computer).replace(/%s/,data.products[i].codes[j].licensed).replace(/%s/,data.products[i].codes[j].license)}<i class="view" title="${i18n[lang].show_registration_code}"></i><i class="code_copy" title="${i18n[lang].copy_registration_code}"><span>${i18n[lang].Copy_complete}</span></i></p>
                                        <p class="code_text_complete" code="${code}">${code.substr(0,3)}******${code.substr(code.length-3,3)}</p>
                                    </div>
                                    ${data.products[i].codes[j].licensed?`<span class="btn" code="${code}">${i18n[lang].activate_unbinding}</span>`:``}
                                </div>
                            `;
                        }
                        $('.info .app').append(`
                            <div class="flex_box" product="${data.products[i].p_id}">
                                <div class="left">
                                    <img src="/images/icon/${product.icon}" loading="lazy" alt="${product.name}">
                                    <span>${product.name}</span>
                                </div>
                                <div class="right">
                                    <p style="font-size:16px;color:#333;margin-bottom:16px;">${i18n[lang].vcu_state}</p>
                                    <div class="product_inner">
                                        <div class="scroll_box">
                                        ${order_product}
                                        </div>
                                    </div>
                                    <div class="more_box">
                                        <a class="btn" href="${product['buy-link']}" target="_blank">${i18n[lang].buy_more}</a>
                                        <a style="color:#3774FF;" href="${product.link}" target="_blank">${i18n[lang].use_this_function}</a>
                                    </div>
                                </div>
                            </div>
                        `)
                    }
                }
            }else{
                if(data.error==28802){
    
                }else{
                    error(i18n[lang].error_order1+data.error+i18n[lang].error_order2);
                }
            }
        }
    ).error(function(xhr){
        overtime(24899);
    });
}
// 注册码显示隐藏
$(document).on('click','.info>.right .app>div .right .desktop_order p.code .code_copy',function(){
    // $(this).find('span').show()
    // var that=$(this)
    // setTimeout(function(){
    //     that.find('span').fadeOut()
    // },2000)
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(this).parent().siblings('.code_text_complete').attr('code')).select();
    document.execCommand("copy");
    $temp.remove();
})
// 显示注册码
$(document).on('click','.info>.right .app>div .right .desktop_order p.code .view',function(){
    var code=$(this).parent().siblings('.code_text_complete').attr('code')
    if($(this).attr('class').indexOf('active')>-1){
        $(this).removeClass('active')
        $(this).parent().siblings('.code_text_complete').html(code.substr(0,3)+'******'+code.substr(code.length-3,3))
    }else{
        $(this).addClass('active')
        $(this).parent().siblings('.code_text_complete').html(code)
    }
})
// 打开解绑逻辑
var mask_unbind_code;
$(document).on('click','.info>.right .app>div .right .desktop_order .btn',function(){
    mask_unbind_code=$(this).attr('code')
    $('.mask_unbind').show()
    $('.mask_unbind_1').show().siblings('div').hide()
    $('.mask_unbind .btn_box p').html('')
    $('.mask_unbind_1 .p_info').html(i18n[lang].mask_unbind_info_2);
})
$('.mask_unbind>div .close').click(function(){
    $('.mask_unbind').hide()
})
$('.mask_unbind .mask_unbind_1 .btn_v1').click(function(){
    if(!isnetwork()){return false;};
    let that=$(this);
    that.find('p').html('')
    that.find('img').css('opacity',1);
    $.post(
        post_url+"account/reg/deactivate",
        {
            e_id:JSON.parse(getCookie('user')).e_id,
            t_id:JSON.parse(getCookie('user')).t_id,
            token:JSON.parse(getCookie('user')).token,
            code:mask_unbind_code
        },
        function(data){
            console.log(data);
            that.find('img').css('opacity',0);
            error_fn(data.error,that);
            switch(data.error){
                case 0:
                    success(i18n[lang].unbind_success)
                    $('.mask_unbind').hide()
                    for(var i=0;i<$('.unbind_box .unbind_email>div p').length;i++){
                        if($('.unbind_box .unbind_email>div p').eq(i).html()==mask_unbind_code){
                            $('.unbind_box .unbind_email>div p').eq(i).parent().remove()
                        }
                    }
                    if(!$('.unbind_box .unbind_email>div p').length){
                        $('.unbind_box').hide()
                    }
                    registerCodeList();
                    break;
                case 28901:
                    that.parent().find('p').html(i18n[lang].error_28901)
                    break;
                case 28902:
                    that.parent().find('p').html(i18n[lang].error_28902)
                    break;
                case 28903:
                    that.parent().find('p').html(i18n[lang].error_28903)
                    break;
                case 28904:
                    that.parent().find('p').html(i18n[lang].error_28904)
                    break;
                case 28905:
                    that.parent().find('p').html(i18n[lang].error_28905)
                    break;
                case 28906:
                    that.parent().find('p').html(i18n[lang].error_28906)
                    break;
                case 28907:
                    that.parent().find('p').html(i18n[lang].error_28907)
                    break;
                case 28908:
                    that.parent().find('p').html(i18n[lang].error_28908)
                    break;
                case 28909:
                    $('.mask_unbind_1_error').show().siblings('div').hide()
                    $('.mask_unbind_1_error .p_info').html(i18n[lang].error_28909)
                    break;
                case 28910:
                    that.parent().find('p').html(i18n[lang].error_28910)
                    break;
                case 28911:
                    that.parent().find('p').html(i18n[lang].error_28911)
                    break;
                case 28912:
                    that.parent().find('p').html(i18n[lang].error_28912)
                    break;
            }
        }
    ).error(function(xhr){
        that.find('img').css('opacity',0);
        overtime(21299);
    });
})
//根据时间戳计算得出有效期还有多久
function surplusTime(expireDate){
    if(expireDate=="0000-00-00 00:00:00"){
        return i18n[lang].lifetime;
    };
    var expireDate=new Date(expireDate);
    var currentDate=Date.parse(new Date());
    if(parseInt((expireDate-currentDate)/(1000*60*60*24))<=15){
        return '<span style="color: #D80303;">'+i18n[lang].remain+' '+parseInt((expireDate-currentDate)/(1000*60*60*24))+' '+i18n[lang].day_s+'</span>';
    }else{
        return i18n[lang].remain+" "+parseInt((expireDate-currentDate)/(1000*60*60*24))+" "+i18n[lang].day_s;
    }
}
// 时间戳转换
function getdate(time) {
    var now=new Date(time*1000)
    var y=now.getFullYear()
    var m=now.getMonth()+1
    var d=now.getDate()
    return (d < 10 ? "0" + d : d) + "/" + (m < 10 ? "0" + m : m) + "/" + y
}
// 判断包月还是包年
function isTime(ts_since,ts_expire){
    var diffInMilliseconds = Math.abs(ts_since*1000 - ts_expire*1000);
    var diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
    if(Math.ceil(diffInDays)>=365){
        return i18n[lang].year_subscription
    }else{
        return i18n[lang].monthly_subscription
    }
}