function system_confirm(){var userAgentInfo = navigator.userAgent;if(userAgentInfo.indexOf("Win")>-1){return "win"}else if(userAgentInfo.indexOf("Mac")>-1){return "mac"}};function device_confirm(){var userAgentInfo = navigator.userAgent;var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];var flag = true;for (var v = 0; v < Agents.length; v++) {if (userAgentInfo.indexOf(Agents[v]) > 0) {flag = false;break;}}return flag;};
//system identification
function IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"
    ];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
};
var device_id = IsPC();
var isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel");

if(system_confirm()=="mac"){isMac=true}
else if(system_confirm()=="win"){isMac=false}
if(device_confirm()==true){device_id=true}
else if(device_confirm()==false){device_id=false}else{};
if (isMac == true) {
  $('.mac_btn_box').css({"display":"flex"});
  $('.mac_btn').css({"display":"block"});
  $('.win_btn_box').css({"display":"none"});
  $('.win_btn').css({"display":"none"});
} else if (!isMac == true) {
  $('.win_btn_box').css({"display":"flex"});
  $('.win_btn').css({"display":"block"});
  $('.mac_btn_box').css({"display":"none"});
  $('.mac_btn').css({"display":"none"});
  
} else if (device_id == false) {
  $('.pc_btn').css({"display":"none"});
};
$.ajax({
    url: "/header.html",
    type: "GET",
    dataType: "html",
    async: false,
    success: function(h){
        $.ajax({
            url: "/footer.html",
            type: "GET",
            dataType: "html",
            async: false,
            success: function(f){
                $('.header_module').html(h)
                $('.footer_module').html(f)
            }
        });
    }
});
// 上下导航
var a
a="<div id=\"search\">";
a+="<form action=\"/search-results.html\" method=\"get\" name=\"s\">";
a+="<input type=\"hidden\" name=\"cx\" value=\"010617911608535859722:uqlapznormm\" />";
a+="<input type=\"hidden\" name=\"cof\" value=\"FORID:11\" />";
a+="<input type=\"hidden\" value=\"UTF-8\" name=\"ie\">";
a+="<input type=\"hidden\" name=\"domains\" value=\"www.tipard.com\" \/>";
a+="<input type=\"hidden\" name=\"sitesearch\" value=\"www.tipard.com\" \/>";
a+="<input type=\"text\" id=\"q\" name=\"q\" onmouseover=\"this.focus()\" onclick=\"if(this.value==\'DVD Ripper\')this.value=\'\'\" onblur=\"if (value ==\'\'){value=\'DVD Ripper\'}\" onfocus=\"this.select()\"class=\"searchbox\" value=\"DVD Ripper\"\/>";
a+="<input type=\"submit\" id=\"search_btn\" name=\"search_btn\" class=\"btn_search\" value=\"Search\" \/>";
a+="<\/form>";
a+="<\/div>";
a+="<script type=\"text/javascript\" src=\"http://www.google.com/cse/brand?form=cse-search-box&lang=en\"></script>";
try{
    document.getElementById("search").innerHTML=a
}catch(e){
    console.log(e);
}
$(".sliding").hide();
$(".show_hide").show();
$('.show_hide').click(function(e){
    e.stopPropagation();
    $(".sliding").slideToggle();


    $('.header_new div.toggle').removeClass('active')
    $('.header_new div.menu').removeClass('active');
    $('.header_new .login_box ul').hide()
});
// 多语言
$(".footer .language").click(function(e) {
    e.stopPropagation();
    $(this).toggleClass('active');
});
$(document).click(function(){
    $(".sliding").hide();
    $('.header_new div.toggle').removeClass('active')
    $('.header_new div.menu').removeClass('active');
    $('.header_new .login_box ul').hide()
    
	$(".footer .language").removeClass('active');
})
$('.header_new .login_box ul').click(function(e){
    e.stopPropagation()
})

try{
    if($(window).width() >= 1200){
        $('.header_new div.menu li').mouseover(function(){
            $(this).addClass('active').siblings().removeClass('active')
        })
        $('.header_new div.menu li').mouseout(function(){
            $(this).removeClass('active').siblings().removeClass('active')
        })
    }
}catch(e){}

if($('.header_new').find('div.toggle').length){
    $('.header_new div.toggle').click(function(e){
        e.stopPropagation()
        $(this).toggleClass('active');
        $('.header_new div.menu').toggleClass('active');


        $(".sliding").hide();
        $('.header_new .login_box ul').hide()
    });
    $('.header_new div.menu li').click(function(){
        $(this).toggleClass('active').siblings().removeClass('active');
    })
    $('.header_new div.menu').click(function(e){
        e.stopPropagation()
    })
}
function headerResize(){
    if($(window).width()>1200){
        $('.header_new .login_box').unbind('click');
        $('.header_new .login_btn .login_box').unbind('hover')
        $('.header_new .login_btn .login_box').hover(function(){
            $('.header_new .login_btn .login_box ul').show().css({
                'width':300,
                'marginLeft':0
            });;
        },function(){
            $('.header_new .login_btn .login_box ul').hide();
        });
    }else{
        // 会员
        $('.header_new .login_box').unbind('click');
        $('.header_new .login_btn .login_box').unbind('hover')
        $('.header_new .login_btn .login_box').unbind('mouseenter')
        $('.header_new .login_btn .login_box').unbind('mouseleave')
        $('.header_new .login_box').click(function(e){
            e.stopPropagation();
            if(String($('.header_new .login_box ul').attr('class')).indexOf('active')<0){
                $(".log_list").slideToggle();
                $('.header_bg').show()
            }else{
                $('.header_bg').hide()
            }

            $(".sliding").hide();
            $('.header_new div.toggle').removeClass('active')
            $('.header_new div.menu').removeClass('active');
        });
    }
}
headerResize()
var windowWidth=$(window).width();
$(window).resize(function(){
    if(windowWidth!=$(window).width()){
        headerResize()
        windowWidth=$(window).width()
    }
})
// 打点
function manage(operation,data){
    // try{
    //     dataLayer.push({
    //         'event':'dotting',
    //         'name':'AnyCoord',
    //         'operation':operation,
    //         'lable':String(data)
    //     })
    // }catch(e){
    //     console.log(e);
    // }
}
$(document).click(function(e){
    if(e.target.getAttribute('data-operation') || e.target.getAttribute('data-label')){
        // manage(e.target.getAttribute('data-operation'),e.target.getAttribute('data-label'))
    }
})